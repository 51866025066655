import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column, Summary, TotalItem  } from 'devextreme-react/data-grid';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, CodeSelectBox,
    SrchButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */
import ico_siren from '../../images/etc/siren.png';

/** 
 *  재고 관리
 */
const StockPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    /* ====================
     *  Object
     * ==================== */

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodTp, setProdTp] = useState(cmn.CMNCO_PRODTP.MAT);
    const [prodId, setProdId] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "제품유형",
                width: "200px",
                item: 
                    <CodeSelectBox
                        value={prodTp}
                        classId={'101'}
                        placeholder="제품유형을 선택하세요."
                        showClearButton={false}
                        onValueChanged={(v) => { 
                            setProdTp(v);
                        }}
                    />
            },
            {   title: "품번",
                item: 
                <TextBox 
                    value={prodId}
                    width={"200px"}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />    
            }
        ]
    ];
    /* #endregion */

    // * Grid
    const [stockList, setStockList] = useState([]);
    const [stockListTot, setStockListTot] = useState(0);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, prodTp]);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        const ps =  {
            p: {
                prodTp: prodTp, 
                prodId: prodId,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength

            }
        }

       // console.log("===== para", ps);
        const r = await cmn.api("getStockList", ps );
        if(r === undefined || r == null) return;

        setStockList(r.d0)
        setStockListTot(r.tot)
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
    }
    
    // * Data Grid
    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    /* ====================
     *  Render
     * ==================== */
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
            </ListTopView>          
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="재고내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>

            {/** GRID */}
            <Observer>{() =>
            <>        
            <DataGridView  
                reff={grid1}          
                dataSource={stockList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}              
                
                selection=''
                showNo={false}

                // paging
                page={curPage}
                total={stockListTot}   // store
                itemsPerPage={rowsLength}
                multiply={2}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // excel
                exlnm={'재고내역'}
                customizeExcelCell={customizeExcelCell}

                // onSelectionChanged={handleGridSelectionChanged}   
                colsWidth={[cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd, 70
                    ,cmn.CW.lot, cmn.CW.amt, 60, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80]} 
            >
                <Column 
                    alignment={'center'}
                    dataField={'prodNm'} 
                    caption={'품명'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockList, 'prodId'); }}
                />    
                <Column 
                    alignment={'center'}
                    dataField={'prodId'} 
                    caption={'품번'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockList, 'prodId'); }}
                />                
                <Column 
                    alignment={'center'}
                    dataField={'prodStnd'}
                    caption={'규격'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockList, 'prodId'); }}
                />
                 <Column 
                    alignment={'center'}
                    dataField={'prodPrice'}
                    caption={'단가'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockList, 'prodId', util.getNumComma(d.value)); }}
                />
                 <Column 
                    alignment={'center'}
                    dataField={'safeAmt'}
                    caption={'안전재고'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockList, 'prodId', util.getNumComma(d.value)); }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'stockAmt'}
                    caption={'재고'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockList, 'prodId', util.getNumComma(d.value)); }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'safeYn'}
                    caption={'과부족'}
                    cellRender={(d) => { 
                         let img = '';
                         if(d.value === 'N')
                            img = <img src={ico_siren} alt=''></img>;
                            
                         return cmn.cellRenderRowSpan(d, stockList, 'prodId', img); 
                    }}
                />
                { cmn.getGridColumn('proc1', '공정1') }
                { cmn.getGridColumn('proc2', '공정2') }
                { cmn.getGridColumn('proc3', '공정3') }
                { cmn.getGridColumn('proc4', '공정4') }
                { cmn.getGridColumn('proc5', '공정5') }
                { cmn.getGridColumn('proc6', '공정6') }
                { cmn.getGridColumn('proc7', '공정7') }
                { cmn.getGridColumn('proc8', '공정8') }
                { cmn.getGridColumn('proc9', '공정9') }
                { cmn.getGridColumn('proc10', '공정10') }
                <Column />
                <Summary>
                    <TotalItem
                        column={'prodPrice'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value / 2);
                        }}
                    />
                </Summary>
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default StockPage;
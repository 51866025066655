import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';

/** components */


/** 
 *  Grid Top View
 */
const GridTopView = (props) => {

    /* ====================
     *  Store 
     * ==================== */

        // ...

    /* ====================
     *  Object
     * ==================== */

    const {
        iconVisible=true,
        title,
        style
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */

    useEffect(() => {
        // ...
    },[]);

    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */       
   
    const renderContent = () => {
        return (
            <>
            <div 
                className="clearfix"
                style={style}
            >                
                <div className="tit-area fl-l">{ title }</div>
                <div className="btn-area fl-r" style={{height: '34px'}} >
                { props.children }
                </div>
            </div>            
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default GridTopView;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout,
    ActionButton,
    BizSelectBox,
    UseYnSwitchBox,
    CodeSelectBox,
    EqupSelectBox,
    SrchButton
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공정 등록 / 수정
 */
const ProdProcDtlPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        ppuid = ls.ppuid,
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [equpItems, setEqupItems] = useState([]);

    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [equpUid, setEqupUid] = useState('');
    const [equpNm, setEqupNm] = useState('');
    const [lineUid, setLineUid] = useState('');

    const [procPrice, setProcPrice] = useState(0);
    const [oldProcPrice, setOldProcPrice] = useState(0);
    const [unitTp, setUnitTp] = useState('');
    const [unitTpNm, setUnitTpNm] = useState('');
    const [defBizYn, setDefBizYn] = useState('N');
    const [useYn, setUseYn] = useState('Y');
    const [cmnt, setCmnt] = useState('');
    const [prodProcUid, setProdProcUid] = useState('');

    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [procNm, setProcNm] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = () => {
        try {
            // 공정정보조회
            if(mode === "a"){
                loadProdProcInfo();
            }
            else{
                loadProdProcDtlInfo();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadProdProcInfo = async() => {
        if(util.getChkNull(ppuid) === '') return;

        let r = await cmn.api('getProdProc', {p:{prodProcUid: ppuid}});
        if(r == null || r === undefined) return;

        const data = r.d0[0];
        
        setProdId(data.prodId);
        setProdNm(data.prodNm);
        setProdStnd(data.prodStnd);
        setProcNm(data.procNm);
        setUnitTp(data.unitTp);
        setUnitTpNm(data.unitTpNm);
        setEqupUid(data.equpUid);
        setLineUid(data.lineUid);
    }

    const loadProdProcDtlInfo = async() => {
        if(util.getChkNull(uid) === '') return;

        let r = await cmn.api('getProdProcDtl', {p:{prodProcDtlUid: uid}});
        if(r == null || r === undefined) return;

        const data = r.d0[0];

        if(util.getChkNull(data.equpUid) !== "")
            loadEqupCombo();

        setProdId(data.prodId);
        setProdNm(data.prodNm);
        setProdStnd(data.prodStnd);
        setProcNm(data.procNm);

        setProdProcUid(data.prodProcUid);
        setBizUid(data.bizUid);
        setEqupUid(data.equpUid);
        setLineUid(data.lineUid);
        setProcPrice(data.procPrice);
        setOldProcPrice(data.procPrice);
        setUnitTp(data.unitTp);
        setDefBizYn(data.defBizYn);
        setUseYn(data.useYn);
        setCmnt(data.cmnt);
        setEqupNm(data.equpNm);
        setBizNm(data.bizNm);
        setUnitTpNm(data.unitTpNm);

        if(_.includes(data.bizTp,'70201')) //업체가 본사일 경우
            loadEqupCombo(); 
    }

    const loadEqupCombo = async() => {
        const r = await cmn.api("getEqupCombo", {p: {}});
        if(r === undefined || r == null) return;         
        setEqupItems(r.d0);
    }
    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(bizUid, '업체선택'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addProdProcDtl();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upProdProcDtl();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickOpen = (e) => {
        const url = `/base/bizpop?${qs.stringify({mode: 'a'})}`;        
        util.openWindow(url, 700, 500);    
    }


    const addProdProcDtl = async() => {

        const ps = {
            p: {
                prodProcDtlUid: util.getUUID(),
                prodProcUid: ppuid,
                bizUid: bizUid,
                equpUid: equpUid,
                procPrice: procPrice,
                unitTp: unitTp,
                defBizYn: defBizYn,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid,
                procPriceHis: {
                    procPriceHisUid: util.getUUID(),
                    chgDy: util.getDateToString('d',util.getNow()),
                    chgPrice: procPrice,
                    chgPer: 0,
                    chgTp:''
                }
            }
        }

        const r = await cmn.api('addProdProcDtl', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    const upProdProcDtl = async() => {
        //alert(unitTp);
        let procPriceHis_ = null;

        if(oldProcPrice !== procPrice)
        {
            procPriceHis_ = {
                procPriceHisUid: util.getUUID(),
                chgDy: util.getDateToString('d',util.getNow()),
                chgPrice: procPrice,
                chgPer: oldProcPrice < procPrice ? oldProcPrice === 0 ? 100 : util.fix((procPrice - oldProcPrice) / oldProcPrice * 100) : util.fix((oldProcPrice - procPrice ) / oldProcPrice * 100),
                chgTp: oldProcPrice < procPrice ? '70801' : '70802'
            }
        }

        const ps = {
            p: {
                prodProcDtlUid: uid,
                prodProcUid: prodProcUid,
                bizUid: bizUid,
                equpUid: equpUid,
                procPrice: procPrice,
                unitTp: unitTp,
                defBizYn: defBizYn,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid,
                procPriceHis: procPriceHis_
            }
        }
        
        const r = await cmn.api('upProdProcDtl', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");

        util.clickOpenerElement("subSrchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <SrchButton 
                    id="srchButton"
                    onClick={ (e)=> {
                        window.location.reload();
                    }}
                />
                <ActionButton 
                    onClick={handleClickOpen}
                    title={"업체추가"}
                    type={2}
                    show={true}
                />
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon" >
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">품명</th>
                            <td className="tcon"> 
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">규격</th>
                            <td className="tcon" >
                                <span>{prodStnd}</span>
                            </td>
                            <th className="tit">공정명</th>
                            <td className="tcon"> 
                                <span>{procNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">업체선택</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <BizSelectBox
                                        value={bizUid}
                                        showClearButton={false}
                                        onLoadInfo={(o) => {
                                            if(_.includes(o.bizTp,'70201')) //업체가 본사일 경우
                                                loadEqupCombo(); //설비 콤보를 호출
                                            else //아닐경우
                                            {
                                                //setEqupItems([]); //초기화
                                                setEqupUid('');
                                            }
                                        }} 
                                        onValueChanged={(v) => {
                                            setBizUid(v);
                                        }}
                                    />  
                                :
                                    <span>{bizNm}</span>
                                }
                            </td>
                            <th className="tit">단위</th>
                            <td className="tcon"> 
                                <span>{unitTpNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">공정비용</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        value={procPrice}
                                        format={'#,##0'}
                                        width={'120px'}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setProcPrice(e.value);
                                        }} 
                                    />
                                :
                                    <span>{procPrice}</span>
                                }
                            </td>
                        
                        </tr>
                        <tr>
                        <th className="tit">설비선택</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <EqupSelectBox
                                        width={'200px'}
                                        value={equpUid}
                                        lineYn={authStore.com.isLine ? 'Y' : 'N'}
                                        lineValue={lineUid}
                                        onLoadInfo={(o) => {}} 
                                        onValueChanged={(v) => {
                                            setEqupUid(v);
                                        }}
                                    />   
                                    :
                                    <span>{equpNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">기본여부</th>
                            <td className="tcon"> 
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={defBizYn}
                                        onValueChanged={(v)=>{
                                            setDefBizYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{defBizYn}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon"> 
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={useYn}
                                        onValueChanged={(v)=>{
                                            setUseYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요"
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "공정상세 등록" 
                : mode === "u" ? "공정상세 수정"
                : "공정상세" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProdProcDtlPopPage;
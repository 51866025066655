import React, { useState, useEffect, useContext, useRef } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */
import {
    SubActionButton
} from '../../components';

/** stores & lib */
import { CmnStore } from '../../stores';
import { util } from '../../lib';

/** 
 *  Lot 선택 조회
 */
const LotSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    const {

        width='100%',
        value,
        preValue,
        placeholder='Lot 선택하세요.',
        refreshCd,
        showClearButton=false,

        workNum,
        prodUid,

        sortCol,
        sortTp,

        onValueChanged,
        btnShow=true

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [lots, setLots] = useState([]);

    useEffect(() => {
        loadPage();
    },[prodUid, workNum, refreshCd]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {      
        try{
            if(util.getChkNull(prodUid) === '' && util.getChkNull(workNum) === ''){
                setLots([]);
    
                onValueChanged();
                return;
            }
            const ps =  {
                p: {
                    workNum: workNum, 
                    prodUid: prodUid, 
                    sortCol: sortCol,
                    sortTp: sortTp
                }
            }
    
            const lots_ = await cmnStore.getLotListCombo(ps);
            
            setLots(lots_);

           // alert(value);
            // if(util.getChkNull(value) === '')
            // {
            //     alert(lots_[0].lot);
            //     onValueChanged(lots_[0].lot);
            // }
        }  
        catch(err) {
            // nothing ...
        }

    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <div>
                <ul className="ul-row" style={{width: width}}>
                    <li style={{width: 'calc(100% - 80px)'}}>
                    <SelectBox 
                        width={width}
                        placeholder={placeholder}
                        value={value}
                        dataSource={lots}
                        displayExpr={'lot'}
                        valueExpr={'lot'}

                        showClearButton={showClearButton}
                        // searchEnabled={true}
                        // searchMode={'startswith'} // contains, startswith
                        // searchExpr={['prodId', 'prodNm', 'prodDisp']}
                        
                        //searchTimeout={this.state.searchTimeoutOption}
                        //minSearchLength={this.state.minSearchLengthOption}

                        onValueChanged={(e)=>{
                            const o = _.find(lots, {lot: e.value});
                            onValueChanged(e.value, o);
                        }} 
                    />
                    </li>
                    <li className="fl-r" style={{width: '80px'}}>                        
                        <SubActionButton 
                            id="lotCrtButton"
                            title="신규발급"
                            type="1"
                            marginRight={0}
                            show={lots.length == 0 ? false : btnShow}
                            onClick={(e)=>{
                                let bool_ = true;
                                _.forEach(lots, (v,k) => {
                                    if(v.lot === preValue)
                                        bool_= false;
                                })

                                if(bool_)
                                    onValueChanged('');
                                else
                                {
                                    util.cmnAlert(`이미 생성된 Lot 입니다. (예상 Lot: ${preValue})`);
                                    onValueChanged(preValue);
                                }
                                
                            }} 
                        />
                    </li>
                    
                </ul>
            </div>

            

            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default LotSelectBox;

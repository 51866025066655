const toolApiz = {
    //치공구
    getToolList: {
        title: "치공구 목록 조회",
        url: "/api/tool/getToolList",
        method: "POST"
    },
    getTool: {
        title: "치공구 상세 조회",
        url: "/api/tool/getTool",
        method: "POST"
    },
    getToolCombo: {
        title: "치공구 콤보",
        url: "/api/tool/getToolCombo",
        method: "POST"
    },
    addTool: {
        title: "치공구 등록",
        url: "/api/tool/addTool",
        method: "POST"
    },
    upTool: {
        title: "치공구 수정",
        url: "/api/tool/upTool",
        method: "POST"
    },
    delTool: {
        title: "치공구 삭제",
        url: "/api/tool/delTool",
        method: "POST"
    },
    //치공구 입고
    getToolInList: {
        title: "치공구 입고 목록 조회",
        url: "/api/tool/getToolInList",
        method: "POST"
    },
    getToolIn: {
        title: "치공구 입고 상세 조회",
        url: "/api/tool/getToolIn",
        method: "POST"
    },
    addToolIn: {
        title: "치공구 입고 등록",
        url: "/api/tool/addToolIn",
        method: "POST"
    },
    upToolIn: {
        title: "치공구 입고 수정",
        url: "/api/tool/upToolIn",
        method: "POST"
    },
    delToolIn: {
        title: "치공구 입고 삭제",
        url: "/api/tool/delToolIn",
        method: "POST"
    },
    //치공구 불출
    getToolOutList: {
        title: "치공구 불출 목록 조회",
        url: "/api/tool/getToolOutList",
        method: "POST"
    },
    getToolOut: {
        title: "치공구 불출 상세 조회",
        url: "/api/tool/getToolOut",
        method: "POST"
    },
    addToolOut: {
        title: "치공구 불출 등록",
        url: "/api/tool/addToolOut",
        method: "POST"
    },
    upToolOut: {
        title: "치공구 불출 수정",
        url: "/api/tool/upToolOut",
        method: "POST"
    },
    delToolOut: {
        title: "치공구 불출 삭제",
        url: "/api/tool/delToolOut",
        method: "POST"
    },
    //치공구 재고
    getToolStockList: {
        title: "치공구 불출 목록 조회",
        url: "/api/tool/getToolStockList",
        method: "POST"
    },
 
}

export default toolApiz;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';

import _ from 'lodash';

/** components */
import { EditGridView } from '..';

/** stores & lib */
import { cmn } from '../../lib';

/** 
 *  설비점검항목별 점검
 */
const EqupChkBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        mode,
        type, // 현장인 경우 site
        chkItems=[],

        onValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [goodBadTps, setGoodBadTps] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            if(mode !== "a" && mode !== "u") return;
            const r = await cmn.apiCodeCombo("403");
            setGoodBadTps(r);
        }
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */


    /* ====================
     *  Render
     * ==================== */

    const renderSelectItem = (d) => {        
        return(
            <>
            <span className={type === 'site' ? 'site-span' : ''}>
                {d.codeNm}
            </span>
            </>
        ) 
    }
      
    const renderContent = () => {
        let colsWidth_ = [];
        if(type === 'site'){
            colsWidth_ = [80, 80, 160, 320, 100, 220, 160, 100, null];
        }
        else {
            colsWidth_ = [60, 200, 80, 120, 120, 80, null];
        }        

        return (

                <EditGridView
                    // width="100%"                     
                    dataSource={chkItems}
                    colsWidth={colsWidth_}
                    gridHeight={450}
                >
                    { cmn.getGridColumn('sortOrder', '순서' ) }
                    {
                        type === 'site' &&
                        cmn.getGridColumn('chkCycleTpNm', '구분' )
                    }
                    {
                        type === 'site' &&
                        cmn.getGridColumnImg('chkFileInfo', '점검부위')
                    }
                    <Column 
                        alignment={'center'}
                        caption={'점검내용'}
                        allowEditing={false} 
                        cellRender={(d) => {
                            return (
                                <>
                                <ul>
                                    <li>
                                        <span>{d.data.chkNm}</span>
                                    </li>
                                    <li>
                                        <span>{d.data.chkCont}</span>
                                    </li>
                                </ul>
                                </>
                            )
                        }} 
                    />
                    { cmn.getGridColumn('chkTpNm', '점검구분' ) }
                    { cmn.getGridColumn('dispStnd', '규격' ) }
                    {/* <Column 
                        alignment={'center'}
                        caption={'규격'}  
                        allowEditing={false}
                        cellRender={(d) => {
                            return (
                                <>
                                { cmn.getDispEqupChkStnd(d.data.valSelectYn, d.data.upperVal, d.data.lowerVal) } 
                                </>
                            )
                        }} 
                    /> */}
                    <Column 
                        alignment={'center'}
                        dataField={'chkVal'} 
                        caption={'점검'}  
                        dataType="number"
                        cellRender={(d) => {
                            return (
                                <>
                                <div className="editable-outline">
                                {
                                    d.data.valSelectTp === cmn.CMNCO_VALSELTP.SEL ?
                                    d.data.goodBadTpNm
                                    :
                                    d.data.chkVal                                    
                                }
                                </div>
                                </>
                            )
                        }}
                        editCellRender={(d) => { 
                            return (
                                <>
                                {
                                    d.data.valSelectTp === cmn.CMNCO_VALSELTP.SEL ?
                                    <SelectBox 
                                        width={'100%'}
                                        value={d.data.goodBadTp}
                                        dataSource={goodBadTps}
                                        displayExpr={'codeNm'}
                                        valueExpr={'codeId'}

                                        itemRender={renderSelectItem}

                                        onValueChanged={(e)=>{
                                            const items_ = [...chkItems];
                                            items_[d.rowIndex].goodBadTp = e.value;
                                            items_[d.rowIndex].goodBadTpNm = e.component._options.displayValue;

                                            onValueChanged(items_);
                                        }} 
                                    />
                                    :
                                    <NumberBox
                                        width={'100%'}
                                        format={'#,##0.###'}
                                        value={d.data.chkVal}
                                        // defaultValue={0}
                                        // min={0}
                                        showSpinButtons={true}
                                        showClearButton={true}
                                        onValueChanged={(e)=>{
                                            const items_ = [...chkItems];
                                            items_[d.rowIndex].chkVal = e.value;

                                            let goodBadTp_ = cmn.CMNCO_CHKSEL.GOOD;
                                            if (e.value === undefined || e.value == null) {
                                                goodBadTp_ = null;
                                            }                                          
                                            else if ( 
                                                !cmn.getIsPassInsp(d.data.goodBadTp, e.value, d.data.valSelectTp
                                                    , d.data.stndVal, d.data.upperVal, d.data.lowerVal)
                                            ) {
                                                goodBadTp_ = cmn.CMNCO_CHKSEL.BAD;
                                            }

                                            items_[d.rowIndex].goodBadTp = goodBadTp_;
                                            onValueChanged(items_);
                                        }}
                                    />
                                }
                                </>
                            )
                        }}                        
                    />
                     <Column 
                        alignment={'center'}
                        caption={'점검결과'}  
                        cellRender={(d) => {
                             return cmn.getDispEqupGoodBadTp(d.data.goodBadTp);
                        }} 
                    />
                    {
                        type !== 'site' &&
                        <Column 
                            alignment={'center'}
                            dataField={'actDtl'} 
                            caption={'조치사항'}  
                            cellRender={(d) => {
                                return (
                                    <>
                                    <div className="editable-outline">
                                    { d.data.actDtl }
                                    </div>
                                    </>
                                )
                            }}
                            editCellRender={(d) => {
                                return (
                                    <>
                                    <TextBox                                         
                                        value={d.data.actDtl}
                                        showClearButton={true}
                                        placeholder="조치사항을 입력하세요."
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            const items_ = [...chkItems];
                                            items_[d.rowIndex].actDtl = e.value;
                                            onValueChanged(items_);
                                        }} 
                                    />   
                                    </>
                                )
                            }} 
                        />
                    }
                    {
                       type === 'site' &&
                       <Column />
                    }
                </EditGridView>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default EqupChkBox;

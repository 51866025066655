import React, { useState, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  사원 관리
 */
const UserPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [userNm, setUserNm] = useState('');
    const [deptTp, setDeptTp] = useState('');
    const [useYn, setUseYn] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "부서유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={deptTp}
                    classId={'701'}
                    placeholder="부서유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setDeptTp(v);
                    }}
                />
            },
            {   title: "이름",
                width: "200px",
                item: 
                <TextBox 
                    value={userNm}
                    showClearButton={true}
                    placeholder="이름으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setUserNm(e.value);
                    }}
                />       
            }
            ,
            {   title: "재직여부",
                item: 
                <UseYnSelectBox
                    value={useYn}
                    onValueChanged={(e)=>{
                        setUseYn(e.value);
                    }}
                />    
            }
        ]
    ];

    /* #endregion */
    
    // * Grid
    const [userList, setUserList] = useState([]);
    const [userListTot, setUserListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [gridHeight, setGridHeight] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});    

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, deptTp, useYn]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            //권한 조회
            await authStore.loadPage(props);

            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                deptTp: deptTp, 
                userNm: userNm, 
                useYn: useYn,
                delYn: 'N',
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getUserList", ps );
        if(r === undefined || r == null) return;

        setUserList(r.d0)
        setUserListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.userUid);
        }
    }

    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.userUid);
        }
    }

    const handleClickPwInit = async () => {
        try {
            if(util.chkListSelected(selectedItem)){
                if(util.confirmYn('비밀번호 초기화'))
                {
                    let pwInfo = util.pwInit('000');

                    let ps = {
                            p:{
                                comUid: authStore.com.uid
                                ,userUid: selectedItem.userUid
                                ,userPw: pwInfo.retKey
                                ,salt: pwInfo.retBuf
                                ,uptUserUid: authStore.user.uid
                            }
                        }

                    let r = await cmn.api('upUserPw', ps);
                    if(r == null || r === undefined) return;

                    util.cmnSucAlert('비밀번호 초기화');
                }
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, userUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/userpop?${qs.stringify({mode: mode, uid: userUid})}`;        
        util.openWindow(url, 700, 500);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />
                <ActionButton 
                    width={'160px'}
                    onClick={handleClickPwInit}
                    type={2}
                    title="비밀번호 초기화"
                    show={authStore.page.upYn}
                />
            </ListTopView>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="사원 내역"
            >
                {/* <IconButton
                    className="btn btn-isz ico-exldown"
                    marginRight={0}
                /> */}
            </GridTopView>
            <>        
            <DataGridView            
                dataSource={userList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={userListTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}

                colsWidth={[200, 150, 150, 150, 100, 100, 80, cmn.CW.yn, null]}
            >
                { cmn.getGridColumn('userNm', '이름', renderGridHeaderCell) }
                { cmn.getGridColumn('phone', '연락처', renderGridHeaderCell) }
                { cmn.getGridColumn('mobile', '핸드폰', renderGridHeaderCell) }
                { cmn.getGridColumn('email', '이메일', renderGridHeaderCell) }
                { cmn.getGridColumn('userId', '아이디', renderGridHeaderCell) }
                { cmn.getGridColumn('deptNm', '부서', renderGridHeaderCell) }
                { cmn.getGridColumn('position', '직위', renderGridHeaderCell) }
                { cmn.getGridColumnUseYn('useYn', '재직여부') }
                { cmn.getGridColumnCmnt() }
            </DataGridView>
            </>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default UserPage;
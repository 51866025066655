import React, { useState, useEffect, useContext, useRef, useReducer } from 'react';
import { Observer } from 'mobx-react-lite';
import { SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공통코드 관리
 */
const CodePage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */


    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 코드유형 Item
    const [codeClassItems, setCodeClassItems] = useState([]);

    // * 검색
    const [classId, setClassId] = useState('');
    const [useYn, setUseYn] = useState('');
    const codeNmEl = useRef(null);
    // const useYnEl = useRef(null);

    // ** Ref
    const dgEl0 = useRef(null);

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "코드유형",
                width: "200px",
                item: 
                    <SelectBox 
                        value={classId}
                        dataSource={codeClassItems}
                        displayExpr={'classNm'}
                        valueExpr={'classId'}
                        placeholder="코드유형을 선택하세요"
                        showClearButton={true} 
                        onValueChanged={(e)=>{
                            setClassId(util.getChkNull(e.value, ''));
                            // setListLoad1(true);
                        }} 
                    />
            },
            {   title: "코드명",
                width: "200px",
                item: 
                    <TextBox 
                        ref={codeNmEl}
                        showClearButton={true}
                        placeholder="코드명으로 검색하세요."
                        valueChangeEvent={'keyup'}
                    /> 
            },
            {   title: "사용여부",
                item: 
                    <UseYnSelectBox
                        // reff={useYnEl}
                        value={useYn}
                        onValueChanged={(e)=>{
                            setUseYn(e.value);
                        }}
                    />
            }
        ]
    ];

    /* #endregion */

    // * Grid
    const [codeList, setCodeList] = useState([]);
    const [codeListTot, setCodeListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    // const [selectedItem, setSelectedItem] = useState(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, classId, useYn]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await loadCodeClass();
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadCodeClass = async() => {
        let ps = {p:{systemYn:'N'}};
        let r = await cmn.api('getCodeClass', ps);
        if(r == null || r === undefined) return;

        setCodeClassItems(r.d0);  
    }

    const loadList1 = async() => {

        const ps =  {
            p: {
                classId: classId, 
                codeNm: util.getValEl(codeNmEl), 
                useYn: useYn,
                showYn: 'Y',

                offset: rowsOffset,
                limit: rowsLength,
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
            }
        }

        // console.log("===== p", ps);

        const r = await cmn.api("getCodeList", ps );
        if(r === undefined || r == null) return;

        setCodeList(r.d0)
        setCodeListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        const sd = util.getDgSelectedRowsData(dgEl0);        
        if(!util.chkListSelected(sd[0], '공통코드를')) return;
        if(sd[0].systemYn === 'Y') {
            util.cmnAlert('[SYSTEM] 코드는 수정할 수 없습니다.');
            return;
        }
        openCrudPopup("u", sd[0].codeUid);
    }

    const handleClickView = async () => {
        const sd = util.getDgSelectedRowsData(dgEl0);
        if(!util.chkListSelected(sd[0], '공통코드를')) return;
        openCrudPopup("v", sd[0].codeUid);
    }

    // * Data Grid
    // const handleGridSelectionChanged = ({selectedRowsData}) => {
    //     if(selectedRowsData.length === 0) { 
    //         setSelectedItem(null); return;
    //     }
    //     setSelectedItem(selectedRowsData[0]);
    // }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Funtions

    const openCrudPopup = (mode, uid) => {
        const url = `/base/codepop?${qs.stringify({mode: mode, uid: uid})}`;
        util.openWindow(url, 600, 360);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {

        console.log("##### renderContent");

        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView>
            }</Observer>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="공통코드 내역"
            >
                {/* <IconButton
                    className="btn btn-isz ico-print"
                />
                <IconButton
                    className="btn btn-isz ico-exldown"
                    marginRight={0}
                /> */}
            </GridTopView>
            <Observer>{() =>
            <DataGridView
                reff={dgEl0}
                dataSource={codeList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={codeListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                // onSelectionChanged={handleGridSelectionChanged}
                onInitPage={(pg, ofs) => {setCurPage(pg); setRowsOffset(ofs)}}
                
                colsWidth={[200, 80, 200, cmn.CW.sortOrder, cmn.CW.yn, null]}
            >
                { cmn.getGridColumn('classNm', '코드유형', renderGridHeaderCell)}
                { cmn.getGridColumn('codeId', '코드', renderGridHeaderCell)}
                { cmn.getGridColumn('codeNm', '코드명', renderGridHeaderCell)}
                { cmn.getGridColumnSortOrder() }
                { cmn.getGridColumnUseYn() }
                { cmn.getGridColumnCmnt() }
            </DataGridView>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default CodePage;
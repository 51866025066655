import React, { useState, useEffect, useContext } from 'react';
import { TextBox, NumberBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    PopupLayout,
    ActionButton,
    CodeSelectBox,
    UseYnSwitchBox,
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  공정 등록 / 수정
 */
const ProcPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    const [procTp, setProcTp] = useState('');
    const [procTpNm, setProcTpNm] = useState('');
    const [procNm, setProcNm] = useState('');
    const [procCd, setProcCd] = useState('');
    const [useYn, setUseYn] = useState('Y');
    const [costRate, setCostRate] = useState(0);
    const [cmnt, setCmnt] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadInfo1 = async() => {
        try {            
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            let r = await cmn.api('getProc', {p:{procUid:uid}});
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            
            setProcTp(data.procTp);
            setProcTpNm(data.procTpNm);
            setProcNm(data.procNm);
            setProcCd(data.procCd);
            setUseYn(data.useYn);
            setCostRate(data.costRate);
            setCmnt(data.cmnt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(procTp, '공정유형'))
                return;
            if(!util.chkMustValue(procCd, '공정코드'))
                return;
            if(!util.chkMustValue(procNm, '공정명'))
                return;

            // 공정코드 Unique 체크

            if(mode === "a"){
                if(util.confirmYn('등록'))
                    addProc();
            } 
            else if(mode === "u") 
            {
                if(util.confirmYn('수정'))
                    upProc();
            }
            else if(mode === "v") 
            {
                if(util.confirmYn('삭제'))
                    delProc();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }

    }

    const addProc = async() => {

        const ps = {
            p: {
                procUid: util.getUUID(),
                comUid: authStore.com.uid,
                procTp: procTp,
                procNm: procNm,
                procCd: procCd,
                useYn: useYn,
                cmnt: cmnt,
                costRate: costRate,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('addProc', ps)
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upProc = async() => {
        
        const ps = {
            p: {
                procUid: uid,
                procTp: procTp,
                procNm: procNm,
                procCd: procCd,
                useYn: useYn,
                cmnt: cmnt,
                costRate: costRate,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('upProc', ps)
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");
        
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delProc = async() => {
        
        const ps = {
            p: {
                comUid: authStore.com.uid,
                procUid: uid,
                delCmnt: '',
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('delProc', ps)
        if(r == null || r === undefined) return;

        util.cmnSucAlert("삭제");
        
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">공정유형</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <CodeSelectBox
                                        value={procTp}
                                        classId={'104'}
                                        placeholder="공정유형을 선택하세요"
                                        onValueChanged={(v) => { 
                                            setProcTp(v);
                                        }}
                                    />
                                :
                                    <span>{procTpNm}</span>
                                }
                            </td>
                            <th className="tit req">공정코드</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={procCd}
                                        showClearButton={true}
                                        placeholder="공정코드를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setProcCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{procCd}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">공정명</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={procNm}
                                        showClearButton={true}
                                        placeholder="공정명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setProcNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{procNm}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon"> 
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={useYn}
                                        onValueChanged={(v)=>{
                                            setUseYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                        </tr>
                        {/* <tr>
                            <th className="tit">임률</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <NumberBox
                                    value={costRate}
                                    format={'#,##0'}
                                    width={'100px'}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setCostRate(e.value);
                                    }} 
                                />
                                :
                                    <span>{costRate}</span>
                                }
                            </td>
                        </tr> */}
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요"
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "공정 등록" 
                : mode === "u" ? "공정 수정"
                : mode === "u" ? "공정 상세보기"
                : "공정" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProcPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    UseYnSwitchBox,
    CodeSelectBox 
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  부서 등록 / 수정
 */
const DeptPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */
    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageload, setPageLoad] = useState(true);

    /** Items */
    const [deptNm, setDeptNm] = useState('');
    const [deptTp, setDeptTp] = useState('');
    const [deptTpNm, setDeptTpNm] = useState('');
    const [useYn, setUseYn] = useState('Y');
    const [loginUrlTp, setLoginUrlTp] = useState('');
    const [loginUrlTpNm, setLoginUrlTpNm] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [mail, setMail] = useState('');
    const [cmnt, setCmnt] = useState('');
    const [uptUserUid] = useState(authStore.user.uid);
    

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            //권한 조회
            await authStore.loadPopPage(props);

            if(mode === 'u' || mode === 'v')
            {
                await loadInfo1();
            }

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    //부서정보 상세 조회
    const loadInfo1 = async() => {
        try {            
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api('getDept', {p:{deptUid: uid}});
            if(r == null || r === undefined) return;

            console.log(r);
            const d0 = r.d0[0];
            
            setDeptNm(d0.deptNm);
            setDeptTp(d0.deptTp);
            setDeptTpNm(d0.deptTpNm);
            setUseYn(d0.useYn);
            setLoginUrlTp(d0.loginUrlTp);
            setLoginUrlTpNm(d0.loginUrlTpNm);
            setPhone(d0.phone);
            setFax(d0.fax);
            setMail(d0.mail);
            setCmnt(d0.cmnt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(deptTp, '부서유형'))
                return;
            if(!util.chkMustValue(deptNm, '부서명'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addDept();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upDept();
            }else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delDept();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // 부서 등록
    const addDept = async() => {
        const ps = {
            p: {
                    deptUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    deptNm: deptNm,
                    deptTp: deptTp,
                    useYn: useYn,
                    loginUrlTp: loginUrlTp,
                    phone: phone,
                    fax: fax,
                    mail: mail,
                    cmnt: cmnt,
                    uptUserUid: uptUserUid
                }
        }

        const r = await cmn.api("addDept", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('등록'); 

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // 부서 수정
    const upDept = async() => {
        const ps = {
            p: {
                deptUid: uid,
                comUid: authStore.com.uid,
                deptNm: deptNm,
                deptTp: deptTp,
                useYn: useYn,
                loginUrlTp: loginUrlTp,
                phone: phone,
                fax: fax,
                mail: mail,
                cmnt: cmnt,
                uptUserUid: uptUserUid
            }
        }
        
        const r = await cmn.api("upDept", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('수정'); 
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // 부서 삭제
    const delDept = async() => {
        const ps = {
            p: {
                deptUid: uid,
                comUid: authStore.com.uid,
            }
        }
        
        const r = await cmn.api("delDept", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('삭제'); 
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">부서유형</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={deptTp}
                                        classId={'701'}
                                        showClearButton={false}
                                        placeholder="부서유형을 선택하세요"
                                        onValueChanged={(v) => { 
                                            setDeptTp(v);
                                        }}
                                    />
                                    :
                                    <span>{deptTpNm}</span>
                                }
                            </td>
                            <th className="tit req">부서명</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={deptNm}
                                        showClearButton={true}
                                        placeholder="부서명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setDeptNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{deptNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">부서 전화번호</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={phone}
                                        showClearButton={true}
                                        placeholder="전화번호를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setPhone(e.value);
                                        }} 
                                    />
                                :
                                    <span>{phone}</span>
                                }
                            </td>
                            <th className="tit">부서 FAX</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={fax}
                                        showClearButton={true}
                                        placeholder="FAX를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setFax(e.value);
                                        }} 
                                    />
                                :
                                    <span>{fax}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">부서 이메일</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={mail}
                                        showClearButton={true}
                                        placeholder="이메일을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMail(e.value);
                                        }} 
                                    />
                                :
                                    <span>{mail}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={useYn}
                                        onValueChanged={(v)=>{
                                            setUseYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">로그인 경로</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={loginUrlTp}
                                        classId={'709'}
                                        showClearButton={true}
                                        placeholder="로그인 경로를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setLoginUrlTp(v);
                                        }}
                                    />
                                : 
                                    <span>{loginUrlTpNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요"
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "부서 등록" 
                : mode === "u" ? "부서 수정"
                : mode === "v" ? "부서 보기"
                : "" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default DeptPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    EditGridView,
    PopupLayout, 
    ActionButton,
    DayDateBox,
    UserSelectBox,
    CancelButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  납품 등록 / 수정
 */
const InOutPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */
    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,
        ouid = ls.ouid,
        uid = ls.uid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [lotListOutsInItem, setLotListOutsInItem] = useState([]);

    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState('');
    const [inDy, setInDy] = useState(util.getNow());
    const [totInAmt, setTotInAmt] = useState(0);
    const [outsInPrice, setOutsInPrice] = useState(0);
    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [procNm, setProcNm] = useState('');
    const [prodProcUid, setProdProcUid] = useState('');
    const [lastYn, setLastYn] = useState('');
    const [isDel, setIsDel] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadInfo1 = () => {
        try {            
            if(mode === "a") {
                loadLotItems(ouid);
            }else if(mode === 'v')
                loadOutsInInfo();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadOutsInInfo = async(e) => {
        if(util.getChkNull(uid) === '') return;

        let r = await cmn.api('getOutsIn', {p:{outsInUid:uid}});
        if(r == null || r === undefined) return;

        let data = r.d0;

        setProdId(data[0].prodId);
        setProdNm(data[0].prodNm);
        setProcNm(data[0].procNm);
        setBizUid(data[0].bizUid);
        setBizNm(data[0].bizNm);
        setUserNm(data[0].userNm);
        setIsDel(data[0].isDel);
        setOutsInPrice(data[0].outsInPrice);
        setTotInAmt(data[0].inAmt);
        setIsDel(data[0].isDel);
        setLastYn(data[0].lastYn);
        setProdProcUid(data[0].prodProcUid);
        setProdUid(data[0].prodUid);

        let lotListOutsInItem_ = [];
        _.forEach(data, (v, k) => {
            lotListOutsInItem_.push({
                lot: v.lot
                ,inAmt: v.inAmt
                ,cmnt: v.cmnt
            })
        })
        setLotListOutsInItem(lotListOutsInItem_);

    }

    const loadLotItems = async(e) => {
        let ps = {p: {outsOutUid: e}};

        let r = await cmn.api('getLotListOutsIn', ps);
        if(r == null || r === undefined) return;

        let data = r.d0[0];
        let data2 = r.d1;

        setProdUid(data.prodUid);
        setProdId(data.prodId);
        setProdNm(data.prodNm);
        setProcNm(data.procNm);
        setBizNm(data.bizNm);
        setBizUid(data.bizUid);
        setProdProcUid(data.prodProcUid);
        setLastYn(data.lastYn);
        setOutsInPrice(data.procPrice)
        

        let lotListOutsInItem_ = [];

        _.forEach(data2, (v, k) => {
            lotListOutsInItem_.push({
                lot: v.lot
                ,preAmt : v.preAmt
                ,compAmt: v.compAmt
                ,possAmt : v.possAmt
                ,inAmt: 0
                ,cmnt: ''
            })
        })
        setLotListOutsInItem(lotListOutsInItem_);
    }

    const loadTotInAmt = () => {
        setTotInAmt(_.sumBy(lotListOutsInItem, 'inAmt'));
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(mode === "a")
            { 
                if(!util.chkMustValue(userUid, '입고자'))
                return;
                // if(!util.chkMustValue(inAmt, '입고수량', 'cnt'))
                //     return;
                if(!util.chkMustValue(_.sumBy(lotListOutsInItem, 'inAmt'), '입고수량', 'cnt'))
                    return;

                // 추가 Validation Check
                let msg = '';
                _.forEach(lotListOutsInItem, (v,k) => {
                    if(v.inAmt > v.possAmt) 
                        msg = '[입고수량]이 [입고가능수량] 보다 큽니다.';
                });

                if(msg !== '')
                {
                    util.cmnAlert(msg);
                    return;
                }

                if(util.confirmYn("등록"))
                    addInOut();
            }
            else if(mode === "v") 
            {
                delInOut(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addInOut = async() => {

        let lotListOutsInItem_ = [];

        let groupUid = util.getUUID()
        _.forEach(lotListOutsInItem, (v,k) => {
            if(v.inAmt > 0) {
                lotListOutsInItem_.push(
                    {
                        outsInUid: util.getUUID(),
                        comUid: authStore.com.uid,
                        bizUid: bizUid,
                        prodUid: prodUid,
                        prodProcUid: prodProcUid,
                        inDy: util.getDateToString('d',inDy),
                        outsInPrice: outsInPrice,
                        userUid: userUid,
                        lastYn: lastYn,
                        uptUserUid: authStore.user.uid,
                        lot: v.lot,
                        inAmt: v.inAmt,
                        groupUid: groupUid,
                        cmnt: util.getChkNull(v.cmnt)
                    }
                );
            }
        });

        const ps = {
            p: [...lotListOutsInItem_]
        }

        console.log(ps);

        const r = await cmn.api("addOutsIn", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delInOut = async(delCmnt) => {
        const ps = {
            p: {
                outsInUid: uid,
                delCmnt: delCmnt,
                comUid: authStore.com.uid,
                uptUserUid: authStore.user.uid,
                lot: lotListOutsInItem[0].lot,
                inAmt: lotListOutsInItem[0].inAmt,
                prodProcUid: prodProcUid,
                prodUid:prodUid,
                lastYn: lastYn
            }
        }

        console.log(ps);
        const r = await cmn.api("delOutsIn", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("취소");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "v" ? "취소완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                {mode === "v" ?
                    <CancelButton
                        title={completeButtonTitle}
                        isDel={isDel}
                        isDelMsg={'공정이 진행중이므로 취소할 수 없습니다. \n진행된 공정을 취소한 후 취소 가능합니다.'}
                        handleClickComplete={(e) => {
                            handleClickComplete(e)
                        }} 
                    />
                    :
                    <ActionButton 
                        onClick={handleClickComplete}
                        title={completeButtonTitle}
                        marginRight={0}
                        show={true}
                    />
                }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">공정</th>
                            <td className="tcon">
                                <span>{procNm}</span>
                            </td>
                            <th className="tit">입고업체</th>
                            <td className="tcon">
                                <span>{bizNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">입고일</th>
                            <td className="tcon">
                                {
                                    mode === "a" ?
                                    <DayDateBox
                                        value={inDy}
                                        onValueChanged={(e) => {
                                            setInDy(e.value);
                                        }}                    
                                    />
                                : 
                                    <span>{inDy}</span>
                                }
                            </td>
                            <th className="tit req">입고자</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        placeholder="입고자를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                :
                                    <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">입고단가</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <NumberBox
                                        width={80}
                                        format={'#,##0'}
                                        value={outsInPrice}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setOutsInPrice(e.value);
                                        }}
                                    />   
                                :
                                    <span>{util.getNumComma(outsInPrice)}</span>
                                }
                            </td>
                            <th className="tit">입고수량</th>
                            <td className="tcon">
                                <span>{totInAmt}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="pop-grid">
                <div className="clearfix">
                <>
                { mode === 'a' ? 
                    <EditGridView
                        dataSource={lotListOutsInItem}   // store
                        gridHeight={180}
                        colsWidth={[120, 100, 100, 100, 100, null]}
                        onRowUpdated={(e) => {loadTotInAmt();}}
                    >
                        { cmn.getGridColumn('lot', 'Lot')}
                        { cmn.getGridColumnNumCommaType('preAmt', '입고대기수량')}
                        { cmn.getGridColumnNumCommaType('compAmt', '기입고수량')}
                        { cmn.getGridColumnNumCommaType('possAmt', '입고가능수량')}
                        <Column 
                            alignment={'center'}
                            dataField={'inAmt'} 
                            caption={'입고수량'} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'cmnt'} 
                            caption={'비고'} 
                        />
                    </EditGridView>
                    :
                    <EditGridView
                        dataSource={lotListOutsInItem}   // store
                        gridHeight={180}
                        colsWidth={[150, 120, null]}
                        onRowUpdated={(e) => {loadTotInAmt();}}
                    >
                        { cmn.getGridColumn('lot', 'Lot')}
                        { cmn.getGridColumnNumCommaType('inAmt', '입고수량')}
                        { cmn.getGridColumnCmnt()}
                    </EditGridView>
                }
                </>
                </div>
            </div>      

            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "입고 등록" 
                : mode === "u" ? "입고 수정"
                : "입고" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default InOutPopPage;
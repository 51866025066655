import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, BizSelectBox, SrchButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  고객매츌현황
 */
const OrderInAnalysisPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate('-1','M'));
    const [endDy, setEndDy] = useState(Date.now);
    const [prodNm, setProdNm] = useState('');
    const [bizUid, setBizUid] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "입고일",
                width: "200px",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
                </>
            },
            {   title: "협력사",
                width: "200px",
                item: 
                <BizSelectBox
                    value={bizUid}
                    bizTp={['70203']}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        setBizUid(v);
                    }}
                />     
            },
            {   title: "품명",
                item: 
                <TextBox 
                    width={"200px"}
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            }
        ]
    ];
    /* #endregion */
    
    // * 목록 선택 코드 UID
    const [deliUid, setDeliUid] = useState('');

    // * Grid
    const [orderInList, setOrderInList] = useState([]);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, bizUid, startDy, endDy]);   

    /* ====================
     *  Load
     * ==================== */

   
    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        if(util.getChkNull(bizUid) === '') return;

        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy), 
                endDy: util.getDateToString('d',endDy), 
                bizUid: bizUid, 
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getOrderInList", ps );
        if(r === undefined || r == null) return;

        setOrderInList(r.d0)
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setDeliUid(""); return;
        }
        setDeliUid(selectedRowsData[0].deliUid);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    // const handleClickPage = (p) => {
    //     setCurPage(p);
    //     const offset = (p-1)*rowsLength;
    //     setRowsOffset(offset);
    // }

    // const handleChangePageSize = (e) => {
    //     setRowsLength(e.value);
    // }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "buyDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    
    // * Functions

    // const openCrudPopup = (mode, deliUid) => {
    //     // page mode: v(View), a(Add), u(Update)
    //     const url = `/deli/delipop?${qs.stringify({mode: mode, suid: deliUid})}`;        
    //     util.openWindow(url, 600, 360);    
    // }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                    marginRight={0}
                />
            </ListTopView>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="업체별납기현황"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>

            {/** GRID */}
            <DataGridView            
                reff={grid1}
                dataSource={orderInList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H20}

                // paging
                pagingable={false}
          
                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}      

                // excel
                exlnm={'업체별납기현황'}
                customizeExcelCell={customizeExcelCell}               

                colsWidth={[cmn.CW.bizNm, cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd, 100
                    , cmn.CW.unit, cmn.CW.amt, cmn.CW.amt, 100, null]}
            >
                { cmn.getGridColumn('bizNm', '업체명', renderGridHeaderCell)}
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell)}
                { cmn.getGridColumn('prodMattTpNm', '재질', renderGridHeaderCell)}
                { cmn.getGridColumn('unitTpNm', '단위', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('orderAmt', '발주량') }
                { cmn.getGridColumnNumCommaType('inAmt', '입고량') }
                { cmn.getGridColumnPer('inPer', '입고율') }
                <Column/>

                <Summary>
                    <TotalItem
                        column={'orderAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                     <TotalItem
                        column={'inAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                </Summary>
            </DataGridView>
            </>
        )

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default OrderInAnalysisPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  부서 관리
 */
const DeptPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
   
    // * Control Items (* Combo ...)

    // * 검색
    const [deptTp, setDeptTp] = useState('');
    const [deptNm, setDeptNm] = useState('');
    const [useYn, setUseYn] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "부서유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={deptTp}
                    classId={'701'}
                    placeholder="부서유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setDeptTp(v);
                    }}
                />
            },
            {   title: "부서명",
                width: "200px",
                item: 
                <TextBox 
                    value={deptNm}
                    showClearButton={true}
                    placeholder="부서명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setDeptNm(e.value);
                    }}
                />          
            },
            {   title: "사용여부",
                item: 
                <UseYnSelectBox
                    value={useYn}
                    onValueChanged={(e)=>{
                        setUseYn(e.value);
                    }}
                />
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [deptList, setDeptList] = useState([]);
    const [deptListTot, setDeptListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, deptTp, useYn]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            // 권한조회
            await authStore.loadPage(props);
            
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                deptTp: deptTp, 
                deptNm: deptNm, 
                useYn: useYn, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }
        
        const r = await cmn.api("getDeptList", ps );
        if(r === undefined || r == null) return;

        setDeptList(r.d0)
        setDeptListTot(r.tot);
    }

    /* ====================
     *  Handle
     * ==================== */

    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // 부서 등록
    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    // 부서 수정
    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.deptUid);
        }
    }

    // 부서 상세보기
    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.deptUid);
        }
    }

    // * Data Grid
    // Row 클릭 
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    // 데이타 정렬
    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    // Paging 클릭
    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    /* ====================
     *  Functions
     * ==================== */

    const openCrudPopup = (mode, deptUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/deptpop?${qs.stringify({mode: mode, uid: deptUid})}`;        
        util.openWindow(url, 600, 360);    
    }


    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <Observer>{() =>
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView title="부서 내역">
            </GridTopView>     
            <DataGridView            
                dataSource={deptList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={deptListTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}                

                colsWidth={[100, 150, 120, 120, 150, 70,  null]}
            >
                { cmn.getGridColumn('deptTpNm', '부서유형', renderGridHeaderCell) }   
                { cmn.getGridColumn('deptNm', '부서명', renderGridHeaderCell) }   
                { cmn.getGridColumn('phone', '부서전화번호') }   
                { cmn.getGridColumn('fax', '부서FAX') }   
                { cmn.getGridColumn('mail', '부서이메일') }   
                { cmn.getGridColumnUseYn() }              
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
            }</Observer>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default DeptPage;
import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import _ from 'lodash';
import JsBarcode from 'jsbarcode';

/** components */
import {PrintLayout, ActionButton} from '../../components';

/** stores & lib */
import { util, cmn } from '../../lib';
/** 
 *  공정이동표
 */
const ProcFlowTagPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

     /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        lot = ls.lot,
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    /** Items */
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [prodMattTpNm, setProdMattTpNm] = useState('');
    const [workAmt, setWorkAmt] = useState('');
    const [procFlowItems, setProcFlowItems] = useState([]);

    
    // const [useYn, setUseYn] = useState('N');
    // const [plcCd, setPlcCd] = useState('');
    // const [equpImgUid, setEqupImgUid] = useState(util.getUUID());
    // const [chkImgUid, setChkImgUid] = useState(util.getUUID());
    // const [equpUploadFiles, setEqupUploadFiles] = useState([]);
    // const [chkUploadFiles, setChkUploadFiles] = useState([]);

    // const [cmnt, setCmnt] = useState('');

    // const [cycleDatas, setCycleDatas] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadBarcode();
    },[procFlowItems]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
        loadInfo1();
    }

    const loadBarcode = async() => {
        _.times(procFlowItems.length, (n) => {
            console.log(procFlowItems);
            JsBarcode(`#bcd-proc-${n}`, procFlowItems[n].procCd, { width: 1, height: 24, fontSize: 10, margin: 0});
        });
    }
    
    const loadInfo1 = async() => {
      //  alert(uid);
       // alert(lot);
        if(util.getChkNull(uid) === '' || util.getChkNull(lot) === '') return;

        let ps = {p: 
                    {
                        prodUid: uid
                        ,lot: lot
                    }           
                };

        const r = await cmn.api("getProcFlow", ps );
        if(r === undefined) return;
         
        //console.log(r);

        let dataList = r.d0;
        let data = r.d0[0];
         
        setProcFlowItems(dataList);

        setProdId(data.prodId);
        setProdNm(data.prodNm);
        setProdStnd(data.prodStnd);
        setProdMattTpNm(data.prodMattTpNm);
        setWorkAmt(data.firstAmt);

        JsBarcode("#bcd-lot", data.lot, { width: 1.2, height: 60, fontSize: 14 });
        JsBarcode("#bcd-wknum", data.workNum, { width: 1, height: 20, fontSize: 10, margin: 0 });


     }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        printPage();
    }

    const printPage = async() => {

        window.print();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        
        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div className="print_type"
                style={{ padding: "30px 20px 0 20px" }}
            >
                <table>
                    <colgroup>
						<col width={120} />
						<col width={306}/>
                        <col width={100} />
						<col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 120 }}
                            >
                            </td>
                            <td className="tcon"
                                style={{ fontSize: "26px", fontWeight: "bold" }}
                            >
                                {`공 정 이 동 표`}
                            </td>
                            <th className="tit">LOT NO</th>
                            <td className="tcon">
                                <svg id="bcd-lot"></svg>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
                <table>
                    <colgroup>
                        <col width={100} />
						<col width={163} />
						<col width={100} />
						<col width={163} />
                        <col width={100} />
						<col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit"
                                style={{height: "46px"}}
                            >
                                품명
                            </th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">작업<br />지시량</th>
                            <td className="tcon">
                                <span>{workAmt}</span>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit"
                                style={{height: "46px"}}
                            >
                                규격
                            </th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                            <th className="tit">재질</th>
                            <td className="tcon">
                                <span>{prodMattTpNm}</span>
                            </td>
                            <th className="tit">작업번호</th>
                            <td className="tcon">
                                <svg id="bcd-wknum"></svg>
                            </td>
                        </tr>                         
                    </tbody>
                </table>
                <table>
                    <colgroup>
                        <col width={70} />
						<col width={150} />
                        <col />
						<col width={120} />
						{/* <col width={90} /> */}
                        <col width={100} />
                        <col width={100} />
                        <col width={100} />						
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: "46px"}} >No.</th>
                            <th className="tit">공정</th>
                            <th className="tit">공정명</th>
                            <th className="tit">설비/업체</th>
                            {/* <th className="tit">작업수량</th> */}
                            <th className="tit">작업수량</th>
                            <th className="tit">작업일</th>
                            <th className="tit">작업자</th>
                        </tr>
                        {
                            _.map(procFlowItems, (v, k) => {

                                const bcdId = `bcd-proc-${k}`;

                                console.log(v.firstAmt);
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: "54px"}}
                                        >
                                            {k+1}</td>
                                        <td className="tcon">
                                            <svg id={bcdId}></svg>                                           
                                        </td>
                                        <td className="tcon">{v.procNm}</td>
                                        <td className="tcon">{v.bizOrEqupNm}</td>                                        
                                        {/* <td className="tcon">{util.getChkNull(v.manuAmt, 0) == 0 ? '' : util.getNumComma(v.manuAmt) }</td> */}
                                        <td className="tcon">{util.getChkNull(v.ingStockAmt, 0) == 0 ? '' : util.getNumComma(v.ingStockAmt)}</td>
                                        <td className="tcon">{util.getDateStrSplit('d', v.workDy)}</td>
                                        <td className="tcon">{v.userNm}</td>
                                    </tr>    
                                )

                            })
                        }         
                        {
                            _.times(14 - procFlowItems.length, (n) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: "54px"}}
                                        >
                                            </td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        {/* <td className="tcon"></td> */}
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                    </tr>    
                                )
                            })
                        }
                    </tbody>
                </table>                
            </div>

            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"공정 이동표 출력"}
            content = {renderContent()}
        />
        </>
    )
}

export default ProcFlowTagPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { NumberBox, TextBox, SelectBox, TextArea } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, ActionButton, CodeSelectBox, GridTopView, SubActionButton, DataGridView, DayDateBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  제품 이력 등록 / 수정
 */
const ProdHisPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 목록 선택 코드 UID

    const [cmnt, setCmnt] = useState('');
    const [chgDy, setChgDy] = useState(util.getNow());
    const [chgCmnt, setChgCmnt] = useState('');
   
    // * Grid
    const [prodHisList, setProdHisList] = useState([]);
    const [prodHisListTot, setProdHisListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    
    useEffect(() => {
        loadPage();
    },[]);
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);

            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadInfo1 = async() => {
        try {            
            const ps =  {
                p: {
                    prodUid: uid, 
                                    
                    sortCol: sortItem.col,
                    sortTp: sortItem.tp,
                    offset: rowsOffset,
                    limit: rowsLength
                }
            }
    
            const r = await cmn.api("getProdHisList", ps );
            if(r === undefined || r == null) return;
    
            setProdHisList(r.d0)
            setProdHisListTot(r.tot);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const addProdHis = async() => {

        // validation 체크
        if(!util.chkMustValue(chgDy, '개정일자'))
            return;
        if(!util.chkMustValue(chgCmnt, '개정이력'))
            return;

        if(util.confirmYn("등록"))
        {
            const ps = {
                p: {
                    prodUid: uid,
                    chgDt: chgDy,
                    chgCmnt: chgCmnt,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid
                }
            }
    
            console.log(ps);
            const r = await cmn.api('addProdHis', ps);
            if(r == null || r === undefined) return;
    
            util.cmnSucAlert("등록");
    
            setChgDy(util.getNow());
            setChgCmnt('');
            setCmnt('');

            await loadInfo1();

            // util.clickOpenerElement("srchButton");

            // window.close();
        }
        
    }

    const delProdHis = async() => {
        
        if(util.chkListSelected(selectedItem)){
            if(util.confirmYn("삭제")){
                const ps = {p: { 
                    prodHisUid: selectedItem.prodHisUid
                }}
    
                const r = await cmn.api('delProdHis', ps);
                if(r == null || r === undefined) return;
    
                util.cmnSucAlert("삭제");
    
                await loadInfo1();
                // util.clickOpenerElement("srchButton");
                
                // window.close();
            }
        }
       
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }
    

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={addProdHis}
                    title={'등록'}
                    show={true}
                />
                <ActionButton 
                    onClick={delProdHis}
                    title={'삭제'}
                    marginRight={0}
                    show={true}
                />
            </div>
            <GridTopView title="이력 정보"></GridTopView>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" width={'18%'}/>
						<col className="tcon" width={'37%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">개정일자</th>
                            <td className="tcon" colSpan={3}>
                                <DayDateBox
                                    value={chgDy}
                                    onValueChanged={(e) => {
                                        setChgDy(e.value);
                                    }}                    
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">개정이력</th>
                            <td className="tcon" colSpan={3}>
                                <TextArea
                                    minHeight={80}
                                    // width={500}
                                    value={chgCmnt}
                                    placeholder="개정 이력을 입력하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setChgCmnt(e.value);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                       
                    </tbody>
                </table>
            </div>

                <GridTopView title="이력 관리">   
                    
                </GridTopView>

                <DataGridView
                    dataSource={prodHisList}   // store

                    reff={grid1}         
                    gridHeight={500}
                    showNo={false}
                    // paging
                    page={curPage}
                    total={prodHisListTot} 
                    itemsPerPage={rowsLength}
                    onClickPage={handleClickPage}
                    onChangePageSize={handleChangePageSize}

                    // select
                    onSelectionChanged={handleGridSelectionChanged}        

                    colsWidth={[cmn.CW.dy, 300,null]}
                >
                    { cmn.getEditGridColumnDate('chgDy', '개정일자') }
                    { cmn.getEditGridColumn('chgCmnt', '개정사유 및 내용') }
                    { cmn.getEditGridColumn('cmnt', '비고') }
                </DataGridView>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                "제품개정이력" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProdHisPopPage;
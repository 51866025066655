import React, { useState, useEffect, useContext } from 'react';
import { TextBox, SelectBox } from 'devextreme-react';
import qs from 'query-string';
import moment from 'moment';


/** components */
import {
    InspSampBox, DateTimeBox, FileUploadBox, TimeBox,
    CodeSelectBox, InspIdxSelectBox, LotSelectBox, WorkNumSelectBox, EqupSelectBox,
    ProdSearchBox, ProdProcSelectBox, UserSelectBox, PopupLayout, ActionButton,
    SubActionButton,
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공정검사 등록
 */
const ProcInspPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),

        mode = ls.mode,   // mode: v(View), a(Add), u(Update)

        uid = ls.uid,  // proc_insp_his_uid
        
        pdy = ls.pdy,    // insp_dy
    //    pduid = ls.pduid, // prod_uid
        ppuid = ls.ppuid,    // prod_proc_uid

        pwn = ls.pwn,    // work_num
        plot = ls.plot,  // lot
    //    peuid = ls.peuid, // equp_uid

        ptp = ls.ptp // page type 현장화면: site

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */    
    const [pageLoad, setPageLoad] = useState(true);

    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [procNm, setProcNm] = useState('');
    const [prodProcUid, setProdProcUid] = useState(ppuid);
    const [prodStnd, setProdStnd] = useState('');

    const [workNum, setWorkNum] = useState(pwn);
    const [lot, setLot] = useState(plot);
    const [preLot, setPreLot] = useState(plot);
    
    
    const [lineUid, setLineUid] = useState('');
    const [lineNm, setLineNm] = useState('');
    const [equpUid, setEqupUid] = useState('');
    const [equpNm, setEqupNm] = useState('');    
    const [inspTp, setInspTp] = useState(ptp === 'site' ? '10701' : '');
    const [inspTpNm, setInspTpNm] = useState('');
    const [procInspHisUid, setProcInspHisUid] = useState(uid);
    const [inspDt, setInspDt] = useState(util.getNow('m'));
    const [inspDy, setInspDy] = useState(util.getChkNull(pdy, util.getNow('', '')));    
    const [inspUserUid, setInspUserUid] = useState(authStore.user.uid);
    const [inspUserNm, setInspUserNm] = useState('');
    const [inspIdx, setInspIdx] = useState('1');
    const [actDtl, setActDtl] = useState('');

    const [passYn, setPassYn] = useState('N');
    const [inspItems, setInspItems] = useState([]);

    const [atchFileGrpUid, setAtchFileGrpUid] = useState(util.getUUID());
    const [atchFiles, setAtchFiles] = useState([]);

    const [lotTp, setLotTp] = useState('71002');
    const [dyNtTp, setDyNtTp] = useState('80101');

    const [procInspItem, setProcInspItem] = useState([]);
    

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadQualInfo();
    },[procInspHisUid]);

    useEffect(() => {
        !pageLoad && loadInspItems();
    },[prodProcUid, inspTp]);

    useEffect(() => {
        !pageLoad && loadProcInspItem();
    },[prodProcUid]);

    useEffect(() => {
        !pageLoad && loadWorkNum();
    },[inspDt]);
    
    // useEffect(() => {
    //     !pageLoad && loadWorkNum();
    // },[workNum]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadQualInfo();
            await loadInspItems();

            await loadWorkNum();
            await loadProcInspItem();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadQualInfo = async() => {
        try {   
            if(mode !== 'u') return;
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api("getProcInspHis", {p: {procInspHisUid: uid}});
            if(r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
        //    const d2 = r.d2;

        //    console.log('d0---------------->', d0);

            setProdUid(d0.prodUid);
            setProcInspHisUid(d0.procInspHisUid);
            setProdProcUid(d0.prodProcUid);

            setInspDy(d0.inspDy);
            setInspDt(d0.inspDt);

            setInspUserUid(d0.inspUserUid);
            setInspUserNm(d0.inspUserNm);
        
            setWorkNum(d0.workNum);
            setLot(d0.lot);        
            
            setProdId(d0.prodId);
            setProdNm(d0.prodNm);
            setProdStnd(d0.prodStnd);
        
            setProcNm(d0.procNm);

            setEqupUid(d0.equpUid);
            setEqupNm(d0.equpNm);
                
            setInspIdx(d0.inspIdx);
            setInspTp(d0.inspTp);
            setInspTpNm(d0.inspTpNm);

            setActDtl(d0.actDtl);
            setPassYn(d0.passYn);

            setInspItems(d1);

            setAtchFileGrpUid(d0.atchFileGrpUid == null ? util.getUUID() : d0.atchFileGrpUid);
            setAtchFiles(r.f1);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }   
    }

    /** 검사항목 */
    const loadInspItems = async() => {
        try {  
            if(mode !== 'a') return;

            if(util.getChkNull(prodProcUid) === '' || util.getChkNull(inspTp) === '' ){
                setInspItems([]);
                return;
            }

            const ps = {p: {prodProcUid: prodProcUid, inspTp: inspTp}} 
            const r = await cmn.api("getProcInspHisInspItems", ps);
            if(r === undefined || r == null) return;

            const d = r.d0;
            setInspItems(d);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }   
    }

    const loadWorkNum = async() => {
      //  setProcInspItem([]);
        if(mode !== 'a') return;
      
        setInspTp('');

        try {
            if(util.getChkNull(workNum) === '') return;
            
            let para = {p: {workNum: workNum}};

            let r = await cmn.api('getWorkNum', para);
            if(r == null || r.d0.length == 0) {
                util.cmnAlert('등록된 정보가 없습니다.');
                return;
            }

            let data = r.d0[0];
            setProdUid(data.prodUid);
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setProdStnd(data.prodStnd);
            setEqupUid(data.equpUid);
            setLineUid(data.lineUid);
            
            setLotTp(data.lotTp);
            
            let inspDy_ = util.getDateToString('d', inspDt, '-');
            let format_ = 'YYYY-MM-DD HH:mm';
            let dyStartTm_ = moment(`${inspDy_} ${util.getDateStrSplit('t' , data.dyStartTm, ':')}`).format(format_);
            let dyEndTm_ = moment(`${inspDy_} ${util.getDateStrSplit('t' , data.dyEndTm, ':')}`).format(format_);
            let getDate_ = util.getNow('m');
            
            //주간일 경우 80101 야간일 경우 80102을 설정함
            setDyNtTp(moment(getDate_).isBetween(dyStartTm_, dyEndTm_) ? '80101' : '80102');

            //  alert(`${workNum}-${util.getAddDate('-1', 'd', 'YYMMDD')}-${data.ntChar}`);
            let preLot = cmn.getCrtDyLotNum(workNum, data.lotTp, data.dyNtUseYn, dyStartTm_, dyEndTm_, data.dyChar, data.ntChar, inspDt)
            setPreLot(preLot);

          // 넘어오는 Lot 가 없을 경우 새로 생성함
            if(util.getChkNull(plot) === '')
                setLot(data.lot);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    //공정별 검사 타입
    const loadProcInspItem = async() => {
        try{
            if(util.getChkNull(prodProcUid) === '' ){
                setProcInspItem([]);
                return;
            }
    
           // alert(prodProcUid);
            const ps = {p: {prodProcUid: prodProcUid}} 
            const r = await cmn.api("getProcInspComboPP", ps);
            if(r === undefined || r == null) return;

            const d = r.d0;
            setProcInspItem(d);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        } 
      
    };
    
    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(prodUid, '제품')) return;
            if(!util.chkMustValue(workNum, '작지번호')) return;
            if(!util.chkMustValue(prodProcUid, '공정')) return;
            if(!util.chkMustValue(inspTp, '검사유형')) return;

            if(!util.confirmYn('등록완료')) return;
            addProcInspHis();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addProcInspHis = async() => {
        const lotPara = {p:{comUid: authStore.com.uid, workNum: workNum, 
            lot: util.getChkNull(lot) === '' ? preLot : lot}};
        const ps = {
            p: {
                procInspHisUid: util.getChkNull(procInspHisUid) === '' ? util.getUUID() : procInspHisUid,
                isUp: util.getChkNull(procInspHisUid) === '' ? false : true,
                comUid: authStore.com.uid,
                prodProcUid: prodProcUid,
                inspDy: util.getDateToString('d',inspDy),
                inspDt: `${util.getDateStrSplit('d', inspDy)} ${util.getDateToString('t', inspDt, ':')}`,
                inspIdx: util.getChkNull(lot) === "" ? 1 : inspIdx,
                inspTp: inspTp,
                inspProgStt: '30603',
                inspUserUid: inspUserUid,
                lot: await cmnStore.getLotCrt(lotPara),
                passYn: passYn,
                condPassYn: 'N',
                actDtl: actDtl,
                equpUid: util.getChkNull(equpUid),
                inUid: '',
                atchFileGrpUid: atchFileGrpUid,
                uptUserUid: authStore.user.uid,
                bads: null,
                dtls: inspItems,
                atchFiles: atchFiles
            }
        }

        console.log(ps);

        const r = await cmn.api("addProcInspHis", ps);
        if(r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">작업번호</th>
                            <td className="tcon">
                            {
                                mode === 'a' ?
                                <>
                                    <div>
                                        <ul className="ul-row" style={{width: '100%'}}>
                                            <li style={{width: 'calc(100% - 80px)'}}>
                                            <TextBox
                                                width={'100%'}         
                                                value={workNum}
                                                showClearButton={true}
                                                placeholder={'작업번호로 검색하세요.'}
                                                valueChangeEvent={'keyup'}
                                                onValueChanged={(e)=>{
                                                    setWorkNum(e.value);
                                                }} 
                                                onEnterKey={(e)=>{
                                                    loadWorkNum();
                                                    setProdProcUid('');
                                                }} 
                                            />   
                                            </li>
                                            <li className="fl-r" style={{width: '80px'}}>                        
                                                <SubActionButton 
                                                    title="검색"
                                                    type="1"
                                                    marginRight={0}
                                                    show={true}
                                                    onClick={(e)=>{
                                                        loadWorkNum();
                                                        setProdProcUid('');
                                                    }} 
                                                />
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </>  
                                :
                                <span>{workNum}</span>
                            }
                            </td>
                            <th className="tit">Lot</th>
                            <td className="tcon" colSpan={3}>
                               {
                                mode === "a" ?
                                    <LotSelectBox
                                        value={lot}
                                        preValue={preLot}
                                        workNum={workNum}
                                        placeholder={'신규 Lot 발급'}
                                        onValueChanged={(v) => { 
                                            setLot(v); 
                                        }}
                                    />
                                    :
                                    <span>{lot}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                {prodNm}
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                {prodId}
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                {prodStnd}
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">공정</th>
                            <td className="tcon">
                            {
                                mode === 'a' ?
                                <ProdProcSelectBox
                                    value={prodProcUid}
                                  //  inYn={'Y'} // 사내공정 데이터만 조회
                                    procTp={'10402'} // 출하 공정은 제외하고 조회
                                    prodUid={prodUid}
                                    onLoadInfo={(o) => {
                                        //setLastYn(util.getVal(o, 'lastYn'));
                                        //setProcPrice(util.getVal(o, 'procPrice'));
                                    }} 
                                    onValueChanged={(v) => { 
                                        setProdProcUid(v);
                                    }}
                                />
                                :
                                <span>{procNm}</span>
                            }
                            </td>
                            <th className="tit">설비</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                    <EqupSelectBox
                                        width="200px"
                                        lineYn={authStore.com.isLine ? 'Y' : 'N'}
                                        lineValue={lineUid}
                                        value={equpUid}
                                        mode={mode}
                                        onLoadInfo={(o) => {}} 
                                        onValueChanged={(v) => {
                                            setEqupUid(v);
                                        }}
                                    />   
                                    :
                                    // <span>{`${lineNm}${lineNm === '' ? ' / ' : ''}${equpNm}`}</span>
                                    <span>{lineNm} {lineNm === '' ? '' : ' / '} {equpNm}</span>
                                }
                            </td>
                        </tr>                          
                        {/* <tr>
                            <th className="tit req">Lot</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === 'a' && util.getChkNull(pdy) === '' ?
                                <>
                                <div>
                                <ul className="ul-row">
                                    <li>
                                        <WorkNumSelectBox
                                            value={workNum}
                                            prodUid={prodUid}
                                            prodUidReq={true}
                                            onValueChanged={(v) => {
                                                setLot('');
                                                setWorkNum(v);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <LotSelectBox
                                            value={lot}
                                            prodUid={prodUid}
                                            showClearButton={true}
                                            onValueChanged={(v) => {
                                                setLot(v);
                                            }}
                                        />
                                    </li>
                                </ul>
                                </div>
                                <div>
                                    * Lot 번호가 없을 경우 작지번호만 선택하면 새로운 Lot 번호로 할당 됩니다.            
                                </div>
                                </>
                                :
                                <span>{lot}</span>  
                            }
                            </td>                            
                        </tr>                         */}
                    </tbody>
                </table>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"26%"} />
						<col className="tit" />
						<col className="tcon" width={"14%"} />
                        <col className="tit" />                        
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">검사유형</th>
                            <td className="tcon">
                            {
                                mode === 'a' ?
                                <SelectBox 
                                    //width={width}
                                    placeholder={'검사유형을 선택하세요'}
                                    value={inspTp}
                                    dataSource={procInspItem}
                                    displayExpr={'inspTpNm'}
                                    valueExpr={'inspTp'}
                                    showClearButton={true}
                                    onValueChanged={(e)=>{
                                        setInspTp(e.value);
                                    }} 
                                />
                                :
                                <span>{inspTpNm}</span>  
                            }
                            </td>
                            <th className="tit">검사차수</th>
                            <td className="tcon">
                            {
                                mode === 'a' ?    
                                <InspIdxSelectBox
                                    mode={'v'}
                                    value={inspIdx}
                                    inspDy={inspDy}
                                    lot={lot}
                                    prodProcUid={prodProcUid}
                                    equpUid={equpUid}
                                    // placeholder="차수"
                                    onValueChanged={(v) => { 
                                        setInspIdx(v);
                                    }}
                                />    
                                :
                                <span>{inspIdx}</span>  
                            }
                            </td>
                            <th className="tit">검사자</th>
                            <td className="tcon">
                                <UserSelectBox
                                    // mode={mode}
                                    showClearButton={false}
                                    value={inspUserUid}
                                    onValueChanged={(v) => { 
                                        setInspUserUid(v); 
                                    }}
                                />
                            </td>                                                  
                        </tr>
                        <tr>
                            <th className="tit">조치사항</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={actDtl}
                                    showClearButton={true}
                                    placeholder="조치사항을 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setActDtl(e.value);
                                    }}
                                /> 
                            </td>    
                            <th className="tit">검사일시</th>
                            <td className="tcon">
                                {mode === 'a' && util.getChkNull(pdy) === '' ?
                                    <DateTimeBox
                                        value={inspDt}                                    
                                        onValueChanged={(dt) => { 
                                            setInspDt(dt);
                                            setInspDy(util.getDateToString('d',dt));
                                        }}
                                    />
                                :   
                                    <ul className="ul-row">
                                        <li>
                                            <span>{util.getDateStrSplit('d', inspDy)}</span>
                                        </li>
                                        <li>
                                            <TimeBox
                                                value={inspDt}                                    
                                                onValueChanged={(dt) => { 
                                                    setInspDt(dt);
                                                }}
                                            />
                                        </li>
                                    </ul>
                                }
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">합격여부</th>
                            <td className="tcon">
                                {cmn.getDispPass(passYn)}
                            </td>  
                            <th className="tit">검사이미지</th>
                            <td className="tcon" colSpan={3}>
                                <>
                                 <FileUploadBox
                                    grpUid={atchFileGrpUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={atchFiles}

                                    onUploaded={(e) => {
                                        setAtchFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setAtchFiles(e);
                                    }}
                                />
                                </>
                            </td>   
                        </tr>
                    </tbody>
                </table>
            </div>
            <InspSampBox
                mode={mode}
                inspItems={inspItems}
                onValueChanged={(inspItems, passYn) => { 
                    // setInspItems(inspItems); 
                    setPassYn(passYn); 
                }}
            />
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "공정검사 등록" 
                : mode === "u" ? "공정검사 수정"
                : "공정검사" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProcInspPopPage;
import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, DataGridView
} from '../../components';

import {CmnStore, AuthStore} from '../../stores';

/** stores & lib */
import { cmn, util } from '../../lib';

/** images */

/** 
 *  재고실사 History
 */
const StockDueDiliHisPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const cmnStore = useContext(CmnStore);  
    const authStore = useContext(AuthStore);  
    
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        lt = ls.lot, // 
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    //Combo
    const [lot, setLot] = useState(util.getChkNull(lt));
    const [stockDueDiliItems, setStockDueDiliItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);


    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        if(util.getChkNull(lot) === '') return;
        try {  
            //if(pageLoad) return;
            const ps = {p: {lot: lot}}
            const r = await cmn.api('getStockDueDiliList', ps);
            if(r == null || r === undefined) return;

            const data = r.d0;

            let stockDueDiliItems_ = [];
            _.forEach(data, (v, k) => {
                stockDueDiliItems_.push({
                    chgDy: v.chgDy
                    ,dueDiliTpNm: v.dueDiliTpNm
                    ,chgAmt: v.chgAmt
                    ,userNm: v.userNm
                    ,cmnt: v.cmnt
                })
            })

            setStockDueDiliItems(stockDueDiliItems_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return(
            <>
            <div className="btn_r mgb10">
            
            </div>
            {/* <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">Lot</th>
                            <td className="tcon" colSpan={3}>
                                <span>{lot}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> */}

            <div className="pop-grid">
                <div className="clearfix">
              
                    <DataGridView
                        dataSource={stockDueDiliItems}   // store
                        pagingable={false}
                        showNo={false}
                        gridHeight={200}
                        colsWidth={[80, 60, 100, 100, null]}
                    >
                        { cmn.getGridColumnDayType('chgDy', '수정일') }
                        { cmn.getGridColumn('dueDiliTpNm', '수정타입') }
                        { cmn.getGridColumnNumCommaType('chgAmt', '수정량') }
                        { cmn.getGridColumn('userNm', '작업자') }
                        { cmn.getGridColumn('cmnt', '사유') }
                    </DataGridView>
               
                </div>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                "재고 수정 이력" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default StockDueDiliHisPopPage;

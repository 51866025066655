import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    EditGridView,
    PopupLayout, 
    ActionButton,
    UserSelectBox,
    DayDateBox,
    DataGridView,
    CancelButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  납품 등록 / 수정
 */
const DeliPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */
    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        dnum = ls.dnum,
        puid = ls.puid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [lotListDeliItem, setLotListDeliItem] = useState([]);

    const [bizProdItems, setBizProdItems] = useState([]);
    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState('');
    const [deliDy, setDeliDy] = useState(util.getNow());
    const [deliNm, setDeliNm] = useState('');
    const [cmnt, setCmnt] = useState('');
    const [deliPrice, setDeliPrice] = useState(0);
    const [totDeliAmt, setTotDeliAmt] = useState(0);
    const [acctMon, setAcctMon] = useState(util.getNow());

    const [salesNum, setSalesNum] = useState('');
    const [deliNum, setDeliNum] = useState('');

    useEffect(() => {
        loadPage();
    },[]);


    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);

            if(mode === "a" ){
                let para = {p:{bizUid:'', prodUid:puid}};
                let r = await cmn.api('getBizProdCombo', para);
                setBizProdItems( r.d0);
            }
            
            await loadInfo1();

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = () => {
        try { 
            if(mode === "a") {
                loadLotItems(puid);
            }

            if(mode !== "a") {
                // 납품정보조회
                loadDeliInfo();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadDeliInfo = async() => {
        if(util.getChkNull(dnum) === '') return;

        try { 
            let r = await cmn.api('getDeli', {p:{deliNum: dnum}});
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            const data2 = r.d0;

            console.log(data);
            setBizNm(data.bizNm);
            setUserNm(data.userNm);
            setDeliDy(data.deliDy);
            setAcctMon(data.acctMon);
            setSalesNum(data.salesNum);
            setDeliNum(data.deliNum);
         //   setDeliPrice(data.deliPrice);
         //   setTotDeliAmt(data.deliAmt);

            let lotListDeliItem_ = [];

            _.forEach(data2, (v,k) => {
                lotListDeliItem_.push({
                    deliUid: v.deliUid
                   ,prodNm: v.prodNm
                   ,prodUid: v.prodUid
                   ,lot: v.lot
                   ,deliAmt: v.deliAmt
                   ,cmnt: v.cmnt
               })
            })
            setLotListDeliItem(lotListDeliItem_);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }

    }

    const loadLotItems = async(e) => {
        try { 
            let para ={p: {prodUid: e}};

            let r = await cmn.api('getLotListDeli', para);
            if(r == null || r === undefined) return;

            let data = r.d0;

            let lotListDeliItem_ = [];
            _.forEach(data, (v, k) => {
                lotListDeliItem_.push({
                    lot: v.lot
                    ,firstAmt : v.firstAmt
                    ,compAmt: v.compAmt
                    ,badAmt : v.badAmt
                    ,stockAmt: v.stockAmt
                    ,deliAmt: 0
                    ,cmnt: ''
                })
            })

            setLotListDeliItem(lotListDeliItem_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadTotDeliAmt = () => {
        setTotDeliAmt(_.sumBy(lotListDeliItem, 'deliAmt'));
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(mode === "a")
            { 
                if(!util.chkMustValue(bizUid, '업체'))
                    return;
                if(!util.chkMustValue(userUid, '납품자'))
                    return;
                if(!util.chkMustValue(_.sumBy(lotListDeliItem, 'deliAmt'), '납품수량', 'cnt'))
                    return;
                
                // 추가 Validation Check
                let msg = '';
                _.forEach(lotListDeliItem, (v,k) => {
                    if(v.deliAmt > v.stockAmt) 
                        msg = '[납품수량]이 [납품가능수량] 보다 큽니다.';
                });

                if(msg !== '')
                {
                    alert(msg);
                    return;
                }


                if(util.confirmYn("등록"))
                    addDeli();
            }
            else if(mode === "v") 
            {
                if(util.confirmYn("취소"))
                    delDeli(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addDeli = async() => {

        let r2 = await cmn.api('getDeliNum', {});
        if(r2 == null || r2 === undefined) return;

        let deliNum_ = r2.d0[0].deliNum;

        let lotListDeliItem_ = [];
        let groupUid = util.getUUID();

        _.forEach(lotListDeliItem, (v,k) => {
            if(v.deliAmt > 0) {
                lotListDeliItem_.push(
                    {
                        deliUid: util.getUUID(),
                        comUid: authStore.com.uid,
                        deliNum: deliNum_,
                        bizUid: bizUid,
                        prodUid: puid,
                        deliDy: util.getDateToString('d',deliDy),
                        userUid: userUid,
                        deliPrice: deliPrice,
                        acctMon: util.getDateToString('M',acctMon),
                        lot: v.lot,
                        deliAmt: v.deliAmt,
                        cmnt: v.cmnt,
                        groupUid: groupUid,
                        uptUserUid: authStore.user.uid,
                    }
                );
            }
        });

        const ps = {
            p: [...lotListDeliItem_]
        }

       // console.log(ps);
        let r = await cmn.api('addDeli', ps);
        if(r == null || r === undefined) return;
        
        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delDeli = async(delCmnt) => {
        let lotListDeliItem_ = [];

        _.forEach(lotListDeliItem, (v,k) => {
            if(v.deliAmt > 0) {
                lotListDeliItem_.push(
                    {
                        deliUid: v.deliUid,
                        comUid: authStore.com.uid,
                        prodUid: v.prodUid,
                        lot: v.lot,
                        deliAmt: v.deliAmt,
                        delcmnt: delCmnt,
                        uptUserUid: authStore.user.uid,
                    }
                );
            }
        });

        const ps = {
            p: [...lotListDeliItem_]
        }

        console.log(ps);
        let r = await cmn.api('delDeli', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("취소");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "v" ? "취소완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                {
                mode === "v" ?
                    <CancelButton
                        title={completeButtonTitle}
                        isDel={'Y'}
                        handleClickComplete={(e) => {
                            handleClickComplete(e)
                        }} 
                    />
                    :
                    <ActionButton 
                        onClick={handleClickComplete}
                        title={completeButtonTitle}
                        marginRight={0}
                        show={true}
                    />
                }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                    {mode === 'v' ? 
                        <tr>
                            {/* <th className="tit">수주번호</th>
                            <td className="tcon">
                                <span>{salesNum}</span>
                            </td> */}
                            <th className="tit">납품번호</th>
                            <td className="tcon" colSpan={3}>
                                <span>{deliNum}</span>
                            </td>
                        </tr>
                        : ''
                    }
                        <tr>
                            <th className="tit">업체</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <SelectBox 
                                        value={bizUid}
                                        dataSource={bizProdItems}
                                        displayExpr={'bizNm'}
                                        valueExpr={'bizUid'}
                                        placeholder="업체를 선택하세요"
                                        showClearButton={true} 
                                        onValueChanged={(e)=>{
                                            setBizUid(e.value);
                                            const o = _.find(bizProdItems, {bizUid: e.value});
                                            setDeliPrice(o.bizPrice);
                                        }} 
                                    />
                                    :
                                    <span>{bizNm}</span>
                                }
                            </td>
                            <th className="tit">납품자</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        placeholder="작업자를 선택하세요"
                                        showClearButton={false}
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                :
                                    <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">납품일</th>
                            <td className="tcon">
                                {
                                    mode === "a" ?
                                    <DayDateBox
                                        value={deliDy}
                                        onValueChanged={(e) => {
                                            setDeliDy(e.value);
                                        }}                    
                                    />
                                    : 
                                    <span>{util.getDateStrSplit('d', deliDy)}</span>
                                }
                            </td>
                            <th className="tit">회계월</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <DayDateBox 
                                        value={acctMon}
                                        type={'month'}
                                        displayFormat={cmn.DATE_FORMAT_MON_1}
                                        onValueChanged={(e) => {
                                            setAcctMon(e.value);
                                        }}                    
                                    />
                                    : 
                                    <span>{util.getDateStrSplit('M', acctMon)}</span>
                                }
                            </td>
                        </tr>
                        {/* <tr>
                            <th className="tit">판매단가</th>
                            <td className="tcon">
                                {
                                    mode === "a" ?
                                    <NumberBox
                                        width={80}
                                        format={'#,##0'}
                                        value={deliPrice}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setDeliPrice(e.value);
                                        }}
                                    />   
                                    : 
                                    <span>{util.getNumComma(deliPrice)}</span>
                                }
                            </td>
                            <th className="tit">납품수량</th>
                            <td className="tcon">
                              
                                    <span>{util.getNumComma(totDeliAmt)}</span>
                              
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>

            <div className="pop-grid">
                <div className="clearfix">
                <>
                { mode === 'a' ?
                    <EditGridView
                        dataSource={lotListDeliItem}   // store
                        colsWidth={[150, 100, 100, 100, 100, null]}
                        onRowUpdated={(e) => {loadTotDeliAmt();}}
                        gridHeight={200}
                        
                    >
                        {cmn.getGridColumn('lot', 'Lot')}
                        {cmn.getGridColumnNumCommaType('compAmt', '기출고수량')}
                        {cmn.getGridColumnNumCommaType('badAmt', 'LOSS수량')}
                        {cmn.getGridColumnNumCommaType('stockAmt', '납품가능수량')}
                        <Column 
                            alignment={'center'}
                            dataField={'deliAmt'} 
                            caption={'납품수량'} 
                            cellRender={(d) => {
                                return <span>{util.getNumComma(d.value)}</span>;
                            }}
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'cmnt'} 
                            caption={'비고'} 
                        />
                    </EditGridView>
                    :
                    <DataGridView
                        dataSource={lotListDeliItem}   // store
                        colsWidth={[150, 120, 120, null]}
                        gridHeight={200}
                    >
                        {cmn.getGridColumn('lot', 'Lot')}
                        {cmn.getGridColumn('prodNm', '품명')}
                        {cmn.getGridColumnNumCommaType('deliAmt', '납품수량')}
                        {cmn.getGridColumnCmnt()}
                    </DataGridView>
                }
                </>
                </div>
            </div>      

            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "납품 등록" 
                : mode === "v" ? "납품 상세보기"
                : "납품" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default DeliPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  제품 선택 조회
 */
const BizSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {

        width='100%',
        value,
        placeholder='업체를 선택하세요.',
        
        bizTp,
        sortCol='bizNm',
        sortTp='asc',
        isDefaultVal=false,
        onLoadInfo,
        onValueChanged,
        onInit,

        showClearButton=true

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [bizs, setBizs] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            let ps = {p: { bizTp: bizTp, sortCol: sortCol, sortTp: sortTp }};
            const r = await cmn.api("getBizCombo", ps);
            if(r == null || r === undefined) return;
            
            setBizs(r.d0);    
            
            if(util.getChkNull(value) !== ''){
                handleLoadInfo(r.d0, value);
            }
            if(isDefaultVal) {
                handleSetDefault(r.d0)
            }
        }
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleLoadInfo = (ds, v) => {
        const o = _.find(ds, {bizUid: v});
        console.log(o);
        if(util.getChkNull(o) !== '')
            onLoadInfo && onLoadInfo(o);
    }

    const handleSetDefault = (ds) => {
        const filter = ds.filter((v) => {
            const bizTp = v.bizTp.split(',')
            const isHeadquarter = bizTp.includes('70201');
            if (isHeadquarter) return v
        })
        onInit(filter[0]);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
                <SelectBox 
                    width={width}
                    placeholder={placeholder}
                    value={value}
                    dataSource={bizs}
                    displayExpr={'bizNm'}
                    valueExpr={'bizUid'}
                    
                    showClearButton={showClearButton}

                    searchEnabled={true}
                    searchMode={'contains'} // contains, startswith
                    searchExpr={['bizNm']}
                    minSearchLength={0}
                    searchTimeout={200}

                    onValueChanged={(e)=>{
                        onValueChanged(e.value);
                        handleLoadInfo(bizs, e.value);
                    }} 
                />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default BizSelectBox;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import SplitterLayout from 'react-splitter-layout';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    CodeSelectBox, SrchButton, SubActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 점검항목 관리
 */
const EqupChkItemPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [equpTp, setEqupTp] = useState('');
    const [equpNm, setEqupNm] = useState('');
    const [equpCd, setEqupCd] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "설비유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={equpTp}
                    classId={'401'}
                    placeholder="설비유형을 선택하세요."
                    onValueChanged={(v, o) => { 
                        setEqupTp(v);
                    }}
                />
            },
            {   title: "설비코드",
                width: "200px",
                item: 
                <TextBox 
                    value={equpCd}
                    showClearButton={true}
                    placeholder="설비코드로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setEqupCd(e.value);
                    }}
                />                      
            },
            {   title: "설비명",                
                item: 
                <TextBox 
                    width="200px"
                    value={equpNm}
                    showClearButton={true}
                    placeholder="설비명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setEqupNm(e.value);
                    }}
                />                      
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [equpList, setEqupList] = useState([]);
    const [equpListTot, setEqupListTot] = useState(0);
    const [equpChkItemList, setEqupChkItemList] = useState([]);
    
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItem2, setSelectedItem2] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    

    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {        
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    useEffect(() => {
        !pageLoad && handleClickSearch2();
    }, [selectedItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        // console.log("### loadPage");
        try {
            await authStore.loadPage(props);            
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                equpTp: equpTp, 
                equpCd: equpCd, 
                equpNm: equpNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getEqupList", ps );
        if(r === undefined || r == null) return;

        setEqupList(r.d0)
        setEqupListTot(r.tot);
    }

    const loadList2 = async() => {
        // console.log("$$$ selectedItem", selectedItem)
        if(selectedItem == null) {
            setEqupChkItemList(null);
            return;
        } 
        
        const ps =  {
            p: {
                equpUid: selectedItem.equpUid,
                sortCol: "",
                sortTp: "",
                offset: 0,
                limit: -1,            
            }
        }

        const r = await cmn.api("getEqupChkItemList", ps );
        if(r === undefined || r == null) return;

        setEqupChkItemList(r.d0);

    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions
    const handleClickSearch = async(e) => {
        // console.log("### handleClickSearch");
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickSearch2 = async(e) => {
        // console.log("### handleClickSearch2");
        try {
            await loadList2();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = () => {
        if(!util.chkListSelected(selectedItem, '생산설비를')) return;
        openCrudPopup('a', selectedItem.equpUid);
    }

    const handleClickUp = () => {
        if(!util.chkListSelected(selectedItem2, '점검항목을')) return;
        openCrudPopup('u', selectedItem.equpUid, selectedItem2.equpChkItemUid);
    }
    
    const handleClickDel = async() => {
        if(!util.chkListSelected(selectedItem2, '점검항목을')) return;
        if(!util.confirmYn('삭제')) return;

        // console.log("=== selectedItem", selectedItem);
        // console.log("=== selectedItem2", selectedItem2);
        // return;

        const ps = {
            p: {
                    equpUid: selectedItem.equpUid,
                    equpChkItemUid: selectedItem2.equpChkItemUid,
                    equpChkCycleUid: selectedItem2.equpChkCycleUid,
                    sortOrder: selectedItem2.sortOrder,
                    chkCycleTp: selectedItem2.chkCycleTp
                }
         }        

        const r = await cmn.api("delEqupChkItem", ps);
        if(r == null) return;

        util.cmnSucAlert("삭제");
        handleClickSearch2();
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGrid2SelectionChanged =  ({selectedRowsData}) => {        
        if(selectedRowsData.length === 0) { 
            setSelectedItem2(null); return;
        }
        setSelectedItem2(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, equpUid, equpChkItemUid) => {
        const url = `/equp/equpchkitempop?${qs.stringify({mode: mode, uid: equpUid, ciuid: equpChkItemUid})}`;        
        util.openWindow(url, 760, 500);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderLeftContent = () => {
        return(
            <>
            <GridTopView title="설비 내역" />
            <DataGridView            
                dataSource={equpList}   
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[100, 100, cmn.CW.equpNm
                    , 100, 70
                    , cmn.CW.yn, null]}
                // paging
                page={curPage}
                total={equpListTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}                
            >
                { cmn.getGridColumn('equpTpNm', '설비유형', renderGridHeaderCell ) }
                { cmn.getGridColumn('equpCd', '설비코드', renderGridHeaderCell ) }
                { cmn.getGridColumn('equpNm', '설비명', renderGridHeaderCell ) }

                { cmn.getGridColumn('modelNm', '모델명', renderGridHeaderCell ) }
                { cmn.getGridColumn('uph', 'UPH', renderGridHeaderCell ) }
                { cmn.getGridColumnUseYn('useYn', '사용여부', renderGridHeaderCell) }  
                <Column />               
            </DataGridView>
            </>
        );

    }

    const renderRightContent = () => {
        return(
            <>
            <GridTopView title="설비 점검항목 내역">
                <SubActionButton 
                    onClick={handleClickAdd}
                    title="추가"
                    show={authStore.page.crtYn}
                /> 
                <SubActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />  
                <SubActionButton 
                    onClick={handleClickDel}
                    title="삭제"
                    marginRight={0}
                    show={authStore.page.delYn}
                />
            </GridTopView>
            <DataGridView            
                dataSource={equpChkItemList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[50, 70, 120, 150, 100, 80, 80, 80, 80,null]}
                showNo={false}
                pagingable={false}                
                onSelectionChanged={handleGrid2SelectionChanged}            
            >
                { cmn.getGridColumn('sortOrder', '순서' ) }
                { cmn.getGridColumn('chkCycleTpNm', '점검주기' ) }                
                { cmn.getGridColumn('chkNm', '점검명' ) }
                { cmn.getGridColumn('chkCont', '점검내용' ) }
                { cmn.getGridColumn('valSelectTpNm', '점검유형' ) }
                { cmn.getGridColumn('dispStnd', '규격' ) }
                <Column 
                    alignment={'center'}
                    dataField={'upperVal'} 
                    caption={'상한(최대)'}
                    cellRender={(d) => {
                        return d.row.data.valSelectTp === cmn.CMNCO_VALSELTP.SEL ? 
                            "양호"
                            : d.value
                            ;
                    }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'lowerVal'} 
                    caption={'하한(최소)'}
                    cellRender={(d) => {
                        return d.row.data.valSelectTp  === cmn.CMNCO_VALSELTP.SEL? 
                            "불량"
                            : d.value
                            ;
                    }}
                />
                { cmn.getGridColumn('chkTpNm', '점검구분' ) }
                <Column /> 
            </DataGridView>
            </>
        );

    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    marginRight={0}
                    onClick={handleClickSearch}
                />
                <SrchButton 
                    id="subSrchButton"
                    marginRight={0}
                    onClick={handleClickSearch2}
                    show={false}
                />
            </ListTopView>
            <ListSearchView searchItems={searchItems} />
            <Observer>{() =>    
            <div className="cont-split"
                style={{
                    marginTop: 16
                }}
            >
                <SplitterLayout
                    customClassName={`split-layout-cust ${authStore.sideOpened === true ? "is-visible" : ""}`}
                >
                    <div className="cont-split-left"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderLeftContent() }
                    </div>
                    <div className="cont-split-right"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderRightContent() }
                    </div>
                </SplitterLayout>
            </div>         
            }</Observer>       
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupChkItemPage;
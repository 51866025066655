import React, { useState, useEffect } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */

/** stores & lib */
import {  cmn, util } from '../../lib';

/** 
 *  제품공정 선택 조회
 */
const ProdProcSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        reff,
        width='100%',
        value,
        defaultValue,
        inYn='',
        procTp='',
        firstYn='',
        eqProcTpYn='N', //procTp equl or not-equl 여부
        placeholder='공정을 선택하세요.',

        prodUid='',

        showClearButton=false,
        
        onLoadInfo,
        onValueChanged,

        searchEnabled=false
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [prodProcs, setProdProcs] = useState([]);

    useEffect(() => {
        loadPage();
    },[prodUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
       // alert(value);
        try {
            if(util.getChkNull(prodUid) === '')
                return;

            const ps = {p: {
                                prodUid: prodUid
                                ,inYn: inYn
                                ,procTp: procTp
                                ,eqProcTpYn: eqProcTpYn
                                ,firstYn: firstYn
                            }
                        } 
            
            const r = await cmn.api('getProdProcCombo', ps);

            let prodProcs_ = r.d0;
            setProdProcs(prodProcs_);

            if(util.getChkNull(value) !== '') {
                handleLoadInfo(prodProcs_, value);
            }
        }catch{
            
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleLoadInfo = (ds, v) => {
        const o = _.find(ds, {prodProcUid: v});
        onLoadInfo && onLoadInfo(o);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
           {
            value !== undefined ?
            <SelectBox 
                width={width}
                placeholder={placeholder}
                value={value}
                dataSource={prodProcs}
                displayExpr={'procNm'}
                valueExpr={'prodProcUid'}

                showClearButton={showClearButton}

                searchEnabled={searchEnabled}
                searchMode={'contains'} // contains, startswith
                searchExpr={['procNm', 'prodProcUid']}
                minSearchLength={0}
                searchTimeout={200}

                onValueChanged={(e)=>{
                    const o = _.find(prodProcs, {prodProcUid: e.value});
                    onValueChanged(e.value, o);
                    handleLoadInfo(prodProcs, e.value);
                }} 
            />
            :
            <SelectBox 
                ref={reff}
                width={width}
                placeholder={placeholder}
                defaultValue={defaultValue}
                dataSource={prodProcs}
                displayExpr={'procNm'}
                valueExpr={'prodProcUid'}

                showClearButton={showClearButton}

                searchEnabled={searchEnabled}
                searchMode={'contains'} // contains, startswith
                searchExpr={['procNm', 'prodProcUid']}
                minSearchLength={0}
                searchTimeout={200}

                onValueChanged={(e)=>{

                    const o = _.find(prodProcs, {prodProcUid: e.value});
                    onValueChanged(e.value, o);
                    handleLoadInfo(prodProcs, e.value);
                }} 
            />
            }
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default ProdProcSelectBox;

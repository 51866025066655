import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    CodeSelectBox, SrchButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';


/** 
 *  재고 관리
 */
const LotTracePage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [searchTp, setSearchTp] = useState('');
    const [searchText, setSearchText] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "추적유형",
                width: "200px",
                colSpan:"5",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <CodeSelectBox
                            value={searchTp}
                            width={'150px'}
                            classId={'802'}
                            showClearButton={false}
                            onValueChanged={(v) => { 
                                setSearchTp(v);
                            }}
                        />
                    </li>
                    <li>
                        <TextBox 
                            value={searchText}
                            width={'200px'}
                            showClearButton={true}
                            placeholder="검색 정보를 입력하세요."
                            onValueChanged={(e)=>{
                                setSearchText(e.value);
                            }}
                        /> 
                    </li>
                </ul>  
                </>
            }
        ]
    ];
    /* #endregion */

    // * 목록 선택 코드 UID
    const [workNum, setWorkNum] = useState('');
    const [lot, setLot] = useState('');

    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [prodMattTpNm, setProdMattTpNm] = useState('');
    const [compYn, setCompYn] = useState('');
    const [firstAmt, setFirstAmt] = useState('');
    //const [compYn, setCompYn] = useState('');
    const [traceItems, setTraceItems] = useState([]);
    const [workItems, setWorkItems] = useState([]);
    const [manuWorkNumItems, setManuWorkNumItems] = useState([]);
    const [showYn, setShowYn] = useState(false);
    

    // * Grid, Paging & Sorting

    // const dgCodeListRef = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    // useEffect(() => {
    //     !pageLoad && loadWorkNumInfo();
    // }, [schWorkNumOrLot]);   

    // useEffect(() => {
    //     !pageLoad && loadManuItemInfo();
    // }, [schWorkNumOrLot]);   

    // useEffect(() => {
    //     !pageLoad && loadTraceInfo();
    // }, [prodUid, schWorkNumOrLot]);   

    useEffect(() => {
        !pageLoad && loadManuItemInfo();
    }, [workNum, lot]);   

    useEffect(() => {
        !pageLoad && loadManuWorkNum();
    }, [workNum, lot]);  

    useEffect(() => {
        !pageLoad && loadTraceInfo();
    }, [prodUid]);  
    
    useEffect(() => {
        !pageLoad && loadWorkNumInfo();
    }, [searchText]);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
//            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const pageInit = () =>{
        setWorkNum('');
        setLot('');
        setProdUid('');
        setProdId('');
        setProdNm('');
        setProdStnd('');
        setProdMattTpNm('');
        setCompYn('');
        setFirstAmt('');
        setTraceItems([]);
        setWorkItems([]);
        setManuWorkNumItems([]);
        setShowYn(false);
    }

    const loadWorkNumInfo = async() => {
        try {
            
            if(util.getChkNull(searchText) === '') return;
            
            // workNum 검색
            let workNum_ = '';
            if(searchTp === '80201') 
            {
                workNum_ = searchText
            }else if (searchTp === '80202')
            {
                let r_lot = await cmn.api('getLot', {p: {lot: searchText}});
                if(r_lot == null || r_lot.d0.length === 0) 
                {
                    util.cmnAlert('등록된 정보가 없습니다.');
                    pageInit();
                    return;
                }
                
                let lot_data = r_lot.d0[0];
                workNum_ = lot_data.workNum;
                setLot(searchText);
            }

            let ps = {p: {workNum: workNum_}}
            let r = await cmn.api('getWorkNum', ps);
            if(r == null || r.d0.length === 0) 
            {
                util.cmnAlert('등록된 정보가 없습니다.');
                pageInit();
                return;
            }

            let data = r.d0[0];
            setWorkNum(data.workNum);
            setProdUid(data.prodUid);
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setProdStnd(data.prodStnd);
            setCompYn(data.compYn);
            setFirstAmt(data.firstAmt);
            setProdMattTpNm(data.prodMattTpNm);

            setShowYn(true);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadTraceInfo = async() => {
        try {
            if(util.getChkNull(searchText) === '' || util.getChkNull(prodUid) === '') return;

            let searchText_ = searchText;
            if(searchTp !== '80201') 
                searchText_ = lot;

            let ps = {p: {workNumOrLot: searchText_, prodUid: prodUid}}
          //  console.log("para====>", para);
            let r = await cmn.api('getWorkNumTraceList', ps);
            if(r == null || r.d0.length === 0) return;

            let data = r.d0;
            
         //   console.log(data);
            setTraceItems(data);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadManuItemInfo = async() => {
        try {
            if(util.getChkNull(searchText) === '') return;
            
            let workNum_ = '';
            let lot_ = '';
            if(searchTp !== '80201') 
                lot_ = searchText;
            else
                workNum_ = searchText;
                

            let ps = {p: {workNum: workNum_, lot: lot_, outStatTp: '30801', offset: 0, limit: -1}}
        //    console.log("para====>", para);
            let r = await cmn.api('getOutList', ps);
            if(r == null || r.d0.length === 0) return;

            let data = r.d0;
            
          //  console.log(data);

            setWorkItems(data);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    
    const loadManuWorkNum = async() => {
        try {
            if(util.getChkNull(searchText) === '') return;
            if(util.getChkNull(workNum) === '' && util.getChkNull(lot) === '') return;
        
            const ps =  {p: {workNum: workNum, lot: lot}}

          //  console.log("para=============>", para);
            let r = await cmn.api('getManuWorkNum', ps);
            if(r == null || r === undefined || r.d0.length === 0) return;

            let data = r.d0;
            
            let manuWorkNumItems_ = [];
            _.forEach(data, (v, k) => {
                manuWorkNumItems_.push({
                    manuDy: v.manuDy
                    ,lot: v.lot
                    ,manuAmt: v.manuAmt
                    ,goodAmt: v.goodAmt
                    ,badAmt: v.badAmt
                    ,workPer: v.workPer
                })
            })
            
            setManuWorkNumItems(manuWorkNumItems_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    // const handleClickSearch = (e) => {
    //     loadWorkNumInfo();
    // }
    const handleClickSearch = async(e) => {
        try {
            await loadWorkNumInfo();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Data Grid

    // * Functions

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
            </ListTopView>        
            <ListSearchView searchItems={searchItems} />
        
        { showYn  ?
            <>
            <GridTopView
                title="작업내용"
            >
            </GridTopView>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"25%"} />
						<col className="tit" />
						<col className="tcon"  width={"25%"}/>
                        <col className="tit" />
						<col className="tcon"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">작업번호</th>
                            <td className="tcon">
                                <span>{workNum}</span>
                            </td>
                            <th className="tit">작업지시량</th>
                            <td className="tcon">
                                <span>{firstAmt}</span>
                            </td>
                            <th className="tit">공정마감여부</th>
                            <td className="tcon">
                                <span>{compYn}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon" >
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon" >
                                <span>{prodId}</span>  
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon" >
                                <span>{prodStnd}</span>
                            </td>
                        </tr>
                        {/* <tr>
                            <th className="tit">재질</th>
                            <td className="tcon">
                                <span>{prodMattTpNm}</span>
                            </td>
                            <th className="tit">작업일자</th>
                            <td className="tcon" colSpan={3} >
                                <span>{}</span>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>


            <div className="clearfix">                
                <div className="tit-area fl-l">Lot별 작업현황</div>
            </div>  
            <DataGridView            
                dataSource={manuWorkNumItems}   // store
                colsWidth={[150, null, 150, 150, 150, null]}
                showNo={false}
                pagingable={false}                
            >
                { cmn.getGridColumnDayType('manuDy', '작업일자')}
                { cmn.getGridColumn('lot', 'Lot') }
                { cmn.getGridColumnNumCommaType('manuAmt', '생산수량') }
                { cmn.getGridColumnNumCommaType('goodAmt', '양품수량') }
                { cmn.getGridColumnNumCommaType('badAmt', '불량수량') }
                { cmn.getGridColumnPer('workPer', '진행률') }
            </DataGridView>

            <div className="clearfix" style={{marginTop:"15px"}}>             
                <div className="tit-area fl-l">투입자재</div>
            </div>  
            <DataGridView            
                dataSource={workItems}   // store
                selection=''
                showNo={false}
                colsWidth={[120, 200, 150, 120, 100, 100, 100, null]} 
            >
                { cmn.getGridColumn('lot', '투입 Lot') }
                { cmn.getGridColumn('prodNm', '품명') }
                { cmn.getGridColumn('prodId', '품번') }
                { cmn.getGridColumn('prodStnd', '규격') }
                { cmn.getGridColumnNumCommaType('outAmt', '투입량') }
                { cmn.getGridColumnDayType('outDy', '투입일자') }
                { cmn.getGridColumn('userNm', '작업자') }
                { cmn.getGridColumn('cmnt', '비고') }
            </DataGridView>
            </>
        : ''
        }  
            {
                _.map(traceItems, (v, k) => {
                    return(
                    v.type === '70701' ? 
                    <>
                    <div className="clearfix">                
                        <div className="tit-area fl-l">{ v.procNm }</div>
                    </div>  
                    <DataGridView            
                        dataSource={v.items}   // store
                        selection=''
                        showNo={true}
                        colsWidth={[ null, null, null, null, null, null, null, null, null]} 
                    >
                        { cmn.getGridColumnDayType('inDy', '입고일자') }
                        { cmn.getGridColumn('bizNm', '구매업체') }
                        { cmn.getGridColumn('lot', 'Lot') }
                        { cmn.getGridColumn('heatNo', 'HeatNo') }
                        { cmn.getGridColumn('coilNo', 'CoilNo') }
                        { cmn.getGridColumnNumCommaType('inAmt', '입고량') }
                        { cmn.getGridColumn('bizLot', '구매업체Lot') }
                        { cmn.getGridColumn('userNm', '입고자') }
                        { cmn.getGridColumn('cmnt', '비고') }
                    </DataGridView>
                    </>
                    :  v.type === '70702' ? 
                    <>
                        <div className="clearfix" style={{marginTop:"15px"}}>                
                            <div className="tit-area fl-l">{ v.procNm }</div>
                        </div>  
                        <DataGridView            
                            dataSource={v.items}   // store
                            selection=''
                            showNo={true}
                            colsWidth={[ null, null, null, null, null, null, null, null, null ]} 
                        >
                            { cmn.getGridColumn('procNm', '공정명') }
                            { cmn.getGridColumnDayType('manuDy', '작업일') }
                            { cmn.getGridColumn('bizNm', '업체명') }
                            { cmn.getGridColumn('lot', 'Lot') }
                            { cmn.getGridColumnNumCommaType('manuAmt', '생산량') }
                            { cmn.getGridColumnNumCommaType('goodAmt', '양품량') }
                            { cmn.getGridColumnNumCommaType('badAmt', '불량') }
                            { cmn.getGridColumn('equpNm', '설비명') }
                            { cmn.getGridColumn('userNm', '작업자') }
                            <Column />
                        </DataGridView>
                    </>
                    :  v.type === '70703' ?
                    <>
                        <div className="clearfix" style={{marginTop:"15px"}}>                
                            <div className="tit-area fl-l">{ v.procNm }</div>
                        </div>  
                        <DataGridView            
                            dataSource={v.items}   // store
                            selection=''
                            showNo={true}
                            colsWidth={[null, null, null, null, null, null, null ]} 
                        >
                            { cmn.getGridColumn('procNm', '공정명') }
                            { cmn.getGridColumnDayType('outDy', '출고일') }
                            { cmn.getGridColumn('lot', 'Lot') }
                            { cmn.getGridColumn('bizNm', '출고업체') }
                            { cmn.getGridColumnNumCommaType('outAmt', '출고량') }
                            { cmn.getGridColumn('userNm', '출고자') }
                            { cmn.getGridColumn('cmnt', '비고') }
                        </DataGridView>
                    </>
                    :  v.type === '70704' ? 
                    <>
                        <div className="clearfix" style={{marginTop:"15px"}}>                
                            <div className="tit-area fl-l">{ v.procNm }</div>
                        </div>  
                        <DataGridView            
                            dataSource={v.items}   // store
                            selection=''
                            showNo={true}
                            colsWidth={[null, null, null, null, null, null,  null ]} 
                        >
                            { cmn.getGridColumn('procNm', '공정명') }
                            { cmn.getGridColumnDayType('inDy', '입고일') }
                            { cmn.getGridColumn('lot', 'Lot') }
                            { cmn.getGridColumn('bizNm', '입고업체') }
                            { cmn.getGridColumnNumCommaType('inAmt', '입고량') }
                            { cmn.getGridColumn('userNm', '입고자') }
                            { cmn.getGridColumn('cmnt', '비고') }
                        </DataGridView>
                    </>
                    :  v.type === '70705' ? 
                    <>
                        <div className="clearfix" style={{marginTop:"15px"}}>                
                            <div className="tit-area fl-l">{ v.procNm }</div>
                        </div>  
                        <DataGridView            
                            dataSource={v.items}   // store
                            selection=''
                            showNo={true}
                            colsWidth={[ null, null, null, null, null, null, null ]} 
                        >
                            { cmn.getGridColumnDayType('deliDy', '납품일자') }
                            { cmn.getGridColumn('lot', 'Lot') }
                            { cmn.getGridColumnNumCommaType('deliAmt', '납품수량') }
                            { cmn.getGridColumn('bizNm', '고객명') }
                            { cmn.getGridColumn('userNm', '납품자') }
                            { cmn.getGridColumn('cmnt', '비고') }
                        </DataGridView>
                    </>
                    : null
                    )
                })  
            }     
        </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default LotTracePage;
const comApiz = {
    
    getComList: {
        title: "회사 목록 조회",
        url: "/api/com/getComList",
        method: "POST"
    },
    getCom: {
        title: "회사 상세 조회",
        url: "/api/com/getCom",
        method: "POST"
    },
    getComDomain: {
        title: "회사 상세 조회(도메인)",
        url: "/api/com/getComDomain",
        method: "POST"
    },
    addCom: {
        title: "회사 등록",
        url: "/api/com/addCom",
        method: "POST"
    },
    upCom: {
        title: "회사 수정",
        url: "/api/com/upCom",
        method: "POST"
    },
    delCom: {
        title: "회사 삭제",
        url: "/api/com/delCom",
        method: "POST"
    },
}

export default comApiz;
import React, { useState, useEffect, useContext } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { Column} from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    CodeSelectBox, UserSelectBox,
    PopupLayout, ActionButton, SubActionButton, GridTopView, DataGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  생산설비 비가동내역 CRUD 팝업
 */
const EqupRunPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,   // equp_down_his_uid
        //duid = ls.duid  // equp_down_his_uid

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    const [equpNm, setEqupNm] = useState('');
    const [downEndDt, setDownEndDt] = useState('');
    const [downStartDt, setDownStartDt] = useState('');
    const [downTp, setDownTp] = useState('');
    const [downCont, setDownCont] = useState('');
    const [workerUid, setWorkerUid] = useState(authStore.user.uid);
    const [downTime, setDownTime] = useState(null);

    /** Items */

    useEffect(() => {
        loadPage();
    },[]);
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {             
            // if(mode == 'a') return;
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getEqupDownHis", {p: {equpDownHisUid: uid}} );
            if(r == undefined) return;

            const d0 = r.d0[0];

            setEqupNm(d0.equpNm);
            setDownEndDt(d0.downEndDt);
            setDownStartDt(d0.downStartDt);

            setDownTp(d0.downTp);
            setDownCont(d0.downCont);
            setWorkerUid(d0.workerUid);
            setDownTime(d0.downTime);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // if(!util.chkMustValue(partNm, '부품명')) return;

            // if(mode == "a") { 
            //     if(!util.confirmYn('등록')) return;
            //     addEqupDownHis();
            // }
          //  else if(mode == "u") { 
                if(!util.confirmYn('수정')) return;
                    upEqupDownHis();
         //   }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

  
    // const addEqupDownHis = async() => {
    //     const para = {
    //         p: 
    //             {
    //                 equpConnDataUid: uid,
    //                 downTp: downTp,
    //                 downCont: downCont,
    //                 workerUid: workerUid
    //             }
    //     }

    //     const r = await cmn.api("addEqupDownHis", para);
    //     if(r == null) return;

    //     util.cmnSucAlert(mode === "a" ? "등록완료" : "수정완료");
    //     util.clickOpenerElement("srchButton");
    //     window.close();
    // }

    
    const upEqupDownHis = async() => {
        const para = {
            p: 
                {
                    equpDownHisUid: uid,
                    downTp: downTp,
                    downCont: downCont,
                    workerUid: workerUid
                }
        }

        const r = await cmn.api("upEqupDownHis", para);
        if(r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon"/>
						<col className="tit" />
						<col className="tcon" width={160} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">설비명</th>
                            <td className="tcon">
                                {equpNm}
                            </td>
                            <th className="tit">시작시각</th>
                            <td className="tcon">
                                {util.getDateToString('m', downStartDt, '-')}
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비가동시간</th>
                            <td className="tcon">
                            {`${util.getNumComma(downTime)} (분)`}
                            </td>
                            <th className="tit">종료시각</th>
                            <td className="tcon">
                                {util.getDateToString('m', downEndDt, '-')}
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비가동유형</th>
                            <td className="tcon">
                            {
                                (mode == "a" || mode == "u") &&
                                <CodeSelectBox
                                    value={downTp}
                                    classId={'412'}
                                    placeholder="비가동 유형 선택"
                                    onValueChanged={(v, o) => { 
                                        setDownTp(v);
                                    }}
                                /> 
                                }
                            </td>
                            <th className="tit">작업자</th>
                            <td className="tcon">
                            {
                                (mode == "a" || mode == "u") &&
                                <UserSelectBox
                                    value={workerUid}
                                    onValueChanged={(v) => { setWorkerUid(v); }}
                                />
                            }
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit">비가동내역</th>
                            <td className="tcon" colSpan={3}>
                            {
                                (mode == "a" || mode == "u") &&
                                <TextBox 
                                    value={downCont}
                                    showClearButton={true}
                                    placeholder="비가동 내역을 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setDownCont(e.value);
                                    }}
                                /> 
                            }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>                           
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "생산설비 비가동 등록" 
                : mode == "u" ? "생산설비 비가동 수정"
                : "생산설비 비가동" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default EqupRunPopPage;
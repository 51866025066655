import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, DataGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { cmn, util } from '../../lib';

/** images */

/** 
 *  생산실적 CRUD 팝업
 */
const ProcPriceHisPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  
   
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid, // 
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    //Combo
    const [bizNm, setBizNm] = useState('');
    const [procPriceHisItems, setProcPriceHisItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {

        try {            
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            const ps = {p: {prodProcDtlUid: uid}}

            const r = await cmn.api('getProcPriceHisList', ps);
            if(r == null || r === undefined) return;

            let data = r.d0;
            
            setBizNm(data[0].bizNm);

            let procPriceHisItems_ = [];

            _.forEach(data, (v, k) => {
                procPriceHisItems_.push({
                    chgDy: v.chgDy
                    ,chgPrice: v.chgPrice
                    ,chgPer: v.chgPer
                    ,chgTpNm: v.chgTpNm
                })
            })

            setProcPriceHisItems(procPriceHisItems_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

     // ...

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return(
            <>
            <div className="pop-grid">
                <div className="clearfix">
                <>
                    <DataGridView
                        dataSource={procPriceHisItems}   // store
                        gridHeight={360}
                        pagingable={false}
                        showNo={false}
                        colsWidth={[cmn.CW.dy, cmn.CW.amt, cmn.CW.price, null]}
                    >
                        { cmn.getGridColumnDayType('chgDy', '변경일') }
                        { cmn.getGridColumnNumCommaType('chgPrice', '변경가격') }
                        { cmn.getGridColumnPer('chgPer', '변경률') }
                        { cmn.getGridColumn('chgTpNm', '') }
                    </DataGridView>
                </>
                </div>
            </div>

            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                "가격동향" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProcPriceHisPopPage;

import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, PrintLayout, CheckListBox, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  측정기 관리대장
 */
const GaugeHisRepoPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        uid = ls.uid,
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [gaugeUid, setGaugeUid] = useState(uid);

    const [gaugeTp, setGaugeTp] = useState('');
    const [gaugeTpNm, setGaugeTpNm] = useState('');

    const [calibCycle, setCalibCycle] = useState(0);

    const [gaugeCd, setGaugeCd] = useState('');
    const [gaugeNm, setGaugeNm] = useState('');
    const [modelNm, setModelNm] = useState('');   
    const [serialNo, setSerialNo] = useState('');    

    const [maker, setMaker] = useState('');
    const [useYn, setUseYn] = useState('N');

    const [buyDy, setBuyDy] = useState('');
    const [saveLoca, setSaveLoca] = useState('');    
    const [cmnt, setCmnt] = useState('');    

    const [imgFileInfo, setImgFileInfo] = useState('');

    const [gaugeCalibs, setGaugeCalibs] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);
  
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {     
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getGaugeHisRepo", {p: {gaugeUid: uid}} );
            if(r == undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;

            setGaugeUid(d0.gaugeUid);
            setGaugeTp(d0.gaugeTp);
            setGaugeTpNm(d0.gaugeTpNm);
            setGaugeCd(d0.gaugeCd);
            setGaugeNm(d0.gaugeNm);
            setMaker(d0.maker);
            setModelNm(d0.modelNm);
            setSerialNo(d0.serialNo);
            setBuyDy(d0.buyDy);
            setSaveLoca(d0.saveLoca);
            setCalibCycle(d0.calibCycle);
            setUseYn(d0.useYn);
            setCmnt(d0.cmnt); 
            setImgFileInfo(d0.imgFileInfo);

            setGaugeCalibs(d1);    
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }    
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            printPage();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const printPage = async() => {

        window.print();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        
        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div className="print_type"
                style={{ padding: "20px 20px 0 20px", backgroundColor: "#ffffff" }}
            >
                {/* <div className="fl-l">{authStore.com.name}</div> */}
                {/* <div className="fl-r">
                    {`출력일자: ${util.getDateToString('d',util.getNow(), '-')}`}
                </div> */}
                <table>
                    <colgroup>
						<col width={100} />
                        <col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon">
                                <div
                                    style={{ fontSize: "26px", fontWeight: "bold" }}
                                >
                                {`측정기 관리 대장`}
                                </div>
                                <div>
                                {`출력일자: (${util.getDateToString('d',util.getNow(), '-')})`}
                                </div>
                            </td>                           
                        </tr>                        
                    </tbody>
                </table>                
                <table>
                    <colgroup>
						<col width={100} />                        
                        <col  />
						<col width={100}/>
                        <col width={100} />
                        <col width={260} />
                    </colgroup>
                    <tbody>                        
                        <tr>   
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                측정기명
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{gaugeNm}</span>
                            </td>
                            <td className="tcon"
                                rowSpan={7}
                                style={{paddingTop: 4, paddingBottom: 4}}
                            >
                                <div className="div-img-100" style={{minHeight: 240}}>
                                { 
                                    imgFileInfo != '' ?                                                
                                    cmn.getRenderImg(imgFileInfo, 240) 
                                    :
                                    <p>
                                        이미지 없음.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr>                        
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                측정기코드
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{gaugeCd}</span>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                측정기유형
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                            >
                                <span>{gaugeTpNm}</span>
                            </td>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                교정주기
                            </th>
                            <td className="tcon">
                                <span>{`${calibCycle} (년)`}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                제조사
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{maker}</span>
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                S/N
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{serialNo}</span>
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit"                                
                                style={{height: "30px"}}
                            >
                                구입일자
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                            >
                                <span>{util.getDateStrSplit('d',buyDy)}</span>
                            </td>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                사용여부
                            </th>
                            <td className="tcon">
                                <span>{useYn == 'Y' ? "사용" : "사용안함"}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                보관위치
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{saveLoca}</span>
                            </td>
                        </tr> 
                    </tbody>
                </table>
                <div className="subTitle">
                    교정 이력
                </div>
                <table>
                    <colgroup>
                        <col width={50} />
						<col width={140} />
                        <col width={80} />
						<col width={100} />
                        <col width={100} />
                        <col width={70} />
                        <col width={60} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: "42px"}} >No.</th>
                            <th className="tit">교정처</th>
                            <th className="tit">교정일</th>
                            <th className="tit">교정비용</th>
                            <th className="tit">교정치수</th>
                            <th className="tit">교정결과</th>
                            <th className="tit">성적서</th>
                            <th className="tit">비고</th>
                        </tr>
                        {
                            _.map(gaugeCalibs, (v, k) => {
                                return (
                                    <tr key={k}>
                                        <td className="tcon al-c" style={{height: "50px"}}>
                                            {k+1}
                                        </td>
                                        <td className="tcon">{v.calibComNm}</td>
                                        <td className="tcon">{util.getDateStrSplit('d',v.calibDy)}</td>
                                        <td className="tcon">{util.getNumComma(v.calibCost)}</td>
                                        <td className="tcon">{v.calibSize}</td>
                                        <td className="tcon">{v.calibRet}</td>
                                        <td className="tcon">{cmn.getRenderDownloadFile(v.certFile)}</td>
                                        <td className="tcon">{v.cmnt}</td>
                                    </tr>    
                                )
                            })
                        }
                        {
                            _.times(12 - gaugeCalibs.length, (n) => {
                                return (
                                    <tr key={n}>
                                        <td style={{height: "50px"}}></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>    
                                )
                            })
                        }
                    </tbody>
                </table>    

                <div className="print-bottom">
                    <table>
                        <colgroup>
                            <col width={160}/>
                            <col />
                            <col width={160}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>QF-000-000</td>
                                <td className="al-c">{authStore.com.name}</td>
                                <td className="al-r">A4 (210x297mm)</td>     
                            </tr>
                        </tbody>
                    </table> 
                </div> 

            </div>
            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"측정기 관리대장"}
            content = {renderContent()}
        />
        </>
    )
}

export default GaugeHisRepoPage;

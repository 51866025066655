import React, { useState, useEffect} from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  점검주기 선택 박스
 */
const ChkCycleSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    /* ====================
     *  Object
     * ==================== */

    const {

        width='80px',
        value,
        placeholder='점검주기',

        equpUid,
        showClearButton=true,

        onLoadInfo,
        onValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [chkCycles, setChkCycles] = useState([]);

    useEffect(() => {
        loadPage();
    },[equpUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {       
        try {
            if(util.getChkNull(equpUid) === ''){
                setChkCycles([]); return;
            }
    
            const r = await cmn.api("getEqupChkCycleEqup", {p: {equpUid: equpUid}});
            if(r === undefined || r == null) return;
            setChkCycles(r.d0);
    
            if(util.getChkNull(value) !== ''){
                handleLoadInfo(r.d0, value);
            }
        } 
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleLoadInfo = (ds, v) => {
        const o = _.find(ds, {chkCycleTp: v});
        onLoadInfo(o);
    }
    
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <SelectBox 
                width={width}
                placeholder={placeholder}
                value={value}
                dataSource={chkCycles}
                displayExpr={'chkCycleTpNm'}
                valueExpr={'chkCycleTp'}

                showClearButton={showClearButton}

                onValueChanged={(e)=>{
                    onValueChanged(e.value);
                    handleLoadInfo(chkCycles, e.value);
                }} 
            />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default ChkCycleSelectBox;

import React, { useState, useEffect, useContext } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore} from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  창고 등록 / 수정
 */
const StorPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [storNm, setStorNm] = useState('');
    const [storImgUid, setStorImgUid] = useState('');
    const [storCd, setStorCd] = useState('');
    const [area, setArea] = useState(0);
    const [line, setLine] = useState(0);
    const [cell, setCell] = useState(0);
    const [cmnt, setCmnt] = useState('');


    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {            
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api('getStor', {p: {storUid: uid}} );
            if(r == null || r === undefined) return;
            
            const data = r.d0[0];
            
            setStorNm(data.storNm);
            setStorImgUid(data.storImgUid);
            setStorCd(data.storCd);
            setArea(data.area);
            setLine(data.line);
            setCell(data.cell);
            setCmnt(data.cmnt);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(storNm, '창고명'))
                return;

            if(mode == "a")
            { 
                if(util.confirmYn("등록"))
                    addStor();
            }
            else if(mode == "u") 
            {
                if(util.confirmYn("수정"))
                    upStor();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addStor = async() => {

        const para = {
            p: 
                {
                    comUid: authStore.com.uid,
                    storUid: util.getUUID(),
                    storNm: storNm,
                    storImgUid: storImgUid,
                    storCd: storCd,
                    area: area,
                    line: line,
                    cell: cell,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid
                }
        }

        const r = await cmn.api("addStor", para);
        if(r == undefined || r == null) return;

        util.cmnSucAlert('등록'); 

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upStor = async() => {
        const para = {
            p: 
                {
                    storUid: uid,
                    storNm: storNm,
                    storImgUid: storImgUid,
                    storCd: storCd,
                    area: area,
                    line: line,
                    cell: cell,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid
                }
        }
        
        const r = await cmn.api("upStor", para);
        if(r == undefined || r == null) return;

        util.cmnSucAlert('수정'); 
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode == "a" ? "등록완료" 
            : mode == "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon w30p" />
						<col className="tit" />
						<col className="tcon w30p" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">창고명</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={storNm}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setStorNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{storNm}</span>
                                }
                            </td>
                            
                        </tr>
                        <tr>
                            <th className="tit">창고코드</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={storCd}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setStorCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{storCd}</span>
                                }
                            </td>
                            {/* <th className="tit">Area</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'100px'}
                                        format={'#,##0'}
                                        value={area}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setArea(e.value);
                                        }} 
                                    />
                                :
                                    <span>{storNm}</span>
                                }
                            </td> */}
                            
                        </tr>
                        {/* <tr>
                            <th className="tit">Line</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'100px'}
                                        format={'#,##0'}
                                        value={line}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setLine(e.value);
                                        }} 
                                    />
                                :
                                    <span>{storNm}</span>
                                }
                            </td>
                            <th className="tit">Cell</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'100px'}
                                        format={'#,##0'}
                                        value={cell}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setCell(e.value);
                                        }} 
                                    />
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr> */}
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "창고 등록" 
                : mode == "u" ? "창고 수정"
                : "창고" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default StorPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { TreeList, Selection as TreeSelection, Column as TreeColumn } from 'devextreme-react/tree-list';
import SplitterLayout from 'react-splitter-layout';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, TreeListView,
    CodeSelectBox, SrchButton, SubActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공통코드 관리
 */
const ProdProcPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    /* ====================
     *  State / Effect
     * ==================== */
    const bomTreeList = useRef(null);
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodTp, setProdTp] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');

    /* #region Search Items */

    const searchItems = [
        [
            {   title: "제품유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={prodTp}
                    classId={'101'}
                    placeholder="제품유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setProdTp(v);
                    }}
                />
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />  
            },
            {   title: "품번",
                item: 
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />      
            }
        ]
    ];

    /* #endregion */    

    // * 목록 선택 코드 UID
    // * Grid, Paging & Sorting
    const [prodList, setProdList] = useState([]);
    const [prodListTot, setProdListTot] = useState(0);
    const [bomTree, setBomTree] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItem2, setSelectedItem2] = useState(null);

    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, prodTp]);

    useEffect(() => {
        !pageLoad && handleClickSearch2();
    }, [selectedItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodTp: prodTp, 
                prodId: prodId, 
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getProdList", ps );
        if(r === undefined || r == null) return;

        setProdList(r.d0)
        setProdListTot(r.tot);
    }

    const loadList2 = async() => {
        if(selectedItem == null) return; 
        
        const ps =  {
            p: {
                prodUid: selectedItem.prodUid
            }
        }

        const r = await cmn.api("getBomTree", ps );
        if(r === undefined || r == null) return;

        setBomTree(r.d0)
        
        // Expand All
        const items = _.filter(bomTree,  (v, k) => { 
            return v.parentTaskId !== "0" || v ? true : false 
        });

        _.forEach(_.uniqBy(items, 'parentTaskId'), (v, k) => {
            bomTreeList.current.instance.expandRow(v.parentTaskId);
        })
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickSearch2 = async(e) => {
        try {
            await loadList2();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("a", selectedItem.prodUid, "");
        }
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem2)){
            if(util.chkListSelected(selectedItem2.bomUid)){
                openCrudPopup("u", selectedItem.prodUid, selectedItem2.bomUid);
            }
        }
    }

    const handleClickDel = async () => {
        try {
            if(util.chkListSelected(selectedItem2)){
                if(util.chkListSelected(selectedItem2.bomUid)){
                    if(!util.confirmYn('삭제')) return;
                    const ps =  {
                        p: {bomUid: selectedItem2.bomUid}
                    }
                    let r = await cmn.api('delBom', ps);
                    if(r == null || r === undefined) return;

                    util.cmnSucAlert('삭제');

                    loadList2();
                }
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGrid2SelectionChanged =  ({selectedRowsData}) => {
        if(selectedRowsData.length < 1) return;
        if(selectedRowsData[0].parentTaskId === "0"){
            const nodeKey = selectedRowsData[0].taskId;
            const refObj = bomTreeList.current.instance;
            if(refObj.isRowExpanded(nodeKey)) refObj.collapseRow(nodeKey);
            else refObj.expandRow(nodeKey);            
            refObj.clearSelection();
            setSelectedItem2(null);
            return;
        }

        setSelectedItem2(selectedRowsData[0]);
    }
    

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);        
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    const openCrudPopup = (mode, pduid, bomuid) => {
        // page mode: v(View), a(Add), u(Update)
       //alert(bomuid);
        const url = `/base/prodbompop?${qs.stringify({mode: mode, puid: pduid, uid: bomuid})}`;
        util.openWindow(url, 650, 500);
    }

    /* ====================
     *  Render
     * ==================== */

    // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderLeftContent = () => {

        return (
            <>
            <GridTopView
                title="제품 내역"
            >
            </GridTopView>                 
            <DataGridView            
                dataSource={prodList}
                gridHeight={authStore.windowHeight - cmn.BH.H21}

                // paging
                page={curPage}
                total={prodListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}

                colsWidth={[100, 140, 140, 100, 100, null]}
            >
                { cmn.getGridColumn('prodTpNm', '제품유형', renderGridHeaderCell)}
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell)}
                { cmn.getGridColumn('prodMattTpNm', '재질', renderGridHeaderCell)}
                { cmn.getGridColumnCmnt() }
            </DataGridView>
            </>
        )   
    }

    const renderRightContent = () => {

        return (
            <>
            <GridTopView
                title="BOM"
            >
                <SubActionButton 
                    onClick={handleClickAdd}
                    title="추가"
                    show={authStore.page.crtYn}
                /> 
                <SubActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />  
                <SubActionButton 
                    onClick={handleClickDel}
                    title="삭제"
                    marginRight={0}
                    show={authStore.page.delYn}
                />
            </GridTopView>             
            
            <TreeListView
                reff={bomTreeList}
                height={authStore.windowHeight - cmn.BH.H20}
                dataSource={bomTree}
                
                onSelectionChanged={handleGrid2SelectionChanged}

                keyExpr={'taskId'}
                parentIdExpr={'parentTaskId'}

              //  onSelectionChanged={handleTreeListSelectionChanged}
            >
                <TreeColumn dataField={'prodNm'} caption={'품명'} />
                <TreeColumn dataField={'prodId'} caption={'품번'} alignment={'center'} />
                <TreeColumn dataField={'prodStnd'} caption={'규격'} alignment={'center'}  />
                <TreeColumn dataField={'putAmt'} caption={'투입수'} alignment={'center'} width={80} />
                <TreeColumn dataField={'putTpNm'} caption={'투입단위'} alignment={'center'}  width={80} />
            </TreeListView>            
            </>
        )   

    }
 
    const renderContent = () => {

        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    marginRight={0}
                    onClick={handleClickSearch}
                />
                <SrchButton 
                    id="subSrchButton"
                    marginRight={0}
                    onClick={handleClickSearch2}
                    show={false}
                />                
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <div className="cont-split"
                style={{
                    marginTop: 16,
                    // width: `calc(100% - 100px)`
                }}
            >
                <SplitterLayout
                    customClassName={`split-layout-cust ${authStore.sideOpened === true ? "is-visible" : ""}`}
                >
                    <div className="cont-split-left"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50 
                        }}
                    >
                        { renderLeftContent() }
                    </div>
                    <div className="cont-split-right"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50 
                        }}
                    >
                        { renderRightContent() }
                    </div>
                </SplitterLayout>
            </div>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ProdProcPage;

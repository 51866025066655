import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, 
    DayDateBox, SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  X-R 관리도
 */
const XRControlPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate(-30));
    const [endDy, setEndDy] = useState(util.getNow());    
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "검사기간",
                width: "180px",
                item: 
                    <>
                    <ul className="ul-row">
                        <li>
                            <DayDateBox 
                                value={startDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setStartDy(e.value);
                                }}                    
                            />
                        </li>
                        <li>
                            ~
                        </li>
                        <li>            
                            <DayDateBox 
                                value={endDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setEndDy(e.value);
                                }}                    
                            />
                        </li>
                    </ul>
                    </>
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                   
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",               
                item: 
                <>
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />     
                </>           
            }
        ]
    ];
    /* #endregion */

    // * Grid
    const [procInspHisListXr, setProcInspHisListXr] = useState([]);
    const [procInspHisListXrTot, setProcInspHisListXrTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    // const dgCodeListRef = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);


    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),

                offset: rowsOffset,
                limit: rowsLength,
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
            }
        }

        

        const r = await cmn.api("getProcInspHisListXr", ps );
        if(r === undefined || r == null) return;

        setProcInspHisListXr(r.d0)
        setProcInspHisListXrTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }   
    }

    const handleClickDtl = (e) => {
        openCrudPopup("v", selectedItem.prodProcUid, selectedItem.prodProcInspUid, util.getDateToString('d',startDy), util.getDateToString('d',endDy));
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;        
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        else if(     gc.column.dataField === "buyDy" 
            ||  gc.column.dataField === "lastCalibDy" 
            ||  gc.column.dataField === "nextCalibDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
        else if(
            gc.column.dataField === "startDy" 
        ) e.value = `${util.getDateToString('d',gc.data.startDy, '-')} ~ ${util.getDateToString('d',gc.data.endDy, '-')}`;
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Funtions

    const openCrudPopup = (mode, ppuid, pihuid, sdy, edy) => {
        const url = `/qual/xrcontrolpop?${qs.stringify({mode: mode, ppuid: ppuid, pihuid: pihuid, sdy: sdy, edy: edy})}`;
        util.openWindow(url, 1140, 920);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickDtl}
                    title="상세보기"
                    show={authStore.page.crtYn}
                />  
            </ListTopView>
            }</Observer>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="X-R 관리도 내역"
            >
                {/* <IconButton
                    className="btn btn-isz ico-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn btn-isz ico-exldown"
                    marginRight={0}
                />
            </GridTopView>
            <Observer>{() =>
            <DataGridView
                reff={grid1}
                dataSource={procInspHisListXr}
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd, cmn.CW.procNm, 
                    140, cmn.CW.inspStnd, 160, cmn.CW.amt, 100,
                    300, 100, 100, 100,
                    300, 100, 100, 100,
                    700, 100, 100, 100, 100, 100, 100, 100,
                    null
                ]}
                // paging
                page={curPage}
                total={procInspHisListXrTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}
                // excel
                exlnm={'X-R관리도'}
                customizeExcelCell={customizeExcelCell}  
            >
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell ) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell ) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell ) }
                { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell ) }  

                { cmn.getGridColumn('inspNm', '검사항목', renderGridHeaderCell ) }  
                { cmn.getGridColumn('dispStnd', '검사규격', renderGridHeaderCell ) }  
                <Column 
                    alignment={'center'}
                    caption={'검사기간'}
                    dataField={'startDy'}
                    cellRender={(d) => {
                        return <span>{`${util.getDateToString('d',d.data.startDy, '-')} ~ ${util.getDateToString('d',d.data.endDy, '-')}`}</span>
                    }}
                />
                { cmn.getGridColumn('inspCnt', '검사횟수', renderGridHeaderCell ) }  
                { cmn.getGridColumn('', '공정도면', renderGridHeaderCell ) }  
                <Column
                    alignment={'center'}
                    caption={"X"}
                >
                    { cmn.getGridColumn('xcl', 'CL', renderGridHeaderCell ) }  
                    { cmn.getGridColumn('xucl', 'UCL', renderGridHeaderCell ) }  
                    { cmn.getGridColumn('xlcl', 'LCL', renderGridHeaderCell ) }  
                </Column>
                <Column
                    alignment={'center'}
                    caption={"R"}                      
                >
                    { cmn.getGridColumn('rcl', 'CL', renderGridHeaderCell ) }  
                    { cmn.getGridColumn('rucl', 'UCL', renderGridHeaderCell ) }  
                    { cmn.getGridColumn('rlcl', 'LCL', renderGridHeaderCell ) }
                </Column>    
                <Column
                    alignment={'center'}
                    caption={"CpK"}                      
                >
                    { cmn.getGridColumn('inspValStd', 'σ', renderGridHeaderCell ) }  
                    { cmn.getGridColumn('stndUpperVal', 'Su', renderGridHeaderCell ) }  
                    { cmn.getGridColumn('stndLowerVal', 'Sl', renderGridHeaderCell ) }
                    { cmn.getGridColumn('cp', 'Cp', renderGridHeaderCell ) }
                    { cmn.getGridColumn('cpu', 'CpU', renderGridHeaderCell ) }
                    { cmn.getGridColumn('cpl', 'CpL', renderGridHeaderCell ) }
                    { cmn.getGridColumn('cpk', 'CpK', renderGridHeaderCell ) }
                </Column>

                <Column /> 
            </DataGridView>            
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default XRControlPage;
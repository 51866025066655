import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';
import moment from 'moment';


/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    UserSelectBox,
    ProdSearchBox,
    DataGridView,
} from '../../components';

/** stores & lib */
import { AuthStore, StockStore, ProdProcStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  재공재고 등록 / 수정
 */
const FirstIngStockPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore =  useContext(CmnStore);    

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState('');
    const [workDy, setWorkDy] = useState(util.getNow());
    const [cmnt, setCmnt] = useState('');

    const [procItems, setProcItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

  
    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = () => {
        try {  
            //if(pageLoad) return;
            // if(mode != "a"){
            //     // 재공재고정보조회
            //     //loadFirstIngStockInfo();
            // }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // const loadFirstIngStockInfo = async() => {
    //     try {  
    //         let r = await cmn.api('getFirstIngStock', {p:{}})
    //         const resData = await stockStore.getFirstIngStock(uid);
    //         console.log("loadFirstIngStockInfo ===> ",resData);
    //         if(resData.code != RCODE_S){
    //             // ...
    //             alert(`error: ${resData.message}`);
    //             return;
    //         }

    //         const data = resData.data.items[0];
    //     }
    //     catch(err){
    //         cmnStore.handleErrorPage(err, props);
    //     }
    // }

    const loadProdProcInfo = async(e) => {
        try { 
            if(util.getChkNull(e) === '')
                return;

            let r = await cmn.api('getProdProcFirst', {p:{prodUid:e}});

            let data = r.d0;
            let procItems_ = [];
            _.forEach(data, (v, k) => {
                procItems_.push({
                    prodProcUid: v.prodProcUid,
                    procNm: v.procNm,
                    procTp: v.procTp,
                    procAmt: 0,
                    bizUid: v.bizUid,
                    bizNm: v.bizNm,
                    outAmt: 0,
                    firstYn: v.firstYn,
                    lastYn: v.lastYn,
                    cmnt:'최초재공재고'
                })
            });
            
            setProcItems(procItems_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try { 
            if(!util.chkMustValue(userUid, '작업자'))
                return;
            if(!util.chkMustValue(prodUid, '제품'))
                return;
            if(!util.chkMustValue(_.sumBy(procItems, 'procAmt') + _.sumBy(procItems, 'outAmt'), '수량', 'cnt'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addFirstIngStock();
            }
            // else if(mode == "u") 
            // {
            //     if(util.confirmYn("수정"))
            //         upFirstIngStock();
            // }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addFirstIngStock = async() => {

        const workNumPara = {
            p:{
                comUid: authStore.com.uid,
                prodUid: prodUid,
                workDy: util.getDateToString('d', util.getNow()),
                firstAmt: _.sumBy(procItems, 'procAmt') + _.sumBy(procItems, 'outAmt'),
                inputTp: cmn.CMNCO_WORKNUMTP.FIRST,
                prefix: cmn.PREFIX.MANUFACTURE
            }
        }
        let workNum_ =  await cmnStore.getWorkNumCrt(workNumPara);

        let para = {p: {workNum: workNum_}};
        let r2 = await cmn.api('getWorkNum', para);
        if(r2 == null || r2.d0.length == 0) {
            util.cmnAlert('등록된 정보가 없습니다.');
            return;
        }

        let data = r2.d0[0];
        let format_ = 'YYYY-MM-DD HH:mm';
        let dyStartTm_ = moment(`${util.getNow()} ${util.getDateStrSplit('t' , data.dyStartTm, ':')}`).format(format_);
        let dyEndTm_ = moment(`${util.getNow()} ${util.getDateStrSplit('t' , data.dyEndTm, ':')}`).format(format_);
      // 넘어오는 Lot 가 없을 경우 새로 생성함
        let preLot_ = cmn.getCrtDyLotNum(workNum_, data.lotTp, data.dyNtUseYn, dyStartTm_, dyEndTm_, data.dyChar, data.ntChar)

        const lotPara = {
            p:{
                comUid: authStore.com.uid,
                workNum: workNum_,
                lot: preLot_
            }
        }

        let lot_ = await cmnStore.getLotCrt(lotPara)

        const ps = {
            firstIngStock: [
                {
                    firstIngStockUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    workDy: util.getDateToString('d',workDy),
                    workNum: workNum_,
                    lot: lot_,
                    workAmt: _.sumBy(procItems, 'procAmt') + _.sumBy(procItems, 'outAmt'),
                    prodUid: prodUid,
                    userUid: userUid,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                    procItems:procItems,
                    sumProcAmt:_.sumBy(procItems, 'procAmt') + _.sumBy(procItems, 'outAmt') //재공재고 등록시 전체 수량을 첫번째 공정의 생산수량을 계산
                }
            ]
        }

        let r = await cmn.api('addFirstIngStock', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // const upFirstIngStock = async() => {

    //     const ps = {
    //         firstIngStock: [
    //             {
    //                 firstIngStockUid: util.getUUID(),
    //                 userUid: userUid,
    //                 uptUserUid: authStore.user.uid
    //             }
    //         ]
    //     }

    //     const resData = await stockStore.upFirstIngStock(ps);

    //     if(resData.code != RCODE_S){
    //         // ...
    //         alert(`error: ${resData.message}`);
    //         return;
    //     }
    //     util.cmnSucAlert("수정");
    //     // Reload Opener List & Close Popup
    //     util.clickOpenerElement("srchButton");
    //     window.close();
    // }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">작업일</th>
                            <td className="tcon">
                            <DayDateBox 
                                value={workDy}
                                onValueChanged={(e) => {
                                    setWorkDy(e.value);
                                }}                    
                            />
                            </td>
                            <th className="tit req">작업자</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                <UserSelectBox
                                    mode={mode}
                                    value={userUid}
                                    placeholder="등록자를 선택하세요"
                                    onValueChanged={(v) => { 
                                        setUserUid(v);
                                    }}
                                />
                                :
                                <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">제품</th>
                            <td className="tcon" colSpan={3}>
                                {
                                    mode === "a" ? 
                                    <ProdSearchBox
                                        popCenter={true}
                                        value={prodUid}
                                            onLoadInfo={(o) => {
                                            }} 
                                            onValueChanged={(v) => {                                        
                                                setProdUid(v);
                                                loadProdProcInfo(v);
                                            }}
                                        />
                                    :
                                    <span>{prodId}</span>
                                }
                            </td>
                        </tr>
                        {/* <tr>
                            <th className="tit req">총 작업수량</th>
                            <td className="tcon" colSpan={3}>
                                    <span>{workAmt}</span>
                            </td>
                        </tr> */}
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    //valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="pop-grid">
                <div className="clearfix">
                    <DataGridView
                        dataSource={procItems}
                        pagingable={false}
                        gridHeight={270}
                        selection=''
                    >
                        <Column 
                            width={120} 
                            alignment={'center'}
                            dataField={'procNm'} 
                            caption={'공정'} 
                        />
                        <Column 
                            width={100} 
                            alignment={'center'}
                            dataField={'procAmt'} 
                            caption={'제품수량'} 
                            cellRender={(d) => {
                                return (
                                    <NumberBox
                                        width={80}
                                        format={'#,##0'}
                                        value={d.data.procAmt}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            //alert(e.value + ', ' + d.data.procAmt);
                                            const procItems_ = [...procItems];
                                            procItems_[d.rowIndex].procAmt = e.value;
                                            setProcItems(procItems_);
                                            //alert(procItems[d.rowIndex].procAmt);
                                        }}
                                    />
                                )
                            }}
                        />
                        <Column 
                            width={120} 
                            alignment={'center'}
                            dataField={'bizNm'} 
                            caption={'입고/외주출고 업체'} 
                        />
                        <Column 
                            width={100} 
                            alignment={'center'}
                            dataField={'outAmt'} 
                            caption={'출고수량'} 
                            cellRender={(d) => {
                                return (
                                    <>
                                    {
                                        (d.data.bizNm === '' || d.data.bizNm == null) || d.data.procTp === '10401'?
                                        ''
                                        :
                                        <NumberBox
                                            width={80}
                                            format={'#,##0'}
                                            value={d.data.outAmt}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                procItems[d.rowIndex].outAmt = e.value;
                                            }}
                                        />
                                        
                                    }
                                    </>
                                )
                            }}
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'cmnt'} 
                            caption={'비고'} 
                            cellRender={(d) => {
                                return (
                                    <TextBox 
                                    value={d.data.cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    onValueChanged={(e)=>{
                                        procItems[d.rowIndex].cmnt = e.value;
                                    }}
                                /> 
                                )
                            }}
                        />
                    </DataGridView>
                </div>
            </div>        
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "재공재고 등록" 
                : "재공재고" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default FirstIngStockPopPage;

const procApiz = {
    
    getProcList: {
        title: "공정 목록 조회",
        url: "/api/proc/getProcList",
        method: "POST"
    },
    getProc: {
        title: "공정 상세 조회",
        url: "/api/proc/getProc",
        method: "POST"
    },
    addProc: {
        title: "공정 등록",
        url: "/api/proc/addProc",
        method: "POST"
    },
    upProc: {
        title: "공정 수정",
        url: "/api/proc/upProc",
        method: "POST"
    },
    delProc: {
        title: "공정 삭제",
        url: "/api/proc/delProc",
        method: "POST"
    },
    getProcCombo: {
        title: "공정 콤보",
        url: "/api/proc/getProcCombo",
        method: "POST"
    },  
    getProcFlow: {
        title: "공정이동표 조회",
        url: "/api/proc/getProcFlow",
        method: "POST"
    },  
    
}

export default procApiz;
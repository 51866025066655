import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    ChkCycleSelectBox, SeqSelectBox, CodeSelectBox, FileUploadBox,
    PopupLayout, ActionButton,
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  설비 등록 / 수정
 */
const EqupChkItemPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
        ciuid = ls.ciuid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [equpUid, setEqupUid] = useState(uid);
//    const [equpChkItemUid, setEqupChkItemUid] = useState(ciuid);

    const [sortOrderMax, setSortOrderMax] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortOrderOld, setSortOrderOld] = useState(0);

    const [chkNm, setChkNm] = useState('');
    const [chkCont, setChkCont] = useState('');

    const [chkTp, setChkTp] = useState('');

    const [equpChkCycleUid, setEqupChkCycleUid] = useState('');
    const [chkCycleTp, setChkCycleTp] = useState('');
    const [chkCycleTpNm, setChkCycleTpNm] = useState('');
    const [chkCycleObj, setChkCycleObj] = useState(null);

    const [valSelectTp, setValSelectTp] = useState('');
    const [dispStnd, setDispStnd] = useState('');

    const [stndVal, setStndVal] = useState(0);
    const [upperVal, setUpperVal] = useState(0);
    const [lowerVal, setLowerVal] = useState(0);

    const [stndValDis, setStndValDis] = useState(false);
    const [upperValDis, setUpperValDis] = useState(false);
    const [lowerValDis, setLowerValDis] = useState(false);

    const [chkImgUid, setChkImgUid] = useState(util.getUUID());
    const [uploadFiles, setUploadFiles] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && renderChkCycleInfo();
    },[chkCycleObj]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {          
            if(mode === 'a') return;

            const r = await cmn.api("getEqupChkItem", {p: {equpChkItemUid: ciuid}});
            if(r === undefined || r == null) return;

            const d0 = r.d0[0];

         //   console.log('d0==========>', d0)
            chgValSelectTp(d0.valSelectTp);

            setEqupChkCycleUid(d0.equpChkCycleUid); 
            setSortOrder(d0.sortOrder);
            setSortOrderOld(d0.sortOrderOld);
            setSortOrderMax(d0.sortOrderMax);
            setChkTp(d0.chkTp);
            setChkNm(d0.chkNm);
            setChkCont(d0.chkCont);
            setChkCycleTp(d0.chkCycleTp);
            setChkCycleTpNm(d0.chkCycleTpNm);
            setValSelectTp(d0.valSelectTp);
            setDispStnd(d0.dispStnd);
            setStndVal(d0.stndVal);
            setUpperVal(d0.upperVal);
            setLowerVal(d0.lowerVal);
            setChkImgUid(d0.chkImgUid == null ? util.getUUID() : d0.chkImgUid);

            setChkCycleObj(d0);

            setUploadFiles(r.f1);

            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {        
            if(!util.chkMustValue(chkNm, '점검명')) return;
            if(!util.chkMustValue(chkCont, '점검내용')) return;
            if(!util.chkMustValue(chkCycleTp, '점검주기')) return;
            if(!util.chkMustValue(valSelectTp, '점검유형')) return;
            if(!util.chkMustValue(dispStnd, '출력(규격)')) return;
            if(!util.chkMustValue(stndVal, '규격')) return;

            if(mode === "a") { 
                if(!util.confirmYn('등록')) return;
                add();
            }
            else if(mode === "u") {
                if(!util.confirmYn('수정')) return;
                up();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const add = async() => {

        const ps = {
            p:  {
                equpChkCycleUid: equpChkCycleUid,
                sortOrder: sortOrder,
                chkTp: chkTp,
                chkNm: chkNm,
                chkCont: chkCont,
                valSelectTp: valSelectTp,
                dispStnd: dispStnd,
                stndVal: stndVal,
                upperVal: upperVal === undefined ? null : upperVal,
                lowerVal: lowerVal === undefined ? null : lowerVal,
                chkImgUid: chkImgUid,
                uploadFiles: uploadFiles
            }
        }

//        console.log(ps);

        const r = await cmn.api("addEqupChkItem", ps);
        if(r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    const up = async() => {
        const ps = {
            p: {
                equpChkItemUid: ciuid,
                equpChkCycleUid: equpChkCycleUid,
                sortOrder: sortOrder,
                sortOrderOld: sortOrderOld,
                chkTp: chkTp,
                chkNm: chkNm,
                chkCont: chkCont,
                valSelectTp: valSelectTp,
                dispStnd: dispStnd,
                stndVal: stndVal,
                upperVal: upperVal === undefined ? null : upperVal,
                lowerVal: lowerVal === undefined ? null : lowerVal,
                chkImgUid: chkImgUid,
                uploadFiles: uploadFiles
            }
        }

        const r = await cmn.api("upEqupChkItem", ps);
        if(r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("subSrchButton");
        window.close();
    }   
    
    const chgValSelectTp = (e) => {
        if(util.getChkNull(e) === '') return;

        if(e === cmn.CMNCO_VALSELTP.SEL)
        {
            setDispStnd('양호/불량');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);

            setStndValDis(true);
            setUpperValDis(true);
            setLowerValDis(true);

        } else if(e === cmn.CMNCO_VALSELTP.TOPBOT)
        {
            setDispStnd('');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);
            
            setStndValDis(false);
            setUpperValDis(false);
            setLowerValDis(false);
        } else if(e === cmn.CMNCO_VALSELTP.TOP)
        {
            setDispStnd('');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);
            
            setStndValDis(false);
            setUpperValDis(true);
            setLowerValDis(true);            
        } else if(e === cmn.CMNCO_VALSELTP.BOT)
        {
            setDispStnd('');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);
            
            setStndValDis(false);
            setUpperValDis(true);
            setLowerValDis(true);
        }
    }

    /* ====================
     *  Render
     * ==================== */

    const renderChkCycleInfo = () => {
        const disp = cmn.getDispChkCycleInfo(chkCycleObj);
        return(
            <>
            <span>{disp}</span>    
            </>
        )
    }

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon"/>
						<col className="tit" />
						<col className="tcon" width={120} />
						<col className="tit" />
						<col className="tcon" width={120} />                 
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">점검명</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox                                         
                                        value={chkNm}
                                        showClearButton={true}
                                        placeholder="점검명을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setChkNm(e.value);
                                        }} 
                                    />                                 
                                    :
                                    <span>{chkNm}</span>
                                }
                            </td>
                            <th className="tit">점검구분</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <CodeSelectBox
                                    value={chkTp}
                                    classId={'404'}
                                    placeholder="점검구분 선택"
                                    onValueChanged={(v, o) => { 
                                        setChkTp(v);
                                    }}
                                /> 
                                :
                                <span>{sortOrder}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">점검내용</th>
                            <td className="tcon" colSpan={5}>
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox                                         
                                        value={chkCont}
                                        showClearButton={true}
                                        placeholder="점검내용을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setChkCont(e.value);
                                        }} 
                                    />                                 
                                    :
                                    <span>{chkCont}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">점검주기</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                    <>
                                    <ul className="ul-row">
                                        <li>
                                            <ChkCycleSelectBox 
                                                value={chkCycleTp}
                                                equpUid={equpUid}
                                                // valueChangeEvent={'keyup'}
                                                onLoadInfo={(o)=>{
                                                  // console.log("---ChkCycleSelectBox o ", o);
                                                    setChkCycleObj(o);
                                                    setEqupChkCycleUid(o.equpChkCycleUid);
                                                    if(o === undefined) setSortOrderMax(undefined);
                                                    else setSortOrderMax(o.sortOrderMax);
                                                }}
                                                onValueChanged={(v)=>{
                                                    setChkCycleTp(v);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                            { renderChkCycleInfo() }
                                        </li>
                                    </ul>
                                    </>
                                :
                                renderChkCycleInfo()
                                }
                            </td>
                            <th className="tit">점검순서</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <SeqSelectBox 
                                    mode={mode}
                                    value={sortOrder}
                                    cnt={sortOrderMax}                         
                                    onValueChanged={(v)=>{
                                        setSortOrder(v);
                                    }} 
                                />
                                :
                                <span>{sortOrder}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon"/>
						<col className="tit" />
						<col className="tcon" width={120} />
						<col className="tit" />
						<col className="tcon" width={120} />                 
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">점검유형</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={valSelectTp}
                                        classId={'304'}
                                        showClearButton={false}
                                        onValueChanged={(v) => { 
                                            setValSelectTp(v);
                                            chgValSelectTp(v);
                                        }}
                                    />
                                : 
                                    <span>{valSelectTp}</span>
                                }
                            </td>
                            <th className="tit req">출력(규격)</th>
                            <td className="tcon" colSpan={3}>
                                {
                                    mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={dispStnd}
                                        showClearButton={true}
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setDispStnd(e.value);
                                        }}
                                    /> 
                                    :
                                        <span>{dispStnd}</span>
                                }
                            </td>                    
                        </tr>  
                        <tr>
                            <th className="tit req">규격</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'100%'}
                                        format={'#,##0.####'}
                                        value={stndVal}
                                        defaultValue={0}
                                        disabled={stndValDis}
                                        //min={0}
                                        showSpinButtons={true}                                    
                                        onValueChanged={(e)=>{
                                            setStndVal(e.value);
                                            if(valSelectTp === cmn.CMNCO_VALSELTP.TOP)
                                                setLowerVal(e.value);
                                            else if (valSelectTp === cmn.CMNCO_VALSELTP.BOT)
                                                setUpperVal(e.value);
                                        }} 
                                    />
                                :
                                    <span>{stndVal}</span>
                                }
                            </td>
                            <th className="tit">상한값</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <NumberBox
                                    width={'100%'}
                                    format={'#,##0.###'}
                                    value={upperVal}
                                    defaultValue={0}
                                    disabled={upperValDis}
                                    // min={0}
                                    showSpinButtons={true}                                    
                                    onValueChanged={(e)=>{
                                        setUpperVal(e.value);
                                    }} 
                                />
                                :
                                    <span>{upperVal}</span>
                                }
                            </td>
                            <th className="tit">하한값</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <NumberBox
                                    width={'100%'}
                                    format={'#,##0.###'}
                                    value={lowerVal}
                                    defaultValue={0}
                                    disabled={lowerValDis}
                                    // min={0}
                                    showSpinButtons={true}                                    
                                    onValueChanged={(e)=>{
                                        setLowerVal(e.value);
                                    }} 
                                />
                                :
                                    <span>{lowerVal}</span>
                                }
                            </td>                            
                        </tr>                         
                    </tbody>
                </table>
            </div>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">점검이미지</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <>
                                 <FileUploadBox
                                    limit={1}
                                    grpUid={chkImgUid}
                                    accept={'image/*'}
                                    userUid={authStore.user.uid}
                                    uploadFiles={uploadFiles}

                                    onUploaded={(e) => {
                                        setUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setUploadFiles(e);
                                    }}
                                />
                                </>
                                :
                                <span>{chkImgUid}</span>
                            }
                            </td>
                            
                        </tr>
                      
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "설비 점검항목 등록" 
                : mode === "u" ? "설비 점검항목 수정"
                : "설비 점검항목" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default EqupChkItemPopPage;
const cmnApiz = {
    
    getSortOrderCombo: {
        title: "정렬순서 조회 콤보",
        url: "/api/cmn/getSortOrderCombo",
        method: "POST"
    },
    getSortOrderCnt: {
        title: "정렬순서 조회 카운트",
        url: "/api/cmn/getSortOrderCnt",
        method: "POST"
    },
    upSortOrder: {
        title: "정렬순서 수정",
        url: "/api/cmn/upSortOrder",
        method: "POST"
    },
    getLotCrt: {
        title: "Lot 생성 및 조회",
        url: "/api/cmn/getLotCrt",
        method: "POST"
    },
    getLot: {
        title: "Lot 조회",
        url: "/api/cmn/getLot",
        method: "POST"
    },
    getLotListCombo: {
        title: "Lot 목록 Combo",
        url: "/api/cmn/getLotListCombo",
        method: "POST"
    },
    getLotComboStock: {
        title: "Lot 목록 Combo (재고수량)",
        url: "/api/cmn/getLotComboStock",
        method: "POST"
    },
    getWorkNumCrt: {
        title: "작업번호 생성 및 조회",
        url: "/api/cmn/getWorkNumCrt",
        method: "POST"
    },
    getWorkNum: {
        title: "작업번호 조회",
        url: "/api/cmn/getWorkNum",
        method: "POST"
    },
    getWorkNumListCombo: {
        title: "작지번호 목록 Combo",
        url: "/api/cmn/getWorkNumListCombo",
        method: "POST"
    },
    // getWorkNumCombo: {
    //     title: "작업지시번호 콤보",
    //     url: "/api/work/getWorkNumCombo",
    //     method: "POST"
    // },
    upWorkNumCompYn: {
        title: "공정 마감 수정",
        url: "/api/cmn/upWorkNumCompYn",
        method: "POST"
    },
    getDyMon: {
        title: "월 일자 정보 조회",
        url: "/api/cmn/getDyMon",
        method: "POST"
    },
    uploadFile: {
        title: "파일 업로드",
        url: "/api/cmn/uploadFile",
        method: "POST"
    }, 
    downloadFile: {
        title: "파일 다운로드",
        url: "/api/cmn/downloadFile",
        method: "GET"
    },   
    addLog: {
        title: "로그 등록",
        url: "/api/cmn/addLog",
        method: "POST"
    },    
    getUseLogList: {
        title: "사용 로그 목록",
        url: "/api/cmn/getUseLogList",
        method: "POST"
    },    
}

export default cmnApiz;
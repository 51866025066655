import React  from 'react';
import { DateBox } from 'devextreme-react';
import _ from 'lodash';

/** components */

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  Date Time Component
 */
const DateTimeBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        value,
        width='146',
        displayFormat=cmn.DATE_FORMAT_DT_1,
        onValueChanged
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */

    // ...

    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {

        // console.log("--- value", value);

        return (
            <>
            <DateBox 
                width={width}
                value={value}
                type={'datetime'}

                // displayFormat={type == 'month' ? 'monthAndYear' : undefined}
                // maxZoomLevel={type == 'month' ? 'year' : undefined}
                // minZoomLevel={type == 'month' ? 'century' : undefined}

                // showClearButton={true}
                displayFormat={displayFormat}

                // calendarOptions={{
                //     showTodayButton: type == 'day' ? true : false,
                // }} 

                onValueChanged={(e) => { onValueChanged(e.value); }}
            />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default DateTimeBox;
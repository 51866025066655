import React from 'react';
import { NumberBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import qs from 'query-string';
import _ from 'lodash';
import moment from 'moment';

/** components */
import {
    IconButton, DayDateBox, UseYnSwitchBox
} from '../components';

/** stores & lib */
import { 
    apiz, sapi, util, ResData, RCODE_S, NODE_ENV, SERVER_ENV, SERVER_URL, MGR_KEY, MGR_SALT
} from './';

const H_BAS= 224; //  기본
const H_SRCH= 36; // 검색행
const H_PG= 38; // 페이징

const cmn = {

    getUser: () => {
        const o = localStorage.getItem('user');
        return (o === undefined || o == null) ?
            {uid: '', id: '', name: '', rows: 10, sgr:false}
            : JSON.parse(o);
    },

    setUser: (v) => {
        localStorage.setItem('user', JSON.stringify(v));
    },

    getCom: () => {
        const o = localStorage.getItem('com');
        return (o === undefined || o == null) ?
            {uid: '', logo: '', name: '', manual: '', isLine: false, isPwPolicy: false, url: ''}
            : JSON.parse(o);
    },

    setCom: (v) => {
        localStorage.setItem('com', JSON.stringify(v));
    },

    getDept: () => {
        const o = localStorage.getItem('dept');
        return (o === undefined || o == null) ?
            {uid: '', name: ''}
            : JSON.parse(o);
    },

    setDept: (v) => {
        localStorage.setItem('dept', JSON.stringify(v));
    },

    getAuths: () => {
        const o = localStorage.getItem('auths');
        return (o === undefined || o == null) ?
            []
            : JSON.parse(o)
    },

    setAuths: (v) => {
        localStorage.setItem('auths', JSON.stringify(v));
    },

    getMenus: () => {
        const o = localStorage.getItem('menus');
        return (o === undefined || o == null) ?
            []
            : JSON.parse(o)
    },

    setMenus: (v) => {
        localStorage.setItem('menus', JSON.stringify(v));
    },

    getSiteMenus: () => {
        const o = localStorage.getItem('siteMenus');
        return (o === undefined || o == null) ?
            []
            : JSON.parse(o)
    },

    setSiteMenus: (v) => {
        localStorage.setItem('siteMenus', JSON.stringify(v));
    },

    getToken: () => {
        return localStorage.getItem('token');
    },

    setToken: (v) => {
        return localStorage.setItem('token', v);
    },

    chkAuth: () => {
        const token = cmn.getToken();
        if(util.getChkNull(token) === '') return false;
        else return true;
    },

    getUserId: () => {
        return localStorage.getItem('userid');
    },

    setUserId: (v) => {
        localStorage.setItem('userid', v);
    },

    /** Button Name */
    BTN_TTL_COMPLETE_ADD: "등록완료",
    BTN_TTL_COMPLETE_UP: "수정완료",

    /** Message */
    MSG_SUCCESS_ADD: "등록 되었습니다.",
    MSG_SUCCESS_UP: "수정 되었습니다.",
    MSG_SUCCESS_DEL: "삭제 되었습니다.",

    /** Formmat */
    DATE_FORMAT_YR_1: "yyyy",
    DATE_FORMAT_MON_1: "yyyy-MM",
    DATE_FORMAT_DAY_1: "yyyy-MM-dd",    
    DATE_FORMAT_DT_1: "yyyy-MM-dd HH:mm",

    INSP_IDX_LIMIT: 10,

    /** 공통코드 - 업체유형 */
    CMNCO_BIZ: {
        HEAD: "70201",   // 본사
        CUST: "70202",   // 고객사
        PART: "70203"    // 협력사
    },

    /** 공통코드 - 제품유형 */
    CMNCO_PRODTP: {
        MAT: "10101",   // 원자재
        ONE: "10102",   // 단품
        HALF: "10103",  // 반제품
        ASSY: "10104",  // 조립품
        MERC: "10105"   // 상품
    },

    /** 공통코드 - 검사유형 */
    CMNCO_INSPTP: {
        PROC: "10701", // 자주(공정)검사
        QUAL: "10702", // 품질검사
        FIRS: "10703", // 초품검사
        IN: "10704",  // 수입검사
        OUT: "10705" // 최종검사
    },

    /** 공통코드 - 선택검사 구분 */
    CMNCO_INSPSEL: {
        GOOD: "30101", // 양호
        BAD: "30102" // 불량
    },

    /** 공통코드 - 선택검사 유형 */
    CMNCO_VALSELTP: {
        SEL: "30401", // 양호/불량
        TOPBOT: "30402", // 상한/하한
        TOP: "30403", // 상한한쪽값
        BOT: "30404" // 하한한쪽값
    },

    /** 공통코드 - 작업번호 유입경로 */
    CMNCO_WORKNUMTP: {
        WORK: "70301", // 작업지시
        ORDER: "70302", // 발주
        OUT: "70303", // 외주
        FIRST: "70304", // 최초재공재고
        ORDER_WORK: "70305" // 구매후생산
    },

    /** 공통코드 - 작업지시등록구분 */
    CMNCO_WORKTP: {
        DEFALUT: "30201", // 기본등록
        SALES_SINGLE: "30202", // 수주단품
        SALES_ASSY: "30203", // 수주조립품
        STOCK_SINGLE: "30204", // 재고단품
        STOCK_ASSY: "30205" // 재고조립품
    },

    /** 공통코드 - 검사주기 */
    CMNCO_CHKCYCLE: {
        DAY: "40201",   // 일상
        WEEK: "40202",  // 주간
        MON: "40203",   // 월간
        YEAR: "40204",  // 연간
        QUAT: "40205",  // 분기
        HALF: "40206"   // 반기
    },

    /** 공통코드 - 점검선택 구분 */
    CMNCO_CHKSEL: {
        GOOD: "40301", // 양호
        BAD: "40302" // 불량
    },
    
    /** 공통코드 - 시간유형 구분 */
    CMNCO_TIMETP: {
        DAY: "80101", // 주간
        NIGHT: "80102" // 야간
    },
    
     /** 공통코드 - LOT 유형 */
     CMNCO_LOTTP: {
        ONE: "71001", // 건별
        DAY: "71002", // 일별
        MON: "71003"  // 월별
    },

    /** 작업번호 Prefix 구분 */
    PREFIX: {
        PURCHASE: "P", // 구매
        MANUFACTURE: "M" // 생산
    },

    BH: {
        H10: H_BAS + (H_SRCH*1) + H_PG,    // 검색1행, 페이지
        H11: H_BAS + (H_SRCH*2) + H_PG,    // 검색2행, 페이지

        H20: H_BAS + (H_SRCH*1),        // 검색1행
        H21: H_BAS + (H_SRCH*2),        // 검색2행

        // H50: H_BAS + (H_SRCH*1) - 64,    // spliter
        H50: H_BAS + (H_SRCH*1) - 48,    // spliter

        H00: 67, // Default Grid Row (값이 없을 경우, 하단 길이가 정의 되어있지 않을경우)
    },

    CW: {
        dy: 100,
        month: 80,
        lot: 140,
        amt: 100,
        ratio: 80,
        unit: 60,
        yn: 80,
        file: 100,
        prodNm: 200,
        prodNmH: 100,
        prodId: 160,
        prodIdH: 80,
        prodStnd: 120,
        procNm: 120,
        bizNm: 140,
        userNm: 100,
        equpNm: 120,
        prodTp: 80,
        sortOrder: 60,
        price:80,
        workNum:100,
        inspNm: 160,
        inspStnd: 120,

        moldCd: 140,
        moldNm: 180,
    },
    
    xlsNo: 0,  // 엑셀 No.
    
    api: async (nm, para, isAlert) => {
        let resData =  new ResData();
        
        if(isAlert === undefined || isAlert == null) isAlert = true;

        //console.log(nm, para);
        resData = await sapi.send(apiz[nm], para);            
      //  console.log(`RES ${nm}`, resData);

        if(resData === undefined || resData == null){
            throw new Error("서버와 통신이 원활하지 않습니다.\r\n관리자에게 문의하시기 바랍니다.");
        }
        
        if(resData.code !== RCODE_S){
            // ** 토큰 오류로 로그인 페이지 이동
            if(resData.code === 'E1001' || resData.code === 'E1002'){
                // if(isAlert) {
                // alert(resData.message);
                // }
                cmn.setToken('');
                // await cmn.goLogin();
                window.location.href = '/login';
                // return;
                throw new Error(resData.message);
   
            }
            else {

                throw new Error(resData.message);
            }
        }

        return resData.data;
    },    

    apiCodeCombo: async(classId, isIn, ids) => {
        const ps = {p:{classId: classId, isIn:isIn, ids: ids}};
        const r = await cmn.api("getCodeCombo", ps);
        return r.d0;
    },

    apiBizCombo: async(bizTp, sortCol, sotrTp) => {
        const ps = {p: { bizTp: bizTp, sortCol: sortCol, sortTp: sotrTp }};
        const r = await cmn.api("getBizCombo", ps);
        return r.items;
    },

    apiProdCombo: async(prodTp, sortCol, sotrTp) => {
        const ps = {p: { prodTp: prodTp, sortCol: sortCol, sortTp: sotrTp }};
        const r = await cmn.api("getProdCombo", ps);
        return r.items;
    },
    
    env: {
        NODE_ENV: NODE_ENV,
        SERVER_ENV: SERVER_ENV,
        SERVER_URL: SERVER_URL,
        MGR_KEY: MGR_KEY,
        MGR_SALT: MGR_SALT,
    },

    /** Grid Header Click 시 Sort Item을 리턴 - Sort */
    getGridHandleSortItem: (e, sortItem) => {
        if(e.rowType !== "header") return null;  
        if(e.column.sortable !== true) return null;

        const col = e.column.dataField;
        let tp = sortItem.col === col ? sortItem.tp : "";
        tp = tp === "desc" ? "asc" : "desc";

        return {col: col, tp: tp};
    },

    /** Render Grid Hearder - Sort */
    renderGridHeaderCell: (e, sortItem) => {
        return (
            <>
            {
                (sortItem.tp !== "" && sortItem.col === e.column.dataField) ?
                <>
                    <span className="dg-h-cont"></span>
                    {e.column.caption}
                    <span className={`${sortItem.tp === "desc" ? "dx-sort-down" : "dx-sort-up"} dg-h-sort`}></span>
                </>
                :
                <>
                { e.column.caption }
                </>
            }
            </>
        )
    },

    /* #region Grid Column Common */

    getGridColumn: (dataField, caption, renderGridHeaderCell) => {
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                allowEditing={false}
            />    
        )
    },

    getEditGridColumn: (dataField, caption) => {
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
            />    
        )
    },

    getGridColumnPer: (dataField, caption, renderGridHeaderCell) => {
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                cellRender={(d) => {
                    return <span>{util.fix(d.value)} %</span>;
                }}
            />    
        )
    },

    getGridColumnUseYn: (dataField, caption, renderGridHeaderCell) => {
        return (           
            <Column 
                // width={80}
                alignment={'center'}
                dataField={dataField === undefined ? 'useYn' : dataField}
                caption={caption === undefined ? '사용여부' : caption}    
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}             
                cellRender={(d) => {
                    return d.value === "Y" ? <span>○</span> : null;
                }}
            />           
        )
    },

    getEditGridColumnUseYn: (dataField, caption) => {
        return (           
            <Column
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                cellRender={(c) => {
                    return c.value === "Y" ? <span>○</span> : null;
                }}
                editCellRender={(c) => {
                    return (
                        <UseYnSwitchBox 
                            defaultValue={c.value}
                            onValueChanged={(v)=>{
                                c.setValue(v);
                            }} 
                        />
                    )                    
                }}
            />   
        )
    },

    getGridColumnPassYn: (dataField, caption, renderGridHeaderCell, val) => {
        
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField === undefined ? 'passYn' : dataField}
                caption={caption === undefined ? '점검결과' : caption}              
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                cellRender={(d) => {
                    const v = val !== undefined ? val : d.value;
                    return v === "Y" ? 
                        <span className="pass-y">합격</span> 
                        : v === "N" ? 
                            <span className="pass-n">불합격</span>
                            : null;
                }}
            />           
        )
    },

    getGridColumnQualRet: (dataField, caption, renderGridHeaderCell) => {        
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField === undefined ? 'passYn' : dataField}
                caption={caption === undefined ? '점검결과' : caption}              
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                cellRender={(d) => {
                    // console.log("==== d", d);
                    const condPassYn = d.data.condPassYn; // 조건부합격
                    const inspProgStt = d.data.inspProgStt; // 검사진행상태 (30603: 검사완료)

                    return  inspProgStt !== '30603' ?
                            <span>{d.data.inspProgSttNm}</span>
                            :(condPassYn === 'Y' || d.value === 'Y') ? 
                            <span className="pass-y">합격</span> 
                            : d.value === "N" ? 
                            <span className="pass-n">불합격</span>
                            : null;
                }}
            />           
        )
    },

    getGridColumnSortOrder: (dataField, caption) => {
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField === undefined ? 'sortOrder' : dataField}
                caption={caption === undefined ? '순서' : caption} 
            />        
        )
    },

    getGridColumnCmnt: (dataField, caption) => {
        return (           
            <Column
                alignment={'center'}
                dataField={dataField === undefined ? 'cmnt' : dataField}
                caption={caption === undefined ? '비고' : caption}                 
            />      
        )
    },

    // getGridColumnProdId: (dataField, caption, renderGridHeaderCell, handleGridProdLinkClick) => {
    //     return (           
    //         <Column 
    //             width={140}
    //             alignment={'center'}
    //             dataField={dataField === undefined ? 'prodId' : dataField}
    //             caption={caption === undefined ? '품번' : caption}  
    //             sortable={true}
    //             headerCellRender={renderGridHeaderCell}
    //             cellRender={(d) => {
    //                 // const d_ = {...d};
    //                 return (
    //                     <div className="dg-col-prod">
    //                         <a
    //                             href="#"
    //                             className="prod-link"
    //                             onClick={(e) => {
    //                                 e.preventDefault();
    //                                 handleGridProdLinkClick(d);
    //                             }}
    //                         >
    //                             { d.value }
    //                         </a>                                
    //                     </div>
    //                 )
    //             }}
    //         />    
    //     )
    // },

    // getGridColumnProdNm: (dataField, caption, renderGridHeaderCell, handleGridProdLinkClick) => {
    //     return (           
    //         <Column 
    //             width={140}
    //             alignment={'center'}
    //             dataField={dataField === undefined ? 'prodNm' : dataField}
    //             caption={caption === undefined ? '품명' : caption}  
    //             sortable={true}
    //             headerCellRender={renderGridHeaderCell}
    //             cellRender={(d) => {
    //                 return (
    //                     <div className="dg-col-prod">
    //                         <a
    //                             href="#"
    //                             className="prod-link"
    //                             onClick={(e) => {
    //                                 e.preventDefault();
    //                                 //console.log(d.data.prodUid);
    //                                 //handleGridProdLinkClick(d);
    //                                 const url = `/base/prodpop?${qs.stringify({mode: 'v', uid: d.data.prodUid})}`;        
    //                                 util.openWindow(url, 800, 550);   
    //                             }}
    //                         >
    //                             { d.value }
    //                         </a>                                
    //                     </div>
    //                 )
    //             }}
    //         />    
    //     )
    // },

    
    getGridColumnProdInfo: (dataField, caption, renderGridHeaderCell, handleGridProdLinkClick) => {
        return (           
            <Column 
                width={140}
                alignment={'center'}
                dataField={dataField === undefined ? 'prodNm' : dataField}
                caption={caption === undefined ? '품명' : caption}  
                sortable={true}
                headerCellRender={renderGridHeaderCell}
                cellRender={(d) => {
                    return (
                        <div className="dg-col-prod">
                            <a
                                href="#"
                                className="prod-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    //console.log(d.data.prodUid);
                                    //handleGridProdLinkClick(d);
                                    const url = `/cmn/prodviewpop?${qs.stringify({mode: 'v', uid: d.data.prodUid})}`;        
                                    util.openWindow(url, 700, 550);   
                                }}
                            >
                                { d.value }
                            </a>                                
                        </div>
                    )
                }}
            />    
        )
    },

    getGridColumnProcInfo: (dataField, caption, cnt, handleGridManuLinkClick) => {
        return (           
            <Column 
                width={140}
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={false}
                cellRender={(d) => {
                    const o = d.row.data;
                    return (
                        util.getChkNull(o.idx) === 0 ?
                            <span>{o['proc' + cnt]}</span>
                        : 
                            util.getChkNull(o['proc' + cnt]) === '' ?
                                '' 
                            :
                                o['procTp' + cnt] === '70302' ?
                                    <ul>
                                        <li>구매</li>
                                        <li>{`발주 : ${util.getNumComma(o['procOutAmt' + cnt])}`}</li>
                                        <li>입고 : <span style={{color:'blue'}}>{util.getNumComma(o['procInAmt' + cnt])}</span></li>
                                        <li>불량 : <span style={{color:'red'}}>{util.getNumComma(o['procBadAmt' + cnt])}</span></li>
                                    </ul>
                                : o['procTp' + cnt] === '70303' ?
                                <span>
                                    <ul>
                                        <li>외주출고</li>
                                        <li>{`출고 : ${util.getNumComma(o['procOutAmt' + cnt])}`}</li>
                                        <li>입고 : <span style={{color:'blue'}}>{util.getNumComma(o['procInAmt' + cnt])}</span></li>
                                        <li>불량 : <span style={{color:'red'}}>{util.getNumComma(o['procBadAmt' + cnt])}</span></li>
                                    </ul>
                                </span>
                                :
                                <div 
                                    className={'insp-col-act'}
                                    onClick={(e) => {
                                        //첫공정일 경우 Lot를 넘기지 않는다.
                                        //Lot 생성은 상세페이지에서 처리
                                        handleGridManuLinkClick(o['proc' + cnt], util.getChkNull(o.lot), o.workNum, );
                                    }}
                                >
                                    <ul>
                                        <li>{`생산 : ${util.getNumComma(o['procOutAmt' + cnt])}`}</li>
                                        <li>양품 : <span style={{color:'blue'}}>{util.getNumComma(o['procInAmt' + cnt])}</span></li>
                                        <li>불량 : <span style={{color:'red'}}>{util.getNumComma(o['procOutAmt' + cnt] - o['procInAmt' + cnt])}</span></li>
                                        <li>재처리 : <span style={{color:'blue'}}>{util.getNumComma(o['procBadAmt' + cnt])}</span></li>
                                    </ul>
                                </div> 
                    )
                }}
            />    
        )
    },

    
    getGridColumnProcInfoSite: (dataField, caption, cnt, handleGridManuLinkClick) => {
        return (           
            <Column 
                width={140}
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={false}
                cellRender={(d) => {
                    const o = d.row.data;
                    return (
                        util.getChkNull(o.idx) === 0 ?
                            <span>{o['proc' + cnt]}</span>
                        : 
                            util.getChkNull(o['proc' + cnt]) === '' ?
                                '' 
                            :
                                o['procTp' + cnt] === '70302' ?
                                    <ul>
                                        <li>구매</li>
                                        <li>{`발주 : ${util.getNumComma(o['procOutAmt' + cnt])}`}</li>
                                        <li>입고 : <span style={{color:'blue'}}>{util.getNumComma(o['procInAmt' + cnt])}</span></li>
                                        <li>불량 : <span style={{color:'red'}}>{util.getNumComma(o['procBadAmt' + cnt])}</span></li>
                                    </ul>
                                : o['procTp' + cnt] === '70303' ?
                                <span>
                                    <ul>
                                        <li>외주출고</li>
                                        <li>{`출고 : ${util.getNumComma(o['procOutAmt' + cnt])}`}</li>
                                        <li>입고 : <span style={{color:'blue'}}>{util.getNumComma(o['procInAmt' + cnt])}</span></li>
                                        <li>불량 : <span style={{color:'red'}}>{util.getNumComma(o['procBadAmt' + cnt])}</span></li>
                                    </ul>
                                </span>
                                :
                                <div 
                                    className={'insp-col-act'}
                                    onClick={(e) => {
                                        //첫공정일 경우 Lot를 넘기지 않는다.
                                        //Lot 생성은 상세페이지에서 처리
                                  //     console.log(o);
                                        handleGridManuLinkClick(o['proc' + cnt], util.getChkNull(o.lot), o.workNum, o['procTp' + cnt], o['procCd' + cnt], cnt );
                                    }}
                                >
                                    <ul>
                                        <li>{`생산 : ${util.getNumComma(o['procOutAmt' + cnt])}`}</li>
                                        <li>양품 : <span style={{color:'blue'}}>{util.getNumComma(o['procInAmt' + cnt])}</span></li>
                                        <li>불량 : <span style={{color:'red'}}>{util.getNumComma(o['procOutAmt' + cnt] - o['procInAmt' + cnt])}</span></li>
                                        <li>재처리 : <span style={{color:'blue'}}>{util.getNumComma(o['procBadAmt' + cnt])}</span></li>
                                    </ul>
                                </div> 
                    )
                }}
            />    
        )
    },


    /** 파일 확장자별 아이콘 타입을 조회한다. */
    getExtType: (ext) => {
        let ret = "file";

        switch (_.lowerCase(ext)) {
            case 'png': 
            case 'jpg': 
            case 'git': 
            case 'bmp': 
                ret = "img";
                break;
            case 'doc': case 'docx': 
            case 'xls': case 'xlsx': 
            case 'ppt': case 'pptx':
            case 'pdf': 
            case 'hwp': 
                ret = "doc";
                break;
            default:
                break;
        }
        return ret;
    },

    getGridColumnFiles: (dataField, caption, width, dispTp) => {
        return (           
            <Column
                // width={width == undefined ? 100 : width}
                alignment={'center'}
                dataField={dataField === undefined ? 'fileInfo' : dataField}
                caption={caption === undefined ? '파일' : caption} 
                cellRender={(d) => {
                    const files = _.split(d.value, ',');
                    return (
                        <ul style={{ display: "inline-flex" }}>
                        {
                            _.map(files, (v, k) => {
                                if(v === '') return;
                                const fi = _.split(v, '^'); // {fileUid}^{fileNm}
                                const ext = fi[1].split('.').pop();
                                return(
                                    <li key={k} 
                                        //style={{ padding: "2px" }}
                                    >
                                        { dispTp === "text" ?
                                            <a 
                                                href="#"
                                                onClick={(e) => {
                                                    const url = cmn.getDownloadFileUrl(fi[0]);
                                                    window.open(url, '_blank');
                                                }}
                                            > {fi[1]}
                                            </a>
                                            : 
                                            <IconButton
                                                alt={fi[1]}
                                                title={fi[1]}
                                                className={`btn-icon-${cmn.getExtType(ext)}`}
                                                marginLeft={2}
                                                marginRight={2}
                                                onClick={(e) => {
                                                    const url = cmn.getDownloadFileUrl(fi[0]);
                                                    window.open(url, '_blank');
                                                }}
                                            />
                                        }
                                    </li>
                                )
                            })
                        }
                        </ul>
                      )
                }}
            />      
        )
    },

    getGridColumnImg: (dataField, caption,  imgWidth, imgHeigh) => {
        return (           
            <Column
                // width={width == undefined ? 100 : width}
                alignment={'center'}
                allowEditing={false} 
                dataField={dataField === undefined ? 'fileInfo' : dataField}
                caption={caption === undefined ? '사진' : caption} 
                cellRender={(d) => {
                    const files = _.split(d.value, ',');
                    return (
                        <ul style={{ display: "inline-flex" }}>
                        {
                            _.map(files, (v, k) => {
                                if(v === '') return;
                                const fi = _.split(v, '^'); // {fileUid}^{fileNm}
                                const url = cmn.getDownloadFileUrl(fi[0]);
                                return(
                                    <li key={k}>
                                        <img src={url} alt={fi[1]} 
                                            width={imgWidth === undefined ? 64 : imgWidth} 
                                            height={imgHeigh === undefined ? 64 : imgHeigh} 
                                        ></img>
                                    </li>
                                )
                            })
                        }
                        </ul>
                    )
                }}
            />      
        )
    },

    /**
     * @fileInfo: {fileUid1}^{fileNm1},{fileUid2}^{fileNm2} ...
     */
    getDispImg: (fileInfo, w, h) => {
        const files = _.split(fileInfo, ',');
        return (
            <ul style={{ display: "inline-flex" }}>
            {
                _.map(files, (v, k) => {
                    if(v === '') return;
                    const fi = _.split(v, '^'); // {fileUid}^{fileNm}
                    const url = cmn.getDownloadFileUrl(fi[0]);
                    return(
                        <li key={k}>
                            <img src={url} alt={fi[1]} 
                                width={w === undefined ? 64 : w} 
                                height={h === undefined ? 64 : h} 
                            ></img>
                        </li>
                    )
                })
            }
            </ul>
        )
    },

    getGridColumnDayType: (dataField, caption, renderGridHeaderCell) => {
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                cellRender={(d) => {
                    return (
                        <>
                        <span>{util.getDateStrSplit('d',d.value)}</span>
                        </>
                    )
                }}
            />    
        )
    },
    
    getGridColumnDateType: (dataField, caption, renderGridHeaderCell) => {
        return (           
            <Column 
                // width={80}
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                cellRender={(d) => {
                    return (
                        <>
                        <span>{util.getChkNull(d.value) === '' ? ''  : util.getDateToString('m', d.value, '-')}</span>
                        </>
                    )
                }}
            />    
        )
    },

    getEditGridColumnDate: (dataField, caption, def) => {
        return (           
            <Column
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                cellRender={(c) => {
                    return (
                        <span>{util.getDateToString('d',c.value, '-')}</span>
                    )
                }}
                editCellRender={(c) => {
                    // console.log("=== c", c);
                    return (
                        <>
                            <DayDateBox 
                                defaultValue={c.value === undefined ? def : c.value}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    c.setValue(e.value);
                                }}                    
                            />                            
                        </>
                    )                    
                }}
            />   
        )
    },

    getGridColumnNumCommaType: (dataField, caption, renderGridHeaderCell, defRet, alignment) => {
        return (           
            <Column 
                // width={width == undefined ? 100 : width}
                alignment={util.getChkNull(alignment) === '' ? 'center' : alignment}
                dataField={dataField}
                caption={caption}  
                sortable={util.getChkNull(renderGridHeaderCell) === '' ? false : true}
                headerCellRender={renderGridHeaderCell}
                allowEditing={false}
                cellRender={(d) => {
                    return (
                        <>
                        <span>{util.getNumComma(d.value, defRet)}</span>
                        </>
                    )
                }}
            />    
        )
    },

    getEditGridColumnNum: (dataField, caption) => {
        return (           
            <Column
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                cellRender={(c) => {
                    return (
                        <span>{util.getNumComma(c.value)}</span>
                    )
                }}
                editCellRender={(c) => {
                    return (
                        <div>
                            <NumberBox
                                width={'100%'}
                                format={'#,##0.###'}
                                defaultValue={c.value}
                                showSpinButtons={true}
                                // showClearButton={true}
                                onValueChanged={(e)=>{
                                    c.setValue(e.value);
                                }}

                            />
                        </div>
                    )                    
                }}
            />   
        )
    },

    getGridColumnMonthType: (dataField, caption, renderGridHeaderCell) => {
        return (           
            <Column 
                // width={80}
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                cellRender={(d) => {
                    return (
                        <>
                        <span>{util.getDateStrSplit('M', d.value, '-')}</span>
                        </>
                    )
                }}
            />    
        )
    },

    getGridColumnProcInspIdx: (idx) => {
        return (
            <Column 
                // width={100}
                alignment={'center'}
                // dataField={'equpNm'} 
                caption={`${idx}차`}
                cellRender={(d) => {
                    // console.log("--- d", d);
                    // console.log("--- d", d.row.data.inspDt1);
                    return (
                        <div>
                            {d.row.data[`inspDt${idx}`]}
                        </div>
                    )
                }}
            />
        )
    },

    getGridColumnUserNm: (dataField, caption, renderGridHeaderCell) => {
        return (           
            <Column 
                alignment={'center'}
                dataField={dataField}
                caption={caption}  
                sortable={renderGridHeaderCell === undefined ? false : true}
                headerCellRender={renderGridHeaderCell}
                // cellRender={(d) => {
                //     return (
                //         <>
                //         <span>{util.getMonthDBString(d.value, '-')}</span>
                //         </>
                //     )
                // }}
            />    
        )
    },

    /**
     * Render DataGrid RowSpan
     * d: data
     * ds: data source
     * rc: rowspan column
     */
    cellRenderRowSpan: (d, ds, rc, v) => {
        // console.log("--- d", d);
        // console.log("--- ds", ds);
        // console.log("--- rs", rc);

        if(ds.length === 0) return null;
        if(ds.length < (d.rowIndex+1)) return null;

        if(d.rowIndex > 0 && d.data[rc] === ds[d.rowIndex-1][rc]){
            d.cellElement.style.display = "none";
            return undefined; 
        }

        let cnt = 1;
        for(let i = (d.rowIndex+1); i < ds.length; i++){
            if(d.data[rc] !== ds[i][rc]) break;
            cnt++;
        }
        
        if(cnt > 1) d.cellElement.rowSpan = cnt; 
        
        return v === undefined ? d.value : v;
    },
    
    /* #endregion */

    /* #region File */

    /** 
     * 파일 다운로드
     * uid: file uid or save file name, tmp: 1(temp), fnm: file Name
     * */
    getDownloadFileUrl: (uid, w, tmp, fnm) => {
        // const w = imgWidth == undefined ? '' : `&w=${imgWidth}`;
        const q = qs.stringify({uid: uid, w: w, tmp: tmp, fnm: fnm})
        // return `${cmn.env.SERVER_URL}${apiz.downloadFile.url}?uid=${fileUid}${w}`;
        let com  = cmn.getCom();
        let url =  `${com.url}${apiz.downloadFile.url}?${q}`;
        console.log('url========>', url);
        //return `${cmn.env.SERVER_URL}${apiz.downloadFile.url}?${q}`;
        return `${com.url}${apiz.downloadFile.url}?${q}`;
    },

    getHtmlDownloadFile: (fileInfo) => {
        if(util.getChkNull(fileInfo) === '') return '';
        let html = '';        
        const files = _.split(fileInfo, ',');

        _.forEach(files, (v, k) => {
            if(v === '') return;
            const fi = _.split(v, '^'); // {fileUid}^{fileNm}
            const ext = fi[1].split('.').pop();

            html += `
                <li>
                    <a href="#" 
                        class="btn-icon-${cmn.getExtType(ext)}" 
                        alt="${fi[1]}" 
                        title="${fi[1]}" 
                        style="margin-top: 0px; margin-left: 2px; margin-right: 2px;"
                        onclick="javascript:window.open('${cmn.getDownloadFileUrl(fi[0])}', '_blank');"
                    >
                    </a>
                </li>
            `;
        })

        return html !== '' ? `<ul>${html}</ul>` : '';
    },

    getRenderDownloadFile: (fileInfo, tp) => {
        if(util.getChkNull(fileInfo) == '') return '';
        const files = _.split(fileInfo, ',');        
        return (
            <ul style={{ display: "inline-flex" }}>
            {
                _.map(files, (v, k) => {
                    if(v === '') return;                    
                    const fi = _.split(v, '^'); // {fileUid}^{fileNm}
                    const ext = fi[1].split('.').pop();
                    const url = cmn.getDownloadFileUrl(fi[0]);
                    return(
                        <li key={k}>
                            {tp == 'text' ? 
                            <a 
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(url, '_blank');
                                }}
                            >   
                                {fi[1]}
                            </a>
                            :
                            <a 
                                href="#"
                                className={`btn-icon-${cmn.getExtType(ext)}`}
                                style={{ 
                                    marginTop: 0,
                                    marginLeft: 2, 
                                    marginRight: 2,
                                }}    
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(url, '_blank');
                                }}
                                alt={fi[1]}
                                title={fi[1]}
                            >   
                            </a>
                            }
                        </li>
                    )
                })
            }
            </ul>
        )
    },   

    getRenderImg: (fileInfo, imgWidth) => {
        if(util.getChkNull(fileInfo) === '') return '';
        const files = _.split(fileInfo, ',');        
        return (
            <ul style={{ display: "inline-flex" }}>
            {
                _.map(files, (v, k) => {
                    if(v === '') return;                    
                    const fi = _.split(v, '^'); // {fileUid}^{fileNm}^{tmp} ... tmp: 1(temp)
                    const tmp = fi.length > 2 ? fi[2] : undefined;
                    // const ext = fi[1].split('.').pop();
                    const url = cmn.getDownloadFileUrl(fi[0], imgWidth, tmp, fi[1]);
                    return(
                        <li key={k}>
                            <img src={url} width={imgWidth} alt={fi[1]}></img>
                        </li>
                    )
                })
            }
            </ul>
        )
    },

    // 파일 업로드 박스의 파일들 정보를 구분자 있는 문자열로 리턴한다.
    getUploadFileInfoStr: (files) => {
        let fi = '';
        _.forEach(files, (v, k) => {
            fi += k > 0 ? ',' : '';
            fi += `${v.uploaded ? v.saveFileNm : v.fileUid}^${v.fileNm}^${v.uploaded ? '1' : ''}`;                                                    
        });

        return fi;
    },

    /* #endregion File */
 
    /** CheckBoxList 등 체크된 공통코드를 배열로 리턴 */
    getCheckedCodes: (objs) => {
        let ret = [];
        _.forEach(objs, (v, k) => {
            if(v.checked)
                ret.push(v.codeId);
        });

        return ret;
    },

    /** Combo 내 공통코드명 가져오기
     *      BadAmt Component에서 사용됨.
     */
    getCodeNmInCombo: (arr, id) => {
        const obj = _.find(arr, {codeId: id});
        return obj === undefined ? '' : obj.codeNm;
    },

    /** 제품 Display */
    getDispProd: (id, nm, stnd) => {

        if(util.getChkNull(id) == '') return '';
        return _.concat('[', id, '] ', nm, util.getChkNull(stnd) === '' ? '' : ` / ${stnd}`);
    },

    /** 선택검사 유형별 검사 합격여부 조회 */
    getIsPassInsp: (goodBadTp, inspVal, valSelectTp, stndVal, upperVal, lowerVal) => {
        if(inspVal === undefined || inspVal == null) return false;
        let ret = false;

        // 양호/불량, 상한/하한, 상한한쪽값, 하한한쪽값
        if(valSelectTp === cmn.CMNCO_VALSELTP.SEL) {   // 양호/불량
            if(goodBadTp === cmn.CMNCO_INSPSEL.GOOD) {
                ret = true;
            }
        }
        else if(valSelectTp === cmn.CMNCO_VALSELTP.TOPBOT) {   // 상한/하한
            if(inspVal <= (stndVal + upperVal) && inspVal >= (stndVal + lowerVal)) {
                ret = true;
            }         
        }
        else if(valSelectTp === cmn.CMNCO_VALSELTP.TOP) {   // 상한한쪽값
            if (inspVal >= stndVal) {
                ret = true;
            } 
        }
        else if(valSelectTp === cmn.CMNCO_VALSELTP.BOT) {   // 하한한쪽값
            if (inspVal <= stndVal) {
                ret = true;
            }      
        }

        return ret;
    },

    /** 합격여부 표시 */
    getDispPass: (d, defVal) => {
        if(defVal === undefined || defVal == null) defVal = '';
        if(util.getChkNull(d) === '') d = defVal;

        return d === "Y" ? 
        <span className="pass-y">합격</span> 
        : d === "N" ? 
            <span className="pass-n">불합격</span>
            : defVal;
    },

    /** 점검결과 */
    getDispEqupGoodBadTp: (d) => {
        return d === cmn.CMNCO_CHKSEL.GOOD ? 
        <span className="pass-y">양호</span> 
        : d === cmn.CMNCO_CHKSEL.BAD ? 
            <span className="pass-n">불량</span>
            : '';
    },

    /** 단위 표시 */
    getDispUnitTpNm: (d) => {
        return util.getChkNull(d) === '' ?  '' : `(${d})`
    },

    /** Print Bottom 표시 
     * l: left 문자열
     * c: center 문자열
     * r: right 문자열
     * p: page 
    */
    getRenderPrintBottom: (l, c, r, p) => {
        if(p === undefined || p == null) p = 1;
        const h = 297 * p;

        return (
            <div className="print-bottom" style={{top: `calc(${h}mm - 40px + 105px)`}}>
            <table>
                <colgroup>
                    <col width={160}/>
                    <col />
                    <col width={160}/>
                </colgroup>
                <tbody>
                    <tr>
                        <td>{l}</td>
                        <td style={{textAlign: 'center'}}>{c}</td>
                        <td style={{textAlign: 'right'}}>{r}</td>
                    </tr>
                </tbody>
            </table> 
        </div> 
        )
    },
 
    /** Toast */
    toast: (text, type, time) =>{
        time = time === undefined ? 600 : time;
        notify(text, type, time);
    },

    successToast: (text) => {
        cmn.toast(text, "success");
    },

    failedToast: (text) => {
        cmn.toast(text, "error");
    },

    weekItems: [
        {d: '월', v: 2},
        {d: '화', v: 3},
        {d: '수', v: 4},
        {d: '목', v: 5},
        {d: '금', v: 6},
        {d: '토', v: 7},
        {d: '일', v: 1}
    ],

    getWeekItemNm: (v) => {
        return _.find(cmn.weekItems, {v: v}).d;
    },

    getDayItems: () => {
        let items = [];
        _.times(31, (n) => { items.push({d: `${n+1}`, v: n+1}); });
        return items;
    },
    
    /** getDispChkCycleInfo - 설비점검 주기 정보 표시 */
    getDispChkCycleInfo: (o) => {
        if(o === undefined || o == null) return '';
        let ret = '';

        if(o.chkCycleTp === cmn.CMNCO_CHKCYCLE.DAY) ret = `일상`;
        else if(o.chkCycleTp === cmn.CMNCO_CHKCYCLE.WEEK) ret = `매주 ${cmn.getWeekItemNm(o.wk)}요일 마다`;
        else if(o.chkCycleTp === cmn.CMNCO_CHKCYCLE.MON) ret = `매월 ${o.dd}요일 마다`;
        else if(o.chkCycleTp === cmn.CMNCO_CHKCYCLE.YEAR) ret = `${util.getDateStrSplit(o.startDy)}일 부터 매년 같은 날`;
        else if(o.chkCycleTp === cmn.CMNCO_CHKCYCLE.QUAT) ret = `${util.getDateStrSplit(o.startDy)}일 부터 3개월 마다`;
        else if(o.chkCycleTp === cmn.CMNCO_CHKCYCLE.HALF) ret = `${util.getDateStrSplit(o.startDy)}일 부터 6개월 마다`;              

        return ret;
    },

    /** getDispEqupChkStnd - 설비점검 규격 표시 */
    getDispEqupChkStnd: (valSelectYn, upperVal, lowerVal) => {
        if(valSelectYn === 'Y') return `양호/불량`;
        return `${upperVal} ~ ${lowerVal}`;
    },

    /** 현재 시간유형(주간, 야간) 코드 조회 */
    getCurTimeTp: () => {
        const curHour = util.getCurHour();        
        //console.log("--- curHour", curHour);
        return (curHour > 8 && curHour < 21) ? cmn.CMNCO_TIMETP.DAY : cmn.CMNCO_TIMETP.NIGHT;
    },

    /** 작지번호 또는 Lot를 받아서 작지번호로 리턴 */
    getWorkNum: (s) => {
        if(s.length < 11) return '';
        return s.trim().length > 11 ? s.trim().substring(0, 11) :  s.trim();
    },

    /* Lot 새로 생성해서 Return 함*/
    getCrtDyLotNum : (workNum, lotTp, dyNtUseYn, dyStartTm, dyEndTm, dyChar, ntChar, getDate) => {
        let lot_ = '';
        let getDate_ = util.getChkNull(getDate) ?? moment().format('YYYY-MM-DD HH:mm');

        if(lotTp === cmn.CMNCO_LOTTP.DAY) //lot type 이 일별일 경우 
        {
            if(dyNtUseYn === 'Y') // 주/야로 구분이 나누어져있을 경우
            {
                if(moment(getDate_).isBetween(dyStartTm, dyEndTm))
                {
                    lot_ = `${workNum}-${util.getDateToString('f', getDate_, '', 'YYMMDD')}-${dyChar}`;
                }else{
                    if(moment(getDate_).isBefore(dyStartTm)) // 시작시간 이전일 경우 -1일 해준다.
                        lot_ = `${workNum}-${util.getAddDate2(getDate_, '-1', 'd', 'YYMMDD')}-${ntChar}`;
                    else
                        lot_ = `${workNum}-${util.getDateToString('f', getDate_, '', 'YYMMDD')}-${ntChar}`;
                }
            }else // 주/야로 구분되어있지 않을 경우
            {
                lot_ = `${workNum}-${util.getDateToString('f', getDate_, '', 'YYMMDD')}`;
            }
        }else if(lotTp === cmn.CMNCO_LOTTP.MON)
        {
            //lot type 이 월별일 경우 당월 데이터의 Lot를 생성한다.
            lot_ = `${workNum}-${util.getDateToString('f', getDate_, '', 'YYMM')}`;
        }

        return lot_;
    },

    /* Dy 값을 조건에 맞게 Return 함*/
    getCrtDy : (lotTp, dyNtUseYn, dyStartTm, dyEndTm, getDate) => {
        let dy_ = '';
        let getDate_ = util.getChkNull(getDate) ?? moment().format('YYYY-MM-DD HH:mm');
        dy_ = util.getDateToString('f', getDate_, '', 'YYYYMMDD');

        if(lotTp === cmn.CMNCO_LOTTP.DAY) //lot type 이 일별일 경우 
        {
            if(dyNtUseYn === 'Y') // 주/야로 구분이 나누어져있을 경우
            {
                if(moment(getDate_).isBetween(dyStartTm, dyEndTm))
                {
                    dy_ = util.getDateToString('f', getDate_, '', 'YYYYMMDD');
                }else{
                    if(moment(getDate_).isBefore(dyStartTm)) // 시작시간 이전일 경우 -1일 해준다.
                        dy_ = util.getAddDate2(getDate_, '-1', 'd', 'YYYYMMDD');
                    else
                        dy_ = util.getDateToString('f', getDate_, '', 'YYYYMMDD');
                }
            }
        }
        return dy_;
    }

}

export default cmn;

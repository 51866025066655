import React, { useState, useEffect, useContext } from 'react';
import { SelectBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    UserSelectBox,
    EditGridView,
    DataGridView,
    CancelButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  납품 등록 / 수정
 */
const OutsOutPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */
    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,
        uid = ls.uid,
        ppuid = ls.ppuid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [bizItems, setBizItems] = useState([]);
    const [lotListOutsOutItem, setLotListOutsOutItem] = useState([]);
    

    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState('');
    const [outDy, setOutDy] = useState(util.getNow());
    const [totOutAmt, setTotOutAmt] = useState(0);
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [procNm, setProcNm] = useState('');
    const [isDel, setIsDel] = useState('');
    

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);

            if(mode === "a" || mode === "u"){
                let r = await cmn.api('getBizComboProc', {p:{prodProcUid:ppuid}});
                if(r == null || r === undefined) return;
                setBizItems(r.d0);
            }

            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = () => {
        try {
            if(mode === "a") {
                loadLotItems(ppuid);
            }else
            {
                loadOutsOutInfo(uid);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadOutsOutInfo = async(e) => {
        if(util.getChkNull(uid) === '') return;

        let r = await cmn.api('getOutsOut', {p:{outsOutUid:uid}});
        if(r == null || r === undefined) return;

        const data = r.d0;
        
        setProdId(data[0].prodId);
        setProdNm(data[0].prodNm);
        setProcNm(data[0].procNm);
        setBizUid(data[0].bizUid);
        setBizNm(data[0].bizNm);
        setUserNm(data[0].userNm);
        setIsDel(data[0].isDel);

        let lotListOutsOutItem_ = [];
        _.forEach(data, (v, k) => {
            lotListOutsOutItem_.push({
                lot: v.lot
                ,outAmt: v.outAmt
                ,cmnt: v.cmnt
            })
        })
        setLotListOutsOutItem(lotListOutsOutItem_);
    }

    const loadLotItems = async(e) => {
        // 입고량 / Lot 정량 
        let ps = {p: {prodProcUid: e}};

        let r = await cmn.api('getLotListOutsOut', ps);
        if(r == null || r === undefined) return;

        let data = r.d0;

        setProdId(data[0].prodId);
        setProdNm(data[0].prodNm);
        setProcNm(data[0].procNm);
        setBizUid(data[0].bizUid);

        let lotListOutsOutItem_ = [];
        _.forEach(data, (v, k) => {
            lotListOutsOutItem_.push({
                lot: v.lot
                ,preAmt : v.preAmt
                ,compAmt: v.compAmt
                ,possAmt : v.possAmt
                ,outAmt: v.outAmt
                ,cmnt: ''
            })
        })
        setLotListOutsOutItem(lotListOutsOutItem_);
    }

    const loadTotOutAmt = () => {
        setTotOutAmt(_.sumBy(lotListOutsOutItem, 'outAmt'));
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {     
            if(mode === "a")
            { 
                if(!util.chkMustValue(bizUid, '출고업체'))
                    return;
                if(!util.chkMustValue(userUid, '출고자'))
                    return;
                if(!util.chkMustValue(_.sumBy(lotListOutsOutItem, 'outAmt'), '출고수량', 'cnt'))
                    return;
                
                // 추가 Validation Check
                let msg = '';
                _.forEach(lotListOutsOutItem, (v,k) => {
                    if(v.outAmt > v.possAmt) 
                        msg = '[출고수량]이 [출고가능수량] 보다 큽니다.';
                });
                if(msg !== '')
                {
                    util.cmnAlert(msg);
                    return;
                }
                
                if(util.confirmYn("외주출고"))
                    addOutsOut();
            }
            else if(mode === "v") 
            {
                delOutsOut(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addOutsOut = async() => {

        let lotListOutsOutItem_ = [];

        let groupUid = util.getUUID();
        _.forEach(lotListOutsOutItem, (v,k) => {
            if(v.outAmt > 0) {
                lotListOutsOutItem_.push(
                    {
                        outsOutUid: util.getUUID(),
                        comUid: authStore.com.uid,
                        bizUid: bizUid,
                        prodProcUid: ppuid,
                        outDy: util.getDateToString('d',outDy),
                        userUid: userUid,
                        uptUserUid: authStore.user.uid,
                        lot: v.lot,
                        outAmt: v.outAmt,
                        groupUid: groupUid,
                        cmnt: v.cmnt
                    }
                );
            }
        });

        const ps = {
            p: [...lotListOutsOutItem_]
        }

        const r = await cmn.api('addOutsOut', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delOutsOut = async(delCmnt) => {

        const ps = {
            p: {
                comUid: authStore.com.uid,
                outsOutUid:uid,
                delCmnt: delCmnt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('delOutsOut', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("취소");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "v" ? "취소완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                {mode === "v" ?
                    <CancelButton
                        title={completeButtonTitle}
                        isDel={isDel}
                        isDelMsg={'외주입고 내역이 있어 취소가 불가능합니다. \n외주입고를 취소하고 진행해 주시기 바랍니다.'}
                        handleClickComplete={(e) => {
                            handleClickComplete(e)
                        }} 
                    />
                    :
                    <ActionButton 
                        onClick={handleClickComplete}
                        title={completeButtonTitle}
                        marginRight={0}
                        show={true}
                    />
                }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">공정</th>
                            <td className="tcon">
                                <span>{procNm}</span>
                            </td>
                            <th className="tit req">출고업체</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <SelectBox 
                                        value={bizUid}
                                        dataSource={bizItems}
                                        displayExpr={'bizNm'}
                                        valueExpr={'bizUid'}
                                        placeholder="업체를 선택하세요"
                                        showClearButton={true}
                                        onValueChanged={(e)=>{
                                            setBizUid(e.value);
                                        }}
                                    />
                                    :
                                    <span>{bizNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">출고일</th>
                            <td className="tcon">
                                {
                                    mode === "a" ?
                                    <DayDateBox
                                        value={outDy}
                                        onValueChanged={(e) => {
                                            setOutDy(e.value);
                                        }}                    
                                    />
                                : 
                                    <span>{outDy}</span>
                                }
                            </td>
                            <th className="tit req">출고자</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        showClearButton={false}
                                        placeholder="출고자를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                :
                                    <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">총 출고수량</th>
                            <td className="tcon" colSpan={3}>
                                <span>{totOutAmt}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        
            <div className="pop-grid">
                <div className="clearfix">
                <>
                {
                    mode === 'a' ? 
                    <EditGridView
                        dataSource={lotListOutsOutItem}   // store
                        colsWidth={[cmn.CW.lot, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, null]}
                        onRowUpdated={(e) => {loadTotOutAmt();}}
                        gridHeight={180}
                    >
                        { cmn.getGridColumn('lot', 'Lot')}
                        { cmn.getGridColumnNumCommaType('preAmt', '출고예정수량')}
                        { cmn.getGridColumnNumCommaType('compAmt', '기출고수량')}
                        { cmn.getGridColumnNumCommaType('possAmt', '출고가능수량')}
                        <Column 
                            alignment={'center'}
                            dataField={'outAmt'} 
                            caption={'출고수량'} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'cmnt'} 
                            caption={'비고'} 
                        />
                    </EditGridView>
                    : 
                    <DataGridView
                        dataSource={lotListOutsOutItem}   // store
                        colsWidth={[150, 120, null]}
                        gridHeight={180}
                    >
                        { cmn.getGridColumn('lot', 'Lot')}
                        { cmn.getGridColumnNumCommaType('outAmt', '출고수량')}
                        { cmn.getGridColumnCmnt()}
                    </DataGridView>
                }   
                </>
                </div>
            </div>   
            

            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "외주출고 등록" 
                : mode === "v" ? "외주출고 상세보기"
                : "외주출고" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default OutsOutPopPage;
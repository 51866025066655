import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox, SelectBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    DayDateBox, FileUploadBox,
    CodeSelectBox, ChkCycleChkListBox, UseYnSwitchBox,
    PopupLayout, ActionButton,
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  설비 등록 / 수정
 */
const EqupPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [equpTp, setEqupTp] = useState('');
    const [equpTpNm, setEqupTpNm] = useState('');

    const [equpCd, setEqupCd] = useState('');
    const [equpNm, setEqupNm] = useState('');
    const [modelNm, setModelNm] = useState('');
    const [equpStnd, setEqupStnd] = useState('');
    const [maker, setMaker] = useState('');
    const [makeYr, setMakeYr] = useState(util.getNow());
    const [buyDy, setBuyDy] = useState(util.getNow());

    const [uph, setUph] = useState(0);

    const [useYn, setUseYn] = useState('Y');
    const [plcCd, setPlcCd] = useState('');

    const [equpImgUid, setEqupImgUid] = useState(util.getUUID());
    const [equpImgInfo, setEqupImgInfo] = useState('');
    const [equpImgFiles, setEqupImgFiles] = useState([]);

    const [lineUid, setLineUid] = useState('');
    const [lineItem, setLineItem] = useState([]);

    const [sortOrder, setSortOrder] = useState(0);

    // const [chkImgUid, setChkImgUid] = useState(util.getUUID());
    // const [equpUploadFilesBef, setEqupUploadFilesBef] = useState([]); // 파일첨부 변경 이전값, 변경여부 확인용
    // const [chkUploadFiles, setChkUploadFiles] = useState([]);
    // const [equpFilesUpdated, setEqupFilesUpdated] = useState(false); // 파일첨부 변경여부

    const [cmnt, setCmnt] = useState('');
    const [connYn, setConnYn] = useState('N');
    const [connCd, setConnCd] = useState('');

    const [realTimeYn, setRealTimeYn] = useState('N');
    const [connDataTp, setConnDataTp] = useState('');

    const [cycleDatas, setCycleDatas] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();

            const r = await cmn.api("getLineCombo", {} );
            setLineItem(r.d0);

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {            
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api("getEqup", {p: {equpUid: uid}} );
            if(r === undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
            

            setEqupTp(d0.equpTp);
            setEqupTpNm(d0.equpTpNm);

            setLineUid(d0.lineUid);

            setEqupCd(d0.equpCd);
            setEqupNm(d0.equpNm);
            setModelNm(d0.modelNm);
            setEqupStnd(d0.equpStnd);
            setMaker(d0.maker);
            setMakeYr(d0.makeYr);
            setBuyDy(d0.buyDy);
            setUph(d0.uph);
            setUseYn(d0.useYn);
            setPlcCd(d0.plcCd);
            setEqupImgUid(d0.equpImgUid == null ? util.getUUID() : d0.equpImgUid );

            setCmnt(d0.cmnt);

            setConnYn(d0.connYn);
            setConnCd(d0.connCd);

            
            if(r.d2.length > 0){
                const d2 = r.d2[0];
                setRealTimeYn(d2.realTimeYn);
                setConnDataTp(d2.connDataTp);
            }

            setCycleDatas(d1);

            setSortOrder(d0.sortOrder);

            setEqupImgFiles(r.f1);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(equpTp, '설비유형')) return;
            if(!util.chkMustValue(equpNm, '설비명')) return;

            if(mode === "a") {
                if(!util.confirmYn('등록')) return;
                addEqup();
            }
            else if(mode === "u") { 
                if(!util.confirmYn('수정')) return;
                upEqup();
            }
            else if(mode === "v") { 
                if(!util.confirmYn('삭제')) return;
                delEqup();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // const handleClickDel = (e) => {
    //     try {
    //         if(!util.confirmYn('삭제')) return;
    //         delEqup();
    //     }
    //     catch(err){
    //         cmnStore.handleErrorPage(err, props);
    //     }
    // }

    const addEqup = async() => {
        const ps = {
            p: {
                equpUid: util.getUUID(),
                comUid: authStore.com.uid,
                lineUid: lineUid,
                equpTp: equpTp,
                equpCd: equpCd,
                equpNm: equpNm,
                modelNm: modelNm,
                equpStnd: equpStnd,
                maker: maker,
                makeYr: util.getDateToString('y', makeYr),
                buyDy: util.getDateToString('d', buyDy),
                uph: uph,
                plcCd: plcCd,
                equpImgUid: equpImgUid,
                equpImgFiles: equpImgFiles,
                useYn: useYn,
                cmnt: cmnt,
                connYn: connYn,
                connCd: connCd,
                connDataTp: connDataTp,
                realTimeYn: realTimeYn,
                sortOrder: sortOrder,
                uptUserUid: authStore.user.uid,
                cycles: cycleDatas
            }
        }

        const r = await cmn.api("addEqup", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upEqup = async() => {
        const ps = {
            p: {
                comUid: authStore.com.uid,
                equpUid: uid,
                lineUid: lineUid,
                equpTp: equpTp,
                equpCd: equpCd,
                equpNm: equpNm,
                modelNm: modelNm,
                equpStnd: equpStnd,
                maker: maker,
                makeYr: util.getDateToString('y', makeYr),
                buyDy: util.getDateToString('d', buyDy),
                uph: uph,
                plcCd: plcCd,
                equpImgUid: equpImgUid,
                equpImgFiles: equpImgFiles,
                useYn: useYn,
                cmnt: cmnt,
                connYn: connYn,
                connCd: connCd,
                connDataTp: connDataTp,
                realTimeYn: realTimeYn,
                sortOrder: sortOrder,
                uptUserUid: authStore.user.uid,
                cycles: cycleDatas
            }
        }

        const r = await cmn.api("upEqup", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delEqup = async() => {
        const ps = {
            p: {
                comUid: authStore.com.uid,
                equpUid: uid,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api("delEqup", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("삭제완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;
          
        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
                {/* <ActionButton 
                    title={"삭제완료"}
                    onClick={handleClickDel}
                    marginRight={0}
                    show={mode == "u" ? true : false}
                /> */}
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={120} />
                        <col className="tcon" width={460} />
                    </colgroup>
                    <tbody>
                    {/* <tr>
                        <th className="tit">생산라인</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <SelectBox 
                                        placeholder={'생산라인을 선택하세요.'}
                                        value={lineUid}
                                        dataSource={lineItem}
                                        displayExpr={'lineNm'}
                                        valueExpr={'lineUid'}
                                        showClearButton={true}
                                        onValueChanged={(e)=>{
                                            setLineUid(e.value);
                                        }} 
                                    />                                 
                                    :
                                    <span>{equpTpNm}</span>
                            }
                            </td> 
                            <td className="tcon" rowSpan={8}>
                                <div className="div-img-100" style={{minHeight: 336}}>
                                { 
                                    equpImgInfo !== '' ?                                                
                                    cmn.getRenderImg(equpImgInfo, 448) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr> */}
                        <tr>
                            <th className="tit req">설비유형</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={equpTp}
                                        classId={'401'}
                                        placeholder="설비유형을 선택하세요."
                                        showClearButton={false}
                                        onError={(err) => {
                                            cmnStore.handleErrorPage(err, props);
                                        }}
                                        onValueChanged={(v, o) => { 
                                            // console.log("--- v", v);
                                            setEqupTp(v);
                                        }}
                                    />                                    
                                    :
                                    <span>{equpTpNm}</span>
                            }
                            </td>
                            <td className="tcon" rowSpan={9}>
                                <div className="div-img-100" style={{minHeight: 336}}>
                                { 
                                    equpImgInfo !== '' ?                                                
                                    cmn.getRenderImg(equpImgInfo, 448) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">설비코드</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <TextBox                                         
                                        value={equpCd}
                                        showClearButton={true}
                                        placeholder="설비코드를 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setEqupCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{equpCd}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">설비명</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={equpNm}
                                        showClearButton={true}
                                        placeholder="설비명을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setEqupNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{equpNm}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">모델명</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={modelNm}
                                        showClearButton={true}
                                        placeholder="모델명을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setModelNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{modelNm}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">규격</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={equpStnd}
                                        showClearButton={true}
                                        placeholder="규격을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setEqupStnd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{equpStnd}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제작처</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={maker}
                                        showClearButton={true}
                                        placeholder="제작처를 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMaker(e.value);
                                        }} 
                                    />
                                :
                                    <span>{maker}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">정렬순서</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'40%'}
                                        format={'#,##0'}
                                        value={sortOrder}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setSortOrder(e.value);
                                        }} 
                                    />
                                :
                                    <span>{maker}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제작년도</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <DayDateBox 
                                    value={makeYr}
                                    type={'year'}
                                    displayFormat={cmn.DATE_FORMAT_YR_1}
                                    onValueChanged={(e) => {
                                        setMakeYr(e.value);
                                    }}                    
                                />
                                :
                                    <span>{makeYr}</span>
                            }
                            </td>
                            <th className="tit">구입일자</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <DayDateBox 
                                    value={buyDy}
                                    type={'day'}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setBuyDy(e.value);
                                    }}                    
                                />
                                :
                                    <span>{buyDy}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">생산능력</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                    <ul className="ul-row">
                                        <li>
                                            <NumberBox
                                                width={120}
                                                format={'#,##0'}
                                                value={uph}
                                                defaultValue={0}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setUph(e.value);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                          <span>(EA/HOUR)</span>      
                                        </li>
                                    </ul>
                                :
                                    <span>{uph}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            {/* <th className="tit">PLC코드</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={plcCd}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setPlcCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{plcCd}</span>
                                }
                            </td> */}
                            <th className="tit">사용여부</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <UseYnSwitchBox 
                                    value={useYn}
                                    onValueChanged={(v)=>{
                                        setUseYn(v);
                                    }} 
                                />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                            <td className="tcon" style={{verticalAlign: "top"}} rowSpan={2}>
                                <FileUploadBox
                                    limit={1}
                                    // width={100}
                                    linkWidth={200}
                                    grpUid={equpImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={equpImgFiles}
                                    labelText=''
                                    onLoadInfo={(e) => {
                                        const fi = cmn.getUploadFileInfoStr(e);
                                        setEqupImgInfo(fi);
                                    }}
                                    onUploaded={(e) => { 
                                        setEqupImgFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setEqupImgFiles(e);
                                    }}
                                /> 
                                {/* <FileUploadBox
                                    limit={20}
                                    // accept={'image/*'}
                                    grpUid={equpImgUid}                                    
                                    userUid={authStore.user.uid}
                                    uploadFiles={equpUploadFiles}                                    
                                    uploadFilesBef={equpUploadFilesBef} // 파일첨부 변경여부 확인용

                                    onUploaded={(e) => {
                                        setEqupUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setEqupUploadFiles(e);
                                    }}

                                    onIsUpdated={(v) => {   // 파일첨부 변경여부 확인용
                                        setEqupFilesUpdated(v);
                                    }}
                                /> */}
                            </td> 
                        </tr>  
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                <span>{cmnt}</span>
                            }
                            </td>
                        </tr>   
                        {authStore.user.sgr ? 
                        <>
                        <tr>
                            <th className="tit">연동코드</th>
                            <td className="tcon">
                                <TextBox 
                                    value={connCd}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setConnCd(e.value);
                                    }} 
                                />
                            </td>
                            <th className="tit">연동여부</th>
                            <td className="tcon" colSpan={2}>
                                <UseYnSwitchBox 
                                    value={connYn}
                                    onValueChanged={(v)=>{
                                        setConnYn(v);
                                    }} 
                                />
                            </td>   
                        </tr>
                        <tr>
                            <th className="tit">데이터 유형</th>
                            <td className="tcon" colSpan={2}>
                                <CodeSelectBox
                                    value={connDataTp}
                                    classId={'411'}
                                    placeholder="연동데이타유형"
                                    onValueChanged={(v) => { 
                                        setConnDataTp(v);
                                    }}
                                />
                            </td>   
                            <th className="tit">실시간여부</th>
                            <td className="tcon">
                                <UseYnSwitchBox 
                                    value={realTimeYn}
                                    onValueChanged={(v)=>{
                                        setRealTimeYn(v);
                                    }} 
                                />
                            </td>
                        </tr>
                        </>
                        : ''
                        }                                 
                    </tbody>
                </table>
            </div>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">점검주기</th>
                            <td className="tcon">
                                <ChkCycleChkListBox
                                    // direction="column"
                                    mode={mode}
                                    datas={cycleDatas}
                                    onError={(err) => {
                                        cmnStore.handleErrorPage(err, props);
                                    }}
                                    onValueChanged={(v)=>{
                                        setCycleDatas(v);
                                    }}
                                />
                            </td>                  
                        </tr>
                    </tbody>
                </table>
            </div>            
            </>
        )   
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "설비 등록" 
                : mode === "u" ? "설비 수정"
                : mode === "v" ? "설비 상세보기"
                : "설비" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default EqupPopPage;
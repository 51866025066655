import React, { useState, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { Summary, TotalItem } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, DataGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { cmn, util } from '../../lib';

/** images */

/** 
 *  수주대비 납품실적 팝업
 */
const SalesDeliPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  
   
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        bpuid = ls.bpuid, // 
        sm = ls.sm, // 시작월
        em = ls.em // 종료월
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    const [salesListBizProd, setSalesListBizProd] = useState([]);
    const [deliListBizProd, setDeliListBizProd] = useState(0);

    /** Items */
    //Combo
    const [bizNm, setBizNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
  

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {   
            //if(pageLoad) return;
                
            await loadBizProd();// 정보조회
            await loadSalesDeli();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const loadBizProd = async() => {
        try {  
            const ps = {p: {bizProdUid: bpuid}}

            let r = await cmn.api('getBizProd', ps);
            if(r == null || r === undefined) return;
//            console.log('loadBizProd==>', resData);

            const data = r.d0[0];
            
            setBizNm(data.bizNm);
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setProdStnd(data.prodStnd);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }

    }

    const loadSalesDeli = async() => {
        try {  
            const ps = {
                            p: {
                                bizProdUid: bpuid
                                ,startMon: sm
                                ,endMon: em
                            }
                        }

            const r = await cmn.api("getSalesDeli", ps );
            if(r === undefined || r == null) return;
    
            setSalesListBizProd(r.d0);
            setDeliListBizProd(r.d1);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return(
            <>
            <div className="btn_r mgb10">
            
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">고객사</th>
                            <td className="tcon">
                                <span>{bizNm}</span>
                            </td>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="pop-grid">
                <div className="clearfix">
                <Observer>{() =>
                <>
                    <DataGridView
                        dataSource={salesListBizProd}   
                        pagingable={false}
                        showNo={false}
                        colsWidth={[cmn.CW.dy, cmn.CW.amt, cmn.CW.price, cmn.CW.price, 
                            cmn.CW.dy, cmn.CW.userNm, null]}
                        gridHeight={240}
                    >
                        { cmn.getGridColumnDayType('planDy', '계획일') }
                        { cmn.getGridColumnNumCommaType('salesAmt', '수주량') }
                        { cmn.getGridColumnNumCommaType('salesPrice', '수주단가') }
                        { cmn.getGridColumnNumCommaType('totPrice', '수주금액') }
                        { cmn.getGridColumnDayType('payDy', '납기일') }
                        { cmn.getGridColumn('userNm', '등록자') }
                        { cmn.getGridColumn('cmnt', '비고') }
                        <Summary>
                            <TotalItem
                                column={'salesAmt'}
                                summaryType={'sum'}
                                customizeText={(d) => {
                                    return util.getNumComma(d.value);
                                }}
                            />
                            <TotalItem
                                column={'totPrice'}
                                summaryType={'sum'}
                                customizeText={(d) => {
                                    return util.getNumComma(d.value);
                                }}
                            />
                        </Summary>
                    </DataGridView>
                </>
                }</Observer>
                </div>
            </div>

            <div className="pop-grid">
                <div className="clearfix">
                <Observer>{() =>
                <>
                    <DataGridView
                        dataSource={deliListBizProd}   
                        pagingable={false}
                        showNo={false}
                        gridHeight={240}
                        colsWidth={[cmn.CW.dy, cmn.CW.amt, cmn.CW.price, cmn.CW.price, 
                            cmn.CW.workNum, cmn.CW.lot, null]}
                    >
                        { cmn.getGridColumnDayType('deliDy', '납품일') }
                        { cmn.getGridColumnNumCommaType('deliAmt', '납품량') }
                        { cmn.getGridColumnNumCommaType('deliPrice', '납품단가') }
                        { cmn.getGridColumnNumCommaType('totPrice', '납품금액') }
                        { cmn.getGridColumn('workNum', '작업번호') }
                        { cmn.getGridColumn('lot', 'Lot') }
                        { cmn.getGridColumn('userNm', '판매자') }
                        <Summary>
                            <TotalItem
                                column={'deliAmt'}
                                summaryType={'sum'}
                                customizeText={(d) => {
                                    return util.getNumComma(d.value);
                                }}
                            />
                            <TotalItem
                                column={'totPrice'}
                                summaryType={'sum'}
                                customizeText={(d) => {
                                    return util.getNumComma(d.value);
                                }}
                            />
                        </Summary>
                    </DataGridView>
                </>
                }</Observer>
                </div>
            </div>

            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                "수주/납품건" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default SalesDeliPopPage;

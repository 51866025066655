import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  작업지시 생성(수주단품)
 */
const OrderTakeAnalysisPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [planMon, setPlanMon] = useState(util.getNow());
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "계획월",
                width: "200px",
                item: 
                    <DayDateBox 
                        value={planMon}
                        type={'month'}
                        displayFormat={cmn.DATE_FORMAT_MON_1}
                        onValueChanged={(e) => {
                            setPlanMon(e.value);
                        }}                    
                    />
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",
                item: 
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />        
            }
        ]
    ];
    /* #endregion */    

     // * Grid
     const [orderTakeAnalysisList, setOrderTakeAnalysisList] = useState([]);
     const [orderTakeAnalysisListTot, setOrderTakeAnalysisListTot] = useState(0);
     const [selectedItem, setSelectedItem] = useState(null);
     // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, planMon]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
      
        const ps =  {
            p: {
                prodTp: '', //단품 검색 코드
                planMon: util.getDateToString('M', planMon),
                prodId: prodId, 
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getOrderTakeAnalysisList", ps );
        if(r === undefined || r == null) return;

        setOrderTakeAnalysisList(r.d0)
        setOrderTakeAnalysisListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
 
    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickOrder = () => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("a", selectedItem.prodUid, selectedItem.lackAmt);
        }
    }

    const handleClickTakeProd = () => {
        if(util.chkListSelected(selectedItem)){
            const url = `/order/takeprodpop?${qs.stringify({uid: selectedItem.prodUid, mon: util.getDateToString('M', planMon)})}`;        
            util.openWindow(url, 300, 360);    
        }
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }
 
    // * Functions


    const openCrudPopup = (mode, prodUid, amt) => {
        // page mode: v(View), a(Add), u(Update)
        //const url = `/order/ordertakepop?${qs.stringify({mode: mode, uid: prodUid, amt: amt})}`;        
        //util.openWindow(url, 600, 360);    

        const url = `/order/orderpop?${qs.stringify({mode: mode, puid: prodUid, amt: amt})}`;        
        util.openWindow(url, 850, 600); 
    }
    
    /* ====================
     *  Render
     * ==================== */
 
    // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickOrder}
                    title="발주등록"
                    show={authStore.page.crtYn}
                /> 
                 <ActionButton 
                    onClick={handleClickTakeProd}
                    title="분석내역"
                    type="2"
                    show={authStore.page.viewYn}
                />     
            </ListTopView>   
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="소요분석 내역"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView> 
            <DataGridView            
                dataSource={orderTakeAnalysisList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                page={curPage}
                total={orderTakeAnalysisListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}

                colsWidth={[cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd,
                    cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, null]}
            >
                { cmn.getGridColumnProdInfo('prodNm', '품명' ,renderGridHeaderCell)}                    
                { cmn.getGridColumn('prodId', '품번' ,renderGridHeaderCell)}                    
                { cmn.getGridColumn('prodStnd', '규격' ,renderGridHeaderCell)}                    
                { cmn.getGridColumnNumCommaType('takeAmt', '소요량') }
                { cmn.getGridColumnNumCommaType('stockAmt', '현재재고') }
                { cmn.getGridColumnNumCommaType('lackAmt', '부족량') }
                { cmn.getGridColumnNumCommaType('orderAmt', '발주량') }
                { cmn.getGridColumnNumCommaType('noOrderAmt', '미발주량') }

                <Column />
            </DataGridView>
            </>
        );

    }


    return (
        <>
        { renderContent() }
        </>
    )
}

export default OrderTakeAnalysisPage;
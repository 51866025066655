const orderApiz = {
    
    getOrderList: {
        title: "발주 목록 조회",
        url: "/api/order/getOrderList",
        method: "POST"
    },
    getOrder: {
        title: "발주 상세 조회",
        url: "/api/order/getOrder",
        method: "POST"
    },
    addOrder: {
        title: "발주 등록",
        url: "/api/order/addOrder",
        method: "POST"
    },
    delOrder: {
        title: "발주 삭제",
        url: "/api/order/delOrder",
        method: "POST"
    },
    getOrderCombo: {
        title: "발주 콤보",
        url: "/api/order/getOrderCombo",
        method: "POST"
    },   
    getOrderTakeAnalysisList: {
        title: "발주소요분석 목록 조회",
        url: "/api/order/getOrderTakeAnalysisList",
        method: "POST"
    },   
    getOrderInList: {
        title: "납기 목록 조회",
        url: "/api/order/getOrderInList",
        method: "POST"
    },   
    getTakeProd: {
        title: "소요분석내역",
        url: "/api/order/getTakeProd",
        method: "POST"
    },   
    
}

export default orderApiz;
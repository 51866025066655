import React from 'react';
import { SelectBox } from 'devextreme-react';
import _ from 'lodash';

/** components */

/** stores & lib */
// import AuthStore from '../../stores/AuthStore';

/** images */

/** 
 *  List Top View
 */
const UseYnSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        reff,
        value,
        onValueChanged,
        showClearButton=true
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */


    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {
        return (
            <>
            <SelectBox 
                ref={reff}
                width={80}
                value={value}                            
                items={["Y", "N"]}
                placeholder="전체"
                showClearButton={showClearButton}
                onValueChanged={onValueChanged}
            />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default UseYnSelectBox;
import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';

/** components */

/** stores & lib */
import { apiz, cmn, util } from '../../lib';

/** 
 *  Calendar
 */
const CalendarView = (props) => {

    /* ====================
     *  Store 
     * ==================== */


    /* ====================
     *  Object
     * ==================== */

    const {
       yrMon,
       workTp,  // equp-chk: 설비점검
       uid,
       datas=[],
       onClick,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [dys, setDys] = useState([]);

    useEffect(() => {
        loadPage();
    },[yrMon, workTp, datas]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            const ps = { p: { yrMon: util.getDateToString('M', yrMon) } }  
            const r = await cmn.api("getDyMon", ps);
          //  console.log(r.d0)
            setDys(r.d0);
        }
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 

     /** 설비점검 Data Rendering */
    const renderDataEqupChk = (dy) => {        
        if(util.getChkNull(yrMon) === '' || util.getChkNull(uid) === '') return;

        // console.log("--- datas", datas);

        const o1 = _.find(datas, {chkCycleDy: dy, timeTp: cmn.CMNCO_TIMETP.DAY});

        let isChk1 = false;
        let isGood1 = false;
        let disp1 = '';
        if(o1 === undefined) disp1 = "주간 점검하기";
        else {
            isChk1 = true;
            if(o1.totCnt === o1.goodCnt) isGood1 = true;
            disp1 = `${o1.timeTpNm} : ${isGood1 ? '양호' : ('불량 ( ' + o1.goodCnt + ' / ' + o1.totCnt + ' )')} `;
        }

        const o2 = _.find(datas, {chkCycleDy: dy, timeTp: cmn.CMNCO_TIMETP.NIGHT});

        let isChk2 = false;
        let isGood2 = false;
        let disp2 = '';
        if(o2 === undefined) disp2 = "야간 점검하기";
        else {
            isChk2 = true;
            if(o2.totCnt === o2.goodCnt) isGood2 = true;
            disp2 = `${o2.timeTpNm} : ${isGood2 ? '양호' : ('불량 ( ' + o2.goodCnt + ' / ' + o2.totCnt + ' )')} `;
        }

        return (
            <>
            <ul>
                <li>
                    <a
                        href="#"
                        className={`btn-chk ${isChk1 ? isGood1 ? 'good' : 'bad' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            onClick(uid, dy, cmn.CMNCO_TIMETP.DAY);
                        }}
                    >
                        {
                            disp1
                        }
                    </a>
                </li>
                <li>
                    <a
                        href="#"
                        className={`btn-chk ${isChk2 ? isGood2 ? 'good' : 'bad' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            onClick(uid, dy, cmn.CMNCO_TIMETP.NIGHT);
                        }}
                    >
                        {
                            disp2
                        }
                    </a>
                </li>
            </ul>            
            </>
        )
    }

    const renderWkSeq = (wkSeq) => {
        return (
            <>
            <div className="wk-seq">
                <span>{wkSeq}</span>
            </div>
            </>
        )
    }

    const renderDayCell = (dy) => {
        const yrMon_ = util.getDateToString('M', yrMon);
        const o =  _.split(dy, '^'); // dy^yr_mon^dd^wk 

        return (
            <>
            <div className="dy-cell">
                <div className={`disp-dy fl-r`}>
                    <span className={`${util.isToday(o[0]) ? 'today' : yrMon_ != o[1] ? 'diff-mon' : ''}`}>
                        {o[2]}
                    </span>
                </div>
                <div className="disp-info">
                    {
                        workTp === "equp-chk" ? renderDataEqupChk(o[0]) 
                        : null
                    }
                </div>
            </div>
            </>
        )
    }

    const renderContent = () => {
        return (
            <>
            <div className="cal-vw">
                <table>
                    <colgroup>
                        <col width={"9%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
                        <col width={"13%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="b-left">주차</th>
                            <th>월</th>
                            <th>화</th>
                            <th>수</th>
                            <th>목</th>
                            <th>금</th>
                            <th>토</th>
                            <th>일</th>
                        </tr>
                        {
                            _.map(dys, (v, k) => {
                                return(
                                    <tr key={k}>
                                        <td className="b-left">{renderWkSeq(v.wkSeq)}</td>
                                        <td>{renderDayCell(v.wk2)}</td>
                                        <td>{renderDayCell(v.wk3)}</td>
                                        <td>{renderDayCell(v.wk4)}</td>
                                        <td>{renderDayCell(v.wk5)}</td>
                                        <td>{renderDayCell(v.wk6)}</td>
                                        <td>{renderDayCell(v.wk7)}</td>
                                        <td>{renderDayCell(v.wk1)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default CalendarView;
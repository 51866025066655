import React, { useState, useEffect, useContext, useRef } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';

import _ from 'lodash';

/** components */

/** stores & lib */
import AuthStore from '../../stores/AuthStore';

/** images */

/** 
 *  List Top View
 */
const ListTopView = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const {


    } = props;


    /* ====================
     *  State / Effect
     * ==================== */

    useEffect(() => {
        // ...
    },[]);

    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 

    const renderContent = () => {
        // console.log("=== authStore.curMenu", authStore.curMenu);
        return (
            <>
            <Observer>{() =>           
            <div className="historyWrap clearfix">
                <div className="fl-l">
                    <p className="indicate">
                        <span>{ authStore.curMenu.parentNm }</span>
                        <span className="arr">&gt;</span>
                        <span className="ftstyle1">{ authStore.curMenu.menuNm }</span>
                    </p>
                </div>
                <div className="fl-r btn_r">
                { props.children }
                </div>
            </div>
            }</Observer>
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default ListTopView;
import React, { useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react'; // MobX 에서 사용하는 Provider

import { locale, loadMessages } from "devextreme/localization";

/** components */
import App from './App';
import koMessages from './locales/ko.json';

/** stores & lib */
import AuthStore from './stores/AuthStore';

const Root = () => {

    /* ====================
     *  Store 
     * ==================== */    

    /* ====================
     *  Object
     * ==================== */

        //  ...

    /* ====================
     *  State / Effect
     * ==================== */

    locale('ko');
    loadMessages(koMessages);

    // useEffect(() => {
        // console.log(" @@@ Root ...");
        // authStore.loadMenuUser();
    // },[]);

    return (
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    );

}

export default Root;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    InspSampBox, DayDateBox, DateTimeBox, FileUploadBox,
    CodeSelectBox, ChkCycleChkListBox, UseYnSwitchBox, 
    UserSelectBox , EqupSelectBox ,
    PopupLayout, ActionButton, SubActionButton, GridTopView, DataGridView 
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';
import { Column } from 'devextreme-react/data-grid';

/** 
 *  금형 점검 팝업
 */
const MoldInspPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,   // mold_move_his_uid
        mouid = ls.mouid // mold_uid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    // 금형정보
    const [moldCd, setMoldCd] = useState('');
    const [moldNm, setMoldNm] = useState('');
    const [moldStnd, setMoldStnd] = useState('');
    const [saveLoca, setSaveLoca] = useState('');
    const [modelNm, setModelNm] = useState('');
    const [limitShot, setLimitShot] = useState(0);
    const [mngShot, setMngShot] = useState(0);
    const [startShot, setStartShot] = useState(0);
    const [totalShot, setTotalShot] = useState(0);
    const [cmnt, setCmnt] = useState('');  
    const [moldTpNm, setMoldTpNm] = useState('');
    const [prodProcs, setProdProcs] = useState([]);
       
    // 점검 정보
    const [inspDy, setInspDy] = useState(util.getDateToString('d',util.getNow(), '-'));    
    const [inspTotalShot, setInspTotalShot] = useState(null);
    const [inspCont, setInspCont] = useState('');
    const [moldInspTp, setMoldInspTp] = useState('');
    const [moldInspTpNm, setMoldInspTpNm] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadMoldInfo();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /** 금형 정보 조회 */
    const loadMoldInfo = async() => {  
        try {            
            // if(mode == 'a') return;
            if(util.getChkNull(mouid) == '') return;

            const r = await cmn.api("getMold", {p: {moldUid: mouid}} );
            if(r == undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
            const d2 = r.d2.length > 0 ? r.d2[0] : null;

            setMoldCd(d0.moldCd);
            setMoldNm(d0.moldNm);
            setMoldStnd(d0.moldStnd);
            setSaveLoca(d0.saveLoca);
            setModelNm(d0.modelNm);
            setLimitShot(d0.limitShot);
            setMngShot(d0.mngShot);
            setStartShot(d0.startShot);
            setTotalShot(d0.totalShot); // 현재 타수 설정
            setCmnt(d0.cmnt);            

            setMoldTpNm(d0.moldTpNm);
            setProdProcs(d1);

            if(mode != 'a') {
                const rr = await cmn.api("getMoldInspHis", {p: {moldInspHisUid: uid}} );
                if(rr == undefined || rr == null) return;

                const dd0 = rr.d0[0];

                setInspDy(dd0.inspDy);
                setMoldInspTp(dd0.moldInspTp);
                setInspTotalShot(dd0.totalShot);
                setInspCont(dd0.inspCont);
            }
            else {
                setInspTotalShot(d0.totalShot);
            }

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(moldInspTp, '점검유형')) return;

            if(mode == "a") {
                if(!util.confirmYn("등록")) return;
                addMoldInsp();
            }
            else if(mode == "u") { 
                if(!util.confirmYn("수정")) return;
                upMoldInsp();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickDel = (e) => {
        try {
            if(!util.confirmYn("삭제")) return;
            delMoldInsp();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const addMoldInsp = async() => {        
        const ps = {
            p: 
                {
                    moldInspHisUid: util.getUUID(),
                    moldUid: mouid,
                    inspDy: util.getDateToString('d',inspDy),
                    moldInspTp: moldInspTp,
                    totalShot: inspTotalShot,
                    inspCont: inspCont,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("addMoldInsp", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upMoldInsp = async() => {        
        const ps = {
            p: 
                {
                    moldInspHisUid: uid,
                    inspDy: util.getDateToString('d',inspDy),
                    moldInspTp: moldInspTp,
                    totalShot: inspTotalShot,
                    inspCont: inspCont,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("upMoldInsp", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }    

    const delMoldInsp = async() => {        
        const ps = {
            p: 
                {
                    moldInspHisUid: uid
                }
        }

        const r = await cmn.api("delMoldInsp", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("삭제완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }    

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        // 금형이동유형 moldMoveTp : 불출(40801), 회수(40802)        
        
        const completeButtonTitle = 
              mode == "a" ? "점검등록완료"
            : mode == "u" ? "점검수정완료"
            : "" ;
          
        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    width={140}
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    show={true}
                />
                <ActionButton 
                    title={"삭제완료"}
                    onClick={handleClickDel}
                    marginRight={0}
                    show={mode == "u" ? true : false}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={200}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">금형코드</th>
                            <td className="tcon">
                                <span>{moldCd}</span>
                            </td>
                            <th className="tit">금형유형</th>
                            <td className="tcon">
                                <span>{moldTpNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">금형명</th>
                            <td className="tcon">
                                <span>{moldNm}</span>
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{moldStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">보관위치</th>
                            <td className="tcon">
                                <span>{saveLoca}</span>
                            </td>
                            <th className="tit">모델</th>
                            <td className="tcon">
                                <span>{modelNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">타수</th>
                            <td className="tcon" colSpan={3}>
                                <span>{`보증: ${limitShot}  누적: ${totalShot}  시작: ${startShot}  관리: ${mngShot}`}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                <span>{cmnt}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <GridTopView title="제품공정">   
            </GridTopView>
            <DataGridView         
                // reff={prodProcGrid}   
                dataSource={prodProcs}   // store
                // gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[
                    200, 180, 80, null                         
                ]}
                pagingable={false}
                
                columnResizingMode={'nextColumn'}
                // selection='multiple' 
                // onSelectionChanged={handleGridSelectionChanged}            
            >
                { cmn.getGridColumn('prodNm', '품명' ) }
                { cmn.getGridColumn('procNm', '공정') }
                { cmn.getGridColumn('cavity', '캐비티' ) }

                <Column />
                
            </DataGridView>
            <GridTopView 
                title={"점검정보"} 
                style={{marginTop: 16}}
            >   
            </GridTopView>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={200}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">점검일자</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={inspDy}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setInspDy(e.value);
                                    }}                    
                                />
                            </td>
                            <th className="tit req">점검유형</th>
                            <td className="tcon">
                                <CodeSelectBox
                                    value={moldInspTp}
                                    classId={'409'}
                                    placeholder="점검유형 선택하세요"
                                    onValueChanged={(v) => { 
                                        setMoldInspTp(v);
                                    }}
                                />                              
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">점검타수</th>
                            <td className="tcon" colSpan={3}>
                                <NumberBox
                                    width={120}
                                    format={'#,##0,###'}
                                    value={inspTotalShot}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setInspTotalShot(e.value);
                                    }}
                                />
                            </td>
                          
                        </tr>
                        <tr>
                            <th className="tit">점검내용</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                <TextBox 
                                    value={inspCont}
                                    showClearButton={true}
                                    placeholder="점검내용을 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setInspCont(e.value);
                                    }}
                                /> 
                                :
                                    <span>{inspCont}</span>
                            }
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            </>
        )   
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "금형 점검등록" 
                : mode == "u" ? "금형 점검수정"
                : "금형 점검" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default MoldInspPopPage;
import React, { useState, useEffect, useContext, useRef,  } from 'react';
import { Observer, } from 'mobx-react-lite';
import { CheckBox, TextBox } from 'devextreme-react';
import { Column  } from 'devextreme-react/data-grid';
import SplitterLayout from 'react-splitter-layout';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    EditGridView, SrchButton, SubActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  권한별 접근 관리
 */
const AuthMenuPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const useNmEl = useRef(null);
    // const [useNm, setUseNm] = useState('');    

    // const count = useRef(0);
    const aRefs = useRef([]);
    const vRefs = useRef([]);
    const cRefs = useRef([]);
    const uRefs = useRef([]);
    const dRefs = useRef([]);
    // //const chkBox = null;

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "권한/부서명",
                item: 
                <TextBox 
                    ref={useNmEl}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="권한/부서명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                />        
            }
        ]
    ];
    /* #endregion */    

    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);

    const [userAuthMenuItems, setUserAuthMenuItems] = useState([]);
    const [authDeptItems, setAuthDeptItems] = useState([]);
    

    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, []);

    useEffect(() => {
        !pageLoad && handleClickSearch2();
    }, [selectedItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    

    const loadList1 = async() => {
        setUserAuthMenuItems([]);

        const ps =  {
            p: {
                useNm: util.getValEl(useNmEl), 

                sortCol: sortItem.col,
                sortTp: sortItem.tp
            }
        }

        let r = await cmn.api('getAuthDeptList', ps);
        if(r == null || r === undefined) return;
        
        let data = r.d0;
        let authDeptItems_ = [];

        _.forEach(data, (v, k) => {
            authDeptItems_.push({
                 useUid: v.useUid
                ,useTp: v.useTp
                ,useTpNm: v.useTpNm
                ,useNm: v.useNm
                ,cmnt: v.cmnt
            })
        })       
        
      //  chkBox = React.createRef(new Array(10).fill(React.createRef()));

        setAuthDeptItems(authDeptItems_);
    }

    const loadList2 = async() => {
        if(selectedItem == null) return; 

        setUserAuthMenuItems([]);

        const ps =  {
            p: {
                authUid: selectedItem.useUid, 
            }
        }

        let r = await cmn.api('getAuthMenuAuthUid', ps);
        if(r == null || r === undefined) return;
        let data = r.d0;
        let userAuthMenuItems_ = [];
        let cnt = 0;

        _.forEach(data, (v, k) => {
            let allYn = false;

            cnt = v.parentSortOrder !== cnt ? cnt + 1: cnt;

            if(v.sortOrder === 0 && v.parentSortOrder === cnt)
            {
                if(_.find(data, {parentSortOrder:cnt, viewYn:'N'}) === undefined)
                    v.viewYn = 'Y'
                else
                    v.viewYn = 'N'

                if(_.find(data, {parentSortOrder:cnt, crtYn:'N'}) === undefined)
                    v.crtYn = 'Y'
                else
                    v.crtYn = 'N'
                
                if(_.find(data, {parentSortOrder:cnt, upYn:'N'}) === undefined)
                    v.upYn = 'Y'
                else
                    v.upYn = 'N'
            
                if(_.find(data, {parentSortOrder:cnt, delYn:'N'}) === undefined)
                    v.delYn = 'Y'
                else
                    v.delYn = 'N'
            }

            if(v.viewYn === 'Y' && v.crtYn === 'Y' &&
                v.upYn === 'Y' && v.delYn === 'Y' )
                allYn = true;
            
            userAuthMenuItems_.push({
              parentMenuNm: v.parentMenuNm
              ,menuUid: v.menuUid
              ,menuNm: v.menuNm === '' ? '전체선택': v.menuNm
              ,allYn: allYn
              ,viewYn: v.viewYn === 'Y' ? true : false
              ,crtYn: v.crtYn === 'Y' ? true : false
              ,upYn: v.upYn === 'Y' ? true : false
              ,delYn: v.delYn === 'Y' ? true : false
              ,groupId: v.parentSortOrder
              ,sortOrder: v.sortOrder
            })

        })        

        

   //     authMenuItems.current = userAuthMenuItems_;
        // count.current += 1;
        // console.log(authMenuItems.current);
        setUserAuthMenuItems(userAuthMenuItems_);
        console.log('2');
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const handleClickSearch2 = async(e) => {
        try {
            await loadList2();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickSave = async () => {
        try {
            let authMenuItems_ = [];
            let userAuthMenuItems_ = [...userAuthMenuItems];

            _.forEach(userAuthMenuItems_, (v,k) => {
                if(v.sortOrder !== 0)
                {
                    authMenuItems_.push({
                        authMenuUid: util.getUUID(),
                        comUid: authStore.com.uid,
                        menuUid: v.menuUid,
                        useUid: selectedItem.useUid,
                        useTp: selectedItem.useTp,
                        crtYn: v.crtYn === true ? "Y" : "N",
                        upYn: v.upYn === true ? "Y" : "N",
                        viewYn: v.viewYn === true ? "Y" : "N",
                        delYn: v.delYn === true ? "Y" : "N",
                        uptUserUid: authStore.user.uid,
                    })
                }
            })

            const ps =  {
                p: authMenuItems_
            }

            console.log(ps);
            let r = await cmn.api('addAuthMenu', ps);
            if(r == null || r === undefined) return;

            util.cmnSucAlert('저장');
            util.clickElement('subSrchButton');
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleGridSelectionChanged = ({selectedRowsData}) => {
   //     console.log('handleGridSelectionChanged');
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleGridCheckBoxClick = (rowIndex, isChecked, type) => {
        const items = [...userAuthMenuItems];
        
        // if(items[rowIndex].sortOrder === 0)
        // {
        //     let groupId_ = items[rowIndex].groupId;
        //     if(type === 'a')
        //     {
        //         _.forEach(items, (v, k) => {
        //             if(v.groupId === groupId_)
        //             {
        //                 v.viewYn = isChecked;
        //                 v.crtYn = isChecked;
        //                 v.upYn = isChecked;
        //                 v.delYn = isChecked;
        //                 v.allYn = isChecked;
        //             }
        //         })
        //     }else if(type === 'v')
        //     {
        //         _.forEach(items, (v, k) => {
        //             if(v.groupId === groupId_)
        //             {
        //                 v.viewYn = isChecked;
        //             }
        //         })
        //     }else if(type === 'c')
        //     {
        //         _.forEach(items, (v, k) => {
        //             if(v.groupId === groupId_)
        //             {
        //                 v.crtYn = isChecked;
        //             }
        //         })
        //     }else if(type === 'u')
        //     {
        //         _.forEach(items, (v, k) => {
        //             if(v.groupId === groupId_)
        //             {
        //                 v.upYn = isChecked;
        //             }
        //         })
        //     }else if(type === 'd')
        //     {
        //         _.forEach(items, (v, k) => {
        //             if(v.groupId === groupId_)
        //             {
        //                 v.delYn = isChecked;
        //             }
        //         })
        //     }
        // }
        // else
        //{
            if(type === 'a')
            {
                items[rowIndex].viewYn = isChecked;
                items[rowIndex].crtYn = isChecked;
                items[rowIndex].upYn = isChecked;
                items[rowIndex].delYn = isChecked;
                items[rowIndex].allYn = isChecked;
            }else if(type === 'v')
            {
                items[rowIndex].viewYn = isChecked;
            }else if(type === 'c')
            {
                items[rowIndex].crtYn = isChecked;
            }else if(type === 'u')
            {
                items[rowIndex].upYn = isChecked;
            }else if(type === 'd')
            {
                items[rowIndex].delYn = isChecked;
            }
       // }

        
     //   authMenuItems.current = items;
        // //a.current.props.checked = true;
        // console.log(authMenuItems.current);
        setUserAuthMenuItems(items); 
    }
    /* ====================
     *  Render
     * ==================== */

    const renderLeftContent = () => {
        return (
            <>
            <GridTopView
                title="권한/부서 목록"
            >
            </GridTopView>   
            <DataGridView            
                dataSource={authDeptItems}   
                gridHeight={authStore.windowHeight - cmn.BH.H20}

                paging={false}

                // sort                
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}

                colsWidth={[100, 150, 150, null]}
            >
                { cmn.getGridColumn('useTpNm', '권한유형')}
                { cmn.getGridColumn('useNm', '권한명/조직명')}
                { cmn.getGridColumn('cmnt', '비고')}
                <Column />
                
            </DataGridView>
            </>
        )   
    }

    const renderRightContent = () => {

        return (
            <>
            <GridTopView
                title="메뉴 목록"
            >
                <SubActionButton 
                    onClick={handleClickSave}
                    title="저장"
                    show={authStore.page.crtYn}
                /> 
            </GridTopView> 
            <div style={{height:authStore.windowHeight - cmn.BH.H20, overflow:'auto'}}>
                <table  className="board_grid_type">
                <colgroup>
                    <col width={"20%"} />
                    <col width={"30%"} />
                    <col width={"10%"} />
                    <col width={"10%"} />
                    <col width={"10%"} />
                    <col width={"10%"} />
                    <col width={"10%"} />
                </colgroup>
                <tbody>
                    <tr>
                        <th className="tit">
                            대메뉴
                        </th>
                        <th className="tit">
                            소메뉴
                        </th>
                        <th className="tit">
                            전체선택
                        </th>
                        <th className="tit">
                            조회
                        </th>
                        <th className="tit">
                            등록
                        </th>
                        <th className="tit">
                            수정
                        </th>
                        <th className="tit">
                            삭제
                        </th>
                    </tr>
                {
                
                    _.map(userAuthMenuItems, (v, k) => {
                    // console.log(k);
                        return(
                            <tr  key = {k}>
                                <td className="tcon">
                                    {v.parentMenuNm}
                                </td>
                                <td className="tcon">
                                    {v.menuNm}
                                </td>
                                <td className="tcon">
                                    {/* <CheckBox 
                                        ref={(el) => (aRefs.current[k] = el)}
                                        defaultValue={v.allYn}
                                        onClick={() => {
                                            // if(aRefs.current[k].isChecked)
                                            // {
                                            //     vRefs.current[k].checked = true;
                                            //     cRefs.current[k].checked = true;
                                            //     uRefs.current[k].checked = true;
                                            //     dRefs.current[k].checked = true;
                                            // }else
                                            // {
                                                vRefs.current[k].checked = true;
                                                // cRefs.current[k].checked = false;
                                                // uRefs.current[k].checked = true;
                                                // dRefs.current[k].checked = true;
                                        // }
                                        }}  
                                    /> */}
                                    <input type='checkbox' 
                                        ref={(el) => (aRefs.current[k] = el)}
                                        defaultChecked={v.allYn}
                                        onClick={()=>{
                                            if(aRefs.current[k].checked)
                                            {
                                                vRefs.current[k].checked=true;
                                                cRefs.current[k].checked=true;
                                                uRefs.current[k].checked=true;
                                                dRefs.current[k].checked=true;
                                            }else
                                            {
                                                vRefs.current[k].checked=false;
                                                cRefs.current[k].checked=false;
                                                uRefs.current[k].checked=false;
                                                dRefs.current[k].checked=false;
                                            }

                                            handleGridCheckBoxClick(k, aRefs.current[k].checked, 'a');
                                        }}
                                    
                                    ></input>
                                </td>
                                <td className="tcon ">
                                    {/* <CheckBox 
                                        ref={(el) => (vRefs.current[k] = el)}
                                        defaultValue={v.viewYn}
                                        onValueChanged={(e) => {
                                        }}  
                                    /> */}
                                    <input type='checkbox' 
                                        ref={(el) => (vRefs.current[k] = el)}
                                        defaultChecked={v.viewYn}
                                        onClick={()=>{
                                            handleGridCheckBoxClick(k, vRefs.current[k].checked, 'v');
                                            //handleGridCheckBoxClick(c.rowIndex, e.value, 'a');
                                        }}
                                    ></input>
                                </td>
                                <td className="tcon">
                                    {/* <CheckBox 
                                        ref={(el) => (cRefs.current[k] = el)}
                                        defaultValue={v.crtYn}
                                        onValueChanged={(e) => {
                                        }}  
                                    /> */}
                                    <input type='checkbox' 
                                        ref={(el) => (cRefs.current[k] = el)}
                                        defaultChecked={v.crtYn}
                                        onClick={()=>{
                                            handleGridCheckBoxClick(k, vRefs.current[k].checked, 'v');
                                            //handleGridCheckBoxClick(c.rowIndex, e.value, 'a');
                                        }}
                                    ></input>
                                </td>
                                <td className="tcon">
                                    {/* <CheckBox 
                                        ref={(el) => (uRefs.current[k] = el)}
                                        defaultValue={v.upYn}
                                        onValueChanged={(e) => {
                                        }}  
                                    /> */}
                                    <input type='checkbox' 
                                        ref={(el) => (uRefs.current[k] = el)}
                                        defaultChecked={v.upYn}
                                        onClick={()=>{
                                            handleGridCheckBoxClick(k, uRefs.current[k].checked, 'v');
                                            //handleGridCheckBoxClick(c.rowIndex, e.value, 'a');
                                        }}
                                    ></input>
                                </td>
                                <td className="tcon">
                                    {/* <CheckBox 
                                        ref={(el) => (dRefs.current[k] = el)}
                                        defaultValue={v.delYn}
                                        onValueChanged={(e) => {
                                        }}  
                                    /> */}
                                    <input type='checkbox' 
                                        ref={(el) => (dRefs.current[k] = el)}
                                        defaultChecked={v.delYn}
                                        onClick={()=>{
                                            handleGridCheckBoxClick(k, dRefs.current[k].checked, 'v');
                                            //handleGridCheckBoxClick(c.rowIndex, e.value, 'a');
                                        }}
                                    ></input>
                                </td>
                            </tr>
                        )
                    })
                    
                    
                }
                
                </tbody>
                </table>
            </div>  
            
{/*             
            <GridTopView
                title="메뉴 목록"
            >
                <SubActionButton 
                    onClick={handleClickSave}
                    title="저장"
                    show={authStore.page.crtYn}
                /> 
            </GridTopView>                        
            <EditGridView            
                dataSource={authMenuItems.current}   // store
           //     dataSource={userAuthMenuItems}   // store
                
                gridHeight={authStore.windowHeight - cmn.BH.H20}
                colsWidth={[100, 200, 50, 50, 50, 50, 50, null]}
                
            >
                <Column 
                    alignment={'center'}
                    dataField={'parentMenuNm'} 
                    caption={'상위메뉴명'}  
                    allowEditing={false}
                    cellRender={(d) => {
                        const o = d.row.data;
                        return (
                        o.sortOrder === 0 ? <span style={{color:'red'}}>{d.value}</span> : d.value
                        )
                    }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'menuNm'} 
                    caption={'메뉴명'}
                    allowEditing={false}
                    cellRender={(d) => {
                        const o = d.row.data;
                        return (
                        o.sortOrder === 0 ? <span style={{color:'red'}}>{d.value}</span> : d.value
                        )
                    }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'allYn'} 
                    caption={'전체'}
                    editCellRender={(c) => {  
                        return (
                        <>
                            {
                                <CheckBox 
                                    ref={(el) => (vRefs.current[1] = el)}
                                    defaultValue={c.value}
                                    checked={false}
                                    onValueChanged={(e) => {
                                        handleGridCheckBoxClick(c.rowIndex, e.value, 'a');
                                    }}  
                                />
                            }
                        </>
                        )
                    }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'viewYn'} 
                    caption={'조회'}  
                    editCellRender={(c) => {  
                        return (
                        <>
                            {
                                <CheckBox 
                                    defaultValue={c.value}
                                    checked={false}
                                    onValueChanged={(e) => {
                                        handleGridCheckBoxClick(c.rowIndex, e.value, 'v');
                                    }}  
                                />
                            }
                        </>
                        )
                    }}                  
                />
                <Column 
                    alignment={'center'}
                    dataField={'crtYn'} 
                    caption={'등록'}
                    editCellRender={(c) => {  
                        return (
                        <>
                            {
                                <CheckBox 
                                    defaultValue={c.value}
                                    checked={false}
                                    onValueChanged={(e) => {
                                        handleGridCheckBoxClick(c.rowIndex, e.value, 'c');                                   

                                    }}  
                                />
                            }
                        </>
                        )
                    }}  
                />
                <Column 
                    alignment={'center'}
                    dataField={'upYn'} 
                    caption={'수정'}
                    editCellRender={(c) => {  
                        return (
                        <>
                            {
                                <CheckBox 
                                    defaultValue={c.value}
                                    checked={false}
                                    onValueChanged={(e) => {
                                        handleGridCheckBoxClick(c.rowIndex, e.value, 'u');                                
                                    }}  
                                />
                            }
                        </>
                        )
                    }}  
                />
                <Column 
                    alignment={'center'}
                    dataField={'delYn'} 
                    caption={'삭제'}
                    editCellRender={(c) => {  
                        return (
                        <>
                            {
                                <CheckBox 
                                    defaultValue={c.value}
                                    
                                    checked={false}
                                    onValueChanged={(e) => {
                                        handleGridCheckBoxClick(c.rowIndex, e.value, 'd');                                  
                                    }}  
                                />
                            }
                        </>
                        )
                    }}  
                />
                <Column />                 
            </EditGridView> */}
            </>
        )   
    }
 
    const renderContent = () => {        
     //   console.log("### renderContent ~~~");

        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    marginRight={0}
                    onClick={handleClickSearch}
                />
                <SrchButton 
                    id="subSrchButton"
                    marginRight={0}
                    onClick={handleClickSearch2}
                    show={false}
                />
            </ListTopView>
            }</Observer>
            <ListSearchView searchItems={searchItems} />
            <Observer>{() =>    
            <div className="cont-split"
                style={{
                    marginTop: 16,
                }}
            >
                <SplitterLayout
                    customClassName={`split-layout-cust ${authStore.sideOpened === true ? "is-visible" : ""}`}
                >
                    <div className="cont-split-left"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderLeftContent() }
                    </div>
                    <div className="cont-split-right"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderRightContent() }
                    </div>
                </SplitterLayout>
            </div>       
            }</Observer>         
            </>
        );

    }

    return (
        <>
        { renderContent() }
        </>
    )
}

export default AuthMenuPage;

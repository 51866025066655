import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { SelectBox, TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, DayDateBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  수입검사 불량현황
 */
const InInspBadStaPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate(-7));
    const [endDy, setEndDy] = useState(util.getNow());    
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [treatYn, setTreatYn] = useState(''); // 처리여부

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "입고일",
                width: "180px",
                item: 
                    <>
                    <ul className="ul-row">
                        <li>
                            <DayDateBox 
                                value={startDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setStartDy(e.value);
                                }}                    
                            />
                        </li>
                        <li>
                            ~
                        </li>
                        <li>            
                            <DayDateBox 
                                value={endDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setEndDy(e.value);
                                }}                    
                            />
                        </li>
                    </ul>
                    </>
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",
                width: "200px",
                item: 
                <>
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />     
                </>           
            },            
            {   title: "처리여부",
                item: 
                <SelectBox 
                    width={'120px'}
                    value={treatYn}      
                    displayExpr={'name'}
                    valueExpr={'value'}                      
                    items={[ 
                        { name: "처리완료", value: "Y" }, 
                        { name: "미처리", value: "N" } 
                    ]}
                    placeholder="전체"
                    showClearButton={true}
                    onValueChanged={(e)=>{
                        setTreatYn(e.value);
                    }}
                />   
            } 
        ]
    ];
    /* #endregion */

    // * Grid
    const [procInspBadHisListIn, setProcInspBadHisListIn] = useState([]);
    const [procInspBadHisListInTot, setProcInspBadHisListInTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, treatYn]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodId: prodId,
                prodNm: prodNm,
                treatYn: treatYn,
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),

                offset: rowsOffset,
                limit: rowsLength,
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
            }
        }

        const r = await cmn.api("getProcInspBadHisListIn", ps );
        if(r === undefined || r == null) return;

        setProcInspBadHisListIn(r.d0)
        setProcInspBadHisListInTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickBadTreat = (e) => {
        if(!util.chkListSelected(selectedItem, "불량처리 대상을")) return;
        openCrudPopup("a", selectedItem.procInspHisUid);
    }

    const handleClickBadImpr = (e) => {
        if(!util.chkListSelected(selectedItem, "부적합 개선 대상을")) return;
        if(util.getChkNull(selectedItem.procInspBadTreatUid) === ''){ 
            alert("불량처리를 먼저 수행 하십시오.");
            return;
        }

        if(util.getChkNull(selectedItem.badImprUid) !== ''){
            openCrudPopupBadImpr("u", selectedItem.badImprUid, selectedItem.procInspHisUid);
        }
        else {
            openCrudPopupBadImpr("a", selectedItem.badImprUid, selectedItem.procInspHisUid);
        }
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "inDy" 
            ||  gc.column.dataField === "inspDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
        else if(gc.column.dataField === "inAmt" 
            ||  gc.column.dataField === "goodAmt" 
            ||  gc.column.dataField === "badAmt" 
            ) e.value =util.getNumComma(gc.value);
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, uid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/qual/ininspbadpop?${qs.stringify({mode: mode, uid: uid})}`;        
        util.openWindow(url, 800, 680);    
    }

    const openCrudPopupBadImpr = (mode, uid, pihuid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/qual/badimprpop?${qs.stringify({mode: mode, uid: uid, pihuid: pihuid})}`;        
        util.openWindow(url, 1180, 720);    
    }

    /* ====================
     *  Render
     * ==================== */

    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickBadTreat}
                    title="불량처리"
                    type="2"
                    show={true}
                />
                <ActionButton 
                    width={120}
                    onClick={handleClickBadImpr}
                    title="부적합 개선"
                    type="2"
                    show={true}
                />
            </ListTopView>
             }</Observer> 
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="수입검사 불량내역"
            >
               <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>
            <Observer>{() =>
            <DataGridView
                reff={grid1}
                dataSource={procInspBadHisListIn}
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[cmn.CW.dy, cmn.CW.dy, 
                    cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd,
                    cmn.CW.lot, cmn.CW.bizNm, cmn.CW.procNm,
                    cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.unit,
                    cmn.CW.yn, cmn.CW.file, cmn.CW.userNm, cmn.CW.userNm, null
                ]}
                // paging
                page={curPage}
                total={procInspBadHisListInTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}
                // excel
                exlnm={'수입검사 불량내역'}
                customizeExcelCell={customizeExcelCell}                   
            >
                { cmn.getGridColumnDayType('inDy', '입고일자', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('inspDy', '검사일자', renderGridHeaderCell) }
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell ) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell ) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell ) }
                { cmn.getGridColumn('lot', 'Lot', renderGridHeaderCell ) }
                { cmn.getGridColumn('bizNm', '입고업체', renderGridHeaderCell ) }
                { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell ) }          
                { cmn.getGridColumnNumCommaType('inAmt', '입고수량', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('goodAmt', '양품수량', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('badAmt', '불량수량', renderGridHeaderCell) }
                { cmn.getGridColumn('unitTpNm', '단위', renderGridHeaderCell ) }      
                { cmn.getGridColumn('badTreatTpNm', '처리결과', renderGridHeaderCell) }
                { cmn.getGridColumn('badImprCd', '부적합개선코드') }
                { cmn.getGridColumnUserNm('inUserNm', '입고자') }
                { cmn.getGridColumnUserNm('inspUserNm', '검수자') }
                <Column />
            </DataGridView>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default InInspBadStaPage;
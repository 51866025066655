import React, { useState, useEffect, useContext, useRef } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    InspSampBox, DayDateBox, DateTimeBox, FileUploadBox,
    CodeSelectBox, ChkCycleChkListBox, UseYnSwitchBox, 
    ProdSearchBox, ProdProcSelectBox,
    PopupLayout, ActionButton, SubActionButton, GridTopView, EditGridView 
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';
import { Column } from 'devextreme-react/data-grid';

/** 
 *  금형 등록 / 수정
 */
const MoldPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [moldCd, setMoldCd] = useState('');
    const [moldNm, setMoldNm] = useState('');
    const [moldStnd, setMoldStnd] = useState('');
    const [moldTp, setMoldTp] = useState('');
    const [moldWght, setMoldWght] = useState(null);
    const [saveLoca, setSaveLoca] = useState('');
    const [modelNm, setModelNm] = useState('');
    const [grade, setGrade] = useState('');
    const [maker, setMaker] = useState('');
    const [makeDy, setMakeDy] = useState('');
    const [makeCost, setMakeCost] = useState(0);
    const [limitShot, setLimitShot] = useState(0);
    const [mngShot, setMngShot] = useState(0);
    const [startShot, setStartShot] = useState(0);
    const [totalShot, setTotalShot] = useState(0);

    const [useYn, setUseYn] = useState('Y');
    const [cmnt, setCmnt] = useState('');

    const [moldTpNm, setMoldTpNm] = useState('');
   
    const [moldImgUid, setMoldImgUid] = useState(util.getUUID());    
    const [moldImgInfo, setMoldImgInfo] = useState('');
    const [moldImgFiles, setMoldImgFiles] = useState([]);

    const [drawFileUid, setDrawFileUid] = useState(util.getUUID());
    const [drawFiles, setDrawFiles] = useState([]);

    const [prodProcs, setProdProcs] = useState([]);
    const [prods, setProds] = useState([]);  

    const prodProcGrid = useRef(null); 
    const prodProcSelect = useRef(new Array(10).fill(React.createRef()));

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {            
            if(mode == 'a') return;
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getMold", {p: {moldUid: uid}} );
            if(r == undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;

            setMoldCd(d0.moldCd);
            setMoldNm(d0.moldNm);
            setMoldStnd(d0.moldStnd);
            setMoldTp(d0.moldTp);
            setMoldWght(d0.moldWght);
            setSaveLoca(d0.saveLoca);
            setModelNm(d0.modelNm);
            setGrade(d0.grade);
            setMaker(d0.maker);
            setMakeDy(d0.makeDy);
            setMakeCost(d0.makeCost);
            setLimitShot(d0.limitShot);
            setMngShot(d0.mngShot);
            setStartShot(d0.startShot);
            setTotalShot(d0.totalShot);
            setMoldImgUid(d0.moldImgUid == null ? util.getUUID() : d0.moldImgUid );
            setDrawFileUid(d0.drawFileUid == null ? util.getUUID() : d0.drawFileUid );
            setUseYn(d0.useYn);
            setCmnt(d0.cmnt);

            setProdProcs(d1);

            setMoldImgFiles(r.f1);
            setDrawFiles(r.f2);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        // console.log("=== prodProcs", prodProcs);
        // console.log("&&& prodProcGrid", prodProcGrid.current.instance);
        // return;

        try {
            if(!util.chkMustValue(moldCd, '금형코드')) return;
            if(!util.chkMustValue(moldNm, '금형명')) return;

            if(mode == "a") {
                if(!util.confirmYn('등록')) return;
                addMold();
            }
            else if(mode == "u") { 
                if(!util.confirmYn('수정')) return;
                upMold();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickDel = (e) => {
        try {
            if(!util.confirmYn('삭제')) return;
            delMold();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const handleClickAddProdPorc = (e) => {
        prodProcGrid.current.instance.addRow();
    }

    const addMold = async() => {
        
        const prodProcs_ = [...prodProcs];
        _.forEach(prodProcs_, (v, k) => {
            v.prodProcUid = _.split(v.prodProcInfo, '^')[0];
        });

        const ps = {
            p: 
                {
                    moldUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    moldCd: moldCd,
                    moldNm: moldNm,
                    moldStnd: moldStnd,
                    moldTp: moldTp,
                    moldWght: moldWght,
                    saveLoca: saveLoca,
                    modelNm: modelNm,
                    grade: grade,
                    maker: maker,
                    makeDy: util.getDateToString('y', makeDy),
                    makeCost: makeCost,
                    limitShot: limitShot,
                    mngShot: mngShot,
                    startShot: startShot,
                    moldImgUid: moldImgUid,
                    moldImgFiles: moldImgFiles,
                    drawFileUid: drawFileUid,
                    drawFiles: drawFiles,
                    useYn: useYn,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                    prodProcs: prodProcs_
                }
        }

        const r = await cmn.api("addMold", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upMold = async() => {
        const prodProcs_ = [...prodProcs];
        _.forEach(prodProcs_, (v, k) => {
            v.prodProcUid = _.split(v.prodProcInfo, '^')[0];
        });

        const ps = {
            p: 
                {
                    moldUid: uid,
                    comUid: authStore.com.uid,
                    moldCd: moldCd,
                    moldNm: moldNm,
                    moldStnd: moldStnd,
                    moldTp: moldTp,
                    moldWght: moldWght,
                    saveLoca: saveLoca,
                    modelNm: modelNm,
                    grade: grade,
                    maker: maker,
                    makeDy: util.getDateToString('y', makeDy),
                    makeCost: makeCost,
                    limitShot: limitShot,
                    mngShot: mngShot,
                    startShot: startShot,
                    moldImgUid: moldImgUid,
                    moldImgFiles: moldImgFiles,
                    drawFileUid: drawFileUid,
                    drawFiles: drawFiles,
                    useYn: useYn,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                    prodProcs: prodProcs_
                }
            }

        const r = await cmn.api("upMold", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delMold = async() => {

        const ps = {
            p: 
                {
                    moldUid: uid,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("delMold", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("삭제완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode == "a" ? "등록완료" 
            : mode == "u" ? "수정완료"
            : "" ;
          
        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    show={true}
                />
                <ActionButton 
                    title={"삭제완료"}
                    onClick={handleClickDel}
                    marginRight={0}
                    show={mode == "u" ? true : false}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={120} />
                        <col className="tcon" width={460} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">금형코드</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                    <TextBox                                         
                                        value={moldCd}
                                        showClearButton={true}
                                        placeholder="금형코드를 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMoldCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{moldCd}</span>
                            }
                            </td>
                            <td className="tcon" rowSpan={8}>
                                <div className="div-img-100" style={{minHeight: 336}}>
                                { 
                                    moldImgInfo != '' ?                                                
                                    cmn.getRenderImg(moldImgInfo, 428) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">금형명</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                    <TextBox                                         
                                        value={moldNm}
                                        showClearButton={true}
                                        placeholder="금형명을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMoldNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{moldNm}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">규격</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                    <TextBox 
                                        value={moldStnd}
                                        showClearButton={true}
                                        placeholder="규격을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMoldStnd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{moldStnd}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">금형유형</th>
                            <td className="tcon">
                            {
                                mode == "a" || mode == "u" ?
                                    <CodeSelectBox
                                        value={moldTp}
                                        classId={'407'}
                                        placeholder="금형유형을 선택하세요."
                                        onError={(err) => {
                                            cmnStore.handleErrorPage(err, props);
                                        }}
                                        onValueChanged={(v, o) => { 
                                            setMoldTp(v);
                                        }}
                                    />                                    
                                    :
                                    <span>{moldTpNm}</span>
                            }
                            </td>
                            <th className="tit">금형중량</th>
                            <td className="tcon">
                            {
                                mode == "a" || mode == "u" ?
                                    <ul className="ul-row">
                                        <li>
                                            <NumberBox
                                                width={120}
                                                format={'#,##0'}
                                                value={moldWght}
                                                defaultValue={0}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setMoldWght(e.value);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                          <span>(KG)</span>      
                                        </li>
                                    </ul>
                                :
                                    <span>{`${moldWght} (KG)`}</span>
                            }
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit">보관위치</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                    <TextBox 
                                        value={saveLoca}
                                        showClearButton={true}
                                        placeholder="보관위치을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setSaveLoca(e.value);
                                        }} 
                                    />
                                :
                                    <span>{saveLoca}</span>
                            }
                            </td>
                        </tr>     
                        <tr>
                            <th className="tit">모델명</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                    <TextBox 
                                        value={modelNm}
                                        showClearButton={true}
                                        placeholder="모델명을 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setModelNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{modelNm}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제작처</th>
                            <td className="tcon"colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                    <TextBox 
                                        value={maker}
                                        showClearButton={true}
                                        placeholder="제작처를 입력하세요."
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMaker(e.value);
                                        }} 
                                    />
                                :
                                    <span>{maker}</span>
                            }
                            </td>
                        </tr>            
                        <tr>
                            <th className="tit">제작일자</th>
                            <td className="tcon">
                            {
                                mode == "a" || "u" ?
                                <DayDateBox 
                                    value={makeDy}
                                    type={'day'}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setMakeDy(e.value);
                                    }}                    
                                />
                                :
                                    <span>{makeDy}</span>
                            }
                            </td>
                            <th className="tit">제작금액</th>
                            <td className="tcon">
                            {
                                mode == "a" || mode == "u" ?
                                    <ul className="ul-row">
                                        <li>
                                            <NumberBox
                                                width={120}
                                                format={'#,##0'}
                                                value={makeCost}
                                                defaultValue={0}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setMakeCost(e.value);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                          <span>(원)</span>      
                                        </li>
                                    </ul>
                                :
                                    <span>{`${makeCost} (원)`}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">등급</th>
                            <td className="tcon">
                                {
                                mode == "a" || mode == "u" ?
                                    <TextBox 
                                        value={grade}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setGrade(e.value);
                                        }} 
                                    />
                                :
                                    <span>{grade}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon">
                                {
                                mode == "a" || mode == "u" ?
                                <UseYnSwitchBox 
                                    value={useYn}
                                    onValueChanged={(v)=>{
                                        setUseYn(v);
                                    }} 
                                />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                            <td className="tcon" style={{verticalAlign: "top"}} rowSpan={2}>
                                <FileUploadBox
                                    limit={1}
                                    // width={100}
                                    linkWidth={200}
                                    grpUid={moldImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={moldImgFiles}
                                    labelText=''
                                    onLoadInfo={(e) => {
                                        const fi = cmn.getUploadFileInfoStr(e);
                                        setMoldImgInfo(fi);
                                    }}
                                    onUploaded={(e) => { 
                                        setMoldImgFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setMoldImgFiles(e);
                                    }}
                                /> 
                            </td> 
                        </tr>  
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">타발수</th>
                            <td className="tcon" colSpan={4}>
                            {
                                (mode == "a" || mode == "u") && 
                                <ul className="ul-row">
                                    <li>
                                        보증: &nbsp;&nbsp;
                                        <NumberBox
                                            width={100}
                                            format={'#,##0'}
                                            value={limitShot}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setLimitShot(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        &nbsp;&nbsp;관리: &nbsp;&nbsp;
                                        <NumberBox
                                            width={100}
                                            format={'#,##0'}
                                            value={mngShot}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setMngShot(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        &nbsp;&nbsp;시작: &nbsp;&nbsp;
                                        <NumberBox
                                            width={100}
                                            format={'#,##0'}
                                            value={startShot}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setStartShot(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        &nbsp;&nbsp;누적: &nbsp;&nbsp;
                                        {mode == 'a' ? util.getNumComma(startShot) : util.getNumComma(totalShot)}
                                    </li>
                                </ul>                                
                            }
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit">도면파일</th>
                            <td className="tcon" colSpan={4}>
                                <FileUploadBox
                                    grpUid={drawFileUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={drawFiles}
                                    onUploaded={(e) => {
                                        setDrawFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setDrawFiles(e);
                                    }}
                                />
                            </td>
                        </tr>   
                    </tbody>
                </table>
            </div>
            <GridTopView title="제품공정">   
                <ul className="ul-row">
                    <li
                        style={{
                            paddingRight: 10
                        }}
                    >
                        <SubActionButton 
                            onClick={handleClickAddProdPorc}
                            title="추가"
                            show={true}
                        />    
                    </li>
                </ul>                     
            </GridTopView>
            <EditGridView         
                reff={prodProcGrid}   
                dataSource={prodProcs}   // store
                // gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[
                    260, 220, 180, null                         
                ]}
                pagingable={false}
                editMode={'row'}     
                allowDeleting={true}
                allowAdding={true}     
                
                columnResizingMode={'nextColumn'}
                // selection='multiple' 
                // onSelectionChanged={handleGridSelectionChanged}            
            >
                {/* { cmn.getEditGridColumn('prodNm', '제품') }         */}
                <Column
                    alignment={'center'}
                    dataField={'prodInfo'}
                    caption={'제품'}  
                    cellRender={(c) => {
                        const prodNm_ = c.value != '' ? _.split(c.value, '^')[1] : '';
                        return (
                            <span>{prodNm_}</span>
                        )
                    }}
                    editCellRender={(c) => {
                        const value_ = _.split(c.value, '^')[0];

                        // if((c.rowIndex+1) > prods.length){
                        //     prods_.push(null);
                        // }
                        // else {
                        //     prods_[c.rowIndex] = v;
                        // } 

                        return (
                            <div>
                                <ProdSearchBox
                                    value={value_}
                                    popCenter={true}
                                    onLoadInfo={(o) => {
                                        // console.log("*** onLoadInfo o", o);
                                        const prods_ = [...prods];
                                        if((c.rowIndex+1) > prods.length){
                                            prods_.push(o.prodUid);
                                        }
                                        else {
                                            prods_[c.rowIndex] = o.prodUid;
                                        }

                                        setProds(prods_);    
                                        // console.log("&&& prodProcSelect", prodProcSelect);
                                        // prodProcSelect.current[c.rowIndex].current.instance.reset();
                                    }} 
                                    onValueChanged={(v, o) => {  
                                        if(o != undefined && o != null) {   
                                            c.setValue(`${v}^${o.prodNm}`);
                                        }
                                        else {
                                            c.setValue('');                                        
                                        }

                                        prodProcSelect.current[c.rowIndex].current.instance.reset();
                                    }}
                                />
                            </div>
                        )                    
                    }}
                />   

                <Column
                    alignment={'center'}
                    dataField={'prodProcInfo'}
                    caption={'공정'}  
                    cellRender={(c) => {
                        const procNm_ = c.value != '' ? _.split(c.value, '^')[1] : '';
                        return (
                            <span>{procNm_}</span>
                        )
                    }}
                    editCellRender={(c) => {
                        // console.log("&&& 공정 c", c);
                        // console.log("&&& prods ", prods);
                        const value_ = _.split(c.value, '^')[0];

                        return (
                            <div>
                                <ProdProcSelectBox
                                    reff={prodProcSelect.current[c.rowIndex]}
                                    width={200}
                                    defaultValue={value_}
                                    prodUid={prods[c.rowIndex]}
                                    onLoadInfo={(o) => {
                                        // console.log("&&& ProdProcSelectBox onLoadInfo", o);
                                    }} 
                                    onValueChanged={(v, o) => { 
                                        // console.log("ProdProcSelectBox o", o);
                                        // setEqupUid('');
                                        // setWorkNum('');
                                        // setProdProcUid(v);
                                        if(o != undefined && o != null) {                                            
                                            c.setValue(`${v}^${o.procNm}`);                                        
                                        }
                                        else {
                                            c.setValue('');                                        
                                        }
                                        
                                        // c.setValue(v);
                                    }}
                                />
                            </div>
                        )                    
                    }}
                />   
    
                { cmn.getEditGridColumnNum('cavity', '캐비티') }
    
                <Column />
            </EditGridView>
        
            </>
        )   
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "금형 등록" 
                : mode == "u" ? "금형 수정"
                : "금형" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default MoldPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';
import moment from 'moment';

/** components */
import {
    CancelButton,LotSelectBox, ProdProcSelectBox, UserSelectBox, DayDateBox, PopupLayout, ActionButton, SubActionButton, EqupSelectBox, BadAmtBox, CodeSelectBox,
    UserSearchBox, DateTimeBox, BizSelectBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';
import ManuTimeDayPage from './ManuTimeDayPage';

/** images */

/** 
 *  생산실적 CRUD 팝업
 */
const ManuRetPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
        wn = ls.wn,
        lt = ls.lt,
        ppuid = ls.ppuid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    //Combo
    const [prodProcUid, setProdProcUid] = useState(util.getChkNull(ppuid, ''));
    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [unitTp, setUnitTp] = useState('');
    const [lastYn, setLastYn] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    
    const [unitTpNm, setUnitTpNm] = useState('');
    const [procNm, setProcNm] = useState('');
    const [procPrice, setProcPrice] = useState(0);
    const [equpUid, setEqupUid] = useState('');
    const [equpNm, setEqupNm] = useState('');
    const [manuDy, setManuDy] = useState(util.getNow());
    const [manuDt, setManuDt] = useState(util.getNow('m'));
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState('');
    const [goodAmt, setGoodAmt] = useState(0);
    const [manuAmt, setManuAmt] = useState(0);
    const [unitWght, setUnitWght] = useState(0);
    const [lot, setLot] = useState(util.getChkNull(lt, ''));
    const [workNum, setWorkNum] = useState(util.getChkNull(wn, ''));
    const [cmnt, setCmnt] = useState('');
    const [possAmt, setPossAmt] = useState(0);
    const [badAmts, setBadAmts] = useState([]);
    const [isDel, setIsDel] = useState('N');
    const [lineUid, setLineUid] = useState('');
    const [lineNm, setLineNm] = useState('');
    const [preLot, setPreLot] = useState('');
    
    const [subUsers, setSubUsers] = useState([]);

    const [dyStartTm, setDyStartTm] = useState('');
    const [dyEndTm, setDyEndTm] = useState('');
    const [lotTp, setLotTp] = useState('71002');
    const [dyNtUseYn, setDyNtUseYn] = useState('Y');

    const [dyNtTp, setDyNtTp] = useState('80101');
    const [dyChar, setDyChar] = useState('');
    const [ntChar, setNtChar] = useState('');

    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    
  
    
    //취소
    // const [delCmnt, setDelCmnt] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadProdProcAmtInfo();
    },[prodProcUid, lot]);

    useEffect(() => {
        !pageLoad && loadManuAmt();
    },[badAmts, goodAmt]);

    useEffect(() => {
        !pageLoad && loadWorkNum();
    },[manuDt]);
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);

            if(mode === "a"){
                await loadWorkNum();
                await loadProdProcAmtInfo();
            }
            
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        if(util.getChkNull(mode) === 'a') return;

        try {
            // 정보조회
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api('getManu', {p: {manuUid: uid}} );
            if(r == null || r === undefined) return;

            const data = r.d0[0];

            setProdUid(data.prodUid);
            setProdProcUid(data.prodProcUid);
            setEqupUid(data.equpUid);
            setManuDy(util.getDateStrSplit('d', data.manuDy));
            setManuDt(data.manuDt);
            setLot(data.lot);
            setWorkNum(data.workNum);
            setGoodAmt(data.goodAmt);
            setUserUid(data.userUid);
            setUnitWght(data.unitWght);
            setUnitTp(data.unitTp);
            setManuAmt(data.manuAmt);
            setCmnt(data.cmnt);
            setLastYn(data.lastYn);
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setProdStnd(data.prodStnd);
            setProcNm(data.procNm);
            setUnitTpNm(data.unitTpNm);
            setEqupNm(data.equpNm);
            setLineNm(data.lineNm);
            setUserNm(data.userNm);
            setIsDel(data.isDel);
            setBizUid(data.bizUid);
            setBizNm(data.bizNm);

            let badAmts_ = [];
            _.forEach(r.d1, (v,k) => {
                badAmts_.push({
                    uid: v.badUid, 
                    badTp: v.badTp, 
                    badTpNm: v.badTpNm,
                    badAmt: v.badAmt
                });
            });          
            setBadAmts(badAmts_);

            let subUsers_ = [];
            _.forEach(r.d2, (v,k) => {
                subUsers_.push({
                    uid: v.manuSubUserUid, 
                    userUid: v.userUid,
                    userNm: v.userNm
                });
            });        

            console.log('subUsers_ ', subUsers_);

            setSubUsers(subUsers_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadWorkNum = async() => {
        try {
            if(util.getChkNull(workNum) === '') return;
            
            let para = {p: {workNum: workNum}};

            let r = await cmn.api('getWorkNum', para);
            if(r == null || r.d0.length == 0) {
                util.cmnAlert('등록된 정보가 없습니다.');
                return;
            }

            let d0 = r.d0[0];

            setProdUid(d0.prodUid);
            setProdId(d0.prodId);
            setProdNm(d0.prodNm);
            setProdStnd(d0.prodStnd);
            setUnitTp(d0.unitTp);
            setUnitTpNm(d0.unitTpNm);
            setEqupUid(d0.equpUid);
            setLineUid(d0.lineUid);

            setLotTp(d0.lotTp);
            setDyStartTm(dyStartTm_);
            setDyEndTm(dyEndTm_);
            setDyNtUseYn(d0.dyNtUseYn);

            let manuDy_ = util.getDateToString('d', manuDt, '-');
            let format_ = 'YYYY-MM-DD HH:mm';
            let dyStartTm_ = moment(`${manuDy_} ${util.getDateStrSplit('t' , d0.dyStartTm, ':')}`).format(format_);
            let dyEndTm_ = moment(`${manuDy_} ${util.getDateStrSplit('t' , d0.dyEndTm, ':')}`).format(format_);
            
            //예정 신규 Lot를 생성해놓음.
            let preLot = cmn.getCrtDyLotNum(workNum, d0.lotTp, d0.dyNtUseYn, dyStartTm_, dyEndTm_, d0.dyChar, d0.ntChar, manuDt)
            setPreLot(preLot);

            //넘어오는 Lot 가 없을 경우 WorkNum 조회시 조회된 Lot를 적용
            if(util.getChkNull(lt) === '')
            {
                setLot(d0.lot);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // const loadLot = () =>{
    //     let lot_ = `${workNum}-${util.getDateToString('f', manuDy, '', 'YYMMDD')}-${dyNtTp==='80101' ? dyChar:ntChar}`;
    //     setLot(lot_);
    // }
    
    // 제품공정의 수량를 조회
    const loadProdProcAmtInfo = async() => {
        try {
            if(util.getChkNull(prodProcUid) === "")  return;
            if(util.getChkNull(workNum) === "")  return;

            const ps = {
                p: {
                    prodProcUid: prodProcUid,
                    workNum: workNum,
                    lot: lot
                }
            }

            const r = await cmn.api('getProdProcAmt', ps);
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            setPossAmt(data.manuAmt);
          //  setManuAmt(data.manuAmt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadManuAmt = () => {
        setManuAmt(goodAmt + _.sumBy(badAmts, 'badAmt'));
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(workNum, '작업지시'))
                return;
            if(!util.chkMustValue(userUid, '작업자'))
                return;
            if(!util.chkMustValue(prodProcUid, '공정'))
                return;
            if(!util.chkMustValue(manuAmt, '생산량', 'cnt'))
                return;

                   
            if(mode === "a") 
            {
                //생산가능수량은 양품량 + 불량품량보다 작아야함.
                let totBadAmts = _.sumBy(badAmts, 'badAmt');
                if(possAmt < goodAmt + totBadAmts)
                {
                    util.cmnAlert('[양품량]과 [불량품량]의 합이 [생산가능수량]보다 큽니다.');
                    return;
                }

                if(util.confirmYn('실적등록'))
                    addManu();
            }
            else if(mode === "v") 
            {
                if(util.confirmYn('실적취소'))
                    delManu(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const addManu = async() => {
        const lotPara = {p:{comUid: authStore.com.uid,
                              workNum: workNum,
                              lot: util.getChkNull(lot) === '' ? preLot : lot}};


        let manuStndDy_ = cmn.getCrtDy(lotTp, dyNtUseYn, dyStartTm, dyEndTm, manuDt);

        const ps = {
            p: {
                manuUid: util.getUUID(),
                comUid: authStore.com.uid,
                prodUid: prodUid,
                prodProcUid: prodProcUid,
                equpUid: equpUid,
                lot: await cmnStore.getLotCrt(lotPara),
                workNum: workNum,
                manuDy: util.getDateToString('d',manuDy),
                manuStndDy: manuStndDy_,
                manuDt: manuDt,
                userUid: userUid,
                manuAmt: manuAmt,
                goodAmt: goodAmt,
                unitWght: unitWght,
                cmnt: cmnt,
                lastYn: lastYn,
                procPrice: procPrice,
                uptUserUid: authStore.user.uid,
                subUsers: subUsers,
                badAmts: badAmts,
                bizUid: bizUid
            }
        }

        //console.log(ps);
        const r = await cmn.api("addManu", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("실적등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delManu = async(delCmnt) => {
        const ps = {
            p: {
                manuUid: uid,
                comUid: authStore.com.uid,
                delCmnt: delCmnt,
                prodUid: prodUid,
                prodProcUid: prodProcUid,
                lot: lot,
                manuAmt: manuAmt,
                goodAmt: goodAmt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api("delManu", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("실적취소");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "취소완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
            {mode === "v" ?
                <CancelButton
                    title={completeButtonTitle}
                    isDel={isDel}
                    isDelMsg={'공정이 진행중이므로 취소할 수 없습니다. \n진행된 공정을 취소한 후 취소 가능합니다.'}
                    handleClickComplete={(e) => {
                        handleClickComplete(e)
                    }} 
                />
                :
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">작업번호</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <>
                                    <div>
                                        <ul className="ul-row" style={{width: '100%'}}>
                                            <li style={{width: 'calc(100% - 80px)'}}>
                                            <TextBox
                                                width={'100%'}         
                                                value={workNum}
                                                showClearButton={true}
                                                placeholder={'작업번호로 검색하세요.'}
                                                valueChangeEvent={'keyup'}
                                                onValueChanged={(e)=>{
                                                    setWorkNum(e.value);
                                                }} 
                                                onEnterKey={(e)=>{
                                                    loadWorkNum();
                                                }} 
                                            />   
                                            </li>
                                            <li className="fl-r" style={{width: '80px'}}>                        
                                                <SubActionButton 
                                                    title="검색"
                                                    type="1"
                                                    marginRight={0}
                                                    show={true}
                                                    onClick={(e)=>{
                                                        loadWorkNum();
                                                    }} 
                                                />
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    </>
                                :
                                <span>{workNum}</span>
                                }
                            </td>
                            <th className="tit">Lot</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <LotSelectBox
                                        value={lot}
                                        preValue={preLot}
                                        workNum={workNum}
                                        placeholder={'신규 Lot 발급'}
                                        onValueChanged={(v) => { 
                                            setLot(v); 
                                        }}
                                    />
                                    :
                                    <span>{lot}</span>
                                }
                            </td>
                            
                        </tr>
                        <tr>
                            <th className="tit">작업일</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                // <>
                                //     <div>
                                //         <ul className="ul-row">
                                //             <li>
                                //                 <DayDateBox
                                //                     value={manuDy}
                                //                     onValueChanged={(e) => {
                                //                         setManuDy(e.value);
                                //                     }}                    
                                //                 />
                                                
                                //             </li>
                                //             <li>
                                //                 <CodeSelectBox
                                //                     value={dyNtTp}
                                //                     classId={'801'}
                                //                     placeholder="주/야"
                                //                     showClearButton={false}
                                //                     onValueChanged={(v) => { 
                                //                         setDyNtTp(v);
                                //                     }}
                                //                 />
                                //             </li>
                                //         </ul>
                                //     </div>
                                // </>
                                    <DateTimeBox
                                        value={manuDt}                                    
                                        onValueChanged={(dt) => { 
                                            setManuDy(util.getDateToString('d',dt));
                                            setManuDt(dt);
                                        }}
                                    />
                                    : <span>{manuDy}</span>
                                }
                            </td>
                            <th className="tit req">작업자</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                <UserSelectBox
                                    mode={mode}
                                    value={userUid}
                                    placeholder="작업자를 선택하세요"
                                    onValueChanged={(v) => { 
                                        setUserUid(v);
                                    }}
                                />
                                :
                                <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">보조작업자</th>
                            <td className="tcon">
                                <UserSearchBox
                                    value={subUsers}
                                    popCenter={true}
                                    mode={mode}
                                    schCnt={2}
                                    onValueChanged={(e) => {
                                        setSubUsers(e);
                                    }}
                                    
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                            
                        </tr>
                        <tr>
                            <th className="tit req">공정</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <ProdProcSelectBox
                                        value={prodProcUid}
                                        inYn={'Y'} // 사내공정 데이터만 조회
                                        procTp={'10401'} // 구매 공정은 제외하고 조회
                                        prodUid={prodUid}
                                        onLoadInfo={(o) => {
                                            setLastYn(util.getVal(o, 'lastYn'));
                                            setProcPrice(util.getVal(o, 'procPrice'));
                                        }} 
                                        onValueChanged={(v) => { 
                                            setProdProcUid(v);
                                        }}
                                    />
                                    :
                                    <span>{procNm}</span>
                                }
                            </td>
                            <th className="tit">단위</th>
                            <td className="tcon">
                                <span>{unitTpNm}</span>
                            </td>
                            
                        </tr>
                        <tr>
                            <th className="tit req">생산량<br />{mode === 'a' ?  '/생산가능수량' : ''}</th>
                            <td className="tcon">
                                {/*                                 
                                // <NumberBox
                                //     width={80}
                                //     format={'#,##0.###'}
                                //     value={manuAmt}
                                //     defaultValue={0}
                                //     min={0}
                                //     max={possAmt}
                                //     showSpinButtons={true}
                                //     onValueChanged={(e)=>{
                                //         setManuAmt(e.value);
                                //     }}
                                // /> */}
                                <span>{manuAmt} {mode === "a" ? `/ ${possAmt}` : ''}</span>
                            </td>
                            <th className="tit">양품량</th>
                            <td className="tcon" >
                                {
                                mode === "a" ?
                                <NumberBox
                                    width={80}
                                    format={'#,##0.###'}
                                    value={goodAmt}
                                    defaultValue={0}
                                    min={0}
                                    max={possAmt}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setGoodAmt(e.value);
                                        
                                    }}
                                />
                                :
                                    <span>{goodAmt}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                        <th className="tit">업체</th>
                            <td className="tcon" colSpan={3}>
                                {
                                    mode === "a" ?
                                    <BizSelectBox
                                        width={'200px'}
                                        value={bizUid}
                                        isDefaultVal={true}
                                        showClearButton={false}
                                        onInit={(i) => {
                                            setBizUid(i.bizUid);
                                        }}
                                        onValueChanged={(v) => {
                                            setBizUid(v);
                                        }}
                                    />  
                                    :
                                    <span>{bizNm}</span>
                                }
                                
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">설비</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                    <EqupSelectBox
                                        width="200px"
                                        lineYn={authStore.com.isLine ? 'Y' : 'N'}
                                        lineValue={lineUid}
                                        value={equpUid}
                                        mode={mode}
                                        onLoadInfo={(o) => {}} 
                                        onValueChanged={(v) => {
                                            setEqupUid(v);
                                        }}
                                    />   
                                    :
                                    // <span>{`${lineNm}${lineNm === '' ? ' / ' : ''}${equpNm}`}</span>
                                    <span>{lineNm} {lineNm === '' ? '' : ' / '} {equpNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">불량</th>
                            <td className="tcon"colSpan={3}>
                                <BadAmtBox
                                    mode={mode}
                                    badAmts={badAmts}
                                    unitTp={unitTp}
                                    unitTpNm={unitTpNm}
                                    onClickAdd={(badAmts) => { setBadAmts(badAmts); }}
                                    onClickDel={(badAmts) => { setBadAmts(badAmts); }}
                                />
                            </td>  
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "생산실적 등록" 
                : mode === "v" ? "생산실적 상세보기"
                : "생산실적" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ManuRetPopPage;

import React, { useState, useEffect, useContext, useRef } from 'react';
import DataGrid, { Column, Editing, Paging, Pager, Selection, Sorting, Export, GroupPanel, LoadPanel } from 'devextreme-react/data-grid';
import useMobileDetect from 'use-mobile-detect-hook'
import _ from 'lodash';

/** components */
import {
    DataGridPagerView
} from '../';

/** stores & lib */


/** 
 *  Data Grid Pager
 */
const DataGridView = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // ...

    /* ====================
     *  Object
     * ==================== */

    const detectMobile = useMobileDetect();

    const {
        id,
        reff,
        showNo=true,

        exlnm,

        dataSource,
        gridHeight=0,

        selection='single', // single, multiple

        // sort
        onCellClick,

        // Paging
        pagingable=true,
        page=1,
        total= 0,
        itemsPerPage= 0,
        //itemsPerPage= -1,
        pagesPerBlock= detectMobile.isMobile() ? 5 : 10,
        onChangePageSize,
        onClickPage,
        multiply = 1,

        // 
        editable=false,
        showLoading=false,

        // select
        selectedRowKeys,    // multiple 인 경우만 해당
        onSelectionChanged,
        onInitPage,

        // columnWidth=[]
        colsWidth=[],
        columnResizingMode='widget',

        // excel
        customizeExcelCell
                
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
        if(loaded) return;        
        setLoaded(true);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const customizeColumns = (c) => {
        // if(loaded) return;        
        if(showNo) c[0].width = 60;
        _.forEach(colsWidth, (v,k) => {                            
            if(v == null) return;
            const co = c[showNo ? k + 1 : k];
            if(co == null || co == undefined) return;
            // const v_ = v === null ? null : detectMobile.isMobile() ? (v / 2) : v;
            co.width = v;          
        })
    }

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {

        // console.log("%%%%% renderContent DataGridView");

        if(pagingable){
            const totolPages = _.floor(total / itemsPerPage) + ((total % itemsPerPage) > 0 ? 1 : 0);
            // console.log("### totolPages", totolPages);
            page > totolPages && onInitPage && onInitPage(1, 0);
        }

        return (            
            <>
            <div className="data-grid-1">
                <DataGrid 
                    id={id}
                    ref={reff}
                    // width={'100%'}                
                    
                    height={(gridHeight && !detectMobile.isMobile()) > 0 ? gridHeight : null}
                    dataSource={dataSource}
                    selection={{ mode: selection }}                    
                    showBorders={true}

                    allowColumnResizing={detectMobile.isMobile() == true ? false : true}
                    columnResizingMode={columnResizingMode}   // widget, nextColumn
                    // columnAutoWidth={true}
                    showRowLines={true}

                    onCellClick={onCellClick}
                    onSelectionChanged={onSelectionChanged}

                    // multiple select
                    selectedRowKeys={selectedRowKeys}

                    


                    
                    // adaptablility
                    columnHidingEnabled={detectMobile.isMobile() == true ? true : false}

                    // columns width
                    customizeColumns={!loaded ? customizeColumns : null}
                >
                    <Export enabled={true} fileName={exlnm} customizeExcelCell={customizeExcelCell} />
                    <GroupPanel visible={false} />

                    <Paging enable={false} pageSize={itemsPerPage == - 1 ? total : itemsPerPage * multiply} />
                    <Pager visible={false} />
                    <LoadPanel enabled={showLoading} />
                    <Sorting mode={'none'} />
                    {
                        editable &&
                        <Editing
                            mode={'cell'}
                            allowUpdating={true} 
                        />
                    }
                    {
                        showNo &&
                        <Column 
                            // width={60}
                            visible={detectMobile.isMobile() ? false : true}
                            alignment={'center'}
                            dataField={'no'}
                            caption={'No'}
                            cellRender={(d) => {
                                const no = ((page-1)*(itemsPerPage == -1 ? total : itemsPerPage)) + (d.rowIndex + 1);
                                return <span>{no}</span>
                            }}
                        />
                    }
                    {
                        /** Columns */
                        props.children
                    }
                </DataGrid>
            </div>
            {
                /** Paging  */
                (pagingable && page > 0) &&
                <DataGridPagerView
                    page= {page}
                    total= {total}
                    itemsPerPage= {itemsPerPage}
                    pagesPerBlock= {pagesPerBlock}
                    onClickPage= {onClickPage}
                    onValueChanged={onChangePageSize}
                />
            }
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default DataGridView;
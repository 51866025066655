const equpApiz = {
    
    /** ***************** 
     *  목록 조회
     *  ***************** */
    getLineList: {
        title: "라인 목록 조회",
        url: "/api/equp/getLineList",
        method: "POST"
    },
    getLineCombo: {
        title: "라인 콤보 조회",
        url: "/api/equp/getLineCombo",
        method: "POST"
    },
    getEqupList: {
        title: "설비 목록 조회",
        url: "/api/equp/getEqupList",
        method: "POST"
    },
    getEqupChkListDy: {
        title: "설비 일상점검 목록 조회",
        url: "/api/equp/getEqupChkListDy",
        method: "POST"
    },
    getEqupChkListCycle: {
        title: "설비 정기점검 목록 조회",
        url: "/api/equp/getEqupChkListCycle",
        method: "POST"
    },
    getEqupChkItemList: {
        title: "설비 점검항목 조회",
        url: "/api/equp/getEqupChkItemList",
        method: "POST"
    },
    getGaugeList: {
        title: "측정기 목록 조회",
        url: "/api/equp/getGaugeList",
        method: "POST"
    },
    getGaugeCalibList: {
        title: "측정기 검교정 목록 조회",
        url: "/api/equp/getGaugeCalibList",
        method: "POST"
    },
    getBkupPartList: {
        title: "예비부품 목록 조회",
        url: "/api/equp/getBkupPartList",
        method: "POST"
    },
    getBkupPartInList: {
        title: "예비부품 입고 목록 조회",
        url: "/api/equp/getBkupPartInList",
        method: "POST"
    },
    getBkupPartOutList: {
        title: "예비부품 출고 목록 조회",
        url: "/api/equp/getBkupPartOutList",
        method: "POST"
    },
    getEqupFixList: {
        title: "설비수리 목록 조회",
        url: "/api/equp/getEqupFixList",
        method: "POST"
    },
    getEqupConnHisList: {
        title: "설비 연동내역 목록 조회",
        url: "/api/equp/getEqupConnHisList",
        method: "POST"
    },
    getEqupConnHisListMonit: {
        title: "설비 연동생산 모니터링 목록 조회",
        url: "/api/equp/getEqupConnHisListMonit",
        method: "POST"
    },
    // getEqupConnManuListRunDown: {
    //     title: "설비 연동 비가동 다중 조회",
    //     url: "/api/equp/getEqupConnManuListRunDown",
    //     method: "POST"
    // },

    getDownHisList: {
        title: "설비 연동 비가동 다중 조회",
        url: "/api/equp/getDownHisList",
        method: "POST"
    },
    

    /** ***************** 
     *  단일조회
     *  ***************** */
    getLine: {
        title: "라인 상세 조회",
        url: "/api/equp/getLine",
        method: "POST"
    },
    getEqup: {
        title: "설비 상세 조회",
        url: "/api/equp/getEqup",
        method: "POST"
    },
    getEqupChkCycleEqup: {
        title: "설비 점검주기 조회(설비UID)",
        url: "/api/equp/getEqupChkCycleEqup",
        method: "POST"
    },
    getEqupChkItem: {
        title: "설비 점검항목 조회",
        url: "/api/equp/getEqupChkItem",
        method: "POST"
    },   
    getEqupChkEqup: {
        title: "설비 점검 조회(설비, 주기타입, 점검대상일자)",
        url: "/api/equp/getEqupChkEqup",
        method: "POST"
    },
    getEqupChkMon: {
        title: "설비 점검 조회(월단위)",
        url: "/api/equp/getEqupChkMon",
        method: "POST"
    },
    getEqupCombo: {
        title: "설비 콤보",
        url: "/api/equp/getEqupCombo",
        method: "POST"
    },
    getGauge: {
        title: "측정기 단일조회",
        url: "/api/equp/getGauge",
        method: "POST"
    },
    getGaugeCalib: {
        title: "측정기 검교정 단일조회",
        url: "/api/equp/getGaugeCalib",
        method: "POST"
    },
    getBkupPart: {
        title: "예비부품 상세 조회",
        url: "/api/equp/getBkupPart",
        method: "POST"
    },
    getBkupPartEqup: {
        title: "예비부품 조회(설비별) - 설비별 예비부품 목록",
        url: "/api/equp/getBkupPartEqup",
        method: "POST"
    },
    getEqupFix: {
        title: "설비수리 상세 조회",
        url: "/api/equp/getEqupFix",
        method: "POST"
    },
    getEqupHisRepo: {
        title: "설비 이력카드 리포트",
        url: "/api/equp/getEqupHisRepo",
        method: "POST"
    },
    getGaugeHisRepo: {
        title: "측정기 관리대장 리포트",
        url: "/api/equp/getGaugeHisRepo",
        method: "POST"
    },
    getEqupConnHis: {
        title: "설비 연동생산 상세 조회",
        url: "/api/equp/getEqupConnHis",
        method: "POST"
    },
    getEqupConnConcentEqup: {
        title: "설비 연동농도 정보조회",
        url: "/api/equp/getEqupConnConcentEqup",
        method: "POST"
    },
    getEqupDownHis: {
        title: "설비 비가동 정보조회 ",
        url: "/api/equp/getEqupDownHis",
        method: "POST"
    },
    getEqupConnHisDataAmt: {
        title: "연동장비 실적정보조회",
        url: "/api/equp/getEqupConnHisDataAmt",
        method: "POST"
    },
    

    /** ***************** 
     *  등록
     *  ***************** */
    addLine: {
        title: "라인 등록",
        url: "/api/equp/addLine",
        method: "POST"
    },
    addEqup: {
        title: "설비 등록",
        url: "/api/equp/addEqup",
        method: "POST"
    },
    addEqupChkItem: {
        title: "설비 점검항목 등록",
        url: "/api/equp/addEqupChkItem",
        method: "POST"
    },
    addEqupChk: {
        title: "설비 점검 등록",
        url: "/api/equp/addEqupChk",
        method: "POST"
    },
    addGauge: {
        title: "측정기 등록",
        url: "/api/equp/addGauge",
        method: "POST"
    },
    addGaugeCalib: {
        title: "측정기 검교정 등록",
        url: "/api/equp/addGaugeCalib",
        method: "POST"
    },
    addBkupPart: {
        title: "예비부품 등록",
        url: "/api/equp/addBkupPart",
        method: "POST"
    },
    addBkupPartIn: {
        title: "예비부품 입고 등록",
        url: "/api/equp/addBkupPartIn",
        method: "POST"
    },    
    addBkupPartOut: {
        title: "예비부품 출고 등록",
        url: "/api/equp/addBkupPartOut",
        method: "POST"
    },    
    addEqupFix: {
        title: "설비 수리 등록",
        url: "/api/equp/addEqupFix",
        method: "POST"
    },   
    addEqupConnHis: {
        title: "설비 연동내역 등록",
        url: "/api/equp/addEqupConnHis",
        method: "POST"
    },       
    addEqupDownHis: {
        title: "설비 비가동 내역 등록",
        url: "/api/equp/addEqupDownHis",
        method: "POST"
    },     

    /** ***************** 
     *  수정
     *  ***************** */
    upLine: {
        title: "라인 수정",
        url: "/api/equp/upLine",
        method: "POST"
    },
    upEqup: {
        title: "설비 수정",
        url: "/api/equp/upEqup",
        method: "POST"
    },
    
    upEqupChkItem: {
        title: "설비 점검항목 수정",
        url: "/api/equp/upEqupChkItem",
        method: "POST"
    },
    upGauge: {
        title: "측정기 수정",
        url: "/api/equp/upGauge",
        method: "POST"
    },
    upGaugeCalib: {
        title: "측정기 수정",
        url: "/api/equp/upGaugeCalib",
        method: "POST"
    },
    upBkupPart: {
        title: "예비부품 수정",
        url: "/api/equp/upBkupPart",
        method: "POST"
    },
    upEqupFix: {
        title: "설비 수리 수정",
        url: "/api/equp/upEqupFix",
        method: "POST"
    },   
    upEqupConnHisEnd: {
        title: "설비 연동내역 수정(종료)",
        url: "/api/equp/upEqupConnHisEnd",
        method: "POST"
    },     
    upEqupConnHisAmt: {
        title: "설비 연동내역 수정(수량)",
        url: "/api/equp/upEqupConnHisAmt",
        method: "POST"
    },     
    upEqupDownHis: {
        title: "설비 비가동 내역 수정",
        url: "/api/equp/upEqupDownHis",
        method: "POST"
    },     
    /** ***************** 
     *  삭제
     *  ***************** */
    delLine: {
        title: "라인 삭제",
        url: "/api/equp/delLine",
        method: "POST"
    },
    delEqup: {
        title: "설비 삭제",
        url: "/api/equp/delEqup",
        method: "POST"
    },
    delEqupChkItem: {
        title: "설비 점검항목 삭제",
        url: "/api/equp/delEqupChkItem",
        method: "POST"
    },
    delGaugeCalib: {
        title: "측정기 검교정 삭제",
        url: "/api/equp/delGaugeCalib",
        method: "POST"
    },
    delEqupDownHis: {
        title: "설비 비가동 내역 삭제",
        url: "/api/equp/delEqupDownHis",
        method: "POST"
    },    
}

export default equpApiz;
import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    ActionButton, SiteLayout
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';
/** 
 *  메인 페이지
 */
const SitePage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

 
    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            await loadMenu()
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    /* ====================
     *  Handle / Functions
     * ==================== */

     const loadMenu = async() => {
        // let r = await cmn.api('getMenuUser', {p:{}});
        // if(r == null || r === undefined) return;

        // let cnt = r.d1.length;

        // console.log(r.d1);
        // setMenus(r.d1);
     }

    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <table className="main-menu">
                <colgroup>
                    <col />
                    <col />
                    <col />
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteworksta');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="작업지시"
                                show={true}
                            /> 
                        </td>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/sitemanustndby');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="사내실적대기"
                                show={true}
                            /> 
                        </td>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/sitemanuretfstproc');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="작업등록(주공정)"
                                show={true}
                            /> 
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/sitemanuretaftproc');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="작업등록(후공정)"
                                show={true}
                            /> 
                        </td>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/sitelblprint');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="라벨출력"
                                show={true}
                            /> 
                        </td>
                        <td></td>
{/*                         
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteoutsoutstndby');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="외주출고대기"
                                show={true}
                            /> 
                        </td>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteoutsout');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="외주출고"
                                show={true}
                            /> 
                        </td> */}
                    </tr>
                    {/* <tr> */}
                        
                        {/* <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteoutsinstndby');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="외주입고대기"
                                show={true}
                            /> 
                        </td>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteoutsin');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="외주입고"
                                show={true}
                            /> 
                        </td> */}
                    {/* </tr>   */}
                    <tr>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteequpdaychk');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="설비점검(일상)"
                                show={true}
                            /> 
                        </td>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteequpworksta');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="설비작업현황"
                                show={true}
                            /> 
                        </td>
                        <td>
                            <ActionButton 
                                onClick={() => {
                                    authStore.history.push('/site/siteequpworkmonit');
                                }}
                                width={"100%"}
                                type={"site-2"}
                                title="설비작업 모니터링"
                                show={true}
                            /> 
                        </td>
                    </tr>    
                </tbody>
            </table>
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"POP"}
            content = {renderContent()}
        />
        </>
    )
}

export default SitePage;
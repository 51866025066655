import React, { useState, useEffect, useContext } from "react";
import { Observer } from "mobx-react-lite";
import { TextBox } from "devextreme-react";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import qs from "query-string";
import useKey from "use-key-hook";
import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";
import { Chart, Series, ArgumentAxis, CommonSeriesSettings, Legend, Margin, Tooltip, Grid } from "devextreme-react/chart";

/** components */
import { ListSearchView, ListTopView, GridTopView, DataGridView, SrchButton, DayDateBox, ProdSearchBox, ProdProcSelectBox } from "../../components";

/** stores & lib */
import { AuthStore, CmnStore } from "../../stores";
import { util, cmn } from "../../lib";

/**
 *  공정불량률
 */
const ProcBadPage = (props) => {
  /* ====================
   *  Store
   * ==================== */

  const authStore = useContext(AuthStore);
  const cmnStore = useContext(CmnStore);

  /* ====================
   *  Object
   * ==================== */

  // ...

  /* ====================
   *  State / Effect
   * ==================== */
  const [pageLoad, setPageLoad] = useState(true);

  // * 검색
  const [startMon, setStartMon] = useState(util.getNow());
  const [endMon, setEndMon] = useState(util.getNow());
  const [prodUid, setProdUid] = useState("");
  const [prodProcUid, setProdProcUid] = useState("");

  /* #region Search Items */

  const searchItems = [
    [
      {
        title: "작업월",
        width: "200px",
        item: (
          <ul className="ul-row">
          <li>
            <DayDateBox
              value={startMon}
              type={"month"}
              displayFormat={cmn.DATE_FORMAT_MON_1}
              onValueChanged={(e) => {
                setStartMon(e.value);
              }}
            />
          </li>
          <li>~</li>
          <li>
            <DayDateBox
              value={endMon}
              type={"month"}
              displayFormat={cmn.DATE_FORMAT_MON_1}
              onValueChanged={(e) => {
                setEndMon(e.value);
              }}
            />
          </li>
        </ul>
        ),
      },
      {
        title: "제품검색",
        width: "300px",
        item: (
          <ProdSearchBox
            value={prodUid}
            popCenter={false}
            onLoadInfo={(o) => {}}
            onValueChanged={(v) => {
              setProdUid(v);
              setProdProcUid("");
            }}
          />
        ),
      },
      {
        title: "공정명",
        item: (
          <ProdProcSelectBox
            width={"200px"}
            value={prodProcUid}
            prodUid={prodUid}
            onLoadInfo={(o) => {}}
            onValueChanged={(v) => {
              setProdProcUid(v);
            }}
          />
        ),
      },
    ],
  ];

  /* #endregion */

  // * Grid
  const [manuListTime, setManuListTime] = useState([]);
  const [chartManuListTime, setChartManuListTime] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  // * Paging & Sorting
  // * Grid, Paging & Sorting
  const [curPage, setCurPage] = useState(1);
  const [rowsOffset, setRowsOffset] = useState(0);
  const [rowsLength, setRowsLength] = useState(authStore.user.rows);
  const [sortItem, setSortItem] = useState({ col: "", tp: "" });

  /** Enter Key */
  useKey(
    (pressedKey, event) => {
      util.clickElement("srchButton");
    },
    {
      detectKeys: [13], // detectKeys: ['A', '+', 122]
    }
  );

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    !pageLoad && handleClickSearch();
  }, [curPage, rowsLength, sortItem]);

  /* ====================
   *  Load
   * ==================== */

  const loadPage = async () => {
    try {
      await authStore.loadPage(props);
      //     await handleClickSearch();
      setPageLoad(false);
    } catch (err) {
      cmnStore.handleErrorPage(err, props);
    }
  };

  const loadList1 = async () => {
    if (util.getChkNull(prodProcUid) === "") {
      util.cmnAlert("[제품]과 [공정]을 선택해 주십시오.");
      return;
    }

    const ps = {
      p: {
        startDy: util.getDateToString("M", startMon),
        endDy: util.getDateToString("M", endMon),
        prodProcUid: prodProcUid,

        sortCol: sortItem.col,
        sortTp: sortItem.tp,
      },
    };

    const r = await cmn.api("getManuListTime", ps);
    if (r === undefined || r == null) return;

    setManuListTime(r.d0);
    setChartManuListTime(
      r.d0.map((v) => {
        return {
          manuMon: v.manuMon,
          badPpm: v.badPpm * 1,
        };
      })
    );
  };

  /* ====================
   *  Handle / Functions
   * ==================== */

  // * Actions

  const handleClickSearch = async (e) => {
    try {
      await loadList1();
    } catch (err) {
      cmnStore.handleErrorPage(err, props);
    }
  };

  const handleClickAdd = (e) => {
    openCrudPopup("a", "0");
  };

  const handleClickUp = (e) => {
    if (util.chkListSelected(selectedItem)) {
      openCrudPopup("u", selectedItem.bizUid);
    }
  };

  const handleClickView = async () => {
    if (util.chkListSelected(selectedItem)) {
      openCrudPopup("v", selectedItem.bizUid);
    }
  };

  // * Data Grid

  const handleGridSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData.length == 0) {
      setSelectedItem(null);
      return;
    }
    setSelectedItem(selectedRowsData[0]);
  };

  const handleGridCellClick = (e) => {
    // Header Sorting
    const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
    if (sortItem_ != null) {
      setSortItem(sortItem_);
    }
  };

  const handleClickPage = (p) => {
    setCurPage(p);
    const offset = (p - 1) * rowsLength;
    setRowsOffset(offset);
  };

  const handleChangePageSize = (e) => {
    setRowsLength(e.value);
  };

  // * Functions

  const openCrudPopup = (mode, bizUid) => {
    // page mode: v(View), a(Add), u(Update)
    const url = `/base/bizpop?${qs.stringify({ mode: mode, uid: bizUid })}`;
    util.openWindow(url, 700, 500);
  };

  /* ====================
   *  Render
   * ==================== */

  // Render Grid Header (* Sort ...)
  const renderGridHeaderCell = (e) => {
    return cmn.renderGridHeaderCell(e, sortItem);
  };

  const renderContent = () => {
    return (
      <>
        <ListTopView>
          <SrchButton id="srchButton" onClick={handleClickSearch} />
          {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                /> */}
        </ListTopView>
        <ListSearchView searchItems={searchItems} />
        <ResponsiveBox singleColumnScreen="sm">
          <Row ratio={1}></Row>
          <Col ratio={1}></Col>
          <Col ratio={1}></Col>
          <Item>
            <Location row={0} col={0} screen="lg"></Location>
            <Location row={1} col={0} screen="sm"></Location>
            <GridTopView title="공정불량률"></GridTopView>
            <DataGridView
              dataSource={manuListTime} // store
              gridHeight={authStore.windowHeight - cmn.BH.H10}
              // sort
              onCellClick={handleGridCellClick}
              // select
              onSelectionChanged={handleGridSelectionChanged}
              colsWidth={[80, 130, 130, 100, 100, 100, 100]}
            >
              {cmn.getGridColumnMonthType("manuMon", "작업월", renderGridHeaderCell)}
              {cmn.getGridColumn("prodId", "품번", renderGridHeaderCell)}
              {cmn.getGridColumn("prodNm", "품명", renderGridHeaderCell)}
              {cmn.getGridColumn("procNm", "공정", renderGridHeaderCell)}
              {cmn.getGridColumnNumCommaType("manuAmt", "생산수량")}
              {cmn.getGridColumnNumCommaType("badAmt", "불량수량")}
              {cmn.getGridColumnNumCommaType("badPpm", "불량 PPM")}

              <Summary>
                <TotalItem
                  column={"manuAmt"}
                  summaryType={"sum"}
                  customizeText={(d) => {
                    return util.getNumComma(d.value);
                  }}
                />
                <TotalItem
                  column={"badAmt"}
                  summaryType={"sum"}
                  customizeText={(d) => {
                    return util.getNumComma(d.value);
                  }}
                />
                <TotalItem
                  column={"badPpm"}
                  summaryType={"avg"}
                  customizeText={(d) => {
                    return util.getNumComma(d.value);
                  }}
                />
              </Summary>
            </DataGridView>
          </Item>
          <Item>
            <Location row={0} col={1} screen="lg"></Location>
            <Location row={1} col={0} screen="sm"></Location>
            <GridTopView title="공정불량률 Chart"></GridTopView>
            <div style={{ border: "1px solid #d8d8d8", padding: "0 6px", height: "100%" }}>
              <Chart dataSource={chartManuListTime}>
                <CommonSeriesSettings argumentField="manuMon" type={"line"} />
                <Series valueField={"badPpm"} name={"공정불량률"} color="#0000ff" />
                <Margin bottom={20} />
                <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode="crossLabels">
                  <Grid visible={true} />
                </ArgumentAxis>
                <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom" />
                {/* <Export enabled={true} /> */}

                <Tooltip enabled={true} />
              </Chart>
            </div>
          </Item>
        </ResponsiveBox>
      </>
    );
  };

  return <>{!pageLoad && renderContent()}</>;
};

export default ProcBadPage;

import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    IconButton, DayDateBox, UserSelectBox, 
    ActionButton, SiteLayout, BizSelectBox,
    GridTopView, ListSearchView, EditGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';

import { util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const SiteOustsOutPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        lt = ls.lt,
    } = props;


    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [lot, setLot] = useState(util.getChkNull(lt));  

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "Lot",
                item: 
                    <TextBox 
                        value={lot}
                        width={'200px'}
                        placeholder="Lot로 검색하세요."
                        showClearButton={true}
                        onValueChanged={(e)=>{
                            setLot(e.value);
                        }}
                    /> 
            }
        ]
    ];    

    //
    const [outDy, setOutDy] = useState(util.getNow());   
    const [userUid, setUserUid] = useState(authStore.user.uid);  
    const [prodProcUid, setProdProcUid] = useState('');  
    const [outsOutItems, setOutsOutItems] = useState([]);  
    const [bizUid, setBizUid] = useState('');  
    

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadLotInfo();
    },[lot]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadLotInfo = async() => {
        if(util.getChkNull(lot) === '') return;

        let ps = {
            p:{
                lot:lot,

                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        };

        let r = await cmn.api('getOutsOutListStndBy', ps);
        if(r == null || r === undefined) return;

        setLot('');
        
        let data = r.d0[0];

        let outsOutItems_ = [...outsOutItems];

        // 입력 Validation Check
        // data = outsOutItems_ 의 상태를 체크

        let msg = '';
        _.forEach(outsOutItems_, (v, k) => {
            if(v.lot === data.lot) // lot 중복 체크
                msg = '이미 등록된 Lot 입니다.' 

            if(v.prodProcUid !== data.prodProcUid)
                msg = '같은 공정의 제품만 등록할 수 있습니다.' 

            if(v.prodUid !== data.prodUid)
                msg = '같은 제품만 등록할 수 있습니다.' 
        });

        if(msg !== '')
        {
            util.cmnAlert(msg);
            return;
        }

        outsOutItems_.push({
            uid: util.getUUID()
            ,lot: data.lot
            ,prodProcUid: data.prodProcUid
            ,procNm: data.procNm
            ,procSpec: data.procSpec
            ,prodUid: data.prodUid
            ,prodNm: data.prodNm
            ,prodId: data.prodId
            ,prodStnd: data.prodStnd
            ,stndByAmt: data.stndByAmt
            ,compAmt: data.compAmt
            ,outAmt: data.outAmt
            ,possAmt: data.possAmt
            ,cmnt: ''
        });

        console.log('outsOutItems_ ===>', outsOutItems_);

        setProdProcUid(data.prodProcUid);
        setOutsOutItems(outsOutItems_);
    }

     /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = async(e) => {

        // validation Check
        if(!util.chkMustValue(bizUid, '출고업체'))
            return;
        if(!util.chkMustValue(userUid, '출고자'))
            return;
        if(!util.chkMustValue(_.sumBy(outsOutItems, 'outAmt'), '출고수량', 'cnt'))
            return;

        // 추가 Validation Check
        let msg = '';
        _.forEach(outsOutItems, (v,k) => {
            if(v.outAmt > v.possAmt) 
                msg = '[출고수량]이 [출고가능수량] 보다 큽니다.';
            
            if(v.outAmt < 0)
                msg = '[출고수량]은 양수로 입력해야 합니다.';

        });
        if(msg !== '')
        {
            util.cmnAlert(msg);
            return;
        }

        if(util.confirmYn('외주출고'))
            addOutsOut();
    }

    const addOutsOut = async() => {
        
        let outsOutItems_ = [];

        _.forEach(outsOutItems, (v,k) => {
            let groupUid = util.getUUID();
            if(v.outAmt > 0) {
                outsOutItems_.push(
                    {
                        outsOutUid: util.getUUID(),
                        comUid: authStore.com.uid,
                        bizUid: bizUid,
                        prodProcUid: prodProcUid,
                        outDy: util.getDateToString('d',outDy),
                        userUid: userUid,
                        uptUserUid: authStore.user.uid,
                        lot: v.lot,
                        outAmt: v.outAmt,
                        groupUid: groupUid,
                        cmnt: v.cmnt
                    }
                );
            }
        });

        const ps = {
            p: [...outsOutItems_]
        }

        const r = await cmn.api('addOutsOut', ps); // inOutStore.addOutsOut(para);
        if(r == null) return;

        util.cmnSucAlert('외주출고'); 

        window.location.reload();
    }


    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                <GridTopView
                    title="외주출고 정보"
                >
                    {/* <SrchButton 
                        id="srchButton"
                        onClick={handleClickSearch}
                    /> */}
                    <ActionButton 
                        title="외주출고"
                        onClick={handleClickComplete}
                        show={true}
                        type={"site-1"}
                        width={"140px"}
                    />
                    <ActionButton 
                        title="공정이동표"
                        width={"160px"}
                      //  onClick={handleClickDel}
                        show={true}
                        type={"site-3"}
                    />
                </GridTopView>
                <ListSearchView searchItems={searchItems} type={"site"} />
                
                    <div className="site_list_type">
                        <table>
                            <colgroup>
                                <col className="tit" />
                                <col className="tcon" width={'35%'} />
                                <col className="tit"  />
                                <col className="tcon" width={'35%'}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="tit">출고일자</th>
                                    <td className="tcon">
                                        <DayDateBox
                                            value={outDy}
                                            onValueChanged={(e) => {
                                                setOutDy(e.value);
                                            }}                    
                                        />
                                    </td>
                                    <th className="tit">출고자</th>
                                    <td className="tcon">
                                        <UserSelectBox
                                            value={userUid}
                                            showClearButton={false}
                                            placeholder="출고자를 선택하세요"
                                            onValueChanged={(v) => { 
                                                setUserUid(v);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">출고업체</th>
                                    <td className="tcon" colSpan={3}>
                                        <BizSelectBox
                                            value={bizUid}                                        
                                            bizTp={[cmn.CMNCO_BIZ.PART]}
                                            width={'200px'}
                                            onLoadInfo={(o) => {
                        
                                            }} 
                                            onValueChanged={(v) => {
                                                setBizUid(v);
                                            }}
                                        />   
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                        <>
                        <EditGridView            
                            dataSource={outsOutItems}   // store
                            gridHeight={authStore.windowHeight - 380}
                            colsWidth={[150, 120, null, null, null, 120, 120, 120, 120, null, 100]}
                        >
                            { cmn.getGridColumn('lot', 'Lot' ) }
                            { cmn.getGridColumn('procNm', '공정' ) }
                            { cmn.getGridColumn('prodNm', '품명' ) }
                            { cmn.getGridColumn('prodId', '품번' ) }
                            { cmn.getGridColumn('prodStnd', '규격' ) }
                            { cmn.getGridColumnNumCommaType('stndByAmt', '출고대기수량' ) }
                            { cmn.getGridColumnNumCommaType('compAmt', '기출고수량' ) }
                            { cmn.getGridColumn('possAmt', '출고가능수량' ) }
                            <Column 
                                alignment={'center'}
                                dataField={'outAmt'} 
                                caption={'출고수량'}
                            />
                            <Column 
                                alignment={'center'}
                                dataField={'cmnt'} 
                                caption={'비고'}
                            />
                            <Column 
                                alignment={'center'}
                                dataField={'uid'} 
                                caption={'취소'}
                                allowEditing={false}
                                cellRender={(d) => {
                                    return (
                                        <IconButton
                                            className="btn-icon-close-1"
                                            onClick={(e) => {
                                                const outsOutItems_ = _.remove(outsOutItems, (v) => {
                                                    return d.value != v.uid;
                                                });
                                        
                                                setOutsOutItems(outsOutItems_);
                                            }}
                                            marginRight={0}
                                        />
                                    )
                                }}
                            />
                        </EditGridView>
                        </>
                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"외주출고"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteOustsOutPage;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout,
    ActionButton,
    UseYnSwitchBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  공통코드 등록 / 수정
 */
const CodePopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [codeClassItems, setCodeClassItems] = useState([]);
    const [classId, setClassId] = useState('');
    const [classNm, setClassNm] = useState('');
    const [codeId, setCodeId] = useState('');
    const [codeNm, setCodeNm] = useState('');
    const [sortOrder, setSortOrder] = useState(1);
    const [useYn, setUseYn] = useState('Y');
    const [cmnt, setCmnt] = useState('');
    
    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {        
        try {
            await authStore.loadPopPage(props);

            if(mode === "a" || mode === "u"){
                let ps = {p:{systemYn:'N'}};
                let r = await cmn.api('getCodeClass', ps);
                if(r == null || r === undefined) return;
                setCodeClassItems(r.d0);  
            }

            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {    
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            const ps = { p: {codeUid: uid}};
        
            const resData = await cmn.api('getCode', ps);
            let data = resData.d0[0];

            setClassId(data.classId);
            setClassNm(data.classNm);
            setCodeId(data.codeId);
            setCodeNm(data.codeNm);
            setSortOrder(data.sortOrder);
            setUseYn(data.useYn);
            setCmnt(data.cmnt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(classId, '코드유형'))
                return;
            if(!util.chkMustValue(codeNm, '코드명'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addCode();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upCode();
            }
            else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delCode();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addCode = async() => {
        const ps = {
            p: {
                codeUid: util.getUUID(),
                codeId: '',
                comUid: authStore.com.uid,
                codeNm: codeNm,
                classId: classId,
                classNm: classNm,
                useYn: useYn,
                systemYn: "N",
                showYn: "Y",
                sortOrder: sortOrder,
                etcVal: "",
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api("addCode", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upCode = async() => {
        const ps = {
            p: {
                codeUid: uid,
                codeNm: codeNm,
                classId: classId,
                classNm: classNm,
                useYn: useYn,
                systemYn: "N",
                sortOrder: sortOrder,
                etcVal: "",
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api("upCode", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("수정");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delCode = async() => {
        const ps = {p: {comUid: authStore.com.uid, codeUid: uid}}

        const r = await cmn.api("delCode", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("삭제");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">코드유형</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                    <SelectBox 
                                        value={classId}
                                        dataSource={codeClassItems}
                                        displayExpr={'classNm'}
                                        valueExpr={'classId'}
                                        placeholder="코드유형을 선택하세요"
                                        showClearButton={false} 
                                        onValueChanged={(e)=>{
                                            setClassId(e.value);
                                            const codeClassItems_ = _.find(codeClassItems, {classId: e.value});
                                            setClassNm(codeClassItems_.classNm);
                                        }} 
                                    />
                                :
                                    <span>{classNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">코드</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    // <TextBox 
                                    //     value={codeId}
                                    //     showClearButton={true}
                                    //     // placeholder=""
                                    //     valueChangeEvent={'keyup'}
                                    //     onValueChanged={(e)=>{
                                    //         setCodeId(e.value);
                                    //     }} 
                                    // />
                                    <span>자동생성</span>
                                :
                                    <span>{codeId}</span>
                                }
                            </td>
                            <th className="tit req">코드명</th>
                            <td className="tcon">
                                {
                                    mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={codeNm}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setCodeNm(e.value);
                                        }} 
                                    /> 
                                :
                                     <span>{codeNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">코드순서</th>
                            <td className="tcon">
                                {
                                    mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={80}
                                        value={sortOrder}
                                        format={'#,##0'}
                                        defaultValue={1}
                                        min={1}
                                        // max={20}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setSortOrder(e.value);
                                        }} 
                                    />
                                    :
                                     <span>{sortOrder}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon">
                                {
                                    mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={useYn}
                                        onValueChanged={(v)=>{
                                            setUseYn(v);
                                        }} 
                                    />
                                    :
                                    <span>{useYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                    mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={cmnt}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setCmnt(e.value);
                                        }}
                                    />  
                                    :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "공통코드 등록" 
                : mode === "u" ? "공통코드 수정"
                : mode === "v" ? "공통코드 상세보기"
                : "공통코드" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default CodePopPage;
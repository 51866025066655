import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextBox, SelectBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import _ from 'lodash';

/** components */
import { SrchButton, SubActionButton, DataGridView } from '..';

/** stores & lib */
import { CmnStore } from '../../stores';
import { cmn, util } from '../../lib';
import ActionButton from '../button/ActionButton';

/** 
 *  제품 검색 조회
 */
const UserSearchBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const cmnStore = useContext(CmnStore);
    
    /* ====================
     *  Object
     * ==================== */

    const {
        value,
        popCenter=false,

        onLoadInfo,
        onValueChanged,

        schCnt = 1,
        mode
    } = props;

    const pw = 560;     // popup width
    const ph = 470;     // popup height

    /* ====================
     *  State / Effect
     * ==================== */

    const [users, setUsers] = useState([]);
    const [usersTotal, setUsersTotal] = useState(0);    
    const [searchText, setSearchText] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);

    // * 검색
    const [userNm, setUserNm] = useState('');
    const [deptUid, setDeptUid] = useState('');

    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(10);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const [userItems, setUserItems] = useState([]);

    const [deptItems, setDeptItems] = useState([]);    
    
    const grid1 = useRef(null);
    const divUsers = useRef(null);

    useEffect(() => {
        loadPage();
    },[value]);

    useEffect(() => {
        handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {        
        try {

            if(util.getChkNull(value) === '') {

                // setSelectedItem(null);
                setUserItems([]);    
                handleLoadInfo(null);
                setPopupVisible(false);
                grid1.current.instance.clearSelection();
                return;
            }     

            console.log('value=======>', value);
            setUserItems(value);
            
            let r = await cmn.api('getDeptCombo', {})
            if(r == null || r === undefined) return;

            setDeptItems(r.d0);
            // Colunm 정보를 설정한다.
           // loadUser();
        }
        catch(err) {
            // nothing ...
        }
    }



    const loadUsers = async() => {
 
        const ps =  {
            p: {
                deptUid: deptUid, 
                userNm: userNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getUserList", ps);

        setUsers(r.d0);
        setUsersTotal(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleLoadInfo = (ds) => {

      //  setUserInfo(ds.prodNm);

        onLoadInfo(ds);        
    }

    const handleValueChanged = () => {
        console.log(userItems);
        onValueChanged(userItems);
        setPopupVisible(false);
    }

    const handleClickOpenPop = (e) => {
        setPopupVisible(true);
    }

    const handleClickSearch = async(e) => {
        try {
            await loadUsers();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Data Grid
    // const handleGridSelectionChanged = ({selectedRowsData}) => {
    //     if(selectedRowsData.length === 0) return;

    //     setSelectedItem(selectedRowsData[0]);

    //     handleLoadInfo(selectedRowsData[0]);
    //     handleValueChanged(selectedRowsData[0].prodUid, selectedRowsData[0]);

    //     setPopupVisible(false);
    // }

    // const handleGridCellClick = (e) => {
    //     // Header Sorting
    //     const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
    //     if(sortItem_ != null){
    //         setSortItem(sortItem_);
    //     }
    // }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <div ref={divUsers}>
                <ul className="ul-row" style={{width: '100%'}}>
                    {
                        mode === 'a' || mode === 'u' ?
                            <li style={{width: '80px'}}>
                                <SubActionButton 
                                    onClick={handleClickOpenPop}
                                    title="검색"
                                    type="1"
                                    marginRight={0}
                                    show={true}
                                />
                            </li>
                        :
                        ''
                    }
                    {
                        _.map(userItems, (v, k) => {
                            return(
                                <>
                                <li className="fl-r" >                        
                                    {k === 0 ? '' : ','} {v.userNm}
                                </li>
                                </>
                            )
                        })  
                    }
                    
                </ul>
            </div>
        
            <Popup
                visible={popupVisible}
                
                shading={false}
                dragEnabled={true}
                closeOnOutsideClick={true}

                width={pw}
                height={ph}

                position={(e)=>{
                    
                    if(popCenter) {
                        const ofs = `${(window.innerWidth/2)} ${(window.innerHeight/2)}`;   
                        return {at: 'left top', offset: ofs};   
                    }
                    else {
                        if(divUsers.current === undefined || divUsers.current == null) return;
                        const r = divUsers.current.getBoundingClientRect();
                        const ofs = `${r.x+(pw/2)} ${r.y+(ph/2)+r.height+2}`;   

                        return {at: 'left top', offset: ofs};                        
                    }                  

                }}

                onHiding={(e)=>{
                    setPopupVisible(false);
                }} 
            >
                <>
                <div>
                    <div className="clearfix">
                        <div className="fl-l" >
                            <ul className="ul-row">
                                <li style={{width: '50%'}}>
                                    <SelectBox 
                                        value={deptUid}
                                        dataSource={deptItems}
                                        displayExpr={'deptNm'}
                                        valueExpr={'deptUid'}
                                        placeholder="부서를 선택하세요"
                                        showClearButton={false}
                                        onValueChanged={(e)=>{
                                            setDeptUid(e.value);
                                        }} 
                                    />
                                </li>
                                <li  style={{width: '50%'}}>
                                    <TextBox 
                                        width={'100%'}
                                        value={userNm}
                                        showClearButton={true}
                                        placeholder="이름으로 검색하세요"
                                        valueChangeEvent={'keyup'}

                                        onEnterKey={(e)=>{
                                            loadUsers();
                                        }} 

                                        onValueChanged={(e)=>{
                                            setUserNm(e.value);
                                        }}

                                    /> 
                                </li>
                                
                            </ul>
                        </div>
                        <div className="fl-r">
                            <SrchButton 
                                id="srchButton"
                                onClick={handleClickSearch}
                                type="selpop"
                                marginRight={0}
                                width="90px"
                            />
                           <ActionButton 
                                onClick={handleValueChanged}
                                type={3}
                                title="확인"
                                width="90px"
                                show={true}
                            />
                        </div>
                    </div>
                    <div>
                        <DataGridView   
                            reff={grid1}         
                            dataSource={users}   // store

                            gridHeight={185}

                            showNo={false}

                            // paging
                            page={curPage}
                            total={usersTotal}   // store

                            itemsPerPage={rowsLength}
                            pagesPerBlock={5}
                            onClickPage={handleClickPage}
                            onChangePageSize={handleChangePageSize}

                            selection=''
                            // sort
                          //  onCellClick={handleGridCellClick}

                            // select
                          //  onSelectionChanged={handleGridSelectionChanged}        

                            colsWidth={[100, null, 110, 80]}
                        >
                            { cmn.getGridColumn('deptNm', '부서명') }
                            { cmn.getGridColumn('userNm', '사용자명') }
                            { cmn.getGridColumn('mobile', '핸드폰번호') }
                            <Column 
                                alignment={'center'}
                                dataField={''}
                                caption={''}  
                                cellRender={(d) => {
                                    return (
                                        <SubActionButton 
                                            title="추가"
                                            show={true}
                                            type={"2"}
                                            onClick={(e) => {
                                                const userItems_ = [...userItems];
                                                
                                                if(schCnt <= userItems_.length)
                                                {
                                                    util.cmnAlert(`최대 ${schCnt}명 까지 추가할수 있습니다.`)
                                                    return;
                                                }

                                                let o_ = _.find(userItems_,{userUid:d.data.userUid});
                                                
                                                if(!(o_ === undefined || o_ == null))
                                                {
                                                    util.cmnAlert(`이미 추가한 사용자입니다.`)
                                                    return;
                                                }

                                                userItems_.push({uid: util.getUUID(), userUid: d.data.userUid, userId: d.data.userId, userNm: d.data.userNm});
                                                setUserItems(userItems_);                                            
                                            }}
                                        />
                                    )
                                }}
                            /> 
                        </DataGridView>

                    </div>
                </div>
                <div style={{marginTop:"20px"}}  className="board_list_type">
                    <table height={"95px"}>
                    <colgroup>
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                            {
                                _.map(userItems, (v, k) => {
                                    return(
                                        <ul className="row" key={k}>
                                            <li> {`${v.userId} / ${v.userNm}`} 
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <SubActionButton 
                                                    title="삭제"
                                                    show={true}
                                                    type={"2"}
                                                    onClick={(e) => {
                                                        const userItems_ = _.remove(userItems, (vv) => {
                                                            return v.uid !== vv.uid;
                                                        });
                                                        setUserItems(userItems_);
                                                    }}
                                                />  
                                            </li>
                                        </ul>  
                                    )
                                })  
                            }
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </>
            </Popup>  
              
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default UserSearchBox;

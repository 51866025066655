import React, { useState, useEffect, useContext } from 'react';
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export,
    ArgumentAxis, Margin, Title, Subtitle, Tooltip, Grid} from 'devextreme-react/chart';
import { TextBox } from 'devextreme-react';
import { Observer } from 'mobx-react-lite';
import { Popup } from 'devextreme-react/popup';

import { ActionButton } from '../components';

/** components */
import {
    DataGridView
} from '../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../stores';
import { util, cmn } from '../lib';

/** 
 *  메인 페이지
 */
const MainPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    const [toDay, setToDay] = useState(util.getNow());    
    const [manuBadInfo, setManuBadInfo] = useState([]);    
    const [salesDeliInfo, setSalesDeliInfo] = useState([]);    
    const [workList, setWorkList] = useState([]);
  
    const [oldPw, setOldPw] = useState('');
    const [newPw, setNewPw] = useState('');
    const [newPwConfirm, setNewPwConfirm] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        loadPage();
    },[]); 

    useEffect(() => {     
        loadInfo1();
    },[pageLoad]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        authStore.loadPage(props);
        setPageLoad(false);
    }

    const loadInfo1 = async() => {        
    
        // 반기 생산실적 대비 불량건수
        const manuBadPara =  {
            p: {
                planMon: '', 
                payDy: '', 
                prodNm: '',
            }
        }
        const r = await cmn.api('getManuNBadAmt', manuBadPara);
        if(r == null || r === undefined) return;

        const data = r.d0;
        setManuBadInfo(data);


        // 반기 수주대비 납품 실적
        const salesDeliPara =  {
            p: {
                planMon: '', 
            }
        }
        const r2 = await cmn.api('getSalesNDeliAmt', salesDeliPara);
        if(r2 == null || r2 === undefined) return;

        const data2 = r2.d0;
        setSalesDeliInfo(data2);



        // 생산현황 (2019.11.20)

        const workPara =  {
            p: {
                startDy: util.getDateToString('d',toDay), 
                endDy: util.getDateToString('d',toDay), 
                equpNm: '', 

                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        }

        const r3 = await cmn.api("getWorkList", workPara );
        if(r3 === undefined || r3 == null) return;

        setWorkList(r3.d0)

        if(authStore.com.isPwPolicy){
            if(pageLoad)
            {
                const rUser = await cmn.api('getUser', {p:{userUid:authStore.user.uid}});
                if(rUser == null || rUser === undefined) return;

                if(rUser.d0[0].pwExpireDt < util.getNow())
                {
                    setPopupVisible(true);
                }
            }
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickUpPw = async (e) => {
        try {
            //validation 체크
            if(!util.chkMustValue(oldPw, '기존 비밀번호'))
                return;
            if(!util.chkMustValue(newPw, '신규 비밀번호'))
                return;
            
            if(authStore.com.isPwPolicy){
                if(!util.getPwChk(newPw)){
                    util.cmnAlert("영문대/소문자, 숫자, 특수문자를 포함하고 8자~20자로 입력해야 합니다.");
                    return;
                }
            }

            if(!util.chkMustValue(newPwConfirm, '신규 비밀번호 확인'))
                return;
            
            if(newPwConfirm !== newPw)
            {
                util.cmnAlert('신규 비밀번호 확인이 같지 않습니다.');
                return;
            }

            let para = {
                p:{
                    comUid: authStore.com.uid
                    ,userId: authStore.user.id
                }
            }

            const r = await cmn.api("getUserLogIn", para);
            if(r == null) return;

            const d0 = r.d0[0];

            let salt = d0.salt;
            let key = d0.userPw;

            let confirmPw = util.pwCrypto(oldPw, salt);

            if(key !== confirmPw)
            {
                util.cmnAlert('기존 비밀번호를 잘못 입력하셨습니다.');
                return;
            }
            
            if(util.confirmYn('변경'))   
                upUserPw();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const upUserPw = async() => {
        let pwInfo = util.pwInit(newPw);

        let ps = 
            {
                p: {
                    comUid: authStore.com.uid
                    ,userUid: authStore.user.uid
                    ,userPw: pwInfo.retKey
                    ,salt: pwInfo.retBuf
                    ,uptUserUid: authStore.user.uid
                }
            }

        let r = await cmn.api('upUserPw', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert('변경');

        setPopupVisible(false);
    }

    const handleClickNext = async (e) => {
        let ps = 
            {
                p: {
                    userUid: authStore.user.uid
                }
            }

        let r = await cmn.api('upUserExpireDt', ps);
        if(r == null || r === undefined) return;

        setPopupVisible(false);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderManu = () => {
        return(
            <>
            <div className="mainTit">
                <h3>생산현황 ({util.getDateToString('d', toDay, '.')})</h3>
            </div>
            <DataGridView            
                dataSource={workList}   // store
                gridHeight={'332px'}
                colsWidth={[100, null, 120, 100, 110, 100, 100, 90, 90, null]}
                showNo={true}
                pagingable={false}                
            >
                { cmn.getGridColumn('workNum', '작업번호' ) }
                { cmn.getGridColumn('prodNm', '품명' ) }
                { cmn.getGridColumn('prodId', '품번' ) }
                { cmn.getGridColumn('prodStnd', '규격' ) }
                { cmn.getGridColumn('equpNm', '설비' ) }                
                { cmn.getGridColumn('workAmt', '작업지시수량' ) }
                { cmn.getGridColumn('accrueManuAmt', '누적생산수량' ) }
                { cmn.getGridColumnPer('workPer', '진행률(%)') }
                { cmn.getGridColumnUseYn('compYn', '주공정마감') }
            </DataGridView>
            </>
        )
    }


    const renderContent = () => {
        
        // console.log("@@@ renderContent Main");

        return (
            <>
            <div className="clearfix">
                <div className="mainWrap">
                    <div className="mainColumn widCase1 fl-l">
                        <div className="mainTit">
                            <h3>생산실적 대비 불량건수</h3>
                        </div>
                        {/* <div style={{height: "300px"}}>  */}
                            <Chart
                                palette="Violet"
                                dataSource={manuBadInfo}
                                >
                                <CommonSeriesSettings
                                    argumentField="mon"
                                    type={"line"}
                                />
                                <Series
                                    argumentField="mon"
                                    valueField="manuAmt"
                                    name="생산량"
                                    color="#0000ff"
                                />
                                <Series
                                    valueField="goodAmt"
                                    name="양품량"
                                    color="#008000"
                                />
                                <Series
                                    valueField="badAmt"
                                    name="불량"
                                    color="#ff0000"
                                />
                                {/* <Margin bottom={20} /> */}
                                <ArgumentAxis
                                    valueMarginsEnabled={false}
                                    discreteAxisDivisionMode="crossLabels"
                                >
                                    <Grid visible={true} />
                                </ArgumentAxis>
                                <Legend
                                    verticalAlignment="bottom"
                                    horizontalAlignment="center"
                                    itemTextPosition="bottom"
                                />
                                <Export enabled={false} />
                                <Tooltip enabled={true} />
                            </Chart>
                        {/* </div> */}
                    </div>
                    <div className="mainColumn widCase1 fl-l">
                        <div className="mainTit">
                            <h3>수주대비 납품실적</h3>
                        </div>
                        <Chart id="chart"
                            //title="수주대비 납품 실적"
                            dataSource={salesDeliInfo}
                    //       onPointClick={this.onPointClick}
                        >
                            <CommonSeriesSettings
                                argumentField="mon"
                                type="bar"
                                hoverMode="allArgumentPoints"
                                selectionMode="allArgumentPoints"
                            >
                            <Label visible={true}>
                                <Format type="fixedPoint" precision={0} />
                            </Label>
                            </CommonSeriesSettings>
                            <Series
                                argumentField="mon"
                                valueField="salesAmt"
                                name="수주"
                            />
                            <Series
                                valueField="deliAmt"
                                name="납품"
                            />
                            <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                            <Export enabled={false} />
                        </Chart>
                    </div>
                    <div className="mainColumn mgt20 widCase3 fl-l">
                        { renderManu() }
                        {/* <div className="mainTit">
                            <h3>생산현황 (2019.11.20)</h3>
                        </div>
                        <div className="gridArea">
                            그리드 영역
                        </div> */}
                    </div>
                </div>
            </div>


            
            <Popup
                visible={popupVisible}
                
                shading={false}
                dragEnabled={true}
                closeOnOutsideClick={true}

                width={450}
                height={300}

                position={(e)=>{
                    const ofs = `${(window.innerWidth/2)} ${(window.innerHeight/2)}`;   
                    return {at: 'left top', offset: ofs};   
                }}

                onHiding={(e)=>{
                    setPopupVisible(false);
                }} 
            >
                <>
                <div className="btn_r mgb10">
                    <ActionButton 
                        width={'80px'}
                        onClick={handleClickUpPw}
                        title={'변경'}
                        show={true}
                    />
                    <ActionButton 
                        width={'150px'}
                        onClick={handleClickNext}
                        title={'다음에 변경하기'}
                        marginRight={0}
                        type={2}
                        show={true}
                    />
                </div>
                <div className="board_list_type">
                    <table>
                        <colgroup>
                            <col className="tit150"/>
                            <col className="tcon" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="tit150">기존 비밀번호</th>
                                <td className="tcon" >
                                    <TextBox 
                                        mode={'password'}
                                        value={oldPw}
                                        showClearButton={true}
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setOldPw(e.value);
                                        }} 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th className="tit150">신규 비밀번호</th>
                                <td className="tcon">
                                    <TextBox 
                                        mode={'password'}
                                        value={newPw}
                                        showClearButton={true}
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setNewPw(e.value);
                                        }} 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th className="tit150">신규 비밀번호 확인</th>
                                <td className="tcon">
                                    <TextBox 
                                        mode={'password'}
                                        value={newPwConfirm}
                                        showClearButton={true}
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setNewPwConfirm(e.value);
                                        }} 
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="clearfix">
                        <div className="fl-l">
                            <ul>
                                <li>
                                    <span style={{color:'blue', fontWeight:'bold'}}> * 비밀번호는 3개월마다 변경해야 합니다.</span>
                                </li>
                                <li>
                                    <span style={{color:'red', fontWeight:'bold'}}> * 비밀번호 규칙</span>
                                </li>
                                <li>
                                <span style={{color:'red', fontWeight:'bold'}}> - 영문대/소문자, 숫자, 특수문자를 포함하고 8자 이상 20자 이내.</span>
                                </li>
                            </ul>
                        </div>
                </div>
                </>
            </Popup>  
              
            </>  

            
        )
    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default MainPage;
import React, { useState, useEffect } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  생산설비 선택
 */
const ManuEqupSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        width='100%',

        value,
        lot,
        prodProcUid,
        placeholder='설비를 선택하세요.',

        onValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [equps, setEqups] = useState([]);

    useEffect(() => {
        loadPage();
    },[lot, prodProcUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        if(util.getChkNull(lot) === '' && util.getChkNull(prodProcUid) === ''){
            setEqups([]);
            onValueChanged();
            return;
        }
        const r = await cmn.api('getManuEqup', {p:{lot:lot, prodProcUid:prodProcUid}});
        if(r == null || r === undefined) return;

        setEqups(r.d0);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    
    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            {
                // equps.length > 0 &&
                <SelectBox 
                    width={width}
                    placeholder={placeholder}
                    value={value}
                    dataSource={equps}
                    displayExpr={'equpNm'}
                    valueExpr={'equpUid'}
                    // showClearButton={true}

                    onValueChanged={(e)=>{
                        const o = _.find(equps, {equpUid: e.value});
                        onValueChanged(e.value, o);
                    }} 
                />
            }
                
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default ManuEqupSelectBox;

const prodApiz = {

    getProdList: {
        title: "제품 목록 조회",
        url: "/api/prod/getProdList",
        method: "POST"
    },
    getProd: {
        title: "제품 상세 조회",
        url: "/api/prod/getProd",
        method: "POST"
    },
    getProdCombo: {
        title: "제품 콤보",
        url: "/api/prod/getProdCombo",
        method: "POST"
    },
    addProd: {
        title: "제품 등록",
        url: "/api/prod/addProd",
        method: "POST"
    },
    upProd: {
        title: "제품 수정",
        url: "/api/prod/upProd",
        method: "POST"
    },
    delProd: {
        title: "제품 삭제",
        url: "/api/prod/delProd",
        method: "POST"
    },

    getProdHisList: {
        title: "제품 이력 목록",
        url: "/api/prod/getProdHisList",
        method: "POST"
    },
    addProdHis: {
        title: "제품 이력 등록",
        url: "/api/prod/addProdHis",
        method: "POST"
    },
    delProdHis: {
        title: "제품 이력 삭제",
        url: "/api/prod/delProdHis",
        method: "POST"
    },

    getProdBizPriceList: {
        title: "제품별 업체 단가 목록 조회",
        url: "/api/prod/getProdBizPriceList",
        method: "POST"
    },
    getProdBizPrice: {
        title: "제품별 업체 단가 상세 조회",
        url: "/api/prod/getProdBizPrice",
        method: "POST"
    },
    getProdBizPriceCombo: {
        title: "제품별 업체 단가 콤보",
        url: "/api/prod/getProdBizPriceCombo",
        method: "POST"
    },
    addProdBizPrice: {
        title: "제품별 업체 단가 등록",
        url: "/api/prod/addProdBizPrice",
        method: "POST"
    },
    upProdBizPrice: {
        title: "제품별 업체 단가 수정",
        url: "/api/prod/upProdBizPrice",
        method: "POST"
    },
    delProdBizPrice: {
        title: "제품별 업체 단가 삭제",
        url: "/api/prod/delProdBizPrice",
        method: "POST"
    },
    getProdBizPriceHisList: {
        title: "제품별 업체 단가 내역 목록 조회",
        url: "/api/prod/getProdBizPriceHisList",
        method: "POST"
    },
    // getBomListTree: {
    //     title: "BOM 목록 조회 Tree",
    //     url: "/api/prod/getBomListTree",
    //     method: "POST"
    // },
    getBomTree: {
        title: "BOM Tree 정보 조회",
        url: "/api/prod/getBomTree",
        method: "POST"
    },
    getBom: {
        title: "BOM 상세 조회",
        url: "/api/prod/getBom",
        method: "POST"
    },
    addBom: {
        title: "BOM 등록",
        url: "/api/prod/addBom",
        method: "POST"
    },
    upBom: {
        title: "BOM 수정",
        url: "/api/prod/upBom",
        method: "POST"
    },
    delBom: {
        title: "BOM 삭제",
        url: "/api/prod/delBom",
        method: "POST"
    },
    getBomManu: {
        title: "BOM 조회 생산실적",
        url: "/api/prod/getBomManu",
        method: "POST"
    },
    getBomProd: {
        title: "BOM 조회 제품별",
        url: "/api/prod/getBomProd",
        method: "POST"
    },
    
}

export default prodApiz;
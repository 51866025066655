import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    SubActionButton,
    DataGridView,
    UserSelectBox ,
    CancelButton
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  발주 등록 / 수정
 */
const ToolOutPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);    

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
        wn = ls.wn
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);

    const [toolOutItems, setToolOutItems] = useState([]);
    const [toolItems, setToolItems] = useState([]);
    
    /** Items */
    
    const [toolUid, setToolUid] = useState('');
    const [toolId, setToolId] = useState('');
    const [toolCd, setToolCd] = useState('');
    const [toolNm, setToolNm] = useState('');
    const [toolGrade, setToolGrade] = useState('');
    const [cornerCnt, setCornerCnt] = useState('');
    
    const [toolOutUid, setToolOutUid] = useState('');
    const [workNum, setWorkNum] = useState(util.getChkNull(wn));
    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState(authStore.user.name);
    const [outAmt, setOutAmt] = useState(0);
    const [outDy, setOutDy] = useState(util.getNow());
    const [cmnt, setCmnt] = useState('');
    const [delCmnt, setDelCmnt] = useState('');
    const [stockAmt, setStockAmt] = useState(0);
    const [isDel, setIsDel] = useState('N');

    useEffect(() => {
        loadPage();
    },[]);

    // useEffect(() => {
    //     loadProdInfo();
    // },[prodUid]);
    
    // useEffect(() => {
    //     !pageLoad && loadProdProcDtlCombo();
    // },[prodProcUid]);

    // useEffect(() => {
    //     !pageLoad && loadProdProcInfo();
    // },[prodProcUid]);

    // useEffect(() => {
    //     !pageLoad && loadPrice();
    // },[outAmt, inPrice]);
    
    // useEffect(() => {
    //     !pageLoad && loadUnitPrice();
    // },[bizUid]);

    // useEffect(() => {
    //     !pageLoad && loadPreToolOutfo('proc');
    // },[preProdProcUid]);
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();

            const r = await cmn.api("getToolCombo", {} );
            if(r === undefined || r == null) return;

            setToolItems(r.d0);

            setPageLoad(false);

            if(wn !== '' && mode === 'a')
            {
                loadWorkNum();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadInfo1 = async() => {
        if(util.getChkNull(mode) === 'a') return;
        if(util.getChkNull(uid) === '') return;

        try {

            let r = await cmn.api('getToolOut', {p:{toolOutUid: uid}});
            if(r == null || r === undefined) return;

            let d0 = r.d0[0];
            
            setToolOutUid(d0.toolOutUid);
            setWorkNum(d0.workNum);
            setProdUid(d0.prodUid);
            setProdId(d0.prodId);
            setProdNm(d0.prodNm);
            setProdStnd(d0.prodStnd);
            setUserUid(d0.userUid);
            setUserNm(d0.userNm);
            setOutAmt(d0.outAmt);
            setOutDy(d0.outDy);
            setCmnt(d0.cmnt);
            setToolUid(d0.toolUid);
            setToolId(d0.toolId);
            setToolNm(d0.toolNm);
            setToolCd(d0.toolCd);
            setToolGrade(d0.toolGrade);
            setCornerCnt(d0.cornerCnt);
            setIsDel(d0.isDel)
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
  
    const pageInit = () => {
        setSelectedItem(null);
        setOutAmt(0);
        setUserUid(authStore.user.uid);
        setUserNm(authStore.user.name);
        setCmnt('');
        setOutDy(util.getNow());
        setToolUid('');
        setToolId('');
        setToolNm('');
        setToolCd('');
        setToolGrade('');
        setCornerCnt('');
        setProdUid('');
        setProdId('');
        setProdNm('');
        setProdStnd('');
        setWorkNum('');
        setStockAmt(0);
    }
    
    /* ====================
     *  Handle / Functions
     * ==================== */

    const loadWorkNum = async() => {
        try {
            if(util.getChkNull(workNum) === '') return;
            
            let para = {p: {workNum: workNum}};

            let r = await cmn.api('getWorkNum', para);
            if(r == null || r.d0.length == 0) {
                util.cmnAlert('검색 정보가 없습니다.');
                pageInit();
                return;
            }

            let d0 = r.d0[0];
            setProdUid(d0.prodUid);
            setProdNm(d0.prodNm);
            setProdId(d0.prodId);
            setProdStnd(d0.prodStnd);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
     
    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    
    const handleClickCrtRows = () => {
        //validation
        // if(!util.chkMustValue(prodUid, '제품'))
        //     return;
        // if(!util.chkMustValue(prodProcUid, '공정'))
        //     return;
        // if(!util.chkMustValue(bizUid, '협력사'))
        //     return;
        if(!util.chkMustValue(outAmt, '불출량', 'cnt'))
            return;
            
        const toolOutItems_ = [...toolOutItems];

        toolOutItems_.push(
        {
            toolOutUid: util.getUUID(),
            comUid: authStore.com.uid,
            toolUid: toolUid,
            toolNm: toolNm,
            workNum: workNum,
            prodUid: prodUid,
            prodNm: prodNm,
            userUid: userUid,
            userNm: userNm,
            outAmt: outAmt,
            outDy: outDy,
            cmnt: cmnt,
            uptUserUid: authStore.user.uid,
        });

       // console.log(toolOutItems_);
        setToolOutItems(toolOutItems_);

        pageInit();
    }

    const handleClickDelRows = () => {
        
        if(util.chkListSelected(selectedItem)){
            if(util.confirmYn('삭제'))
            {
                const toolOutItems_ = _.remove(toolOutItems, (v) => {
                    return selectedItem.toolUid !== v.toolUid;
                });
        
                setToolOutItems(toolOutItems_);
            }
        }

    }

    const handleClickComplete = (e) => {
        try {

            if(mode === 'a')
            {
                if(toolOutItems.length <= 0)
                {
                    util.cmnAlert('불출 정보가 없습니다.');
                    return;
                }

                if(util.confirmYn("등록"))
                    addToolOut();
            }else if(mode === 'v')
            {
                delToolOut(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addToolOut = async() => {

        const tools_ = []
        //let groupUid_ = util.getUUID();

        for(let i =0; i < toolOutItems.length; i ++)
        {
            let v = toolOutItems[i];

            tools_.push({
                toolOutUid: v.toolOutUid,
                comUid: authStore.com.uid,
                toolUid: v.toolUid,
                workNum: v.workNum,
                prodUid: v.prodUid,
                userUid: v.userUid,
                outAmt: v.outAmt,
                outDy: util.getDateToString('d', v.outDy),
                cmnt: v.cmnt,
                uptUserUid: authStore.user.uid,
            });

        }

        const ps = {
            p : [...tools_]
        }

        //console.log(ps);
        const r = await cmn.api('addToolOut', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

         // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    
    const delToolOut = async(delCmnt) => {
        if(util.getChkNull(uid) === '') return;

        try 
        {
            const ps =  {
                p: {
                    toolOutUid: uid,
                    comUid: authStore.com.uid,
                    groupUid: '',
                    delCmnt : delCmnt,
                    toolUid: toolUid,
                    outAmt: outAmt,
                    uptUserUid : authStore.user.uid
                }
            }

            console.log(ps)
            let r = await cmn.api('delToolOut', ps);
            if(r == null || r === undefined) return;

            util.cmnSucAlert('취소');

            // Reload Opener List & Close Popup
            util.clickOpenerElement("srchButton");
            window.close();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

    //    console.log(toolDy);
        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "v" ? "취소완료" 
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
            {mode === "v" ?
                <CancelButton
                    title={completeButtonTitle}
                    isDel={isDel}
                    isDelMsg={'재고가 없으므로 취소할 수 없습니다.'}
                    handleClickComplete={(e) => {
                        handleClickComplete(e)
                    }} 
                />
                :
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                    <tr>
                            <th className="tit">작업번호</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <>
                                    <div>
                                        <ul className="ul-row" style={{width: '100%'}}>
                                            <li style={{width: 'calc(100% - 80px)'}}>
                                            <TextBox
                                                width={'100%'}         
                                                value={workNum}
                                                showClearButton={true}
                                                placeholder={'작업번호로 검색하세요.'}
                                                valueChangeEvent={'keyup'}
                                                onValueChanged={(e)=>{
                                                    setWorkNum(e.value);
                                                }} 
                                                onEnterKey={(e)=>{
                                                    loadWorkNum();
                                                }} 
                                            />   
                                            </li>
                                            <li className="fl-r" style={{width: '80px'}}>                        
                                                <SubActionButton 
                                                    title="검색"
                                                    type="1"
                                                    marginRight={0}
                                                    show={true}
                                                    onClick={(e)=>{
                                                        loadWorkNum();
                                                    }} 
                                                />
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    </>
                                :
                                <span>{workNum}</span>
                                }
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>    
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">불출일</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                    <DayDateBox 
                                        value={outDy}
                                        onValueChanged={(e) => {
                                            setOutDy(e.value);
                                        }}                    
                                    />
                                :
                                    <span>{outDy}</span>
                                }
                            </td>
                            <th className="tit">불출자</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        showClearButton={false}
                                        placeholder="불출자를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                :
                                <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">치공구선택</th>
                            <td className="tcon" >
                                { 
                                mode === "a" ?
                                <SelectBox 
                                    placeholder={'치공구를 선택하세요'}
                                    value={toolUid}
                                    dataSource={toolItems}
                                    displayExpr={'toolNm'}
                                    valueExpr={'toolUid'}
                                    
                                    showClearButton={false}
                                    
                                    onValueChanged={(e)=>{
                                        setToolUid(e.value);

                                        let o = _.find(toolItems, {toolUid: e.value});
                                        setToolNm(o.toolNm);
                                        setToolId(o.toolId);
                                        setToolCd(o.toolCd);
                                        setToolGrade(o.toolGrade);
                                        setCornerCnt(o.cornerCnt);
                                        setStockAmt(o.stockAmt);
                                    }} 
                                />
                                :
                                <span>{toolNm}</span>
                                }
                                
                            </td>
                            <th className="tit">등급</th>
                            <td className="tcon">
                                <span>{toolGrade}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">치공구품번</th>
                            <td className="tcon">
                                <span>{toolId}</span>
                            </td>
                            <th className="tit">치공구코드</th>
                            <td className="tcon">
                                <span>{toolCd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">불출량</th>
                            <td className="tcon" >
                                { 
                                mode === "a" ?
                                    <>
                                        <div className="inline-block">
                                            <NumberBox
                                                width={80}
                                                format={'#,##0'}
                                                value={outAmt}
                                                defaultValue={0}
                                                min={0}
                                                max={stockAmt}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setOutAmt(e.value);
                                                }}
                                            />
                                        </div>
                                        <div className="inline-block mar-left-10">
                                            (재고:  <strong>{util.getNumComma(stockAmt)}</strong>) 
                                        </div>
                                    </>
                                   :
                                    <span>{outAmt}</span>
                                }
                            </td>
                            <th className="tit">코너수</th>
                            <td className="tcon">
                                <span>{cornerCnt}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                { 
                                mode === "a" ?
                                    <TextBox 
                                        value={cmnt}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setCmnt(e.value);
                                        }}
                                    /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>

            { 
            mode === "a" ?
                <div className="pop-grid">
                    <div className="clearfix">
                        <div className="btn-area fl-r">
                            <SubActionButton 
                                onClick={handleClickCrtRows}
                                title="추가"
                                show={true}
                            /> 
                            <SubActionButton 
                                onClick={handleClickDelRows}
                                title="삭제"
                                marginRight={0}
                                show={true}
                            /> 
                        </div>
                    </div>
                    <div className="clearfix" style={{marginTop:'10px'}}>
                        <DataGridView
                            onSelectionChanged={handleGridSelectionChanged}     
                            showNo={false}
                            dataSource={toolOutItems}
                            gridHeight={180}
                            colsWidth={[80, 120, 100, 120, 80, 80, null]}  
                        >
                            { cmn.getGridColumn('workNum', '작업번호')}
                            { cmn.getGridColumn('prodNm', '품명')}
                            { cmn.getGridColumn('outDy', '불출일') }
                            { cmn.getGridColumn('toolNm', '치공구명')}
                            { cmn.getGridColumn('userNm', '불출자')}
                            { cmn.getGridColumnNumCommaType('outAmt', '불출량') }
                            { cmn.getGridColumnCmnt() }
                            
                        </DataGridView>
                    </div>
                </div>  
            :
                ''
            }        
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "치공구불출 등록" 
                : mode === "v" ? "불출 상세보기"
                : "불출" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ToolOutPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    UserSelectBox,
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  재공재고 등록 / 수정
 */
const StockDueDiliPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    const authStore = useContext(AuthStore);    
    const cmnStore = useContext(CmnStore); 

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        lt = ls.lot,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [lot, setLot] = useState(util.getChkNull(lt, ''));
    const [chgAmt, setChgAmt] = useState(0);
    const [chgDy, setChgDy] = useState(util.getNow());
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [cmnt, setCmnt] = useState('');
    const [stockAmt, setStockAmt] = useState(0);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        if(util.getChkNull(lot) === '') return;

        try {  
            
            let ps = {p:{lot: lot}};

            const r = await cmn.api('getStockLot', ps);
            if(r == null || r === undefined) return;

            const data = r.d0[0];

            setProdUid(data.prodUid);
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setStockAmt(data.stockAmt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

 
    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = () => {
        try { 
            if(!util.chkMustValue(userUid, '작업자'))
                return;
            if(!util.chkMustValue(chgAmt, '변경수량', 'cnt'))
                return;
            if(!util.chkMustValue(cmnt, '사유'))
                return;
            

            if(stockAmt < chgAmt)
            {
                util.cmnAlert('[재고 수량] 보다 [Loss 수량]이 큽니다.')
                return;
            }
                
            if(util.confirmYn('재고 수정'))
                addStockDueDili();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addStockDueDili = async() => {
        const ps = {
            d1: [
                {
                    comUid: authStore.com.uid,
                    dueDiliUid: util.getUUID(),
                    prodUid: prodUid,
                    lot: lot,
                    dueDiliTp: '70701',
                    chgAmt: chgAmt,
                    chgDy: util.getDateToString('d',chgDy),
                    userUid: userUid,
                    cmnt: cmnt
                }
            ]
        }

        const r = await cmn.api('addStockDueDili', ps);
        if(r == null || r === undefined) return;
        
        util.cmnSucAlert("수정");

        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle =  "수정완료";

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">수정일</th>
                            <td className="tcon">
                            <DayDateBox 
                                value={chgDy}
                                onValueChanged={(e) => {
                                    setChgDy(e.value);
                                }}                    
                            />
                            </td>
                            <th className="tit req">작업자</th>
                            <td className="tcon">
                                <UserSelectBox
                                    mode={mode}
                                    value={userUid}
                                    placeholder="등록자를 선택하세요"
                                    onValueChanged={(v) => { 
                                        setUserUid(v);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">Lot</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">현 재고 수량</th>
                            <td className="tcon">
                                <span>{stockAmt}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">Loss 수량</th>
                            <td className="tcon" colSpan={3}>
                                <NumberBox
                                    width={'100px'}
                                    format={'#,##0.###'}
                                    value={chgAmt}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setChgAmt(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">사유</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {"재고 수정"}
            content = {renderContent()}
        />
        </>
    )
}

export default StockDueDiliPopPage;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, BizSelectBox,
    SrchButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore} from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  고객매츌현황
 */
const DeliRetPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startMon, setStartMon] = useState(Date.now);
    const [endMon, setEndMon] = useState(Date.now);
    const [prodNm, setProdNm] = useState('');
    const [bizUid, setBizUid] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "회계월",
                width: "200px",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setStartMon(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setEndMon(e.value);
                            }}                    
                        />
                    </li>
                </ul>
                </>
            },
            {   title: "고객사",
                width: "200px",
                item: 
                <BizSelectBox
                    value={bizUid}
                    bizTp={[cmn.CMNCO_BIZ.CUST]}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        // console.log("--- BizSelectBox v", v);
                        setBizUid(v);
                    }}
                />     
            },
            {   title: "품명",
                item: 
                <TextBox 
                    width="200px"
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            }
        ]
    ];
    /* #endregion */
    
    // * 목록 선택 코드 UID
    const [deliUid, setDeliUid] = useState('');

    
    // * Grid
    const [deliListRet, setDeliListRet] = useState([]);
    const [deliListRetTot, setDeliListRetTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, bizUid]);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        if(util.getChkNull(bizUid) === '')
            return;
        
        const ps =  {
            p: {
                startMon: util.getDateToString('M', startMon), 
                endMon: util.getDateToString('M', endMon), 
                bizUid: bizUid, 
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getDeliListRet", ps );
        if(r === undefined || r == null) return;

        setDeliListRet(r.d0)
        //setDeliListRetTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "deliDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    
    
    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setDeliUid(""); return;
        }
        setDeliUid(selectedRowsData[0].deliUid);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                    marginRight={0}
                />
            </ListTopView>
            }</Observer>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="고객매출현황"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>

            {/** GRID */}
            <Observer>{() =>
            <>        
            <DataGridView        
                reff={grid1}    
                dataSource={deliListRet}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H20}

                // paging
                pagingable={false}

                // sort
                onCellClick={handleGridCellClick}

                // excel
                exlnm={'고객매출현황'}
                customizeExcelCell={customizeExcelCell} 

                // select
                onSelectionChanged={handleGridSelectionChanged}      
                colsWidth={[cmn.CW.bizNm,  cmn.CW.dy, cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    ,cmn.CW.amt, 110, 110, null]}
            >
                { cmn.getGridColumn('bizNm', '고객', renderGridHeaderCell)}
                {/* { cmn.getGridColumn('salesNum', '수주번호') }
                { cmn.getGridColumn('deliNum', '납품번호') } */}
                { cmn.getGridColumnDayType('deliDy', '납품일') }
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('deliAmt', '납품수량') }
                { cmn.getGridColumnNumCommaType('deliPrice', '납품단가') }
                { cmn.getGridColumnNumCommaType('totPrice', '납품금액') }
                <Column />
                
                <Summary>
                    <TotalItem
                        column={'totPrice'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'deliAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                </Summary>
            </DataGridView>
            </>
            }</Observer>
            </>
        )

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default DeliRetPage;
import React, { useState, useEffect, useContext } from 'react';
import { SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    PopupLayout, 
    ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  사용자 등록 / 수정
 */
const UserInfoPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */    
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [defRow, setDefRow] = useState(10);
    const [userId, setUserId] = useState('');

    const [oldPw, setOldPw] = useState('');
    const [newPw, setNewPw] = useState('');
    const [newPwConfirm, setNewPwConfirm] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadInfo1 = async() => {
        try {     
            const ps = {p: {userUid: authStore.user.uid}} 
            const r = await cmn.api("getUser", ps);

            const data = r.d0[0];            
            setDefRow(data.defRow);
            setUserId(data.userId);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }

    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickUpPw = async (e) => {
        try {
            //validation 체크
            if(!util.chkMustValue(oldPw, '기존 비밀번호'))
                return;
            if(!util.chkMustValue(newPw, '신규 비밀번호'))
                return;
            
            if(authStore.com.isPwPolicy){
                if(!util.getPwChk(newPw)){
                    util.cmnAlert("비밀번호는 영문대소문자, 특수문자를 포함하여 8~20자리로 입력해야 합니다.");
                    return;
                }
            }
            if(!util.chkMustValue(newPwConfirm, '신규 비밀번호 확인'))
                return;
            
            if(newPwConfirm !== newPw)
            {
                util.cmnAlert('신규 비밀번호 확인이 같지 않습니다.');
                return;
            }

            let para = {
                p:{
                    comUid: authStore.com.uid
                    ,userId: userId
                    ,userPw: oldPw
                }
            }

            const r = await cmn.api("getUserLogIn", para);
            if(r == null) return;

            const d0 = r.d0[0];

            let salt = d0.salt;
            let key = d0.userPw;

            let confirmPw = util.pwCrypto(oldPw, salt);

            if(key !== confirmPw)
            {
                util.cmnAlert('기존 비밀번호를 잘못 입력하셨습니다.');
                return;
            }
            
            if(util.confirmYn('수정'))   
                upUserPw();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const upUserPw = async() => {
        let pwInfo = util.pwInit(newPw);

        let ps = 
            {
                p: {
                    comUid: authStore.com.uid
                    ,userUid: authStore.user.uid
                    ,userPw: pwInfo.retKey
                    ,salt: pwInfo.retBuf
                    ,uptUserUid: authStore.user.uid
                }
            }

        let r = await cmn.api('upUserPw', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert('수정');

        window.close();
    }

    const handleClickUpUser = async (e) => {
        try {
            //validation 체크
            if(util.confirmYn('수정'))   
                upUserInfo();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const upUserInfo = async() => {

        const ps = {
            p: 
                {
                    userUid: authStore.user.uid,
                    defRow: defRow,
                    uptUserUid: authStore.user.uid
                }
        }

        let r = await cmn.api('upUserInfo', ps);
        if(r == null || r === undefined) return;

        cmn.setUser(
            {uid: authStore.user.uid, id: authStore.user.id, name: authStore.user.name, rows: defRow}
        );
        
        util.cmnSucAlert('수정');

        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    width={'140px'}
                    onClick={handleClickUpPw}
                    title={'비밀번호 변경'}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit150"/>
						<col className="tcon" />
                
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit150 req">기존 비밀번호</th>
                            <td className="tcon" >
                                <TextBox 
                                    mode={'password'}
                                    value={oldPw}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setOldPw(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit150 req">신규 비밀번호</th>
                            <td className="tcon">
                                <TextBox 
                                    mode={'password'}
                                    value={newPw}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setNewPw(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit150 req">신규 비밀번호 확인</th>
                            <td className="tcon">
                                <TextBox 
                                    mode={'password'}
                                    value={newPwConfirm}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setNewPwConfirm(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {authStore.com.isPwPolicy &&
                <div className="clearfix">
                    <div className="fl-l">
                        <ul>
                            <li>
                                <span style={{color:'blue', fontWeight:'bold'}}> * 비밀번호는 3개월마다 변경해야 합니다.</span>
                            </li>
                            <li>
                                <span style={{color:'red', fontWeight:'bold'}}> * 비밀번호 규칙</span>
                            </li>
                            <li>
                            <span style={{color:'red', fontWeight:'bold'}}> - 영문대/소문자, 숫자, 특수문자를 포함하고 8자 이상 20자 이내.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            <div className="btn_r mgb10" style={{marginTop:'20px'}}>
                <ActionButton 
                    width={'130px'}
                    onClick={handleClickUpUser}
                    title={'설정 변경'}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit150"/>
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit150">기본 목록 수</th>
                            <td className="tcon" >
                                <SelectBox 
                                    width={60}
                                    value={defRow}
                                    displayExpr={'name'}
                                    valueExpr={'value'}
                                    items={[ 
                                        { name: "10", value: 10 }, 
                                        { name: "20", value: 20 }, 
                                        { name: "30", value: 30 }, 
                                        { name: "50", value: 50 },
                                        { name: "100", value: 100 }, 
                                        { name: "전체", value: -1 }
                                    ]}
                                    // showClearButton={true} 
                                    onValueChanged={(e)=>{
                                        setDefRow(e.value);
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
            
        )
    }

    return(
        <>
        <PopupLayout
            title = {
               "사용자 정보 수정" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default UserInfoPopPage;
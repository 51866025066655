import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';

/** components */

/** stores & lib */
import { AuthStore } from '../stores';
import { util } from '../lib';

/** images */

/** 
 *  오류 페이지
 */
const ErrorPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const { history, location } = props

    /* ====================
     *  State / Effect
     * ==================== */

    const [pageLoad, setPageLoad] = useState(true);

    const msgRef = useRef(null);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
        console.log("=== error page props", props);
        setPageLoad(false);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */


    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        let message = util.getChkNull(location.state) === "" ? "" : location.state.message;
        message = _.replace(message, /\r\n/g, "<br />");

        if(msgRef.current !== null){
            // console.log("=== msgRef", msgRef);
            msgRef.current.innerHTML = message;
        }      
        
        return (
            <>
            {/* <div>{authStore.err.message}</div>
            <button
                onClick={(e) => {
                    authStore.history.goBack();
                }}
            >이전페이지</button> */}
            <div className="errorWrap">
                <div className="error">
                    <div className="error_inner">
                        <div className="error_txt">
                            <p className="tit">죄송합니다.</p>
                            {/* <p className="culm">페이지를 찾을 수 없습니다.</p> */}
                            <p ref={msgRef} className="culm"></p>
                        </div>
                        <div className="error_btn">
                            <button className="btn btn-gsz"
                                onClick={(e) => {
                                    //alert("aaa");
                                    history.goBack();
                                }}
                            >이전페이지로 돌아가기</button>
                        </div>
                    </div>
                </div>
		    </div>
            </>  
        )
    }

    return (
        <>
        { renderContent() }
        </>
    )
}

export default ErrorPage;
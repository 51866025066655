import React, { useState, useEffect, useContext, useRef } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, DayDateBox, CodeSelectBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  부적합 개선 
 */
const BadImprPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate(-7));
    const [endDy, setEndDy] = useState(util.getNow());    
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [badImprTp, setBadImprTp] = useState('');
    const [compYn, setCompYn] = useState(''); // 완료여부

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "발생일",
                width: "180px",
                item: 
                    <>
                    <ul className="ul-row">
                        <li>
                            <DayDateBox 
                                value={startDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setStartDy(e.value);
                                }}                    
                            />
                        </li>
                        <li>
                            ~
                        </li>
                        <li>            
                            <DayDateBox 
                                value={endDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setEndDy(e.value);
                                }}                    
                            />
                        </li>
                    </ul>
                    </>
            },
            {   title: "품명",
                width: "160px",
                item: 
                <TextBox 
                    value={prodNm}
                    // width={'160px'}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",
                width: "160px",
                item: 
                <>
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />     
                </>           
            }, 
            {   
                title: "구분",
                width: "148px",
                item: 
                <CodeSelectBox
                    value={badImprTp}
                    classId={'307'}
                    placeholder="구분을 선택하세요."
                    onValueChanged={(v, o) => { 
                        setBadImprTp(v);
                    }}
                />
            },           
            {   title: "완료여부",
                item: 
                <SelectBox 
                    width={'120px'}
                    value={compYn}      
                    displayExpr={'name'}
                    valueExpr={'value'}                      
                    items={[ 
                        { name: "완료", value: "Y" }, 
                        { name: "미완료", value: "N" } 
                    ]}
                    placeholder="전체"
                    showClearButton={true}
                    onValueChanged={(e)=>{
                        setCompYn(e.value);                        
                    }}
                />   
            } 
        ]
    ];
    /* #endregion */

    // * Grid
    const [badImprList, setBadImprList] = useState([]);
    const [badImprListTot, setBadImprListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, badImprTp, compYn]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodId: prodId,
                prodNm: prodNm,
                badImprTp: badImprTp,
                compYn: compYn,
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),

                offset: rowsOffset,
                limit: rowsLength,
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
            }
        }

        // console.log("===== para", para);
        const r = await cmn.api("getBadImprList", ps );
        if(r === undefined || r == null) return;

        setBadImprList(r.d0)
        setBadImprListTot(r.tot);
        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // const handleClickBadTreat = (e) => {
    //     if(!util.chkListSelected(selectedItem, '불량처리 대상을')) return;
    //     openCrudPopup("u", selectedItem.procInspHisUid);
    // }

    const handleClickAdd = (e) => {
        openCrudPopup("a");
    }

    const handleClickUp = (e) => {
        if(!util.chkListSelected(selectedItem,'부적합 개선 항목을')) return;
        openCrudPopup("u", selectedItem.badImprUid);
    }

    const handleClickImprRepo = (e) => {
        if(!util.chkListSelected(selectedItem, '부적합 개선 항목을')) return;
        openImprRepoPopup(selectedItem.badImprUid);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType != "data") return;
        if(gc.column.dataField == "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField == "happDy"             
            ) e.value =util.getDateStrSplit('d',gc.value);
        else if(     gc.column.dataField == "compYn" 
            ) e.value = gc.value == 'Y' ? "○" : "";            
        else if(gc.column.dataField == "manuAmt" 
            ||  gc.column.dataField == "badAmt" 
            ||  gc.column.dataField == "claimCost" 
            ) e.value =util.getNumComma(gc.value);
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, uid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/qual/badimprpop?${qs.stringify({mode: mode, uid: uid})}`;        
        util.openWindow(url, 1180, 720);    
    }

    const openImprRepoPopup = (uid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/qual/badimprrepo?${qs.stringify({uid: uid})}`;        
        util.openWindow(url, 820, 840);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"                    
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    width={150}
                    onClick={handleClickImprRepo}
                    title="부적합 개선카드"
                    type="2"
                    show={authStore.page.viewYn}
                />
            </ListTopView>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="부적합 개선 내역"
            >
                {/* <IconButton
                    className="btn btn-isz ico-print"
                /> */}
               <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>
            <DataGridView
                reff={grid1}
                dataSource={badImprList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[ 80, cmn.CW.dy, 120, 200, 
                    cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd,                    
                    cmn.CW.procNm, cmn.CW.bizNm, 100, 100,
                    cmn.CW.lot, cmn.CW.amt, cmn.CW.amt,
                    cmn.CW.yn, cmn.CW.amt, 200, null
                ]}
                // paging
                page={curPage}
                total={badImprListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}
                // excel
                exlnm={'부적합개선내역'}
                customizeExcelCell={customizeExcelCell}                   
            >
                { cmn.getGridColumn('badImprTpNm', '구분', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('happDy', '발생일자', renderGridHeaderCell) }
                { cmn.getGridColumn('badImprCd', '부적합코드', renderGridHeaderCell ) }
                { cmn.getGridColumn('badImprTitle', '부적합제목', renderGridHeaderCell ) }

                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell ) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell ) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell ) }

                { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell ) }          
                { cmn.getGridColumn('bizNm', '업체', renderGridHeaderCell ) }
                { cmn.getGridColumn('badTpNm', '부적합유형', renderGridHeaderCell ) }
                { cmn.getGridColumn('badCauseTpNm', '부적합원인', renderGridHeaderCell ) }

                { cmn.getGridColumn('lot', 'Lot', renderGridHeaderCell ) }
                { cmn.getGridColumnNumCommaType('manuAmt', '생산수량', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('badAmt', '불량수량', renderGridHeaderCell) }

                { cmn.getEditGridColumnUseYn('compYn', '완료', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('claimCost', '클레임비용', renderGridHeaderCell) }
                { cmn.getGridColumn('cmnt', '비고', renderGridHeaderCell) }   
                <Column />
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default BadImprPage;
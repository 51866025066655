import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    IconButton,
    SubActionButton,
    DataGridView,
    UserSelectBox ,
    GridTopView,
    EditGridView,
    CancelButton
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  발주 등록 / 수정
 */
const OutPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);    

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
        wn = ls.wn
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);

    const [outItems, setOutItems] = useState([]);
    const [prodBomItems, setProdBomItems] = useState([]);
    /** Items */
    const [workNum, setWorkNum] = useState(util.getChkNull(wn));
    const [outDy, setOutDy] = useState(util.getNow());
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [lot, setLot] = useState('');
    const [prodUid, setProdUid] = useState('');
    const [recallCmnt, setRecallCmnt] = useState('');
    const [recallUserUid, setRecallUserUid] = useState(authStore.user.uid);
    const [recallUserNm, setRecallUserNm] = useState(authStore.user.uid);
    const [recallDy, setRecallDy] = useState(util.getNow());

    const [prodNm, setProdNm] = useState('');
    const [workAmt, setWorkAmt] = useState('');
 
    const [outProdUid, setOutProdUid] = useState('');
    const [outProdId, setOutProdId] = useState('');
    const [outProdNm, setOutProdNm] = useState('');
    const [outLot, setOutLot] = useState('');
    const [outAmt, setOutAmt] = useState(0);
    const [outUserNm, setOutUserNm] = useState('');
    const [cmnt, setCmnt] = useState('');
    const [outStatTp, setOutStatTp] = useState('');
    

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadProdBomItem();
    },[prodUid, workAmt]);
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadInfo1 = () => {
        try {
            if(mode !== 'a')
            {
                loadOutItem();
            }
            loadWorkNum();

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    //상세보기 
    //제품의 등록된 출고정보를 조회함.
    const loadOutItem = async() => {
        //if(util.getChkNull(wn) === '') return;
        if(util.getChkNull(uid) === '') return;

        let r = await cmn.api('getOut', {p:{outUid: uid}});
        if(r.d0.length === 0) return;

        let d = r.d0[0];

        setOutProdUid(d.prodUid);
        setOutProdId(d.prodId);
        setOutProdNm(d.prodNm);
        setOutLot(d.lot);
        setOutAmt(d.outAmt);
        setOutUserNm(d.userNm);
        setOutStatTp(d.outStatTp);

        setOutDy(util.getDateStrSplit('d', d.outDy));
        setCmnt(d.cmnt);
        setRecallDy(util.getDateStrSplit('d', d.recallDy));
        setRecallUserNm(d.recallUserNm);
        setRecallCmnt(d.recallCmnt);
        // let outItems_ = [];
        // _.forEach(data, (v,k) => {
        //     outItems_.push({
        //         outUid: v.outUid,
        //         comUid: v.comUid,
        //         workNum: v.workNum,
        //         outDy: v.outDy,
        //         userUid: v.userUid,
        //         lot: v.lot,
        //         prodUid: v.prodUid,
        //         prodNm: v.prodNm,
        //         outStatTp: v.outStatTp,
        //         storUid: v.storUid,
        //         outAmt: v.outAmt,
        //     });
        // })
    
        // console.log(outItems_);
        // setOutItems(outItems_);
    }

    // 등록
    // 제품의 BOM 정보를 Load
    const loadProdBomItem = async() => {
        if(util.getChkNull(prodUid, '') === '') return;
        if(util.getChkNull(workAmt, 0) === 0) return;

        let r = await cmn.api('getBomProd', {p:{prodUid: prodUid, workNum:workNum}});
        
        let data = r.d0;

        let prodBomItems_ = [];

        _.forEach(data, (v,k) => {
            prodBomItems_.push({
                prodUid: v.prodUid,
                prodNm: v.prodNm,
                prodId: v.prodId,
                putAmt: util.fix(v.putAmt * workAmt),
                outAmt: util.fix(v.outAmt),
                possAmt: util.fix(v.putAmt * workAmt - v.outAmt < 0 ? 0 : v.putAmt * workAmt - v.outAmt),
                inspChkYn: v.inspChkYn,
                amt: 0
            });
        })
    

        // 투입제품의 Lot를 조회.
        let r2 = await cmn.api('getBomManu', {p:{prodUid: prodUid}});
        let data2 = r2.d0;
        let outItems_ = [];
        //먼저 등록한 같은 제품의 수량을 제한다.
        _.forEach(data2, (v,k) => {
            let prod_ = _.find(prodBomItems_, {prodUid: v.prodUid});

            let preProdAmt_ = 0;
            let outAmt_ = 0;
            _.forEach(outItems_, (v2,k2) => {
                if(v2.prodUid === v.prodUid)
                    preProdAmt_ = preProdAmt_ + v2.outAmt;
            });
    
            let possAmt_ = prod_.possAmt - preProdAmt_ > 0 ? prod_.possAmt - preProdAmt_ : 0;
            outAmt_ = possAmt_ <= v.stockAmt ? possAmt_ : v.stockAmt;

            outItems_.push({
                outUid: util.getUUID(),
                comUid: authStore.com.uid,
                workNum: workNum,
                outDy: outDy,
                userUid: userUid,
                lot: v.lot,
                prodUid: v.prodUid,
                prodNm: v.prodNm,
                outStatTp: '30801', 
                stockAmt: v.stockAmt,
                outAmt: prod_.inspChkYn === 'Y' && v.passYn === 'N' && v.materialStatus !== 'stock' ? 0 : outAmt_,
                passYn: v.passYn,
                inspChkYn: prod_.inspChkYn,
                materialStatus: v.materialStatus,
                cmnt: v.materialStatus === 'stock' ? '재고' : '',
            });
        });

        
        _.forEach(prodBomItems_, (v,k) => {
            v.amt = _.sumBy(outItems_, (o) => { return o.prodUid === v.prodUid ? o.outAmt : 0; });
        })
        
        console.log(prodBomItems_);
        setProdBomItems(prodBomItems_);
        setOutItems(outItems_);
    }
  
    const prodBomItemsAmt = (outItems_) => {
        _.forEach(prodBomItems, (v,k) => {
            v.amt = _.sumBy(outItems_, (o) => { return o.prodUid === v.prodUid ? o.outAmt : 0; });
        })

        setProdBomItems(prodBomItems);
    }

    //
    // 작업번호 정보
    const loadWorkNum = async() => {
        try {
            if(util.getChkNull(workNum) === '') return;
            
            let para = {p: {workNum: workNum}};

            let r = await cmn.api('getWorkNum', para);
            if(r == null || r.d0.length == 0) {
                util.cmnAlert('등록된 정보가 없습니다.');
                return;
            }

            let data = r.d0[0];
            setProdUid(data.prodUid);
            setProdNm(data.prodNm);
            setWorkAmt(data.firstAmt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    // 등록완료 클릭
    const handleClickComplete = async(e) => {

        // let r = null;
        // let v = null;
        // let para = {};
        
        // //선입선출
        // for(let i = 0; i < outItems.length; i++)
        // {
        //     v = outItems[i];
        //     para = {
        //         p:{
        //             lot:v.lot,
        //             prodUid:v.prodUid
        //         }
        //     }
        //     r = await cmn.api('getStockFifl', para);
        //     console.log(r);
        //     if(!(r == null || r == undefined || r.d0.length == 0))
        //     {
        //         let lot_ = r.d0[0].lot;
        //         let stockAmt_ = r.d0[0].stockAmt;

        //          let fiflAmt_ = 0;
        //          _.forEach(outItems, (v,k) =>{
        //             if(v.lot == lot_)
        //                 fiflAmt_ = fiflAmt_ + v.stockAmt;
        //          });
                   
        //         alert('lot_:' + lot_ + 'stockAmt_:' + stockAmt_ + ', fiflAmt_:' + fiflAmt_);
        //         if(fiflAmt_ < stockAmt_)
        //         {
        //             if(!util.cmnConfirm(`Lot : [${lot_}] 제품의 재고가 남아 있으므로 선입선출에 위배됩니다. \n계속 진행하시겠습니까?`))
        //                 return;
        //         }
        //     }
        // }
        // alert(_.sumBy(outItems, 'outAmt'));
        // alert(_.sumBy(prodBomItems, 'possAmt'));
        // console.log(prodBomItems);
        if(_.sumBy(outItems, 'outAmt') !== _.sumBy(prodBomItems, 'possAmt'))
        {
            if(!util.cmnConfirm('제품 투입수량이 필요수량보다 많거나 적습니다.\n계속 진행하시겠습니까?'))
                return;
        }

        try {
            if(util.confirmYn("등록"))
            {
                addOut();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // Lot 추가 클릭
    const handleClickCrtRows = async() => {
        //validation
        if(prodBomItems.length <= 0)
        {
            util.cmnAlert('제품 BOM 정보가 없습니다.');
            return;
        }
        if(!util.chkMustValue(lot, 'Lot'))
            return;
       
        // Lot가 등록되어있는지 확인
        let lot_ = _.find(outItems, {lot: lot});
        if(util.getChkNull(lot_, '') != '')
        {
            util.cmnAlert('이미 등록된 Lot 입니다.');
            return;
        }

        //lot로 재고 정보 조회
        let r = await cmn.api('getStockLot', {p:{lot:lot}});
        if(r == null || r.d0.length == 0) return;

        let data = r.d0[0];

        const outItems_ = [...outItems];

        let outAmt_ = 0;
        if(data.stockAmt <= 0 )
        {
            util.cmnAlert('출고 가능한 재고가 없습니다.');
            return;
        }
        
        let prod_ = _.find(prodBomItems, {prodUid: data.prodUid});
        if(util.getChkNull(prod_, '') == '')
        {
            util.cmnAlert('BOM 정보의 제품만 출고 가능합니다.');
            return;
        }

        //먼저 등록한 같은 제품의 수량을 제한다.
        let preProdAmt_ = 0;
        _.forEach(outItems_, (v,k) => {
            if(v.prodUid == data.prodUid)
                preProdAmt_ = preProdAmt_ + v.outAmt;
        });

        let possAmt_ = util.fix(prod_.possAmt - preProdAmt_ > 0 ? prod_.possAmt - preProdAmt_ : 0);
        outAmt_ = possAmt_ <= data.stockAmt ? possAmt_ : data.stockAmt;

        outItems_.push(
        {
            outUid: util.getUUID(),
            comUid: authStore.com.uid,
            workNum: workNum,
            outDy: outDy,
            userUid: userUid,
            lot: lot,
            prodUid: data.prodUid,
            prodNm: data.prodNm,
            outStatTp: '30801', //출고
            stockAmt: data.stockAmt,
            outAmt: outAmt_,
            cmnt: '',
        });

        // console.log(orderItems_);
        let prodBomItems_ = [...prodBomItems]
        _.forEach(prodBomItems_, (v,k) => {
            v.amt = _.sumBy(outItems_, (o) => { return o.prodUid === v.prodUid ? o.outAmt : 0; });
         })
        setProdBomItems(prodBomItems_);
        setOutItems(outItems_);
        setLot('');
        // pageInit();
    }

    // 전체 회수 클릭
    // const handleClickRecallAll = async() => {
    //     if(!util.chkMustValue(recallCmnt, '회수 사유'))
    //         return;

    //     try {
    //         if(util.confirmYn("전체회수"))
    //         {
    //             OutRecall('all');
    //         }
    //     }
    //     catch(err){
    //         cmnStore.handleErrorPage(err, props);
    //     }
    // }

    const handleClickRecall = async(v) => {
        
        try {
            if(util.confirmYn("회수"))
            {
                OutRecall(v);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    

    // 등록함수
    const addOut = async() => {

        let outs_ = [];

        _.forEach(outItems, (v,k) => {
            if(v.outAmt > 0)
            {
                outs_.push({
                    outUid: v.outUid,
                    comUid: authStore.com.uid,
                    workNum: workNum,
                    outDy: util.getDateToString('d', outDy),
                    userUid: userUid,
                    lot: v.lot,
                    prodUid: v.prodUid,
                    outStatTp: v.outStatTp,
                    outAmt: v.outAmt,
                    cmnt: v.cmnt,
                    uptUserUid: authStore.user.uid
                });
            }
        });
      
        const ps = {
            p : [...outs_]
        }

      //  console.log("===== para", ps);

        const r = await cmn.api('addOut', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

         // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }
    
    // 전체 회수 함수
    const OutRecall = async(recallCmnt) => {

        // let outItems_ = [];
        // if(uid === 'all')
        //     outItems_ = [...outItems];
        // else
        //     outItems_[0] = _.find(outItems, {outUid: uid});

        // let outs_ = [];

        // console.log(outItems_);
        // _.forEach(outItems_, (v,k) => {
        //     outs_.push(
        //         {
        //             outUid: v.outUid,
        //             comUid: authStore.com.uid,
        //             workNum: v.workNum,
        //             userUid: v.userUid,
        //             lot: v.lot,
        //             prodUid: v.prodUid,
        //             prodNm: v.prodNm,
        //             stockAmt: v.stockAmt,
        //             outAmt: v.outAmt,
        //             //cmnt: v.cmnt,
        //             outStatTp: '30802',
        //             recallDy: util.getDateToString('d', util.getNow()),
        //             recallCmnt: recallCmnt,
        //             recallUserUid: authStore.user.uid,
        //             uptUserUid: authStore.user.uid
        //         });
        // });
      
        const ps = {
            p : {
                outUid: uid,
                comUid: authStore.com.uid,
                lot: outLot,
                prodUid: outProdUid,
                outAmt: outAmt,
                outStatTp: '30802',
                recallDy: util.getDateToString('d', util.getNow()),
                recallCmnt: recallCmnt,
                recallUserUid: authStore.user.uid,
                uptUserUid: authStore.user.uid
            }
        }

        console.log("===== para", ps);

        const r = await cmn.api('upOutRecall', ps);
        if(r == null || r === undefined) return;

        // if(uid === 'all')
        //     util.cmnSucAlert("전체회수");
        // else
        //     util.cmnSucAlert("부분회수");
        
        util.cmnSucAlert("회수");

         // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // //datagrid
    // const handleGridSelectionChanged = ({selectedRowsData}) => {
    //     if(selectedRowsData.length === 0) { 
    //         setSelectedItem(null); return;
    //     }
    //     setSelectedItem(selectedRowsData[0]);
    // }

    /* ====================
     *  Render
     * ==================== */
    const renderContent = () => {
        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : "" ;
        return(
            <>
            <div className="btn_r mgb10">
            {
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon w30p" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">출고일자</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={outDy}
                                    onValueChanged={(e) => {
                                        setOutDy(e.value);
                                    }}                    
                                />
                            </td>
                            <th className="tit">출고자</th>
                            <td className="tcon">
                                <UserSelectBox
                                    mode={mode}
                                    value={userUid}
                                    placeholder="출고자를 선택하세요"
                                    showClearButton={false}
                                    onValueChanged={(v) => { 
                                        setUserUid(v);
                                    }}
                                />                               
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">작업번호</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" ?
                                    <>
                                    <div>
                                        <ul className="ul-row" style={{width: '100%'}}>
                                            <li style={{width: 'calc(100% - 80px)'}}>
                                            <TextBox
                                                width={'100%'}         
                                                value={workNum}
                                                showClearButton={true}
                                                placeholder={'작업번호로 검색하세요.'}
                                                valueChangeEvent={'keyup'}
                                                onValueChanged={(e)=>{
                                                    setWorkNum(e.value);
                                                }} 
                                                onEnterKey={(e)=>{
                                                    loadWorkNum();
                                                }} 
                                            />   
                                            </li>
                                            <li className="fl-r" style={{width: '80px'}}>                        
                                                <SubActionButton 
                                                    title="검색"
                                                    type="1"
                                                    marginRight={0}
                                                    show={true}
                                                    onClick={(e)=>{
                                                        loadWorkNum();
                                                    }} 
                                                />
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    </>
                                    :
                                    <span>{workNum}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제품명</th>
                            <td className="tcon">
                                {prodNm}
                            </td>
                            <th className="tit">생산수량</th>
                            <td className="tcon">
                                {workAmt}
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
                <GridTopView title="제품 BOM 정보">
                </GridTopView>
                <DataGridView
                    showNo={false}
                    dataSource={prodBomItems}
                    gridHeight={185}
                    colsWidth={[null, 80, 80, 80]}  
                >
                    { cmn.getGridColumn('prodNm', '제품명')}
                    { cmn.getGridColumnNumCommaType('putAmt', '필요수량') }
                    { cmn.getGridColumnNumCommaType('outAmt', '기투입수량') }
                    { cmn.getGridColumnNumCommaType('amt', '투입수량') }
                </DataGridView>

                <GridTopView title="투입 Lot" style={{marginTop:15}}>
                {/* <ul className="ul-row">
                    <li>
                        <TextBox
                            width={'100%'}         
                            value={lot}
                            showClearButton={true}
                            placeholder={'Lot로 검색하세요.'}
                            valueChangeEvent={'keyup'}
                            onValueChanged={(e)=>{
                                setLot(e.value);
                            }} 
                            onEnterKey={(e)=>{
                                handleClickCrtRows();
                            }} 
                        />   
                    </li>
                    <li>
                        <SubActionButton 
                            onClick={handleClickCrtRows}
                            title="조회"
                            marginRight={0}
                            show={true}
                        /> 
                    </li>
                </ul>  */}
            </GridTopView>
             
            <div className="clearfix">
                <EditGridView
                    showNo={false}
                    dataSource={outItems}
                    gridHeight={185}
                    colsWidth={[ 80, 80, 120, null, 70, 150]}  
                >
                    
                    <Column 
                        alignment={'center'}
                        dataField={'stockAmt'}
                        caption={'재고'}  
                        sortable={false}
                        allowEditing={false}
                        cellRender={(d) => {
                            return <span>{util.getNumComma(d.value)}</span>;
                        }}
                    />
                    <Column 
                        alignment={'center'}
                        dataField={'outAmt'}
                        caption={'투입수량'}  
                        sortable={false}
                        cellRender={(d) => {
                            return <span>{util.getNumComma(d.value)}</span>;
                        }}
                        editCellRender={(d) => {     
                            return (
                                <>
                                    {
                                        <NumberBox
                                            width={'100%'}
                                            format={'#,##0.###'}
                                            min={0}
                                            value={d.data.outAmt}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                const items_ = [...outItems];
                                                let stockAmt_ = items_[d.rowIndex].stockAmt;
                                                const item_ = items_[d.rowIndex];
                                                const checked = item_.inspChkYn === 'Y' && item_.passYn === 'N' && item_.materialStatus !== 'stock'
                                                if(checked) return util.cmnAlert('해당 로트 수입검사를 진행해주세요.');

                                                if(stockAmt_ < e.value)
                                                {
                                                    util.cmnAlert('재고량보다 투입량이 많습니다.');
                                                    items_[d.rowIndex].outAmt = 0;
                                                    prodBomItemsAmt(items_);
                                                    setOutItems(items_);
                                                }else{
                                                    items_[d.rowIndex].outAmt = e.value;
                                                    prodBomItemsAmt(items_);
                                                    setOutItems(items_);
                                                }
                                            }}
                                        />
                                    }
                                </>
                            )
                        }}
                    />
                  
                    <Column 
                        alignment={'center'}
                        dataField={'lot'} 
                        caption={'Lot'} 
                        allowEditing={false}
                    />
                    <Column 
                        alignment={'center'}
                        dataField={'prodNm'} 
                        caption={'제품명'} 
                        allowEditing={false}
                    />
                    { cmn.getGridColumnUseYn('passYn', '수입검사') }
                    <Column 
                        alignment={'center'}
                        dataField={'cmnt'} 
                        caption={'비고'} 
                    />
                      {/* <Column 
                            alignment={'center'}
                            dataField={'outUid'} 
                            caption={'삭제'}
                            allowEditing={false} 
                            cellRender={(d) => {
                                return (
                                    <IconButton
                                        className="btn-icon-close-1"
                                        onClick={(e) => {
                                            const items2_ = [...outItems];
                                            
                                            items2_[d.rowIndex].outAmt = 0;
                                            prodBomItemsAmt(items2_);
                                            
                                            const items_ = _.remove(outItems, (v) => {
                                                return d.value != v.outUid;
                                            });
                                            setOutItems(items_);
                                        }}
                                        marginRight={0}
                                    />
                                )
                            }}
                        /> */}
                </EditGridView>
            </div>
            </>
        )
    }

    const renderViewContent = () => {
        return(
            <>
            <div className="btn_r mgb10">
            { 
                outStatTp === '30801' ? 
                <>
                 <CancelButton
                    title={'회수'}
                    isDel={'Y'}
                    titleMsg={'회수하시겠습니까?'}
                    placeholder={'회수사유를 입력하세요'}
                    handleClickComplete={(e) => {
                        //setRecallCmnt(e);
                        handleClickRecall(e)
                    }} 
                />
                {/* <ActionButton 
                    onClick={handleClickRecall}
                    title={"회수"}
                    marginRight={0}
                    show={true}
                /> */}
                </>
                : ''
            }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon w30p" />
						<col className="tit" />
						<col className="tcon w30p" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">작업번호</th>
                            <td className="tcon" colSpan={3}>
                                <span>{workNum}</span>
                            </td>
                            
                        </tr>
                        <tr>
                            <th className="tit">제품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">생산수량</th>
                            <td className="tcon">
                                <span>{workAmt}</span>
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon w30p" />
						<col className="tit" />
						<col className="tcon w30p" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">출고 Lot</th>
                            <td className="tcon">
                                <span>{outLot}</span>
                            </td>
                            <th className="tit">출고일</th>
                            <td className="tcon">
                                <span>{outDy}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">출고품번</th>
                            <td className="tcon">
                                {outProdId}
                            </td>
                            <th className="tit">출고품명</th>
                            <td className="tcon">
                                {outProdNm}              
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">출고수량</th>
                            <td className="tcon">
                                {outAmt}              
                            </td>
                            <th className="tit">출고자</th>
                            <td className="tcon">
                                {outUserNm}                           
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {cmnt}              
                            </td>
                        </tr>
                            {
                                outStatTp === '30802' ? 
                                <>
                                <tr>
                                    <th className="tit">회수자</th>
                                    <td className="tcon" >
                                        {recallUserNm}              
                                    </td> 
                                    <th className="tit">회수일자</th>
                                    <td className="tcon" >
                                        {recallDy}              
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">회수사유</th>
                                    <td className="tcon" colSpan={3}>
                                        {recallCmnt}              
                                    </td>
                                </tr>
                                </>
                                : ''
                            }
                    </tbody>
                </table>

            </div>

            {/* <GridTopView title="투입제품 정보">
            </GridTopView>
            <DataGridView
                showNo={false}
                dataSource={outItems}
                colsWidth={[ 120, 120, null, 80]}  
                // select
                onSelectionChanged={handleGridSelectionChanged}   
            >
                { cmn.getGridColumnDayType('outDy', '출고일자')}
                { cmn.getGridColumn('lot', 'lot')}
                { cmn.getGridColumn('prodNm', '제품명')}
                { cmn.getGridColumnNumCommaType('outAmt', '출고수량')}
            </DataGridView> */}
            
            </>
        )
    }
    
    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "재고출고 등록" 
                : mode === "u" ? "재고출고 등록"
                : "재고출고 상세" 
            }
            content = {mode === "v" ? !pageLoad && renderViewContent() : !pageLoad && renderContent()}
        />
        </>
    )
}

export default OutPopPage;
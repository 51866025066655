import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Summary, TotalItem  } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  발주 관리
 */
const OrderPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate(-7));
    const [endDy, setEndDy] = useState(util.getNow());
    const [bizNm, setbizNm] = useState('');
    const [prodNm, setProdNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "발주일",
                width: "200px",
                item: 
                    <>
                    <ul className="ul-row">
                        <li>
                            <DayDateBox 
                                value={startDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setStartDy(e.value);
                                }}                    
                            />
                        </li>
                        <li>
                            ~
                        </li>
                        <li>            
                            <DayDateBox 
                                value={endDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setEndDy(e.value);
                                }}                    
                            />
                        </li>
                    </ul>
                    </>
            },
            {   title: "업체명",
                width: "200px",
                item: 
                <TextBox 
                    value={bizNm}
                    showClearButton={true}
                    placeholder="업체명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setbizNm(e.value);
                    }}
                />         
            },
            {   title: "품명",
                item: 
                <TextBox 
                    value={prodNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                /> 
            }
        ]
    ];

    /* #endregion */

    // * Grid
    const [orderList, setOrderList] = useState([]);
    const [orderListTot, setOrderListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    const grid1 = useRef(null);
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadList1 = async() => {
        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),
                bizNm:bizNm,
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getOrderList", ps );
        if(r === undefined || r == null) return;

        setOrderList(r.d0)
        setOrderListTot(r.tot);

    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    // const handleClickDel = async() => {
    //     try {
    //         if(util.chkListSelected(selectedItem)){
    //             if(!util.confirmYn('발주 취소')) return;

    //             const para =  {
    //                 orders: [
    //                     {
    //                         orderUid: selectedItem.orderUid,
    //                     }
    //                 ]
    //             }

    //             let r = await cmn.api('delOrder', para);

    //             util.cmnSucAlert('발주 취소');

    //             loadList1();
    //         }
    //     }
    //     catch(err){
    //         cmnStore.handleErrorPage(err, props);
    //     }
    // }

    const handleClickIn = (e) => {
        if(util.chkListSelected(selectedItem)){
            openInPopup("a", selectedItem.orderUid, selectedItem.lotTp);
        }
    }

    // const handleClickOrderRepo = (e) => {
    //     if(!util.chkListSelected(selectedItem, '발주내역')) return;
    //     openOrderRepoPopup(selectedItem.orderUid);
    // }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            const url = `/order/orderpop?${qs.stringify({mode: 'v', uid: selectedItem.orderUid})}`;        
            util.openWindow(url, 600, 400);    
        }
    }


    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "payDy" 
            ||  gc.column.dataField === "orderDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    

    // * Functions

    const openCrudPopup = (mode, orderUid, prodUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/order/orderpop?${qs.stringify({mode: mode, uid: orderUid, puid: prodUid})}`;        
        util.openWindow(url, 850, 600); 
    }

    const openInPopup = (mode, orderUid, lp) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/order/inpop?${qs.stringify({mode: mode, ouid: orderUid, lp: lp})}`;        
        util.openWindow(url, 800, 600);    
    }

    // const openOrderRepoPopup = (orderUid) => {
    //     // page mode: v(View), a(Add), u(Update)
    //     const url = `/order/orderrepo?${qs.stringify({uid: orderUid})}`;        
    //     util.openWindow(url, 820, 840);
    // }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(           
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="발주등록"
                    show={authStore.page.crtYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />  
                {/* <ActionButton 
                    onClick={handleClickDel}
                    title="발주취소"
                    show={authStore.page.delYn}
                /> */}
                <ActionButton 
                    onClick={handleClickIn}
                    marginRight={0}
                    title="입고"
                    type="2"           
                    show={authStore.page.crtYn}
                />
                {/* <ActionButton 
                    onClick={handleClickOrderRepo}
                    title="발주서"
                    marginRight={0}
                    type="2"
                    show={authStore.page.viewYn}
                /> */}
            </ListTopView>  
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="발주 내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView> 
            <DataGridView      
                reff={grid1}      
                dataSource={orderList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={orderListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}     

                // excel
                exlnm={'발주내역'}
                customizeExcelCell={customizeExcelCell}     

                colsWidth={[cmn.CW.bizNm, cmn.CW.workNum, cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    ,cmn.CW.amt,  cmn.CW.unit ,cmn.CW.amt, cmn.CW.amt, cmn.CW.dy, cmn.CW.dy, null]}
            >
                { cmn.getGridColumn('bizNm', '업체명', renderGridHeaderCell)}
                { cmn.getGridColumn('orderNum', '발주번호', renderGridHeaderCell)}
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('orderAmt', '발주량') }
                { cmn.getGridColumn('unitTpNm', '단위')}
                { cmn.getGridColumnNumCommaType('inAmt', '입고량') }
                { cmn.getGridColumnPer('inPer', '입고율') }
                { cmn.getGridColumnDayType('payDy', '납기일', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('orderDy', '발주일', renderGridHeaderCell) }
                { cmn.getGridColumnCmnt() }
                <Summary>
                    <TotalItem
                        column={'orderAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                </Summary>
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default OrderPage;
import React, { useState, useEffect } from 'react';
import { SelectBox } from 'devextreme-react';

/** components */

/** stores & lib */
import { cmn } from '../../lib';

/** 
 *  사용자 선택
 */
const UserSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        width='150px',
        value,
        placeholder='사용자를 선택하세요.',
        showClearButton=true,
        type,
        onValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [users, setUsers] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await loadUsers();
        }
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

     const loadUsers = async() => {
        const ps =  {
            p: {
                deptUid: '', 
                userNm: '', 
                sortCol: '',
                sortTp: '',
            }
        }

        const r = await cmn.api("getUserListCombo", ps);
        setUsers(r.d0);
    }

    
    /* ====================
     *  Render
     * ==================== */

    const renderUserNm = (d) => {        
        return(
            <>
            <span className={type === 'site' ? 'site-span' : ''}>
                {d.userNm}
            </span>
            </>
        ) 
    }
      
    const renderContent = () => {

        return (
            <>
                <SelectBox 
                    width={width}
                    placeholder={placeholder}
                    value={value}
                    dataSource={users}
                    displayExpr={'userNm'}
                    valueExpr={'userUid'}
                    showClearButton={showClearButton}

                    itemRender={renderUserNm}

                    onValueChanged={(e)=>{
                        //const o = _.find(users, {userUid: e.value});
                        onValueChanged(e.value);
                    }} 
                />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default UserSelectBox;

import React, { useState, useEffect, useContext } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox, TextArea } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    FileUploadBox, EqupSelectBox, DayDateBox, BizSelectBox,
    PopupLayout, ActionButton, GridTopView, EditGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 수리이력 CRUD 팝업
 */
const EqupFixPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [equpUid, setEqupUid] = useState('');
    const [equpNm, setEqupNm] = useState('');
    // const [equpFixUid, setEqupFixUid] = useState(uid);
    const [fixTitle, setFixTitle] = useState('');
    const [fixReqDy, setFixReqDy] = useState(util.getNow());
    const [fixBizUid, setFixBizUid] = useState('');
    const [fixBizNm, setFixBizNm] = useState('');
    const [fixCost, setFixCost] = useState(0);
    const [fixMngr, setFixMngr] = useState('');
    const [fixMngrContact, setFixMngrContact] = useState('');
    const [fixStartDy, setFixStartDy] = useState('');
    const [fixEndDy, setFixEndDy] = useState('');
    const [fixCnts, setFixCnts] = useState('');
    const [fixBefFileUid, setFixBefFileUid] = useState(util.getUUID());
    const [fixAftFileUid, setFixAftFileUid] = useState(util.getUUID());
    const [cmnt, setCmnt] = useState('');

    const [fixBefFiles, setFixBefFiles] = useState([]);
    const [fixAftFiles, setFixAftFiles] = useState([]);    

    const [equpParts, setEqupParts] = useState([]);    

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadPartUseList();
    },[equpUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {    
            if(mode == 'a') return;
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getEqupFix", {p: {equpFixUid: uid}} );
            if(r == undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
                    
            setEqupUid(d0.equpUid);
            setEqupNm(d0.equpNm);
            setFixTitle(d0.fixTitle);
            setFixReqDy(d0.fixReqDy);
            setFixBizUid(d0.fixBizUid);
            setFixCost(d0.fixCost);
            setFixMngr(d0.fixMngr);
            setFixMngrContact(d0.fixMngrContact);
            setFixStartDy(d0.fixStartDy);
            setFixEndDy(d0.fixEndDy);
            setFixCnts(d0.fixCnts);
            setFixBefFileUid(d0.fixBefFileUid == null ? util.getUUID() : d0.fixBefFileUid);
            setFixAftFileUid(d0.fixAftFileUid == null ? util.getUUID() : d0.fixAftFileUid);
            setCmnt(d0.cmnt);

            setEqupParts(d1);

            setFixBefFiles(r.f1);
            setFixAftFiles(r.f2);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadPartUseList = async() => {
        if(mode != 'a') return;
        if(util.getChkNull(equpUid) == '') {
            setEqupParts([]);
            return;
        }

        const r = await cmn.api("getBkupPartEqup", {p: {equpUid: equpUid}});
        if(r == undefined) return;
        setEqupParts(r.d0);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(equpUid, '설비')) return;
            if(!util.chkMustValue(fixTitle, '수리제목')) return;

            if(mode == "a") { 
                if(!util.confirmYn('등록')) return;
                addEqupFix();
            }
            else if(mode == "u") {
                if(!util.confirmYn('수정')) return;
                upEqupFix();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addEqupFix = async() => {
        const ps = {
            p: 
                {
                    equpFixUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    equpUid: equpUid,
                    fixTitle: fixTitle,
                    fixReqDy: util.getDateToString('d',fixReqDy),
                    fixBizUid: fixBizUid,
                    fixCost: fixCost,
                    fixMngr: fixMngr,
                    fixMngrContact: fixMngrContact,
                    fixStartDy: util.getDateToString('d',fixStartDy),
                    fixEndDy: util.getDateToString('d',fixEndDy),
                    fixCnts: fixCnts,
                    fixBefFileUid: fixBefFileUid,
                    fixAftFileUid: fixAftFileUid,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                    equpParts: equpParts,
                    fixBefFiles: fixBefFiles,
                    fixAftFiles: fixAftFiles
                }
        }

        const r = await cmn.api("addEqupFix", ps);
        if(r == undefined || r == null) return;
        
        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upEqupFix = async() => {
        const ps = {
            p: 
                {
                    equpFixUid: uid,
                    equpUid: equpUid,
                    fixTitle: fixTitle,
                    fixReqDy: util.getDateToString('d',fixReqDy),
                    fixBizUid: fixBizUid,
                    fixCost: fixCost,
                    fixMngr: fixMngr,
                    fixMngrContact: fixMngrContact,
                    fixStartDy: fixStartDy,
                    fixStartDy: util.getDateToString('d',fixStartDy),
                    fixEndDy: util.getDateToString('d',fixEndDy),
                    fixBefFileUid: fixBefFileUid,
                    fixAftFileUid: fixAftFileUid,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                    equpParts: equpParts,
                    fixBefFiles: fixBefFiles,
                    fixAftFiles: fixAftFiles
                }
        }

        const r = await cmn.api("upEqupFix", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode == "a" ? "등록완료" 
            : mode == "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"40%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">설비</th>
                            <td className="tcon">
                                {
                                mode == "a"?
                                    <EqupSelectBox
                                        value={equpUid}
                                        lineYn={authStore.com.isLine ? 'Y' : 'N'}
                                        onLoadInfo={(o) => {
                                            // setEqup(o);
                                        }} 
                                        onValueChanged={(v) => {
                                            setEqupUid(v);
                                        }}
                                    />                                 
                                    :
                                    <span>{equpNm}</span>
                                }
                            </td>
                            <th className="tit">요청일자</th>
                            <td className="tcon">
                                {
                                mode == "a" ||  mode == "u" ?
                                    <DayDateBox 
                                        value={fixReqDy}
                                        type={'day'}
                                        displayFormat={cmn.DATE_FORMAT_DAY_1}
                                        onValueChanged={(e) => {
                                            setFixReqDy(e.value);
                                        }}                    
                                    />
                                :
                                    <span>{fixReqDy}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">수리제목</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode == "a" ||  mode == "u" ?
                                    <TextBox 
                                        value={fixTitle}
                                        placeholder={'수리제목을 입력하세요.'}
                                        showClearButton={true}
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setFixTitle(e.value);
                                        }} 
                                    />
                                :
                                    <span>{fixTitle}</span>
                                }
                            </td>                            
                        </tr>
                        <tr>
                            <th className="tit">수리업체</th>
                            <td className="tcon">
                                {
                                mode == "a" ||  mode == "u" ?
                                    <BizSelectBox
                                        value={fixBizUid}                                        
                                        bizTp={[cmn.CMNCO_BIZ.PART]}
                                        onLoadInfo={(o) => {
                    
                                        }} 
                                        onValueChanged={(v) => {
                                            setFixBizUid(v);
                                        }}
                                    /> 
                                :
                                    <span>{fixBizNm}</span>
                                }
                            </td>
                            <th className="tit">수리비용</th>
                            <td className="tcon">
                                {
                                mode == "a" || mode == "u" ?
                                    <ul className="ul-row">
                                        <li>
                                            <NumberBox
                                                width={100}
                                                value={fixCost}
                                                format={'#,##0'}
                                                defaultValue={0}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setFixCost(e.value);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                          <span>(원)</span>      
                                        </li>
                                    </ul>
                                :
                                    <span>{util.getNumComma(fixCost)}(원)</span>
                                }
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit">담당자</th>
                            <td className="tcon">
                                {
                                mode == "a" || mode == "u" ?
                                    <TextBox 
                                        value={fixMngr}
                                        showClearButton={true}
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setFixMngr(e.value);
                                        }} 
                                    />                               
                                    :
                                    <span>{fixMngr}</span>
                                }
                            </td>
                            <th className="tit">담당자연락처</th>
                            <td className="tcon">
                                {
                                mode == "a" || mode == "u" ?
                                    <TextBox 
                                        value={fixMngrContact}
                                        showClearButton={true}
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setFixMngrContact(e.value);
                                        }} 
                                    />                                  
                                    :
                                    <span>{fixMngrContact}</span>
                                }
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit">수리시작일자</th>
                            <td className="tcon">
                                {
                                mode == "a" ||  mode == "u" ?
                                    <DayDateBox 
                                        value={fixStartDy}
                                        type={'day'}
                                        displayFormat={cmn.DATE_FORMAT_DAY_1}
                                        onValueChanged={(e) => {
                                            setFixStartDy(e.value);
                                        }}                    
                                    />                                
                                    :
                                    <span>{fixStartDy}</span>
                                }
                            </td>
                            <th className="tit">수리종료일자</th>
                            <td className="tcon">
                                {
                                mode == "a" ||  mode == "u" ?
                                    <DayDateBox 
                                        value={fixEndDy}
                                        type={'day'}
                                        displayFormat={cmn.DATE_FORMAT_DAY_1}
                                        onValueChanged={(e) => {
                                            setFixEndDy(e.value);
                                        }}                    
                                    />
                                :
                                    <span>{fixEndDy}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">수리내용</th>
                            <td className="tcon" colSpan={3}>
                                <TextArea
                                    minHeight={60}
                                    // width={500}
                                    value={fixCnts}
                                    placeholder="수리내용을 선택하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setFixCnts(e.value);
                                    }}
                                />
                            </td>                            
                        </tr> 
                        <tr>
                            <th className="tit">수리전사진</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox
                                    grpUid={fixBefFileUid}
                                    accept={'image/*'}
                                    userUid={authStore.user.uid}
                                    uploadFiles={fixBefFiles}

                                    onUploaded={(e) => {
                                        setFixBefFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setFixBefFiles(e);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">수리후사진</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox
                                    grpUid={fixAftFileUid}
                                    accept={'image/*'}
                                    userUid={authStore.user.uid}
                                    uploadFiles={fixAftFiles}

                                    onUploaded={(e) => {
                                        setFixAftFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setFixAftFiles(e);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode == "a" ||  mode == "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>                                             
                    </tbody>
                </table>
            </div>
            <GridTopView title="부품 사용 내역">
                <>
                </>               
            </GridTopView>
            <EditGridView            
                dataSource={equpParts}   // store
                colsWidth={[200, 120, 120, 100, 100, 100, null]}
            >
                { cmn.getGridColumn('partNm', '부품명' ) }
                { cmn.getGridColumn('partStnd', '규격' ) }
                { cmn.getGridColumn('saveLoca', '보관위치' ) }
                { cmn.getGridColumnNumCommaType('safeStock', '안전재고') }
                { cmn.getGridColumnNumCommaType('curStock', '현재재고') }
                <Column 
                    alignment={'center'}
                    dataField={'useAmt'} 
                    caption={'투입수량'}  
                    cellRender={(d) => {
                        return (
                            <>
                            <div className="editable-outline">
                            {d.data.useAmt}
                            </div>
                             </>
                        )
                    }}
                    editCellRender={(d) => {
                        // console.log("--- d", d);
                        return (
                            <>
                            {
                                <NumberBox
                                    width={'100%'}
                                    format={'#,##0'}
                                    value={d.data.useAmt}
                                    min={0}
                                    max={d.data.curStock}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        const equpParts_ = [...equpParts];
                                        equpParts_[d.rowIndex].useAmt = e.value;
                                        setEqupParts(equpParts_);
                                    }}
                                />
                            }
                            </>
                        )
                    }}                    
                />              
                <Column />
            </EditGridView>   
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "설비 수리내역 등록" 
                : mode == "u" ? "설비 수리내역 수정"
                : "설비 수리" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default EqupFixPopPage;
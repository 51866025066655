import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    PopupLayout,
    ActionButton,
    SeqSelectBox,
    FileUploadBox,
    UseYnSwitchBox
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  제품공정 등록 / 수정
 */
const ProdProcPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);    

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        pduid = ls.pduid,
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [procItems, setProcItems] = useState([]);

    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    
    const [procUid, setProcUid] = useState('');
    const [procNm, setProcNm] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [unitWght, setUnitWght] = useState(0);
    const [useYn, setUseYn] = useState('Y');
    const [cmnt, setCmnt] = useState('');
    const [oldOrder, setOldOrder] = useState(''); 
    const [maxOrder, setMaxOrder] = useState(''); 
    const [workManHourYn, setWorkManHourYn] = useState('N')

    const [procImgUid, setProcImgUid] = useState(util.getUUID());
    const [stndImgUid, setStndImgUid] = useState(util.getUUID());
    const [procUploadFiles, setProcUploadFiles] = useState([]);
    const [stndUploadFiles, setStndUploadFiles] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);

            if(mode === "a" || mode === "u"){
                const ps = {
                                p: {
                                tableNm : "tb_prod_proc",
                                keyField: "prod_uid",
                                keyValue: pduid
                            }
                        };
                let r = await cmn.api('getSortOrderCnt', ps);
                if(r == null || r === undefined) return;
                setMaxOrder(r.d0[0].cnt);
    
                let r2 = await cmn.api('getProcCombo', {p:{procUid:''}});
                if(r2 == null || r2 === undefined) return;
                setProcItems(r2.d0);

            }

            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = () => {
        try {
            if(mode === "a")
            {
                loadProdInfo();
            }else
            {
                loadProdProcInfo();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadProdInfo = async() => {
        if(util.getChkNull(pduid) === '') return;
        let r = await cmn.api('getProd', {p:{prodUid:pduid}});
        if(r == null || r === undefined) return;
        const data = r.d0[0];

        setProdId(data.prodId);
        setProdNm(data.prodNm);
        setProdStnd(data.prodStnd);
    }

    const loadProdProcInfo = async() => {
        if(util.getChkNull(uid) === '') return;

        const r = await cmn.api('getProdProc', {p: {prodProcUid: uid}});
        if(r == null || r === undefined) return;

        const data = r.d0[0];
        
        setProdId(data.prodId);
        setProdNm(data.prodNm);
        setProdStnd(data.prodStnd);
        setProcUid(data.procUid);
        setProcNm(data.procNm);
        setSortOrder(data.sortOrder);
        setUnitWght(data.unitWght);
        setProcImgUid(data.procImgUid == null ? util.getUUID() : data.procImgUid);
        setStndImgUid(data.stndImgUid == null ? util.getUUID() : data.stndImgUid);
        setUseYn(data.useYn);
        setCmnt(data.cmnt);
        setWorkManHourYn(data.workManHourYn);

        //이전 sort 정보 저장
        setOldOrder(data.sortOrder);

        //files
        const f0 = r.f0;
        const f1 = r.f1;
        setProcUploadFiles(f0);
        setStndUploadFiles(f1);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(procUid, '공정'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addProdProc();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upProdProc();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

  
    const addProdProc = async() => {
        const ps = {
            p: {
                prodProcUid: util.getUUID(),
                prodUid: pduid,
                procUid: procUid,
                sortOrder: sortOrder,
                unitWght: unitWght,
                procImgUid: procImgUid,
                stndImgUid: stndImgUid,
                procUploadFiles: procUploadFiles,
                stndUploadFiles: stndUploadFiles,
                useYn: "Y",
                cmnt: cmnt,
                uptUserUid: authStore.user.uid,
                sort:{
                    oldOrder: "0",
                    newOrder: sortOrder,
                },
                workManHourYn: workManHourYn
            }
        }

        const r = await cmn.api('addProdProc', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    const upProdProc = async() => {
        const ps = {
            p: {
                prodProcUid: uid,
                prodUid: pduid,
                procUid: procUid,
                sortOrder: sortOrder,
                unitWght: unitWght,
                procImgUid: procImgUid,
                stndImgUid: stndImgUid,
                procUploadFiles: procUploadFiles,
                stndUploadFiles: stndUploadFiles,
                useYn: "Y",
                cmnt: cmnt,
                uptUserUid: authStore.user.uid,
                sort:{
                    oldOrder: oldOrder,
                    newOrder: sortOrder
                },
                workManHourYn: workManHourYn
            }
        }

        console.log(ps);
        const r = await cmn.api('upProdProc', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {


        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">규격</th>
                            <td className="tcon">                           
                                <span>{prodStnd}</span>
                            </td>
                            <th className="tit">공정순서</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <SeqSelectBox 
                                    mode={mode}
                                    value={sortOrder}
                                    cnt={maxOrder}                         
                                    onValueChanged={(v)=>{
                                        setSortOrder(v);
                                    }} 
                                />
                                :
                                    <span>{sortOrder}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">공정</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <SelectBox 
                                        value={procUid}
                                        dataSource={procItems}
                                        displayExpr={'procNm'}
                                        valueExpr={'procUid'}
                                        placeholder="공정을 선택하세요"
                                        showClearButton={true}
                                        onValueChanged={(e)=>{
                                            setProcUid(e.value);
                                        }} 
                                    />
                                :
                                    <span>{procNm}</span>
                                }
                            </td>
                            <th className="tit">공정단중</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        value={unitWght}
                                        format={'#,##0.###'}
                                        width={'80px'}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setUnitWght(e.value);
                                        }} 
                                    />
                                :
                                    <span>{unitWght}</span>
                                }
                            </td>
                        </tr>
                      
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">공정 Spec</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox                                    
                                    // multiple={false}
                                    limit={1}
                                    grpUid={procImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={procUploadFiles}
                                    onUploaded={(e) => {
                                        setProcUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setProcUploadFiles(e);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">작업표준서</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox                                    
                                    // multiple={false}
                                    limit={1}
                                    grpUid={stndImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={stndUploadFiles}
                                    onUploaded={(e) => {
                                        setStndUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setStndUploadFiles(e);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">작업공수산정</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={workManHourYn}
                                        onValueChanged={(v)=>{
                                            setWorkManHourYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{workManHourYn}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "제품공정 등록" 
                : mode === "u" ? "제품공정 수정"
                : "제품공정" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProdProcPopPage;
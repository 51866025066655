import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListTopView, GridTopView, DataGridView,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  회사 관리
 */
const ComPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
   
    // * Control Items (* Combo ...)

    // * 검색
    const [comNm, setComNm] = useState('');

    /* #endregion */    

   // * Grid
   const [comList, setComList] = useState([]);
   const [comListTot, setComListTot] = useState(0);
   const [selectedItem, setSelectedItem] = useState(null);
   // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                comNm: comNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getComList", ps );
        if(r === undefined || r == null) return;

        setComList(r.d0)
        //setBizListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.comUid);
        }
    }


    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }
    // * Functions

    const openCrudPopup = (mode, comUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/compop?${qs.stringify({mode: mode, uid: comUid})}`;        
        util.openWindow(url, 600, 600);    
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        return(
            <Observer>{() =>
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                    show={false}
                />
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    marginRight={0}
                    show={authStore.page.upYn}
                />
            </ListTopView>       
            <GridTopView
                title="회사 내역"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView>     
            <DataGridView            
                dataSource={comList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H20}

                // sort
                onCellClick={handleGridCellClick}

                showNo={false}
                // select
                onSelectionChanged={handleGridSelectionChanged}   
                
                colsWidth={[150, 120, 120, 120, null, null]}

            >
                { cmn.getGridColumn('comNm', '회사명')}
                { cmn.getGridColumn('ownerNm', '대표자명')}
                { cmn.getGridColumn('phone', '대표번호')}
                { cmn.getGridColumn('fax', 'FAX')}
                { cmn.getGridColumn('addr', '주소')}
                { cmn.getGridColumn('addrDtl', '상세주소')}
            </DataGridView>
            </>
            }</Observer>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ComPage;
import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import _ from 'lodash';

/** stores & lib */
import { 
    apiz, sapi, RCODE_S, RCODE_E, ResData
} from '../lib';

class CmnStore {

    popup = {show: false, message: ''};

    curLot = '';

    /**
     * Redirect Error Page
     * 
     * @e e object
     * @h props
     */    
    handleErrorPage = (err, props) => {
        if(props != undefined && props != null) {
            props.history.push({
                pathname: '/error',
                state: { message: err.message}
            });
        }
    }

    /**
     * Popup Error Info
     * 
     * @e e object
     * @cs CmnStore object
     */ 
    handleErrorPopup = (e) => {
        console.log("=== handleErrorPopup e", e.message);
        this.popup = {show: true, message: e.message};
    }

    /** 
     * 정렬순서 콤보
     * p: {bizTp: []}
     * */
    getSortOrderCombo = async (para) => {
        const resData = await sapi.send(apiz.getSortOrderCombo, para);
        console.log("getSortOrderCombo ======>", resData);

        /** Exception */
        if(resData.code !== RCODE_S){
            return;
        }

        return resData.data.d0;
    }

    
     /** 
     * 정렬순서 카운트
     * 
     * */
    getSortOrderCnt = async (tableNm, keyField, keyValue) => {
        const ps = {
            p: {
                tableNm : tableNm,
                keyField: keyField,
                keyValue: keyValue
            }
        };

        const resData = await sapi.send(apiz.getSortOrderCnt, ps);
        console.log("getSortOrderCnt ======>", resData);

        /** Exception */
        if(resData.code != RCODE_S){
            return;
        }

        return resData.data.d0;
    }

    /** 
     * 정렬순서 수정
     *
     * */
    upSortOrder = async (para) => {
        const resData = await sapi.send(apiz.upSortOrder, para);
        console.log("upSortOrder ======>", resData);

        /** Exception */
        if(resData.code != RCODE_S){
            return;
        }

        return resData;
    }

    /** 
     * Lot 생성 및 조회
     *
     * */
    getLotCrt = async (ps) => {
        const resData = await sapi.send(apiz.getLotCrt, ps);
        console.log("getLotCrt ======>", resData);

        /** Exception */
        if(resData.code !== RCODE_S){
            return;
        }

        return resData.data.d0[0].lot;
    }

    /** 
     * Lot 목록 Combo
     * {
            "search": {
                "workNum": "",
                "prodUid": "",
                "sortCol": "",
                "sortTp": ""
            }
        }
     *
     * */
    getLotListCombo = async (para) => {        
        const resData = await sapi.send(apiz.getLotListCombo, para);                
        if(resData.code != RCODE_S){
            return;
        }
        return resData.data.d0;
    }

    /** 
     * WorkNum / OrderNum 생성 및 조회
     *
     * */
    getWorkNumCrt = async (para) => {
        const resData = await sapi.send(apiz.getWorkNumCrt, para);

        /** Exception */
        if(resData.code !== RCODE_S){
            return;
        }
        
        return resData.data.d0[0].workNum;
    }

    /** 
     * 작지번호 목록 Combo
     * {
            "search": {
                "prodUid": "",
                "sortCol": "",
                "sortTp": ""
            }
        }
     *
     * */
    getWorkNumListCombo = async (para) => {        
        const resData = await sapi.send(apiz.getWorkNumListCombo, para);                
        if(resData.code != RCODE_S){
            return;
        }
        return resData.data.d0;
    }

    /** 월 일자 정보 조회 */
    getDyMon = async (yrMon) => { 
        let resData =  new ResData();
        const ps = {p: {yrMon: yrMon}} 
        try{
            resData = await sapi.send(apiz.getDyMon, ps );
        }
        catch(err){        
            resData.code = RCODE_E;
            resData.message = err.message;
        }

        return resData.data.d0;
    }
   
}

decorate(CmnStore, {
    popup: observable
    ,cutLot : observable
});

export default createContext(new CmnStore())
import React, { useState, useEffect } from "react";

const ActionButton = (props) => {

    const { 
        onClick, 
        width = "100px",
        title = "",
        type="1",
        marginRight = 0,
        show=false
    } = props;

    return(
        <>

        <a
            href="#"
            className={`btn btn-sz-${type} `}
            style={{ 
                width, 
                marginRight: marginRight,
                display: show ? '' : 'none' 
            }}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            { title }
        </a>
        </>
    );

}

export default ActionButton;
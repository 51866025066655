const authApiz = {
    getAuthList: {
        title: "권한 목록 조회",
        url: "/api/auth/getAuthList",
        method: "POST"
    },
    getAuth: {
        title: "권한 상세 조회",
        url: "/api/auth/getAuth",
        method: "POST"
    },
    addAuth: {
        title: "권한 등록",
        url: "/api/auth/addAuth",
        method: "POST"
    },
    upAuth: {
        title: "권한 수정",
        url: "/api/auth/upAuth",
        method: "POST"
    },
    delAuth: {
        title: "권한 삭제",
        url: "/api/auth/delAuth",
        method: "POST"
    },
    getAuthUserList: {
        title: "권한별 사용자 목록 조회",
        url: "/api/auth/getAuthUserList",
        method: "POST"
    },
    addAuthUser: {
        title: "권한별 사용자 등록",
        url: "/api/auth/addAuthUser",
        method: "POST"
    },
    delAuthUser: {
        title: "권한별 사용자 삭제",
        url: "/api/auth/delAuthUser",
        method: "POST"
    },
    getAuthDeptList: {
        title: "권한 및 부서 목록 조회",
        url: "/api/auth/getAuthDeptList",
        method: "POST"
    },
    getAuthMenuAuthUid: {
        title: "권한별 메뉴 조회 (AuthUid)",
        url: "/api/auth/getAuthMenuAuthUid",
        method: "POST"
    },
    addAuthMenu: {
        title: "권한별 메뉴 등록",
        url: "/api/auth/addAuthMenu",
        method: "POST"
    },
    getUserPageAllow: {
        title: "화면 접근권한 조회",
        url: "/api/auth/getUserPageAllow",
        method: "POST"
    },
    getUserLogIn: {
        title: "로그인 정보",
        url: "/api/auth/getUserLogIn",
        method: "POST"
    },
    getChkAuthToken: {
        title: "인증토큰체크",
        url: "/api/auth/getChkAuthToken",
        method: "POST"
    },
    
}

export default authApiz;
import React, { useState, useEffect, useContext } from 'react';
import {  NumberBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    BizSelectBox, DayDateBox, PopupLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 예비부품 입고 팝업
 */
const EqupBkupInPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    // const [bkupPartUid, setBkupPartUid] = useState(uid);
    const [partNm, setPartNm] = useState('');
    const [partStnd, setPartStnd] = useState('');
    const [aftInStock, setAftInStock] = useState(0);

    const [inDy, setInDy] = useState(util.getNow());
    const [curStock, setCurStock] = useState(0);
    const [orderBizUid, setOrderBizUid] = useState('');
    const [inAmt, setInAmt] = useState(0);
    const [price, setPrice] = useState(0);
    const [amts, setAmts] = useState(0);
    const [cmnt, setCmnt] = useState('');
    
    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && setCalcAmt();
    },[inAmt, price]);

    useEffect(() => {
        !pageLoad && setCalcAftInStock();
    },[inAmt]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {
            if(util.getChkNull(uid) == '') return;
            
            const r = await cmn.api("getBkupPart", {p: {bkupPartUid: uid}} );
            if(r == undefined || r == null) return;

            const d0 = r.d0[0];
            setPartNm(d0.partNm);
            setPartStnd(d0.partStnd);
            setCurStock(d0.curStock);
            setOrderBizUid(d0.orderBizUid);  
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const setCalcAmt = () => {
        const amts_ = inAmt * price;
        setAmts(amts_);
    }

    const setCalcAftInStock = () => {
        const aftInStock_ = inAmt + curStock;
        setAftInStock(aftInStock_);
    }

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(inAmt, '입고수량', 'cnt')) return;

            if(mode == "a") { 
                if(!util.confirmYn('입고')) return;
                addBkupInPart();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addBkupInPart = async() => {

        const ps = {
            p: 
                {
                    bkupPartInUid: util.getUUID(),
                    bkupPartUid: uid,
                    inDy: util.getDateToString('d',inDy),
                    curStock: curStock,
                    orderBizUid: orderBizUid,
                    inAmt: inAmt,
                    price: price,
                    amts: amts,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid
                }
        }

        const r = await cmn.api("addBkupPartIn", ps);

        util.cmnSucAlert("입고완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode == "a" ? "입고완료" 
            : mode == "u" ? "입고완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"40%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">부품명</th>
                            <td className="tcon">
                                <span>{partNm}</span>
                            </td>
                            <th className="tit">부품규격</th>
                            <td className="tcon">
                                <span>{partStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">구매처</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <BizSelectBox
                                        value={orderBizUid}                                        
                                        bizTp={[cmn.CMNCO_BIZ.PART]}
                                        onLoadInfo={(o) => {
                    
                                        }} 
                                        onValueChanged={(v) => {
                                            setOrderBizUid(v);
                                        }}
                                    />   
                                :
                                    <span>...</span>
                                }
                            </td>
                            <th className="tit">입고일자</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <DayDateBox 
                                        value={inDy}
                                        type={'day'}
                                        displayFormat={cmn.DATE_FORMAT_DAY_1}
                                        onValueChanged={(e) => {
                                            setInDy(e.value);
                                        }}                    
                                    />
                                :
                                    <span>{inDy}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">입고수량</th>
                            <td className="tcon" colSpan={3}>                        
                                <ul className="ul-row">
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={inAmt}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setInAmt(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>(EA)</li>
                                    <li>X</li>
                                    <li className="ftstyle1">단가:</li>
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={price}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setPrice(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>(원)</li>
                                    <li>=</li>
                                    <li className="ftstyle1">금액:</li>
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={amts}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setAmts(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>(원)</li>
                                </ul>
                            </td>
                        </tr>                           
                        <tr>
                            <th className="tit">현재재고</th>
                            <td className="tcon">
                                <span>{curStock} (EA)</span>
                            </td>
                            <th className="tit">입고후재고</th>
                            <td className="tcon">
                                <span>{aftInStock} (EA)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode == "a" || "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>         
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "생산설비 예비부품 입고" 
                : mode == "u" ? "생산설비 예비부품 입고"
                : "생산설비 예비부품" 
            }
            content = { renderContent() }
        />
        </>
    )
}

export default EqupBkupInPopPage;
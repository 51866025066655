import React, { useState, useEffect } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */
//import { IconButton } from '..';

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  작업지시 선택 조회
 */
const WorkNumSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {

        width='100%',
        value,
        placeholder='작지번호를 선택하세요.',

        prodUid,
        workNumNull,
        prodUidReq=false, // Prod Uid 필수여부

        sortCol,
        sortTp,

        onValueChanged,
        onLoadInfo

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [workNums, setWorkNums] = useState([]);

    useEffect(() => {
        loadPage();
    },[prodUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            if(prodUidReq && util.getChkNull(prodUid) === ''){
                setWorkNums([]);    
                onValueChanged();
                return;
            }

            const ps =  {
                p: {
                    prodUid: prodUid, 
                    workNumNull: workNumNull,
                    sortCol: sortCol,
                    sortTp: sortTp
                }
            }

            const r = await cmn.api("getWorkNumListCombo", ps);
            setWorkNums(r.d0);
        }
        catch(err) {
            // nothing ...
        }        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleLoadInfo = (ds, v) => {
        const o = _.find(ds, {workNum: v});
        onLoadInfo && onLoadInfo(o);
    }

    
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <SelectBox 
                width={width}
                placeholder={placeholder}
                value={value}
                dataSource={workNums}
                displayExpr={'workNumDisp'}
                valueExpr={'workNum'}

                // showClearButton={true}
                // searchEnabled={true}
                // searchMode={'startswith'} // contains, startswith
                // searchExpr={['prodId', 'prodNm', 'prodDisp']}
                
                //searchTimeout={this.state.searchTimeoutOption}
                //minSearchLength={this.state.minSearchLengthOption}

                onValueChanged={(e)=>{
                    onValueChanged(e.value);
                    handleLoadInfo(workNums, e.value);
                }}
            />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default WorkNumSelectBox;

import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    EditGridView,
    PopupLayout, 
    ActionButton, 
    DayDateBox,
    UserSelectBox,
    CancelButton
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  입고 등록 / 수정
 */
const InPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        ouid = ls.ouid,   // orderUid
        uid = ls.uid,     // inUid
        lp = ls.lp,     // lotTp
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    //입고정보
    
    const [bizUid, setBizUid] = useState('');
    const [prodUid, setProdUid] = useState('');
    const [orderUid, setOrderUid] = useState('');
    const [prodProcUid, setProdProcUid] = useState('');
    const [inDy, setInDy] = useState(util.getNow());
    const [acctMon, setAcctMon] = useState(util.getNow());
    const [unitTp, setUnitTp] = useState('');
    const [unitTpNm, setUnitTpNm] = useState('');
    const [prodWght, setProdWght] = useState(0);
    const [inPrice, setInPrice] = useState(0);
    
    const [userUid, setUserUid] = useState(authStore.user.uid);

    const [orderNum, setOrderNum] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [orderDy, setOrderDy] = useState('');
    const [payDy, setPayDy] = useState('');
    const [orderAmt, setOrderAmt] = useState('');
    const [lastYn, setLastYn] = useState('');
    const [lotAmt, setLotAmt] = useState(0);
    const [lotCnt, setLotCnt] = useState(0);
    const [inAmt, setInAmt] = useState(0);
    
    const [userNm, setUserNm] = useState('');
    const [lot, setLot] = useState('');

    const [isDel, setIsDel] = useState('N');

    const [lotItems, setLotItems] = useState([]);

    const [storUid, setStorUid] = useState('');
    const [storItems, setStorItems] = useState([]);
    const [storNm, setStorNm] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        handleClickCrtRows();
    },[lotCnt]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);

            let r = await cmn.api('getStorCombo', {p:{}})
            if(r == null || r == undefined) return;

            setStorItems(r.d0);

            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {   
            if(mode === 'a')
            {
                if(util.getChkNull(ouid) === '') return;

                let r = await cmn.api('getOrder', {p:{orderUid:ouid}});
                if(r == null || r === undefined) return;

                const data = r.d0[0];
                        
                setOrderUid(data.orderUid);
                setOrderNum(data.orderNum);
                setBizUid(data.bizUid);
                setBizNm(data.bizNm);
                setProdUid(data.prodUid);
                setProdId(data.prodId);
                setProdNm(data.prodNm);
                setProdStnd(data.prodStnd);
                setOrderDy(data.orderDy);
                setPayDy(data.payDy);
                setOrderAmt(data.orderAmt);
                setUnitTp(data.unitTp);
                setUnitTpNm(data.unitTpNm);
                setInPrice(data.procPrice);
                setProdWght(data.prodWght);
                setLotAmt(data.lotAmt);
                setProdProcUid(data.prodProcUid);
                setLastYn(data.lastYn);
                setInAmt(data.inAmt);
                setStorUid(data.storUid);

                if(lp === cmn.CMNCO_LOTTP.DAY)
                {
                    //lot type 이 일별일 경우 당일 데이터의 Lot를 생성한다.
                    let lot_ = `${data.orderNum}-${util.getDateToString('f', util.getNow(), '', 'YYMMDD')}`;
                    setLot(lot_);
                    setLotCnt(1);
                }else if(lp === cmn.CMNCO_LOTTP.MON)
                {
                    //lot type 이 월별일 경우 당월 데이터의 Lot를 생성한다.
                    let lot_ = `${data.orderNum}-${util.getDateToString('f', util.getNow(), '', 'YYMM')}`;
                    setLot(lot_);
                    setLotCnt(1);
                }

            }else if(mode === 'v')
            {
                if(util.getChkNull(uid) === '') return;

                let r = await cmn.api('getIn', {p:{inUid:uid}});
                if(r == null || r === undefined) return;

                const data = r.d0[0];

                setOrderNum(data.orderNum);
                setBizNm(data.bizNm);
                setProdUid(data.prodUid);
                setProdId(data.prodId);
                setProdNm(data.prodNm);
                setProdStnd(data.prodStnd);
                setOrderDy(data.orderDy);
                setPayDy(data.payDy);
                setOrderAmt(data.orderAmt);
                setInPrice(data.inPrice);
                setLastYn(data.lastYn);
                setInAmt(data.inAmt);
                setUserNm(data.userNm);
                setAcctMon(data.acctMon);
                setProdProcUid(data.prodProcUid)
                setIsDel(data.isDel);
                setStorUid(data.storUid);
                setStorNm(data.storNm);

                const lotItems_ = [];
                lotItems_.push(
                    {
                        inUid: data.inUid,
                        inAmt: data.inAmt,
                        heatNo: '',
                        coilNo: '',
                        bizLot: data.inAmt,
                        cmnt: data.cmnt,
                        lot: data.lot
                    });

                setLotItems(lotItems_);
            }
            
        }catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(mode === "a")
            { 
                if(!util.chkMustValue(userUid, '입고자'))
                    return;

                //추가 Validation
                let possInAmt = orderAmt - inAmt;
                let totInAmt = _.sumBy(lotItems, 'inAmt');
                if(!util.chkMustValue(totInAmt, '입고량', 'cnt'))
                    return;
            
                if(!util.chkMustValue(lotCnt, 'Lot 수', 'cnt'))
                    return;
                
                if(lp === cmn.CMNCO_LOTTP.ONE) // Lot Type 이 건별일 경우
                {
                    if(totInAmt >  possInAmt)
                    {
                        if(!window.confirm('[입고량]이 [입고가능량]보다 큽니다. \n계속 진행하시겠습니까?'))
                            return;
                    }
                }else{
                    if(totInAmt >  possInAmt)
                    {
                        util.cmnAlert('[입고량]이 [입고가능량]보다 큽니다.')
                        return;
                    }
                }

                if(util.confirmYn("등록"))
                    addIn();
            }
            else if(mode === "v")
            {
                delIn(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickCrtRows = (e) => {
        if(lotCnt === 0) return;
        // if(!util.chkMustValue(lotCnt, 'Lot 수', 'cnt')) return;

        const lotItems_ = [];
        
        //let i = 0;
        _.times(lotCnt, 
            () => {
          //      ++i;
                lotItems_.push(
                {
                    inUid: util.getUUID(),
                    inAmt: 0,
                    heatNo: '',
                    coilNo: '',
                    bizLot: '',
                    cmnt: ''
                });
            }
        );

        setLotItems(lotItems_);
    }

    const addIn = async(e) => {
        const lotPara = {
            p :
            {
                comUid: authStore.com.uid,
                workNum: orderNum,
                lot: lot
            }
        }

        console.log(lotPara);
        //const lot_ = cmnStore.getLotCrt(para2);
        let ins_ = [];
        for(let i =0; i < lotItems.length; i ++)
        {
            ins_.push(
                {
                    inUid: lotItems[i].inUid,
                    comUid: authStore.com.uid,
                    orderUid: orderUid,
                    bizUid: bizUid,
                    prodUid: prodUid,
                    lot: await cmnStore.getLotCrt(lotPara),
                    prodProcUid: prodProcUid,
                    inDy: util.getDateToString('d',inDy),
                    acctMon: util.getDateToString('M', acctMon),
                    inAmt: lotItems[i].inAmt,
                    unitTp: unitTp,
                    prodWght: prodWght,
                    inPrice: inPrice,
                    heatNo: lotItems[i].heatNo,
                    coilNo: lotItems[i].coilNo,
                    bizLot: lotItems[i].bizLot,
                    userUid: userUid,
                    cmnt: lotItems[i].cmnt,
                    lastYn: lastYn,
                    storUid: storUid,
                    uptUserUid: authStore.user.uid
                }
            )
        }
        
        const ps = {
            p: [...ins_]
        }
        console.log(ps);

        let r = await cmn.api('addIn', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delIn = async(delCmnt) => {
        let ins_ = [];
        for(let i =0; i < lotItems.length; i ++)
        {
            ins_.push(
                {
                    inUid: lotItems[i].inUid,
                    inAmt: lotItems[i].inAmt,
                    comUid: authStore.com.uid,
                    prodProcUid: prodProcUid,
                    prodUid: prodUid,
                    lot: lotItems[i].lot,
                    delCmnt: delCmnt,
                    lastYn: lastYn,
                    uptUserUid: authStore.user.uid
                }
            )
        }

        const ps = {
            p: [...ins_]
        }
        console.log(ps);
        let r = await cmn.api('delIn', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("취소");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "v" ? "취소완료"
            : "" ;

        return(
            <>
            <div>
                {/* <div className="tit-area fl-l">입고정보 입력</div> */}
                <div className="btn_r mgb10">                
                {mode === "v" ?
                    <CancelButton
                        title={completeButtonTitle}
                        isDel={isDel}
                        isDelMsg={'제품의 재고가 부족합니다. \n제품의 재고를 확인하여주시기 바랍니다.'}
                        handleClickComplete={(e) => {
                            handleClickComplete(e)
                        }} 
                    />
                    :
                    <ActionButton 
                        onClick={handleClickComplete}
                        title={completeButtonTitle}
                        marginRight={0}
                        show={true}
                    />
                }
                </div>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">발주번호</th>
                            <td className="tcon">
                                <span>{orderNum}</span>
                            </td>
                            <th className="tit">업체명</th>
                            <td className="tcon">
                                <span>{bizNm}</span>                             
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                            <th className="tit">발주량<br/>(입고량)</th>
                            <td className="tcon">
                                <span>{util.getNumComma(orderAmt)} ({util.getNumComma(inAmt)})</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">발주일</th>
                            <td className="tcon">
                                <span>{util.getDateStrSplit('d',orderDy)}</span>
                            </td>
                            <th className="tit">납기일</th>
                            <td className="tcon">
                                <span>{util.getDateStrSplit('d',payDy)}</span>                              
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">입고일</th>
                            <td className="tcon">
                                { 
                                    mode === 'a' ?
                                    <DayDateBox 
                                        value={inDy}
                                        onValueChanged={(e) => {
                                            setInDy(e.value);
                                        }}                    
                                    />
                                    :
                                    <span>{inDy}</span>
                                }
                            </td>
                            <th className="tit">회계월</th>
                            <td className="tcon">
                                { 
                                    mode === 'a' ?
                                    <DayDateBox 
                                        value={acctMon}
                                        type={'month'}
                                        displayFormat={cmn.DATE_FORMAT_MON_1}
                                        onValueChanged={(e) => {
                                            setAcctMon(e.value);
                                        }}                    
                                    />
                                    :
                                    <span>{util.getDateStrSplit('M',acctMon)}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">입고단가</th>
                            <td className="tcon">
                                { 
                                    mode === 'a' ?
                                    <NumberBox
                                        width={80}
                                        format={'#,##0'}
                                        value={inPrice}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setInPrice(e.value);
                                        }}
                                    />
                                    :
                                    <span>{util.getNumComma(inPrice)}</span>
                                }
                            </td>
                            {/* <th className="tit">입고단위</th>
                            <td className="tcon">
                                <CodeSelectBox
                                    value={unitTp}
                                    classId={'105'}
                                    placeholder="입고단위 선택하세요."
                                    // onLoadInfo={(o) => {
                                    //         setUnitTpNm(util.getVal(o, 'unitTpNm'));
                                    //     }} 
                                    onValueChanged={(v) => { 
                                        setUnitTp(v);
                                    }}
                                />
                            </td> */}
                            <th className="tit req">입고자</th>
                            <td className="tcon">
                                { 
                                    mode === 'a' ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        placeholder="입고자를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                    :
                                    <span>{userNm}</span>
                                }
                            </td>
                        </tr>   
                        <tr>
                            {/* <th className="tit">입고단중</th>
                            <td className="tcon">
                                <NumberBox
                                    width={80}
                                    format={'#,##0.####'}
                                    value={prodWght}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setProdWght(e.value);
                                    }}
                                />                                
                            </td> */}
                           
                        </tr> 
                            {
                            mode === "a" || mode === "u" ?
                            <>
                            <tr>
                                <th className="tit req">Lot 수</th>
                                <td className="tcon" >
                                    <NumberBox
                                        width={80}
                                        format={'#,##0'}
                                        value={lotCnt}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setLotCnt(e.value);
                                        }}
                                    />
                                </td>
                                <th className="tit">창고위치</th>
                                <td className="tcon" >
                                    <SelectBox 
                                        width={"200px"}
                                        placeholder={'창고를 선택하세요.'}
                                        value={storUid}
                                        dataSource={storItems}
                                        displayExpr={'storNm'}
                                        valueExpr={'storUid'}
                                        showClearButton={true}
                                        onValueChanged={(e)=>{
                                            setStorUid(e.value);
                                        }} 
                                    />
                                </td>
                            </tr> 
                            </>
                            :
                            <>
                            <tr>
                                <th className="tit">창고위치</th>
                                <td className="tcon" colSpan={3}>
                                    
                                        <span>{storNm}</span>
                                </td>
                            </tr>
                            </>
                            }
                    </tbody>
                </table>
            </div>
            
            <div className="pop-grid">
                {
                    mode === 'a' ?
                    <div className="clearfix">
                        <div className="btn-area fl-l">
                            <span>Lot는 입고량 등록 시 자동 생성 됩니다.</span>
                        </div>
                    </div>
                    : 
                    ''
                }
                <div className="clearfix">
                    <EditGridView
                        dataSource={lotItems}
                        gridHeight={150}
                        colsWidth={[100, 250,  null]}  
                    >
                        <Column 
                            alignment={'center'}
                            dataField={'inAmt'} 
                            caption={'입고량'} 
                            allowEditing={mode === 'a' ? true : false}
                        />
                        {/* <Column 
                            alignment={'center'}
                            dataField={'heatNo'} 
                            caption={'자재HEAT'} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'coilNo'} 
                            caption={'자재COIL'} 
                        /> */}
                        <Column 
                            alignment={'center'}
                            dataField={'bizLot'} 
                            caption={'업체Lot'} 
                            allowEditing={mode === 'a' ? true : false}
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'cmnt'} 
                            caption={'비고'} 
                            allowEditing={mode === 'a' ? true : false}
                        />
                    </EditGridView>
                </div>
            </div>          
            
            </>
        )
    }                                                                                                          

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "입고 등록" 
                : mode === "v" ? "입고 상세보기"
                : "입고" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default InPopPage;
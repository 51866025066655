import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';

/** components */
import {
    PopupLayout, 
    DataGridView
} from '../../components';

import { AuthStore, CmnStore } from '../../stores';

/** stores & lib */
import { cmn } from '../../lib';

/** images */

/** 
 *  발주 등록 / 수정
 */
const TakeProdPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        uid = ls.uid,
        mon = ls.mon,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [takeProdItems, setTakeProdItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = () => {

        // 발주정보조회
        loadTakeProdInfo();
    }
  
    const loadTakeProdInfo = async() => {
        let para = {p: {prodUid:uid, takeMon:mon}}

        const r = await cmn.api('getTakeProd', para);
        if(r == null || r === undefined) return;
        
        setTakeProdItems(r.d0);
    }
  

    /* ====================
     *  Handle / Functions
     * ==================== */

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        return(
            <>
            <DataGridView            
                dataSource={takeProdItems}   // store
                colsWidth={[null, null]}          
                showNo={false}    
                gridHeight={270}
            >
                { cmn.getGridColumn('workNum', '작업번호')}
                { cmn.getGridColumnNumCommaType('takeAmt', '작업수량') }

            </DataGridView>
            </>
        );

    }

    return(
        <>
        <PopupLayout
            title = {
               "소요 분석 내역" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default TakeProdPopPage;
import React, { useState, useEffect, useContext } from 'react';

// import '../../css/site.scss'; 

/** components */
import {
    IconButton
 } from '../../components';

/** stores & lib */
import { AuthStore } from '../../stores';


const SiteLayout = (props) => {

    useEffect(() => {
        loadPage();
    },[]);

    
    const loadPage = async() => {
        try {
            window.addEventListener("resize", handleResizeWindow);
            handleResizeWindow();    
        }
        catch(err){
        }
    }

    const { 
        title = "",
        content = null,
        isHeader = true,
        style

    } = props;

    const handleClickClose = () => {
        window.close();
    }

    const handleResizeWindow = (e) => {
        const h = e === undefined ? window.innerHeight : e.currentTarget.innerHeight;
        authStore.windowHeight = h;
    }

    const authStore = useContext(AuthStore);

    return(
        <>
        <div id="siteWrap">
            {
            isHeader &&            
            <div id="siteHeader" className="clearfix">
                <h1 className="site-tit fl-l">{ title }</h1>
                <IconButton
                    className="btn btn-icon-site-close fl-r"
                    alt="닫기"
                    onClick={handleClickClose}
                    marginRight={0}
                />
                <IconButton
                    className="btn btn-icon-site-reload fl-r"
                    alt="새로고침"
                    onClick={() => {
                        // authStore.history.push('/site/site');
                        window.location.reload();
                    }}
                    marginRight={60}
                />
                <IconButton
                    className="btn btn-icon-site-home fl-r"
                    alt="홈"
                    onClick={() => {
                        authStore.history.push('/site/site');
                    }}
                    marginRight={16}
                />
            </div>
            }
        </div>
        <div className="site-content"
            style={{...style}}
        >
            {content}
        </div>
        </>
    )
}

export default SiteLayout;
import React, { useState, useEffect, useContext } from 'react';
import {  Observer } from 'mobx-react-lite';
import {  TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공정 관리
 */
const ProcPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * Control Items (* Combo ...)
    // const [codeClassItems, setCodeClassItems] = useState([]);

    // * 검색
    const [procTp, setProcTp] = useState('');
    const [procNm, setProcNm] = useState('');
    const [useYn, setUseYn] = useState('');

    
    
    /* #region Search Items */
    
    const searchItems = [
        [
            {   title: "공정유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={procTp}
                    classId={'104'}
                    placeholder="공정유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setProcTp(v);
                    }}
                />
            },
            {   title: "공정명",
                width: "200px",
                item: 
                <TextBox 
                    value={procNm}
                    showClearButton={true}
                    placeholder="공정명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProcNm(e.value);
                    }}
                />        
            },
            {   title: "사용여부",
                item: 
                <UseYnSelectBox
                    value={useYn}
                    onValueChanged={(e)=>{
                        setUseYn(e.value);
                    }}
                />
            }
        ]
    ];

    /* #endregion */    
   
    // * Grid
   const [procList, setProcList] = useState([]);
   const [procListTot, setProcListTot] = useState(0);
   const [selectedItem, setSelectedItem] = useState(null);
   // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    // const dgCodeListRef = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });    

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, procTp, useYn]); 

    /* ====================
     *  Load
     * ==================== */


    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadList1 = async() => {
        const ps =  {
            p: {
                procTp: procTp, 
                procNm: procNm, 
                useYn: useYn,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getProcList", ps );
        if(r === undefined || r == null) return;

        setProcList(r.d0)
        setProcListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.procUid);
        }
    }

    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.procUid);
        }
    }
    
    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, procUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/procpop?${qs.stringify({mode: mode, uid: procUid})}`;        
        util.openWindow(url, 600, 360);    
    }

    /* ====================
     *  Render
     * ==================== */
 
          // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
    
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="공정 내역"
            >
            </GridTopView>
                   
            <DataGridView            
                dataSource={procList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={procListTot}   // store
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}       

                colsWidth={[100, 200, 100, cmn.CW.yn, null]}         
            >
                { cmn.getGridColumn('procTpNm', '공정유형', renderGridHeaderCell) } 
                { cmn.getGridColumn('procNm', '공정명', renderGridHeaderCell) } 
                { cmn.getGridColumn('procCd', '공정코드', renderGridHeaderCell) }     
                { cmn.getGridColumnUseYn() }         
                { cmn.getGridColumnCmnt() }
                
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ProcPage;
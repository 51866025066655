import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import { 
    DayDateBox, UseYnSwitchBox, CodeSelectBox,
    PopupLayout, ActionButton 
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  수입검사 불량처리 팝업
 */
const InInspBadPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,   // proc_insp_his_uid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    const [procInspHisUid, setProcInspHisUid] = useState(uid);
    const [procInspBadTreatUid, setProcInspBadTreatUid] = useState(null);

    const [prodNm, setProdNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [lot, setLot] = useState('');
    const [unitTpNm, setUnitTpNm] = useState('');

    const [bizNm, setBizNm] = useState('');
    const [procPrice, setProcPrice] = useState(null);
    const [inDy, setInDy] = useState('');    
    const [inspDt, setInspDt] = useState('');
    const [inspUserUid, setInspUserUid] = useState('');
    const [inspUserNm, setInspUserNm] = useState('');

    const [inAmt, setInAmt] = useState(null);
    const [badAmt, setBadAmt] = useState(null);
    const [goodAmt, setGoodAmt] = useState(null);
    const [badCont, setBadCont] = useState('');
    // const [badAmts, setBadAmts] = useState([]);

    const [badTreatTp, setBadTreatTp] = useState('');
    const [badTreatTpNm, setBadTreatTpNm] = useState('');
    const [claimYn, setClaimYn] = useState('N');
    const [claimAmt, setClaimAmt] = useState(0);
    const [claimPrice, setClaimPrice] = useState(0);
    const [claimWay, setClaimWay] = useState('');
    const [accountYrMon, setAccountYrMon] = useState(util.getNowMon());
    const [delYn, setDelYn] = useState('N');
    

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadProcInspHisInfo();
    },[procInspHisUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadProcInspHisInfo();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadProcInspHisInfo = async() => {
        try {     
            if(util.getChkNull(procInspHisUid) === '') return;

            const r =  await cmn.api("getProcInspBadTreatHis", {p: {procInspHisUid: procInspHisUid}} );
            if(r === undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;

            // ** 기본정보 표시
            setInspDt(util.getChkNull(d0.inspDt) === '' ? util.getDateToString('m', util.getNow(), '-') : d0.inspDt );
            setInspUserUid(util.getChkNull(d0.inspUserUid) === '' ? authStore.user.uid :  d0.inspUserUid);
            setInspUserNm(d0.inspUserNm);

            setUnitTpNm(d0.unitTpNm);

            setProcInspBadTreatUid(d0.procInspBadTreatUid);
            setProcInspHisUid(d0.procInspHisUid);
            setBadTreatTp(d0.badTreatTp);
            setBadTreatTpNm(d0.badTreatTpNm);
            setClaimYn(util.getChkNull(d0.claimYn, 'N'));
            setClaimAmt(util.getChkNull(d0.claimAmt, 0));
            setClaimPrice(util.getChkNull(d0.claimPrice, 0));
            setClaimWay(util.getChkNull(d0.claimWay));
            setAccountYrMon(util.getDateStrSplit('M', d0.accountYrMon));
        
            if(util.getChkNull(d0.procInspBadTreatUid) !== '')
                setDelYn('Y');

            setLot(d0.lot);
            setInDy(d0.inDy);
            setInAmt(d0.inAmt);
            setBadAmt(d0.badAmt);
            setGoodAmt(d0.goodAmt);
            // setUnitTp(d0.unitTp);
            // setUnitTpNm(d0.unitTpNm);

            setBizNm(d0.bizNm);
            
            setProdId(d0.prodId);
            setProdNm(d0.prodNm);
            // setProdProcUid(d0.prodProcUid);
            setProdStnd(d0.prodStnd);
            setProcPrice(d0.procPrice);

            let badCont_ = '';
            _.forEach(d1, (v, k) => {
                if(k > 0) badCont_ += ", ";
                badCont_ += `${v.badTpNm}: ${v.badAmt} (${d0.unitTpNm})`; 
            })
            
            setBadCont(badCont_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }       
    } 

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(badTreatTp, '불량처리 항목')) return;            
            if(!util.confirmYn('불량처리완료')) return;
            
            addBadTreat();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickDel = (e) => {
        try {
            if(!util.cmnConfirm('불량처리취소 시 부적합 개선 정보도 같이 삭제 됩니다. \n취소 하시겠습니까?')) return;
            
            delBadTreat();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    

    const addBadTreat = async() => {
        const ps = {
            p: {
                procInspBadTreatUid: procInspBadTreatUid,
                procInspHisUid: procInspHisUid,
                badTreatTp: badTreatTp,
                claimYn: claimYn,
                claimAmt: claimAmt,
                claimPrice: claimPrice,
                claimWay: claimWay,
                accountYrMon: util.getDateToString('M', accountYrMon),
                uptUserUid: authStore.user.uid,
            }
        }

      //  console.log(ps);
        const r = await cmn.api("addProcInspBadTreat", ps);
        if(r == null) return;

        util.cmnSucAlert("불량처리완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delBadTreat = async() => {
        const ps = {
            p: {
                procInspHisUid: procInspHisUid,
                procInspBadTreatUid: procInspBadTreatUid
            }
        }

        const r = await cmn.api("delProcInspBadTreat", ps);
        if(r == null) return;

        util.cmnSucAlert("취소완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "불량처리완료" 
            : mode === "u" ? "불량처리완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    width={140}
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
                {
                    delYn === 'Y' ?
                        <ActionButton 
                            width={140}
                            onClick={handleClickDel}
                            title={'불량처리취소'}
                            marginRight={0}
                            show={true}
                        />
                    :
                    ''
                }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"40%"} />
                        <col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>                           
                        </tr>   
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">Lot</th>
                            <td className="tcon">
                                <span>{lot}</span>
                            </td>                           
                        </tr>                  
                    </tbody>
                </table>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"40%"} />
                        <col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">입고업체</th>
                            <td className="tcon">
                                <span>{bizNm}</span>
                            </td>
                            <th className="tit">공정비용</th>
                            <td className="tcon">
                                <span>{util.getNumComma(procPrice)}</span>
                            </td>                           
                        </tr>   
                        <tr>
                            <th className="tit">검사일자</th>
                            <td className="tcon">
                                <span>{util.getDateToString('m', inspDt, '-')}</span>
                            </td>
                            <th className="tit">회계처리년월</th>
                            <td className="tcon">
                                {
                                    <DayDateBox 
                                        value={accountYrMon}
                                        type={'month'}
                                        displayFormat={cmn.DATE_FORMAT_MON_1}
                                        onValueChanged={(e) => {
                                            setAccountYrMon(e.value);
                                        }}                    
                                    />    
                                }
                            </td>                           
                        </tr>
                        <tr>
                            <th className="tit">입고량</th>
                            <td className="tcon">
                                <span>{`${util.getNumComma(inAmt)} (${unitTpNm})`}</span>
                            </td>
                            <th className="tit">양품수량</th>
                            <td className="tcon">
                                <span>{`${util.getNumComma(goodAmt)} (${unitTpNm})`}</span>
                            </td>                           
                        </tr>                     
                        <tr>
                            <th className="tit">불량수량</th>
                            <td className="tcon">
                                <span>{`${util.getNumComma(badAmt)} (${unitTpNm})`}</span>
                            </td>
                            <th className="tit">손실비용</th>
                            <td className="tcon">
                                <span>{`${util.getNumComma(procPrice*badAmt)} (원)`}</span>
                            </td>                           
                        </tr> 
                        <tr>
                            <th className="tit">불량내용</th>
                            <td className="tcon" colSpan={3}>
                                <span>{badCont}</span>
                            </td>                           
                        </tr> 
                    </tbody>
                </table>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"40%"} />
                        <col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">클레임 여부</th>
                            <td className="tcon">
                            {
                                mode === "a"  || mode === "u" ?
                                <UseYnSwitchBox 
                                    value={claimYn}
                                    onValueChanged={(v)=>{
                                        setClaimYn(v);
                                    }} 
                                />
                                :
                                <span>{claimYn}</span>
                            }
                            </td>
                            <th className="tit">클레임 수량</th>
                            <td className="tcon">
                            {
                                mode === "a"  || mode === "u" ?
                                <NumberBox
                                    width={80}
                                    format={'#,##0,###'}
                                    value={claimAmt}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setClaimAmt(e.value);
                                    }} 
                                />
                                :
                                <span>{util.getNumComma(claimAmt)}</span>
                            }
                            </td>                           
                        </tr>   
                        <tr>
                            <th className="tit req">불량처리</th>
                            <td className="tcon">
                            {
                                mode === "a"  || mode === "u" ?
                                    <CodeSelectBox
                                        value={badTreatTp}
                                        width={200}
                                        classId={'410'}
                                        placeholder="불량처리유형을 선택하세요."
                                        onValueChanged={(v, o) => { 
                                            setBadTreatTp(v);
                                        }}
                                    /> 
                                :
                                <span>{badTreatTpNm}</span>
                            }
                            </td>
                            <th className="tit">클레임 비용</th>
                            <td className="tcon">
                            {
                                mode === "a"  || mode === "u" ?
                                <NumberBox
                                    width={80}
                                    format={'#,##0,###'}
                                    value={claimPrice}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setClaimPrice(e.value);
                                    }} 
                                />
                                :
                                <span>{util.getNumComma(claimPrice)}</span>
                            }
                            </td>       
                        </tr>  
                        <tr>
                            <th className="tit">클레임 방법</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a"  || mode === "u" ?
                                    <TextBox 
                                        value={claimWay}
                                        showClearButton={true}
                                        placeholder="클레임 방법을 입력하세요."
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setClaimWay(e.value);
                                        }} 
                                    />
                                :
                                    <span>{claimWay}</span>
                                }
                            </td>
                        </tr>                    
                    </tbody>
                </table>
            </div> 
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "수입검사 불량처리" 
                : mode === "u" ? "수입검사 불량처리"
                : "수입검사 불량처리" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default InInspBadPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 예비부품 관리
 */
const EqupBkupPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * Control Items (* Combo ...)
    const [equpTpItems, setEqupTpItems] = useState([]);

    // * 검색
    const [partNm, setPartNm] = useState('');
    const [partStnd, setPartStnd] = useState('');
    const [saveLoca, setSaveLoca] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "부품명",
                width: "200px",
                item: 
                <TextBox 
                    value={partNm}
                    showClearButton={true}
                    placeholder="부품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setPartNm(e.value);
                    }}
                />
            },
            {   title: "부품규격",
                width: "200px",
                item: 
                <TextBox 
                    width="200px"
                    value={partStnd}
                    showClearButton={true}
                    placeholder="부품규격으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setPartStnd(e.value);
                    }}
                />                      
            },
            {   title: "보관위치",
                item: 
                <TextBox 
                    width="200px"
                    value={saveLoca}
                    showClearButton={true}
                    placeholder="보관위치로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setSaveLoca(e.value);
                    }}
                />                      
            }
        ]
    ];

    // * Grid
    const [bkupPartList, setBkupPartList] = useState([]);
    const [bkupPartListTot, setBkupPartListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                partNm: partNm,
                partStnd: partStnd,
                saveLoca: saveLoca,

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getBkupPartList", ps );
        if(r === undefined || r == null) return;

        setBkupPartList(r.d0)
        setBkupPartListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a");
    }

    const handleClickUp = (e) => {
        if(!util.chkListSelected(selectedItem, '예비부품을')) return;
        openCrudPopup("u", selectedItem.bkupPartUid);
    }

    const handleClickIn = (e) => {
        if(!util.chkListSelected(selectedItem, '예비부품을')) return;
        openInPopup("a", selectedItem.bkupPartUid);
    }

    const handleClickOut = (e) => {
        if(!util.chkListSelected(selectedItem, '예비부품을')) return;
        openOutPopup("a", selectedItem.bkupPartUid);
    }
    
    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType != "data") return;
        if(gc.column.dataField == "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField == "useYn" 
            ) e.value = gc.value == 'Y' ? "○" : "";
        else if(gc.column.dataField == "safeStock" 
            ||  gc.column.dataField == "curStock"
            ||  gc.column.dataField == "makeTakeDyCnt"
            ||  gc.column.dataField == "makeCost"
            ||  gc.column.dataField == "equpCnt"
            ) e.value = util.getNumComma(gc.value);     
        else if(
                gc.column.dataField == "fileInfo" 
        )   e.value = '';
    }  

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, bkupPartUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equpbkuppop?${qs.stringify({mode: mode, uid: bkupPartUid})}`;        
        util.openWindow(url, 800, 700);    
    }

    const openInPopup = (mode, bkupPartUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equpbkupinpop?${qs.stringify({mode: mode, uid: bkupPartUid})}`;        
        util.openWindow(url, 800, 700);    
    }

    const openOutPopup = (mode, bkupPartUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equpbkupoutpop?${qs.stringify({mode: mode, uid: bkupPartUid})}`;        
        util.openWindow(url, 800, 700);    
    }

    

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickIn}
                    title="부품입고"
                    type="2"
                    marginRight={0}
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickOut}
                    title="부품출고"
                    type="2"
                    marginRight={0}
                    show={authStore.page.upYn}
                />
            </ListTopView>   
            }</Observer>    
            <ListSearchView searchItems={searchItems} />
            <GridTopView title="예비부품 내역">
                {/* <IconButton className="btn-icon-print" /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>
            <Observer>{() =>
            <>        
            <DataGridView            
                reff={grid1}
                dataSource={bkupPartList}   
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[100, 200, 120, 100, 100, 100, 80, 80, 120, 80, 100, null]}

                // paging
                page={curPage}
                total={bkupPartListTot}   
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}
               // excel
               exlnm={'예비부품내역'}
               customizeExcelCell={customizeExcelCell}                       
           >
                { cmn.getGridColumnImg('fileInfo', '부품사진') }
                { cmn.getGridColumn('partNm', '부품명', renderGridHeaderCell ) }
                { cmn.getGridColumn('partStnd', '규격', renderGridHeaderCell ) }
                { cmn.getGridColumnNumCommaType('safeStock', '안전재고', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('curStock', '현재재고', renderGridHeaderCell) }
                { cmn.getGridColumn('holdGrade', '보유등급', renderGridHeaderCell ) }
                { cmn.getGridColumnNumCommaType('makeTakeDyCnt', '제작소요일', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('makeCost', '비용', renderGridHeaderCell) }
                { cmn.getGridColumn('saveLoca', '보관위치', renderGridHeaderCell ) }
                { cmn.getGridColumnUseYn('useYn', '사용여부', renderGridHeaderCell) }  
                { cmn.getGridColumnNumCommaType('equpCnt', '사용설비수', renderGridHeaderCell) }
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
            }</Observer>
            </>
        );
    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupBkupPage;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    ProdSearchBox,
    PopupLayout, UseYnSwitchBox,
    ActionButton,
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  고객제품 등록 / 수정
 */
const BizProdPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        buid = ls.buid,
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [bizPrice, setBizPrice] = useState(0);
    const [dealYn, setDealYn] = useState('Y');

    useEffect(() => {
        loadPage();
    },[]);


    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    const loadInfo1 = async() => {
        if(util.getChkNull(mode) === "a") return;
        if(util.getChkNull(uid) === "") return;

        const ps = {p: {bizProdUid: uid}};

        const r = await cmn.api('getBizProd', ps);
        if(r == null || r === undefined) return;

        const data = r.d0[0];

        setBizPrice(data.bizPrice);
        setDealYn(data.dealYn);
        setProdId(data.prodId);
        setProdUid(data.prodUid);
        setProdNm(data.prodNm);
        setProdStnd(data.prodStnd);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        if(mode === "a")
        { 
            if(util.confirmYn("등록"))
                addBizProd();
        }
        else if(mode === "u") 
        {
            if(util.confirmYn("수정"))
                upBizProd();
        }
    }

    const addBizProd = async() => {
       // alert(unitTp);
        const ps = {
            p: {
                bizProdUid: util.getUUID(),
                bizUid: buid,
                prodUid: prodUid,
                bizPrice: bizPrice,
                // unitTp: unitTp,
                // boxCnt: boxCnt,
                dealYn: dealYn,
                uptUserUid: authStore.user.uid
            }
        }

        let r = await cmn.api('addBizProd', ps);
        if(r == null || r === undefined) return;
    
        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    const upBizProd = async() => {
        const ps = {
            p: {
                bizProdUid: uid,
                bizPrice: bizPrice,
                // unitTp: unitTp,
                // boxCnt: boxCnt,
                dealYn: dealYn,
                uptUserUid: authStore.user.uid
            }
        }

        let r = await cmn.api('upBizProd', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");
        // Reload Opener List & Close Popup
        // util.clickOpenerElement("srchButton");
        util.clickOpenerElement("subSrchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">품번</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" ?
                                    <ProdSearchBox
                                        value={prodUid}
                                        popCenter={'center'}
                                        onLoadInfo={(o) => {
                                            setProdNm(util.getVal(o, 'prodNm'));
                                            setProdStnd(util.getVal(o, 'prodStnd'));
                                            // setUnitTp(util.getVal(o, 'unitTp'));
                                            // setUnitTpNm(util.getVal(o, 'unitTpNm'));
                                        }} 
                                        onValueChanged={(v) => {                                        
                                            setProdUid(v);
                                        }}
                                    />
                                :
                                <span>{prodId}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">단가</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <NumberBox
                                    width={80}
                                    format={'#,##0'}
                                    value={bizPrice}
                                    defaultValue={0}
                                    min={0}
                                    // max={20}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setBizPrice(e.value);
                                    }} 
                                />
                                :
                                    <span>{bizPrice}</span>
                                }
                            </td>
                            <th className="tit">거래여부</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={dealYn}
                                        onValueChanged={(v)=>{
                                            setDealYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{dealYn}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "고객제품 등록" 
                : mode === "u" ? "고객제품 수정"
                : "고객제품" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default BizProdPopPage;
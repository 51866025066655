import React, { useState, useEffect, useContext } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { FileUploader, CheckBox, NumberBox, DateBox, Calendar, CalendarOptions, SelectBox, TextBox, TextArea } from 'devextreme-react';
import DataGrid, {
    Column,
    Editing,
    Paging,
    Selection,
    KeyboardNavigation,
    Lookup,
    ColumnChooser
  } from 'devextreme-react/data-grid';
  
import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    Legend,
    Margin,
    Title,
    Subtitle,
    Tooltip,
    Grid
  } from 'devextreme-react/chart';

import qs from 'query-string';
// import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
// import TreeList, { Selection, Column } from 'devextreme-react/tree-list';
// import { FORMATS_TO_PATTERN_MAP, date, locale, loadMessages, formatMessage } from 'devextreme/localization';
import useKey from 'use-key-hook';
import _ from 'lodash';
import moment from 'moment';

// import 'devextreme/localization/globalize/date';
// import { locale, loadMessages } from "devextreme/localization";

/** components */
import {
    ListTopView, GridTopView, DataGridView, 
    UseYnSelectBox, CheckListBox, DayDateBox, FileUploadBox,
    SrchButton, ActionButton, IconButton, EditGridView,
} from '../components';

/** stores & lib */
import { cmn, util } from '../lib';

/** images */

/** 
 *  메인 페이지
 */
const Sample1Page = (props) => {

   
    /* ====================
     *  Store 
     * ==================== */

    //const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */


    /* ====================
     *  State / Effect
     * ==================== */

    const [pageLoaded, setPageLoaded] = useState(false);    

    const [dy1, setDy1] = useState('');
    const [mon, setMon] = useState('2020-04');
    const [yr, setYr] = useState('2020');
    const [dateVal, setDateVal] = useState('');

    const [checkListItems, setCheckListItems] = useState(
        [
            {key: "aa", text: "본사", checked: true},
            {key: "bb", text: "고객사", checked: false},
            {key: "cc", text: "협력사", checked: false},
        ]
    );

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([
        // {
        //     destination: "d:/files/temp",
        //     encoding: "7bit",
        //     fieldname: "files[]",
        //     filename: "2ceb7a237c8726395d541340264f4972",
        //     mimetype: "image/png",
        //     originalname: "파일1111.pdf",
        //     path: "d:\\files\\temp\\2ceb7a237c8726395d541340264f4972",
        //     size: 642569
        // },
        // {
        //     destination: "d:/files/temp",
        //     encoding: "7bit",
        //     fieldname: "files[]",
        //     filename: "2ceb7a237c87263223341340264f4972",
        //     mimetype: "image/png",
        //     originalname: "파일2222.png",
        //     path: "d:\\files\\temp\\2ceb7a237c8726395d541340264f4972",
        //     size: 642569
        // }
    ]);

    const [gridItems, setGridItems] = useState([
        {
            'ID': 1,
            'Idx': 1,
            'FirstName': 'John',
            'LastName': 'Heart',
            'Prefix': 'Mr.',
            'Position': 'CEO',
            'BirthDate': '1964/03/16',
            'HireDate': '1995/01/15',
            'Notes': 'John has been in the Audio/Video industry since 1990. He has led DevAv as its CEO since 2003.\r\n\r\nWhen not working hard as the CEO, John loves to golf and bowl. He once bowled a perfect game of 300.',
            'Address': '351 S Hill St.',
            'StateID': 5
          }, {
            'ID': 2,
            'Idx': 2,
            'FirstName': 'Olivia',
            'LastName': 'Peyton',
            'Prefix': 'Mrs.',
            'Position': 'Sales Assistant',
            'BirthDate': '1981/06/03',
            'HireDate': '2012/05/14',
            'Notes': 'Olivia loves to sell. She has been selling DevAV products since 2012. \r\n\r\nOlivia was homecoming queen in high school. She is expecting her first child in 6 months. Good Luck Olivia.',
            'Address': '807 W Paseo Del Mar',
            'StateID': 5
          }, {
            'ID': 3,
            'Idx': 3,
            'FirstName': 'Robert',
            'LastName': 'Reagan',
            'Prefix': 'Mr.',
            'Position': 'CMO',
            'BirthDate': '1974/09/07',
            'HireDate': '2002/11/08',
            'Notes': 'Robert was recently voted the CMO of the year by CMO Magazine. He is a proud member of the DevAV Management Team.\r\n\r\nRobert is a championship BBQ chef, so when you get the chance ask him for his secret recipe.',
            'Address': '4 Westmoreland Pl.',
            'StateID': 4
          }
    ]);

    const [selectedItemKeys, setSelectedItemKeys] = useState([]);

    const [txt, setTxt] = useState('');

    const f = (v) => {
        console.log(v);
    }

    const ff = (v) => {
        f(v);
        f(v);
    }

    let orders = [{
        'ID': 1,
        'OrderNumber': 35703,
        'OrderDate': '2014/04/10',
        'SaleAmount': 11800,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Los Angeles',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 4,
        'OrderNumber': 35711,
        'OrderDate': '2014/01/12',
        'SaleAmount': 16050,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'San Jose',
        'Employee': 'Jim Packard'
      }, {
        'ID': 5,
        'OrderNumber': 35714,
        'OrderDate': '2014/01/22',
        'SaleAmount': 14750,
        'Terms': '15 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 7,
        'OrderNumber': 35983,
        'OrderDate': '2014/02/07',
        'SaleAmount': 3725,
        'Terms': '15 Days',
        'CustomerStoreState': 'Colorado',
        'CustomerStoreCity': 'Denver',
        'Employee': 'Todd Hoffman'
      }, {
        'ID': 9,
        'OrderNumber': 36987,
        'OrderDate': '2014/03/11',
        'SaleAmount': 14200,
        'Terms': '15 Days',
        'CustomerStoreState': 'Utah',
        'CustomerStoreCity': 'Salt Lake City',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 11,
        'OrderNumber': 38466,
        'OrderDate': '2014/03/01',
        'SaleAmount': 7800,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Los Angeles',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 14,
        'OrderNumber': 39420,
        'OrderDate': '2014/02/15',
        'SaleAmount': 20500,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'San Jose',
        'Employee': 'Jim Packard'
      }, {
        'ID': 15,
        'OrderNumber': 39874,
        'OrderDate': '2014/02/04',
        'SaleAmount': 9050,
        'Terms': '30 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 18,
        'OrderNumber': 42847,
        'OrderDate': '2014/02/15',
        'SaleAmount': 20400,
        'Terms': '30 Days',
        'CustomerStoreState': 'Wyoming',
        'CustomerStoreCity': 'Casper',
        'Employee': 'Todd Hoffman'
      }, {
        'ID': 19,
        'OrderNumber': 43982,
        'OrderDate': '2014/05/29',
        'SaleAmount': 6050,
        'Terms': '30 Days',
        'CustomerStoreState': 'Utah',
        'CustomerStoreCity': 'Salt Lake City',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 29,
        'OrderNumber': 56272,
        'OrderDate': '2014/02/06',
        'SaleAmount': 15850,
        'Terms': '30 Days',
        'CustomerStoreState': 'Utah',
        'CustomerStoreCity': 'Salt Lake City',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 30,
        'OrderNumber': 57429,
        'OrderDate': '2014/05/16',
        'SaleAmount': 11050,
        'Terms': '30 Days',
        'CustomerStoreState': 'Arizona',
        'CustomerStoreCity': 'Phoenix',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 32,
        'OrderNumber': 58292,
        'OrderDate': '2014/05/13',
        'SaleAmount': 13500,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Los Angeles',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 36,
        'OrderNumber': 62427,
        'OrderDate': '2014/01/27',
        'SaleAmount': 23500,
        'Terms': '15 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 39,
        'OrderNumber': 65977,
        'OrderDate': '2014/02/05',
        'SaleAmount': 2550,
        'Terms': '15 Days',
        'CustomerStoreState': 'Wyoming',
        'CustomerStoreCity': 'Casper',
        'Employee': 'Todd Hoffman'
      }, {
        'ID': 40,
        'OrderNumber': 66947,
        'OrderDate': '2014/03/23',
        'SaleAmount': 3500,
        'Terms': '15 Days',
        'CustomerStoreState': 'Utah',
        'CustomerStoreCity': 'Salt Lake City',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 42,
        'OrderNumber': 68428,
        'OrderDate': '2014/04/10',
        'SaleAmount': 10500,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Los Angeles',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 43,
        'OrderNumber': 69477,
        'OrderDate': '2014/03/09',
        'SaleAmount': 14200,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Anaheim',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 46,
        'OrderNumber': 72947,
        'OrderDate': '2014/01/14',
        'SaleAmount': 13350,
        'Terms': '30 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 47,
        'OrderNumber': 73088,
        'OrderDate': '2014/03/25',
        'SaleAmount': 8600,
        'Terms': '30 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Reno',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 50,
        'OrderNumber': 76927,
        'OrderDate': '2014/04/27',
        'SaleAmount': 9800,
        'Terms': '30 Days',
        'CustomerStoreState': 'Utah',
        'CustomerStoreCity': 'Salt Lake City',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 51,
        'OrderNumber': 77297,
        'OrderDate': '2014/04/30',
        'SaleAmount': 10850,
        'Terms': '30 Days',
        'CustomerStoreState': 'Arizona',
        'CustomerStoreCity': 'Phoenix',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 56,
        'OrderNumber': 84744,
        'OrderDate': '2014/02/10',
        'SaleAmount': 4650,
        'Terms': '30 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 57,
        'OrderNumber': 85028,
        'OrderDate': '2014/05/17',
        'SaleAmount': 2575,
        'Terms': '30 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Reno',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 59,
        'OrderNumber': 87297,
        'OrderDate': '2014/04/21',
        'SaleAmount': 14200,
        'Terms': '30 Days',
        'CustomerStoreState': 'Wyoming',
        'CustomerStoreCity': 'Casper',
        'Employee': 'Todd Hoffman'
      }, {
        'ID': 60,
        'OrderNumber': 88027,
        'OrderDate': '2014/02/14',
        'SaleAmount': 13650,
        'Terms': '30 Days',
        'CustomerStoreState': 'Utah',
        'CustomerStoreCity': 'Salt Lake City',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 65,
        'OrderNumber': 94726,
        'OrderDate': '2014/05/22',
        'SaleAmount': 20500,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'San Jose',
        'Employee': 'Jim Packard'
      }, {
        'ID': 66,
        'OrderNumber': 95266,
        'OrderDate': '2014/03/10',
        'SaleAmount': 9050,
        'Terms': '15 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 69,
        'OrderNumber': 98477,
        'OrderDate': '2014/01/01',
        'SaleAmount': 23500,
        'Terms': '15 Days',
        'CustomerStoreState': 'Wyoming',
        'CustomerStoreCity': 'Casper',
        'Employee': 'Todd Hoffman'
      }, {
        'ID': 70,
        'OrderNumber': 99247,
        'OrderDate': '2014/02/08',
        'SaleAmount': 2100,
        'Terms': '15 Days',
        'CustomerStoreState': 'Utah',
        'CustomerStoreCity': 'Salt Lake City',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 78,
        'OrderNumber': 174884,
        'OrderDate': '2014/04/10',
        'SaleAmount': 7200,
        'Terms': '30 Days',
        'CustomerStoreState': 'Colorado',
        'CustomerStoreCity': 'Denver',
        'Employee': 'Todd Hoffman'
      }, {
        'ID': 81,
        'OrderNumber': 188877,
        'OrderDate': '2014/02/11',
        'SaleAmount': 8750,
        'Terms': '30 Days',
        'CustomerStoreState': 'Arizona',
        'CustomerStoreCity': 'Phoenix',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 82,
        'OrderNumber': 191883,
        'OrderDate': '2014/02/05',
        'SaleAmount': 9900,
        'Terms': '30 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Los Angeles',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 83,
        'OrderNumber': 192474,
        'OrderDate': '2014/01/21',
        'SaleAmount': 12800,
        'Terms': '30 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Anaheim',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 84,
        'OrderNumber': 193847,
        'OrderDate': '2014/03/21',
        'SaleAmount': 14100,
        'Terms': '30 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'San Diego',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 85,
        'OrderNumber': 194877,
        'OrderDate': '2014/03/06',
        'SaleAmount': 4750,
        'Terms': '30 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'San Jose',
        'Employee': 'Jim Packard'
      }, {
        'ID': 86,
        'OrderNumber': 195746,
        'OrderDate': '2014/05/26',
        'SaleAmount': 9050,
        'Terms': '30 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
      }, {
        'ID': 87,
        'OrderNumber': 197474,
        'OrderDate': '2014/03/02',
        'SaleAmount': 6400,
        'Terms': '30 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Reno',
        'Employee': 'Clark Morgan'
      }, {
        'ID': 88,
        'OrderNumber': 198746,
        'OrderDate': '2014/05/09',
        'SaleAmount': 15700,
        'Terms': '30 Days',
        'CustomerStoreState': 'Colorado',
        'CustomerStoreCity': 'Denver',
        'Employee': 'Todd Hoffman'
      }, {
        'ID': 91,
        'OrderNumber': 214222,
        'OrderDate': '2014/02/08',
        'SaleAmount': 11050,
        'Terms': '30 Days',
        'CustomerStoreState': 'Arizona',
        'CustomerStoreCity': 'Phoenix',
        'Employee': 'Clark Morgan'
      }];
      
      let orders2 = [{
        'ID': 1,
        'OrderNumber': 35703,
        'OrderDate': '2014/04/10',
        'SaleAmount': 11800,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'Los Angeles',
        'Employee': 'Harv Mudd'
        ,'Check': true
      }, {
        'ID': 4,
        'OrderNumber': 35711,
        'OrderDate': '2014/01/12',
        'SaleAmount': 16050,
        'Terms': '15 Days',
        'CustomerStoreState': 'California',
        'CustomerStoreCity': 'San Jose',
        'Employee': 'Jim Packard'
        ,'Check': true
      }, {
        'ID': 5,
        'OrderNumber': 35714,
        'OrderDate': '2014/01/22',
        'SaleAmount': 14750,
        'Terms': '15 Days',
        'CustomerStoreState': 'Nevada',
        'CustomerStoreCity': 'Las Vegas',
        'Employee': 'Harv Mudd'
        ,'Check': false
      }];

    //   export default {
    //     getOrders() {
    //       return orders;
    //     }
    //   };
      

    //const [codeNm, setCodeNm] = useState('');

    // useEffect(() => {
    //     // console.log("===locale", locale);
    //     // console.log("===FORMATS_TO_PATTERN_MAP", FORMATS_TO_PATTERN_MAP);
    //     // Page Load Handling
    //     if(pageLoaded) return;
    //     loadPage();
    //     setPageLoaded(true);
    // });

     
    const states = [{
        'ID': 1,
        'Name': 'Alabama'
      }, {
        'ID': 2,
        'Name': 'Alaska'
      }, {
        'ID': 3,
        'Name': 'Arizona'
      }, {
        'ID': 4,
        'Name': 'Arkansas'
      }, {
        'ID': 5,
        'Name': 'California'
      }, {
        'ID': 6,
        'Name': 'Colorado'
      }, {
        'ID': 7,
        'Name': 'Connecticut'
      }, {
        'ID': 8,
        'Name': 'Delaware'
      }, {
        'ID': 9,
        'Name': 'District of Columbia'
      }, {
        'ID': 10,
        'Name': 'Florida'
      }, {
        'ID': 11,
        'Name': 'Georgia'
      }, {
        'ID': 12,
        'Name': 'Hawaii'
      }, {
        'ID': 13,
        'Name': 'Idaho'
      }, {
        'ID': 14,
        'Name': 'Illinois'
      }, {
        'ID': 15,
        'Name': 'Indiana'
      }, {
        'ID': 16,
        'Name': 'Iowa'
      }, {
        'ID': 17,
        'Name': 'Kansas'
      }, {
        'ID': 18,
        'Name': 'Kentucky'
      }, {
        'ID': 19,
        'Name': 'Louisiana'
      }, {
        'ID': 20,
        'Name': 'Maine'
      }, {
        'ID': 21,
        'Name': 'Maryland'
      }, {
        'ID': 22,
        'Name': 'Massachusetts'
      }, {
        'ID': 23,
        'Name': 'Michigan'
      }, {
        'ID': 24,
        'Name': 'Minnesota'
      }, {
        'ID': 25,
        'Name': 'Mississippi'
      }, {
        'ID': 26,
        'Name': 'Missouri'
      }, {
        'ID': 27,
        'Name': 'Montana'
      }, {
        'ID': 28,
        'Name': 'Nebraska'
      }, {
        'ID': 29,
        'Name': 'Nevada'
      }, {
        'ID': 30,
        'Name': 'New Hampshire'
      }, {
        'ID': 31,
        'Name': 'New Jersey'
      }, {
        'ID': 32,
        'Name': 'New Mexico'
      }, {
        'ID': 33,
        'Name': 'New York'
      }, {
        'ID': 34,
        'Name': 'North Carolina'
      }, {
        'ID': 35,
        'Name': 'Ohio'
      }, {
        'ID': 36,
        'Name': 'Oklahoma'
      }, {
        'ID': 37,
        'Name': 'Oregon'
      }, {
        'ID': 38,
        'Name': 'Pennsylvania'
      }, {
        'ID': 39,
        'Name': 'Rhode Island'
      }, {
        'ID': 40,
        'Name': 'South Carolina'
      }, {
        'ID': 41,
        'Name': 'South Dakota'
      }, {
        'ID': 42,
        'Name': 'Tennessee'
      }, {
        'ID': 43,
        'Name': 'Texas'
      }, {
        'ID': 44,
        'Name': 'Utah'
      }, {
        'ID': 45,
        'Name': 'Vermont'
      }, {
        'ID': 46,
        'Name': 'Virginia'
      }, {
        'ID': 47,
        'Name': 'Washington'
      }, {
        'ID': 48,
        'Name': 'West Virginia'
      }, {
        'ID': 49,
        'Name': 'Wisconsin'
      }, {
        'ID': 50,
        'Name': 'Wyoming'
      }, {
        'ID': 51,
        'Name': 'North Dakota'
      }];

    let employees = [{
        'ID': 1,
        'FirstName': 'John',
        'LastName': 'Heart',
        'Prefix': 'Mr.',
        'Position': 'CEO',
        'BirthDate': '1964/03/16',
        'HireDate': '1995/01/15',
        'Notes': 'John has been in the Audio/Video industry since 1990. He has led DevAv as its CEO since 2003.\r\n\r\nWhen not working hard as the CEO, John loves to golf and bowl. He once bowled a perfect game of 300.',
        'Address': '351 S Hill St.',
        'StateID': 5
      }, {
        'ID': 2,
        'FirstName': 'Olivia',
        'LastName': 'Peyton',
        'Prefix': 'Mrs.',
        'Position': 'Sales Assistant',
        'BirthDate': '1981/06/03',
        'HireDate': '2012/05/14',
        'Notes': 'Olivia loves to sell. She has been selling DevAV products since 2012. \r\n\r\nOlivia was homecoming queen in high school. She is expecting her first child in 6 months. Good Luck Olivia.',
        'Address': '807 W Paseo Del Mar',
        'StateID': 5
      }, {
        'ID': 3,
        'FirstName': 'Robert',
        'LastName': 'Reagan',
        'Prefix': 'Mr.',
        'Position': 'CMO',
        'BirthDate': '1974/09/07',
        'HireDate': '2002/11/08',
        'Notes': 'Robert was recently voted the CMO of the year by CMO Magazine. He is a proud member of the DevAV Management Team.\r\n\r\nRobert is a championship BBQ chef, so when you get the chance ask him for his secret recipe.',
        'Address': '4 Westmoreland Pl.',
        'StateID': 4
      }, {
        'ID': 4,
        'FirstName': 'Greta',
        'LastName': 'Sims',
        'Prefix': 'Ms.',
        'Position': 'HR Manager',
        'BirthDate': '1977/11/22',
        'HireDate': '1998/04/23',
        'Notes': "Greta has been DevAV's HR Manager since 2003. She joined DevAV from Sonee Corp.\r\n\r\nGreta is currently training for the NYC marathon. Her best marathon time is 4 hours. Go Greta.",
        'Address': '1700 S Grandview Dr.',
        'StateID': 11
      }, {
        'ID': 5,
        'FirstName': 'Brett',
        'LastName': 'Wade',
        'Prefix': 'Mr.',
        'Position': 'IT Manager',
        'BirthDate': '1968/12/01',
        'HireDate': '2009/03/06',
        'Notes': 'Brett came to DevAv from Microsoft and has led our IT department since 2012.\r\n\r\nWhen he is not working hard for DevAV, he coaches Little League (he was a high school pitcher).',
        'Address': '1120 Old Mill Rd.',
        'StateID': 13
      }, {
        'ID': 6,
        'FirstName': 'Sandra',
        'LastName': 'Johnson',
        'Prefix': 'Mrs.',
        'Position': 'Controller',
        'BirthDate': '1974/11/15',
        'HireDate': '2005/05/11',
        'Notes': "Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you've not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.",
        'Address': '4600 N Virginia Rd.',
        'StateID': 44
      }, {
        'ID': 7,
        'FirstName': 'Kevin',
        'LastName': 'Carter',
        'Prefix': 'Mr.',
        'Position': 'Shipping Manager',
        'BirthDate': '1978/01/09',
        'HireDate': '2009/08/11',
        'Notes': 'Kevin is our hard-working shipping manager and has been helping that department work like clockwork for 18 months.\r\n\r\nWhen not in the office, he is usually on the basketball court playing pick-up games.',
        'Address': '424 N Main St.',
        'StateID': 5
      }, {
        'ID': 8,
        'FirstName': 'Cynthia',
        'LastName': 'Stanwick',
        'Prefix': 'Ms.',
        'Position': 'HR Assistant',
        'BirthDate': '1985/06/05',
        'HireDate': '2008/03/24',
        'Notes': 'Cindy joined us in 2008 and has been in the HR department for 2 years. \r\n\r\nShe was recently awarded employee of the month. Way to go Cindy!',
        'Address': '2211 Bonita Dr.',
        'StateID': 4
      }, {
        'ID': 9,
        'FirstName': 'Kent',
        'LastName': 'Samuelson',
        'Prefix': 'Dr.',
        'Position': 'Ombudsman',
        'BirthDate': '1972/09/11',
        'HireDate': '2009/04/22',
        'Notes': 'As our ombudsman, Kent is on the front-lines solving customer problems and helping our partners address issues out in the field.    He is a classically trained musician and is a member of the Chamber Orchestra.',
        'Address': '12100 Mora Dr',
        'StateID': 26
      }, {
        'ID': 10,
        'FirstName': 'Taylor',
        'LastName': 'Riley',
        'Prefix': 'Mr.',
        'Position': 'Network Admin',
        'BirthDate': '1982/08/14',
        'HireDate': '2012/04/14',
        'Notes': "If you are like the rest of us at DevAV, then you've probably reached out for help from Taylor. He does a great job as a member of our IT department.",
        'Address': '7776 Torreyson Dr',
        'StateID': 5
      }, {
        'ID': 11,
        'FirstName': 'Sam',
        'LastName': 'Hill',
        'Prefix': 'Mr.',
        'Position': 'Sales Assistant',
        'BirthDate': '1984/02/17',
        'HireDate': '2012/02/01',
        'Notes': 'Sammy is proud to be a member of the DevAV team. He joined the team in 2012 and has been in the sales department from the beginning.\r\n\r\nHe has just picked up golf so you can find him on the links every weekend.',
        'Address': '645 Prospect Crescent',
        'StateID': 11
      }, {
        'ID': 12,
        'FirstName': 'Kelly',
        'LastName': 'Rodriguez',
        'Prefix': 'Ms.',
        'Position': 'Support Assistant',
        'BirthDate': '1988/05/11',
        'HireDate': '2012/10/13',
        'Notes': "Kelly loves people and that's why she joined DevAV's support department. One of the funniest people in the company, she does stand-up on the weekends at the Laugh Factory.",
        'Address': '1601 W Mountain St.',
        'StateID': 5
      }, {
        'ID': 13,
        'FirstName': 'Natalie',
        'LastName': 'Maguirre',
        'Prefix': 'Mrs.',
        'Position': 'Trainer',
        'BirthDate': '1977/10/07',
        'HireDate': '2008/06/19',
        'Notes': 'Natalie travels the US and teaches our partners how to explain the benefits of our products to customers.\r\n\r\nShe is a proud wife and mom and volunteers her time at the elementary school.',
        'Address': '6400 E Bixby Hill Rd',
        'StateID': 29
      }, {
        'ID': 14,
        'FirstName': 'Walter',
        'LastName': 'Hobbs',
        'Prefix': 'Mr.',
        'Position': 'Programmer',
        'BirthDate': '1984/12/24',
        'HireDate': '2011/02/17',
        'Notes': "Walter has been developing apps and websites for DevAV since 2011. His passion is software and if you ever walk by his desk, you'll know why.\r\n\r\nWally once worked 72 hours straight - writing code and fixing bugs.",
        'Address': '10385 Shadow Oak Dr',
        'StateID': 13
      }];

    /* ====================
     *  Load
     * ==================== */

    // const loadPage = async() => {
    //     authStore.loadPage(props);
        
    // }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const renderCalendarCell = (e) => {
        console.log("=== e renderCalendarCell", e);
    }

    const handleChange = (e) => {
        console.log("=== handleChange", e);
    }

    const handleClickProcFlowTag = (e) => {
        const url = `/cmn/procflowtagpop?${qs.stringify({})}`;        
        util.openWindow(url, 820, 840); 
    }

    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {       
        
        return (
            <>
            <div>년도선택</div>
            <div>
                <DateBox 
                    width={120}
                    value={yr}
                    type={"date"}
                    // showClearButton={true}
                    
                    maxZoomLevel={"decade"}
                    minZoomLevel={"decade"}
                    zoomLevel={"decade"}

                    pickerType={'list'}
                    showDropDownButton={false}

                       
                // 'calendar' | 'list' | 'native' | 'rollers'

                    // calendarOptions={{
                    //     showTodayButton: type === 'day' ? true : false,
                    //     maxZoomLevel: type == 'year' ? 'year' : type === 'month' ? 'year' : undefined,
                    //     // minZoomLevel: type == 'year' ? 'year' : type === 'month' ? 'century' : undefined,
                    //     zoomLevel: 'year'
                    // }} 

                    displayFormat={cmn.DATE_FORMAT_YR_1}
                    onValueChanged={(e) => {
                        setYr(e.value);
                    }}                    
                />
               
            </div>
            <div>월선택</div>
            <div>
                <DateBox 
                    width={120}
                    value={mon}
                    type={"date"}
                    // showClearButton={true}
                    maxZoomLevel={'year'}
                    minZoomLevel={'century'}

                    // pickerType={'rollers'}
                    showClearButton={true}
                    pickerType={'list'}
                    showDropDownButton={false}
                       
                    // 'calendar' | 'list' | 'native' | 'rollers'

                    displayFormat={cmn.DATE_FORMAT_MON_1}
                    onValueChanged={(e) => {
                        setMon(e.value);
                    }}                    
                />
                <DateBox 
                    width={120}
                    value={dy1}
                    type={"date"}
                    // showClearButton={true}
                    // displayFormat={cmn.DATE_FORMAT_MON_1}
                    onValueChanged={(e) => {
                        setDy1(e.value);
                    }}                    
                />
            </div>
            <br />
            <div>
                <span>Excel Upload</span>
                <div>
                    <FileUploader 
                        
                        multiple={false}
                        selectButtonText={'파일선택'}
                        labelText={"또는 여기에 파일을 끌어서 놓으세요."}
                        showFileList={true}
                        accept={"*"}
                        uploadMode={'instantly'}
                        
                        uploadUrl={`${cmn.env.SERVER_URL}/api/cmn/uploadExcel?fn=addProd`}
                        // onValueChanged={onValueChanged}
                        onUploaded={(e) =>{

                            cmn.successToast("파일이 업로드 되었습니다.");

                            e.component.reset();                        
                        }}

                        onUploadError={(e) => {
                            cmn.failedToast("파일이 업로드가 실패하였습니다.");
                        }}

                        // onUploadAborted={onUploadAborted}
                    /> 
                </div>
            </div>
            <div>
                <span>Editable Grid</span>
                <button
                    onClick={(e) => {
                        e.defaultPrevented();

                        console.log("=== employees", employees);
                    }}

                >확인</button>
                <DataGrid
                    id="gridContainer"
                    dataSource={employees}
                    keyExpr="ID"
                    allowColumnReordering={true}
                    showBorders={true}
                    // onEditingStart={this.onEditingStart}
                    // onInitNewRow={this.onInitNewRow}
                    // onRowInserting={this.onRowInserting}
                    // onRowInserted={this.onRowInserted}
                    // onRowUpdating={this.onRowUpdating}
                    // onRowUpdated={this.onRowUpdated}
                    // onRowRemoving={this.onRowRemoving}
                    // onRowRemoved={this.onRowRemoved}
                >

                    <Paging enabled={true} />
                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true} />

                    <Column dataField="Prefix" caption="Title" />      
                    <Column dataField="FirstName"  />            
                    <Column 
                        dataField="FirstName"
                        cellRender={(c) => {
                            return (
                                <span>{c.value}</span>
                            )
                        }}
                        editCellRender={(c) => {
                            // console.log("=== c", c);
                            // const txt = [...c.value];
    
                            return (
                                <TextBox 
                                    // value={c.value}
                                    defaultValue={c.value}
                                    showClearButton={true}
                                    placeholder="First Name ..."
                                    // valueChangeEvent={'keyup'}
                                    onInitialized={(e)=>{
                                        console.log("=== onInitialized e", e);
                                        console.log("=== c.value", c.value);
                                        // e.element.innerText = c.value;

                                    }}
                                    onChange={(e)=>{
                                        console.log("=== onChange e", e);

                                        // c.setValue(e.value);
                                    }}
                                    onKeyUp={(e)=>{
                                        console.log("=== onKeyUp e", e);

                                        // c.setValue(e.value);
                                    }}

                                    onValueChanged={(e)=>{
                                        console.log("=== onValueChanged e", e);
                                        // if(e.event == undefined) return;

                                        c.setValue(e.value);
                                    }}
                                /> 
                            )                    
                        }}
                    />
                    
                    <Column dataField="LastName" />
                    <Column dataField="Position" width={130} />
                    <Column
                        dataField="StateID"
                        caption="State"
                        width={125}
                    >
                        <Lookup dataSource={states} displayExpr="Name" valueExpr="ID" />
                    </Column>
                    <Column
                        dataField="BirthDate"
                        width={125}
                        dataType="date" />
                </DataGrid>

            </div>
            <div>
                <ActionButton 
                    width={200}
                    onClick={handleClickProcFlowTag}
                    title={"공정이동표"}
                    show={true}
                />
            </div>
            <div>
            <TextArea
                // height={100}
                minHeight={200}
                width={500}
                value={txt}
                autoResizeEnabled={true}
                // valueChangeEvent={this.state.eventValue}
                onValueChanged={(e) => {
                    setTxt(e.value);
                }}
            />
            </div>
            <div>달력</div>
            <div>일자선택</div>
            <div>
                {/* <DateBox 
                    value={dy1}
                    type={"date"}
                    showClearButton={true}
                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                    calendarOptions={{
                        showTodayButton: true,
                    }} 
                    onValueChanged={(e) => {
                        setDy1(e.value);
                    }}                    
                /> */}
            </div>
            
            <div>
            <DayDateBox 
                value={dy1}
                onValueChanged={(e) => {
                    console.log("=== DayDateBox onValueChanged", e);
                    setDy1(e.value);
                }}                    
            />

            </div>
            <br />
            <div>CheckBox List</div>
            <div>
                <CheckListBox
                    items={checkListItems}
                    // direction="row"
                    onValueChanged={(e) => {
                        // console.log("=== handleValueChanged", e);
                        setCheckListItems(e.items);                        
                    }}  
                />
            </div>
            <button
                onClick={(e) => {
                    console.log("=== checkListItems", checkListItems);
                    console.log("=== setSelectedFiles", selectedFiles);
                    console.log("=== uploadFiles", uploadFiles);                    
                    
                    // const dy1_ = moment(dy1).format("YYYY-MM-DD");
                    const dy1_ = util.getDateToString('d',dy1, '.');
                    console.log("=== dy1_", dy1_);

                    console.log("===gridItems", gridItems);
                    console.log("===selectedItemKeys", selectedItemKeys); 

                }}
            >체크</button>
            <br />
            <button
                onClick={(e) => {
                    const arr = [1,2,3,4,5];

                    _.forEach(arr, (v, k) => {
                        // console.log(v);
                        // await ff(v);
                        // await f(v);
                            f(v);
                            f(v);
                        
                    })

                    console.log("complete ~!");

                }}
            >
                테스트
            </button>
            <br />
            <div>파일업로드</div>
                
                <FileUploadBox
                    width={300}
                    // multiple={false}
                    limit={1}
                    uploadFiles={uploadFiles}
                    onUploaded={(e) => {
                        setUploadFiles(e);
                    }}
                    onReomveFile={(e) => {
                        setUploadFiles(e);
                    }}
                />                
           
            <div>
                Editable Grid
            </div>
            <div>
                <DataGrid id={'gridContainer'}
                    dataSource={gridItems}
                    keyExpr={'ID'}
                    showBorders={true}

                    columnHidingEnabled={true}

                    selectedRowKeys={selectedItemKeys}
                    onSelectionChanged={(e) => {
                        setSelectedItemKeys(e.selectedRowKeys);
                    }}

                >
                    <Selection mode={'multiple'} width={40} />
                    <Paging enabled={false} />
                    <Editing
                        mode={'cell'}
                        allowUpdating={true}
                    />

                    <Column dataField={'Prefix'} allowEditing={false} caption={'Title'} width={55} />
                    <Column dataField={'FirstName'} />
                    <Column dataField={'LastName'} />
                    <Column dataField={'Position'} width={170} />
                    {/* <Column dataField={'StateID'} caption={'State'} width={125}>
                        <Lookup dataSource={states} valueExpr={'ID'} displayExpr={'Name'} />
                    </Column> */}
                    <Column dataField={'BirthDate'} dataType={'date'} />
                    {/* <Column>
                        {
                            <>
                             <NumberBox
                                width={'100%'}
                                format={'#,##0.###'}
                                // value={}
                                showSpinButtons={true}
                             />
                            </>
                        }
                    </Column> */}
                </DataGrid>
            </div>
            <div>
                <DataGridView
                    dataSource={gridItems}

                    pagingable={false}
                    editable={true}

                    selection='multiple'                    
                    selectedRowKeys={selectedItemKeys}
                    onSelectionChanged={(e) => {
                        console.log("=== onSelectionChanged e", e);
                        setSelectedItemKeys(e.selectedRowKeys);
                    }}
                >

                    
                    <Column dataField={'Prefix'} allowEditing={false} caption={'Title'} width={55} />
                    <Column dataField={'Idx'} dataType={'number'} />
                    <Column 
                        dataField={'Idx'}
                    />
                    <Column dataField={'FirstName'} />                    
                    <Column dataField={'LastName'} />
                    <Column dataField={'Position'} width={170} />
                    {/* <Column dataField={'StateID'} caption={'State'} width={125}>
                        <Lookup dataSource={states} valueExpr={'ID'} displayExpr={'Name'} />
                    </Column> */}
                    <Column dataField={'BirthDate'} dataType={'date'} />

                </DataGridView>
            </div>
            <div>
            <DataGrid
                id={'gridContainer'}
                dataSource={orders}
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                // columnHidingEnabled={true}

                customizeColumns={(c) => {
                    c[0].width = 100;
                    c[1].width = 100;
                    c[2].width = 100;
                    c[3].width = 100;
                    c[4].width = 100;
                 }}
                showBorders={true}>
                {/* <Export enabled={true} fileName={'Orders'} /> */}
                {/* <Editing allowAdding={true} allowUpdating={true} mode={'batch'} /> */}
                {/* <Grouping contextMenuEnabled={true} expandMode={'rowClick'} /> */}
                {/* <GroupPanel visible={true} emptyPanelText={'Use the context menu of header columns to group data'} /> */}
                {/* <Pager
                    allowedPageSizes={[5, 8, 15, 30]}
                    showInfo={true}
                    showNavigationButtons={true}
                    showPageSizeSelector={true}
                    visible={true} />
                <Paging pageSize={8} /> */}
                {/* <ColumnChooser enabled={true} mode={'select'} /> */}
                {/* <Column allowGrouping={false} dataField={'OrderNumber'} width={130} caption={'Invoice Number'} /> */}
                <Column dataField={'OrderNumber'} caption={'Invoice Number'} /> 
                <Column dataField={'CustomerStoreCity'} caption={'City'} />
                <Column dataField={'CustomerStoreState'} caption={'State'} />
                <Column dataField={'Employee'} />
                <Column dataField={'OrderDate'} dataType={'date'} />
                <Column dataField={'SaleAmount'} format={'currency'} />
            </DataGrid>

            </div>

            <div>
                <DataGrid
                    id={'editGrid'}
                    dataSource={orders}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    // columnHidingEnabled={true}
                    showBorders={true}>
                     <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction={'moveFocus'}
                        enterKeyDirection={'column'} />
                    <Editing
                        mode="batch"
                        allowUpdating={false}
                        selectTextOnEditStart={true}
                        startEditAction={'click'} />

                    <Column dataField={'OrderNumber'} caption={'Invoice Number'} /> 
                    <Column dataField={'CustomerStoreCity'} caption={'City'} />
                    <Column dataField={'CustomerStoreState'} caption={'State'} />
                    <Column dataField={'Employee'} />
                    <Column dataField={'OrderDate'} dataType={'date'} />
                    <Column dataField={'SaleAmount'} format={'currency'} />
                </DataGrid>

                <EditGridView
                    dataSource={orders2}
                    >
                    {/* <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction='moveFocus'
                        enterKeyDirection='column' />
                    <Paging enabled={false} />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                        startEditAction="click" /> */}
                    <Column dataField="Check" caption="Title" width={70} />
                    <Column dataField="OrderNumber" caption="number" dataType={"number"} width={70} />
                    <Column dataField="CustomerStoreCity" />
                    <Column dataField="CustomerStoreState" />
                    <Column dataField="Employee" width={170} />
                    <Column dataField="SaleAmount" caption="State" width={125}></Column>
                </EditGridView>
            </div>
            </>  


            
        )
    }

    return(
        <>
        {/* <BaseLayout
            content = {renderContent()}
        /> */}
        {
            renderContent()
        }
        </>
    )
}

export default Sample1Page;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    PopupLayout, 
    ActionButton,
    CodeSelectBox,
    ProdSearchBox
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  부서 등록 / 수정
 */
const ProdBomPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        puid = ls.puid,
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    const [prodUid, setProdUid] = useState('');
    const [parentProdId, setParentProdId] = useState('');
    const [parentProdNm, setParentProdNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [putAmt, setPutAmt] = useState(0);
    const [putTp, setPutTp] = useState('');
    const [putTpNm, setPutTpNm] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);
            
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = () => {
        try {    
            if(util.getChkNull(puid) === '') return;
            loadProdInfo(puid, "p");
            loadProdBomInfo();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadProdInfo = async(e, t) => {
        let r = await cmn.api('getProd', {p: {prodUid: e}} )
        if(r == null || r === undefined) return;

        const data = r.d0[0];
        
        if(t === "p")
        {
            setParentProdId(data.prodId);
            setParentProdNm(data.prodNm);
        }else
        {
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setPutTp(data.unitTp);
            setPutTpNm(data.unitTpNm);
        }
    }

    const loadProdBomInfo = async() => {
        if(util.getChkNull(mode) === 'a') return;
        if(util.getChkNull(uid) === '') return;

        const ps = {p: {bomUid: uid}}
        
        const r = await cmn.api('getBom', ps)  
        if(r == null || r === undefined) return;
        const data = r.d0[0];

        setProdUid(data.prodUid);
        setProdId(data.prodId);
        setPutAmt(data.putAmt);
        setPutTp(data.putTp);
        setPutTpNm(data.putTpNm);
        
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(prodUid, '하위품번'))
                return;
            if(!util.chkMustValue(putAmt, '투입량', 'cnt'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addProdBom();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upProdBom();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addProdBom = async() => {
     
        const ps = {
            p: {
                bomUid: util.getUUID(),
                prodUid: prodUid,
                parentProdUid: puid,
                putAmt: putAmt,
                putTp: putTp,
                uptUserUid: authStore.user.uid
            }
        }
        
        const r = await cmn.api("addBom", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('등록'); 

        // Reload Opener List & Close Popup
        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    const upProdBom = async() => {
        const ps = {
            p: {
                bomUid: uid,
                putAmt: putAmt,
                putTp: putTp,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api("upBom", ps);
        if(r === undefined || r == null) return;        

        util.cmnSucAlert('수정'); 
        // Reload Opener List & Close Popup
        util.clickOpenerElement("subSrchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{parentProdId}</span>
                            </td>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{parentProdNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">하위품번</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a"  ?
                                <ProdSearchBox
                                    value={prodUid}
                                    popCenter={true}
                                    onLoadInfo={(o) => {
                                    }} 
                                    onValueChanged={(v) => {                                        
                                        setProdUid(v);
                                        loadProdInfo(v, 's');
                                    }}
                                />
                                : 
                                    <span>{prodId}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">투입량</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <NumberBox
                                    width={80}
                                    format={'#,##0.###'}
                                    value={putAmt}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setPutAmt(e.value);
                                    }}
                                />
                                :
                                    <span>{putAmt}</span>
                                }
                            </td>
                            <th className="tit">투입단위</th>
                            <td className="tcon">
                                {/* {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={putTp}
                                        classId={'105'}
                                        placeholder="투입단위를 선택하세요."
                                        showClearButton={false}
                                        onValueChanged={(v) => { 
                                            setPutTp(v);
                                        }}
                                    />
                                :  */}
                                <span>{putTpNm}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "BOM 등록" 
                : mode === "u" ? "BOM 수정"
                : "BOM" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProdBomPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { Observer} from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, SrchButton, DayDateBox,
    ProdSearchBox, ProdProcSelectBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공정불량률
 *  첫공정
 */
const ProdBadPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [workMon, setWorkMon] = useState(util.getNow());
    const [prodUid, setProdUid] = useState('');
    const [prodProcUid, setProdProcUid] = useState('');
    const [prodBadPerItem, setProdBadPerItem] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);

    /* #region Search Items */

    const searchItems = [
        [
            {   title: "작업월",
                width: "200px",
                item: 
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={workMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setWorkMon(e.value);
                            }}                    
                        />
                    </li>
                </ul>
            },
            {   title: "제품검색",
                //width: "300px",
                item: 
                <ProdSearchBox
                    value={prodUid}
                    width={"300px"}
                    popCenter={false}
                    onLoadInfo={(o) => {
                    }} 
                    onValueChanged={(v) => {                                        
                        setProdUid(v);
                    }}
                />   
            }   
            // },
            // {   title: "공정명",
            //     item: 
            //     <ProdProcSelectBox
            //         width={"200px"}
            //         value={prodProcUid}
            //         prodUid={prodUid}
            //         onLoadInfo={(o) => {
            //         }} 
            //         onValueChanged={(v) => { 
            //             setProdProcUid(v);
            //         }}
            //     />
            // }
        ]
    ];

    /* #endregion */

    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
       //     await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        if(util.getChkNull(prodUid) === '')
        {
            //util.cmnAlert('[제품]과 [공정]을 선택해 주십시오.');
            util.cmnAlert('[제품]을 선택해 주십시오.');
            return;
        }

        const ps =  {
            p: {
                startDy: util.getMonStartEndDy('S', workMon), 
                endDy :  util.getMonStartEndDy('E', workMon), 
                //prodProcUid: prodProcUid,
                prodUid: prodUid,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp
            }
        }

        const r = await cmn.api("getManuListBadProd", ps);
        if(r === undefined || r == null) return;

        setProdBadPerItem(r.d0);
    }

     /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }
    

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                /> */}
            </ListTopView>   
            }</Observer>    
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="공정불량률"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView> 
            <Observer>{() =>
            <>        
            <DataGridView            
                dataSource={prodBadPerItem}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}                
                
                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}  
                
                colsWidth={[120, 180, 180,  150, 150, null]}
            >
                { cmn.getGridColumnDayType('manuDy', '작업일', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell) }
                {/* { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell) } */}
                { cmn.getGridColumnNumCommaType('manuAmt', '생산수량') }
                { cmn.getGridColumnNumCommaType('badAmt', '불량수량') }
                { cmn.getGridColumnNumCommaType('badPpm', '불량 (%)') }

                <Summary>
                    <TotalItem
                        column={'manuAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'badAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'badPpm'}
                        summaryType={'avg'}
                        customizeText={(d) => {
                            return util.fix(d.value, 2);
                        }}
                    />
                </Summary>
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ProdBadPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column} from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, SrchButton, ActionButton, CodeSelectBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  작업지시 생성(수주단품)
 */
const WorkPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * Control Items (* Combo ...)
    // const [codeClassItems, setCodeClassItems] = useState([]);

    // * 검색
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodTp, setProdTp] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "제품유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={prodTp}
                    classId={'101'}
                    placeholder="제품유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setProdTp(v);
                    }}
                />
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",
                item: 
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />        
            }
        ]
    ];
    /* #endregion */    

   // * Grid
   const [workListStock, setWorkListStock] = useState([]);
   const [workListStockTot, setWorkListStockTot] = useState(0);
   const [selectedItem, setSelectedItem] = useState(null);
   // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    // const dgCodeListRef = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodTp: prodTp, //단품 검색 코드
                prodId: prodId, 
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }
        
        const r = await cmn.api("getWorkListStock", ps );
        if(r === undefined || r == null) return;

        setWorkListStock(r.d0)
        setWorkListStockTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
 
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("a", selectedItem.prodUid);
        }
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }
 
    // * Functions

    const openCrudPopup = (mode, prodUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/manu/workpop?${qs.stringify({mode: mode, inputtp: "30201", puid: prodUid})}`;        
        util.openWindow(url, 700, 800);    
    }
    
    /* ====================
     *  Render
     * ==================== */
 
    // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title={"작업지시등록"}
                    width={'130px'}
                    show={authStore.page.crtYn}
                />   
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="재고단품 내역"
            >
            </GridTopView> 
            <>        
            <DataGridView            
                dataSource={workListStock}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                page={curPage}
                total={workListStockTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}
                
                colsWidth={[150, 150, 150, cmn.CW.amt, cmn.CW.amt, null]}
            >
                { cmn.getGridColumnProdInfo('prodNm','품명', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId','품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodStnd','규격', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('stockAmt','현재재고')}
                { cmn.getGridColumnNumCommaType('safeAmt','안전재고')}
                <Column />
            </DataGridView>
            </>
            </>
        );

    }


    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default WorkPage;
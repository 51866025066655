import React, { useState, useEffect, useContext, useRef } from 'react';
import { NumberBox, SelectBox } from 'devextreme-react';

import _ from 'lodash';


/** 
 *  순서 선택 박스
 */
const SeqSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {

        width='80px',
        
        mode='a',
        cnt,
        value,
        placeholder='순서선택',

        showClearButton=false,

        onValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [seqs, setSeqs] = useState([]);

    useEffect(() => {
        loadPage();
    },[cnt]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        if(cnt == undefined || cnt == null){
            setSeqs([]);
            return;
        }
        
        const cnt_ = mode == 'a' ? (cnt + 1) : cnt;
        // console.log("--- cnt_", cnt_);
        let seqs_ = [];        
        _.times(cnt_, (n) => {
            seqs_.push(n+1);
        });
        // console.log("--- seqs_", seqs_);
        setSeqs(seqs_);
        
        if(mode == 'a') onValueChanged(seqs_[seqs_.length-1]);
        // else onValueChanged(seqs_[seqs_.length-1]);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...
     
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <SelectBox 
                width={width}
                placeholder={placeholder}

                value={value}
                items={seqs}

                showClearButton={showClearButton}

                onValueChanged={(e)=>{
                    onValueChanged(e.value);
                }} 
            />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default SeqSelectBox;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    CodeSelectBox, DayDateBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  설비 가동 내역
 */
const EqupRunPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    //...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 검색
    const [baseDy, setBaseDy] = useState(util.getNow());
    // const [equpTp, setEqupTp] = useState('');
    const [equpNm, setEqupNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "기준일자",                
                width: "200px",
                item: 
                    <DayDateBox 
                    width={"130px"}
                    value={baseDy}
                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                    onValueChanged={(e) => {
                        setBaseDy(e.value);
                    }}                    
            />
            },
            {   title: "설비명",
                item: 
                <TextBox 
                    width="200px"
                    value={equpNm}
                    showClearButton={true}
                    placeholder="설비명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setEqupNm(e.value);
                    }}
                />                      
            }
        ]
    ];
    /* #endregion */    

    // * Grid
    const [downHisList, setDownHisList] = useState([]);
    const [downHisListTot, setDownHisListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem,]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                baseDy: baseDy,      
                equpNm: equpNm,                          
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getDownHisList", ps );
        if(r === undefined || r == null) return;

        setDownHisList(r.d0)
        setDownHisListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(!util.chkListSelected(selectedItem, '대상을')) return;
        openCrudPopup(
              "u"
            //, selectedItem.equpConnDataUid
            , selectedItem.equpDownHisUid
        );
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType != "data") return;
        if(gc.column.dataField == "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField == "befManuDt" 
            ||  gc.column.dataField == "manuDt" 
            ) e.value =util.getDateToString('d', gc.value);
        else if(gc.column.dataField == "downTime" 
            ) e.value = util.getNumComma(gc.value);  
    }  

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, uid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equprunpop?${qs.stringify({mode: mode, uid: uid})}`;        
        util.openWindow(url, 600, 400);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
 
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="정보등록"
                    show={authStore.page.crtYn}
                />  
            </ListTopView>   
            <ListSearchView searchItems={searchItems} />
            
            <GridTopView
                title="비가동내역"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <DataGridView   
                reff={grid1}              
                dataSource={downHisList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[80, cmn.CW.equpNm
                    , 100, 120, 120, 100
                    , cmn.CW.userNm, null]}
                // paging
                page={curPage}
                total={downHisListTot}   // store
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged} 
                // excel
                exlnm={'생산설비가동내역'}
                customizeExcelCell={customizeExcelCell}                
            >
                { cmn.getGridColumn('connDy', '일자', renderGridHeaderCell ) }
                { cmn.getGridColumn('equpNm', '설비명', renderGridHeaderCell ) }

                { cmn.getGridColumn('downTpNm', '비가동유형', renderGridHeaderCell ) }
                { cmn.getGridColumnDateType('downStartDt', '시작시각', renderGridHeaderCell ) }
                { cmn.getGridColumnDateType('downEndDt', '종료시각', renderGridHeaderCell ) }
                { cmn.getGridColumnNumCommaType('downTime', '비가동시간(분)', renderGridHeaderCell ) }

                { cmn.getGridColumn('userNm', '작업자', renderGridHeaderCell ) }
                { cmn.getGridColumn('downCont', '비가동내용', renderGridHeaderCell ) }
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupRunPage;
import React, { useState, useEffect, useContext } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    IconButton, DayDateBox, SrchButton,
    ActionButton, SiteLayout, 
    GridTopView, ListSearchView, DataGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  설비 일상 점검(현장)
 */
const SiteEqupDayChkPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [chkDy, setChkDy] = useState(util.getNow());
    const [timeTp, setTimeTp] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "점검일자",
                item: 
                    <>
                    <span className="ftstyle1">
                    { util.getDateToString('d',chkDy, '-') } 
                    </span>
                    <span>
                    {
                        timeTp === cmn.CMNCO_TIMETP.DAY ? '  (주간)' : timeTp === cmn.CMNCO_TIMETP.NIGHT ? '  (야간)' : ''
                    }
                    </span>
                    </>
            }
        ]
    ];

    // * Grid
    const [equpChkDyList, setEqupChkDyList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadList1();
    },[chkDy]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            await loadTimeTp();
            setPageLoad(false);    
            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadTimeTp = async() => {
        const timeTp_ = cmn.getCurTimeTp();
        setTimeTp(timeTp_);
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                chkDy: util.getDateToString('d',chkDy), 
                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        }

        const r = await cmn.api("getEqupChkListDy", ps );
        if(r === undefined || r == null) return;

        setEqupChkDyList(r.d0)
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickSearch = (e) => {
        loadList1();
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    // const handleClickInsp= (e) => {
    //     if(util.chkListSelected(selectedItem)){
    //         const url = `/site/sitemanuretfstproc?${qs.stringify({uid:selectedItem.workNum})}`;   
    //         authStore.history.push(url);
    //     }
    // }

    const handleClickInsp = (e) => {
        if(selectedItem === null){
            alert("점검할 설비를 선택해 주세요.");
            return;
        }
        
        const ps = {uid: selectedItem.equpUid, chktp: cmn.CMNCO_CHKCYCLE.DAY, chkdy: util.getDateToString('d',chkDy), tmtp: timeTp};
        const url = `/site/siteequpdaychkcrt?${qs.stringify(ps)}`;  
        authStore.history.push(url);
    }
   
    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                <GridTopView title="설비 일상 점검 현황">
                    <SrchButton 
                        id="srchButton"
                        type={"site-1"}
                        onClick={handleClickSearch}
                    />
                    <ActionButton
                        width={"140px"}
                        onClick={handleClickInsp}
                        title="점검하기"
                        type={"site-1"}
                        show={true}
                    />
                </GridTopView>                
                <ListSearchView searchItems={searchItems} type="site" />
                <Observer>{() =>
                <>
                <DataGridView            
                    dataSource={equpChkDyList}   
                    colsWidth={[180, 240, 160, 200, 160, null]}
                    showNo={false}
                    pagingable={false}       
                    onSelectionChanged={handleGridSelectionChanged}         
                >
                    { cmn.getGridColumn('equpCd', '설비코드') }
                    { cmn.getGridColumn('equpNm', '설비명') }                   
                    <Column 
                        alignment={'center'}
                        caption={'점검여부'}
                        cellRender={(d) => {
                            const d_ = d.data;
                            // console.log("d_", d_);
                            const disp = d_.totCnt === 0 ? '미실시' : d_.totCnt === d_.goodCnt ? '완료' : '점검중';
                            return <span>{disp}</span>
                        }}
                    />
                    { cmn.getGridColumnDateType('chkDt', '점검일시') }
                    { cmn.getGridColumn('chkUserNm', '점검자명') }            
                    <Column />
                </DataGridView>
                </>
                }</Observer>
                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"설비 일상 점검"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteEqupDayChkPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextArea, NumberBox, TextBox } from 'devextreme-react';
import { Column} from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import { 
    DayDateBox, UseYnSwitchBox, CodeSelectBox, 
    UserSelectBox, ProdSearchBox, FileUploadBox, WorkNumSelectBox, LotSelectBox,
    ProdProcSelectBox, ManuEqupSelectBox, BizSelectBox,
    PopupLayout, ActionButton, SubActionButton,
    GridTopView, EditGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  부적합 개선 팝업
 */
const BadImprPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,     // mode: v(View), a(Add), u(Update)
        uid = ls.uid,       // bad_impr_uid
        pihuid = ls.pihuid, // proc_insp_his_uid
        ppuid = ls.ppuid,   // prod_proc_uid
        plot = ls.lot        // lot
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // const [badImprUid, setBadImprUid] = useState(uid);
    const [procInspHisUid, setProcInspHisUid] = useState(pihuid);
    const [procManuBadTreatUid, setProcManuBadTreatUid] = useState('');
    const [badImprTp, setBadImprTp] = useState('');
    // const [procInspBadTreatUid, setProcInspBadTreatUid] = useState(pibtuid);
    const [badImprCd, setBadImprCd] = useState('');
    const [happDy, setHappDy] = useState(util.getNow());
    const [receDy, setReceDy] = useState(util.getNow());
    const [badImprTitle, setBadImprTitle] = useState('');
    const [mngrUid, setMngrUid] = useState(authStore.user.uid);

    // const [prodProcUid, setProdProcUid] = useState(ppuid);
    const [prodStnd, setProdStnd] = useState('');

    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [procNm, setProcNm] = useState('');

    const [prodProcUid, setProdProcUid] = useState('');
    const [equpUid, setEqupUid] = useState('');
    const [equpNm, setEqupNm] = useState('');

    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');

    const [workNum, setWorkNum] = useState('');
    const [lot, setLot] = useState('');    

    const [manuAmt, setManuAmt] = useState(null);
    const [badAmt, setBadAmt] = useState(null);

    const [unitTpNm, setUnitTpNm] = useState('');
    const [badImprTpNm, setBadImprTpNm] = useState('');

    const [badTp, setBadTp] = useState('');
    const [badTpNm, setBadTpNm] = useState('');
    const [badCauseTp, setBadCauseTp] = useState('');
    const [badCauseTpNm, setBadCauseTpNm] = useState('');
    const [badTreatTp, setBadTreatTp] = useState('');
    const [badTreatTpNm, setBadTreatTpNm] = useState('');
    const [claimYn, setClaimYn] = useState('');
    const [claimCost, setClaimCost] = useState(0);
    const [cmnt, setCmnt] = useState('');
    const [badImprCont, setBadImprCont] = useState('');

    const [blockCompYn, setBlockCompYn] = useState('');
    const [corrCompYn, setCorrCompYn] = useState('');
    const [corrChkCompYn, setCorrChkCompYn] = useState('');

    const [originFileUid, setOriginFileUid] = useState(util.getUUID());
    const [originFiles, setOriginFiles] = useState([]);
    const [imgFileUid, setImgFileUid] = useState(util.getUUID());
    const [imgFiles, setImgFiles] = useState([]);

    // ** 격리조치
    let [blocks, setBlocks] = useState([]);   // 격리조치
    const blockGrid = useRef(null); // 격리조치 Grid

    // ** 시정조치
    const [badImprCorrUid, setBadImprCorrUid] = useState('');
    const [actDy, setActDy] = useState('');
    const [detpNm, setDetpNm] = useState('');
    const [mngrNm, setMngrNm] = useState('');
    const [actTitle, setActTitle] = useState('');

    const [corrFileUid, setCorrFileUid] = useState(util.getUUID());
    const [corrFiles, setCorrFiles] =  useState([]);

    const [actBefCont, setActBefCont] = useState('');
    const [actAftCont, setActAftCont] = useState('');

    const [actBefImgUid, setActBefImgUid] = useState(util.getUUID());
    const [actBefImgFileInfo, setActBefImgFileInfo] = useState('');
    const [actBefImgs, setActBefImgs] = useState([]);

    const [actAftImgUid, setActAftImgUid] = useState(util.getUUID());
    const [actAftImgFileInfo, setActAftImgFileInfo] = useState('');
    const [actAftImgs, setActAftImgs] = useState([]);

    // ** 시정조치 검증
    const [corrChks, setCorrChks] = useState([]);
    const corrChkGrid = useRef(null); // 격리조치 Grid

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            await loadInfo2();
            await loadInfo3();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {            
            if(mode === 'a' || util.getChkNull(uid) === '') return;

            const r = await cmn.api("getBadImpr", {p: {badImprUid: uid}} );
            if(r === undefined || r == null) return;

            const d1 = r.d1[0];

            console.log(d1);

            setBadImprCd(d1.badImprCd);
            setBadImprTp(d1.badImprTp);

            setHappDy(d1.happDy);
            setReceDy(d1.receDy);
            setBadImprTitle(d1.badImprTitle);
            setMngrUid(d1.mngrUid);
            setProdUid(d1.prodUid);
            setProdProcUid(d1.prodProcUid);
            setEqupUid(d1.equpUid);
            setBizUid(d1.bizUid);
            setLot(d1.lot);
            setManuAmt(d1.manuAmt);
            setBadAmt(d1.badAmt);
            setBadTp(d1.badTp);
            setBadCauseTp(d1.badCauseTp);
            setBadTreatTp(d1.badTreatTp);
            setClaimYn(d1.claimYn);
            setClaimCost(d1.claimCost);
            setCmnt(d1.cmnt);
            setBadImprCont(d1.badImprCont);
            setBlockCompYn(d1.blockCompYn);
            setCorrCompYn(d1.corrCompYn);
            setCorrChkCompYn(d1.corrChkCompYn);
            setOriginFileUid(d1.originFileUid  == null ? util.getUUID() : d1.originFileUid);
            setImgFileUid(d1.imgFileUid  == null ? util.getUUID() : d1.imgFileUid);

            setProdId(d1.prodId);
            setProdNm(d1.prodNm);
            setProcNm(d1.procNm);

            setWorkNum(d1.workNum);
            setEqupNm(d1.equpNm);
            setBizNm(d1.bizNm);

            setBadTpNm(d1.badTpNm);
            setBadCauseTpNm(d1.badCauseTpNm);
            setBadTreatTpNm(d1.badTreatTpNm);
            setUnitTpNm(d1.unitTpNm);
            setBadImprTpNm(d1.badImprTpNm);

            setBadImprCorrUid(d1.badImprCorrUid);
            setActDy(d1.actDy);
            setDetpNm(d1.detpNm);
            setMngrNm(d1.mngrNm);
            setActTitle(d1.actTitle);
            setCorrFileUid(d1.corrFileUid == null ? util.getUUID() : d1.corrFileUid);
            setActBefCont(d1.actBefCont);
            setActAftCont(d1.actAftCont);
            setActBefImgUid(d1.actBefImgUid == null ? util.getUUID() : d1.actBefImgUid);
            setActAftImgUid(d1.actAftImgUid == null ? util.getUUID() : d1.actAftImgUid);
            
            setBlocks(r.d2);
            setCorrChks(r.d3);

            setOriginFiles(r.f1);
            setImgFiles(r.f2);
            setCorrFiles(r.f3);
            setActBefImgs(r.f4);
            setActAftImgs(r.f5);



        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }     
    } 
    
    // 수입검사 불량현황에서 오픈 된 경우에 사용함.
    const loadInfo2 = async() => {
        try {            
            if(mode !== 'a' || util.getChkNull(pihuid) === '') return;

            const r = await cmn.api("getProcInspBadTreatHis", {p: {procInspHisUid: pihuid}} );
            if(r === undefined || r == null) return;

            const d1 = r.d0[0];

            setBadImprTp('30701');

            console.log(d1);
            setProdUid(d1.prodUid);

            setProdProcUid(d1.prodProcUid);
            setEqupUid(d1.equpUid);
            setBizUid(d1.procBizUid);
            setLot(d1.lot);
            setManuAmt(d1.inAmt);
            setBadAmt(d1.badAmt);

            setBadTreatTp(d1.badTreatTp);
            setClaimYn(d1.claimYn);
            setClaimCost(d1.claimPrice);

            setProdId(d1.prodId);
            setProdNm(d1.prodNm);
            setProcNm(d1.procNm);

            setWorkNum(d1.workNum);

            setUnitTpNm(d1.unitTpNm);

            setBadTp(r.d1.length > 0 ? r.d1[0].badTp : '');
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }     
    } 

    // 공정검사 불량현황에서 오픈 된 경우에 사용함.
    const loadInfo3 = async() => {
        try {            
            if(     mode !== 'a' 
                ||  util.getChkNull(ppuid) === ''
                ||  util.getChkNull(plot) === ''
            ) return;

            const r =  await cmn.api("getProcManuBad", {p: {lot: plot, prodProcUid: ppuid}});
            if(r === undefined || r == null) return;

            const d1 = r.d0[0];
            setProcManuBadTreatUid(d1.procManuBadTreatUid);
            setBadImprTp('30702');

            setProdUid(d1.prodUid);
            setProdProcUid(d1.prodProcUid);
            setLot(d1.lot);

            setManuAmt(d1.manuAmt);
            setBadAmt(d1.badAmt);

            setBadTreatTp(d1.badTreatTp);

            setProdId(d1.prodId);
            setProdNm(d1.prodNm);
            setProcNm(d1.procNm);

            setWorkNum(d1.workNum);
      
            setBadTp(r.d1.length > 0 ? r.d1[0].badTp : '');            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }     
    } 


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickAddBlock = (e) => {
        blockGrid.current.instance.addRow();
    }

    const handleClickAddCorrChk = (e) => {
        corrChkGrid.current.instance.addRow();
    }

    const handleClickComplete = (e) => {
        try {
            if(mode === 'a') {
                if(!util.confirmYn('등록')) return;            
                addBadImpr();
            }
            else if(mode === 'u') {
                if(!util.confirmYn('수정')) return;            
                upBadImpr();
            }            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickDel = (e) => {
        try {

            if(!util.confirmYn('삭제')) return;            
            delBadImpr();            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addBadImpr = async() => {
        const ps = {
            p:  {
                badImprUid: util.getUUID(),
                comUid: authStore.com.uid,
                procInspHisUid: pihuid,
                procManuBadTreatUid: procManuBadTreatUid,
                badImprTp: badImprTp,
                // badImprCd: badImprCd,
                // badImprCdDy: badImprCdDy,
                // badImprCdIdx: badImprCdIdx,
                happDy: util.getDateToString('d',happDy),
                receDy: util.getDateToString('d',receDy),
                badImprTitle: badImprTitle,
                mngrUid: mngrUid,
                prodUid: prodUid,
                prodProcUid: prodProcUid,
                equpUid: equpUid,
                bizUid: bizUid,
                lot: lot,
                manuAmt: manuAmt,
                badAmt: badAmt,
                badTp: badTp,
                badCauseTp: badCauseTp,
                badTreatTp: badTreatTp,
                claimYn: claimYn,
                claimCost: claimCost,
                cmnt: cmnt,
                badImprCont: badImprCont,

                blockCompYn: blockCompYn,
                corrCompYn: corrCompYn,
                corrChkCompYn: corrChkCompYn,

                originFileUid: originFileUid,
                originFiles: originFiles,

                imgFileUid: imgFileUid,
                imgFiles: imgFiles,

                uptUserUid: authStore.user.uid,
                
                actDy: util.getDateToString('d',actDy),
                detpNm: detpNm,
                mngrNm: mngrNm,
                actTitle: actTitle,

                corrFileUid: corrFileUid,
                corrFiles: corrFiles,
                
                actBefCont: actBefCont,
                actAftCont: actAftCont,

                actBefImgUid: actBefImgUid,
                actBefImgs: actBefImgs,
                actAftImgUid: actAftImgUid,
                actAftImgs: actAftImgs,

                blocks: blocks,     // 격리조치
                corrChks: corrChks  // 시정조치 검증
            }
        }

        const r = await cmn.api("addBadImpr", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }    

    const upBadImpr = async() => {
        const ps = {
            p: {
                badImprUid: uid,
                comUid: authStore.com.uid,
                badImprTp: badImprTp,
                happDy: util.getDateToString('d',happDy),
                receDy: util.getDateToString('d',receDy),
                badImprTitle: badImprTitle,
                mngrUid: mngrUid,
                prodUid: prodUid,
                prodProcUid: prodProcUid,
                equpUid: equpUid,
                bizUid: bizUid,
                lot: lot,
                manuAmt: manuAmt,
                badAmt: badAmt,
                badTp: badTp,
                badCauseTp: badCauseTp,
                badTreatTp: badTreatTp,
                claimYn: claimYn,
                claimCost: claimCost,
                cmnt: cmnt,
                badImprCont: badImprCont,

                blockCompYn: blockCompYn,
                corrCompYn: corrCompYn,
                corrChkCompYn: corrChkCompYn,

                originFileUid: originFileUid,
                originFiles: originFiles,

                imgFileUid: imgFileUid,
                imgFiles: imgFiles,

                uptUserUid: authStore.user.uid,
                
                actDy: util.getDateToString('d',actDy),
                detpNm: detpNm,
                mngrNm: mngrNm,
                actTitle: actTitle,

                corrFileUid: corrFileUid,
                corrFiles: corrFiles,
                
                actBefCont: actBefCont,
                actAftCont: actAftCont,

                actBefImgUid: actBefImgUid,
                actBefImgs: actBefImgs,
                actAftImgUid: actAftImgUid,
                actAftImgs: actAftImgs,

                blocks: blocks,     // 격리조치
                corrChks: corrChks  // 시정조치 검증
            }
        }

        const r = await cmn.api("upBadImpr", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }    

    const delBadImpr = async() => {
        const ps = {
            p: {
                badImprUid: uid
            }
        }

        const r = await cmn.api("delBadImpr", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("삭제완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }    

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    // width={140}                    
                    title={completeButtonTitle}
                    onClick={handleClickComplete}
                    // marginRight={10}
                    show={true}
                />
                <ActionButton 
                    // width={140}                    
                    title={"삭제완료"}
                    onClick={handleClickDel}
                    marginRight={0}
                    show={mode === "u" ? true : false}
                />
            </div>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"30%"} />
                        <col className="tit" />
						<col className="tcon" width={"20%"} />
                        <col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">부적합코드</th>
                            <td className="tcon">
                                <div className="fl-l" style={{paddingTop: '4px'}}>
                                {
                                    mode === "a" ?
                                    <span>등록완료 후 자동생성</span>                
                                    :
                                    <span
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                    >{badImprCd}</span>
                                }
                                </div>
                                <div className="fl-r">
                                {
                                    mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        width={'160px'}
                                        value={badImprTp}
                                        classId={'307'}
                                        placeholder="구분을 선택하세요."
                                        onValueChanged={(v, o) => { 
                                            setBadImprTp(v);
                                        }}
                                    />
                                    :
                                    <span>{badImprTpNm}</span>  
                                }
                                </div>
                            </td>
                            <th className="tit">발생일자</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={happDy}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setHappDy(e.value);
                                    }}                    
                                />
                            </td>                           
                            <th className="tit">접수일자</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={receDy}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setReceDy(e.value);
                                    }}                    
                                />
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">부적합제목</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={badImprTitle}
                                    showClearButton={true}
                                    placeholder="부적합 제목을 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setBadImprTitle(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                            }
                            </td>
                            <th className="tit">담당자</th>
                            <td className="tcon">
                                <UserSelectBox
                                    value={mngrUid}
                                    onValueChanged={(v) => { 
                                        setMngrUid(v);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제품</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <ProdSearchBox
                                    value={prodUid}
                                    // prodTp={['10102', '10103', '10104']}
                                    onLoadInfo={(o) => {
                                        // console.log("=== o", o);
                                        setProdStnd(util.getVal(o, 'prodStnd'));
                                        setProdId(util.getVal(o, 'prodId'));
                                        setUnitTpNm(util.getVal(o, 'unitTpNm'));
                                    }} 
                                    onValueChanged={(v) => {                                        
                                        setProdProcUid('');
                                        setProdUid(v);
                                    }}
                                />
                                :
                                <span>{cmn.getDispProd(prodId, prodNm)}</span>    
                            }
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                {prodId}
                            </td>                           
                            <th className="tit">규격</th>
                            <td className="tcon">
                                {prodStnd}
                            </td>  
                        </tr>  
                        <tr>
                            <th className="tit">원본파일</th>
                            <td className="tcon" colSpan={5}>
                                <FileUploadBox
                                    grpUid={originFileUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={originFiles}
                                    onUploaded={(e) => {
                                        setOriginFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setOriginFiles(e);
                                    }}
                                />
                            </td>                           
                        </tr>            
                    </tbody>
                </table>
            </div>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"30%"} />
                        <col className="tit" />
						<col className="tcon" width={"20%"} />
                        <col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">발생공정</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <ProdProcSelectBox
                                    width={200}
                                    value={prodProcUid}
                                    prodUid={prodUid}
                                    onLoadInfo={(o) => {
                                    }} 
                                    onValueChanged={(v, o) => { 
                                        setEqupUid('');
                                        setWorkNum('');
                                        setProdProcUid(v);
                                    }}
                                />
                                :
                                <span>{procNm}</span>  
                            }
                            </td>
                            <th className="tit">설비</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <ManuEqupSelectBox
                                    value={equpUid}
                                    lot={lot}
                                    prodProcUid={prodProcUid}
                                    onValueChanged={(v, o) => { 
                                        setEqupUid(v);                      
                                    }}
                                />
                                :
                                <span>{equpNm}</span>  
                            }
                            </td>                           
                            <th className="tit">업체</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <BizSelectBox
                                    value={bizUid}
                                    showClearButton={false}
                                    onLoadInfo={(o) => {
                                        // if(_.includes(o.bizTp,'70201')) //업체가 본사일 경우
                                        //     loadEqupCombo(); //설비 콤보를 호출
                                        // else //아닐경우
                                        // {
                                        //     setEqupItems([]); //초기화
                                        //     setEqupUid('');
                                        // }
                                    }} 
                                    onValueChanged={(v) => {
                                        setBizUid(v);
                                    }}
                                />  
                                :
                                <span>{bizNm}</span>
                            }
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">Lot</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <>
                                <div>
                                <ul className="ul-row">
                                    <li>
                                        <WorkNumSelectBox
                                            value={workNum}
                                            prodUid={prodUid}
                                            prodUidReq={true}
                                            onValueChanged={(v) => {
                                                setLot('');
                                                setWorkNum(v);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <LotSelectBox
                                            value={lot}
                                            prodUid={prodUid}
                                            showClearButton={true}
                                            onValueChanged={(v) => {
                                                setLot(v);
                                            }}
                                        />
                                    </li>
                                </ul>
                                </div>
                                </>
                                :
                                <span>{lot}</span>  
                            }
                            </td>
                            <th className="tit">생산수량</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <ul className="ul-row">
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={manuAmt}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setManuAmt(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        <span>{unitTpNm !== '' &&  ` (${unitTpNm})`}</span>      
                                    </li>
                                </ul>
                                :
                                <span>{`${util.getNumComma(manuAmt)} ${unitTpNm}`}</span>
                            }
                            </td>
                            <th className="tit">불량수량</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <ul className="ul-row">
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={badAmt}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setBadAmt(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        <span>{unitTpNm !== '' &&  ` (${unitTpNm})`}</span>      
                                    </li>
                                </ul>
                                :
                                <span>{`${util.getNumComma(badAmt)} ${unitTpNm}`}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">부적합 유형</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <CodeSelectBox
                                    value={badTp}
                                    classId={'303'}
                                    placeholder="부적합유형을 선택하세요."
                                    onValueChanged={(v, o) => { 
                                        setBadTp(v);
                                    }}
                                />
                                :
                                <span>{badTpNm}</span>  
                            }
                            </td>
                            <th className="tit">부적합 원인</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <CodeSelectBox
                                    value={badCauseTp}
                                    classId={'305'}
                                    placeholder="부적합원인을 선택하세요."
                                    onValueChanged={(v, o) => { 
                                        setBadCauseTp(v);
                                    }}
                                />
                                :
                                <span>{badCauseTpNm}</span>  
                            }
                            </td>                           
                            <th className="tit">처리 결과</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <CodeSelectBox
                                    value={badTreatTp}
                                    classId={'406'}
                                    placeholder="처리결과를 선택하세요."
                                    onValueChanged={(v, o) => { 
                                        setBadTreatTp(v);
                                    }}
                                />
                                :
                                <span>{badTreatTpNm}</span>  
                            }
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">클레임 여부</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <UseYnSwitchBox 
                                    value={claimYn}
                                    onValueChanged={(v)=>{
                                        setClaimYn(v);
                                    }} 
                                />
                                : 
                                    <span>{claimYn}</span>
                            }
                            </td>
                            <th className="tit">클레임 비용</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode === "a" || mode === "u" ?
                                <ul className="ul-row">
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={claimCost}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setClaimCost(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        <span>(원)</span>      
                                    </li>
                                </ul>
                                :
                                <span>{`${util.getNumComma(claimCost)} (원)`}</span>
                            }
                            </td>                           
                        </tr>
                        <tr>
                            <th className="tit">사진파일</th>
                            <td className="tcon" colSpan={5}>
                                <FileUploadBox
                                    grpUid={imgFileUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={imgFiles}
                                    onUploaded={(e) => {
                                        setImgFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setImgFiles(e);
                                    }}
                                />
                            </td>                           
                        </tr>   
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={5}>
                            {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                <span>{cmnt}</span>
                            }
                            </td>                           
                        </tr>             
                    </tbody>
                </table>
            </div>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">부적합 내용</th>
                            <td className="tcon">
                                <TextArea
                                    minHeight={140}
                                    // width={500}
                                    value={badImprCont}
                                    placeholder="부적합 내용 입력하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setBadImprCont(e.value);
                                    }}
                                />
                            </td>                                                     
                        </tr>             
                    </tbody>
                </table>
            </div>

            <GridTopView title="격리조치">   
                <ul className="ul-row">
                    <li
                        style={{
                            paddingRight: 10
                        }}
                    >
                        <SubActionButton 
                            onClick={handleClickAddBlock}
                            title="추가"
                            show={true}
                        />    
                    </li>
                    <li>
                        <span>완료여부:</span>
                    </li>
                    <li>
                        <UseYnSwitchBox 
                            value={blockCompYn}
                            onValueChanged={(v)=>{
                                setBlockCompYn(v);
                            }} 
                        />
                    </li>

                </ul>                     
            </GridTopView>
            <EditGridView         
                reff={blockGrid}   
                dataSource={blocks}   // store
                // gridHeight={authStore.windowHeight - cmn.BH.H10}                
                colsWidth={[
                    120, cmn.CW.lot,
                    cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, 
                    160, cmn.CW.userNm, cmn.CW.dy
                ]}
                pagingable={false}
                editMode={'row'}     
                allowDeleting={true}
                allowAdding={true}          
                // selection='multiple' 
                // onSelectionChanged={handleGridSelectionChanged}            
            >
                { cmn.getEditGridColumn('inspPlace', '검사장소') }                
                { cmn.getEditGridColumn('lot', 'Lot') }

                { cmn.getEditGridColumnNum('targetAmt', '대상수량') }
                { cmn.getEditGridColumnNum('goodAmt', '양품수량') }                
                { cmn.getEditGridColumnNum('badAmt', '불량수량') }

                { cmn.getEditGridColumn('actCont', '조치내용') }
                { cmn.getEditGridColumn('mngrNm', '담당자') }
                { cmn.getEditGridColumnDate('actDy', '조치일자', util.getNow()) }
                { cmn.getEditGridColumnUseYn('retYn', '완료') }
            </EditGridView>   
            
            <GridTopView title="시정조치" style={{marginTop: 10}}>
                <ul className="ul-row">
                    <li>
                        <span>완료여부:</span>
                    </li>
                    <li
                        style={{
                            // paddingRight: 20
                        }}
                    >
                        <UseYnSwitchBox 
                            value={corrCompYn}
                            onValueChanged={(v)=>{
                                setCorrCompYn(v);
                            }} 
                        />
                    </li>
                </ul>               
            </GridTopView>            

            <div className="board_list_type">
                <table>
                    <colgroup>
                    <col className="tit" />
						<col className="tcon" width={"30%"} />
                        <col className="tit" />
						<col className="tcon" width={"20%"} />
                        <col className="tit" />
						<col className="tcon " />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">조치일자</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={actDy}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setActDy(e.value);
                                    }}                    
                                />
                            </td>
                            <th className="tit">부서</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={detpNm}
                                    showClearButton={true}
                                    placeholder="부서를 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setDetpNm(e.value);
                                    }}
                                /> 
                                :
                                <span>{detpNm}</span>
                            }
                            </td>                           
                            <th className="tit">담당자</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={mngrNm}
                                    showClearButton={true}
                                    placeholder="담당자를 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setMngrNm(e.value);
                                    }}
                                /> 
                                :
                                <span>{mngrNm}</span>
                            }
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">제목</th>
                            <td className="tcon" colSpan={5}>
                            {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={actTitle}
                                    showClearButton={true}
                                    placeholder="제목을 입력하세요."
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setActTitle(e.value);
                                    }}
                                /> 
                                :
                                <span>{actTitle}</span>
                            }
                            </td>                           
                        </tr>
                        <tr>
                            <th className="tit">원본파일</th>
                            <td className="tcon" colSpan={5}>
                                <FileUploadBox
                                    grpUid={corrFileUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={corrFiles}
                                    onUploaded={(e) => {
                                        setCorrFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setCorrFiles(e);
                                    }}
                                />
                            </td>                           
                        </tr>  
                        <tr>
                            <th className="tit" colSpan={6}>조치 전 (현상 및 문제점)</th>                            
                        </tr>
                        <tr>
                            <td className="tcon" colSpan={3}>
                                <div className="div-file-sel fl-l">
                                    <FileUploadBox
                                        limit={1}
                                        grpUid={actBefImgUid}
                                        userUid={authStore.user.uid}
                                        uploadFiles={actBefImgs}
                                        labelText=''
                                        onLoadInfo={(e) => {
                                            // let fi = '';
                                            // _.forEach(e, (v, k) => {
                                            //     fi += k > 0 ? ',' : '';
                                            //     fi += `${v.uploaded ? v.saveFileNm : v.fileUid}^${v.fileNm}^${v.uploaded ? '1' : ''}`;                                                    
                                            // });
                                            const fi = cmn.getUploadFileInfoStr(e);
                                            setActBefImgFileInfo(fi);
                                        }}
                                        onUploaded={(e) => {                                            
  
                                            setActBefImgs(e);
                                        }}
                                        onReomveFile={(e) => {
                                            setActBefImgs(e);
                                        }}
                                    />  
                                </div>
                                <div className="div-img fl-r">
                                { 
                                    actBefImgFileInfo !== '' ?                                                
                                    cmn.getRenderImg(actBefImgFileInfo, 180) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                                </div>
                            </td>
                            <td className="tcon" style={{verticalAlign: 'top'}} colSpan={3}>
                                <TextArea
                                    minHeight={140}
                                    // width={500}
                                    value={actBefCont}
                                    placeholder="조치 전 내용을 입력하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setActBefCont(e.value);
                                    }}
                                />
                            </td>
                        </tr>                      
                        <tr>
                            <th className="tit" colSpan={6}>조치 후 (개선내용 및 효과)</th>                            
                        </tr>
                        <tr>
                            <td className="tcon" colSpan={3}>
                                <div className="div-file-sel fl-l">
                                    <FileUploadBox
                                        limit={1}
                                        grpUid={actAftImgUid}
                                        userUid={authStore.user.uid}
                                        uploadFiles={actAftImgs}
                                        labelText=''
                                        onLoadInfo={(e) => {
                                            let fi = '';
                                            _.forEach(e, (v, k) => {
                                                fi += k > 0 ? ',' : '';
                                                fi += `${v.uploaded ? v.saveFileNm : v.fileUid}^${v.fileNm}^${v.uploaded ? '1' : ''}`;                                                    
                                            });

                                            setActAftImgFileInfo(fi);
                                        }}
                                        onUploaded={(e) => {
                                            setActAftImgs(e);
                                        }}
                                        onReomveFile={(e) => {
                                            setActAftImgs(e);
                                        }}
                                    />  
                                </div>
                                <div className="div-img fl-r">
                                { 
                                    actAftImgFileInfo !== '' ?                                                
                                    cmn.getRenderImg(actAftImgFileInfo, 180) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                                </div>                                
                            </td>
                            <td className="tcon" style={{verticalAlign: 'top'}} colSpan={3}>
                                <TextArea
                                    minHeight={140}
                                    value={actAftCont}
                                    placeholder="조치 후 내용을 입력하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setActAftCont(e.value);
                                    }}
                                />
                            </td>
                        </tr>          
                    </tbody>
                </table>
            </div>

            <GridTopView title="시정조치 검증">   
                <ul className="ul-row">
                    <li
                        style={{
                            paddingRight: 10
                        }}
                    >
                        <SubActionButton 
                            onClick={handleClickAddCorrChk}
                            title="추가"
                            show={true}
                        />    
                    </li>
                    <li>
                        <span>완료여부:</span>
                    </li>
                    <li>
                        <UseYnSwitchBox 
                            value={corrChkCompYn}
                            onValueChanged={(v)=>{
                                setCorrChkCompYn(v);
                            }} 
                        />
                    </li>

                </ul>                     
            </GridTopView>

            <EditGridView
                reff={corrChkGrid}            
                dataSource={corrChks}   // store
                // gridHeight={authStore.windowHeight - cmn.BH.H10}                
                colsWidth={[
                    80, cmn.CW.dy, cmn.CW.lot,
                    160, 100, null
                ]}
                pagingable={false}
                editMode={'row'}     
                allowDeleting={true}
                allowAdding={true}  
                // selection='multiple' 
                // onSelectionChanged={handleGridSelectionChanged}            
            >
                {/* { cmn.getGridColumn('corrIdx', '검증회차') } */}
                <Column 
                    alignment={'center'}
                    dataField={'corrIdx'}
                    caption={'검증회차'}
                    allowEditing={false}
                    cellRender={(d) => {
                        return <span>{d.rowIndex + 1}</span>
                    }}
                />
                { cmn.getEditGridColumnDate('corrDy', '검증일자') }
                { cmn.getEditGridColumn('lot', 'Lot') }

                { cmn.getEditGridColumn('corrWay', '검증방법') }
                { cmn.getEditGridColumnUseYn('corrYn', '완료') }                
                {/* { cmn.getEditGridColumn('atchFileUid', '첨부파일') } */}               
                { cmn.getEditGridColumn('cmnt', '비고') }
            </EditGridView>  
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "부적합 개선 등록" 
                : mode === "u" ? "부적합 개선 수정"
                : "부적합 개선" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default BadImprPopPage;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공정검사
 */
const ProcInspPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

     // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 코드유형 Item
    // const [codeClassItems, setCodeClassItems] = useState([]);

    // * 검색
    const [startDy, setStartDy] = useState(util.getNow());
    const [endDy, setEndDy] = useState(util.getNow());    
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "검사일",
                width: "200px",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
                </>
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",               
                item: 
                <>
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />     
                </>           
            }
        ]
    ];

    /* #endregion */

    
    // * Grid
    const [procInspHisList, setProcInspHisList] = useState([]);
    const [procInspHisListTot, setProcInspHisListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodId: prodId,
                prodNm: prodNm,
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),

                offset: rowsOffset,
                limit: rowsLength,
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
            }
        }

        const r = await cmn.api("getProcInspHisList", ps );
        if(r === undefined || r == null) return;

        setProcInspHisList(r.d0)
        setProcInspHisListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }   
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a");
    }

    // * Data Grid
    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Funtions

    const openCrudPopup = (mode, uid, pdy, pduid, ppuid, pwn, plot,  peuid) => {
        const url = `/qual/procinsppop?${qs.stringify({mode: mode, uid: uid, pdy: pdy, pduid: pduid, ppuid: ppuid, pwn: pwn, plot: plot, peuid: peuid})}`;
        util.openWindow(url, 1120, 720);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />

                <ActionButton 
                    onClick={handleClickAdd}
                    title="검사등록"
                    type="2"
                    show={true}
                />
            </ListTopView>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="공정검사 내역"
            >
                {/* <IconButton
                    className="btn btn-isz ico-print"
                /> 
                <IconButton
                    className="btn btn-isz ico-exldown"
                    marginRight={0}
                />
                */}
            </GridTopView>
            <DataGridView
                dataSource={procInspHisList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[cmn.CW.dy, cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    , cmn.CW.lot, cmn.CW.procNm, cmn.CW.equpNm, 
                    100, 100, 100, 100, 100, 100, 100, 100, 100, 100]}
                // paging
                page={curPage}
                total={procInspHisListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                selection=''
                // select
                // onSelectionChanged={handleGridSelectionChanged}
            >
                { cmn.getGridColumnDayType('inspDy', '검사일자', renderGridHeaderCell) }
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell) }                
 
                { cmn.getGridColumn('lot', 'Lot', renderGridHeaderCell) }                            
                { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell) }
                { cmn.getGridColumn('equpNm', '설비', renderGridHeaderCell) }

                { 
                    _.times(10, (i) => {
                        return (
                            <Column 
                                key={i}
                                // width={100}
                                alignment={'center'}
                                caption={`${i+1}차`}
                                cellRender={(d) => {
                                    const o = d.row.data;
                                    return (
                                        <div 
                                            className={`insp-col ${ (o.maxInspIdx+1) > i && 'insp-col-act'}`}
                                            onClick={(e) => {
                                                if(o.maxInspIdx > i) {
                                                    openCrudPopup('u', o[`procInspHisUid${i+1}`]);    
                                                }
                                                else if ( o.maxInspIdx === i) {
                                                    openCrudPopup('a', '', 
                                                        o.inspDy, 
                                                        o.prodUid,
                                                        o.prodProcUid, 
                                                        o.workNum,
                                                        o.lot, 
                                                        o.equpUid);
                                                }
                                            }}
                                        >
                                            <ul>
                                                <li>{o[`inspTpNm${i+1}`]}</li>
                                                <li>{cmn.getDispPass(o[`passYn${i+1}`])}</li>
                                                <li>{util.getChkNull(o[`inspDt${i+1}`]) === '' ? '' : util.getDateToString('t', o[`inspDt${i+1}`], ':')}</li>
                                            </ul>
                                        </div>
                                    )
                                }}
                            />
                        )
                    })
                }

            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ProcInspPage;
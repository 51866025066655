import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  치공구 관리
 */
const ToolPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
   
    // * Control Items (* Combo ...)

    // * 검색
    const [toolTp, setToolTp] = useState('');
    const [toolNm, setToolNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "치공구유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={toolTp}
                    classId={'108'}
                    placeholder="치공구유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setToolTp(v);
                    }}
                />
            },
            {   title: "치공구명",
                item: 
                <TextBox 
                    value={toolNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="치공구명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setToolNm(e.value);
                    }}
                />          
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [toolList, setToolList] = useState([]);
    const [toolListTot, setToolListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, toolTp]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            // 권한조회
            await authStore.loadPage(props);
            
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                toolTp: toolTp, 
                toolNm: toolNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }
        
        const r = await cmn.api("getToolList", ps );
        if(r === undefined || r == null) return;

        setToolList(r.d0)
        setToolListTot(r.tot);
    }

    /* ====================
     *  Handle
     * ==================== */

    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // 치공구 등록
    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    // 치공구 수정
    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.toolUid);
        }
    }

    // 치공구 상세보기
    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.toolUid);
        }
    }

    // * Data Grid
    // Row 클릭 
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    // 데이타 정렬
    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    // Paging 클릭
    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    /* ====================
     *  Functions
     * ==================== */

    const openCrudPopup = (mode, toolUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/tool/toolpop?${qs.stringify({mode: mode, uid: toolUid})}`;        
        util.openWindow(url, 700, 550);    
    }


    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView title="치공구 내역">
            </GridTopView>     
            <DataGridView            
                dataSource={toolList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={toolListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}                

                colsWidth={[100, 120, 120, 200, 100, 100, null]}
            >
                { cmn.getGridColumn('toolTpNm', '치공구유형', renderGridHeaderCell) }   
                { cmn.getGridColumn('toolId', '치공구품번', renderGridHeaderCell) }   
                { cmn.getGridColumn('toolCd', '치공구코드', renderGridHeaderCell) }   
                { cmn.getGridColumn('toolNm', '치공구명', renderGridHeaderCell) }   
                { cmn.getGridColumn('toolGrade', '등급', renderGridHeaderCell) }   
                { cmn.getGridColumnNumCommaType('cornerCnt', '코너수') }   
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ToolPage;
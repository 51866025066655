import React, { useState, useEffect, useContext, useRef } from 'react';
import TreeList, { Sorting, Selection as TreeSelection, Column as TreeColumn
    , LoadPanel, Paging, Pager } from 'devextreme-react/tree-list';
import useMobileDetect from 'use-mobile-detect-hook'
import _ from 'lodash';

/** components */
import {
    DataGridPagerView
} from '../';

/** stores & lib */
// import util from '../../lib/util';
// import cmn from '../../lib/cmn';

/** images */

/** 
 *  TreeList View
 */
const TreeListView = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // ...

    /* ====================
     *  Object
     * ==================== */
    
    const detectMobile = useMobileDetect();

    const {
        reff,
        dataSource,
        height=0,

        keyExpr,
        parentIdExpr,
        showNo=false,        

        // sort
        onCellClick,

        onSelectionChanged,

        // Paging
        pagingable=true,
        page=1,
        total= 0,
        itemsPerPage= 0,
        pagesPerBlock= detectMobile.isMobile() ? 5 : 10,
        onChangePageSize,
        onClickPage,
        multiply = 1,

        columnResizingMode='nextColumn',
        
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */
    // const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
       // ...
    }

    /* ====================
     *  Handle / Functions
     * ==================== */


    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {

        return (            
            <>
            <div className="data-tree-1 mgt5">                
                <TreeList
                    ref={reff}
                    
                    height={height > 0 ? height : null}
                    dataSource={dataSource}

                    showBorders={true}
                    showRowLines={true}
                    // columnAutoWidth={true}

                    keyExpr={keyExpr}
                    parentIdExpr={parentIdExpr}

                    onCellClick={onCellClick}
                    onSelectionChanged={onSelectionChanged}
                    
                    allowColumnResizing={detectMobile.isMobile() == true ? false : true}
                    columnResizingMode={columnResizingMode}   // widget, nextColumn
                    
                >
                    <Paging enable={false} pageSize={itemsPerPage == - 1 ? total : itemsPerPage * multiply} />
                    <Pager visible={false} />       
                    <TreeSelection mode={'single'} />
                    <Sorting mode={'none'} />
                    {
                        props.children
                    }
                </TreeList>
            </div>
            {
                /** Paging  */
                (pagingable && page > 0) &&
                <DataGridPagerView
                    page= {page}
                    total= {total}
                    itemsPerPage= {itemsPerPage}
                    pagesPerBlock= {pagesPerBlock}
                    onClickPage= {onClickPage}
                    onValueChanged={onChangePageSize}
                />
            }
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default TreeListView;
import React from 'react';
import { Switch } from 'devextreme-react';
import _ from 'lodash';

/** components */

/** stores & lib */
// import AuthStore from '../../stores/AuthStore';

/** images */

/** 
 *  List Top View
 */
const UseYnSwitchBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        value,
        defaultValue,
        onValueChanged,
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */


    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {
        return (
            <>
            {
                value != undefined ?
                <Switch 
                    width={60}
                    value={value == 'Y' ? true : false}                            
                    switchedOnText='예'
                    switchedOffText='아니요'
                    onValueChanged={(e) => {
                        onValueChanged(e.value ? 'Y' : 'N');
                    }}
                />
                :
                <Switch 
                    width={60}
                    defaultValue={defaultValue == 'Y' ? true : false}                            
                    switchedOnText='예'
                    switchedOffText='아니요'
                    onValueChanged={(e) => {
                        onValueChanged(e.value ? 'Y' : 'N');
                    }}
                />
            }  
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default UseYnSwitchBox;
const menuApiz = {
    
    getMenuList: {
        title: "메뉴 목록 조회",
        url: "/api/menu/getMenuList",
        method: "POST"
    },
    getMenu: {
        title: "메뉴 상세 조회",
        url: "/api/menu/getMenu",
        method: "POST"
    },
    addMenu: {
        title: "메뉴 등록",
        url: "/api/menu/addMenu",
        method: "POST"
    },
    upMenu: {
        title: "메뉴 수정",
        url: "/api/menu/upMenu",
        method: "POST"
    },
    upMenuUseYn: {
        title: "메뉴 수정(사용여부)",
        url: "/api/menu/upMenuUseYn",
        method: "POST"
    },
    delMenu: {
        title: "메뉴 삭제",
        url: "/api/menu/delMenu",
        method: "POST"
    },
    getSubMenuList: {
        title: "서브 메뉴 조회(메뉴ID)",
        url: "/api/menu/getSubMenuList",
        method: "POST"
    },
    addSubMenu: {
        title: "서브메뉴 등록",
        url: "/api/menu/addSubMenu",
        method: "POST"
    },
    delSubMenu: {
        title: "서브메뉴 삭제",
        url: "/api/menu/delSubMenu",
        method: "POST"
    },
    getMenuUser: {
        title: "사용자 메뉴 조회",
        url: "/api/menu/getMenuUser",
        method: "POST"
    },
      
    addMenuFavi: {
        title: "메뉴 즐겨찾기 등록",
        url: "/api/menu/addMenuFavi",
        method: "POST"
    },
    delMenuFavi: {
        title: "메뉴 즐겨찾기 삭제",
        url: "/api/menu/delMenuFavi",
        method: "POST"
    },
}

export default menuApiz;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox, NumberBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    CodeSelectBox, 
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  측정기 관리
 */
const GaugePage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);    

    // * 검색
    const [gaugeTp, setGaugeTp] = useState('');
    const [gaugeNm, setGaugeNm] = useState('');
    const [restDyCnt, setRestDyCnt] = useState(null);

    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "측정기유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={gaugeTp}
                    classId={'405'}
                    placeholder="측정기유형을 선택하세요."
                    onValueChanged={(v, o) => { 
                        setGaugeTp(v);
                    }}
                />
            },
            {   title: "측정기명",
                width: "200px",
                item: 
                <TextBox 
                    value={gaugeNm}
                    showClearButton={true}
                    placeholder="측정기명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setGaugeNm(e.value);
                    }}
                />        
            },
            {   title: "교정잔여일",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <NumberBox
                            width={80}
                            format={'#,##0'}
                            value={restDyCnt}
                            // defaultValue={0}
                            // min={0}
                            showSpinButtons={true}
                            showClearButton={true}
                            onValueChanged={(e)=>{
                                setRestDyCnt(e.value);
                            }} 
                        />
                    </li>
                    <li>
                       일 이내
                    </li>
                </ul>
                </>
            }
        ]
    ];

    /* #endregion */    


    // * Grid
    const [gaugeList, setGaugeList] = useState([]);
    const [gaugeListTot, setGaugeListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, gaugeTp]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                gaugeTp: gaugeTp, 
                gaugeNm: gaugeNm, 
                restDyCnt: restDyCnt,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getGaugeList", ps );
        if(r === undefined || r == null) return;

        setGaugeList(r.d0)
        setGaugeListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", null);
    }

    const handleClickUp = (e) => {
        if(!util.chkListSelected(selectedItem, '수정대상 측정기를')) return;
        openCrudPopup("u", selectedItem.gaugeUid);
    }

    const handleClickCalib = (e) => {
        if(!util.chkListSelected(selectedItem, '교정대상 측정기를')) return;
        openCalibPopup(selectedItem.gaugeUid);
    }

    const handleClickHisRepo = (e) => {
        if(!util.chkListSelected(selectedItem, '측정기를')) return;
        openHisRepoPopup(selectedItem.gaugeUid);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "buyDy" 
            ||  gc.column.dataField === "lastCalibDy" 
            ||  gc.column.dataField === "nextCalibDy" 
            ) e.value = util.getDateStrSplit('d',gc.value);
    }    

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, gaugeUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/gaugepop?${qs.stringify({mode: mode, uid: gaugeUid})}`;        
        util.openWindow(url, 800, 600);    
    }

    const openCalibPopup = (gaugeUid) => {
        const url = `/equp/gaugecalibpop?${qs.stringify({uid: gaugeUid})}`;        
        util.openWindow(url, 720, 560);    
    }

    const openHisRepoPopup = (gaugeUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/gaugehisrepo?${qs.stringify({uid: gaugeUid})}`;        
        util.openWindow(url, 820, 840);
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                    
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    width={120}
                    onClick={handleClickCalib}
                    title="측정기교정"
                    type="2"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickHisRepo}
                    title="관리대장"
                    type="2"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView> 
            }</Observer>      
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="측정기 관리 내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <Observer>{() =>
            <>        
            <DataGridView   
                reff={grid1}
                dataSource={gaugeList}   
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[100, 100, 200
                    , 120, 120, cmn.CW.dy
                    , 80, 100, 100
                    , 80, 120, null]}
                // paging
                page={curPage}
                total={gaugeListTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged} 
                // excel
                exlnm={'측정기관리내역'}
                customizeExcelCell={customizeExcelCell}               
            >
                { cmn.getGridColumn('gaugeTpNm', '측정기유형', renderGridHeaderCell ) }
                { cmn.getGridColumn('gaugeCd', '측정기코드', renderGridHeaderCell ) }
                { cmn.getGridColumn('gaugeNm', '측정기명', renderGridHeaderCell ) }

                { cmn.getGridColumn('serialNo', 'S/N', renderGridHeaderCell ) }
                { cmn.getGridColumn('maker', '제조사', renderGridHeaderCell ) }
                { cmn.getGridColumnDayType('buyDy', '구매일', renderGridHeaderCell) }

                { cmn.getGridColumn('calibCycle', '교정주기', renderGridHeaderCell ) }
                { cmn.getGridColumnDayType('lastCalibDy', '최근교정일', renderGridHeaderCell) }
                {/* { cmn.getGridColumnDayType('nextCalibDy', '차기검교정일', renderGridHeaderCell) } */}
                <Column 
                    alignment={'center'}
                    dataField={'nextCalibDy'}
                    caption={'차기교정일'}  
                    sortable={true}
                    headerCellRender={renderGridHeaderCell}
                    cellRender={(d) => {
                        let style_ = null;
                        if(d.data.restDyCnt < 0) style_ = {color: '#ff0000', fontWeight: 'bold'};
                        else if(d.data.restDyCnt < 30) style_ = {color: '#ff8000', fontWeight: 'bold'};                        
                        return (
                            <>
                            <span
                                style={style_}
                             >{util.getDateStrSplit('d',d.value)}</span>
                            </>
                        )
                    }}
                />
                {/* { cmn.getGridColumnNumCommaType('restDyCnt', '잔여일', renderGridHeaderCell, '') } */}
                <Column 
                    alignment={'center'}
                    dataField={'restDyCnt'}
                    caption={'잔여일'}  
                    sortable={true}
                    headerCellRender={renderGridHeaderCell}
                    cellRender={(d) => {
                        let style_ = null;
                        if(d.data.restDyCnt < 0) style_ = {color: '#ff0000', fontWeight: 'bold'};
                        else if(d.data.restDyCnt < 30) style_ = {color: '#ff8000', fontWeight: 'bold'};
                        return (
                            <>
                            <span
                                style={style_}
                             >{util.getNumComma(d.value, '')}</span>
                            </>
                        )
                    }}
                />
                { cmn.getGridColumn('saveLoca', '보관위치', renderGridHeaderCell ) }
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default GaugePage;

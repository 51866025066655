import React, { useState, useEffect, useContext } from 'react';
import { TextBox, NumberBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';
import moment from 'moment';

/** components */
import {
    DayDateBox, SrchButton, UserSelectBox, ActionButton, SiteLayout, BadAmtBox, 
    GridTopView, ListSearchView, DataGridView, EqupSelectBox, LotSelectBox, DateTimeBox, BizSelectBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const SiteManuRetFstProcPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    
    
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        wn = ls.wn,
        lt = ls.lt
    } = props;

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [workNum, setWorkNum] = useState(util.getChkNull(wn));   
    const [lot, setLot] = useState(util.getChkNull(lt));   
    const [preLot, setPreLot] = useState();   
    const [refreshCd, setRefreshCd] = useState();   
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "작업번호",
                width: "200px",
                item: 
                <TextBox 
                    value={workNum}
                    showClearButton={true}
                    placeholder="작업번호로 검색하세요."
                    onValueChanged={(e)=>{
                        setWorkNum(e.value);
                    }}
                />    
            },
            {   title: "Lot",
                item: 
                <LotSelectBox
                    width={"300px"}
                    value={lot}
                    preValue={preLot}
                    workNum={workNum}
                    refreshCd={refreshCd}
                    placeholder={'신규 Lot 발급'}
                    onValueChanged={(v) => { 
                        setLot(v); 
                    }}
                />
            }
        ]
    ];    

    //
    const [manuDy, setManuDy] = useState(util.getNow());   
    const [manuDt, setManuDt] = useState(util.getNow('m'));
    const [userUid, setUserUid] = useState(authStore.user.uid);  
    const [manuAmt, setManuAmt] = useState(0);  
    const [possAmt, setPossAmt] = useState(0);  
    const [goodAmt, setGoodAmt] = useState(0);  
    const [procNm, setProcNm] = useState('');  
    const [prodProcUid, setProdProcUid] = useState('');  
    const [badAmts, setBadAmts] = useState([]);  
    const [unitTp, setUnitTp] = useState('');  
    const [unitTpNm, setUnitTpNm] = useState('');  
    const [prodUid, setProdUid] = useState('');  
    const [lastYn, setLastYn] = useState('N');  
    const [workListItems, setWorkListItems] = useState([]);  
    const [stndImgFile, setStndImgFile] = useState('');  
    const [equpChkYn, setEqupChkYn] = useState(''); 
    const [lineUid, setLineUid] = useState(''); 
    const [equpUid, setEqupUid] = useState(''); 
    const [equpNm, setEqupNm] = useState(''); 
    const [procPrice, setProcPrice] = useState(0); 

    const [dyStartTm, setDyStartTm] = useState('');
    const [dyEndTm, setDyEndTm] = useState('');
    const [lotTp, setLotTp] = useState('71002');
    const [dyNtUseYn, setDyNtUseYn] = useState('Y');
    const [bizUid, setBizUid] = useState('');
    
    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadProdProcInfo();
    }, [workNum]);   

    useEffect(() => {
        !pageLoad && loadProdProcInfo();
    }, [prodUid]);  

    useEffect(() => {
        !pageLoad && loadProdProcAmtInfo();
    }, [prodProcUid]);  

    useEffect(() => {
        !pageLoad && loadManuAmt();
    },[badAmts, goodAmt]);

    useEffect(() => {
        !pageLoad && loadWorkNum();
    },[manuDt]);
    
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            await handleClickSearch();
            setPageLoad(false);
            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        const para =  {
            p: {
                workNum: workNum === '' ? 'x' : workNum, 

                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        }

        const r = await cmn.api('getWorkList', para);
        if(r == null || r === undefined) return;
        const data = r.d0;

        let workListItems_ = [];
        
        _.forEach(data, (v,k) => {
            workListItems_.push({
                prodUid: v.prodUid
                ,workNum: v.workNum
                ,prodNm: v.prodNm
                ,prodId: v.prodId
                ,prodStnd: v.prodStnd
                ,equpNm: v.equpNm
                ,workAmt: v.workAmt
                ,accrueManuAmt: v.accrueManuAmt
                ,outTpNm: v.outTpNm
                ,workPer: v.workPer
            })
        })

        

        if(workListItems_.length > 0)
        {
            if(workListItems_[0].outTpNm === '미불출') return util.cmnAlert('재료를 투입해주세요.');
            setWorkListItems(workListItems_);
            loadWorkNum()
        }
        
    }

    
    const loadWorkNum = async() => {
        try {
            if(util.getChkNull(workNum) === '') return;
            
            let para = {p: {workNum: workNum}};

            let r = await cmn.api('getWorkNum', para);
            if(r == null || r.d0.length == 0) {
                util.cmnAlert('등록된 정보가 없습니다.');
                return;
            }

            let data = r.d0[0];
            setEqupUid(data.equpUid);
            setLineUid(data.lineUid);
            setProdUid(data.prodUid);

            let manuDy_ = util.getDateToString('d', manuDt, '-');
            let format_ = 'YYYY-MM-DD HH:mm';
            let dyStartTm_ = moment(`${manuDy_} ${util.getDateStrSplit('t' , data.dyStartTm, ':')}`).format(format_);
            let dyEndTm_ = moment(`${manuDy_} ${util.getDateStrSplit('t' , data.dyEndTm, ':')}`).format(format_);
         
            setLotTp(data.lotTp);
            setDyStartTm(dyStartTm_);
            setDyEndTm(dyEndTm_);
            setDyNtUseYn(data.dyNtUseYn);

          // 넘어오는 Lot 가 없을 경우 새로 생성함
            let preLot = cmn.getCrtDyLotNum(workNum, data.lotTp, data.dyNtUseYn, dyStartTm_, dyEndTm_, data.dyChar, data.ntChar, manuDt)
            setPreLot(preLot);

            if(util.getChkNull(lot) === '')
            {
                setLot(data.lot)
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadProdProcInfo = async() => {
      //  alert(prodUid);
        if(util.getChkNull(prodUid) === '') return;

        const para =  {
            p: {
                prodUid: prodUid
                ,firstYn: 'Y'
            }
        }
        const r = await cmn.api('getProdProcCombo', para);
        if(r == null || r === undefined) return;

        const data = r.d0[0];
        setProcNm(data.procNm);
        setProdProcUid(data.prodProcUid);
        setLastYn(data.lastYn);
        setEqupChkYn(data.equpChkYn);
        setStndImgFile(data.stndImgFile);
        setProcPrice(data.procPrice);
    }

    // 제품공정의 수량를 조회
    const loadProdProcAmtInfo = async() => {
        if(util.getChkNull(prodProcUid) === '' || util.getChkNull(workNum) === '') return;

        const ps = {
            p: {
                prodProcUid: prodProcUid,
                workNum: workNum,
                lot: ''
            }
        }

        const r = await cmn.api('getProdProcAmt', ps);
        if(r == null || r === undefined) return;
        const data = r.d0[0];

        console.log(data);

        setPossAmt(data.manuAmt);
    //    setManuAmt(data.manuAmt);
    }

    const loadManuAmt = () => {
        setManuAmt(goodAmt + _.sumBy(badAmts, 'badAmt'));
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

     
    const handleClickSearch = (e) => {
        //alert(1);
        loadList1();
        setRefreshCd(util.getUUID());
    //    alert(cmnStore.curLot);
    //    setLot(cmnStore.curLot);
    }

    const handleClickProcFlowTag = (pduid, lot) => {
        const url = `/cmn/procflowtagpop?${qs.stringify({uid: pduid, lot: lot})}`;        
        util.openWindow(url, 820, 840); 
    }

    const handleClickProcInsp = async(e) => {
        openProcInspPopup('a', '', '' // util.getDateToString('d',manuDy)
            , prodUid, prodProcUid, workNum, lot,  equpUid);
    }

    const openProcInspPopup = (mode, uid, pdy, pduid, ppuid, pwn, plot,  peuid) => {
        const url = `/qual/procinsppop?${qs.stringify({mode: mode, uid: uid, pdy: pdy, pduid: pduid, ppuid: ppuid, pwn: pwn, plot: plot, peuid: peuid, ptp: 'site'})}`;
        util.openWindow(url, 810, 720);
    }   

    const handleClickComplete = async(e) => {

        if(!util.chkMustValue(workNum, '작업지시'))
            return;
        if(!util.chkMustValue(userUid, '작업자'))
            return;
        if(!util.chkMustValue(manuAmt, '생산량', 'cnt'))
            return;

        //생산량과 양품량 + 불량품량이 같아야함.
        let totBadAmts = _.sumBy(badAmts, 'badAmt');
        if(manuAmt !== goodAmt + totBadAmts)
        {
            alert('[양품량]과 [불량품량]의 합은 [생산량]과 동일해야합니다.');
            return;
        }

        // 투입제품의 선입선출을 확인한다.
        // let bomLotItems_ = [...bomLotItems];
        // let totTakeAmt_ = 0;
        // let retVal = true;
        // _.forEach(bomProdItems, (v, k) => {
        //     if(totTakeAmt_ != 0) retVal = false;
        //     totTakeAmt_ = util.fix(_.multiply(util.fix(v.putAmt), util.fix(manuAmt)));
        //     _.forEach(bomLotItems_, (v2, k2) => {
        //         if(v2.putYn) {
        //             if(v.prodId == v2.prodId)
        //             {
        //                 if(totTakeAmt_ > v2.stockAmt)
        //                 {
        //                     v2.useAmt = v2.stockAmt;
        //                     totTakeAmt_ = util.fix(_.subtract(util.fix(totTakeAmt_), util.fix(v2.stockAmt)));
        //                 }else if(totTakeAmt_ <= v2.stockAmt)
        //                 {
        //                     v2.useAmt = totTakeAmt_;
        //                     totTakeAmt_ = 0;
        //                 }
        //             }
        //         }
        //     });
        //     if(totTakeAmt_ != 0) retVal = false;
        // });

        //  console.log('bomProdItems_', bomLotItems_);
 
        //  setBomLotItems(bomLotItems_);
 
        //  if(!retVal)
        //  {
        //      if(!util.cmnConfirm('[투입자재]가 부족합니다. \n계속 진행하시겠습니까?'))
        //          return;
        //  }

         if(util.confirmYn('실적등록'))
            addManu();
    }

 

    const addManu = async() => {
        let workList_ = workListItems[0];

        const lotPara = {p:{comUid: authStore.com.uid,
                              workNum: workNum, 
                              lot: util.getChkNull(lot) === '' ? preLot : lot}};

        let manuStndDy_ = cmn.getCrtDy(lotTp, dyNtUseYn, dyStartTm, dyEndTm, manuDt);

        let lot_ = await cmnStore.getLotCrt(lotPara);

        let prodUid = workList_.prodUid;
        const ps = {
            p: {
                manuUid: util.getUUID(),
                comUid: authStore.com.uid,
                prodUid: prodUid,
                prodProcUid: prodProcUid,
                equpUid: equpUid,
                lot: lot_,
                workNum: workNum,
                manuDy: util.getDateToString('d',manuDy),
                manuStndDy: manuStndDy_,
                manuDt: manuDt,
                userUid: userUid,
                manuAmt: manuAmt,
                goodAmt: goodAmt,
                unitWght: 0,
                cmnt: '',
                lastYn: lastYn,
                procPrice: procPrice,
                uptUserUid: authStore.user.uid,
                badAmts: badAmts,
                bizUid: bizUid
            }
        }

        const r = await cmn.api('addManu', ps);
        if(r == null || r === undefined) return;

        if(window.confirm('[실적등록] 되었습니다. \n[공정이동표]를 출력하시겠습니까?'))
            handleClickProcFlowTag(prodUid, lot_);
        else
            window.location.reload();
    }


    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                <GridTopView
                    title="작업지시 정보"
                >
                    <SrchButton 
                        id="srchButton"
                        type={"site-1"}
                        onClick={handleClickSearch}
                    />                    
                    <ActionButton 
                        title="검사등록"
                        width={"140px"}
                        type={"site-3"}
                        onClick={handleClickProcInsp}
                        show={true}
                    />
                    <ActionButton 
                        title="실적등록"
                        width={"140px"}
                        type={"site-1"}
                        onClick={handleClickComplete}
                        show={true}
                    />
                </GridTopView>
                <ListSearchView searchItems={searchItems} type={"site"} />
                <>
                <DataGridView            
                    dataSource={workListItems}   // store
                    gridHeight={80}
                    colsWidth={[200, 200, 200, null, null, null, null]}
                    showNo={false}
                    pagingable={false}       
                    selection=''                        
                >
                    { cmn.getGridColumn('workNum', '작업지시번호')}
                    { cmn.getGridColumn('prodNm', '품명')}
                    { cmn.getGridColumn('prodId', '품번')}
                    { cmn.getGridColumn('prodStnd', '규격')}
                    {/* { cmn.getGridColumn('equpNm', '생산설비')} */}
                    { cmn.getGridColumnNumCommaType('workAmt', '작업지시수량(EA)')}
                    { cmn.getGridColumnNumCommaType('accrueManuAmt', '누적생산수량(EA)')}
                    { cmn.getGridColumnPer('workPer', '진행률(%)')}
                </DataGridView>
                </>
                <div className="fl-l" style={{width: "49%", marginTop: "20px"}}>
                    <div className="site_list_type">
                        <table>
                            <colgroup>
                                <col className="tit" style={{width:'20%'}}/>
                                <col className="tcon" style={{width:'30%'}}/>
                                <col className="tit" style={{width:'20%'}}/>
                                <col className="tcon" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="tit" colSpan={4} >공정상태 정보(4M)</th>
                                </tr>
                                <tr>
                                    <th className="tit">공정명</th>
                                    <td className="tcon">
                                        <span>{procNm}</span>
                                    </td>
                                    <th className="tit">작업일</th>
                                    <td className="tcon">
                                        {/* <DayDateBox
                                            width={"150px"}
                                            value={manuDy}
                                            onValueChanged={(e) => {
                                                setManuDy(e.value);
                                            }}                    
                                        /> */}
                                        <DateTimeBox
                                            width={"200px"}
                                            value={manuDt}                                    
                                            onValueChanged={(dt) => { 
                                                setManuDy(util.getDateToString('d',dt));
                                                setManuDt(dt);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">작업자</th>
                                    <td className="tcon" colSpan={3}>
                                        <UserSelectBox
                                            width={"150px"}
                                            value={userUid}
                                            showClearButton={false}
                                            placeholder="작업자를 선택하세요"
                                            onValueChanged={(v) => { 
                                                setUserUid(v);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">업체</th>
                                    <td className="tcon" colSpan={3}>
                                        <BizSelectBox
                                            width={'200px'}
                                            value={bizUid}
                                            isDefaultVal={true}
                                            showClearButton={false}
                                            onInit={(i) => {
                                                setBizUid(i.bizUid);
                                            }}
                                            onValueChanged={(v) => {
                                                setBizUid(v);
                                            }}
                                        />  
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">공정설비</th>
                                    <td className="tcon">
                                        <EqupSelectBox
                                            width="200px"
                                            lineYn={authStore.com.isLine ? 'Y' : 'N'}
                                            lineValue={lineUid}
                                            value={equpUid}
                                            mode={'a'}
                                            onLoadInfo={(o) => {}} 
                                            onValueChanged={(v) => {
                                                setEqupUid(v);
                                            }}
                                        />   
                                    </td>
                                    <th className="tit">설비 점검여부</th>
                                    <td className="tcon">
                                        { equpChkYn === 'Y' ? '점검완료' : '미점검' }
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">작업표준서</th>
                                    <td className="tcon" colSpan={3} >
                                        { cmn.getRenderDownloadFile(stndImgFile) }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    <div className="site_list_type fl-r" style={{width: "49%", marginTop: "20px"}}>
                        <table>
                            <colgroup>
                                <col className="tit" style={{width:'20%'}}/>
                                <col className="tcon" />
                                <col className="tit" />
                                <col className="tcon" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="tit" colSpan={4} >작업등록</th>
                                </tr>
                                <tr>
                                    <th className="tit req">생산량<br />생산가능수량</th>
                                    <td className="tcon" colSpan={3}>
                                        {/* <NumberBox
                                            width={150}
                                            format={'#,##0.###'}
                                            value={manuAmt}
                                            defaultValue={0}
                                            min={0}
                                            max={possAmt}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setManuAmt(e.value);
                                            }}
                                        /> */}
                                        {manuAmt} / {possAmt}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">양품량</th>
                                    <td className="tcon" colSpan={3}>
                                        <NumberBox
                                            width={150}
                                            format={'#,##0.###'}
                                            value={goodAmt}
                                            defaultValue={0}
                                            min={0}
                                            max={possAmt}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setGoodAmt(e.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">불량</th>
                                    <td className="tcon" colSpan={3}>
                                        <BadAmtBox
                                            mode={'a'}
                                            badAmts={badAmts}
                                            unitTp={unitTp}
                                            unitTpNm={unitTpNm}
                                            onClickAdd={(badAmts) => { setBadAmts(badAmts); }}
                                            onClickDel={(badAmts) => { setBadAmts(badAmts); }}
                                        />
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>

                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"작업등록 (주공정)"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteManuRetFstProcPage;
const manuApiz = {
    
    getManuList: {
        title: "생산실적 목록 조회",
        url: "/api/manu/getManuList",
        method: "POST"
    },
    getManuListLot: {
        title: "생산실적 목록 조회(Lot단위)",
        url: "/api/manu/getManuListLot",
        method: "POST"
    },
    getManu: {
        title: "생산실적 상세 조회",
        url: "/api/manu/getManu",
        method: "POST"
    },
    getManuWorkNum: {
        title: "생산실적 조회(WorkNum)",
        url: "/api/manu/getManuWorkNum",
        method: "POST"
    },
    getManuBad: {
        title: "생산실적 불량 조회",
        url: "/api/manu/getManuBad",
        method: "POST"
    },
    addManu: {
        title: "생산실적 등록",
        url: "/api/manu/addManu",
        method: "POST"
    },
    upManu: {
        title: "생산실적 수정",
        url: "/api/manu/upManu",
        method: "POST"
    },
    delManu: {
        title: "생산실적 삭제",
        url: "/api/manu/delManu",
        method: "POST"
    },
    getLotCombo: {
        title: "Lot 콤보 조회",
        url: "/api/manu/getLotCombo",
        method: "POST"
    },
    getManuEqup: {
        title: "생산 실적 단일 설비 조회",
        url: "/api/manu/getManuEqup",
        method: "POST"
    },
    getManuItemLot: {
        title: "생산 투입 자재 조회",
        url: "/api/manu/getManuItemLot",
        method: "POST"
    },
    getManuStndByList: {
        title: "생산실적대기 조회",
        url: "/api/manu/getManuStndByList",
        method: "POST"
    },
    getManuNBadAmt: {
        title: "생산실적 대비 불량건수 조회",
        url: "/api/manu/getManuNBadAmt",
        method: "POST"
    },
    getManuListTime: {
        title: "시간당생산량",
        url: "/api/manu/getManuListTime",
        method: "POST"
    },
    getManuListProdPrice: {
        title: "제품원가",
        url: "/api/manu/getManuListProdPrice",
        method: "POST"
    },
    getManuTimeDay: {
        title: "생산시간조회",
        url: "/api/manu/getManuTimeDay",
        method: "POST"
    },
    upManuTimeDay: {
        title: "생산시간등록",
        url: "/api/manu/upManuTimeDay",
        method: "POST"
    },
    getReadTimeList: {
        title: "제조리드타임",
        url: "/api/manu/getReadTimeList",
        method: "POST"
    },
    getManuListBadProd: {
        title: "완제품불량률",
        url: "/api/manu/getManuListBadProd",
        method: "POST"
    },
    getWorkManList: {
        title: "작업공수",
        url: "/api/manu/getWorkManList",
        method: "POST"
    }
    
    
}

export default manuApiz;
import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    FileUploadBox,
    TimeBox,
    UseYnSwitchBox,
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  회사 등록 / 수정
 */
const ComPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [comNm, setComNm] = useState('');
    const [ownerNm, setOwnerNm] = useState('');
    const [bizNum, setBizNum] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [addr, setAddr] = useState('');
    const [addrDtl, setAddrDtl] = useState('');
      
    const [dyChar, setDyChar] = useState('D');
    const [ntChar, setNtChar] = useState('N');
    const [dyStartTm, setDyStartTm] = useState(`${util.getNow()} ${'08:00'}`);
    const [dyEndTm, setDyEndTm] = useState(`${util.getNow()} ${'20:00'}`);
    const [ntTm, setNtTm] = useState('20:00 ~ 익일 08:00');
    const [dyNtUseYn, setDyNtUseYn] = useState('Y');

    const [mainImgUid, setMainImgUid] = useState(util.getUUID());
    const [logoImgUid, setLogoImgUid] = useState(util.getUUID());
    const [mainUploadFiles, setMainUploadFiles] = useState([]);
    const [logoUploadFiles, setLogoUploadFiles] = useState([]);

    const [manualFileUid, setManualFileUid] = useState(util.getUUID());
    const [manualUploadFiles, setManualUploadFiles] = useState([]);

    const [lineYn, setLineYn] = useState('N');

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadNtTm();
    },[dyStartTm, dyEndTm]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {            
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            const ps = {search:{}}

            const r = await cmn.api('getCom', ps);
            if(r == null || r === undefined) return;
            
            const data = r.d0[0];
            
            setComNm(data.comNm);
            setOwnerNm(data.ownerNm);
            setBizNum(data.bizNum);
            setPhone(data.phone);
            setFax(data.fax);
            setAddr(data.addr);
            setAddrDtl(data.addrDtl);
            setMainImgUid(data.mainImgUid == null ? util.getUUID() : data.mainImgUid);
            setLogoImgUid(data.logoImgUid == null ? util.getUUID() : data.logoImgUid); 
            setManualFileUid(data.manualFileUid == null ? util.getUUID() : data.manualFileUid);

            setDyNtUseYn(data.dyNtUseYn);
            setDyChar(data.dyChar);
            setNtChar(data.ntChar);
            setDyStartTm(`${util.getNow()} ${util.getDateStrSplit('t' , data.dyStartTm, ':')}`);
            setDyEndTm(`${util.getNow()} ${util.getDateStrSplit('t' , data.dyEndTm, ':')}`);
            setNtTm(`${util.getDateStrSplit('t' , data.dyEndTm, ':')} ~ 익일 ${util.getDateStrSplit('t' , data.dyStartTm, ':')}`);
            setLineYn(data.lineYn);
            
            //files
            const f0 = r.f0;
            const f1 = r.f1;
            const f2 = r.f2;
            setMainUploadFiles(f0);
            setLogoUploadFiles(f1);
            setManualUploadFiles(f2);
            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }

    }

    const loadNtTm = () => {
        
        setNtTm(`${util.getDateToString('t', dyEndTm, ':')} ~ 익일 ${util.getDateToString('t', dyStartTm, ':')}`);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(comNm, '회사명'))
                return;

            // if(mode == "a")
            // { 
            //     if(util.confirmYn("등록"))
            //         addCom();
            // }
            // else if(mode == "u") 
            // {
                if(util.confirmYn("수정"))
                    upCom();
            //}
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // const addCom = async() => {

    //     const ps = {
    //         coms: [
    //             {
    //                 comUid: util.getUUID(),
    //                 comUid: authStore.com.uid,
    //                 comNm: comNm,
    //                 comTp: comTp,
    //                 useYn: useYn,
    //                 cmnt: cmnt,
    //                 uptUserUid: authStore.user.uid
    //             }
    //         ]
    //     }

    //     console.log("===== para", para);
    //     const resData = await comStore.addCom(para);

    //     if(resData.code != RCODE_S){
    //         // ...
    //         alert(`error: ${resData.message}`);
    //         return;
    //     }

    //     util.cmnSucAlert("등록");

    //     // Reload Opener List & Close Popup
    //     util.clickOpenerElement("srchButton");
    //     window.close();
    // }

    const upCom = async() => {
        const ps = {
            p: {
                comUid: uid,
                comNm: comNm,
                ownerNm: ownerNm,
                bizNum: bizNum,
                phone: phone,
                fax: fax,
                addr: addr,
                addrDtl: addrDtl,
                mainImgUid: mainImgUid,
                logoImgUid: logoImgUid,
                manualFileUid: manualFileUid,
                dyNtUseYn: dyNtUseYn,
                dyChar: dyChar,
                ntChar: ntChar,
                dyStartTm: util.getDateToString('t', dyStartTm),
                dyEndTm: util.getDateToString('t', dyEndTm),
                mainUploadFiles: mainUploadFiles,
                logoUploadFiles: logoUploadFiles,
                manualUploadFiles : manualUploadFiles,
                lineYn: lineYn,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('upCom', ps);
        if(r == null || r === undefined) return;
        
        util.cmnSucAlert("수정");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">회사명</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={comNm}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setComNm(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">대표자명</th>
                            <td className="tcon" >
                                <TextBox 
                                    value={ownerNm}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setOwnerNm(e.value);
                                    }}
                                /> 
                            </td>
                            <th className="tit">사업자번호</th>
                            <td className="tcon">
                                <TextBox 
                                    value={bizNum}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setBizNum(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">대표번호</th>
                            <td className="tcon">
                                <TextBox 
                                    value={phone}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setPhone(e.value);
                                    }} 
                                />
                            </td>
                            <th className="tit">FAX</th>
                            <td className="tcon">
                                <TextBox 
                                    value={fax}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setFax(e.value);
                                    }}
                                /> 
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">주소</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={addr}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setAddr(e.value);
                                    }} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">상세주소</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={addrDtl}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setAddrDtl(e.value);
                                    }}
                                /> 
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">메인 이미지</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox                                    
                                    // multiple={false}
                                    limit={1}
                                    grpUid={mainImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={mainUploadFiles}
                                    onUploaded={(e) => {
                                        setMainUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setMainUploadFiles(e);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">Logo 이미지</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox                                    
                                    // multiple={false}
                                    limit={1}
                                    grpUid={logoImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={logoUploadFiles}
                                    onUploaded={(e) => {
                                        setLogoUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setLogoUploadFiles(e);
                                    }}
                                />
                            </td>
                        </tr>
                        
                    </tbody>
                </table>

                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">주/야<br/>사용여부</th>
                            <td className="tcon" colSpan={3}>
                                <UseYnSwitchBox 
                                    value={dyNtUseYn}
                                    onValueChanged={(v)=>{
                                        setDyNtUseYn(v);
                                    }} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">주간</th>
                            <td className="tcon">
                                <TextBox 
                                    value={dyChar}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setDyChar(e.value);
                                    }} 
                                />
                            </td>
                            <th className="tit">주간 시간</th>
                            <td className="tcon">
                                <ul className="ul-row">
                                    <li>
                                        <TimeBox
                                            value={dyStartTm}                                    
                                            onValueChanged={(dt) => {
                                                let startTm = util.getDateToString('m', dt, '-');
                                                if(startTm >= dyEndTm)
                                                {
                                                    util.cmnAlert('시작시간이 종료시간보다 큽니다.');
                                                    return;
                                                }
                                                setDyStartTm(startTm);
                                            }}
                                        /> ~ 
                                    </li>
                                    <li>
                                        <TimeBox
                                            value={dyEndTm}                                    
                                            onValueChanged={(dt) => { 
                                                let endTm = util.getDateToString('m', dt, '-');
                                                if(dyStartTm >= endTm)
                                                {
                                                    util.cmnAlert('시작시간이 종료시간보다 큽니다.');
                                                    return;
                                                }
                                                setDyEndTm(endTm);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">야간</th>
                            <td className="tcon">
                                <TextBox 
                                    value={ntChar}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setNtChar(e.value);
                                    }} 
                                />
                            </td>
                            <th className="tit">야간 시간</th>
                            <td className="tcon">
                                <span>{ntTm}</span>
                            </td>
                        </tr>
                        {authStore.user.sgr ? 
                        <>
                        <tr>
                            <th className="tit">매뉴얼</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox                                    
                                    // multiple={false}
                                    limit={1}
                                    grpUid={manualFileUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={manualUploadFiles}
                                    onUploaded={(e) => {
                                        setManualUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setManualUploadFiles(e);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">라인유무</th>
                            <td className="tcon" colSpan={3}>
                                <UseYnSwitchBox 
                                    value={lineYn}
                                    onValueChanged={(v)=>{
                                        setLineYn(v);
                                    }} 
                                />
                            </td>
                        </tr>
                        </>
                        : ''
                        }
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "회사 등록" 
                : mode === "u" ? "회사 수정"
                : "회사" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ComPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { Observer} from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    ProdSearchBox, CodeSelectBox, SrchButton, ActionButton, DayDateBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  제품원가
 */
const ProdCostPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    // * 검색
    const [workMon, setWorkMon] = useState(util.getNow());
    const [prodUid, setProdUid] = useState('');

    /* #region Search Items */

    const searchItems = [
        [
            {   title: "작업지시월",
                width: "200px",
                item: 
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={workMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setWorkMon(e.value);
                            }}                    
                        />
                    </li>
                </ul>
            },
            {   title: "제품검색",
                item: 
                <ProdSearchBox
                    width={"300px"}
                    value={prodUid}
                    popCenter={false}
                    onLoadInfo={(o) => {
                    }} 
                    onValueChanged={(v) => {                                        
                        setProdUid(v);
                    }}
                />      
            }
        ]
    ];

    /* #endregion */

    
    // * Grid
    const [manuListProdPrice, setManuListProdPrice] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            //await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        if(util.getChkNull(prodUid) === '')
        {
            util.cmnAlert('[제품]을 선택해 주십시오.');
            return;
        }

        const ps =  {
            p: {
                startDy: util.getMonStartEndDy('S', workMon), 
                endDy :  util.getMonStartEndDy('E', workMon), 
                prodUid: prodUid,
                sortCol: sortItem.col,
                sortTp: sortItem.tp
            }
        }

        const r = await cmn.api("getManuListProdPrice", ps );
        if(r === undefined || r == null) return;

        setManuListProdPrice(r.d0)
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.bizUid);
        }
    }

    const handleClickView = async () => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.bizUid);
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, bizUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/bizpop?${qs.stringify({mode: mode, uid: bizUid})}`;        
        util.openWindow(url, 700, 500);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                /> */}
            </ListTopView>   
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="제품 원가"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView> 
            <DataGridView            
                dataSource={manuListProdPrice}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}                

                // paging
                // page={curPage}
                // total={manuStore.bizListTotal}   // store
                // itemsPerPage={rowsLength}
                // onClickPage={handleClickPage}
                // onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}  
                
                colsWidth={[100, 150, 150, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, null]}
            >
                { cmn.getGridColumnDayType('workDy', '작업지시일', renderGridHeaderCell) }
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell) }
                { cmn.getGridColumn('workMonAmt', '작업수량', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('prodPrice', '제품원가') }
                { cmn.getGridColumn('proc1', '공정1') }
                { cmn.getGridColumn('proc2', '공정2') }
                { cmn.getGridColumn('proc03', '공정3') }
                { cmn.getGridColumn('proc04', '공정4') }
                { cmn.getGridColumn('proc05', '공정5') }
                { cmn.getGridColumn('proc06', '공정6') }
                { cmn.getGridColumn('proc07', '공정7') }
                { cmn.getGridColumn('proc08', '공정8') }
                { cmn.getGridColumn('proc09', '공정9') }
                { cmn.getGridColumn('proc09', '공정10') }
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ProdCostPage;
import React, { useState, useEffect } from 'react';
import { CheckBox, SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */
import { DayDateBox } from '..';

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  제품 선택 조회
 */
const ChkCycleChkListBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        mode,
        //width='100%',

        datas=[],
        direction,

        onValueChanged,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [codes, setCodes] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadInfo1();
    },[codes, datas]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            const r = await cmn.apiCodeCombo("402");
            setCodes(r);
        }
        catch(err) {
            // nothing ...
        }
    }

    const loadInfo1 = async() => {
        const items_ = [];

        _.forEach(codes, (v, k) => {
            const o = _.find(datas, {chkCycleTp: v.codeId});            
            items_.push(
                {
                    codeNm: v.codeNm,
                    codeId: v.codeId,
                    equpChkCycleUid: o === undefined ? null : o.equpChkCycleUid,
                    chkCycleTp: v.codeId,
                    checked: o === undefined ? false : o.checked,        
                    wk: o === undefined ? null : o.wk,
                    dd: o === undefined ? null : o.dd,
                    startDy: o === undefined ? '' : o.startDy
                }
            );

        }); 

        setItems(items_);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    /**
     * 
     * @param {*} k - array index
     * @param {*} c - column
     * @param {*} v - value
     */
    const handleValueChanged = (k, c, v) => {
        let items_ = {...items};
        items_[k][c] = v;
        setItems(items_);
 
        onValueChanged(items_);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <ul 
                style={{
                    display: direction === "column" ? "inline-flex" : null
                }}
            >
            {
                _.map(items, (v, k) => {
                    return(
                        <li key={k}
                            style={{
                                padding: direction === "column" ? "2px" : 0
                            }}
                        >
                            <ul className="ul-row">
                                <li>
                                    <CheckBox key={k}
                                        readOnly={mode === 'a' || mode === 'u' ? false:true}
                                        text={v.codeNm}
                                        value={v.checked === undefined ? false : v.checked }
                                        onValueChanged={(e) => {
                                            handleValueChanged(k, 'checked', e.value);
                                        }}  
                                    />
                                </li>
                                {
                                    (v.checked && v.codeId === cmn.CMNCO_CHKCYCLE.WEEK) &&
                                    <li
                                        style={{ paddingLeft: "16px" }}
                                    >
 
                                        매주
                                        {mode === 'a' || mode === 'u' ?
                                            <SelectBox 
                                                width={60}
                                                value={v.wk}
                                                dataSource={cmn.weekItems}
                                                displayExpr={'d'}
                                                valueExpr={'v'}
                                                onValueChanged={(e)=>{
                                                    handleValueChanged(k, 'wk', e.value);
                                                }} 
                                            /> 
                                        : <span>{v.wk}</span>
                                        }
                                        요일 마다                                      
                                    </li>
                                }
                                {
                                    (v.checked && v.codeId === cmn.CMNCO_CHKCYCLE.MON) &&
                                    <li
                                        style={{ paddingLeft: "16px" }}
                                    >
                                        매월
                                        {mode === 'a' || mode === 'u' ?
                                            <SelectBox 
                                                width={60}
                                                // placeholder={"검사일 선택"}
                                                value={v.dd}
                                                dataSource={cmn.getDayItems()}
                                                displayExpr={'d'}
                                                valueExpr={'v'}
                                
                                                onValueChanged={(e)=>{
                                                    handleValueChanged(k, 'dd', e.value);
                                                }} 
                                            />
                                        : <span>{v.dd}</span>
                                        }
                                        일 마다
                                    </li>
                                }
                                {
                                    (v.checked && v.codeId === cmn.CMNCO_CHKCYCLE.YEAR) &&
                                    <li
                                        style={{ paddingLeft: "16px" }}
                                    >
                                        {mode === 'a' || mode === 'u' ?
                                            <DayDateBox                                            
                                                value={v.startDy}
                                                onValueChanged={(e)=>{
                                                    // console.log("--- DayDateBox e", e);
                                                    handleValueChanged(k, 'startDy', util.getChkNull(e.value) === '' ? '' : util.getDateToString('d',e.value));
                                                }} 
                                            />
                                        : <span>{util.getDateStrSplit('d', v.startDy)}</span>
                                        }
                                        부터 매년 같은 날
                                    </li>
                                }
                                {
                                    (v.checked && v.codeId === cmn.CMNCO_CHKCYCLE.QUAT) &&
                                    <li
                                        style={{ paddingLeft: "16px" }}
                                    >
                                        {mode === 'a' || mode === 'u' ?
                                            <DayDateBox                                            
                                                value={v.startDy}
                                                onValueChanged={(e)=>{
                                                    handleValueChanged(k, 'startDy', util.getChkNull(e.value) === '' ? '' : util.getDateToString('d',e.value));
                                                }} 
                                            />
                                        : <span>{util.getDateStrSplit('d', v.startDy)}</span>
                                        }
                                        부터 3개월 마다
                                    </li>
                                }
                                 {
                                    (v.checked && v.codeId === cmn.CMNCO_CHKCYCLE.HALF) &&
                                    <li
                                        style={{ paddingLeft: "16px" }}
                                    >
                                        {mode === 'a' || mode === 'u' ?
                                            <DayDateBox                                            
                                                value={v.startDy}
                                                onValueChanged={(e)=>{
                                                    handleValueChanged(k, 'startDy', util.getChkNull(e.value) === '' ? '' : util.getDateToString('d',e.value));
                                                }} 
                                            />
                                        : <span>{util.getDateStrSplit('d', v.startDy)}</span>
                                        }
                                        부터 6개월 마다
                                    </li>
                                }
                            </ul>
                        </li>
                    )
                })       
            }
            </ul>
         )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default ChkCycleChkListBox;

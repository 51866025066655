const codeApiz = {

    addCode: {
        title: "코드 등록",
        url: "/api/code/addCode",
        method: "POST"
    },   
    upCode: {
        title: "코드 수정",
        url: "/api/code/upCode",
        method: "POST"
    }, 
    delCode: {
        title: "코드 삭제",
        url: "/api/code/delCode",
        method: "POST"
    },  
    getCodeCombo: {
        title: "코드 콤보",
        url: "/api/code/getCodeCombo",
        method: "POST"
    },
    getCodeList: {
        title: "코드 목록 조회",
        url: "/api/code/getCodeList",
        method: "POST"
    },     
    getCodeClass: {
        title: "코드 클래스 그룹 조회",
        url: "/api/code/getCodeClass",
        method: "POST"
    },
    getCode: {
        title: "코드 상세 조회",
        url: "/api/code/getCode",
        method: "POST"
    },
    addCateMain: {
        title: "대분류 등록",
        url: "/api/code/addCateMain",
        method: "POST"
    },   
    upCateMain: {
        title: "대분류 수정",
        url: "/api/code/upCateMain",
        method: "POST"
    }, 
    delCateMain: {
        title: "대분류 삭제",
        url: "/api/code/delCateMain",
        method: "POST"
    },  
    getCateMain: {
        title: "대분류 상세 조회",
        url: "/api/code/getCateMain",
        method: "POST"
    },
    getCateMainList: {
        title: "대분류 목록 조회",
        url: "/api/code/getCateMainList",
        method: "POST"
    },  
    getCateMainCombo: {
        title: "대분류 콤보",
        url: "/api/code/getCateMainCombo",
        method: "POST"
    },  
    addCateSub: {
        title: "중분류 등록",
        url: "/api/code/addCateSub",
        method: "POST"
    },   
    upCateSub: {
        title: "중분류 수정",
        url: "/api/code/upCateSub",
        method: "POST"
    }, 
    delCateSub: {
        title: "중분류 삭제",
        url: "/api/code/delCateSub",
        method: "POST"
    },  
    getCateSub: {
        title: "중분류 상세 조회",
        url: "/api/code/getCateSub",
        method: "POST"
    },
    getCateSubList: {
        title: "중분류 목록 조회",
        url: "/api/code/getCateSubList",
        method: "POST"
    },  
    getCateSubCombo: {
        title: "중분류 콤보",
        url: "/api/code/getCateSubCombo",
        method: "POST"
    },    
}

export default codeApiz;
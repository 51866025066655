import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, CodeSelectBox, BizSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  외주출고 관리
 */
const OutsOutPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * Control Items (* Combo ...)
    // * 검색
    const [prodId, setProdId] = useState('');
    const [procTp, setProcTp] = useState('');
    const [bizUid, setBizUid] = useState('');

    /* #region Search Items */
    
    const searchItems = [
        [
            {   title: "품번",
                width: "200px",
                item: 
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />  
            },
            {   title: "공정",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={procTp}
                    classId={'104'}
                    placeholder="공정을 선택하세요."
                    onValueChanged={(v) => { 
                        setProcTp(v);
                    }}
                />      
            },
            {   title: "협력사",
                item: 
                <BizSelectBox
                    value={bizUid}
                    width={200}
                    bizTp={[cmn.CMNCO_BIZ.PART]}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        setBizUid(v);
                    }}
                />   
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [outsOutList, setOutsOutList] = useState([]);
    const [outsOutListTot, setOutsOutListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    // const dgCodeListRef = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodId: prodId, 
                procTp: procTp, 
                bizUid: bizUid, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getOutsOutList", ps );
        if(r === undefined || r == null) return;

        setOutsOutList(r.d0)
        setOutsOutListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // const handleClickAdd = (e) => {
    //     if(util.chkListSelected(selectedItem)){
    //         // page mode: v(View), a(Add), u(Update)
    //         const url = `/manu/outsInpop?${qs.stringify({mode: 'a', ouid:selectedItem.outsOutUid})}`;        
    //         util.openWindow(url, 700, 500);    
    //     }
    // }

    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.outsOutUid);
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, outsOutUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/manu/outsOutpop?${qs.stringify({mode: mode, uid:outsOutUid})}`;        
        util.openWindow(url, 700, 500);    
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                /> 
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />  
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    type={2}
                    title="외주입고"
                    show={authStore.page.crtYn}
                />   */}
            </ListTopView>    
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="외주출고 내역"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView>     
            <DataGridView            
                dataSource={outsOutList}
                gridHeight={authStore.windowHeight - 290}

                // paging
                page={curPage}
                total={outsOutListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}     
                
                colsWidth={[100, 100, 120, 150, 120, 120, 120, 100, null]}
            >
                { cmn.getGridColumnDayType('outDy', '출고일')}
                { cmn.getGridColumn('bizNm', '출고업체')}
                { cmn.getGridColumn('procNm', '공정')}
                { cmn.getGridColumn('lot', 'Lot')}
                { cmn.getGridColumnProdInfo('prodNm', '품명')}
                { cmn.getGridColumn('prodId', '품번')}
                { cmn.getGridColumn('prodStnd', '규격')}
                { cmn.getGridColumnNumCommaType('outAmt', '출고수량')}
                {/* { cmn.getGridColumnNumCommaType('inAmt', '입고수량')}
                { cmn.getGridColumnPer('inPer', '입고율')} */}
                { cmn.getGridColumnCmnt()}
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default OutsOutPage;
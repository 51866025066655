import React, { useState, useEffect, useContext } from 'react';
import { SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    PopupLayout, 
    ActionButton,
    UseYnSwitchBox,
    CodeSelectBox,
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  사용자 등록 / 수정
 */
const UserPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */    
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [deptItems, setDeptItems] = useState('');
    const [userId, setUserId] = useState('');
    const [userPw, setUserPw] = useState('000');
    const [userNm, setUserNm] = useState('');
    const [deptUid, setDeptUid] = useState('');
    const [deptNm, setDeptNm] = useState('');
    const [mobile, setMobile] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [loginUrlTp, setLoginUrlTp] = useState('');
    const [loginUrlTpNm, setLoginUrlTpNm] = useState('');
    const [useYn, setUseYn] = useState('Y');
    const [cmnt, setCmnt] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {        
        try {
            await authStore.loadPopPage(props);

            //등록, 수정
            if(mode === "a" || mode === "u"){
                //부서의 Combo 정보를 호출함.
                let r = await cmn.api('getDeptCombo', {})
                if(r == null || r === undefined) return;

                setDeptItems(r.d0);
            }
            
            //수정, 상세보기 
            if(mode === "u" || mode === "v"){
                await loadInfo1();
            }
            
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

      const loadInfo1 = async() => {
        try {            
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api('getUser', {p: {userUid: uid}});
            if(r == null || r === undefined) return;

            const d0 = r.d0[0];
            
            setDeptUid(d0.deptUid);
            setDeptNm(d0.deptNm);
            setUserId(d0.userId);
            setUserPw(d0.userPw);
            setUserNm(d0.userNm);
            setMobile(d0.mobile);
            setPhone(d0.phone);
            setEmail(d0.email);
            setPosition(d0.position);
            setUseYn(d0.useYn);
            setCmnt(d0.cmnt);
            setLoginUrlTp(d0.loginUrlTp);
            setLoginUrlTpNm(d0.loginUrlTpNm);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = async (e) => {
        try {
            //validation 체크
            if(!util.chkMustValue(deptUid, '부서'))
                return;
            if(!util.chkMustValue(userId, '아이디'))
                return;
            if(!util.chkMustValue(userNm, '이름'))
                return;
            if(!util.chkMustValue(mobile, '핸드폰'))
                return;

            if(mode === "a")
            {
                const ps = {p : { userId : userId}}
                let r = await cmn.api('getUserDupYn', ps);
                if(r == null && r === undefined) return;
                let data = r.d0[0];
                if(data.dupYn === 'Y')
                {
                    util.cmnAlert('아이디가 중복되었습니다.');
                    return;
                }

                if(util.confirmYn("등록"))
                    addUser();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upUser();
            }
            else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delUser();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addUser = async() => {
        let pwInfo = {}
        pwInfo = util.pwInit(userPw);
        
        const ps = {
            p: {
                userUid: util.getUUID(),
                comUId: authStore.com.uid,
                deptUid: deptUid,
                userId: userId,
                userPw: pwInfo.retKey,
                salt: pwInfo.retBuf,
                userNm: userNm,
                mobile: mobile,
                phone: phone,
                email:email,
                position: position,
                loginUrlTp: loginUrlTp,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('addUser', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upUser = async() => {
        const ps = {
            p: {
                userUid: uid,
                deptUid: deptUid,
                userId: userId,
                userNm: userNm,
                mobile: mobile,
                phone: phone,
                email: email,
                position: position,
                loginUrlTp: loginUrlTp,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('upUser', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delUser = async() => {
        const r = await cmn.api('delUser', {p: {comUid: authStore.com.uid, userUid: uid}});
        if(r == null || r === undefined) return;

        util.cmnSucAlert("삭제");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">부서</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <SelectBox 
                                        value={deptUid}
                                        dataSource={deptItems}
                                        displayExpr={'deptNm'}
                                        valueExpr={'deptUid'}
                                        placeholder="부서를 선택하세요"
                                        showClearButton={false}
                                        onValueChanged={(e)=>{
                                            setDeptUid(e.value);
                                        }} 
                                    />
                                :
                                    <span>{deptNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">아이디</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <TextBox 
                                        value={userId}
                                        showClearButton={true}
                                        placeholder="아이디를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setUserId(e.value);
                                        }} 
                                    />
                                :
                                    <span>{userId}</span>
                                }
                            </td>
                            <th className="tit req">사원명</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={userNm}
                                        showClearButton={true}
                                        placeholder="사원명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setUserNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">직위</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={position}
                                        showClearButton={true}
                                        placeholder="직위명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setPosition(e.value);
                                        }} 
                                    />
                                :
                                    <span>{position}</span>
                                }
                            </td>
                            <th className="tit">E-Mail</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={email}
                                        showClearButton={true}
                                        placeholder="E-Mail을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setEmail(e.value);
                                        }} 
                                    />
                                :
                                    <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">연락처</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={phone}
                                        showClearButton={true}
                                        placeholder="연락처를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setPhone(e.value);
                                        }} 
                                    />
                                :
                                    <span>{phone}</span>
                                }
                            </td>
                            <th className="tit req">핸드폰</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={mobile}
                                        showClearButton={true}
                                        placeholder="핸드폰번호를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMobile(e.value);
                                        }} 
                                    />
                                : 
                                    <span>{mobile}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">로그인 경로</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={loginUrlTp}
                                        classId={'709'}
                                        showClearButton={false}
                                        placeholder="로그인 경로를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setLoginUrlTp(v);
                                        }}
                                    />
                                : 
                                    <span>{loginUrlTpNm}</span>
                                }
                            </td>
                            <th className="tit">재직여부</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={useYn}
                                        onValueChanged={(v)=>{
                                            setUseYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요"
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "사용자 등록" 
                : mode === "u" ? "사용자 수정"
                : mode === "v" ? "사용자 상세보기"
                : "사용자" 
            }
            content = {!pageLoad && renderContent()}
        />
        </>
    )
}

export default UserPopPage;
import React from 'react';

/** components */
import {
    IconButton
 } from '../../components';

const PrintLayout = (props) => {

    const { 
        title = "",
        content = null,

    } = props;

    const handleClickClose = () => {
        window.close();
    }

    return(
        <>
        <div id="printWrap" className="no_print">
            <div id="printHeader" className="clearfix">
				<h1 className="pop-tit fl-l">{ title }</h1>
                <IconButton
                    className="btn btn-icon-pop-close fl-r"
                    alt="닫기"
                    onClick={handleClickClose}
                    marginRight={0}
                />
			</div>

        </div>
        <div className="print-content">
                {content}
            </div>
        </>
    )
}

export default PrintLayout;
import React, { useState, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import SplitterLayout from 'react-splitter-layout';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, 
    SrchButton, SubActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  고객제품 관리
 */
const BizProdPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [bizNm, setBizNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "업체명",
                item: 
                <TextBox 
                    value={bizNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="업체명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setBizNm(e.value);
                    }}
                />         
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [bizList, setBizList] = useState([]);
    const [bizListTot, setBizListTot] = useState(0);
    const [bizProdList, setBizProdList] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItem2, setSelectedItem2] = useState(null);

    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    useEffect(() => {
        !pageLoad && loadList2();
    }, [selectedItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                bizTp: cmn.CMNCO_BIZ.CUST,
                bizNm: bizNm, 
                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getBizList", ps );
        if(r === undefined || r == null) return;

        setBizList(r.d0)
        setBizListTot(r.tot);
    }

    const loadList2 = async() => {
        if(selectedItem == null) return; 

       // alert(bizUid);
        const ps =  {
            p: {
                bizUid: selectedItem.bizUid, 

                sortCol: "",
                sortTp: "",
                offset: 0,
                limit: -1,            
            }
        }

        const r = await cmn.api("getBizProdList", ps );
        if(r === undefined || r == null) return;

        setBizProdList(r.d0)

    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickSearch = (e) => {
        loadList1();     
    }

    const handleClickSearch2 = (e) => {
        loadList2();
    }

    const handleClickAdd = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("a", selectedItem.bizUid, "");
        }
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem2)){
            openCrudPopup("u", selectedItem.bizUid, selectedItem2.bizProdUid);
        }
    }

    const handleClickDel = async() => {
        if(util.chkListSelected(selectedItem2)){

            if(!util.confirmYn('삭제')) return;

            const ps =  {
                p: {
                    bizProdUid: selectedItem2.bizProdUid,
                }
            }

            let r = await cmn.api('delBizProd', ps);
            if(r == null || r === undefined) return;

            util.cmnSucAlert('삭제');

            loadList2();
        }
    }

    const handleGridSelectionChanged = ({selectedRowsData}) => {
       // console.log("=== selectedRowsData", selectedRowsData);
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGrid2SelectionChanged =  ({selectedRowsData}) => {
       // console.log("=== selectedRowsData", selectedRowsData);
        if(selectedRowsData.length === 0) { 
            setSelectedItem2(null); return;
        }
        setSelectedItem2(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);        
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    const openCrudPopup = (mode, buid, bpduid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/sales/bizprodpop?${qs.stringify({mode: mode, buid: buid, uid: bpduid})}`;
        util.openWindow(url, 600, 500);
    }

    /* ====================
     *  Render
     * ==================== */
    const renderLeftContent = () => {

        return (
            <>
            <GridTopView
                title="고객 정보"
            >
            </GridTopView>            
            <DataGridView            
                dataSource={bizList}  
                gridHeight={authStore.windowHeight - cmn.BH.H21}

                // paging
                page={curPage}
                total={bizListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort                
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged} 
                
                colsWidth={[150, 100, 150, null]}
            >
                { cmn.getGridColumn('bizNm', '업체명') }
                { cmn.getGridColumn('prodCnt', '제품건수') }
                { cmn.getGridColumn('bizNum', '사업자번호') }
                <Column />
            </DataGridView>
            </>
        )   
    }

    const renderRightContent = () => {

        return (
            <> 
            <GridTopView
                title="제품 정보"
            >
                <SubActionButton 
                    onClick={handleClickAdd}
                    title="추가"
                    show={authStore.page.crtYn}
                /> 
                <SubActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />  
                <SubActionButton 
                    onClick={handleClickDel}
                    title="삭제"
                    marginRight={0}
                    show={authStore.page.delYn}
                />
            </GridTopView>               
            <DataGridView            
                dataSource={bizProdList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H20}

                page={-1}

                onSelectionChanged={handleGrid2SelectionChanged}
                colsWidth={[120, 120, 100, 80, 80, 80,  null]}
            >
                { cmn.getGridColumnProdInfo('prodNm', '품명') }
                { cmn.getGridColumn('prodId', '품번') }
                { cmn.getGridColumn('prodStnd', '규격') }
                { cmn.getGridColumnNumCommaType('bizPrice', '단가') }
                { cmn.getGridColumnUseYn('dealYn', '거래여부') }
                <Column />                 
            </DataGridView>
            </>
        )   
    }
 
    const renderContent = () => {

        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    marginRight={0}
                    onClick={handleClickSearch}
                />
                <SrchButton 
                    id="subSrchButton"
                    marginRight={0}
                    onClick={handleClickSearch2}
                    show={false}
                />                
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <Observer>{() =>    
            <div className="cont-split"
                style={{
                    marginTop: 16,
                }}
            >
                <SplitterLayout
                    customClassName={`split-layout-cust ${authStore.sideOpened === true ? "is-visible" : ""}`}
                >
                    <div className="cont-split-left"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderLeftContent() }
                    </div>
                    <div className="cont-split-right"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderRightContent() }
                    </div>
                </SplitterLayout>
            </div>
            }</Observer>     
            </>
        );

    }

    return (
        <>
        { renderContent() }
        </>
    )
}

export default BizProdPage;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import { Chart, Series, ArgumentAxis, CommonSeriesSettings
    , Export, Legend, Margin, Title, Subtitle, Tooltip, Grid, Size } from 'devextreme-react/chart';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

/** components */
import {
    IconButton, DayDateBox, SrchButton,
    ActionButton, SiteLayout, EqupSelectBox,
    GridTopView, ListSearchView, DataGridView, EditGridView 
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  설비 작업 모니터링
 */
const SiteEqupWorkMonitPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */
      

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);    

    // const [equpUid , setEqupUid ] = useState('');
    const [monitorTp, setMonitorTp] = useState('1');    

    const [concentItems, setConcentItems] = useState([
        { value: 'measVal', name: '농도', color: '#009CE8' },
    ]);

    const [concentDatas, setConcentDatas] = useState([]);

    const [equps, setEqups] = useState([]);     // 누적 데이타
    const [equps2, setEqups2] = useState([]);   // 실시간 데이타
    const [inspEqups, setInspEqups] = useState([]) // 검사 데이터

    const [isHeader, setIsHeader] = useState(false);

    const [connDataTp, setConnDataTp] = useState('41101');
    // 수량	41101
    // 농도	41102
    // 길이	41103
    // 온도	41104
    // 여부	41105
    // 무게	41106

    const _cnt = useRef(0);
    const _divCnt = useRef(null);
    
    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        const timer = window.setInterval(() => {
            // console.log("### _cnt", _cnt);
            // console.log("### _divCnt", _divCnt);
            if(_cnt.current > 19) {
                if(_divCnt != null) {                    
                    _divCnt.current.innerHTML = `<span style="font-size: 14px; color: #fff;">조회 중 ...</span>`;
                }

                window.document.getElementById("srchButton").click();
                _cnt.current = 0;
            }
            else {
                if(_divCnt != null) {                    
                    _divCnt.current.innerHTML = `<span style="font-size: 14px; font-weight: bold; color: #ffff00">${20 -_cnt.current}</span><span style="font-size: 14px; color: #fff;"> 초 후 다시 조회</span>`;
                }
                _cnt.current++;    
            }            
            
        }, 1000);        
        return () => { // Return callback to run on unmount.
            window.clearInterval(timer);
        };

    },[]);

    // useEffect(() => {
    //     loadListData();
    // },[equpUid]);    

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            await handleClickSearch();
            setPageLoad(false);    

            // document.body.style = 'background: red;';
            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList = async() => {
        try {
            await loadListManu();
            await loadListInsp();
            // await loadListData();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadListManu = async() => {
       
        // if(monitorTp != '1') return;
        // console.log("### loadListManu ~~~");

        const para =  {
            p: {
                connYn: 'Y', 
                connDataTp: connDataTp
            }
        }
        
        const r = await cmn.api("getEqupConnHisListMonit", para);
        if(r == undefined || r == null) return;
        
        setEqups(r.d0);       
        setEqups2(r.d1);    

    }
    const loadListInsp = async() => {
       
        // if(monitorTp != '1') return;
        // console.log("### loadListManu ~~~");

        const para =  {
            p: {
                connYn: 'Y', 
                connDataTp: '41108'
            }
        }
        
        const r = await cmn.api("getEqupConnHisListMonit", para);
        if(r == undefined || r == null) return;
        
        setInspEqups(r.d0);       

    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickSearch = async(e) => {
        try {
            await loadList();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    } 

    const customizeTooltip = (arg) => {
        return { text: arg.valueText };
    }    

    /* ====================
     *  Render
     * ==================== */

    
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap"
                style={{width: "100%", height: "100%"}}
            >                
                <SrchButton 
                    id="srchButton"
                    marginRight={0}
                    onClick={handleClickSearch}
                    show={false}
                /> 
                <div 
                    style={{ 
                        marginLeft: 12, marginBottom: '6px', height: 40
                    }}
                >
                    <div className="fl-l">
                        <a
                            href="#"
                            className={`btn btn-sz-site-4 ${monitorTp === '1' ? 'btn-sz-site-4_on' : ''}`}
                            style={{
                                marginRight: "10px"
                            }}
                            
                            onClick={(e) => {
                                e.preventDefault();
                                // onClick(e);

                                if(monitorTp === '1') return;
                                setMonitorTp('1');
                            }}
                        >
                            생산실적
                        </a>
                        <a
                            href="#"
                            className={`btn btn-sz-site-4 ${monitorTp === '2' ? 'btn-sz-site-4_on' : ''}`}              
                            style={{
                                marginRight: "10px"
                            }}              
                            onClick={(e) => {
                                e.preventDefault();
                                if(monitorTp === '2') return;
                                setMonitorTp('2');
                            }}
                        >
                            검사실적
                        </a>
                    </div>
                    <div style={{ marginLeft: '20px' }} className="fl-l">
                        <div className="disp-now">
                            <h4>{util.getDateToString('m', null, '-')}</h4>
                        </div>
                    </div>
                    <div className="fl-r">
                        <ul>
                            <li>
                                <a
                                    href="#"
                                    className={`btn btn-sz-site-4`}                            
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const _isHeader = !isHeader;
                                        setIsHeader(_isHeader);
                                    }}
                                >
                                    {
                                        isHeader ? "▲" : "▼"
                                    }                                    
                                </a>
                            </li>                       
                        </ul>        
                    </div>
                    <div className="fl-r"
                        style={{
                            marginTop: 8,
                            marginRight: 10
                        }}

                    >
                        <ul>
                            <li>
                                <div ref={_divCnt}></div>
                            </li>
                        </ul>        
                    </div>
                    
                </div>
            
                {equps.length !== 0 ?
                    <div 
                        style={{ 
                            marginLeft: 12, display: "flex", flexWrap: "wrap"
                        }}
                    >
                        {/* <div style={{ marginLeft: '20px', marginBottom: '6px', width: '100%', lineHeight: "50px"}} >
                            <div className="disp-now">
                                <h4>{equps[0].connDataTpNm}</h4>
                            </div>
                        </div> */}
                    {
                        monitorTp === '1' ? renderEqup() : renderInsp()
                    }    
                    </div>
                    : ''
                } 
                {
                    equps2.length !== 0 ?
                
                    <div 
                        style={{ 
                            marginLeft: 12, display: "flex", flexWrap: "wrap"
                        }}
                    >
                        <div style={{ marginLeft: '20px', marginBottom: '6px', width: '100%', lineHeight: "50px"}} >
                            {/* 상단 각주 부분  {equps[0].connDataTpNm} */}
                            <div className="disp-now">
                                <h4>온도 / 습도 / 조도 / Co2</h4>
                                <h2>온도기준: 17~20℃ <span style={{color:'#228ffc'}}>■</span> / 20~24℃ <span style={{color:'#eedf0a'}}>■</span> / 24이상℃ <span style={{color:'#f01f1f'}}>■</span>
                                &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp; 습도기준: 50~90% <span style={{color:'#228ffc'}}>■</span> / 30~50% <span style={{color:'#eedf0a'}}>■</span> / 30%이하 <span style={{color:'#f01f1f'}}>■</span></h2>
                            </div>
                        </div>
                    {
                        renderEqup2()
                    }    
                    </div>
                    : ''
                }                    
            </div>    
            </>  
        )
    }

    // 검사 데이터
    const renderInsp = () => {
        return (
            <>
            <div style={{ marginLeft: '20px', marginBottom: '6px', width: '100%', lineHeight: "50px"}} >
                <div className="disp-now">
                    <h4>{inspEqups[0].connDataTpNm}</h4>
                </div>
            </div>
            {
                _.map(inspEqups, (v, k) => {  

                    return (
                        <div key={k} className="site-equp-conn-manu fl-l">
                            <div className="hdr">
                                <h1>{v.equpNm}</h1>
                                <div className={`stt ${v.nowStatus === 'Y' ? 'on' : 'off'}`}>
                                    <span>{v.nowStatus === 'Y' ? 'ON' : 'OFF'}</span>
                                </div>
                            </div>
                            <table>
                                <colgroup>
                                    <col width={78} />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td className="prod tit">품      번:</td>  
                                        <td className="tcon" colSpan={3}>{v.prodId}</td> 
                                    </tr>
                                    <tr>
                                        <td className="prod tit">품      명:</td>  
                                        <td className="tcon" colSpan={3}>{v.prodNm}</td> 
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <colgroup>                                    
                                    <col width={78} />
                                    <col />
                                    <col width={100}/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td className="amount tit">생산수량:</td> 
                                        <td className="tcon amt">
                                            {util.getNumComma(v.connData)} ({util.getNumComma(v.connData)} / {util.getNumComma(v.targetAmt)})
                                        </td> 

                                        <td className="sttbar" rowSpan={2}>
                                       
                                            <CircularProgressbar value={v.workRate} text={`${v.workRate}%`}
                                                styles={{
                                                    
                                                    path: {
                                                        // stroke: "#ff8000",
                                                        
                                                    },                                                    
                                                    text: {
                                                        // fill: "#ff8000",
                                                        // fontSize: "20px",
                                                        fontWeight: "600",
                                                    }
                                                }}

                                            />
           
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="amount tit td-end">목표수량:</td>  
                                        <td className="td-end tcon amt">
                                            {util.getNumComma(v.targetAmt, '')}
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )

                })
            }
            </>  
        )
    }

    // 누적 데이타
    const renderEqup = () => {
        return (
            <>
            <div style={{ marginLeft: '20px', marginBottom: '6px', width: '100%', lineHeight: "50px"}} >
                <div className="disp-now">
                    <h4>{equps[0].connDataTpNm}</h4>
                </div>
            </div>
            {
                _.map(equps, (v, k) => {  

                    return (
                        <div key={k} className="site-equp-conn-manu fl-l">
                            <div className="hdr">
                                <h1>{v.equpNm}</h1>
                                <div className={`stt ${v.nowStatus === 'Y' ? 'on' : 'off'}`}>
                                    <span>{v.nowStatus === 'Y' ? 'ON' : 'OFF'}</span>
                                </div>
                            </div>
                            <table>
                                <colgroup>
                                    <col width={78} />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td className="prod tit">품      번:</td>  
                                        <td className="tcon" colSpan={3}>{v.prodId}</td> 
                                    </tr>
                                    <tr>
                                        <td className="prod tit">품      명:</td>  
                                        <td className="tcon" colSpan={3}>{v.prodNm}</td> 
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <colgroup>                                    
                                    <col width={78} />
                                    <col />
                                    <col width={100}/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td className="amount tit">생산수량:</td> 
                                        <td className="tcon amt">
                                            {util.getNumComma(v.connData)} ({util.getNumComma(v.connData)} / {util.getNumComma(v.targetAmt)})
                                        </td> 

                                        <td className="sttbar" rowSpan={2}>
                                       
                                            <CircularProgressbar value={v.workRate} text={`${v.workRate}%`}
                                                styles={{
                                                    
                                                    path: {
                                                        // stroke: "#ff8000",
                                                        
                                                    },                                                    
                                                    text: {
                                                        // fill: "#ff8000",
                                                        // fontSize: "20px",
                                                        fontWeight: "600",
                                                    }
                                                }}

                                            />
           
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="amount tit td-end">목표수량:</td>  
                                        <td className="td-end tcon amt">
                                            {util.getNumComma(v.targetAmt, '')}
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )

                })
            }
            </>  
        )
    }

    // 실시간 데이타
    const renderEqup2 = () => {
        
        return (
            <>
            {
                _.map(equps2, (v, k) => {  
                    return (
                        <div key={k} className="site-equp-conn-manu fl-l">
                            <div className="hdr">
                                <h1>{v.equpNm}</h1>
                                <div className={`stt ${v.nowStatus === 'Y' ? 'on' : 'off'}`}>
                                    <span>{v.nowStatus === 'Y' ? 'ON' : 'OFF'}</span>
                                </div>
                            </div>
                            { connDataTp === '41103' ?  // 길이
                                <>
                                <table>
                                    <colgroup>
                                        <col width={78} />
                                        <col />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="prod tit">품      명:</td>  
                                            <td className="tcon" colSpan={3}>{v.prodNm}</td> 
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <colgroup>                                    
                                        <col width={78} />
                                        <col />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="amount tit">회전속도:</td> 
                                            <td className="tcon amt">
                                                <span style={{color: "#bebebe"}}>(A)  </span> 
                                                <span style={{fontWeight: "bold"}}>{util.getNumComma(v.connData, '0')}</span>
                                                <span style={{color: "#bebebe"}}>  -  (B)  </span> 
                                                <span style={{fontWeight: "bold"}}>{util.getNumComma(v.connData2, '0')}</span> 
                                                <span style={{color: "#bebebe"}}>  (r/m)</span>                                         
                                            </td> 
                                        </tr>
                                        <tr>
                                            <td className="amount tit td-end">현재길이:</td>  
                                            <td className="td-end tcon amt">
                                                <span style={{fontWeight: "bold"}}>{util.getNumComma(v.connData3, '0')}</span> 
                                                <span style={{color: "#bebebe"}}>  (m)</span>  
                                            </td> 
                                        </tr>
                                    </tbody>
                                </table>
                                </>
                                : connDataTp === '41104' ?  // 온도
                                <>
                                <table>
                                    <colgroup>
                                        <col width={78} />
                                        <col />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="prod tit">품      명:</td>  
                                            <td className="tcon" colSpan={3}>{v.prodNm}</td> 
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <colgroup>                                    
                                        <col width={78} />
                                        <col />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="amount tit">온도:</td> 
                                            <td className="tcon amt">
                                            <div className="dds">
                                                <span style={{fontWeight: "bold"}}>{v.connData}</span>
                                                <span style={{color: "#bebebe"}}>  (℃)</span>   
                                                    <div className={`stt nomal`}>
                                                        <span style={{verticalAlign: "middle"}}> 정상</span>
                                                    </div>
                                                </div>                      
                                            </td> 
                                        </tr>
                                        <tr>
                                            <td className="amount tit td-end">습도:</td>  
                                            <td className="td-end tcon amt">
                                            <div className="dds">
                                                <span style={{fontWeight: "bold"}}>{v.connData2}</span> 
                                                <span style={{color: "#bebebe"}}>  (%)</span>  
                                                <div className={`stt warnning`}>
                                                    <span style={{verticalAlign: "middle"}}> 경고</span>
                                                </div>
                                            </div>
                                            </td> 
                                        </tr>
                                        <tr>
                                            <td className="amount tit td-end">조도:</td>  
                                            <td className="td-end tcon amt">
                                                <span style={{fontWeight: "bold"}}>{v.connData3}</span> 
                                                <span style={{color: "#bebebe"}}>  (Lux)</span>  
                                            </td> 
                                        </tr>
                                        <tr>
                                            <td className="amount tit td-end">Co2:</td>  
                                            <td className="td-end tcon amt">
                                                <span style={{fontWeight: "bold"}}>{v.connData4}</span> 
                                                <span style={{color: "#bebebe"}}>  (PPM)</span>  
                                            </td> 
                                        </tr>
                                    </tbody>
                                </table>
                                </>
                                : ''
                            }
                        </div>
                    )

                })
            }
            </>  
        )
    }

    


    return (
        <>
        <SiteLayout
            title={"설비 작업 모니터링"}
            isHeader={isHeader}
            content = { renderContent() }
            style={{
                width: "100%",
                position: "fixed",
                paddingTop: "10px",
                background: "#323b45"
            }}
        />
        </>
    )
}

export default SiteEqupWorkMonitPage;
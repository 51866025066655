import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import useKey from 'use-key-hook';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, BizSelectBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  납품 관리
 */
const DeliPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [acctMon, setAcctMon] = useState();
    const [startDy, setStartDy] = useState(util.getAddDate(-30));
    const [endDy, setEndDy] = useState(util.getNow());
    const [prodNm, setProdNm] = useState('');
    const [bizUid, setBizUid] = useState('');
    const [salesNum, setSalesNum] = useState('');
    const [deliNum, setDeliNum] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "회계월",
                width: "200px",
                item: 
                <DayDateBox 
                    value={acctMon}
                    type={'month'}
                    width={'100px'}
                    showClearButton={true}
                    displayFormat={cmn.DATE_FORMAT_MON_1}
                    onValueChanged={(e) => {
                        setAcctMon(e.value);
                    }}                    
                />
            },
            {   title: "납품기간",
                width: "250px",
                item: 
                <>
                    <ul className="ul-row">
                        <li>
                            <DayDateBox 
                                value={startDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setStartDy(e.value);
                                }}                    
                            />
                        </li>
                        <li>
                            ~
                        </li>
                        <li>            
                            <DayDateBox 
                                value={endDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setEndDy(e.value);
                                }}                    
                            />
                        </li>
                    </ul>
                </>
            },
            {   title: "품명",
                item: 
                <TextBox 
                    value={prodNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />    
            }
        ],
        [
            {   
                title: "고객사",
                width: "200px",
                item: 
                <BizSelectBox
                    value={bizUid}
                    width={'200px'}
                    bizTp={[cmn.CMNCO_BIZ.CUST]}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        setBizUid(v);
                    }}
                />     
            },
            {   
                title: "수주번호",
                width: "200px",
                item: 
                <TextBox 
                    value={salesNum}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="수주번호로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setSalesNum(e.value);
                    }}
                />     
            },
            {   
                title: "납품번호",
                width: "200px",
                colSpan: 5,
                item: 
                <TextBox 
                    value={deliNum}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="납품번호로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setDeliNum(e.value);
                    }}
                />    
            },
        ]
    ];

    /* #endregion */    
    
    // * Grid
    const [deliList, setDeliList] = useState([]);
    const [deliListTot, setDeliListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, acctMon, startDy, endDy, bizUid]);  

    /* ====================
     *  Load
     * ==================== */
    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadList1 = async() => {
        const ps =  {
            p: {
                acctMon: acctMon !== '' ? util.getDateToString('M',acctMon) : '', 
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),
                prodNm: prodNm,
                salesNum: salesNum,
                deliNum: deliNum,
                bizUid: bizUid,

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getDeliList", ps );
        if(r === undefined || r == null) return;

        setDeliList(r.d0)
        setDeliListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
 
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickRepo = () => {
        
        if(util.chkListSelected(selectedItem)){
            const url = `/sales/delirepo?${qs.stringify({dnum: selectedItem.deliNum})}`;
            util.openWindow(url, 830, 500);  
        }
        
        //if(util.chkListSelected(selectedItem)){
        // if(util.getDateToString('d',startDy) != util.getDateToString('d',endDy))
        // {
        //     util.cmnAlert('납품기간을 당일로 설정하여 검색해 주십시오');
        //     return;
        // }
        // if(util.getChkNull(bizUid) == '')
        // {
        //     util.cmnAlert('고객사는 필수 정보입니다.');
        //     return;
        // }
          //  const url = `/sales/delirepo?${qs.stringify({sd: util.getDateToString('d',startDy), ed:util.getDateToString('d',endDy), buid:bizUid})}`;        
        //}
        // if(selectedItems.length <= 0)
        // {
        //     util.cmnAlert('행을 선택하여 주십시오.');
        //         return;
        // }
        // if(selectedItems.length > 6)
        // {
        //     util.cmnAlert('한번에 6개의 항목까지 출력 가능합니다.');
        //         return;
        // }
        // let bizNm_ = selectedItems[0].bizNm
        // let bool_ = true;
        // let uid_ = '';
        // _.forEach(selectedItems, (v,k) => {
        //     if(bizNm_ !== v.bizNm)
        //         bool_ = false;
            
        //     uid_ += `|${v.deliUid}`
        // });

        // if(!bool_)
        // {
        //     util.cmnAlert('같은 업체의 항목만 출력 가능합니다.');
        //         return;
        // }

       // console.log(uid_.substring(1));
        // const url = `/sales/delirepo?${qs.stringify({uid:uid_.substring(1)})}`;        
        // util.openWindow(url, 820, 840);  
        
    }
    
    const handleClickView = async() => {
        if(util.chkListSelected(selectedItem)){

            const url = `/sales/delipop?${qs.stringify({mode: 'v', dnum: selectedItem.deliNum})}`;
            util.openWindow(url, 800, 500);  

            // if(!util.confirmYn('납품 취소')) return;

            // const para =  {
            //     delis: [
            //         {
            //             deliUid: selectedItem.deliUid,
            //             comUid: authStore.com.uid,
            //             prodUid: selectedItem.prodUid,
            //             deliItems : [{
            //                 deliItemUid: selectedItem.deliItemUid,
            //                 deliAmt: selectedItem.deliAmt,
            //                 lot: selectedItem.lot
            //             }]
            //         }
            //     ]
            // }

            // console.log(para);
            // let r = await cmn.api('delDeli', para);
            // if(r == null || r === undefined) return;

            // util.cmnSucAlert('납품 취소');

            // loadList1();
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
        setSelectedItems(selectedRowsData);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "buyDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />  
                <ActionButton 
                    onClick={handleClickRepo}
                    title="거래명세서"
                    width={'130px'}
                    marginRight={0}
                    type={2}
                    show={authStore.page.viewYn}
                />  
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="납품 내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>       
            <Observer>{() =>
            <>        
            <DataGridView 
                reff={grid1}           
                dataSource={deliList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H11}

                page={curPage}
                total={deliListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // selection={'multiple'}
                // select
                onSelectionChanged={handleGridSelectionChanged}   
                // excel
                exlnm={'납품내역'}
                customizeExcelCell={customizeExcelCell}  

                colsWidth={[150, 150, 250, 250, 250, 250,  null]}
            >
                { cmn.getGridColumn('deliNum', '납품번호', renderGridHeaderCell)}
                { cmn.getGridColumn('salesNum', '수주번호', renderGridHeaderCell)}
                { cmn.getGridColumn('bizNm', '고객사', renderGridHeaderCell)}
                {/* { cmn.getGridColumn('lot', 'Lot', renderGridHeaderCell)} */}
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell)}
                {/* { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)} */}
                {/* { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell)} */}
                { cmn.getGridColumnMonthType('acctMon', '회계월', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('deliDy', '납품일', renderGridHeaderCell) }
                {/* { cmn.getGridColumnNumCommaType('deliAmt', '납품수량') } */}
                {/* { cmn.getGridColumnNumCommaType('deliPrice', '단가') }
                { cmn.getGridColumnNumCommaType('totPrice', '금액') } */}
                { cmn.getGridColumn('userNm', '작업자', renderGridHeaderCell)}
                {/* { cmn.getGridColumnCmnt()} */}
            </DataGridView>
            </>
            }</Observer>
            </>
        )

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default DeliPage;
import React, { useState, useEffect, useContext } from 'react';
import { TextBox,NumberBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    FileUploadBox,
    PopupLayout, 
    ActionButton,
    CodeSelectBox 
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  치공구 등록 / 수정
 */
const ToolPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */
    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageload, setPageLoad] = useState(true);

    /** Items */
    const [toolUid, setToolUid] = useState('');
    const [toolTp, setToolTp] = useState('');
    const [toolTpNm, setToolTpNm] = useState('');
    const [toolId, setToolId] = useState('');
    const [toolNm, setToolNm] = useState('');
    const [toolCd, setToolCd] = useState('');
    const [toolPrice, setToolPrice] = useState(0);
    const [toolImgUid, setToolImgUid] = useState(util.getUUID());
    const [toolImgInfo, setToolImgInfo] = useState('');
    const [toolImgFiles, setToolImgFiles] = useState([]);
    const [safeAmt, setSafeAmt] = useState(0);
    const [cmnt, setCmnt] = useState('');
    const [toolGrade, setToolGrade] = useState('');
    const [cornerCnt, setCornerCnt] = useState(0);
    const [delCmnt, setDelCmnt] = useState('');


    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            //권한 조회
            await authStore.loadPopPage(props);

            if(mode === 'u' || mode === 'v')
            {
                await loadInfo1();
            }

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    //치공구정보 상세 조회
    const loadInfo1 = async() => {
        try {            
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api('getTool', {p:{toolUid: uid}});
            if(r == null || r === undefined) return;

            console.log(r);
            const d0 = r.d0[0];
            
            setToolUid(d0.toolUid);
            setToolTp(d0.toolTp);
            setToolTpNm(d0.toolTpNm);
            setToolId(d0.toolId);
            setToolNm(d0.toolNm);
            setToolCd(d0.toolCd);
            setToolPrice(d0.toolPrice);
            setSafeAmt(d0.safeAmt);
            setToolGrade(d0.toolGrade);
            setCornerCnt(d0.cornerCnt);
            setCmnt(d0.cmnt);
            setDelCmnt(d0.delCmnt);
            setToolImgUid(d0.toolImgUid == null ? util.getUUID() : d0.toolImgUid );

            setToolImgFiles(r.f0);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(toolTp, '치공구유형'))
                return;
            if(!util.chkMustValue(toolId, '치공구품번'))
                return;
            if(!util.chkMustValue(toolCd, '치공구코드'))
                return;
            if(!util.chkMustValue(toolNm, '치공구명'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addTool();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upTool();
            }else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delTool();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // 치공구 등록
    const addTool = async() => {
        const ps = {
            p: {
                    toolUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    toolTp: toolTp,
                    toolId: toolId,
                    toolNm: toolNm,
                    toolCd: toolCd,
                    toolPrice: toolPrice,
                    toolImgUid: toolImgUid,
                    toolImgFiles: toolImgFiles,
                    safeAmt: safeAmt,
                    toolGrade: toolGrade,
                    cornerCnt: cornerCnt,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid
                }
        }

//        console.log(ps);
        const r = await cmn.api("addTool", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('등록'); 

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // 치공구 수정
    const upTool = async() => {
        const ps = {
            p: {
                toolUid: toolUid,
                toolTp: toolTp,
                toolId: toolId,
                toolNm: toolNm,
                toolCd: toolCd,
                toolPrice: toolPrice,
                toolImgUid: toolImgUid,
                toolImgFiles: toolImgFiles,
                safeAmt: safeAmt,
                toolGrade: toolGrade,
                cornerCnt: cornerCnt,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }
        
        const r = await cmn.api("upTool", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('수정'); 
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // 치공구 삭제
    const delTool = async() => {
        const ps = {
            p: {
                toolUid: uid,
                delCmnt: '',
                uptUserUid: authStore.user.uid
            }
        }
        
        const r = await cmn.api("delTool", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('삭제'); 
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">치공구유형</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={toolTp}
                                        classId={'108'}
                                        showClearButton={false}
                                        placeholder="치공구유형을 선택하세요"
                                        onValueChanged={(v) => { 
                                            setToolTp(v);
                                        }}
                                    />
                                    :
                                    <span>{toolTpNm}</span>
                                }
                            </td>
                            <td colSpan={2} rowSpan={6} className="tcon">
                            <div className="div-img-100" style={{minHeight: 220}}>
                                { 
                                    toolImgInfo !== '' ?                                                
                                    cmn.getRenderImg(toolImgInfo, 300) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">치공구품번</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={toolId}
                                        showClearButton={true}
                                        placeholder="치공구품번을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setToolId(e.value);
                                        }} 
                                    />
                                :
                                    <span>{toolNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">치공구코드</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={toolCd}
                                        showClearButton={true}
                                        placeholder="치공구코드를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setToolCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{toolCd}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">치공구명</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={toolNm}
                                        showClearButton={true}
                                        placeholder="치공구명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setToolNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{toolNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">등급</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={toolGrade}
                                        showClearButton={true}
                                        placeholder="등급을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setToolGrade(e.value);
                                        }} 
                                    />
                                :
                                    <span>{toolGrade}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">코너수</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        value={cornerCnt}
                                        format={'#,##0'}
                                        width={'80px'}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setCornerCnt(e.value);
                                        }} 
                                    />
                                : 
                                    <span>{cornerCnt}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">적정재고</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        value={safeAmt}
                                        format={'#,##0'}
                                        width={'80px'}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setSafeAmt(e.value);
                                        }} 
                                    />
                                :
                                    <span>{safeAmt}</span>
                                }
                            </td>
                            <td className="tcon" colSpan={2} rowSpan={2} style={{verticalAlign: "top"}}>
                                <FileUploadBox
                                    limit={1}
                                    // width={100}
                                    linkWidth={100}
                                    grpUid={toolImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={toolImgFiles}
                                    labelText=''
                                    onLoadInfo={(e) => {
                                        const fi = cmn.getUploadFileInfoStr(e);
                                        setToolImgInfo(fi);
                                    }}
                                    onUploaded={(e) => { 
                                        setToolImgFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setToolImgFiles(e);
                                    }}
                                /> 
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">단가</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        value={toolPrice}
                                        format={'#,##0'}
                                        width={'80px'}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setToolPrice(e.value);
                                        }} 
                                    />
                                :
                                    <span>{toolPrice}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요"
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "치공구 등록" 
                : mode === "u" ? "치공구 수정"
                : mode === "v" ? "치공구 보기"
                : "" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ToolPopPage;
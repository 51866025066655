const qualApiz = {

    /** ***************** 
     *  목록 조회
     *  ***************** */

    getProcInspHisList: {
        title: "공정검사내역 목록 조회",
        url: "/api/qual/getProcInspHisList",
        method: "POST"
    },
    getProcInspHisListIn: {
        title: "공점검사내역 목록 조회(수입검사)",
        url: "/api/qual/getProcInspHisListIn",
        method: "POST"
    },
    getProcInspHisListOutsIn: {
        title: "공점검사내역 목록 조회(외주품수입검사)",
        url: "/api/qual/getProcInspHisListOutsIn",
        method: "POST"
    },
    getProcInspBadHisListIn: {
        title: "공정검사 불량현황 조회(수입검사)",
        url: "/api/qual/getProcInspBadHisListIn",
        method: "POST"
    }, 
    getProcInspComboPP: {
        title: "제품공정코드로 공정검사 항목 조회",
        url: "/api/qual/getProcInspComboPP",
        method: "POST"
    }, 
    getProcManuBadList: {
        title: "공정생산 불량현황 조회",
        url: "/api/qual/getProcManuBadList",
        method: "POST"
    }, 
    getProcInspHisListXr: {
        title: "공정검사 X-R관리 조회",
        url: "/api/qual/getProcInspHisListXr",
        method: "POST"
    }, 
    getOutInspHisList: {
        title: "출하검사 목록 조회",
        url: "/api/qual/getOutInspHisList",
        method: "POST"
    },
    getBadImprList: {
        title: "부적합 개선 목록 조회",
        url: "/api/qual/getBadImprList",
        method: "POST"
    },

    /** ***************** 
     *  단일조회
     *  ***************** */   

    getProcInspHisIn: {
        title: "공정검사내역 상세 조회(수입검사)",
        url: "/api/qual/getProcInspHisIn",
        method: "POST"
    },
    getProcInspHis: {
        title: "공정검사내역 상세 조회",
        url: "/api/qual/getProcInspHis",
        method: "POST"
    },
    getProcInspHisMaxIdx: {
        title: "공정검사 MAX 순차 번호 조회",
        url: "/api/qual/getProcInspHisMaxIdx",
        method: "POST"
    },
    getProcInspHisInspItems: {
        title: "공정검사내역 검사항목 조회",
        url: "/api/qual/getProcInspHisInspItems",
        method: "POST"
    },
    getProcInspBadTreatHis: {
        title: "공정검사 불량처리 상세 조회(검사UID)",
        url: "/api/qual/getProcInspBadTreatHis",
        method: "POST"
    },
    getProcManuBad: {
        title: "공정생산 불량처리 단일조회",
        url: "/api/qual/getProcManuBad",
        method: "POST"
    },
    getProcInspHisXr: {
        title: "공정검사 X-R 단일조회",
        url: "/api/qual/getProcInspHisXr",
        method: "POST"
    },    
    getOutInspHisLot: {
        title: "출하검사 단일조회(Lot)",
        url: "/api/qual/getOutInspHisLot",
        method: "POST"
    },  
    getOutInspDtlHisItems: {
        title: "출하검사 상세조회",
        url: "/api/qual/getOutInspDtlHisItems",
        method: "POST"
    },  
    getBadImpr: {
        title: "부적합개선 상세조회",
        url: "/api/qual/getBadImpr",
        method: "POST"
    },  
    getShapeConnDataList: {
        title: "형상측정데이타 목록조회",
        url: "/api/qual/getShapeConnDataList",
        method: "POST"
    },  
    getEqupConnDataTmp: {
        title: "공정검사 연동 데이터 조회",
        url: "/api/qual/getEqupConnDataTmp",
        method: "POST"
    },
    
    /** ***************** 
     *  등록
     *  ***************** */

    addProcInspHis: {
        title: "공정검사내역 등록",
        url: "/api/qual/addProcInspHis",
        method: "POST"
    },
    addProcInspBadTreat: {
        title: "공정검사 불량처리 등록",
        url: "/api/qual/addProcInspBadTreat",
        method: "POST"
    },
    delProcInspBadTreat: {
        title: "공정검사 불량처리 삭제",
        url: "/api/qual/delProcInspBadTreat",
        method: "POST"
    },
    addProcManuBadTreat: {
        title: "공정생산 불량 등록",
        url: "/api/qual/addProcManuBadTreat",
        method: "POST"
    },
    addOutInspHis: {
        title: "출하검사 등록",
        url: "/api/qual/addOutInspHis",
        method: "POST"
    },
    addOutInspAtch: {
        title: "출하검사 첨부 등록",
        url: "/api/qual/addOutInspAtch",
        method: "POST"
    },
    addBadImpr: {
        title: "부적합개선 등록",
        url: "/api/qual/addBadImpr",
        method: "POST"
    },

    /** ***************** 
     *  수정
     *  ***************** */

    upOutInspHis: {
        title: "출하검사 수정",
        url: "/api/qual/upOutInspHis",
        method: "POST"
    },
    upBadImpr: {
        title: "부적합개선 수정",
        url: "/api/qual/upBadImpr",
        method: "POST"
    },

    /** ***************** 
     *  삭제
     *  ***************** */

    delOutInspHis: {
        title: "출하검사 삭제",
        url: "/api/qual/delOutInspHis",
        method: "POST"
    },
    delBadImpr: {
        title: "부적합개선 삭제",
        url: "/api/qual/delBadImpr",
        method: "POST"
    },



}

export default qualApiz;
import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    CodeSelectBox, PopupLayout, ActionButton 
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공정검사 불량처리 팝업
 */
const ProcManuBadPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid= ls.uid,    // prod_proc_uid
        lot = ls.lot,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    const [procManuBadTreatUid, setProcManuBadTreatUid] = useState(null);

    const [prodNm, setProdNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodStnd, setProdStnd] = useState('');

    const [unitTpNm, setUnitTpNm] = useState('');

    // const [bizNm, setBizNm] = useState('');
    const [procPrice, setProcPrice] = useState(null);
    const [manuDy, setManuDy] = useState('');    

    const [manuAmt, setManuAmt] = useState(null);
    const [goodAmt, setGoodAmt] = useState(null);
    const [badAmt, setBadAmt] = useState(null);
    const [badCont, setBadCont] = useState('');
    // const [badAmts, setBadAmts] = useState([]);

    const [preBadTreatTp, setPreBadTreatTp] = useState('');
    const [badTreatTp, setBadTreatTp] = useState('');
    const [badTreatTpNm, setBadTreatTpNm] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
 
    const loadInfo1 = async() => {
        try {   
            if(util.getChkNull(lot) === '') return;

            const r =  await cmn.api("getProcManuBad", {p: {lot: lot, prodProcUid: uid}});
            if(r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;

            // ** 기본정보 표시         
            setUnitTpNm(d0.unitTpNm);

            setManuDy(d0.manuDy);
            setManuAmt(d0.manuAmt);
            setGoodAmt(d0.goodAmt);
            setBadAmt(d0.badAmt);
            
            setProdId(d0.prodId);
            setProdNm(d0.prodNm);
            setProdStnd(d0.prodStnd);
            setProcPrice(d0.procPrice);

            setProcManuBadTreatUid(d0.procManuBadTreatUid);
            
            setPreBadTreatTp(d0.preBadTreatTp);
            setBadTreatTp(d0.badTreatTp);
            setBadTreatTpNm(d0.badTreatTpNm);    

            let badCont_ = '';
            _.forEach(d1, (v, k) => {
                if(k > 0) badCont_ += ", ";
                badCont_ += `${v.badTpNm}: ${v.badAmt} (${d0.unitTpNm})`; 
            })
            // badCont_ = `(${badCont_})`;
            
            setBadCont(badCont_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        } 
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(badTreatTp, '불량처리 항목')) return;
            
            if(!util.confirmYn('불량처리완료')) return;        
            addBadTreat();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addBadTreat = async() => {
        const ps = {
            p: 
                {
                    procManuBadTreatUid: procManuBadTreatUid,
                    comUid: authStore.com.uid,
                    lot: lot,
                    prodProcUid: uid,
                    badAmt: badAmt,
                    preBadTreatTp: preBadTreatTp,
                    badTreatTp: badTreatTp,
                    uptUserUid: authStore.user.uid
                }
        }

        const r = await cmn.api("addProcManuBadTreat", ps);
        if(r === undefined) return;

        util.cmnSucAlert("불량처리완료");
        util.clickOpenerElement("srchButton");
        window.close();;
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "불량처리완료" 
            : mode === "u" ? "불량처리완료"
            : "" ;

            return(
                <>
                <div className="btn_r mgb10">
                    <ActionButton 
                        width={140}
                        onClick={handleClickComplete}
                        title={completeButtonTitle}
                        marginRight={0}
                        show={true}
                    />
                </div>
                <div className="board_list_type">
                    <table>
                        <colgroup>
                            <col className="tit" />
                            <col className="tcon" width={"40%"} />
                            <col className="tit" />
                            <col className="tcon " />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="tit">품명</th>
                                <td className="tcon">
                                    <span>{prodNm}</span>
                                </td>
                                <th className="tit">규격</th>
                                <td className="tcon">
                                    <span>{prodStnd}</span>
                                </td>                           
                            </tr>   
                            <tr>
                                <th className="tit">품번</th>
                                <td className="tcon">
                                    <span>{prodId}</span>
                                </td>
                                <th className="tit">Lot</th>
                                <td className="tcon">
                                    <span>{lot}</span>
                                </td>                           
                            </tr>                  
                        </tbody>
                    </table>
                </div>
                <div className="board_list_type">
                    <table>
                        <colgroup>
                            <col className="tit" />
                            <col className="tcon" width={"40%"} />
                            <col className="tit" />
                            <col className="tcon " />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="tit">작업일자</th>
                                <td className="tcon">
                                    <span>{util.getDateToString('d',manuDy, '-')}</span>
                                </td>
                                <th className="tit">공정비용</th>
                                <td className="tcon">
                                    <span>{util.getNumComma(procPrice)}</span>
                                </td>                           
                            </tr>
                            <tr>
                                <th className="tit">생산량</th>
                                <td className="tcon">
                                    <span>{`${util.getNumComma(manuAmt)} (${unitTpNm})`}</span>
                                </td>
                                <th className="tit">양품수량</th>
                                <td className="tcon">
                                    <span>{`${util.getNumComma(goodAmt)} (${unitTpNm})`}</span>
                                </td>                           
                            </tr>                     
                            <tr>
                                <th className="tit">불량수량</th>
                                <td className="tcon">
                                    <span>{`${util.getNumComma(badAmt)} (${unitTpNm})`}</span>
                                </td>
                                <th className="tit">손실비용</th>
                                <td className="tcon">
                                    <span>{`${util.getNumComma(procPrice*badAmt)} (원)`}</span>
                                </td>                           
                            </tr> 
                            <tr>
                                <th className="tit">불량내용</th>
                                <td className="tcon" colSpan={3}>
                                    <span>{badCont}</span>
                                </td>                           
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <div className="board_list_type">
                    <table>
                        <colgroup>
                            <col className="tit" />
                            <col className="tcon " />
                        </colgroup>
                        <tbody>
                             
                            <tr>
                                <th className="tit req">불량처리</th>
                                <td className="tcon">
                                {
                                    mode === "a" || mode === "u" ?
                                        <CodeSelectBox
                                            value={badTreatTp}
                                            width={200}
                                            classId={'406'}
                                            placeholder="불량처리유형을 선택하세요."
                                            onValueChanged={(v, o) => { 
                                                setBadTreatTp(v);
                                            }}
                                        /> 
                                    :
                                    <span>{badTreatTpNm}</span>
                                }
                                </td>
                            </tr>  
                                              
                        </tbody>
                    </table>
                </div> 
                </>
            )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "공정생산 불량처리" 
                : mode === "u" ? "공정생산 불량처리" 
                : "공정생산 불량처리" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProcManuBadPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { FileUploader } from 'devextreme-react';

import _ from 'lodash';

/** components */
import { IconButton } from '../';

/** stores & lib */
import { apiz, cmn, util } from '../../lib';

/** images */
import ico_user from '../../images/ico/ico_user.png';

/** 
 *  File Upload 
 */
const FileUploadBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        width="100%",
        linkWidth="100%",
        limit=1000,

        grpUid,
        userUid,

        multiple=true,
        labelText="또는 여기에 파일을 끌어서 놓으세요.",

        uploadFiles,    // 업로드 파일들
        uploadFilesBef, // 업로드 파일들 ... 이전값 .. 변경여부 확인용

        accept='*',

        onLoadInfo,

        onUploaded,
        onValueChanged,
        // onUploadError,
        onUploadAborted,
        onReomveFile,

        onIsUpdated,    // 업데이트 되었는지 값(true/false)을 전달한다.

        com = cmn.getCom()
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const fu = useRef(null);
    const fi = useRef(null);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadUpdate();
    },[uploadFiles, uploadFilesBef]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const loadUpdate = () => {
        onIsUpdated && onIsUpdated(isUpdated());   // 업데이트 여부 전달
        onLoadInfo && onLoadInfo(uploadFiles);
    }

    const isUpdated = () => {
        if(!uploadFilesBef || !uploadFiles) return false;
        if(uploadFilesBef.length != uploadFiles.length) return true;

        let r = false;
        _.forEach(uploadFilesBef, (v, k) => {
            if(v.fileUid != uploadFiles[k].fileUid) {
                r = true;
                return false;
            } 
        });

        return r;
    }
    
    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {


        return (
            <div className="fileupbox"
                style={{
                    width: width
                }}
            >

                <FileUploader 
                    
                    ref={fu}

                    multiple={limit == 1 ? false : multiple}
                    selectButtonText={'파일선택'}
                    labelText={labelText}
                    showFileList={true}
                    accept={accept}
                    uploadMode={'instantly'}
                    uploadUrl={`${com.url}${apiz.uploadFile.url}`}
                    //uploadUrl={`${cmn.env.SERVER_URL.url}${apiz.uploadFile.url}`}
                    onValueChanged={onValueChanged}
                    onUploaded={(e) =>{

                        cmn.successToast("파일이 업로드 되었습니다.");

                        const res = JSON.parse(e.request.response);
                        let files_ = [];
                        _.forEach(res.data.files, (v, k) => {
                            files_.push({
                                fileUid: util.getUUID(),
                                grpUid: grpUid,
                                fileNm: v.originalname,
                                saveFileNm: v.filename,
                                path: v.path,
                                fileSize: v.size,
                                uptUserUid: userUid,
                                uploaded: true
                            })
                        })

                        const uploadFiles_ = limit == 1 ? files_ : _.concat(uploadFiles == null ? [] : [...uploadFiles], files_);

                        onUploaded(uploadFiles_);
                        // onLoadInfo && onLoadInfo(uploadFiles_);

                        e.component.reset();                        
                    }}

                    onUploadError={(e) => {
                        cmn.failedToast("파일이 업로드가 실패하였습니다.");
                    }}

                    onUploadAborted={onUploadAborted}
                /> 
                <ul className="list-area">
                {
                    _.map(uploadFiles, (v, k) => {                        

                        return (
                            <li key={k} className="list-item">
                                <ul className="ul-row">
                                    <li className="file-name">
                                        <a
                                            href="#"
                                            className={"file-link"}
                                            style={{width: linkWidth}}
                                            title={v.fileNm}                                           
                                            onClick={(e) => {
                                                e.preventDefault();

                                                const tmp = v.uploaded ? '1' : '';
                                                const url = cmn.getDownloadFileUrl(
                                                    tmp == '1' ? v.saveFileNm : v.fileUid, 
                                                    undefined, 
                                                    tmp, 
                                                    v.fileNm);

                                                window.open(url, '_blank');
                                            }}
                                        >
                                            { v.fileNm }
                                        </a>
                                    </li>
                                    <li className="file-action">
                                        <IconButton
                                            className="btn-icon-close-1"
                                            onClick={(e) => {                                                
                                                const uploadFiles_ = _.remove(uploadFiles, (vv) => {
                                                    return v.saveFileNm != vv.saveFileNm;
                                                });  

                                                onReomveFile(uploadFiles_); 
                                                // onLoadInfo && onLoadInfo(uploadFiles_);
                                            }}
                                            marginRight={0}
                                        />
                                    </li>
                                </ul>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default FileUploadBox;

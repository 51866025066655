import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';

/** components */
import {
    EqupChkBox, UserSelectBox, CodeSelectBox,
    PopupLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  설비 일상점검 CRUD 팝업
 */
const EqupDayChkPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)

        uid = ls.uid,   // equp uid
        chktp = ls.chktp, // check tp
        tmtp = ls.tmtp, // time tp ... 주간/야간
        chkdy = ls.chkdy
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [equpUid, setEqupUid] = useState(uid);    
    const [equpNm, setEqupNm] = useState('');    

    const [equpChkUid, setEqupChkUid] = useState('');

    const [chkCycleTp, setChkCycleTp] = useState('');
    const [chkCycleTpNm, setChkCycleTpNm] = useState('');

    const [timeTp, setTimeTp] = useState('');
    const [timeTpNm, setTimeTpNm] = useState('');

    const [chkCycleObj, setChkCycleObj] = useState(null);

    const [wk, setWk] = useState(0);
    const [dd, setDd] = useState(0);
    const [startDy, setStartDy] = useState('');
    
    const [chkCycleDy, setChkCycleDy] = useState(chkdy);
    const [chkDy, setChkDy] = useState('');
    const [chkDt, setChkDt] = useState('');

    const [chkUserUid, setChkUserUid] = useState(authStore.user.uid);
    const [chkUserNm, setChkUserNm] = useState('');

    const [chkItems, setChkItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            await loadTimeTp();   // 주간/야간 
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadTimeTp = async() => {
        if(chktp !== cmn.CMNCO_CHKCYCLE.DAY) return;  // 일상점검이 아니면 주간/야간 구분 없음.

        if(util.getChkNull(tmtp) === '' ) {
            const timeTp_ = cmn.getCurTimeTp();
            setTimeTp(timeTp_);
        }
        else {
            setTimeTp(tmtp);
        }
    }

    const loadInfo1 = async() => {
        try {  
            const r = await cmn.api("getEqupChkEqup"
                        , {p: {equpUid: uid, chkCycleTp: chktp, chkCycleDy: chkdy
                                , timeTp: tmtp === undefined ? '' : tmtp}});
                                
            if(r == null) return;

            const d = r.d0[0];
            
            setEqupNm(d.equpNm);
            setEqupChkUid(d.equpChkUid);

            setChkCycleTp(d.chkCycleTp);
            setChkCycleTpNm(d.chkCycleTpNm);

            if(tmtp === '') {
                setTimeTp(d.timeTp);
                setTimeTpNm(d.timeTpNm);
            }

            setWk(d.wk);
            setDd(d.dd);
            setStartDy(d.startDy);
            setChkCycleObj({chkCycleTp: d.chkCycleTp, wk: d.wk, dd: d.dd, startDy: d.startDy});

            if(util.getChkNull(d.equpChkUid) !== '') setChkCycleDy(d.chkCycleDy);

            setChkDy(d.chkDy);
            setChkDt(d.chkDt);

            if(util.getChkNull(d.chkUserUid) !== '') {
                setChkUserUid(d.chkUserUid);
                setChkUserNm(d.chkUserNm);
            }            

            setChkItems(r.d1);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        if(mode === "a") addEqupChk();
        // else if(mode == "u") up();
    }

    const addEqupChk = async() => {

        const ps = {
            p: {
                equpChkUid: equpChkUid,
                equpUid: equpUid,
                chkCycleTp: chkCycleTp,
                timeTp: timeTp,
                wk: wk,
                dd: dd,
                startDy: startDy,
                chkCycleDy: chkCycleDy,
                chkDy: util.getDateToString('d',chkDy),
                chkDt: chkDt,
                chkUserUid: chkUserUid,
                dtls: chkItems
            }
        }

        // console.log("===== para", para);
        const r = await cmn.api("addEqupChk", ps);
        if(r === undefined) return;

        // alert("success");
        cmn.successToast(cmn.MSG_SUCCESS_ADD);

        // // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "점검완료" 
            : mode === "u" ? "점검완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon"/>
						<col className="tit" />
						<col className="tcon"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">설비</th>
                            <td className="tcon">
                                { equpNm }
                            </td>
                            <th className="tit">점검주기</th>
                            <td className="tcon">
                                { cmn.getDispChkCycleInfo(chkCycleObj) }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">점검일</th>
                            <td className="tcon">
                                <ul className="ul-row">
                                    <li>
                                        { util.getDateStrSplit('d',chkCycleDy, '-') }
                                    </li>
                                    <li>
                                    {
                                        // 주간/야간 선택콤보
                                        chktp === cmn.CMNCO_CHKCYCLE.DAY &&
                                        <CodeSelectBox
                                            width={80}
                                            value={timeTp}
                                            classId={'801'}
                                            showClearButton={false}
                                            onValueChanged={(v) => { 
                                                setTimeTp(v);
                                            }}
                                        />
                                    }
                                    </li>
                               </ul>
                            </td>
                            <th className="tit">점검자</th>
                            <td className="tcon">
                                <UserSelectBox
                                    value={chkUserUid}
                                    showClearButton={false}
                                    onValueChanged={(v) => { 
                                        setChkUserUid(v);
                                    }}
                                />
                            </td>
                        </tr>                                            
                    </tbody>
                </table>
            </div>
            <EqupChkBox
                mode={mode}
                chkItems={chkItems}
                onValueChanged={(v)=>{
                    // console.log("--- v", v);
                    setChkItems(v);
                    // chkItems = v;
                }} 
            />           

            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? `설비 ${chktp === cmn.CMNCO_CHKCYCLE.DAY ? "일상" : "정기"}점검` 
                : mode === "u" ? `설비 ${chktp === cmn.CMNCO_CHKCYCLE.DAY ? "일상" : "정기"}점검` 
                : `설비 ${chktp === cmn.CMNCO_CHKCYCLE.DAY ? "일상" : "정기"}점검` 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default EqupDayChkPopPage;
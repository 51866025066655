import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    SubActionButton,
    DataGridView,
    UserSelectBox ,
    CancelButton,
    BizSelectBox
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  발주 등록 / 수정
 */
const ToolInPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);    

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
        guid = ls.guid // groupUid 
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);

    const [toolInItems, setToolInItems] = useState([]);
    const [toolItems, setToolItems] = useState([]);
    
    /** Items */
    
    const [toolUid, setToolUid] = useState('');
    const [toolId, setToolId] = useState('');
    const [toolCd, setToolCd] = useState('');
    const [toolNm, setToolNm] = useState('');
    const [toolGrade, setToolGrade] = useState('');
    const [cornerCnt, setCornerCnt] = useState('');
    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    
    const [inAmt, setInAmt] = useState(0);
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState(authStore.user.name);
    const [cmnt, setCmnt] = useState('');
    const [inDy, setInDy] = useState(util.getNow());
    const [inPrice, setInPrice] = useState(0);
    const [groupUid, setGroupUid] = useState('');
    const [isDel, setIsDel] = useState('');
    const [price, setPrice] = useState(0);
    

    useEffect(() => {
        loadPage();
    },[]);

    // useEffect(() => {
    //     loadProdInfo();
    // },[prodUid]);
    
    // useEffect(() => {
    //     !pageLoad && loadProdProcDtlCombo();
    // },[prodProcUid]);

    // useEffect(() => {
    //     !pageLoad && loadProdProcInfo();
    // },[prodProcUid]);

    useEffect(() => {
        !pageLoad && loadPrice();
    },[inAmt, inPrice]);
    
    // useEffect(() => {
    //     !pageLoad && loadUnitPrice();
    // },[bizUid]);

    // useEffect(() => {
    //     !pageLoad && loadPreToolInfo('proc');
    // },[preProdProcUid]);
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();

            const r = await cmn.api("getToolCombo", {} );
            if(r === undefined || r == null) return;

            setToolItems(r.d0);

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadInfo1 = async() => {
        if(util.getChkNull(mode) === 'a') return;
        if(util.getChkNull(uid) === '') return;

        try {

            let r = await cmn.api('getToolIn', {p:{toolInUid: uid}});
            if(r == null || r === undefined) return;

            let d0 = r.d0[0];
            
            setBizNm(d0.bizNm);
            setInAmt(d0.inAmt);
            setUserNm(d0.userNm);
            setCmnt(d0.cmnt);
            setInDy(d0.inDy);
            setInPrice(d0.inPrice);
            setPrice(d0.price);
            setToolUid(d0.toolUid);
            setToolId(d0.toolId);
            setToolNm(d0.toolNm);
            setToolCd(d0.toolCd);
            setToolGrade(d0.toolGrade);
            setCornerCnt(d0.cornerCnt);
            setIsDel(d0.isDel);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
  
    const pageInit = () => {
        setSelectedItem(null);
        setBizUid('');
        setInAmt(0);
        setUserUid(authStore.user.uid);
        setCmnt('');
        setInDy(util.getNow());
        setInPrice(0);
        setGroupUid('');
        setToolUid('');
        setToolId('');
        setToolNm('');
        setToolCd('');
        setToolGrade('');
        setCornerCnt('');
    }

    // const loadPrice = () => {
    //     if(util.getChkNull(toolAmt, 0) === 0
    //     || util.getChkNull(toolPrice, 0) === 0) return;

    //   //  setPrice(toolAmt * inPrice);  
    // }

    // const loadToolInfo = async() => {
    //     if(util.getChkNull(toolUid, '') === '') return;

    //     let r = await cmn.api('getProd', {p:{prodUid:prodUid}});
    //     if(r == null || r === undefined) return;
        
    //     const data = r.d0[0];
        
    //     setProdId(data.prodId);
    //     setProdNm(data.prodNm);

    //     setProdImgUid(data.prodImgUid);
    //     setProdImgFile(data.prodImgFile);

    //     setProdProcUid(data.firstProdProcUid);
    //     setProcNm(data.firstProcNm);
    //     setLastYn(data.lastYn);
    // }

    // const loadUnitPrice = () => {
    //     let prodProcDtlItems_ = [...prodProcDtlItems];
    //  //   console.log('prodProcDtlItems_', prodProcDtlItems_);
    //     let o = _.find(prodProcDtlItems_, {bizUid: bizUid});
    //     setUnitPrice(util.getVal(o , 'procPrice'));
    //     setBizNm(util.getVal(o , 'bizNm'));
    // }
    
    /* ====================
     *  Handle / Functions
     * ==================== */

     
    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    
    const handleClickCrtRows = () => {
        //validation
        // if(!util.chkMustValue(prodUid, '제품'))
        //     return;
        // if(!util.chkMustValue(prodProcUid, '공정'))
        //     return;
        // if(!util.chkMustValue(bizUid, '협력사'))
        //     return;
        if(!util.chkMustValue(inAmt, '입고량', 'cnt'))
            return;
            
        const toolInItems_ = [...toolInItems];

        toolInItems_.push(
        {
            toolInUid: util.getUUID(),
            bizUid: bizUid,
            bizNm: bizNm,
            toolUid: toolUid,
            toolNm: toolNm,
            inAmt: inAmt,
            userUid: userUid,
            cmnt: cmnt,
            inDy: inDy,
            inPrice: inPrice,
            price: price,
            userNm: userNm,
        });

       // console.log(toolInItems_);
        setToolInItems(toolInItems_);

        pageInit();
    }

    const handleClickDelRows = () => {
        
        if(util.chkListSelected(selectedItem)){
            if(util.confirmYn('삭제'))
            {
                const toolInItems_ = _.remove(toolInItems, (v) => {
                    return selectedItem.toolUid !== v.toolUid;
                });
        
                setToolInItems(toolInItems_);
            }
        }

    }

    const handleClickComplete = (e) => {
        try {

            if(mode === 'a')
            {
                if(toolInItems.length <= 0)
                {
                    util.cmnAlert('입고 정보가 없습니다.');
                    return;
                }

                if(util.confirmYn("등록"))
                    addToolIn();
            }else if(mode === 'v')
            {
                delToolIn(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addToolIn = async() => {

        const tools_ = []
        let groupUid_ = util.getUUID();

        for(let i =0; i < toolInItems.length; i ++)
        {
            let v = toolInItems[i];

            tools_.push({
                toolInUid: v.toolInUid,
                comUid: authStore.com.uid,
                bizUid: v.bizUid,
                toolUid: v.toolUid,
                inAmt: v.inAmt,
                userUid: v.userUid,
                cmnt: v.cmnt,
                inDy: util.getDateToString('d', v.inDy),
                inPrice: v.inPrice,
                groupUid: groupUid_,
                uptUserUid: authStore.user.uid,
            });

        }

        const ps = {
            p : [...tools_]
        }

        //console.log(ps);
        const r = await cmn.api('addToolIn', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

         // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    
    const delToolIn = async(delCmnt) => {
        if(util.getChkNull(uid) === '') return;

        try 
        {
            const ps =  {
                p: {
                    toolInUid: uid,
                    comUid: authStore.com.uid,
                    groupUid: '',
                    delCmnt : delCmnt,
                    toolUid: toolUid,
                    inAmt: inAmt,
                    uptUserUid : authStore.user.uid
                }
            }

            console.log(ps);
            let r = await cmn.api('delToolIn', ps);
            if(r == null || r === undefined) return;

            util.cmnSucAlert('취소');

            // Reload Opener List & Close Popup
            util.clickOpenerElement("srchButton");
            window.close();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadPrice = () => {
        if(util.getChkNull(inAmt, 0) === 0
        || util.getChkNull(inPrice, 0) === 0) 
        {
            setPrice(0);
            return;
        }

        setPrice(inAmt * inPrice);  
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

    //    console.log(toolDy);
        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "v" ? "취소완료" 
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
            {mode === "v" ?
                <CancelButton
                    title={completeButtonTitle}
                    isDel={isDel}
                    isDelMsg={'재고가 없으므로 취소할 수 없습니다.'}
                    handleClickComplete={(e) => {
                        handleClickComplete(e)
                    }} 
                />
                :
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">입고일</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                    <DayDateBox 
                                        value={inDy}
                                        onValueChanged={(e) => {
                                            setInDy(e.value);
                                        }}                    
                                    />
                                :
                                    <span>{inDy}</span>
                                }
                            </td>
                            <th className="tit">입고자</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        showClearButton={false}
                                        placeholder="입고자를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                :
                                <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">치공구선택</th>
                            <td className="tcon" >
                            { 
                                mode === "a" ?
                                <SelectBox 
                                    placeholder={'치공구를 선택하세요'}
                                    value={toolUid}
                                    dataSource={toolItems}
                                    displayExpr={'toolNm'}
                                    valueExpr={'toolUid'}
                                    
                                    showClearButton={false}
                                    
                                    onValueChanged={(e)=>{
                                        setToolUid(e.value);

                                        let o = _.find(toolItems, {toolUid: e.value});
                                        setToolNm(o.toolNm);
                                        setToolId(o.toolId);
                                        setToolCd(o.toolCd);
                                        setToolGrade(o.toolGrade);
                                        setCornerCnt(o.cornerCnt);
                                        setInPrice(o.toolPrice);
                                    }} 
                                />
                                :
                                <span>{toolNm}</span>
                            }
                                
                            </td>
                            <th className="tit">구매업체</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <BizSelectBox
                                        value={bizUid}
                                        showClearButton={true}
                                        onLoadInfo={(o) => {
                                            setBizNm(util.getVal(o, 'bizNm'));
                                        }} 
                                        onValueChanged={(v) => {
                                            setBizUid(v);
                                        }}
                                    />  
                                :
                                    <span>{bizNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">치공구품번</th>
                            <td className="tcon">
                                <span>{toolId}</span>
                            </td>
                            <th className="tit">치공구코드</th>
                            <td className="tcon">
                                <span>{toolCd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">등급</th>
                            <td className="tcon">
                                <span>{toolGrade}</span>
                            </td>
                            <th className="tit">코너수</th>
                            <td className="tcon">
                                <span>{cornerCnt}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">입고량</th>
                            <td className="tcon" colSpan={3} >
                                <div className="inline-block">
                                    { 
                                    mode === "a" ?
                                        <NumberBox
                                            width={80}
                                            format={'#,##0'}
                                            value={inAmt}
                                            defaultValue={0}
                                            min={0}
                                            // max={20}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setInAmt(e.value);
                                            }}
                                        />
                                        :
                                        <span>{inAmt}</span>
                                    }
                                </div>
                                <div className="inline-block mar-left-10">
                                     X (단가:  <strong>{util.getNumComma(inPrice)}</strong> 원)   
                                     = (금액:  <strong>{util.getNumComma(price)}</strong> 원) 
                                </div>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                { 
                                mode === "a" ?
                                    <TextBox 
                                        value={cmnt}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setCmnt(e.value);
                                        }}
                                    /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>

            { 
            mode === "a" ?
                <div className="pop-grid">
                    <div className="clearfix">
                        <div className="btn-area fl-r">
                            <SubActionButton 
                                onClick={handleClickCrtRows}
                                title="추가"
                                show={true}
                            /> 
                            <SubActionButton 
                                onClick={handleClickDelRows}
                                title="삭제"
                                marginRight={0}
                                show={true}
                            /> 
                        </div>
                    </div>
                    <div className="clearfix" style={{marginTop:'10px'}}>
                        <DataGridView
                            onSelectionChanged={handleGridSelectionChanged}     
                            showNo={false}
                            dataSource={toolInItems}
                            gridHeight={180}
                            colsWidth={[80, 120, 100, 80, 80, 80, 80, null]}  
                        >
                            { cmn.getGridColumn('inDy', '입고일') }
                            { cmn.getGridColumn('toolNm', '치공구명')}
                            { cmn.getGridColumn('bizNm', '협력사')}
                            { cmn.getGridColumn('userNm', '입고자')}
                            { cmn.getGridColumnNumCommaType('inAmt', '입고량') }
                            { cmn.getGridColumnNumCommaType('inPrice', '단가') }
                            { cmn.getGridColumnNumCommaType('price', '금액') }
                            { cmn.getGridColumnCmnt() }
                            
                        </DataGridView>
                    </div>
                </div>  
            :
                ''
            }        
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "치공구입고 등록" 
                : mode === "v" ? "입고 상세보기"
                : "입고" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ToolInPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, DayDateBox, CodeSelectBox, SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산실적 관리
 */
const ManuPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * Control Items (* Combo ...)
    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate('M', -1));
    const [endDy, setEndDy] = useState(util.getNow());
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');

    /* #region Search Items */
    
    const searchItems = [
        [
            {   title: "작업일",
                width: "200px",
                item: 
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
            },
            {   title: "품명",
                width: "200px",
                item: 
                    <TextBox 
                        value={prodNm}
                        showClearButton={true}
                        valueChangeEvent={'keyup'}
                        onValueChanged={(e)=>{
                            setProdNm(e.value);
                        }}
                    /> 
            },
            {   title: "품번",
                item: 
                    <TextBox 
                        value={prodId}
                        width={'200px'}
                        showClearButton={true}
                        valueChangeEvent={'keyup'}
                        onValueChanged={(e)=>{
                            setProdId(e.value);
                        }}
                    /> 
            }
        ]
    ];

    /* #endregion */
    
     // * Grid
     const [manuList, setManuList] = useState([]);
     const [manuListTot, setManuListTot] = useState(0);
     const [selectedItem, setSelectedItem] = useState(null);
     // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem,  startDy, endDy]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy), 
                endDy: util.getDateToString('d',endDy), 
                prodNm: prodNm, 
                prodId: prodId, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }
        const r = await cmn.api("getManuList", ps );
        if(r === undefined || r == null) return;

        setManuList(r.d0)
        setManuListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
 
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(selectedItem == null)
            openCrudPopup("a", "0", '');
        else
            openCrudPopup("a", "0", selectedItem.workNum);
    }

    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            // if(selectedItem.manuDeleteYn === 'N')
            // {
            //     util.cmnAlert('공정이 진행중이므로 삭제할 수 없습니다. \n진행된 공정을 삭제한 후 삭제 가능합니다.');
            //     return;
            // }
            openCrudPopup("v", selectedItem.manuUid);
        }
    }

    const handleClickProcFlowTag = (e) => {
        if(util.chkListSelected(selectedItem)){
            const url = `/cmn/procflowtagpop?${qs.stringify({uid: selectedItem.prodUid, lot: selectedItem.lot})}`;        
            util.openWindow(url, 820, 840); 
        }
    }    

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "manuDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    

    // * Functions
    const openCrudPopup = (mode, manuUid, workNum) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/manu/manuretpop?${qs.stringify({mode: mode, uid: manuUid, wn:workNum})}`;        
        util.openWindow(url, 950, 600 );     
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="실적등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />
                <ActionButton 
                    width={'120px'}
                    type={2}
                    onClick={handleClickProcFlowTag}
                    title="공정이동표"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView>     
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="생산실적 내역"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>    
            <DataGridView 
                reff={grid1}        
                dataSource={manuList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={manuListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}         
                // excel
                exlnm={'생산실적 내역'}
                customizeExcelCell={customizeExcelCell} 
                colsWidth={[cmn.CW.dy, cmn.CW.workNum, cmn.CW.lot, cmn.CW.prodNm, cmn.CW.prodId
                    , cmn.CW.prodStnd, 100, 100, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.userNm, null]} >
                { cmn.getGridColumnDayType('manuDy', '작업일', renderGridHeaderCell) }
                { cmn.getGridColumn('workNum', '작업지시', renderGridHeaderCell)}
                { cmn.getGridColumn('bizNm', '업체명', renderGridHeaderCell)}
                { cmn.getGridColumn('lot', 'Lot', renderGridHeaderCell)}
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell)}
                { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('manuAmt', '작업수량') }
                { cmn.getGridColumnNumCommaType('goodAmt', '양품') }
                { cmn.getGridColumnNumCommaType('badAmt', '불량') }
                { cmn.getGridColumn('userNm', '작업자', renderGridHeaderCell)}
                { cmn.getGridColumn('equpNm', '설비', renderGridHeaderCell)}
                <Column />
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ManuPage;
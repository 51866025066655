import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  라인 등록 / 수정
 */
const LinePopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */
    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageload, setPageLoad] = useState(true);

    /** Items */
    const [lineNm, setLineNm] = useState('');
    const [lineCd, setLineCd] = useState('');
    const [cmnt, setCmnt] = useState('');
    const [uptUserUid] = useState(authStore.user.uid);
    

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            //권한 조회
            await authStore.loadPopPage(props);

            if(mode === 'u' || mode === 'v')
            {
                await loadInfo1();
            }

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    //라인정보 상세 조회
    const loadInfo1 = async() => {
        try {            
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api('getLine', {p:{lineUid: uid}});
            if(r == null || r === undefined) return;

            //console.log(r);
            const d0 = r.d0[0];
            
            setLineNm(d0.lineNm);
            setLineCd(d0.lineCd);
            setCmnt(d0.cmnt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(lineCd, '라인코드'))
                return;
            if(!util.chkMustValue(lineNm, '라인명'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addLine();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upLine();
            }else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delLine();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // 라인 등록
    const addLine = async() => {
        const ps = {
            p: {
                    lineUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    lineNm: lineNm,
                    lineCd: lineCd,
                    cmnt: cmnt,
                    uptUserUid: uptUserUid
                }
        }

        const r = await cmn.api("addLine", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('등록'); 

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // 라인 수정
    const upLine = async() => {
        const ps = {
            p: {
                lineUid: uid,
                comUid: authStore.com.uid,
                lineNm: lineNm,
                lineCd: lineCd,
                cmnt: cmnt,
                uptUserUid: uptUserUid
            }
        }
        
        const r = await cmn.api("upLine", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('수정'); 
        util.clickOpenerElement("srchButton");
        window.close();
    }

    // 라인 삭제
    const delLine = async() => {
        const ps = {
            p: {
                lineUid: uid,
                comUid: authStore.com.uid,
            }
        }
        
        const r = await cmn.api("delLine", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('삭제'); 
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" width={'33%'} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">라인코드</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={lineCd}
                                        showClearButton={true}
                                        placeholder="라인코드을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setLineCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{lineCd}</span>
                                }
                            </td>
                            <th className="tit req">라인명</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={lineNm}
                                        showClearButton={true}
                                        placeholder="라인명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setLineNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{lineNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요"
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "라인 등록" 
                : mode === "u" ? "라인 수정"
                : mode === "v" ? "라인 보기"
                : "" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default LinePopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, CodeSelectBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';


/** 
 *  재고 실사 
 */
const FirstIngStockPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodTp, setProdTp] = useState(cmn.CMNCO_PRODTP.MAT);
    const [prodId, setProdId] = useState('');
    const [lot, setLot] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "제품유형",
                width: "200px",
                item: 
                    <CodeSelectBox
                        value={prodTp}
                        classId={'101'}
                        placeholder="제품유형을 선택하세요."
                        showClearButton={false}
                        onValueChanged={(v) => { 
                            setProdTp(v);
                        }}
                    />
            },
            {   title: "품번",
                width: "200px",
                item: 
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />    
            },
            {   title: "Lot",
                item: 
                <TextBox 
                    value={lot}
                    width={"200px"}
                    showClearButton={true}
                    placeholder="Lot로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setLot(e.value);
                    }}
                />    
            }
        ]
    ];
    /* #endregion */

   
    // * Grid
    const [firstIngStockList, setFirstIngStockList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, prodTp]);   

    /* ====================
     *  Load
     * ==================== */
    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodTp: prodTp, 
                prodId: prodId,
                lot: lot,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getFirstIngStockList", ps );
        if(r === undefined || r == null) return;

        setFirstIngStockList(r.d0)
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const handleClickAdd = (e) => {
        openCrudPopup("a", "", "");
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "crtDt" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }

    // * Functions

    const openCrudPopup = (mode, stockUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/stock/firstingstockpop?${qs.stringify({mode: mode, uid: stockUid})}`;     
        util.openWindow(url, 700, 550);
    }

    /* ====================
     *  Render
     * ==================== */
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                {/* <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    marginRight={0}
                    show={authStore.page.upYn}
                />  */}
            </ListTopView>    
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="최초재공재고내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>

            {/** GRID */}
            <DataGridView    
                reff={grid1}        
                dataSource={firstIngStockList}   // store
                gridHeight={authStore.windowHeight - 290}
                showNo={false}
                // page={curPage}
                // total={stockStore.firstIngStockListTotal}   // store
                // itemsPerPage={rowsLength}
                // onClickPage={handleClickPage}
                // onChangePageSize={handleChangePageSize}

                // select
                onSelectionChanged={handleGridSelectionChanged}   

                // excel
                exlnm={'최초재공재고내역'}
                customizeExcelCell={customizeExcelCell}   

                colsWidth={[cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    ,cmn.CW.lot, cmn.CW.amt, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80]} 
            >
                <Column 
                    alignment={'center'}
                    dataField={'prodNm'} 
                    caption={'품명'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, firstIngStockList, 'lot'); }}
                />    
                 <Column 
                    alignment={'center'}
                    dataField={'prodId'} 
                    caption={'품번'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, firstIngStockList, 'lot'); }}
                />                
                <Column 
                    alignment={'center'}
                    dataField={'prodStnd'}
                    caption={'규격'} 
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, firstIngStockList, 'lot'); }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'lot'}
                    caption={'Lot'} 
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, firstIngStockList, 'lot'); }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'workAmt'}
                    caption={'작업수량'} 
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, firstIngStockList, 'lot', util.getNumComma(d.value)); }}
                />
                { cmn.getGridColumn('proc1', '공정1') }
                { cmn.getGridColumn('proc2', '공정2') }
                { cmn.getGridColumn('proc3', '공정3') }
                { cmn.getGridColumn('proc4', '공정4') }
                { cmn.getGridColumn('proc5', '공정5') }
                { cmn.getGridColumn('proc6', '공정6') }
                { cmn.getGridColumn('proc7', '공정7') }
                { cmn.getGridColumn('proc8', '공정8') }
                { cmn.getGridColumn('proc9', '공정9') }
                { cmn.getGridColumn('proc10', '공정10') }
                <Column />
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default FirstIngStockPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, DayDateBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const WorkStaPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * Control Items (* Combo ...)
    // const [codeClassItems, setCodeClassItems] = useState([]);

    // * 검색
    const [startMon, setStartMon] = useState(util.getAddDate('M', -1));
    const [endMon, setEndMon] = useState(util.getNow());
    const [prodId, setProdId] = useState('');
    const [workNum, setWorkNum] = useState('');
    const [prodTp, setProdTp] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "작업월",
                width: "200px",
                item: 
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setStartMon(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setEndMon(e.value);
                            }}                    
                        />
                    </li>
                </ul>
            },
            {   title: "작업번호",
                width: "200px",
                item: 
                <TextBox 
                    value={workNum}
                    showClearButton={true}
                    placeholder="작업번호로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setWorkNum(e.value);
                    }}
                />        
            },
            {   title: "품번",
                item: 
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />   
            }
        ]

    ];
    /* #endregion */ 

    // * Grid
    const [workList, setWorkList] = useState([]);
    const [workListTot, setWorkListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton')
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                startMon: util.getDateToString('M', startMon), 
                endMon: util.getDateToString('M', endMon), 
                prodTp: prodTp, 
                prodId: prodId, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getWorkList", ps );
        if(r === undefined || r == null) return;

        setWorkList(r.d0)
        setWorkListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(util.chkListSelected(selectedItem)){
            console.log(selectedItem)
            if(selectedItem.outTpNm === '미불출') return util.cmnAlert('재료를 투입해주세요.');
            //공정이 마감되었거나 진척율이 100%이상이면 진행하지 않음.
            if(selectedItem.workPer >= 100 || selectedItem.compYn === 'Y')
            {
                util.cmnAlert('진행 가능한 작업이 아닙니다.');
                return;
            }
            
            const url = `/manu/manuretpop?${qs.stringify({mode: 'a', wn: selectedItem.workNum})}`;        
            util.openWindow(url, 740, 550 );    
        }
    }

    const handleClickOut = () => {
        // page mode: v(View), a(Add), u(Update)
        if(util.chkListSelected(selectedItem)){
            const url = `/stock/outpop?${qs.stringify({mode: 'a', uid: '', wn: selectedItem.workNum})}`;        
            util.openWindow(url, 700, 750);    
        }
    }

    // 작업지사 상세보기
    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            console.log(selectedItem)
            const url = `/manu/workpop?${qs.stringify({mode: 'v', uid: selectedItem.workUid})}`;        
            util.openWindow(url, 700, 550);
        }
    }
    
    const handleClickToolOut = () => {
        // page mode: v(View), a(Add), u(Update)
        if(util.chkListSelected(selectedItem)){
            const url = `/tool/tooloutpop?${qs.stringify({mode: 'a', wn: selectedItem.workNum})}`;        
            util.openWindow(url, 750, 650); 
        }
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        console.log("selectedRowsData ==> ", selectedRowsData);
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleClickEnd = async () => {
        try {
            if(util.chkListSelected(selectedItem)){
                if(!util.confirmYn('공정마감'))
                    return;
                
                // 공정마감
                const ps = {
                    p: {
                        comUid: authStore.com.uid,
                        workNum: selectedItem.workNum,
                        compYn: 'Y'
                    }
                }
        
                const r = await cmn.api('upWorkNumCompYn', ps);
                if(r == null) return;
                
                loadList1();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "startDy" 
            ||  gc.column.dataField === "endDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
 
    // * Functions

    // const openCrudPopup = (mode, produid, workuid) => {
    //     //alert(mode);
    //     // page mode: v(View), a(Add), u(Update)
    //     const url = `/manu/workpop?${qs.stringify({mode: mode, inputtp:'30201', produid: produid, uid:workuid})}`;        
    //     util.openWindow(url, 700, 600);      
    // }
    
    /* ====================
     *  Render
     * ==================== */
 
    // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    } 
 
    const renderContent = () => {
        return(
            <>
           <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    width={'120px'}
                    onClick={handleClickAdd}
                    title="주공정등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickOut}
                    title="재료불출"
                    show={authStore.page.crtYn}
                /> 
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
                {/* <ActionButton 
                    width={'120px'}
                    onClick={handleClickToolOut}
                    title="치공구불출"
                    show={authStore.page.crtYn}
                />  */}
                <ActionButton 
                    onClick={handleClickEnd}
                    title="공정마감"
                    marginRight={0}
                    type={2}
                    show={authStore.page.crtYn}
                /> 
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="작업지시 내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView> 
            <DataGridView           
                reff={grid1} 
                dataSource={workList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                page={curPage}
                total={workListTot}   // store
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}
                // excel
                exlnm={'작업지시 내역'}
                customizeExcelCell={customizeExcelCell}  
                colsWidth={[cmn.CW.workNum, cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    , cmn.CW.amt, 90, cmn.CW.amt, cmn.CW.amt, 80, 80, 100, 100, 100, null]}
            >
                { cmn.getGridColumn('workNum', '작지번호', renderGridHeaderCell)}
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('workAmt', '작업지시량') }
                { cmn.getGridColumn('outTpNm', '재료불출상태') }
                { cmn.getGridColumnNumCommaType('accrueManuAmt', '누적생산수량') }
                { cmn.getGridColumnNumCommaType('accrueBadAmt', '누적불량수량') }
                { cmn.getGridColumnPer('workPer', '진척율')}
                { cmn.getGridColumnUseYn('compYn', '공정마감') }
                { cmn.getGridColumnDayType('startDy', '시작일', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('endDy', '종료일', renderGridHeaderCell) }
                { cmn.getGridColumnFiles('fileInfo', '작업도면') }
                <Column /> 
            </DataGridView>
            </>
        );

    }


    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default WorkStaPage;
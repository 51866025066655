import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column, Summary, TotalItem  } from 'devextreme-react/data-grid';
import useKey from 'use-key-hook';
import qs from 'query-string';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, SrchButton, DayDateBox, SiteLayout
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산실적대기/등록
 */
const SiteManuStndByPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    /* ====================
     *  Object
     * ==================== */

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [startDy, setStartDy] = useState(util.getAddDate('d', -7));
    const [endDy, setEndDy] = useState(util.getNow());
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "작업일",
                width: "200px",
                item: 
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
            },
            {   title: "품번",
                width: "200px",
                item: 
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />    
            },
            {   title: "품명",
                item: 
                <TextBox 
                    value={prodNm}
                    width={"200px"}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />    
            }
        ]
    ];
    /* #endregion */

    // * Grid
    const [manuStndByList, setManuStndByList] = useState([]);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy), 
                endDy: util.getDateToString('d',endDy),
                prodId: prodId,
                prodNm: prodNm,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp
            }
        }

        const r = await cmn.api("getManuStndByList", ps );
        if(r === undefined || r == null) return;

        setManuStndByList(r.d0)

    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // ** Excel
    // const handleClickExcel = (e) => {
    //     cmn.xlsNo = 0;
    //     grid1.current.instance.exportToExcel();
    // }
    
    // * Data Grid
    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    const handleGridManuLinkClick = (prodProcUid, lot, workNum, type, procCd, cnt) => {
        //console.log(prodProcUid, lot, workNum);\

        const manuStndByList_ = [...manuStndByList]
        const findIndex = manuStndByList_.findIndex((x) => x.proc1 === prodProcUid && x.outTpNm === '미불출' && x.workNum === workNum);
        if(findIndex > -1) return util.cmnAlert('재료를 투입해주세요.');

        let url = '';
        if(type === '70301')
        {
            //alert(cnt);
            if(cnt === '1')
                url = `/site/sitemanuretfstproc?${qs.stringify({wn:workNum, lt:lot})}`;   
            else
                url = `/site/sitemanuretaftproc?${qs.stringify({lt:lot, pcd:procCd})}`;   
        }
        else if(type === '70303')
        {

        }
            
        // else if(type === '1')
        //     url = `/site/sitemanuretfstproc?${qs.stringify({wn:workNum})}`;   

        authStore.history.push(url);
        // page mode: v(View), a(Add), u(Update)
        // const url = `/site/manuretpop?${qs.stringify({mode: 'a', wn:workNum, lt:lot, ppuid: prodProcUid})}`;        
        // util.openWindow(url, 700, 550 );   
    }
    

    // * Functions

    /* ====================
     *  Render
     * ==================== */
 
    const renderContent = () => {
        return(
            <>
            <div id="SiteContWrap">                
                <GridTopView title="작업지시 현황">
                        <SrchButton 
                            id="srchButton"
                            type={"site-1"}
                            onClick={handleClickSearch}
                        />
                </GridTopView>
                
                {/** GRID */}
                <ListSearchView searchItems={searchItems} type="site" />
                <DataGridView  
                    reff={grid1}          
                    dataSource={manuStndByList}   // store
                    gridHeight={authStore.windowHeight - cmn.BH.H20}              
        
                    showNo={false}

                    selection = ''
                    onClickPage={handleClickPage}
                    onChangePageSize={handleChangePageSize}

                    colsWidth={[120, 120, 100, 170, 100, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, null]} 
                >
                    <Column 
                        alignment={'center'}
                        dataField={'prodNm'} 
                        caption={'품명'}
                        cellRender={(d) => { return cmn.cellRenderRowSpan(d, manuStndByList, 'colRowSpan'); }}
                    />    
                    <Column 
                        alignment={'center'}
                        dataField={'prodId'} 
                        caption={'품번'}
                        cellRender={(d) => { return cmn.cellRenderRowSpan(d, manuStndByList, 'colRowSpan'); }}
                    />                
                    <Column 
                        alignment={'center'}
                        dataField={'workNum'}
                        caption={'작업번호'}
                        cellRender={(d) => { return cmn.cellRenderRowSpan(d, manuStndByList, 'colRowSpan'); }}
                    />
                    <Column 
                        alignment={'center'}
                        dataField={'lot'}
                        caption={'LOT'}
                        cellRender={(d) => { return cmn.cellRenderRowSpan(d, manuStndByList, 'colRowSpan'); }}
                    />
                    <Column 
                        alignment={'center'}
                        dataField={'firstAmt'}
                        caption={'작업지시량'}
                        cellRender={(d) => { return cmn.cellRenderRowSpan(d, manuStndByList, 'colRowSpan', util.getNumComma(d.value)); }}
                    />
                    { cmn.getGridColumnProcInfoSite('proc1', '공정1', '1', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc2', '공정2', '2', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc3', '공정3', '3', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc4', '공정4', '4', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc5', '공정5', '5', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc6', '공정6', '6', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc7', '공정7', '7', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc8', '공정8', '8', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc9', '공정9', '9', handleGridManuLinkClick)}
                    { cmn.getGridColumnProcInfoSite('proc10', '공정10', '10', handleGridManuLinkClick)}
                    <Column />
                </DataGridView>
            </div>
            </>
        );

    }

    return (
        <>
            <SiteLayout
                title={"실적대기"}
                content = { !pageLoad && renderContent() }
            />
        </>
    )
}

export default SiteManuStndByPage;
import React  from 'react';
import { DateBox } from 'devextreme-react';
import _ from 'lodash';

/** components */

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  Date Time Component
 */
const TimeBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const {
        value,

        displayFormat='HH:mm',
        onValueChanged
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */

    // ...

    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {

        // console.log("--- value", value);

        return (
            <>
            <DateBox 
                width={80}
                value={value}
                type={'time'}
                
                displayFormat={displayFormat}
                onValueChanged={(e) => { onValueChanged(e.value); }}
            />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default TimeBox;
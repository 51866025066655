import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    UserMultiSelectBox,
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  권한 등록 / 수정
 */
const AuthUserPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [users, setUsers] = useState([]);
    const [authNm, setAuthNm] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    // useEffect(() => {
    //     loadInfo1();
    // },[pageLoad]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {      
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api("getAuth", {p: {authUid: uid}} );
            if(r === undefined || r == null) return;

            const data = r.d0[0];
            
            setAuthNm(data.authNm);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(mode === "a") 
            {
                if(util.confirmYn('등록'))
                    addAuthUser();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addAuthUser = async() => {

        let authUsers=[];
        _.forEach(users, (v,k) => {
            authUsers.push({
                authUserUid: v.uid,
                authUid: uid,
                userUid: v.userUid,
                uptUserUid: authStore.user.uid
            })
        });

        const ps = {
            p: authUsers
        }

        const r = await cmn.api("addAuthUser", ps);
        if(r === undefined || r == null) return;
  
        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">권한명</th>
                            <td className="tcon" colSpan={3}>
                                <span>{authNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">사용자</th>
                            <td className="tcon" colSpan={3}>
                                <UserMultiSelectBox
                                    mode={mode}
                                    users={users}
                                    onClickAdd={(users) => { setUsers(users); }}
                                    onClickDel={(users) => { setUsers(users); }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "사용자 등록" 
                : "사용자" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default AuthUserPopPage;
import React, { useState, useEffect, useContext } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    EditGridView,
    PopupLayout, 
    ActionButton,
    UserSelectBox,
    DayDateBox,
    DataGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  납품 (수주기반) 등록
 */
const DeliPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */
    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        snum = ls.snum,
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [lotListDeliSalesItem, setLotListDeliSalesItem] = useState([]);

    const [bizProdItems, setBizProdItems] = useState([]);
    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [userUid, setUserUid] = useState(authStore.user.uid);
    const [userNm, setUserNm] = useState('');
    const [deliDy, setDeliDy] = useState(util.getNow());
    const [deliNm, setDeliNm] = useState('');
    const [cmnt, setCmnt] = useState('');
    const [price, setPrice] = useState(0);
    const [totDeliAmt, setTotDeliAmt] = useState(0);
    const [acctMon, setAcctMon] = useState(util.getNow());

    const [salesNum, setSalesNum] = useState(snum);
    const [planDy, setPlanDy] = useState('');
    const [payDy, setPayDy] = useState('');
    const [salesItem, setSalesItem] = useState([]);
    

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);

            await loadInfo1();
         //   await loadDeliInfo();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
   
    const loadInfo1 = async() => {
        
        //if(util.getChkNull(mode) === 'a') return;
        if(util.getChkNull(snum) === '') return;

        try {   
            let r = await cmn.api('getSales', {p:{salesNum:snum}});
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            const data2 = r.d0;
            
            setBizUid(data.bizUid);
            setBizNm(data.bizNm);
            setPlanDy(util.getDateStrSplit('d', data.planDy));
            setPayDy(util.getDateStrSplit('d', data.payDy));
          //  setSalesTotPrice(util.getNumComma(data.salesTotPrice));

            let salesItem_ = [];

            _.forEach(data2, (v,k) => {
                salesItem_.push(
                    {
                        salesUid: v.salesUid,
                        prodUid: v.prodUid,
                        prodNm: v.prodNm,
                        salesAmt: v.salesAmt,
                        salesPrice: v.salesPrice,
                        amtPrice: v.amtPrice,
                        option1: v.option1,
                        deliAmt: v.deliAmt,
                        cmnt: v.cmnt,
                    });
                })

            setSalesItem(salesItem_);

            let r2 = await cmn.api('getLotListDeliSales', {p: {salesNum: snum}});
            if(r2 == null || r2 == undefined) return;
            let data3 = r2.d0;
            let lotListDeliItem_ = [];
            _.forEach(data3, (v, k) => {
                lotListDeliItem_.push({
                    lot: v.lot
                    ,prodUid: v.prodUid
                    ,prodNm: v.prodNm
                    ,prodId: v.prodId
                    ,prodStnd: v.prodStnd
                    ,stockAmt: v.stockAmt
                    ,deliAmt: 0
                    ,deliPrice: v.salesPrice
                    ,cmnt: ''
                })
            })

            console.log(lotListDeliItem_);
            setLotListDeliSalesItem(lotListDeliItem_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    // const loadLotItems = async(e) => {
    //     try { 

    //         let r = await cmn.api('getLotListDeliSales', {p: {salesNum: snum}});
    //         if(r == null || r == undefined) return;
    //         let data = r.items;
    //         let lotListDeliItem_ = [];
    //         _.forEach(data, (v, k) => {
    //             lotListDeliItem_.push({
    //                 lot: v.lot
    //                 ,firstAmt : v.firstAmt
    //                 ,compAmt: v.compAmt
    //                 ,badAmt : v.badAmt
    //                 ,stockAmt: v.stockAmt
    //                 ,deliAmt: 0
    //                 ,cmnt: ''
    //             })
    //         })
    //         setLotListDeliSalesItem(lotListDeliItem_);
    //     }
    //     catch(err){
    //         cmnStore.handleErrorPage(err, props);
    //     }
    // }

    // const loadTotDeliAmt = () => {
    //     setTotDeliAmt(_.sumBy(lotListDeliItem, 'deliAmt'));
    // }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // if(!util.chkMustValue(bizUid, '업체'))
            //     return;
            
            // if(!util.chkMustValue(_.sumBy(lotListDeliItem, 'deliAmt'), '납품수량', 'cnt'))
            //     return;
            
            // 추가 Validation Check
            // let msg = '';
            // _.forEach(lotListDeliSalesItem, (v,k) => {
            //     if(v.deliAmt > v.stockAmt) 
            //         msg = '[납품수량]이 [납품가능수량] 보다 큽니다.';
            // });
            // if(msg != '')
            // {
            //     alert(msg);
            //     return;
            // }

            if(mode == "a")
            { 
                if(!util.chkMustValue(userUid, '납품자'))
                    return;

                if(lotListDeliSalesItem.length <= 0)
                {
                    util.cmnAlert('등록가능한 품목이 없습니다.');
                    return;
                }else
                {
                    if(!util.chkMustValue(_.sumBy(lotListDeliSalesItem, 'deliAmt'), '납품수량', 'cnt'))
                         return;
                }

                if(util.confirmYn("등록"))
                    addDeli();
            }
            // else if(mode == "u") 
            // {
            //     if(util.confirmYn("수정"))
            //         upDeli();
            // }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addDeli = async() => {

        let r = await cmn.api('getDeliNum', {});
        if(r == null || r === undefined) return;

        let deliNum_ = r.d0[0].deliNum;

        let lotListDeliItem_ = [];

        _.forEach(lotListDeliSalesItem, (v,k) => {
            if(v.deliAmt > 0) {
                lotListDeliItem_.push(
                    {
                        deliUid: util.getUUID(),
                        comUid: authStore.com.uid,
                        bizUid: bizUid,
                        prodUid: v.prodUid,
                        deliDy: util.getDateToString('d',deliDy),
                        userUid: userUid,
                        deliPrice: v.deliPrice,
                        acctMon: util.getDateToString('M',acctMon),
                        lot: v.lot,
                        deliAmt: v.deliAmt,
                        cmnt: v.cmnt,
                     //   groupUid: groupUid,
                        deliNum: deliNum_,
                        salesNum: salesNum,
                        uptUserUid: authStore.user.uid,
                    }
                );
            }
        });

        let para = {
            p : [...lotListDeliItem_]
        }

        console.log(para);

        let r2 = await cmn.api('addDeli', para);
        if(r2 == null || r2 === undefined) return;
        
        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode == "a" ? "등록완료" 
            : mode == "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon w30p" />
						<col className="tit" />
						<col className="tcon w30p" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">수주번호</th>
                            <td className="tcon" colSpan={3}>
                                <span>{salesNum}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">수주일</th>
                            <td className="tcon">
                                <span>{planDy}</span>
                            </td>
                            <th className="tit">납기일</th>
                            <td className="tcon">
                                <span>{payDy}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">납품업체</th>
                            <td className="tcon">
                                <span>{bizNm}</span>
                            </td>
                            <th className="tit">납품자</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        showClearButton={false}
                                        placeholder="작업자를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                :
                                    <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">납품일</th>
                            <td className="tcon">
                                {
                                    mode == "a" || "u" ?
                                    <DayDateBox
                                        value={deliDy}
                                        onValueChanged={(e) => {
                                            setDeliDy(e.value);
                                        }}                    
                                    />
                                : 
                                    <span>{deliDy}</span>
                                }
                            </td>
                            <th className="tit">회계월</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={acctMon}
                                    type={'month'}
                                    displayFormat={cmn.DATE_FORMAT_MON_1}
                                    onValueChanged={(e) => {
                                        setAcctMon(e.value);
                                    }}                    
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="pop-grid">
                <div className="clearfix">
                    <div className="btn-area fl-l">
                        <span style={{color:'blue', fontWeight:'bold'}}> * 수주품목</span>
                    </div>
                </div>
                <div className="clearfix" style={{marginTop:'3px'}}>
                    <DataGridView
                    //  onSelectionChanged={handleGridSelectionChanged}     
                        showNo={false}
                        dataSource={salesItem}
                        gridHeight={150}
                        colsWidth={[150, 80, 90, 90, 250, null]}  
                    >
                        {/* { cmn.getGridColumnDayType('planDy', '수주일') }
                        { cmn.getGridColumnDayType('payDy', '납기일') } */}
                        { cmn.getGridColumn('prodNm', '품명')}
                        { cmn.getGridColumnNumCommaType('salesAmt', '수주량') }
                        { cmn.getGridColumnNumCommaType('amtPrice', '수주금액') }
                        { cmn.getGridColumn('deliAmt', '기납품수량') }
                        { cmn.getGridColumn('option1', '옵션') }
                        { cmn.getGridColumnCmnt() }
                    </DataGridView>
                </div>   
            </div>

            <div className="pop-grid" style={{marginTop:'10px'}}>
                <div className="clearfix">
                    <div className="btn-area fl-l">
                        <span style={{color:'blue', fontWeight:'bold'}} > * 납품대기품목</span>
                    </div>
                </div>
                <div className="clearfix" >
                <>
                    <EditGridView
                        gridHeight={150}
                        dataSource={lotListDeliSalesItem}   // store
                        colsWidth={[150, 100, 100, 100, 80, 80, null]}
                     //   onRowUpdated={(e) => {loadTotDeliAmt();}}
                    >
                        <Column 
                            alignment={'center'}
                            dataField={'lot'} 
                            caption={'Lot'} 
                            allowEditing={false} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'prodNm'} 
                            caption={'품명'} 
                            allowEditing={false} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'prodId'} 
                            caption={'품번'} 
                            allowEditing={false} 
                        />
                         <Column 
                            alignment={'center'}
                            dataField={'prodStnd'} 
                            caption={'규격'} 
                            allowEditing={false} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'stockAmt'} 
                            caption={'재고수량'} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'deliAmt'} 
                            caption={'납품수량'} 
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'cmnt'} 
                            caption={'비고'} 
                        />
                    </EditGridView>
                </>
                </div>
            </div>      

            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "납품 등록" 
                : mode == "u" ? "납품 수정"
                : "납품" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default DeliPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { Selection as TreeSelection, Column as TreeColumn, LoadPanel } from 'devextreme-react/tree-list';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    CancelButton,
    DayDateBox,
    EqupSelectBox,
    TreeListView,
    FileUploadBox
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  부서 등록 / 수정
 */
const WorkPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);    
    
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        inputtp = ls.inputtp, // 30201: 일반,30202: 수주단품,30203: 수주조립품,30204: 재고단품, 30205: 재고조립품
        puid = ls.puid,
        uid = ls.uid,
        amt = ls.amt,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */    
    const bomTreeList = useRef(null);
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [prodItems, setProdItems] = useState('');
    const [prodUid, setProdUid] = useState('');
    const [startDy, setStartDy] = useState(util.getNow());
    const [endDy, setEndDy] = useState(util.getNow());
    const [workAmt, setWorkAmt] = useState(util.getChkNull(amt, 0));
    const [cmnt, setCmnt] = useState('');
    
    const [equpUid, setEqupUid] = useState('');
    const [lineUid, setLineUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [workNum, setWorkNum] = useState('');

    const [workImgUid, setWorkImgUid] = useState(util.getUUID());
    const [workImgInfo, setWorkImgInfo] = useState('');
    const [workImgFiles, setWorkImgFiles] = useState([]);

    const [prodBomItems, setProdBomItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadProdBomInfo();
    },[prodUid]);

    useEffect(() => {
        !pageLoad && setTotPutAmt();
    },[workAmt]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);

            await loadProdInfo(puid);
            mode === 'a' && await loadProdBomInfo(puid);
            await loadInfo1();
            setPageLoad(false);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        if(util.getChkNull(mode) === 'a') return;
        if(util.getChkNull(uid) === '') return;

        try {
            const ps = {p: {workUid: uid}} 
            const r = await cmn.api('getWork', ps);
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            
            setStartDy(data.startDy);
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setProdStnd(data.prodStnd);
            setWorkNum(data.workNum);
            setEndDy(data.endDy);
            setWorkAmt(data.workAmt);
            setEqupUid(data.equpUid);
            setCmnt(data.cmnt);
            setWorkImgUid(data.workImgUid == null ? util.getUUID() : data.workImgUid);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadProdInfo = async(e) => {
        if(util.getChkNull(e) === '') return;

        const r = await cmn.api('getProd', {p:{prodUid:puid}});
        if(r == null || r === undefined) return;

        const data = r.d0[0];
        
        setProdUid(data.prodUid);
        setProdId(data.prodId);
        setProdNm(data.prodNm);
        setProdStnd(data.prodStnd);
        setEqupUid(data.equpUid);
        setLineUid(data.lineUid);
    }
    
    const loadProdBomInfo = async() => {
        try {
            const ps = {p: {prodUid: util.getChkNull(puid) === '' ? prodUid : puid }}

            const r = await cmn.api('getBomTree', ps);
            if(r == null || r === undefined) return;

            let prodBomItems_ = [];
            _.forEach(r.d0, (v, k) => {
                prodBomItems_.push({
                    taskId:v.taskId
                    ,parentTaskId:v.parentTaskId
                    ,prodUid: v.prodUid
                    ,takeTpNm: v.outputTp === "70402" ? "구매" : "작업지시"
                    ,prodId: v.prodId
                    ,prodNm: v.prodNm
                    ,putAmt: v.putAmt
                    ,prodTp: v.prodTp
                    ,prodTpNm: v.prodTpNm
                    ,unitTpNm: v.putTpNm
                    ,outputTp: v.outputTp
                    ,equpUid: v.equpUid
                    ,totPutAmt: util.fix(_.multiply(workAmt, v.putAmt))
                })
            })
            
            setProdBomItems(prodBomItems_);

            const items = _.filter(prodBomItems_,  (v, k) => { 
                return v.parentTaskId !== "0" || v ? true : false 
            });

            _.forEach(_.uniqBy(items, 'parentTaskId'), (v, k) => {
                bomTreeList.current.instance.expandRow(v.parentTaskId);
            })
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const setTotPutAmt = () => {
        try {
            if(util.getChkNull(workAmt, 0) === 0)  return;
            
            let prodBomItems_ = [...prodBomItems];
            _.forEach(prodBomItems_, (v, k) => {
                    v.totPutAmt = util.fix(_.multiply(v.putAmt, workAmt));
            });

            setProdBomItems(prodBomItems_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const getOutList = async () => {
        const ps =  {
            p: {
                workNum: workNum,
                lot:'',
                outStatTp:'30801',

                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        }
        const r = await cmn.api("getOutList", ps );
        return r.d0;
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = async (e) => {
        try {
            //validation
            if(!util.chkMustValue(workAmt, '수량', 'cnt'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addWork();
            }
            else if(mode === "v") 
            {
                
                if(util.confirmYn('삭제')) {
                    const para =  {p: {workNum: workNum}}
                    let r = await cmn.api('getManuWorkNum', para);
                    if(r.d0.length > 0) return util.cmnAlert('진행한 공정을 삭제해주세요.')
                    delWork()
                }
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addWork = async() => {
        let workInfo = [];
        for(let i = 0; i < prodBomItems.length; i++)
        {
            let takeInfo = [];
            let v = prodBomItems[i];
            if(v.outputTp !== '70402') // 원자재가 아닌 데이터만 조회
            {
                const ps = {p: {prodUid: v.prodUid}}

                const r = await cmn.api('getBomProd', ps); // 제품의 하위정보를 조회
                //if(r == null || r.items.length == 0) return;
                console.log('getBomProd1', r.d0);

                let workNumPara = {
                    p:{
                        comUid: authStore.com.uid,
                        prodUid: v.prodUid,
                        workDy: util.getDateToString('d', startDy),
                        firstAmt: v.totPutAmt,
                        inputTp: cmn.CMNCO_WORKNUMTP.WORK,
                        prefix: cmn.PREFIX.MANUFACTURE
                    }
                }

                let workNum_ = await cmnStore.getWorkNumCrt(workNumPara); //작업번호 생성

                _.forEach(r.d0, (vv, kk) => {
                    if(vv.outputTp === "70402") // 원자재 정보를 생성
                    {
                        takeInfo.push({
                            takeUid: util.getUUID(),
                            inputTp: '70501',
                            prodUid: vv.prodUid,
                            outputTp: vv.outputTp,
                            takeMon: util.getDateToString('M', startDy),
                            takeAmt: util.fix(_.multiply(vv.putAmt, workAmt)),
                            workNum: workNum_ //작업번호를 소요분석테이블에 등록
                        })
                    }
                })

                workInfo.push({
                    workUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    prodUid: v.prodUid,
                    workNum: workNum_,
                    startDy: util.getDateToString('d',startDy),
                    endDy: util.getDateToString('d',endDy),
                    workAmt: v.totPutAmt,
                    equpUid: prodUid === v.prodUid ? equpUid : v.equpUid,
                    inputTp: inputtp, 
                    cmnt: cmnt,
                    workImgUid: workImgUid,
                    uploadFiles: workImgFiles,
                    uptUserUid: authStore.user.uid,
                    takeInfo:takeInfo
                });
            }
        }

        const ps = {
            p: [...workInfo]
        }

        const r = await cmn.api("addWork", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }
    

    const delWork = async() => {
        const outList = await getOutList()

        const recallInfo = _.map(outList, (v, k) => {
            return {
                outUid: v.outUid,
                comUid: authStore.com.uid,
                lot: v.lot,
                prodUid: v.prodUid,
                outAmt: v.outAmt,
                outStatTp: '30802',
                recallDy: util.getDateToString('d', util.getNow()),
                recallCmnt: '작지삭제 자동회수',
                recallUserUid: authStore.user.uid,
                uptUserUid: v.uptUserUid
            }
        })
        
        const ps = {
            p: {
                workUid: uid,
                recallInfo: recallInfo
            }
        }
        const r = await cmn.api('delWork', ps)
        if(r == null || r === undefined) return;
        util.cmnSucAlert("삭제");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'35%'}/>
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">시작일</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={startDy}
                                    onValueChanged={(e) => {
                                        setStartDy(e.value);
                                    }}                    
                                />
                            </td>
                            <td colSpan={2} rowSpan={5} className="tcon">
                                <div className="div-img-100" style={{minHeight: 220}}>
                                    { 
                                        workImgInfo !== '' ?                                                
                                        cmn.getRenderImg(workImgInfo, 300) 
                                        :
                                        <p>
                                            이미지 없음.<br />
                                            파일을 선택해 주세요.
                                        </p>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">종료일</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={endDy}
                                    onValueChanged={(e) => {
                                        setEndDy(e.value);
                                    }}                    
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon" >
                                <span>{prodId}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">수량</th>
                            <td className="tcon">
                                {
                                    mode === "a" ? 
                                        <NumberBox
                                            width={80}
                                            format={'#,##0.###'}
                                            value={workAmt}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setWorkAmt(e.value);
                                            }} 
                                        />
                                    :
                                    <span>{workAmt} </span>
                                }
                            </td>
                            <td className="tcon" colSpan={2} rowSpan={2} style={{verticalAlign: "top"}}>
                                <FileUploadBox
                                    limit={1}
                                    // width={100}
                                    linkWidth={100}
                                    grpUid={workImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={workImgFiles}
                                    labelText=''
                                    onLoadInfo={(e) => {
                                        const fi = cmn.getUploadFileInfoStr(e);
                                        setWorkImgInfo(fi);
                                    }}
                                    onUploaded={(e) => { 
                                        setWorkImgFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setWorkImgFiles(e);
                                    }}
                                /> 
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">생산설비</th>
                            <td className="tcon">
                                <EqupSelectBox
                                    width={'150px'}
                                    value={equpUid}
                                    lineYn={authStore.com.isLine ? 'Y' : 'N'}
                                    lineValue={lineUid}
                                    onLoadInfo={(o) => {}} 
                                    onValueChanged={(v) => {
                                        setEqupUid(v);
                                    }}
                                />   
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {
                mode === 'a' ?
            <div>
                <div className="clearfix">
                    <div className="s-tit-area fl-l">
                        <span style={{color:'blue', fontWeight:'bold'}}> * 예상소요분석</span>
                    </div>
                </div>
                <div className="clearfix">
                <>
                    <TreeListView
                        reff={bomTreeList}
                        ///height={authStore.windowHeight - cmn.BH.H10}
                        dataSource={prodBomItems}
                        keyExpr={'taskId'}
                        parentIdExpr={'parentTaskId'}
                        height={200}
                    //  onSelectionChanged={handleTreeListSelectionChanged}
                    >
                        <TreeColumn dataField={'takeTpNm'} caption={'소요구분'} />
                        <TreeColumn dataField={'prodTpNm'} caption={'제품구분'} alignment={'center'} />
                        <TreeColumn dataField={'prodNm'} caption={'품번'} alignment={'center'}  />
                        <TreeColumn dataField={'prodId'} caption={'품명'} alignment={'center'}  />
                        <TreeColumn dataField={'putAmt'} caption={'투입량'} alignment={'center'}  />
                        <TreeColumn dataField={'unitTpNm'} caption={'투입단위'} alignment={'center'}  />
                        <TreeColumn dataField={'totPutAmt'} caption={'예상소요량'} alignment={'center'}  />
                    </TreeListView>
                </>
                </div>
            </div>
            :
            ''
            }
               
            </>
        )
    }


    //getBomProd
    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "작업지시 등록" 
                : mode === "u" ? "작업지시 등록"
                : "작업지시 상세" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default WorkPopPage;
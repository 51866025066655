import React from 'react';

/** components */
import {
    IconButton
 } from '../../components';

const PopupLayout = (props) => {

    const { 
        title = "",
        content = null,

    } = props;

    const handleClickClose = () => {
        window.close();
    }

    return(
        <>
        <div id="popWrap">
            <div id="popHeader" className="clearfix">
				<h1 className="pop-tit fl-l">{ title }</h1>
                <IconButton
                    className="btn btn-icon-pop-close fl-r"
                    alt="닫기"
                    onClick={handleClickClose}
                    marginRight={0}
                />
			</div>
            <div className="pop-content">
                {content}
            </div>
        </div>
        </>
    )
}

export default PopupLayout;
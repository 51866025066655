import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, FileUploadBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  제품 등록 / 수정
 */
const ProdViewPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [prodTp, setProdTp] = useState('');
    const [prodTpNm, setProdTpNm] = useState('');
    const [carTp, setCarTp] = useState('');
    const [carTpNm, setCarTpNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodMattTp, setProdMattTp] = useState('');
    const [prodMattTpNm, setProdMattTpNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [unitTp, setUnitTp] = useState('');
    const [unitTpNm, setUnitTpNm] = useState('');
    const [cmnYn, setCmnYn] = useState('N');
    const [lotAmt, setLotAmt] = useState(0);
    const [prodWght, setProdWght] = useState(0);
    const [manuYn, setManuYn] = useState('Y');
    const [useYn, setUseYn] = useState('Y');
    const [prodImgUid, setProdImgUid] = useState(util.getUUID());
    const [prodImgInfo, setProdImgInfo] = useState('');
    const [prodImgFiles, setProdImgFiles] = useState([]);
    const [safeAmt, setSafeAmt] = useState(0);
    const [cmnt, setCmnt] = useState('');
    const [prodPrice, setProdPrice] = useState(0);
    const [unitAmt, setUnitAmt] = useState(0);
    const [prodImgFile, setProdImgFile] = useState('');
    
    // const [prodUid, setProdUid] = useState('');
//    const [uploadFiles, setUploadFiles] = useState([]);
    
    useEffect(() => {
        loadPage();
    },[]);
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadInfo1 = async() => {
        try {            
            if(util.getChkNull(uid) === '') return;

            let r = await cmn.api('getProd', {p:{prodUid:uid}})
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            
            setProdTp(data.prodTp);
            setProdTpNm(data.prodTpNm);
            setCarTp(data.carTp);
            setCarTpNm(data.carTpNm);
            setProdId(data.prodId);
            setProdNm(data.prodNm);
            setProdMattTp(data.prodMattTp);
            setProdMattTpNm(data.prodMattTpNm);
            setProdStnd(data.prodStnd);
            setUnitTp(data.unitTp);
            setUnitTpNm(data.unitTpNm);
            setCmnYn(data.cmnYn);
            setLotAmt(data.lotAmt);
            setProdWght(data.prodWght);
            setManuYn(data.manuYn);
            setUseYn(data.useYn);
            setProdImgUid(data.prodImgUid == null ? util.getUUID() : data.prodImgUid);
            setSafeAmt(data.safeAmt);
            setCmnt(data.cmnt);
            setProdPrice(data.prodPrice);
            setUnitAmt(data.unitAmt);
            setProdImgFile(prodImgFile);
            
            setProdImgFiles(r.f0);
            // const files = ;
            // setUploadFiles(files);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {


        return(
            <>
            <div className="btn_r mgb10">
                
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={260} />
						<col className="tit" />
						<col className="tcon"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">제품유형</th>
                            <td className="tcon">
                                <span>{prodTpNm}</span>
                            </td>
                            <td colSpan={2} rowSpan={6} className="tcon">
                                <div className="div-img-100" style={{minHeight: 220}}>
                                    { 
                                        prodImgInfo !== '' ?                                                
                                        cmn.getRenderImg(prodImgInfo, 300) 
                                        :
                                        <p>
                                            이미지 없음.<br />
                                            파일을 선택해 주세요.
                                        </p>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">품명</th>
                            <td className="tcon">
                                <span>{prodNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">단위</th>
                            <td className="tcon">
                                <span>{unitTpNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">단위수량</th>
                            <td className="tcon">
                                <span>{unitAmt}</span>
                            </td>
                        </tr>
                        
                        <tr>
                            <th className="tit">제품단가</th>
                            <td className="tcon"> 
                                <span>{prodPrice}</span>
                            </td>
                            <td className="tcon" colSpan={2} rowSpan={2} style={{verticalAlign: "top"}}>
                                <FileUploadBox
                                    limit={1}
                                    // width={100}
                                    linkWidth={100}
                                    grpUid={prodImgUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={prodImgFiles}
                                    labelText=''
                                    onLoadInfo={(e) => {
                                        const fi = cmn.getUploadFileInfoStr(e);
                                        setProdImgInfo(fi);
                                    }}
                                    onUploaded={(e) => { 
                                        setProdImgFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setProdImgFiles(e);
                                    }}
                                /> 
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">사용여부</th>
                            <td className="tcon"> 
                                <span>{useYn}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">안전재고</th>
                            <td className="tcon" > 
                                <span>{safeAmt}</span>
                            </td>
                            <th className="tit">Lot 정량</th>
                            <td className="tcon">
                                <span>{lotAmt}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                <span>{cmnt}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                "제품 상세보기" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProdViewPopPage;
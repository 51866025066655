import React, { useState, useEffect, useContext } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { Popup } from 'devextreme-react/popup';
import useMobileDetect from 'use-mobile-detect-hook'

/** components */
import {
    SideMenuView,
    IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */
import ico_user from '../../images/ico/ico_user.png';

/** 
 *  Base Layout
 */
const BaseLayout = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const cmnStore = useContext(CmnStore);
    const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */
    const detectMobile = useMobileDetect();
    
    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        // console.log("!!! loadPage BaseLayout");
        try {
            // const isAuth = await cmn.chkAuth();
            // alert(isAuth);

            const r = await cmn.api("getChkAuthToken");
            if(r === undefined || r == null) return;    
            cmn.setToken(r.token);

            // if(!isAuth){
            //     window.location.href = "/login";
            //     return;
            // }

            window.addEventListener("resize", handleResizeWindow);
            handleResizeWindow();    
            setPageLoad(false);
           
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleHidePopup = () => {
        cmnStore.popup = {show: false, message: ''};
    }

    const handleResizeWindow = (e) => {
        // console.log("=== handleResizeWindow", e);
        const h = e === undefined ? window.innerHeight : e.currentTarget.innerHeight;
        authStore.windowHeight = h;
    }

    const logOut = async () => {
        cmn.setToken('');
        const ps2 = {
            p : {
                logUid: util.getUUID(),
                comUid: authStore.com.uid,
                userId: authStore.user.id,
                userNm: authStore.user.name,
                ipAddr: '',
                action: '71102',
                deviceTp: detectMobile.isMobile() ? '71202' : '71201',
            }
        }
        const r2 = await cmn.api("addLog", ps2);
        if(r2 === undefined || r2 == null) return;
                
        window.location.href = "/login";
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContentTop = () => {   

        return (
            <>
            <Observer>{() =>       
            <div id="Header" className="clearfix">
                <div className="hdr-l fl-l">
                    <a  className="logo-img"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            authStore.history.push('/');
                        }}                            
                    >
                    { 
                        util.getChkNull(authStore.com.logo) !== '' && 
                        cmn.getRenderImg(authStore.com.logo, 64)
                    }    
                    <span className="com">{authStore.com.name}</span> 
                    </a>   
                    {
                    !detectMobile.isMobile() &&
                        <a className="btn-top" href="/site/site" target="_blank">POP</a>
                    }    
                    {
                    authStore.user.sgr &&
                        <a className="btn-top" href="/base/menu" target="_blank">메뉴관리</a>
                    }    
                </div>

                <div className="userinfo fl-r clearfix">
                    <div className="user-pic fl-l">
                        <a  href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                const url = `/base/userinfopop`;     
                                util.openWindow(url, 470, 500);
                            }}                           
                        >
                        { 
                            util.getChkNull(authStore.user.pic) !== '' ?
                            cmn.getRenderImg(authStore.user.pic, 34)
                            : <img src={ico_user} alt="개인설정" title="개인설정"></img> 
                        }    
                        </a>  
                    </div>
                    <div className="txt fl-l">
                        <span>{authStore.dept.name}</span>&nbsp;&nbsp;
                        <span className="nm">{authStore.user.name}</span>
                        <span>님 반갑습니다.</span>
                    </div>
                    <div className="icoBtn fl-l">
                          <IconButton
                            className="btn-icon-logout"
                            // marginTop={3}
                            marginLeft={16}
                            marginRight={8}
                            alt="로그아웃"
                            title="로그아웃"
                            onClick={logOut}
                        />
                        <IconButton
                            className="btn-icon-help"
                            // marginTop={3}
                            marginRight={8}
                            alt="사용자 매뉴얼"
                            title="사용자 매뉴얼"
                            onClick={() => {
                                const url = cmn.getDownloadFileUrl(authStore.com.manual);
                                window.open(url, '_blank');
                            }}  
                        />
                        {
                        !detectMobile.isMobile() &&
                        <IconButton
                            className="btn-icon-remote"
                            // marginTop={3}
                            marginRight={0}
                            alt="원격지원"
                            title="원격지원"
                            onClick={() => {
                                const url = 'http://helpu.kr/goodksoft/'
                                window.open(url, '_blank');
                                // alert('success');
                            }}  
                        />
                        }
                    </div>
                </div>                
            </div>
            }</Observer>
            </>
        )
    }

    const renderContent = () => {

        return (
            <>
            <Observer>{() =>
            <>       
            <div id="Wrap">
                { renderContentTop() }            
                <div className={`ContWrap fl-l ${authStore.sideOpened && "is-visible"}`}>              
                    <div id="ContentWrap">
                        <SideMenuView/>    
                        { props.children }
                    </div>
                </div>
            </div>

            <Popup
                visible={cmnStore.popup.show}
                onHiding={handleHidePopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title="알림"
                width={300}
                height={250}
            >
                <p>{cmnStore.popup.message}</p>
            </Popup>
            </>
            }</Observer>          
            </>
        )

    }

    return(
        <>
        { !pageLoad && renderContent() }
        </>
    )

}

export default BaseLayout;
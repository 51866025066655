import React from "react";

const SrchButton = (props) => {

    const { 
        id,
        // refObj,
        onClick, 
        width = "100px",
        title = "조회",
        type,   // site-1, selpop
        marginRight = 10,
        show=true
    } = props;

    return(
        <>
        <a 
            id={id}
            // ref={ref => {
            //     refObj = ref;
            // }}
            href="#"
            //className="btn-srch"
            className={`btn btn-sz ${type === "site-1" ? "btnSrch-site-1" : type === "selpop" ? "btnSrch-selpop" : "btnSrch" } `}
            style={{ width: type === "site-1" ? 160 : width, marginRight: marginRight, display: show ? '' : 'none' }}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            alt=""
        >
            { title }
        </a>
        </>
    );

}

export default SrchButton;
const inOutApiz = {
    

    
    getInList: {
        title: "입고 목록 조회",
        url: "/api/inOut/getInList",
        method: "POST"
    },
    getIn: {
        title: "입고 상세 조회",
        url: "/api/inOut/getIn",
        method: "POST"
    },
    addIn: {
        title: "입고 등록",
        url: "/api/inOut/addIn",
        method: "POST"
    },
    upIn: {
        title: "입고 수정",
        url: "/api/inOut/upIn",
        method: "POST"
    },
    delIn: {
        title: "입고 삭제",
        url: "/api/inOut/delIn",
        method: "POST"
    },

    getOutList: {
        title: "불출 목록 조회",
        url: "/api/inOut/getOutList",
        method: "POST"
    },
    getOut: {
        title: "불출 상세 조회",
        url: "/api/inOut/getOut",
        method: "POST"
    },
    addOut: {
        title: "불출 등록",
        url: "/api/inOut/addOut",
        method: "POST"
    },
    upOut: {
        title: "출고 수정",
        url: "/api/inOut/upOut",
        method: "POST"
    },
    delOut: {
        title: "출고 삭제",
        url: "/api/inOut/delOut",
        method: "POST"
    },
    upOutRecall: {
        title: "불출 회수",
        url: "/api/inOut/upOutRecall",
        method: "POST"
    },
    getOutsInList: {
        title: "외주 입고 목록 조회",
        url: "/api/inOut/getOutsInList",
        method: "POST"
    },
    getOutsIn: {
        title: "외주 입고 상세 조회",
        url: "/api/inOut/getOutsIn",
        method: "POST"
    },
    addOutsIn: {
        title: "외주 입고 등록",
        url: "/api/inOut/addOutsIn",
        method: "POST"
    },
    upOutsIn: {
        title: "외주 입고 수정",
        url: "/api/inOut/upOutsIn",
        method: "POST"
    },
    delOutsIn: {
        title: "외주 입고 삭제",
        url: "/api/inOut/delOutsIn",
        method: "POST"
    },
    getOutsInListStndBy: {
        title: "외주 입고 대기 목록 조회",
        url: "/api/inOut/getOutsInListStndBy",
        method: "POST"
    },
    getLotListOutsIn: {
        title: "입고가능한 lot item 목록 조회",
        url: "/api/inOut/getLotListOutsIn",
        method: "POST"
    },
    getOutsOutList: {
        title: "외주 출고 목록 조회",
        url: "/api/inOut/getOutsOutList",
        method: "POST"
    },
    getOutsOutListStndBy: {
        title: "외주 출고 대기 목록 조회",
        url: "/api/inOut/getOutsOutListStndBy",
        method: "POST"
    },
    getOutsOut: {
        title: "외주 출고 상세 조회",
        url: "/api/inOut/getOutsOut",
        method: "POST"
    },
    addOutsOut: {
        title: "외주 출고 등록",
        url: "/api/inOut/addOutsOut",
        method: "POST"
    },
    upOutsOut: {
        title: "외주 출고 수정",
        url: "/api/inOut/upOutsOut",
        method: "POST"
    },
    delOutsOut: {
        title: "외주 출고 삭제",
        url: "/api/inOut/delOutsOut",
        method: "POST"
    },
    getOutsOutListStndBy: {
        title: "외주 출고 대기 목록 조회",
        url: "/api/inOut/getOutsOutListStndBy",
        method: "POST"
    },
    getLotListOutsOut: {
        title: "출고가능한 lot item 목록 조회",
        url: "/api/inOut/getLotListOutsOut",
        method: "POST"
    },
    
}

export default inOutApiz;
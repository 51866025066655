import React, { useState, useEffect, useContext } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { Column} from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    FileUploadBox, BizSelectBox, EqupSelectBox,
    UseYnSwitchBox,
    PopupLayout, ActionButton, SubActionButton, GridTopView, DataGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  생산설비 예비부품 CRUD 팝업
 */
const EqupBkupPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [partNm, setPartNm] = useState('');
    const [partStnd, setPartStnd] = useState('');
    const [holdGrade, setHoldGrade] = useState('');
    const [safeStock, setSafeStock] = useState(0);
    const [curStock, setCurStock] = useState(0);
    const [makeTakeDyCnt, setMakeTakeDyCnt] = useState(0);
    const [makeCost, setMakeCost] = useState(0);
    const [orderBizUid, setOrderBizUid] = useState('');
    const [saveLoca, setSaveLoca] = useState('');
    const [useYn, setUseYn] = useState(false);
    const [cmnt, setCmnt] = useState('');

    const [equps, setEqups] = useState([]);    

    const [equpUid, setEqupUid] = useState('');
    const [equp, setEqup] = useState(null);

    const [fileUid, setFileUid] = useState(util.getUUID());
    const [uploadFiles, setUploadFiles] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        loadPage();
    },[]);
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {             
            if(mode == 'a') return;
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getBkupPart", {p: {bkupPartUid: uid}} );
            if(r == undefined) return;

            const d0 = r.d0[0];
            const d1 = r.d1;    
        
            setPartNm(d0.partNm);
            setPartStnd(d0.partStnd);
            setHoldGrade(d0.holdGrade);
            setSafeStock(d0.safeStock);
            setCurStock(d0.curStock);
            setMakeTakeDyCnt(d0.makeTakeDyCnt);
            setMakeCost(d0.makeCost);
            setOrderBizUid(d0.orderBizUid);
            setSaveLoca(d0.saveLoca);
            setFileUid(d0.fileUid == null ? util.getUUID() : d0.fileUid);
            setUseYn(d0.useYn);
            setCmnt(d0.cmnt);
            setEqups(d1);

            setUploadFiles(r.f1);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(partNm, '부품명')) return;

            if(mode == "a") { 
                if(!util.confirmYn('등록')) return;
                addBkupPart();
            }
            else if(mode == "u") { 
                if(!util.confirmYn('수정')) return;
                upBkupPart();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(equp == null) {
            alert("추가할 설비를 선택하세요.");
            return;
        }

        if(_.find(equps, {equpUid: equp.equpUid})) return;
        const equps_ = [...equps];
        equps_.push(equp);
        // console.log("--- equps_", equps_);
        setEqups(equps_);
    }

    const handleClickDel = (e) => {
        if(!util.chkListSelected(selectedItem, '삭제할 설비를')) return;

        const equps_ = [...equps];
        _.forEach(selectedItem, (v, k) => {
            _.remove(equps_, (vv) => {
                return v.equpUid == vv.equpUid;
            });
        });
        setEqups(equps_);
    }

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData);
    }

    const addBkupPart = async() => {

        const ps = {
            p: {
                    bkupPartUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    partNm: partNm,
                    partStnd: partStnd,
                    holdGrade: holdGrade,
                    safeStock: safeStock,
                    curStock: curStock,
                    makeTakeDyCnt: makeTakeDyCnt,
                    makeCost: makeCost,
                    orderBizUid: orderBizUid,
                    saveLoca: saveLoca,
                    fileUid: fileUid,
                    useYn: useYn,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                    uploadFiles: uploadFiles,
                    equps:equps
                }
        }

        const r = await cmn.api("addBkupPart", ps);
        if(r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upBkupPart = async() => {
        const ps = {
            p: 
                {
                    bkupPartUid: uid,
                    partNm: partNm,
                    partStnd: partStnd,
                    holdGrade: holdGrade,
                    safeStock: safeStock,
                    curStock: curStock,
                    makeTakeDyCnt: makeTakeDyCnt,
                    makeCost: makeCost,
                    orderBizUid: orderBizUid,
                    saveLoca: saveLoca,
                    fileUid: fileUid,
                    useYn: useYn,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                    uploadFiles: uploadFiles,
                    equps:equps
                }
        }

        const r = await cmn.api("upBkupPart", ps);
        if(r == undefined) return;

       util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode == "a" ? "등록완료" 
            : mode == "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"40%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">부품명</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <TextBox                                         
                                        value={partNm}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setPartNm(e.value);
                                        }} 
                                    />                              
                                    :
                                    <span>{partNm}</span>
                                }
                            </td>
                            <th className="tit">부품규격</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <TextBox                                         
                                        value={partStnd}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setPartStnd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{partStnd}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">구매처</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <BizSelectBox
                                        value={orderBizUid}                                        
                                        bizTp={[cmn.CMNCO_BIZ.PART]}
                                        onLoadInfo={(o) => {
                    
                                        }} 
                                        onValueChanged={(v) => {
                                            setOrderBizUid(v);
                                        }}
                                    />   
                                :
                                    <span>...</span>
                                }
                            </td>
                            <th className="tit">보유등급</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        value={holdGrade}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setHoldGrade(e.value);
                                        }} 
                                    />
                                :
                                    <span>{holdGrade}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제작소요일</th>
                            <td className="tcon">
                            {
                                mode == "a" || "u" ?
                                <ul className="ul-row">
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={makeTakeDyCnt}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setMakeTakeDyCnt(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        <span>(일)</span>      
                                    </li>
                                </ul>
                                :
                                <span>{makeTakeDyCnt}(일)</span>
                            }
                            </td>
                            <th className="tit">제작비용</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <ul className="ul-row">
                                        <li>
                                            <NumberBox
                                                width={100}
                                                value={makeCost}
                                                format={'#,##0'}
                                                defaultValue={0}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setMakeCost(e.value);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                          <span>(원)</span>      
                                        </li>
                                    </ul>
                                :
                                    <span>{makeCost}(원)</span>
                                }
                            </td>
                        </tr>                           
                        <tr>
                            <th className="tit">현재재고</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <ul className="ul-row">
                                        <li>
                                            <NumberBox
                                                width={100}
                                                value={curStock}
                                                format={'#,##0'}
                                                defaultValue={0}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setCurStock(e.value);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                          <span>(EA)</span>      
                                        </li>
                                    </ul>
                                :
                                    <span>{makeTakeDyCnt}(EA)</span>
                                }
                            </td>
                            <th className="tit">안전재고</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <ul className="ul-row">
                                        <li>
                                            <NumberBox
                                                width={100}
                                                value={safeStock}
                                                format={'#,##0'}
                                                defaultValue={0}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={(e)=>{
                                                    setSafeStock(e.value);
                                                }} 
                                            />
                                        </li>
                                        <li>
                                          <span>(EA)</span>      
                                        </li>
                                    </ul>
                                :
                                    <span>{safeStock} (EA)</span>
                                }
                            </td>
                        </tr>                        
                        <tr>
                            <th className="tit">보관위치</th>
                            <td className="tcon">
                                {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        value={saveLoca}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setSaveLoca(e.value);
                                        }} 
                                    />
                                :
                                    <span>{saveLoca}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon">
                            {
                                mode == "a" || "u" ?
                                <UseYnSwitchBox 
                                    value={useYn}
                                    onValueChanged={(v)=>{
                                        setUseYn(v);
                                    }} 
                                />
                                : 
                                    <span>{useYn}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">부품사진</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode == "a" || "u" ?
                                <>
                                 <FileUploadBox
                                    limit={1}
                                    grpUid={fileUid}
                                    // accept={'image/*'}
                                    userUid={authStore.user.uid}
                                    uploadFiles={uploadFiles}

                                    onUploaded={(e) => {
                                        setUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setUploadFiles(e);
                                    }}
                                />
                                </>
                                :
                                    <span>{fileUid}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                            }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <GridTopView title="사용설비 내역">
                <ul className="ul-row">
                    <li>
                        <EqupSelectBox
                            width="200px"
                            lineYn={authStore.com.isLine ? 'Y' : 'N'}
                            value={equpUid}
                            onLoadInfo={(o) => {
                                setEqup(o);
                            }} 
                            onValueChanged={(v) => {
                                setEqupUid(v);
                            }}
                        />
                    </li>
                    <li>
                        <SubActionButton 
                            onClick={handleClickAdd}
                            title="추가"
                            show={true}
                        /> 
                        <SubActionButton 
                            onClick={handleClickDel}
                            title="선택삭제"
                            marginRight={0}
                            show={true}
                        />
                    </li>
                </ul>
            </GridTopView>
            <DataGridView            
                dataSource={equps}   // store
                // gridHeight={authStore.windowHeight - cmn.BH.H10}                
                colsWidth={[120, 160, 200, null]}
                pagingable={false}               
                selection='multiple' 
                onSelectionChanged={handleGridSelectionChanged}            
            >
                { cmn.getGridColumn('equpTpNm', '설비유형') }                
                { cmn.getGridColumn('equpCd', '설비코드') }
                { cmn.getGridColumn('equpNm', '설비명') }
                <Column />
            </DataGridView>                  
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "생산설비 예비부품 등록" 
                : mode == "u" ? "생산설비 예비부품 수정"
                : "생산설비 예비부품" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default EqupBkupPopPage;
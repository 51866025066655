import React, { useState, useEffect } from "react";

const SubActionButton = (props) => {

    const { 
        onClick, 
        width = "60px",
        title = "",
        type,   // 1
        marginRight = 8,
        show=false
    } = props;

    return(
        <>
        <a
            href="#"
            className={`${type === "1" ? "btn-action-sub-1" : 
                        type === "2" ? "btn-action-sub-2" :
                        type === "3" ? "btn btn-sz-site-1" :
                        "btn-action-sub"}`}
            style={{ 
                width, 
                marginRight: marginRight,
                display: show ? '' : 'none' 
            }}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            { title }
        </a>
        </>
    );

}

export default SubActionButton;
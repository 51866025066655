import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';

/** components */

/** stores & lib */

/** images */


/** 
 *  List Search View
 */
const ListSearchView = (props) => {

    /* ====================
     *  Store 
     * ==================== */

        // ...

    /* ====================
     *  Object
     * ==================== */

    const {
        searchItems,
        type,
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */

    useEffect(() => {
        // ...
    },[]);

    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 

    const renderItems = (rows) => {

        return  (

            _.map(rows, (v, k) => {                
                return (
                    <React.Fragment key={k}>
                    {
                        v.title != '' &&
                        <th className="tit">
                            { v.title }
                        </th>
                    }
                    {
                        v.item != null &&
                        <td className="tcon"
                            style={{
                                width: v.width
                            }}
                            colSpan={v.colSpan}
                        >
						{ v.item }
						</td>
                    }
                   </React.Fragment>
                )                        
            })

        )
    }
      
    const renderContent = () => {
        const rowsLength = searchItems.length;

        return (
            <>
            <div className={ type === 'site' ? "site_search_type" : "board_search_type"}>
                {/* <table className="bdr2-tb"> */}
                <table>
                    <tbody>
                    {
                        _.map(searchItems, (v, k) => {
                            return (
                                <tr key={k}
                                    // className={`${k < (rowsLength -1) ? "bdr1-b" : ""}`} 
                                >
                                { renderItems(v) }
                                </tr>            
                            )                        
                        })
                    }
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default ListSearchView;
import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    ProdSearchBox,
    SubActionButton,
    DataGridView,
    UserSelectBox ,
    CancelButton,
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** images */

/** 
 *  발주 등록 / 수정
 */
const OrderPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);    

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
        guid = ls.guid, // groupUid 
        puid = ls.puid,
        amt = ls.amt,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);

    const [prodProcDtlItems, setProdProcDtlItems] = useState([]);
    /** Items */
    
    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [prodUid, setProdUid] = useState(util.getChkNull(puid) === '' ? '' : puid);
    const [prodNm, setProdNm] = useState('');
    const [prodProcUid, setProdProcUid] = useState('');
    const [userUid, setUserUid] = useState(authStore.user.uid);

    const [prodImgUid, setProdImgUid] = useState('');
    const [prodImgFile, setProdImgFile] = useState('');

    const [orderItems, setOrderItems] = useState([]);
    const [orderAmt, setOrderAmt] = useState(util.getChkNull(amt) === '' ? 0 : Number(amt));
    const [payDy, setPayDy] = useState(mode === 'a' ? util.getNow() : '');
    const [orderDy, setOrderDy] = useState(mode === 'a' ? util.getNow() : '');
    const [cmnt, setCmnt] = useState('');

    const [orderPrice, setUnitPrice] = useState(0);
    const [prodId, setProdId] = useState('');
    const [totPrice, setPrice] = useState(0);

    const [procNm, setProcNm] = useState('');
    const [userNm, setUserNm] = useState('');

    const [lastYn, setLastYn] = useState('');
    const [isDel, setIsDel] = useState('');

    //이전정보
    // const [preOrderUid, setPreOrderUid] = useState('');
    // const [preOrderNum, setPreOrderNum] = useState('');
    // const [preProdProcUid, setPreProdProcUid] = useState('');
    // const [preProdNm, setPreProdNm] = useState('');
    // const [preBizNmOrderAmt, setPreBizNmOrderAmt] = useState('');
    // const [preProdUid, setPreProdUid] = useState('');
    
    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadProdInfo();
    },[prodUid]);
    
    useEffect(() => {
        !pageLoad && loadProdProcDtlCombo();
    },[prodProcUid]);

    // useEffect(() => {
    //     !pageLoad && loadProdProcInfo();
    // },[prodProcUid]);

    useEffect(() => {
        !pageLoad && loadPrice();
    },[orderAmt, orderPrice]);
    
    useEffect(() => {
        !pageLoad && loadUnitPrice();
    },[bizUid, prodProcDtlItems]);

    // useEffect(() => {
    //     !pageLoad && loadPreOrderInfo('proc');
    // },[preProdProcUid]);
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadInfo1 = async() => {
        if(util.getChkNull(mode) === 'a') return;
        if(util.getChkNull(uid) === '') return;

        try {

            let r = await cmn.api('getOrder', {p:{orderUid: uid}});
            if(r == null || r === undefined) return;

            let data = r.d0[0];

       //     console.log(data);
            setPayDy(util.getDateStrSplit('d', data.payDy));
            setOrderDy(util.getDateStrSplit('d', data.orderDy));
            setBizNm(data.bizNm);
            setProdNm(data.prodNm);
            setProcNm(data.procNm);
            setUserNm(data.userNm);
            setOrderAmt(data.orderAmt);
            setCmnt(data.cmnt);
            setIsDel(data.isDel);
            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
  
    const pageInit = () => {
        setSelectedItem(null);
        setProdProcDtlItems([]);
        setBizUid('');
        setBizNm('');
        setProdUid('');
        setProdNm('');
        setProdProcUid('');
        setProcNm('');
        setProdImgUid('');
        setProdImgFile('');
        setOrderAmt(0);
        setPayDy(util.getNow());
        setOrderDy(util.getNow());
        setCmnt('');
        setUnitPrice(0);
        setProdId('');
        setPrice(0);

        // setPreOrderUid('');
        // setPreOrderNum('');
        // setPreProdProcUid('');
        // setPreProdNm('');
        // setPreBizNmOrderAmt('');
        // setPreProdUid('');
        /** Items */
    }

    const loadProdProcDtlCombo = async() => {
        if(util.getChkNull(prodProcUid) === '') return;
        //alert(e);
        const ps = {
            p: {
                    prodProcUid: prodProcUid
                    ,procTp: '10401' // 구매업체만 조회
                }
        }
        
        let r = await cmn.api('getProdProcDtlCombo', ps);
        if(r == null || r === undefined) return;

        let data = r.d0
        _.forEach(data, (v, k) => {
            if(v.defBizYn === 'Y')
                setBizUid(v.bizUid);
        })
        
        setProdProcDtlItems(data);
    }

    const loadPrice = () => {
        if(util.getChkNull(orderAmt, 0) === 0
        || util.getChkNull(orderPrice, 0) === 0) {
            setPrice(0);
            return;
        }

        setPrice(orderAmt * orderPrice);  
    }

    const loadProdInfo = async() => {
        if(util.getChkNull(prodUid, '') === '') return;

        let r = await cmn.api('getProd', {p:{prodUid:prodUid}});
        if(r == null || r === undefined) return;
        
        const data = r.d0[0];
        
        setProdId(data.prodId);
        setProdNm(data.prodNm);

        setProdImgUid(data.prodImgUid);
        setProdImgFile(data.prodImgFile);

        setProdProcUid(data.firstProdProcUid);
        setProcNm(data.firstProcNm);
        setLastYn(data.lastYn);
    }

    const loadUnitPrice = () => {
        let prodProcDtlItems_ = [...prodProcDtlItems];
     //   console.log('prodProcDtlItems_', prodProcDtlItems_);
        let o = _.find(prodProcDtlItems_, {bizUid: bizUid});
        setUnitPrice(util.getVal(o , 'procPrice'));
        setBizNm(util.getVal(o , 'bizNm'));
    }
    
    /* ====================
     *  Handle / Functions
     * ==================== */

     
    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    
    const handleClickCrtRows = () => {
        //validation
        if(!util.chkMustValue(prodUid, '제품'))
            return;
        if(!util.chkMustValue(prodProcUid, '공정'))
            return;
        if(!util.chkMustValue(bizUid, '협력사'))
            return;
        if(!util.chkMustValue(orderAmt, '발주량', 'cnt'))
            return;
            
        const orderItems_ = [...orderItems];

        orderItems_.push(
        {
            orderUid: util.getUUID(),
            orderDy: util.getDateToString('d',orderDy),
            payDy: util.getDateToString('d',payDy),
            prodUid:prodUid,
            orderNum: '',
            prodNm: prodNm,
            prodProcUid: prodProcUid,
            bizUid: bizUid,
            bizNm: bizNm,
            orderAmt: orderAmt,
            orderPrice:orderPrice,
            totPrice: orderAmt * orderPrice,
            prodImgUid: prodImgUid,
            prodImgFile: prodImgFile,
            userUid: userUid,
            lastYn: lastYn,
            cmnt: cmnt
        });

       // console.log(orderItems_);
        setOrderItems(orderItems_);

        pageInit();
    }

    const handleClickDelRows = () => {
        
        if(util.chkListSelected(selectedItem)){
            if(util.confirmYn('삭제'))
            {
                const orderItems_ = _.remove(orderItems, (v) => {
                    return selectedItem.orderUid !== v.orderUid;
                });
        
                setOrderItems(orderItems_);
            }
        }

    }

    // const handleClickRepo = () => {
    //     const url = `/order/orderrepo?${qs.stringify({guid: guid})}`;        
    //     util.openWindow(url, 820, 840);  
    // }

    const handleClickComplete = (e) => {
        try {

            if(mode === 'a')
            {
                if(orderItems.length <= 0)
                {
                    util.cmnAlert('발주 정보가 없습니다.');
                    return;
                }

                if(util.confirmYn("등록"))
                    addOrder();
            }else if(mode === 'v')
            {
                delOrder(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addOrder = async() => {

        const orders_ = []
        let groupUid = util.getUUID();
        for(let i =0; i < orderItems.length; i ++)
        {
            let v = orderItems[i];
            const workNumPara_ = {
                p:{
                    comUid: authStore.com.uid,
                    prodUid: v.prodUid,
                    workDy: util.getDateToString('d', util.getNow()),
                    firstAmt: v.orderAmt,
                    inputTp: v.lastYn === 'Y' ? cmn.CMNCO_WORKNUMTP.ORDER : cmn.CMNCO_WORKNUMTP.ORDER_WORK,
                    prefix: cmn.PREFIX.PURCHASE
                }
            }

            orders_.push({
                orderUid: v.orderUid,
                comUid: authStore.com.uid,
                bizUid: v.bizUid,
                orderNum: v.orderNum === '' ? await cmnStore.getWorkNumCrt(workNumPara_) : v.orderNum,
                prodUid: v.prodUid,
                orderAmt: v.orderAmt,
                payDy: v.payDy,
                orderDy: v.orderDy,
                cmnt: v.cmnt,
                prodProcUid: v.prodProcUid,
                userUid: v.userUid,
                orderPrice: v.orderPrice,
                prodImgUid: v.prodImgUid,
                groupUid: groupUid,
                uptUserUid: authStore.user.uid
            });

        }

        const ps = {
            p : [...orders_]
        }

        //console.log(ps);
        const r = await cmn.api('addOrder', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

         // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    
    const delOrder = async(delCmnt) => {
        if(util.getChkNull(uid) === '') return;

        try 
        {
            const ps =  {
                p: {
                    orderUid: uid,
                    groupUid: '',
                    delCmnt : delCmnt,
                    uptUserUid : authStore.user.uid
                }
            }

            let r = await cmn.api('delOrder', ps);
            if(r == null || r === undefined) return;

            util.cmnSucAlert('취소');

            // Reload Opener List & Close Popup
            util.clickOpenerElement("srchButton");
            window.close();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

    //    console.log(orderDy);
        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "v" ? "취소완료" 
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
            {mode === "v" ?
                <CancelButton
                    title={completeButtonTitle}
                    isDel={isDel}
                    isDelMsg={'입고 내역이 있으므로 취소할 수 없습니다.\n입고 내역을 취소하신 후 진행하여 주시기 바랍니다.'}
                    handleClickComplete={(e) => {
                        handleClickComplete(e)
                    }} 
                />
                :
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">발주일</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                    <DayDateBox 
                                        value={orderDy}
                                        onValueChanged={(e) => {
                                            setOrderDy(e.value);
                                        }}                    
                                    />
                                :
                                    <span>{orderDy}</span>
                                }
                            </td>
                            <th className="tit">납기일</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                <DayDateBox 
                                    value={payDy}
                                    onValueChanged={(e) => {
                                        setPayDy(e.value);
                                    }}                    
                                />    
                                :
                                    <span>{payDy}</span>
                                }                            
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">제품선택</th>
                            <td className="tcon" >
                                { 
                                mode === "a" ?
                                <ProdSearchBox
                                    value={prodUid}
                                    popCenter={true}
                                    onLoadInfo={(o) => {
                                    }} 
                                    onValueChanged={(v) => {                                        
                                        setProdUid(v);
                                    }}
                                />
                                :
                                    <span>{prodNm}</span>
                                }
                            </td>
                            <th className="tit">발주자</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                    <UserSelectBox
                                        mode={mode}
                                        value={userUid}
                                        placeholder="발주자를 선택하세요"
                                        onValueChanged={(v) => { 
                                            setUserUid(v);
                                        }}
                                    />
                                :
                                <span>{userNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">공정</th>
                            <td className="tcon">
                                {/* { 
                                mode === "a" ?
                                <ProdProcSelectBox
                                    value={prodProcUid}
                                    //inYn={'Y'} // 사내공정 데이터만 조회
                                    procTp={'10401'} // 구매 공정만 조회
                                    prodUid={prodUid}
                                    eqProcTpYn={'Y'}
                                    onLoadInfo={(o) => {
                                        setLastYn(o.lastYn);
                                    }} 
                                    onValueChanged={(v) => { 
                                        setProdProcUid(v);
                                    }}
                                />
                                : */}
                                    <span>{procNm}</span>
                                {/* } */}
                            </td>
                            <th className="tit req">협력사</th>
                            <td className="tcon">
                                { 
                                mode === "a" ?
                                <SelectBox 
                                    //value={prodBizPriceUid}
                                    value={bizUid}
                                    dataSource={prodProcDtlItems}
                                    displayExpr={'bizNm'}
                                    valueExpr={'bizUid'}
                                    placeholder="협력사를 선택하세요"
                                    onValueChanged={(e)=>{
                                        setBizUid(e.value);
                                    }}
                                />
                                :
                                    <span>{bizNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">발주량</th>
                            <td className="tcon" colSpan={3} >
                                <div className="inline-block">
                                    { 
                                    mode === "a" ?
                                        <NumberBox
                                            width={80}
                                            format={'#,##0.###'}
                                            value={orderAmt}
                                            defaultValue={0}
                                            min={0}
                                            // max={20}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setOrderAmt(e.value);
                                            }}
                                        />
                                        :
                                        <span>{orderAmt}</span>
                                    }
                                    </div>
                                    <div className="inline-block mar-left-10">
                                    X (단가:  <strong>{util.getNumComma(orderPrice)}</strong> 원)   
                                    = (금액:  <strong>{util.getNumComma(totPrice)}</strong> 원)    
                                </div>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                { 
                                mode === "a" ?
                                    <TextBox 
                                        value={cmnt}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setCmnt(e.value);
                                        }}
                                    /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>

            { 
            mode === "a" ?
                <div className="pop-grid">
                    <div className="clearfix">
                        <div className="btn-area fl-r">
                            <SubActionButton 
                                onClick={handleClickCrtRows}
                                title="추가"
                                show={true}
                            /> 
                            <SubActionButton 
                                onClick={handleClickDelRows}
                                title="삭제"
                                marginRight={0}
                                show={true}
                            /> 
                        </div>
                    </div>
                    <div className="clearfix" style={{marginTop:'10px'}}>
                        <DataGridView
                            onSelectionChanged={handleGridSelectionChanged}     
                            showNo={false}
                            dataSource={orderItems}
                            gridHeight={180}
                            colsWidth={[80, 80, 150, 100, 100, 100, 100, null]}  
                        >
                            { cmn.getGridColumnDayType('orderDy', '발주일') }
                            { cmn.getGridColumnDayType('payDy', '납기일') }
                            { cmn.getGridColumn('prodNm', '제품명')}
                            { cmn.getGridColumn('bizNm', '협력사')}
                            { cmn.getGridColumnNumCommaType('orderAmt', '발주량') }
                            { cmn.getGridColumnNumCommaType('orderPrice', '단가') }
                            { cmn.getGridColumnNumCommaType('totPrice', '금액') }
                            { cmn.getGridColumnCmnt() }
                            
                        </DataGridView>
                    </div>
                </div>  
            :
                ''
            }        
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "발주 등록" 
                : mode === "v" ? "발주 상세보기"
                : "발주" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default OrderPopPage;
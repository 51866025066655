import React, { useState, useEffect, useContext } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    InspSampBox, DateTimeBox, DayDateBox,
    CodeSelectBox, ChkCycleChkListBox, UseYnSwitchBox,
    PopupLayout, CheckListBox, ActionButton, FileUploadBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  측정기 등록 / 수정
 */
const GaugePopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    // const [gaugeUid, setGaugeUid] = useState(uid);

    const [gaugeTp, setGaugeTp] = useState('');
    const [gaugeTpNm, setGaugeTpNm] = useState('');

    const [calibCycle, setCalibCycle] = useState(0);

    const [gaugeCd, setGaugeCd] = useState('');
    const [gaugeNm, setGaugeNm] = useState('');
    const [modelNm, setModelNm] = useState('');    
    const [serialNo, setSerialNo] = useState('');    

    const [maker, setMaker] = useState('');
    const [useYn, setUseYn] = useState('Y');

    const [buyDy, setBuyDy] = useState('');
    const [saveLoca, setSaveLoca] = useState('');    
    const [cmnt, setCmnt] = useState('');    

    const [imgFileUid, setImgFileUid] = useState(util.getUUID());
    const [imgFileInfo, setImgFileInfo] = useState('');
    const [imgFiles, setImgFiles] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);
  
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {   
            if(mode == 'a') return;
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getGauge", {p: {gaugeUid: uid}} );
            if(r == undefined || r == null) return;

            const d1 = r.d1[0];

            // setGaugeUid(d1.gaugeUid);
            setGaugeTp(d1.gaugeTp);
            setGaugeTpNm(d1.gaugeTpNm);
            setGaugeCd(d1.gaugeCd);
            setGaugeNm(d1.gaugeNm);
            setMaker(d1.maker);
            setModelNm(d1.modelNm);
            setSerialNo(d1.serialNo);
            setBuyDy(d1.buyDy);
            setSaveLoca(d1.saveLoca);
            setCalibCycle(d1.calibCycle);
            setUseYn(d1.useYn);
            setCmnt(d1.cmnt); 
            setImgFileUid(d1.imgFileUid  == null ? util.getUUID() : d1.imgFileUid);

            setImgFiles(r.f1);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(gaugeCd, '측정기코드')) return;
            if(!util.chkMustValue(gaugeNm, '측정기명')) return;
            if(!util.chkMustValue(calibCycle, '교정주기', 'cnt')) return;
            if(!util.chkMustValue(maker, '제조사')) return;
    
            if(mode == "a") { 
                if(!util.confirmYn('등록')) return;
                addGauge();
            }
            else if(mode == "u") { 
                if(!util.confirmYn('수정')) return;
                upGauge();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addGauge = async() => {
        const ps = {
            p: 
                {
                    gaugeUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    gaugeTp: gaugeTp,
                    gaugeCd: gaugeCd,
                    gaugeNm: gaugeNm,
                    maker: maker,
                    modelNm: modelNm,
                    serialNo: serialNo,
                    buyDy: util.getDateToString('d',buyDy),
                    saveLoca: saveLoca,
                    calibCycle: calibCycle,
                    useYn: useYn,
                    cmnt: cmnt,
                    imgFileUid: imgFileUid,
                    imgFiles: imgFiles,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("addGauge", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upGauge = async() => {
        const ps = {
            p: 
                {
                    gaugeUid: uid,
                    gaugeTp: gaugeTp,
                    gaugeCd: gaugeCd,
                    gaugeNm: gaugeNm,
                    maker: maker,
                    modelNm: modelNm,
                    serialNo: serialNo,
                    buyDy:  util.getDateToString('d',buyDy),
                    saveLoca: saveLoca,
                    calibCycle: calibCycle,
                    useYn: useYn,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("upGauge", ps);
        if(r == undefined || r == null) return;
 
        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode == "a" ? "등록완료" 
            : mode == "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={120} />
                        <col className="tcon" width={260} />
                    </colgroup>
                    <tbody>
                        
                        <tr>                           
                            <th className="tit req">측정기명</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        value={gaugeNm}
                                        showClearButton={true}
                                        // placeholder=""
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setGaugeNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{gaugeNm}</span>
                            }
                            </td>
                            <td className="tcon" rowSpan={6}>
                                <div className="div-img-100" style={{minHeight: 240}}>
                                { 
                                    imgFileInfo != '' ?                                                
                                    cmn.getRenderImg(imgFileInfo, 240) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">측정기코드</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        value={gaugeCd}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setGaugeCd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{gaugeCd}</span>
                            }
                            </td>
                            
                        </tr>
                        <tr>
                            <th className="tit">측정기유형</th>
                            <td className="tcon">
                            {
                                mode == "a" || "u" ?
                                    <CodeSelectBox
                                        value={gaugeTp}
                                        classId={'405'}
                                        placeholder="측정기유형을 선택하세요."
                                        onValueChanged={(v, o) => { 
                                            setGaugeTp(v);
                                        }}
                                    />                                    
                                    :
                                    <span>{gaugeTpNm}</span>
                            }
                            </td>
                            <th className="tit req">교정주기</th>
                            <td className="tcon">
                            {
                                mode == "a" || "u" ?
                                <ul className="ul-row">
                                    <li>
                                    <NumberBox
                                        width={80}
                                        format={'#,##0'}
                                        value={calibCycle}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setCalibCycle(e.value);
                                        }} 
                                    />
                                    </li>
                                    <li>
                                        (년)
                                    </li>
                                </ul>
                                :
                                    <span>{calibCycle}(년)</span>
                            }
                            </td>
                        </tr>                        
                        <tr>
                            <th className="tit req">제조사</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        value={maker}
                                        showClearButton={true}
                                        // placeholder=""
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setMaker(e.value);
                                        }} 
                                    />
                                :
                                    <span>{maker}</span>
                            }
                            </td>
                            
                        </tr>   
                        <tr>
                            <th className="tit">S/N</th>
                            <td className="tcon" colSpan={3}>                                
                            {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        value={serialNo}
                                        showClearButton={true}
                                        // placeholder=""
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setSerialNo(e.value);
                                        }} 
                                    />
                                :
                                    <span>{serialNo}</span>
                            }
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit">구입일자</th>
                            <td className="tcon">
                            {
                                mode == "a" || "u" ?
                                <DayDateBox 
                                    value={buyDy}
                                    type={'day'}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setBuyDy(e.value);
                                    }}                    
                                />
                                :
                                    <span>{buyDy}</span>
                            }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon">
                            {
                                mode == "a" || "u" ?
                                <UseYnSwitchBox 
                                    value={useYn}
                                    onValueChanged={(v)=>{
                                        setUseYn(v);
                                    }} 
                                />
                                : 
                                    <span>{useYn}</span>
                            }
                            </td>
                        </tr>    
                        <tr>
                            <th className="tit">보관위치</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        value={saveLoca}
                                        showClearButton={true}
                                        // placeholder=""
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setSaveLoca(e.value);
                                        }} 
                                    />
                                :
                                    <span>{saveLoca}</span>
                            }
                            </td>    
                            <td className="tcon" style={{verticalAlign: "top"}} rowSpan={2}>
                                <FileUploadBox
                                    limit={1}
                                    // width={100}
                                    linkWidth={200}
                                    grpUid={imgFileUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={imgFiles}
                                    labelText=''
                                    onLoadInfo={(e) => {
                                        const fi = cmn.getUploadFileInfoStr(e);
                                        setImgFileInfo(fi);
                                    }}
                                    onUploaded={(e) => { 
                                        setImgFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setImgFiles(e);
                                    }}
                                />       
                            </td>                  
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || "u" ?
                                    <TextBox 
                                        with={"100%"}
                                        value={cmnt}
                                        showClearButton={true}
                                        // placeholder=""
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setCmnt(e.value);
                                        }} 
                                    />
                                :
                                    <span>{cmnt}</span>
                            }
                            </td>                                                   
                        </tr>                                            
                    </tbody>
                </table>
            </div>
      
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "측정기 등록" 
                : mode == "u" ? "측정기 수정"
                : "측정기" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default GaugePopPage;
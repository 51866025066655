const deliApiz = {
    
    getDeliList: {
        title: "납품 목록 조회",
        url: "/api/deli/getDeliList",
        method: "POST"
    },
    getDeli: {
        title: "납품 상세 조회",
        url: "/api/deli/getDeli",
        method: "POST"
    },
    addDeli: {
        title: "납품 등록",
        url: "/api/deli/addDeli",
        method: "POST"
    },
    upDeli: {
        title: "납품 수정",
        url: "/api/deli/upDeli",
        method: "POST"
    },
    delDeli: {
        title: "납품 삭제",
        url: "/api/deli/delDeli",
        method: "POST"
    },
    getLotListDeli: {
        title: "납품 대기 LOT 조회",
        url: "/api/deli/getLotListDeli",
        method: "POST"
    },
    getDeliListStndBy: {
        title: "납품 대기 LOT 조회",
        url: "/api/deli/getDeliListStndBy",
        method: "POST"
    },
    getLotListDeliSales: {
        title: "납품 대기 LOT 조회(수주기반)",
        url: "/api/deli/getLotListDeliSales",
        method: "POST"
    },
    getDeliListRet: {
        title: "고객 매출 현황 조회",
        url: "/api/deli/getDeliListRet",
        method: "POST"
    },
    getDeliListPrint: {
        title: "거래명세용 납품 목록 조회",
        url: "/api/deli/getDeliListPrint",
        method: "POST"
    },
    getDeliPrint: {
        title: "거래명세용 납품 목록 조회 (납품번호)",
        url: "/api/deli/getDeliPrint",
        method: "POST"
    },
    getDeliNum: {
        title: "신규 납품번호 조회",
        url: "/api/deli/getDeliNum",
        method: "POST"
    },
    
}

export default deliApiz;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  재료출고 관리
 */
const OutPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
   
    // * Control Items (* Combo ...)

    // * 검색
    const [workNum, setWorkNum] = useState('');
    const [lot, setLot] = useState('');
    const [outStatTp, setOutStatTp] = useState('30801');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "작업번호",
                width: "200px",
                item: 
                <TextBox 
                    value={workNum}
                    showClearButton={true}
                    placeholder="작업번호 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setWorkNum(e.value);
                    }}
                />    
            },
            {   title: "출고 Lot",
                width: "200px",
                item: 
                <TextBox 
                    value={lot}
                    showClearButton={true}
                    placeholder="출고 Lot 로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setLot(e.value);
                    }}
                />          
            },
            {   title: "출고상태",
                item: 
                <CodeSelectBox
                    value={outStatTp}
                    width={200}
                    classId={'308'}
                    placeholder="출고상태"
                    onValueChanged={(v) => { 
                        setOutStatTp(v);
                    }}
                />
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [outList, setOutList] = useState([]);
    const [outListTot, setOutListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, outStatTp]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            // 권한조회
            await authStore.loadPage(props);
            
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                workNum: workNum,
                lot:lot,
                outStatTp:outStatTp,

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }
        const r = await cmn.api("getOutList", ps );
        if(r === undefined || r == null) return;

        setOutList(r.d0)
        setOutListTot(r.tot);
    }

    /* ====================
     *  Handle
     * ==================== */

    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // 재료출고 등록
    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    // // 재료출고 수정
    // const handleClickUp = (e) => {
    //     if(util.chkListSelected(selectedItem)){
    //         openCrudPopup("u", selectedItem.outUid);
    //     }
    // }

    // 재료출고 상세보기
    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.outUid, selectedItem.workNum);
        }
    }

    // * Data Grid
    // Row 클릭 
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    // 데이타 정렬
    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    // Paging 클릭
    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    /* ====================
     *  Functions
     * ==================== */

    const openCrudPopup = (mode, outUid, workNum) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/stock/outpop?${qs.stringify({mode: mode, uid: outUid, wn:workNum})}`;        
        util.openWindow(url, 600, 800);    
    }


    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <Observer>{() =>
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView title="재료출고 내역">
            </GridTopView>     
            <DataGridView            
                dataSource={outList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={outListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}                

                colsWidth={[100, 120, 150, 120, 120, 120, 90, 100, 100, null]}
            >
                { cmn.getGridColumnDayType('outDy', '출고일자') }   
                { cmn.getGridColumn('workNum', '작업번호', renderGridHeaderCell) }   
                { cmn.getGridColumn('manuProdNm', '생산품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '출고품번') }   
                { cmn.getGridColumn('prodNm', '출고품명') }   
                { cmn.getGridColumn('lot', '출고 Lot') }   
                { cmn.getGridColumn('outAmt', '출고수량') }   
                { cmn.getGridColumn('userNm', '출고자') }   
                { cmn.getGridColumn('outStatTpNm', '출고상태') }   
                { cmn.getGridColumn('cmnt', '비고') }  
            </DataGridView>
            </>
            }</Observer>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default OutPage;
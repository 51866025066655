import axios from 'axios';
import queryString from 'query-string';

/** stores & lib */
import { cmn } from './';
import { RCODE_S, RCODE_E } from './resCode';

const sapi = {

    send: async(api, para) => {
        let ret = null;
        let res = null;

        // SetHeader
        const user = cmn.getUser();
        const com = cmn.getCom();
        const token = cmn.getToken();

        const headers = {
            'Content-Type': 'application/json', 
            'com-uid': (com != undefined && com != null) ? com.uid : '',
            'user-uid': (user != undefined && user != null) ? user.uid : '',
            'gfac-token': token
        }; 

        // Send Api
        try{
            
            console.log(">>>>> send api", api.url, para);
            
            if(api.method == "GET"){
                res = await axios.get(`${cmn.env.SERVER_ENV === 'development' ? '' : com.url}${api.url}?${queryString.stringify(para)}`, {headers});
            }
            else if(api.method == "POST"){            
                res = await axios.post(`${cmn.env.SERVER_ENV === 'development' ? '' : com.url}${api.url}`, para, {headers});
            }
            
            cmn.setToken(res.headers["gfac-token"]);

            console.log("<<<<< res api", api.url, res);
        }
        catch(err){
            throw new Error("서버와 통신이 원활하지 않습니다.\r\n관리자에게 문의하시기 바랍니다.");
        }

        // Check Status
        if(res.status != 200){
            // throw res.statusText;
            throw new Error("서버와 통신이 원활하지 않습니다.\r\n관리자에게 문의하시기 바랍니다.");
        }

        ret = res.data;
        return ret;
    }

}

export default sapi;

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

// ** ie compatibility
import 'react-app-polyfill/ie9'; 
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/symbol';
import 'core-js/stable';

import { polyfill } from 'es6-promise';

// **
// import 'core-js/features/array/find';
// import 'core-js/features/array/includes';
// import 'core-js/features/number/is-nan';

// import 'core-js/fn/symbol';
// import 'core-js/fn/array';

// import 'core-js/fn/promise';
// import 'core-js/fn/object';
// import 'core-js/fn/string';
// import 'core-js/fn/array';

/** css */
import './css/styles.scss';

polyfill();

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextArea, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PrintLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  부적합 개선카드
 */
const BadImprRepoPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        uid = ls.uid,
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);
    const [badImprUid, setBadImprUid] = useState(uid);

    const [badImprCd, setBadImprCd] = useState('');
    const [happDy, setHappDy] = useState(util.getNow());
    const [receDy, setReceDy] = useState(util.getNow());
    const [badImprTitle, setBadImprTitle] = useState('');
    const [mngrUid, setMngrUid] = useState('');
    const [imprMngrNm, setImprMngrNm] = useState('');


    // const [prodProcUid, setProdProcUid] = useState(ppuid);
    const [prodStnd, setProdStnd] = useState('');

    const [prodUid, setProdUid] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [procNm, setProcNm] = useState('');

    const [prodProcUid, setProdProcUid] = useState('');
    const [equpUid, setEqupUid] = useState('');
    const [equpNm, setEqupNm] = useState('');

    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');

    const [workNum, setWorkNum] = useState('');
    const [lot, setLot] = useState('');    

    const [manuAmt, setManuAmt] = useState(null);
    const [badAmt, setBadAmt] = useState(null);

    const [unitTpNm, setUnitTpNm] = useState('');
    const [badImprTpNm, setBadImprTpNm] = useState('');

    const [badTp, setBadTp] = useState('');
    const [badTpNm, setBadTpNm] = useState('');
    const [badCauseTp, setBadCauseTp] = useState('');
    const [badCauseTpNm, setBadCauseTpNm] = useState('');
    const [badTreatTp, setBadTreatTp] = useState('');
    const [badTreatTpNm, setBadTreatTpNm] = useState('');
    const [claimYn, setClaimYn] = useState('');
    const [claimCost, setClaimCost] = useState(0);
    const [cmnt, setCmnt] = useState('');
    const [badImprCont, setBadImprCont] = useState('');

    const [blockCompYn, setBlockCompYn] = useState('');
    const [corrCompYn, setCorrCompYn] = useState('');
    const [corrChkCompYn, setCorrChkCompYn] = useState('');

    const [originFileUid, setOriginFileUid] = useState(util.getUUID());
    const [originFiles, setOriginFiles] = useState([]);
    const [imgFileUid, setImgFileUid] = useState(util.getUUID());
    const [imgFiles, setImgFiles] = useState([]);

    // ** 격리조치
    let [blocks, setBlocks] = useState([]);   // 격리조치
    const blockGrid = useRef(null); // 격리조치 Grid

    // ** 시정조치
    const [badImprCorrUid, setBadImprCorrUid] = useState('');
    const [actDy, setActDy] = useState('');
    const [detpNm, setDetpNm] = useState('');
    const [mngrNm, setMngrNm] = useState('');
    const [actTitle, setActTitle] = useState('');

    const [corrFileUid, setCorrFileUid] = useState(util.getUUID());
    const [corrFiles, setCorrFiles] =  useState([]);

    const [actBefCont, setActBefCont] = useState('');
    const [actAftCont, setActAftCont] = useState('');

    const [actBefImgUid, setActBefImgUid] = useState(util.getUUID());
    const [actBefImgFileInfo, setActBefImgFileInfo] = useState('');
    const [actBefImgs, setActBefImgs] = useState([]);

    const [actAftImgUid, setActAftImgUid] = useState(util.getUUID());
    const [actAftImgFileInfo, setActAftImgFileInfo] = useState('');
    const [actAftImgs, setActAftImgs] = useState([]);

    // ** 시정조치 검증
    const [corrChks, setCorrChks] = useState([]);
    const corrChkGrid = useRef(null); // 격리조치 Grid

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        if(util.getChkNull(uid) == '') return;

        const r = await cmn.api("getBadImpr", {p: {badImprUid: uid}} );
        if(r == undefined || r == null) return;

        const d1 = r.d1[0];
        
        setBadImprCd(d1.badImprCd);
        setBadImprTpNm(d1.badImprTpNm);

        setHappDy(d1.happDy);
        setReceDy(d1.receDy);
        setBadImprTitle(d1.badImprTitle);
        setMngrUid(d1.mngrUid);
        setProdUid(d1.prodUid);
        setProdProcUid(d1.prodProcUid);
        setEqupUid(d1.equpUid);
        setBizUid(d1.bizUid);
        setLot(d1.lot);
        setManuAmt(d1.manuAmt);
        setBadAmt(d1.badAmt);
        setBadTp(d1.badTp);
        setBadCauseTp(d1.badCauseTp);
        setBadTreatTp(d1.badTreatTp);
        setClaimYn(d1.claimYn);
        setClaimCost(d1.claimCost);
        setCmnt(d1.cmnt);
        setBadImprCont(d1.badImprCont);
        setBlockCompYn(d1.blockCompYn);
        setCorrCompYn(d1.corrCompYn);
        setCorrChkCompYn(d1.corrChkCompYn);
        // setOriginFileUid(d1.originFileUid  == null ? util.getUUID() : d1.originFileUid);
        // setImgFileUid(d1.imgFileUid  == null ? util.getUUID() : d1.imgFileUid);

        setProdId(d1.prodId);
        setProdNm(d1.prodNm);
        setProcNm(d1.procNm);

        setWorkNum(d1.workNum);
        setImprMngrNm(d1.imprMngrNm);
        setEqupNm(d1.equpNm);
        setBizNm(d1.bizNm);

        setBadTpNm(d1.badTpNm);
        setBadCauseTpNm(d1.badCauseTpNm);
        setBadTreatTpNm(d1.badTreatTpNm);
        setUnitTpNm(d1.unitTpNm);

        // setBadImprCorrUid(d1.badImprCorrUid);
        setActDy(d1.actDy);
        setDetpNm(d1.detpNm);
        setMngrNm(d1.mngrNm);
        setActTitle(d1.actTitle);
        // setCorrFileUid(d1.corrFileUid == null ? util.getUUID() : d1.corrFileUid);
        setActBefCont(d1.actBefCont);
        setActAftCont(d1.actAftCont);
        // setActBefImgUid(d1.actBefImgUid == null ? util.getUUID() : d1.actBefImgUid);
        // setActAftImgUid(d1.actAftImgUid == null ? util.getUUID() : d1.actAftImgUid);

                    
        setBlocks(r.d2);
        setCorrChks(r.d3);

        setOriginFiles(r.f1);
        setImgFiles(r.f2);
        setCorrFiles(r.f3);
        setActBefImgs(r.f4);
        setActBefImgFileInfo(cmn.getUploadFileInfoStr(r.f4));
        setActAftImgs(r.f5);
        setActAftImgFileInfo(cmn.getUploadFileInfoStr(r.f5));
    } 

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {        
            printPage();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const printPage = async() => {

        window.print();
    }

    const customizeTooltip = (arg) => {
        return { text: arg.valueText };
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        
        // const badImprCont_ = _.replace(badImprCont, /(?:\r\n|\r|\n)/g, "<br />");
        // console.log("=== badImprCont_", badImprCont_);

        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div className="print_type"
                style={{ padding: "20px 20px 0 20px", backgroundColor: "#ffffff" }}
            >
                
                <table>
                    <colgroup>
                        <col width={100}/>
						<col />
                        <col width={100}/>
						<col width={130}/>
                        <col width={100}/>
						<col width={130}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">부적합코드</th>
                            <td className="tcon">
                                <span style={{ fontWeight: 'bold' }}>{badImprCd}</span>
                                {
                                util.getChkNull(badImprTpNm) != '' &&
                                <span>&nbsp;&nbsp;&nbsp;{`(${badImprTpNm})`}</span>
                                }
                            </td>
                            <th className="tit">발생일자</th>
                            <td className="tcon">
                                <span>{util.getDateStrSplit('d',happDy)}</span>
                            </td>                           
                            <th className="tit">접수일자</th>
                            <td className="tcon">
                                <span>{util.getDateStrSplit('d',receDy)}</span>
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">부적합제목</th>
                            <td className="tcon" style={{textAlign: "left"}} colSpan={3}>
                                <span>{badImprTitle}</span>
                            </td>
                            <th className="tit">담당자</th>
                            <td className="tcon">
                                <span>{imprMngrNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제품</th>
                            <td className="tcon">
                                <span>{cmn.getDispProd(prodId, prodNm)}</span>
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                {prodId}
                            </td>                           
                            <th className="tit">규격</th>
                            <td className="tcon">
                                {prodStnd}
                            </td>  
                        </tr>  
                    </tbody>
                </table>
                <table>
                    <colgroup>
                        <col width={100}/>
						<col />
                        <col width={100}/>
						<col width={130}/>
                        <col width={100}/>
						<col width={130}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">발생공정</th>
                            <td className="tcon">
                                <span>{procNm}</span>  
                            </td>
                            <th className="tit">설비</th>
                            <td className="tcon">
                                <span>{equpNm}</span>
                            </td>                           
                            <th className="tit">업체</th>
                            <td className="tcon">
                                <span>{bizNm}</span>
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">Lot</th>
                            <td className="tcon">
                                <span>{lot}</span>  
                            </td>
                            <th className="tit">생산수량</th>
                            <td className="tcon">
                                <span>{`${util.getNumComma(manuAmt)} ${unitTpNm}`}</span>
                            </td>
                            <th className="tit">불량수량</th>
                            <td className="tcon">
                                <span>{`${util.getNumComma(badAmt)} ${unitTpNm}`}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">부적합 유형</th>
                            <td className="tcon">
                                <span>{badTpNm}</span>  
                            </td>
                            <th className="tit">부적합 원인</th>
                            <td className="tcon">
                                <span>{badCauseTpNm}</span>
                            </td>                           
                            <th className="tit">처리 결과</th>
                            <td className="tcon">
                                <span>{badTreatTpNm}</span>
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">클레임</th>
                            <td className="tcon">
                            {
                                claimYn == 'Y' ?
                                <span>{`비용: ${util.getNumComma(claimCost)} (원)`}</span>
                                :
                                <span>없음</span>
                            }
                            </td>
                            <th className="tit">비고</th>
                            <td className="tcon" style={{textAlign: "left"}} colSpan={3}>
                                <span>{cmnt}</span>
                            </td>                              
                        </tr>      
                    </tbody>
                </table>

                <table>
                    <colgroup>
                        <col width={100} />
						<col/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">부적합 내용</th>
                            <td className="tcon">
                                <TextArea
                                    minHeight={100}
                                    // width={500}
                                    value={badImprCont}
                                    // placeholder="부적합 내용 입력하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setBadImprCont(e.value);
                                    }}
                                />
                            </td>                                                     
                        </tr>             
                    </tbody>
                </table>
                {/* <div className="endline"></div>
                <br style={{height:0, lineHeight: 0}} /> */}
                <div className="subTitle">
                    격리조치 ( {blockCompYn == "Y" ? "완료" : "미완료"} )
                </div>
                <table>
                    <colgroup>
                        <col width={90}/>
						<col width={100}/>
                        <col width={70}/>
						<col width={70}/>
                        <col width={70}/>
                        <col />
                        <col width={80}/>
                        <col width={80}/>
                        <col width={40}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: "46px"}} >검사장소</th>
                            <th className="tit">Lot</th>
                            <th className="tit">대상수량</th>
                            <th className="tit">양품수량</th>
                            <th className="tit">불량수량</th>
                            <th className="tit">조치내용</th>
                            <th className="tit">담당자</th>
                            <th className="tit">조치일자</th>
                            <th className="tit">완료</th>
                        </tr>
                        {
                            _.map(blocks, (v, k) => {

                                return (
                                    <tr key={k}>
                                        <td className="tcon">{v.inspPlace}</td>
                                        <td className="tcon">{v.lot}</td>
                                        <td className="tcon">{util.getNumComma(v.targetAmt)}</td>
                                        <td className="tcon">{util.getNumComma(v.goodAmt)}</td>
                                        <td className="tcon">{util.getNumComma(v.badAmt)}</td>                                        

                                        <td className="tcon">{v.actCont}</td>
                                        <td className="tcon">{v.mngrNm}</td>
                                        
                                        <td className="tcon">{util.getDateStrSplit('d',v.actDy)}</td>
                                        <td className="tcon">{v.retYn}</td>                                        
                                    </tr>    
                                )

                            })
                        }
                    </tbody>
                </table>    
                {/* <div className="endline"></div>
                <br style={{height:0, lineHeight: 0}} /> */}
                <div className="subTitle">
                    시정조치 ( {corrCompYn == "Y" ? "완료" : "미완료"} )
                </div>
                <table>
                    <colgroup>
                        <col width={100}/>
						<col width={100}/>
                        <col width={100}/>
						<col />
                        <col width={100}/>
						<col width={100}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">조치일자</th>
                            <td className="tcon">
                                <span>{util.getDateStrSplit('d',actDy)}</span>
                            </td>
                            <th className="tit">부서</th>
                            <td className="tcon">
                                <span>{detpNm}</span>
                            </td>                           
                            <th className="tit">담당자</th>
                            <td className="tcon">
                                <span>{mngrNm}</span>
                            </td>  
                        </tr>
                        <tr>
                            <th className="tit">제목</th>
                            <td className="tcon" style={{textAlign: "left"}} colSpan={5}>
                                <span>{actTitle}</span>
                            </td>                           
                        </tr>
                        {/* <tr>
                            <th className="tit">원본파일</th>
                            <td className="tcon" colSpan={5}>
                                <FileUploadBox
                                    grpUid={corrFileUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={corrFiles}
                                    onUploaded={(e) => {
                                        setCorrFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setCorrFiles(e);
                                    }}
                                />
                            </td>                           
                        </tr>   */}
                        <tr>
                            <th className="tit" colSpan={6}>조치 전 (현상 및 문제점)</th>                            
                        </tr>
                        <tr>
                            <td className="tcon" colSpan={3}>
                                <div style={{width: "100%"}}>
                                { 
                                    actBefImgFileInfo != '' ?                                                
                                    cmn.getRenderImg(actBefImgFileInfo, 180) 
                                    :
                                    <p>
                                        이미지 없음.
                                    </p>
                                }
                                </div>
                            </td>
                            <td className="tcon" style={{verticalAlign: 'top'}} colSpan={3}>
                                <TextArea
                                    minHeight={100}
                                    // width={500}
                                    value={actBefCont}
                                    // placeholder="조치 전 내용을 입력하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setActBefCont(e.value);
                                    }}
                                />
                            </td>
                        </tr>                      
                        <tr>
                            <th className="tit" colSpan={6}>조치 후 (개선내용 및 효과)</th>                            
                        </tr>
                        <tr>
                            <td className="tcon" colSpan={3}>
                                <div style={{width: "100%"}}>
                                { 
                                    actAftImgFileInfo != '' ?                                                
                                    cmn.getRenderImg(actAftImgFileInfo, 180) 
                                    :
                                    <p>
                                        이미지 없음.
                                    </p>
                                }
                                </div>                                
                            </td>
                            <td className="tcon" style={{verticalAlign: 'top'}} colSpan={3}>
                                <TextArea
                                    minHeight={100}
                                    value={actAftCont}
                                    // placeholder="조치 후 내용을 입력하세요."
                                    autoResizeEnabled={true}
                                    onValueChanged={(e) => {
                                        setActAftCont(e.value);
                                    }}
                                />
                            </td>
                        </tr>          
                    </tbody>
                </table>       
                {/* <div className="endline"></div>
                <br style={{height:0, lineHeight: 0}} />                             */}
                <div className="subTitle">
                    시정조치 검증 ( {corrChkCompYn == "Y" ? "완료" : "미완료"} )
                </div>
                <table>
                    <colgroup>
                        <col width={80}/>
						<col width={80}/>
                        <col width={100}/>
						<col width={140}/>
                        <col width={70}/>
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: "46px"}} >검증회차</th>
                            <th className="tit">검증일자</th>
                            <th className="tit">Lot</th>
                            <th className="tit">검증방법</th>
                            <th className="tit">결과</th>
                            <th className="tit">비고</th>
                        </tr>
                        {
                            _.map(corrChks, (v, k) => {

                                return (
                                    <tr key={k}>
                                        <td className="tcon">{v.corrIdx}</td>                                        
                                        <td className="tcon">{util.getDateStrSplit('d',v.corrDy)}</td>                                        
                                        <td className="tcon">{v.lot}</td>
                                        <td className="tcon">{v.corrWay}</td>
                                        <td className="tcon">{v.corrYn}</td>
                                        <td className="tcon">{v.cmnt}</td>
                                    </tr>    
                                )

                            })
                        }
                    </tbody>
                </table>    
                {/* {
                    cmn.getRenderPrintBottom("QF-000-000", authStore.com.name, "A4 (210x297mm)")
                } */}
            </div>
            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"부적합 개선카드"}
            content = {renderContent()}
        />
        </>
    )
}

export default BadImprRepoPage;

import React, { useState, useEffect, useContext } from 'react';
import { CheckBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    BadAmtBox, InspSampBox, DateTimeBox, UserSelectBox, FileUploadBox, CodeSelectBox,
    PopupLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  수입검사 등록 / 수정
 */
const InInspPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
        intp = ls.intp
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    const [procInspHisUid, setProcInspHisUid] = useState('');
    const [inspDt, setInspDt] = useState('');

    const [inspProgStt, setInspProgStt] = useState('30603'); // 기본값: 30603(검사완료)

    const [inspUserUid, setInspUserUid] = useState('');
    const [inspUserNm, setInspUserNm] = useState('');
    const [inUid, setInUid] = useState('');
    const [lot, setLot] = useState('');
    const [inDy, setInDy] = useState('');
    const [inAmt, setInAmt] = useState(null);
    const [unitTp, setUnitTp] = useState('');
    const [unitTpNm, setUnitTpNm] = useState('');    
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodProcUid, setProdProcUid] = useState('');    
    const [prodStnd, setProdStnd] = useState('');
    const [bizUid, setBizUid] = useState('');    
    const [bizNm, setBizNm] = useState('');
    const [procPrice, setProcPrice] = useState(null);
    const [procNm, setProcNm] = useState('');
    const [unitWght, setUnitWght] = useState(null);

    const [passYn, setPassYn] = useState(null);    
    const [condPassYn, setCondPassYn] = useState(false);

    const [preBadAmt, setPreBadAmt] = useState(0);
    const [badAmts, setBadAmts] = useState([]);
    const [inspItems, setInspItems] = useState([]);

    const [atchFileGrpUid, setAtchFileGrpUid] = useState(util.getUUID());
    const [atchFiles, setAtchFiles] = useState([]);

    const [upYn, setUpYn] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadQualInfo();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
 
    const loadQualInfo = async() => {
        try {   
            const r =  await cmn.api("getProcInspHisIn", {p: {inUid: uid, inspTp: cmn.CMNCO_INSPTP.IN, inTp: intp}} );
            if(r === undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
            const d2 = r.d2;

            // ** 기본정보 표시
            setProcInspHisUid(d0.procInspHisUid);
            setInspDt(util.getChkNull(d0.inspDt) === '' ? util.getDateToString('m', util.getNow(), '-') : d0.inspDt );

            if(util.getChkNull(d0.inspProgStt) !== '') {
                setInspProgStt(d0.inspProgStt);
            }

            setInspUserUid(util.getChkNull(d0.inspUserUid) === '' ? authStore.user.uid :  d0.inspUserUid);
            setInspUserNm(d0.inspUserNm);
        
            setInUid(d0.inUid);
            setLot(d0.lot);
            setInDy(d0.inDy);
            setInAmt(d0.inAmt);
            setUnitTp(d0.unitTp);
            setUnitTpNm(d0.unitTpNm);
            
            setProdId(d0.prodId);
            setProdNm(d0.prodNm);
            setProdProcUid(d0.prodProcUid);
            setProdStnd(d0.prodStnd);
        
            setBizUid(d0.bizUid);
            setBizNm(d0.bizNm);
            setProcPrice(d0.procPrice);

            setProcNm(d0.procNm);
        
            setUnitWght(d0.unitWght);
            setPassYn(d0.passYn);
            
            setCondPassYn(d0.condPassYn === "Y" ?  true : false);

            setBizNm(d0.bizNm);
            setUnitTp(d0.unitTp);
            setUnitTpNm(d0.unitTpNm);
            setUpYn(d0.upYn);

            setInspItems(d1);

            const badAmts_ = [...d2];
            _.forEach(badAmts_, (v, k) => {
                v.uid = v.uid === undefined ? util.getUUID() : v.uid;
            })

            const badAmt_ = _.sumBy(badAmts_, 'badAmt');
            // console.log("=== badAmt_", badAmt_);
            setPreBadAmt(badAmt_);

            setBadAmts(badAmts_);

            setAtchFileGrpUid(d0.atchFileGrpUid == null ? util.getUUID() : d0.atchFileGrpUid);
            setAtchFiles(r.f1);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }     
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.confirmYn('저장완료')) return;
            addProcInspHis();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addProcInspHis = async() => {

        const ps = {
            p: 
                {
                    procInspHisUid: util.getChkNull(procInspHisUid) === '' ? util.getUUID() : procInspHisUid,
                    isUp: util.getChkNull(procInspHisUid) === '' ? false : true,
                    comUid: authStore.com.uid,
                    prodProcUid: prodProcUid,
                    inspDy: util.getDateToString('d',inspDt),
                    inspDt: inspDt,
                    inspIdx: 1,
                    inspTp: cmn.CMNCO_INSPTP.IN,
                    inspProgStt: inspProgStt,
                    inspUserUid: inspUserUid,
                    lot: lot,
                    passYn: passYn,
                    condPassYn: condPassYn ? 'Y' : 'N',
                    actDtl: '',
                    procBizUid: bizUid,
                    procPrice: procPrice,
                    equpUid: '',
                    inUid: inUid,
                    atchFileGrpUid: atchFileGrpUid,
                    uptUserUid: authStore.user.uid,
                    preBadAmt: preBadAmt,
                    bads: badAmts,
                    dtls: inspItems,
                    atchFiles: atchFiles
                }
            
        }

        const r = await cmn.api("addProcInspHis", ps);
        if(r == null) return;

        util.cmnSucAlert("저장완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "저장완료" 
            : mode === "u" ? "저장완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                {
                    upYn === 'N' ?
                        <span style={{color:'blue'}}>* 불량처리가 완료된 제품입니다.</span>
                    : 
                        <ActionButton 
                            onClick={handleClickComplete}
                            title={completeButtonTitle}
                            marginRight={0}
                            show={true}
                        />
                }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={"20%"}/>
                        <col className="tit" />
						<col className="tcon" width={"20%"}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">검사일시</th>
                            <td className="tcon">
                            {
                                mode === "a" ?
                                <DateTimeBox
                                    value={inspDt}
                                    onValueChanged={(dt) => { 
                                        setInspDt(dt) 
                                    }}
                                />
                                :
                                <span></span>
                            }
                            </td>
                            <th className="tit">검사자</th>
                            <td className="tcon">
                            {
                                mode === "a" ?
                                <UserSelectBox
                                    mode={mode}
                                    value={inspUserUid}
                                    onValueChanged={(v) => { setInspUserUid(v); }}
                                />
                                :
                                <span></span>
                            }
                            </td>
                            <th className="tit">검사진행상태</th>
                            <td className="tcon">
                            {
                                <CodeSelectBox
                                    value={inspProgStt}
                                    classId={'306'}
                                    showClearButton={false}
                                    onValueChanged={(v, o) => { 
                                        setInspProgStt(v);
                                    }}
                                />
                            }
                            </td>                           
                        </tr>   
                        <tr>
                            <th className="tit">업체성적서</th>
                            <td className="tcon" colSpan={5}>
                                <>
                                 <FileUploadBox
                                    grpUid={atchFileGrpUid}
                                    userUid={authStore.user.uid}
                                    uploadFiles={atchFiles}

                                    onUploaded={(e) => {
                                        setAtchFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setAtchFiles(e);
                                    }}
                                />
                                </>
                            </td>
                        </tr>                     
                    </tbody>
                </table>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={"20%"}/>
                        <col className="tit" />
						<col className="tcon" width={"20%"}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">Lot</th>
                            <td className="tcon">
                                {lot}
                            </td>
                            <th className="tit">입고일</th>
                            <td className="tcon">
                                {util.getDateStrSplit('d',inDy)}
                            </td>
                            <th className="tit">입고량</th>
                            <td className="tcon">
                                {`${util.getNumComma(inAmt)} ${cmn.getDispUnitTpNm(unitTpNm)}`}
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">
                                {prodNm}
                            </td>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                {prodId}
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                {prodStnd}
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">공정</th>
                            <td className="tcon">
                                {procNm}
                            </td>
                            <th className="tit">공정단중</th>
                            <td className="tcon">
                                { unitWght&&`${unitWght} ${cmn.getDispUnitTpNm(unitTpNm)}`}
                            </td>
                            <th className="tit">업체</th>
                            <td className="tcon">
                                {bizNm}
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">불량</th>
                            <td className="tcon"colSpan={3}>
                                <BadAmtBox
                                    mode={mode}
                                    badAmts={badAmts}
                                    unitTp={unitTp}
                                    unitTpNm={unitTpNm}
                                    onClickAdd={(badAmts) => { setBadAmts(badAmts); }}
                                    onClickDel={(badAmts) => { setBadAmts(badAmts); }}
                                />
                            </td>   
                            <th className="tit">합격여부</th>
                            <td className="tcon">
                                <div className="fl-l">                                
                                {   
                                    condPassYn ? 
                                    cmn.getDispPass('Y')
                                    :
                                    cmn.getDispPass(passYn)
                                }
                                </div>
                                <div className="fl-r">
                                    <CheckBox 
                                        value={condPassYn} 
                                        text={'조건부합격'}
                                        onValueChanged={(e) => { 
                                            setCondPassYn(e.value);
                                            
                                        }}
                                    />
                                </div>
                            </td>                              
                        </tr>                                           
                    </tbody>
                </table>
            </div>
            <InspSampBox
                mode={mode}
                inspItems={inspItems}
                onValueChanged={(inspItems, passYn) => { 
                    // setInspItems(inspItems); 
                    setPassYn(passYn); 
                }}
            />
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "수입검사" 
                : mode === "u" ? "수입검사"
                : "수입검사" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default InInspPopPage;
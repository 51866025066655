import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, CodeSelectBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';


/** 
 *  재고 실사 
 */
const StockDueDiliPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodTp, setProdTp] = useState(cmn.CMNCO_PRODTP.MAT);
    const [prodId, setProdId] = useState('');
    const [stndMon, setStndMon] = useState(util.getNow());
    const [lot, setLot] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "제품유형",
                width: "200px",
                item: 
                    <CodeSelectBox
                        value={prodTp}
                        classId={'101'}
                        placeholder="제품유형을 선택하세요."
                        showClearButton={false}
                        onValueChanged={(v) => { 
                            setProdTp(v);
                        }}
                    />
            },
            {   title: "품번",
                width: "200px",
                item: 
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />    
            },
            {   title: "Lot",
                item: 
                <TextBox 
                    value={lot}
                    width={"200px"}
                    showClearButton={true}
                    placeholder="Lot로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setLot(e.value);
                    }}
                />    
            }
        ],
        [
            {   title: "기준월",
                colSpan: 5,
                item: 
                <DayDateBox 
                    value={stndMon}
                    type={'month'}
                    displayFormat={cmn.DATE_FORMAT_MON_1}
                    onValueChanged={(e) => {
                        setStndMon(e.value);
                    }}                    
                />
            },
           
        ]
    ];
    /* #endregion */

    // * Grid
    const [stockListLot, setStockListLot] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, prodTp, stndMon]);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        const ps =  {
            p: {
                prodTp: prodTp, 
                prodId: prodId,
                lot: lot,
                stndMon: util.getDateToString('M',stndMon)
            }
        }

        const r = await cmn.api("getStockListLot", ps );
        if(r === undefined || r == null) return;

        setStockListLot(r.d0)

    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickStockUp = () => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup();
        }
    }

    const handleClickStockUpHis = () => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopupHis();
        }
    }
    
    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "buyDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    // * Functions
    const openCrudPopup = () => {
        const url = `/stock/stockduedilipop?${qs.stringify({mode:'a', lot: selectedItem.lot})}`;     
        util.openWindow(url, 600, 350);
    }

    const openCrudPopupHis = () => {
        const url = `/stock/stockduedilihispop?${qs.stringify({lot: selectedItem.lot})}`;     
        util.openWindow(url, 600, 300);
    }

    /* ====================
     *  Render
     * ==================== */
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickStockUp}
                    title="재고수정"
                    show={true}
                />  
                <ActionButton 
                    width={'130px'}
                    onClick={handleClickStockUpHis}
                    title="재고수정내역"
                    show={true}
                />  
            </ListTopView>          
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="Lot별 재고내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>

            {/** GRID */}
            <DataGridView  
                reff={grid1}          
                dataSource={stockListLot}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                showNo={false}
                // select
                onSelectionChanged={handleGridSelectionChanged}  
                
                // excel
                exlnm={'Lot별 재고내역'}
                customizeExcelCell={customizeExcelCell} 

                colsWidth={[cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    ,cmn.CW.lot, cmn.CW.amt, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80]} 
            >
                <Column 
                    alignment={'center'}
                    dataField={'prodNm'} 
                    caption={'품명'} 
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockListLot, 'lot'); }}
                />    
                <Column 
                    alignment={'center'}
                    dataField={'prodId'} 
                    caption={'품번'}
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockListLot, 'lot'); }}
                />                
                <Column 
                    alignment={'center'}
                    dataField={'prodStnd'}
                    caption={'규격'} 
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockListLot, 'lot'); }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'lot'}
                    caption={'Lot'} 
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockListLot, 'lot'); }}
                />
                <Column 
                    alignment={'center'}
                    dataField={'stockAmt'}
                    caption={'재고'} 
                    cellRender={(d) => { return cmn.cellRenderRowSpan(d, stockListLot, 'lot', util.getNumComma(d.value)); }}
                />
                { cmn.getGridColumn('proc1', '공정1') }
                { cmn.getGridColumn('proc2', '공정2') }
                { cmn.getGridColumn('proc3', '공정3') }
                { cmn.getGridColumn('proc4', '공정4') }
                { cmn.getGridColumn('proc5', '공정5') }
                { cmn.getGridColumn('proc6', '공정6') }
                { cmn.getGridColumn('proc7', '공정7') }
                { cmn.getGridColumn('proc8', '공정8') }
                { cmn.getGridColumn('proc9', '공정9') }
                { cmn.getGridColumn('proc10', '공정10') }

                <Column />
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default StockDueDiliPage;
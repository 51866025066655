import React, { useState, useEffect, useContext } from "react";
import { Observer } from "mobx-react-lite";
import { TextBox } from "devextreme-react";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import qs from "query-string";
import useKey from "use-key-hook";

/** components */
import { ListSearchView, ListTopView, GridTopView, DataGridView, SrchButton, DayDateBox, ProdSearchBox, ProdProcSelectBox } from "../../components";
import ResponsiveBox, { Row, Col, Item, Location } from "devextreme-react/responsive-box";
import { Chart, Series, ArgumentAxis, CommonSeriesSettings, Legend, Margin, Tooltip, Grid } from "devextreme-react/chart";

/** stores & lib */
import { AuthStore, CmnStore } from "../../stores";
import { util, cmn } from "../../lib";

/**
 *  제조리드타임
 */
const ReadTimePage = (props) => {
  /* ====================
   *  Store
   * ==================== */

  const authStore = useContext(AuthStore);
  const cmnStore = useContext(CmnStore);

  /* ====================
   *  Object
   * ==================== */

  // ...

  /* ====================
   *  State / Effect
   * ==================== */
  const [pageLoad, setPageLoad] = useState(true);

  // * 검색
  const [startMon, setStartMon] = useState(util.getNow());
  const [endMon, setEndMon] = useState(util.getNow());
  const [prodUid, setProdUid] = useState("");
  const [prodProcUid, setProdProcUid] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);

  /* #region Search Items */

  const searchItems = [
    [
      {
        title: "작업월",
        width: "200px",
        item: (
          <ul className="ul-row">
            <li>
              <DayDateBox
                value={startMon}
                type={"month"}
                displayFormat={cmn.DATE_FORMAT_MON_1}
                onValueChanged={(e) => {
                  setStartMon(e.value);
                }}
              />
            </li>
            <li>~</li>
            <li>
              <DayDateBox
                value={endMon}
                type={"month"}
                displayFormat={cmn.DATE_FORMAT_MON_1}
                onValueChanged={(e) => {
                  setEndMon(e.value);
                }}
              />
            </li>
          </ul>
        ),
      },
      {
        title: "제품검색",
        item: (
          <ProdSearchBox
            value={prodUid}
            width={"300px"}
            popCenter={false}
            onLoadInfo={(o) => {}}
            onValueChanged={(v) => {
              setProdUid(v);
            }}
          />
        ),
      },
    ],
  ];

  /* #endregion */

  // * Grid, Paging & Sorting
  const [curPage, setCurPage] = useState(1);
  const [rowsOffset, setRowsOffset] = useState(0);
  const [rowsLength, setRowsLength] = useState(authStore.user.rows);
  const [sortItem, setSortItem] = useState({ col: "", tp: "" });

  const [readTimeItem, setReadTimeItem] = useState([]);
  const [chartReadTimeItem, setChartReadTimeItem] = useState([]);

  /** Enter Key */
  useKey(
    (pressedKey, event) => {
      util.clickElement("srchButton");
    },
    {
      detectKeys: [13], // detectKeys: ['A', '+', 122]
    }
  );

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    !pageLoad && handleClickSearch();
  }, [curPage, rowsLength, sortItem]);

  /* ====================
   *  Load
   * ==================== */

  const loadPage = async () => {
    try {
      await authStore.loadPage(props);
      //     await handleClickSearch();
      setPageLoad(false);
    } catch (err) {
      cmnStore.handleErrorPage(err, props);
    }
  };

  const loadList1 = async () => {
    if (util.getChkNull(prodUid) === "") {
      util.cmnAlert("[제품]을 선택해 주십시오.");
      return;
    }

    const ps = {
      p: {
        startMon: util.getDateToString("M", startMon),
        endMon: util.getDateToString("M", endMon),
        prodUid: prodUid,
      },
    };

    let r = await cmn.api("getReadTimeList", ps);
    if (r == null || r === undefined) return;

    let d0 = r.d0;

    setReadTimeItem(d0);
    setChartReadTimeItem(
      r.d0.map((v) => {
        return {
          planMon: v.planMon,
          readTm: v.readTm * 1,
        };
      })
    );
  };

  /* ====================
   *  Handle / Functions
   * ==================== */

  // * Actions

  const handleClickSearch = async (e) => {
    try {
      await loadList1();
    } catch (err) {
      cmnStore.handleErrorPage(err, props);
    }
  };

  // * Data Grid

  const handleGridSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData.length == 0) {
      setSelectedItem(null);
      return;
    }
    setSelectedItem(selectedRowsData[0]);
  };

  const handleGridCellClick = (e) => {
    // Header Sorting
    const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
    if (sortItem_ != null) {
      setSortItem(sortItem_);
    }
  };

  // * Functions

  /* ====================
   *  Render
   * ==================== */

  // Render Grid Header (* Sort ...)
  const renderGridHeaderCell = (e) => {
    return cmn.renderGridHeaderCell(e, sortItem);
  };

  const renderContent = () => {
    return (
      <>
        <Observer>
          {() => (
            <ListTopView>
              <SrchButton id="srchButton" onClick={handleClickSearch} />
            </ListTopView>
          )}
        </Observer>
        <ListSearchView searchItems={searchItems} />
        <Observer>
          {() => (
            <>
              <ResponsiveBox singleColumnScreen="sm">
                <Row ratio={1}></Row>
                <Col ratio={1}></Col>
                <Col ratio={1}></Col>
                <Item>
                  <Location row={0} col={0} screen="lg"></Location>
                  <Location row={1} col={0} screen="sm"></Location>
                  <GridTopView title="수주출하리드타임"></GridTopView>
                  <DataGridView
                    dataSource={readTimeItem} // store
                    gridHeight={authStore.windowHeight - cmn.BH.H10}
                    // sort
                    onCellClick={handleGridCellClick}
                    // select
                    onSelectionChanged={handleGridSelectionChanged}
                    colsWidth={[100, 100, 200, 200, 150]}
                  >
                    {cmn.getGridColumnMonthType("planMon", "수주일", renderGridHeaderCell)}
                    {cmn.getGridColumnMonthType("deliMon", "납품일", renderGridHeaderCell)}
                    {cmn.getGridColumn("prodId", "품번", renderGridHeaderCell)}
                    {cmn.getGridColumn("prodNm", "품명", renderGridHeaderCell)}
                    {/* { cmn.getGridColumnNumCommaType('manuAmt', '생산수량') }
                { cmn.getGridColumn('unitTpNm', '단위') }
                { cmn.getGridColumnNumCommaType('manuMin', '생산시간(분)') } */}
                    {cmn.getGridColumnNumCommaType("readTm", "수주출하리드타임(일)")}

                    <Summary>
                      {/* <TotalItem
                        column={'manuAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'manuMin'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    /> */}
                      <TotalItem
                        column={"readTm"}
                        summaryType={"avg"}
                        customizeText={(d) => {
                          return util.getNumComma(util.fix(d.value, 2));
                        }}
                      />
                    </Summary>
                  </DataGridView>
                </Item>
                <Item>
                  <Location row={0} col={1} screen="lg"></Location>
                  <Location row={1} col={0} screen="sm"></Location>
                  <GridTopView title="수주출하리드타임 Chart"></GridTopView>
                  <div style={{ border: "1px solid #d8d8d8", padding: "0 6px", height: "100%" }}>
                    <Chart dataSource={chartReadTimeItem}>
                      <CommonSeriesSettings argumentField="planMon" type={"line"} />
                      <Series valueField={"readTm"} name={"수주출하리드타임"} color="#0000ff" />
                      <Margin bottom={20} />
                      <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode="crossLabels">
                        <Grid visible={true} />
                      </ArgumentAxis>
                      <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom" />
                      {/* <Export enabled={true} /> */}

                      <Tooltip enabled={true} />
                    </Chart>
                  </div>
                </Item>
              </ResponsiveBox>
            </>
          )}
        </Observer>
      </>
    );
  };

  return <>{!pageLoad && renderContent()}</>;
};

export default ReadTimePage;

import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';
import JsBarcode from 'jsbarcode';

/** components */
import {
    PrintLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore } from '../../stores';
import { util, } from '../../lib';
/** 
 *  라벨출력
 */
const LblPrintPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);

     /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        para = ls.para,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [printItems, setPrintItems] = useState([]);

    
    // const [useYn, setUseYn] = useState('N');
    // const [plcCd, setPlcCd] = useState('');
    // const [equpImgUid, setEqupImgUid] = useState(util.getUUID());
    // const [chkImgUid, setChkImgUid] = useState(util.getUUID());
    // const [equpUploadFiles, setEqupUploadFiles] = useState([]);
    // const [chkUploadFiles, setChkUploadFiles] = useState([]);

    // const [cmnt, setCmnt] = useState('');

    // const [cycleDatas, setCycleDatas] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadBarcode();
    },[printItems]);
    
    // useEffect(() => {
    //     loadInfo2();
    // },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
        loadInfo1();
    }

    const loadBarcode = async() => {
     //   if(document.getElementById('bcd-lot-1') === null) return;
        _.times(printItems.length, (n) => {
            JsBarcode(`#bcd-lot-${n}`, printItems[n].lot, { width: 1, height: 44, fontSize: 10, margin: 0});
        });
    }

    const loadInfo1 = async() => {

        if(util.getChkNull(para) == '') return;

        let para_ = _.split(para, '|');
        let lotInfo_ = [];
        let printItems_ = [];
        let cnt = 0;
        _.forEach(para_, (v, k) => {
            lotInfo_ = _.split(v, '^');
            cnt = lotInfo_[0];
            _.times(cnt, (n) => {
                printItems_.push({
                    lblCnt: lotInfo_[0],
                    lot: lotInfo_[1],
                    dy: lotInfo_[2],
                    amt: lotInfo_[3],
                    prodId: lotInfo_[4],
                    prodNm: lotInfo_[5],
                    prodStnd: lotInfo_[6],
                    bizNm: lotInfo_[7]
                })
            })
        })

        setPrintItems(printItems_);
     }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        printPage();
    }

    const printPage = async() => {

        window.print();
    }

    /* ====================
     *  Render
     * ==================== */

    /** 라벨프린터용 */
    const renderLblPrint01 = () => {        
        return(
            <>
            {
                _.map(printItems, (v, k) => {
                    const bcdId = `bcd-lot-${k}`;
                    return (
                        <React.Fragment key={k}>
                        <div className="print_label">
                        
                            <table>
                                <colgroup>
                                    <col width={50} />
                                    <col width={120} />
                                    <col width={50} />
                                    <col width={120} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td className="tcon" colSpan={4} >
                                            <svg id={bcdId}></svg>  
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="tit" style={{height:'15px'}} >품번</th>
                                        <td className="tcon">
                                            <span>{v.prodNm}</span>
                                        </td>
                                        <th className="tit">품명</th>
                                        <td className="tcon">
                                            <span>{v.prodId}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="tit" style={{height:'15px'}} >규격</th>
                                        <td className="tcon">
                                            <span>{v.prodStnd}</span>
                                        </td>
                                        <th className="tit">일자</th>
                                        <td className="tcon">
                                            <span>{v.dy}</span>
                                        </td>
                                    </tr>   
                                    <tr>
                                        <th className="tit"  style={{height:'15px'}}>lot</th>
                                        <td className="tcon">
                                            <span>{v.lot}</span>
                                        </td>
                                        <th className="tit">수량</th>
                                        <td className="tcon">
                                            <span>{v.amt}</span>
                                        </td>
                                    </tr>                         
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="endline"></div>
                        </React.Fragment>

                    )
                })
            }
            </>
        )

    }

    /** A4용 제품식별표 - 적용업체: D.S테크 */
    const renderLblPrint02 = () => {
        
        return(
            <>
            {
                _.map(printItems, (v, k) => {
                    const bcdId = `bcd-lot-${k}`;
                    return (
                        <React.Fragment key={k}>
                            <div className="print_type">
                                <table className="prod-table">
                                    <colgroup>
                                        <col width={100} />
                                        <col  />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="tit_1" colSpan={2}>
                                                <span>제 품  식 별 표</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tcon_1">품  명</td>
                                            <td className="tcon_2">
                                                <span>{v.prodId}</span>
                                            </td>
                                        </tr>                                    
                                        <tr>
                                            <td className="tcon_1">품  번</td>
                                            <td className="tcon_2">
                                                <span>{v.prodNm}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tcon_1">Lot</td>
                                            <td className="tcon_2">
                                                <span>{v.lot}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tcon_1">바코드</td>
                                            <td className="tcon_2">
                                                <svg id={bcdId}></svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tcon_1">수  량</td>
                                            <td className="tcon_2">
                                                <span>{v.amt}</span>
                                            </td>
                                        </tr>   
                                        <tr>
                                            <td className="tcon_1">업  체</td>
                                            <td className="tcon_2">
                                                {/* <span>{authStore.com.uid}</span> */}
                                                <span>{v.bizNm}</span>
                                            </td>
                                        </tr>   
                                        <tr>
                                            <td className="tit_2" colSpan={2}>
                                                <span>{authStore.com.name}</span>
                                            </td>
                                        </tr>                       
                                    </tbody>
                                </table>
                            </div>
                            {
                                printItems.length > (k+1) &&  
                                <div className="endline"></div>
                            }
                       </React.Fragment>
                    )
                })
            }
            </>
        )

    }

    const renderContent = () => {
        console.log('printItems', printItems);
        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>            
            {
                renderLblPrint02()
                // renderLblPrint01()
            }
            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"라벨 출력"}
            content = {renderContent()}
        />
        </>
    )
}

export default LblPrintPopPage;
import React from 'react';
import { CheckBox } from 'devextreme-react';
import _ from 'lodash';

/** components */

/** stores & lib */

/** images */

/** 
 *  List Top View
 */
const CheckListBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    

    /* ====================
     *  Object
     * ==================== */

    const {
        items,
        textExpr="text",
        direction="column",
        onValueChanged
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */


    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {

        return (
           <ul 
                style={{
                    display: direction == "column" ? "inline-flex" : null
                }}
            >
            {
                _.map(items, (v, k) => {
                    return(
                        <li key={k}
                            style={{
                                padding: "2px 4px"
                            }}
                        >
                            <CheckBox key={k}
                                text={eval(`v.${textExpr}`)}
                                value={ v.checked == undefined ? false : v.checked }
                                onValueChanged={(e) => {
                                    const items_ = {...items};
                                    items_[k].checked = e.value;
                                    e.items = items_;
                                    onValueChanged(e);
                                }}  
                            />
                        </li>
                    )
                })       
            }
           </ul>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default CheckListBox;
import React, { useState, useEffect } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */


/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  Code 선택 조회
 */
const CodeSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    

    /* ====================
     *  Object
     * ==================== */

    const {
        width='100%',
        value,
        placeholder='코드를 선택해주세요.',
        isIn = true,
        ids = [],
        classId,
        showClearButton=true,
        onLoadInfo,
        onValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [codes, setCodes] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {        
        try {
            
            let codes_ = [];
            let ps = '';
            if(ids.length > 0)
            {
                let isIn_ = isIn ? "Y" : "N";
                ps = {p:{classId: classId, isIn:isIn_, ids: ids}};
            }
            else {
                ps = {p:{classId: classId, isIn:'', ids: ''}};
            }     
            const r = await cmn.api("getCodeCombo", ps);
            setCodes(r.d0);

            if(util.getChkNull(value) !== ''){
                handleLoadInfo(codes_, value);
            }
        }
        catch(err){
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    const handleLoadInfo = (ds, v) => {
        const o = _.find(ds, {codeId: v});
        onLoadInfo && onLoadInfo(o);
    }
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        // console.log("@@@ renderContent codeSelectBox");
        return (
            <>
            <SelectBox 
                width={width}
                placeholder={placeholder}
                value={value}
                dataSource={codes}
                displayExpr={'codeNm'}
                valueExpr={'codeId'}

                showClearButton={showClearButton}

                // searchEnabled={true}
                // searchMode={'startswith'} // contains, startswith
                // searchExpr={['prodId', 'prodNm', 'prodDisp']}
                
                //searchTimeout={this.state.searchTimeoutOption}
                //minSearchLength={this.state.minSearchLengthOption}

                onValueChanged={(e)=>{
                    onValueChanged(e.value);
                    handleLoadInfo(codes, e.value);
                }} 
            />
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default CodeSelectBox;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox, DayDateBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 수리이력 관리
 */
const EqupFixPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [startDy, setStartDy] = useState('');
    const [endDy, setEndDy] = useState('');  

    const [equpNm, setEqupNm] = useState('');
    const [equpCd, setEqupCd] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "수리요청일",
                width: "200px",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
                </>
            },
            {   title: "설비코드",
                width: "200px",
                item: 
                <TextBox 
                    value={equpCd}
                    showClearButton={true}
                    placeholder="설비코드로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setEqupCd(e.value);
                    }}
                />                      
            },
            {   title: "설비명",                
                item: 
                <TextBox 
                    width="200px"
                    value={equpNm}
                    showClearButton={true}
                    placeholder="설비명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setEqupNm(e.value);
                    }}
                />                      
            }
        ]
    ];

   // * Grid
   const [equpFixList, setEqupFixList] = useState([]);
   const [equpFixListTot, setEqupFixListTot] = useState(0);
   const [selectedItem, setSelectedItem] = useState(null);
   // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                equpNm: equpNm,
                equpCd: equpCd,
                startDy: startDy,
                endDy: endDy,

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getEqupFixList", ps );
        if(r === undefined || r == null) return;

        setEqupFixList(r.d0)
        setEqupFixListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a");
    }

    const handleClickUp = (e) => {
        if(!util.chkListSelected(selectedItem, '수리내역을')) return;
        openCrudPopup("u", selectedItem.equpFixUid);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType != "data") return;
        if(gc.column.dataField == "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField == "fixReqDy" 
            ||  gc.column.dataField == "fixStartDy" 
            ||  gc.column.dataField == "fixEndDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
        else if(gc.column.dataField == "fixCost" 
            ) e.value = util.getNumComma(gc.value);                 
        else if(
                gc.column.dataField == "fixBefFile" 
            ||  gc.column.dataField == "fixAftFile" 
        )   e.value = '';
    }    

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, equpFixUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equpfixpop?${qs.stringify({mode: mode, uid: equpFixUid})}`;        
        util.openWindow(url, 880, 740);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    marginRight={0}
                    show={authStore.page.upYn}
                />
            </ListTopView>   
            }</Observer>    
            <ListSearchView searchItems={searchItems} />
            <GridTopView title="설비수리 내역" >
                {/* <IconButton
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <Observer>{() =>
            <>        
            <DataGridView 
                reff={grid1}           
                dataSource={equpFixList} 
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[160, cmn.CW.equpNm, 100, 200, 80, 80, 80, 160, 100, 100, 100, null]}
                // paging
                page={curPage}
                total={equpFixListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}  
                // excel
                exlnm={'설비수리내역'}
                customizeExcelCell={customizeExcelCell}              
            >
                { cmn.getGridColumn('equpCd', '설비코드', renderGridHeaderCell ) }
                { cmn.getGridColumn('equpNm', '설비명', renderGridHeaderCell ) }                          
                { cmn.getGridColumn('modelNm', '모델명', renderGridHeaderCell ) }
                { cmn.getGridColumn('fixTitle', '수리제목', renderGridHeaderCell ) }
                { cmn.getGridColumnDayType('fixReqDy', '요청일자', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('fixStartDy', '시작일자', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('fixEndDy', '종료일자', renderGridHeaderCell) }
                { cmn.getGridColumn('fixBizNm', '업체', renderGridHeaderCell ) }
                { cmn.getGridColumnNumCommaType('fixCost', '비용', renderGridHeaderCell) }
                {/* <Column 
                    alignment={'center'}
                     caption={'보고서'}
                /> */}
                { cmn.getGridColumnFiles('fixBefFile', '수리전사진') }
                { cmn.getGridColumnFiles('fixAftFile', '수리후사진') }   
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupFixPage;
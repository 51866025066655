import React, { useState, useEffect, useContext } from 'react';
import { Chart, Series, ArgumentAxis, CommonSeriesSettings
    , Margin, Tooltip, Grid, Size } from 'devextreme-react/chart';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PrintLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  X-R 관리도 상세
 */
const XRControlPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);     

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        ppuid = ls.ppuid,
        pihuid = ls.pihuid,
        sdy = ls.sdy,
        edy = ls.edy
    } = props;

    const data_w = 41;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [prodNm, setProdNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [procNm, setProcNm] = useState('');    
    const [equpNm, setEqupNm] = useState('');

    const [inspNm, setInspNm] = useState('');
    const [dispStnd, setDispStnd] = useState('');

    const [xcl, setXcl] = useState(null);
    const [rcl, setRcl] = useState(null);
    const [xucl, setXucl] = useState(null);
    const [rucl, setRucl] = useState(null);
    const [xlcl, setXlcl] = useState(null);
    const [rlcl, setRlcl] = useState(null);

    const [inspValStd, setInspValStd] = useState(null);
    const [stndUpperVal, setStndUpperVal] = useState(null);
    const [stndLowerVal, setStndLowerVal] = useState(null);
    const [cp, setCp] = useState(null);
    const [cpu, setCpu] = useState(null);
    const [cpl, setCpl] = useState(null);
    const [cpk, setCpk] = useState(null);

    const [sampDatas, setSampDatas] = useState([]);
 
    // const [cycleDatas, setCycleDatas] = useState([]);

    const [xbarItems, setXbarItems] = useState([
        { value: 'ucl', name: 'UCL', color: '#ffbb77' },
        { value: 'cl', name: 'CL', color: '#8b8b8b' },
        { value: 'lcl', name: 'LCL', color: '#91c8c8' },
        { value: 'xbar', name: 'XBar', color: '#0000ff' }
    ]);

    const [xbarDatas, setXbarDatas] = useState([]);

    const [rItems, setRItems] = useState([
        { value: 'ucl', name: 'UCL', color: '#ffbb77' },
        { value: 'cl', name: 'CL', color: '#8b8b8b' },
        { value: 'lcl', name: 'LCL', color: '#91c8c8' },
        { value: 'r', name: 'R', color: '#000000' }
    ]);

    const [rDatas, setRDatas] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {    
            const r = await cmn.api("getProcInspHisXr", 
                {p: {
                    prodProcUid: ppuid,
                    procInspHisUid: pihuid,
                    startDy: sdy,
                    endDy: edy
                }} 
            );

            if(r == null) return;
            
            const d1 = r.d1[0];
            const d2 = r.d2;
            const d3 = r.d3;
            const d4 = r.d4;

            setProdNm(d1.prodNm);
            setProdId(d1.prodId);
            setProcNm(d1.procNm);
            setEqupNm(d1.equpNm);

            setInspNm(d1.inspNm);
            setDispStnd(d1.dispStnd);

            setXcl(d1.xcl);
            setRcl(d1.rcl);
            setXucl(d1.xucl);
            setRucl(d1.rucl);
            setXlcl(d1.xlcl);
            setRlcl(d1.rlcl);

            setInspValStd(d1.inspValStd);
            setStndUpperVal(d1.stndUpperVal);
            setStndLowerVal(d1.stndLowerVal);
            setCp(d1.cp);
            setCpu(d1.cpu);
            setCpl(d1.cpl);
            setCpk(d1.cpk);

            setSampDatas(d2);

            setXbarDatas(d3);
            setRDatas(d4);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
     }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            printPage();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const printPage = async() => {
        window.print();
    }

    const customizeTooltip = (arg) => {
        return { text: arg.valueText };
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div className="print_xr"
                // style={{ padding: "30px 20px 0 20px" }}
            >
                <table>
                    <colgroup>
						<col  />
                        <col width={40} />
                        <col width={100} />
                        <col width={100} />
                        <col width={100} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="top_tit" rowSpan={2}>Xbar - R 관리도</td>
                            <th className="tit_apv" rowSpan={2}>결<br />재</th>
                            <th className="tit_apv">담당</th>
                            <th className="tit_apv">과장</th>
                            <th className="tit_apv">팀장</th>
                        </tr>
                        <tr>
                          <td className="tcon_apv"></td>
                          <td className="tcon_apv"></td>
                          <td className="tcon_apv"></td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
						<col width={80} />
						<col />
                        <col width={80} />
						<col width={120}/>
                        <col width={80} />
						<col width={140}/>
                        <col width={80} />
						<col width={120}/>
                        <col width={80} />
						<col width={120}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">품명</th>
                            <td className="tcon">{prodNm}</td>
                            <th className="tit">품번</th>
                            <td className="tcon">{prodId}</td>
                            <th className="tit">공정명</th>
                            <td className="tcon">{procNm}</td>
                            <th className="tit">설비</th>
                            <td className="tcon" colSpan={3}>{equpNm}</td>
                        </tr>
                        <tr>
                            <th className="tit">검사항목</th>
                            <td className="tcon">{inspNm}</td>
                            <th className="tit">검사규격</th>
                            <td className="tcon">{dispStnd}</td>
                            <th className="tit">검사기간</th>
                            <td className="tcon">{`${util.getDateStrSplit('d',sdy, '-')} ~ ${util.getDateStrSplit('d',edy, '-')}`}</td>
                            <th className="tit">작성일자</th>
                            <td className="tcon">{`${util.getDateToString('d',util.getNow(), '-')}`}</td>
                            <th className="tit">작성자</th>
                            <td className="tcon">{authStore.user.name}</td>
                        </tr>
                        
                    </tbody>
                </table> 
                <table>
                    <colgroup>
                        <col width={180} />
						<col  />                        
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <div className="chart_left">
                                    <div className="chart_tit">Xbar 관리도</div>
                                    <div>
                                        <ul className="ul-row">
                                            <li className="i_tit">UCL</li>
                                            <li>=</li>
                                            <li className="i_data">{xucl}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className="ul-row">
                                            <li className="i_tit">CL</li>
                                            <li>=</li>
                                            <li className="i_data">{xcl}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className="ul-row">
                                            <li className="i_tit">LCL</li>
                                            <li>=</li>
                                            <li className="i_data">{xlcl}</li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td className="chart">
                                <Chart                                   
                                    palette={'Violet'}
                                    dataSource={xbarDatas}
                                    >
                                    <CommonSeriesSettings
                                        argumentField={'dy'}
                                        type={'spline'}
                                    />
                                    {
                                        xbarItems.map(function(item) {
                                            return (
                                                <Series 
                                                    key={item.value} 
                                                    valueField={item.value} 
                                                    name={item.name} 
                                                    color={item.color} 
                                                />
                                            )
                                        })
                                    }
                                    <Size
                                        height={140}
                                        width={890}
                                    />
                                    <Margin bottom={0} />
                                    <ArgumentAxis
                                        valueMarginsEnabled={false}
                                        discreteAxisDivisionMode={'crossLabels'}
                                    >
                                        <Grid visible={true} />
                                    </ArgumentAxis>
                                    {/* <Legend
                                        verticalAlignment={'bottom'}
                                        horizontalAlignment={'center'}
                                        itemTextPosition={'right'}
                                    /> */}
                                    {/* <Export enabled={true} />
                                    <Title text={'XBar 관리도'}>
                                        <Subtitle text={'(Millions of Tons, Oil Equivalent)'} />
                                    </Title> */}
                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={customizeTooltip}
                                    />
                                </Chart>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="chart_left">
                                    <div className="chart_tit">R 관리도</div>
                                    <div>
                                        <ul className="ul-row">
                                            <li className="i_tit">UCL</li>
                                            <li>=</li>
                                            <li className="i_data">{rucl}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className="ul-row">
                                            <li className="i_tit">CL</li>
                                            <li>=</li>
                                            <li className="i_data">{rcl}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className="ul-row">
                                            <li className="i_tit">LCL</li>
                                            <li>=</li>
                                            <li className="i_data">{rlcl}</li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td className="chart">
                                <Chart                                   
                                    palette={'Violet'}
                                    dataSource={rDatas}
                                    >
                                    <CommonSeriesSettings
                                        argumentField={'dy'}
                                        type={'spline'}
                                    />
                                    {
                                        rItems.map(function(item) {
                                            return (
                                                <Series 
                                                    key={item.value} 
                                                    valueField={item.value} 
                                                    name={item.name} 
                                                    color={item.color} 
                                                />
                                            )
                                        })
                                    }
                                    <Size
                                        height={140}
                                        width={890}
                                    />
                                    <Margin bottom={0} />
                                    <ArgumentAxis
                                        valueMarginsEnabled={false}
                                        discreteAxisDivisionMode={'crossLabels'}
                                    >
                                        <Grid visible={true} />
                                    </ArgumentAxis>
                                    {/* <Legend
                                        verticalAlignment={'bottom'}
                                        horizontalAlignment={'center'}
                                        itemTextPosition={'right'}
                                    /> */}
                                    {/* <Export enabled={true} />
                                    <Title text={'XBar 관리도'}>
                                        <Subtitle text={'(Millions of Tons, Oil Equivalent)'} />
                                    </Title> */}
                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={customizeTooltip}
                                    />
                                </Chart>
                            </td>
                        </tr>
                    </tbody>
                </table>
                                  
                <table>
                    <colgroup>
                        <col width={30} />
						<col />
                        {
                            _.times(25, (i) => {
                                
                                return (
                                    <col width={data_w} />
                                )
                            })
                        }   
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" colSpan={2}>순번</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <th className="tit">{i+1}</th>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit" colSpan={2}>일자</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && util.getDateStrSplit('m', sampDatas[i].dy, '.')}</td>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit" rowSpan={5}>데<br />이<br />터</th>
                            <th className="tit">X1</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && sampDatas[i].x1}</td>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit">X2</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && sampDatas[i].x2}</td>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit">X3</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && sampDatas[i].x3}</td>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit">X4</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && sampDatas[i].x4}</td>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit">X5</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && sampDatas[i].x5}</td>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit" colSpan={2}>X(평균)</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && sampDatas[i].x}</td>
                                    )
                                })
                            }   
                        </tr>
                        <tr>
                            <th className="tit" colSpan={2}>R</th>
                            {
                                _.times(25, (i) => {                                
                                    return (
                                        <td className="tcon">{sampDatas.length > i && sampDatas[i].r}</td>
                                    )
                                })
                            }   
                        </tr>
                    </tbody>
                </table>

                {/* <table>
                    <colgroup>
						<col />
						
                        <col width={60} />
						<col width={100}/>
                        <col width={60} />
						<col width={100}/>
                        <col width={60} />
						<col width={100}/>
                        <col width={60} />
						<col width={100}/>
                        <col width={60} />
						<col width={100}/>
                        <col width={60} />
						<col width={100}/>
                        <col width={60} />
						<col width={100}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">공정능력지수<br />(CpK)</th>
                            <th className="tit">표준편차<br />σ</th>
                            <td className="tcon"></td>
                            <th className="tit">규격상한<br />Su</th>
                            <td className="tcon"></td>
                            <th className="tit">규격하한<br />Sl</th>
                            <td className="tcon"></td>
                            <th className="tit">(Su-Sl)/6σ<br />Cp</th>
                            <td className="tcon"></td>
                            <th className="tit">(Su-X)/3σ<br />CpU</th>
                            <td className="tcon"></td>
                            <th className="tit">(X-Sl)/3σ<br />CpL</th>
                            <td className="tcon"></td>
                            <th className="tit">MIN(CpU,CpL)<br />CpK</th>
                            <td className="tcon"></td>
                        </tr>                        
                    </tbody>
                </table> */}

                <table className="cpk">
                    <colgroup>
						<col />						
						<col width={130}/>
    					<col width={130}/>
    					<col width={130}/>
    					<col width={130}/>
    					<col width={130}/>
    					<col width={130}/>
    					<col width={130}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" rowSpan={2}>공정능력지수<br />(CpK)</th>
                            <th className="tit">표준편차<br />σ</th>
                            <th className="tit">규격상한<br />Su</th>
                            <th className="tit">규격하한<br />Sl</th>
                            <th className="tit">(Su-Sl)/6σ<br />Cp</th>
                            <th className="tit">(Su-X)/3σ<br />CpU</th>
                            <th className="tit">(X-Sl)/3σ<br />CpL</th>
                            <th className="tit">MIN(CpU,CpL)<br />CpK</th>
                        </tr>  
                        <tr>
                            <td className="tcon">{inspValStd}</td>
                            <td className="tcon">{stndUpperVal}</td>
                            <td className="tcon">{stndLowerVal}</td>
                            <td className="tcon">{cp}</td>
                            <td className="tcon">{cpu}</td>
                            <td className="tcon">{cpl}</td>
                            <td className="tcon">{cpk}</td>
                        </tr>                      
                    </tbody>
                </table>  

            </div>

            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"X-R 관리도"}
            content = {renderContent()}
        />
        </>
    )
}

export default XRControlPopPage;

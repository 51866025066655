import authApiz from './apis/authApiz';
import bizApiz from './apis/bizApiz';
import codeApiz from './apis/codeApiz';
import menuApiz from './apis/menuApiz';
import moldApiz from './apis/moldApiz';
import prodApiz from './apis/prodApiz';
import procApiz from './apis/procApiz';
import salesApiz from './apis/salesApiz';
import userApiz from './apis/userApiz';
import deliApiz from './apis/deliApiz';
import workApiz from './apis/workApiz';
import manuApiz from './apis/manuApiz';
import inOutApiz from './apis/inOutApiz';
import deptApiz from './apis/deptApiz';
import orderApiz from './apis/orderApiz';
import equpApiz from './apis/equpApiz';
import cmnApiz from './apis/cmnApiz';
import qualApiz from './apis/qualApiz';
import prodProcApiz from './apis/prodProcApiz';
import stockApiz from './apis/stockApiz';
import comApiz from './apis/comApiz';
import traceApiz from './apis/traceApiz';
import toolApiz from './apis/toolApiz';

const apiz = {
    ...authApiz,
    ...bizApiz,
    ...codeApiz,
    ...menuApiz,
    ...moldApiz,
    ...prodApiz,
    ...procApiz,
    ...salesApiz,
    ...userApiz,
    ...deliApiz,
    ...workApiz,
    ...manuApiz,
    ...inOutApiz,
    ...deptApiz,
    ...orderApiz,
    ...equpApiz,
    ...cmnApiz,
    ...qualApiz,
    ...prodProcApiz,
    ...stockApiz,
    ...comApiz,
    ...traceApiz,
    ...toolApiz,
}

export default apiz;

import React, { useState, useEffect } from 'react';
import { SelectBox } from 'devextreme-react';
import _ from 'lodash';

/** components */
/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  설비 선택 조회
 */
const EqupSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {

        width='100%',
        value,
        placeholder='설비를 선택하세요.',
        type,

        lineYn='N',
        lineValue,
        equpTp,

        useYn='Y',
        ecc1UseYn,  // 일상점검 사용여부

        sortCol='',
        sortTp='',

        searchEnabled=true,
        showClearButton=true,
        onLoadInfo,
        onValueChanged,

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [equps, setEqups] = useState([]);
    const [lineUid, setLineUid] = useState(lineValue);
    const [lines, setLines] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadPage();
    },[lineValue]);


     /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            const r2 = await cmn.api("getLineCombo", {});
            if(r2 === undefined || r2 == null) return;  
            setLines(r2.d0);  

            //lineLoadInfo('');
            const ps = {p: { useYn: useYn, ecc1UseYn: ecc1UseYn, sortCol: sortCol, sortTp: sortTp }};
            const r = await cmn.api("getEqupList", ps);
            if(r === undefined || r == null) return;        
    
            setEqups(r.d0);
            if(util.getChkNull(value) !== ''){
                handleLoadInfo(r.d0, value);
            }
        }
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleLoadInfo = (ds, v) => {
        const o = _.find(ds, {equpUid: v});
        if(o == null || o === undefined)
        return;
        
     //   if(lineUid !== o.lineUid)

        lineLoadInfo(o.lineUid, false);

        onLoadInfo && onLoadInfo(o);
    }

    const lineLoadInfo = async(e, reset) =>{
        setLineUid(e);
        const ps = {p: { lineUid: e, useYn: useYn, ecc1UseYn: ecc1UseYn, sortCol: sortCol, sortTp: sortTp }};
        const r = await cmn.api("getEqupList", ps);
        if(r === undefined || r == null) return;  
        setEqups(r.d0);
        
        if(reset)
            onValueChanged('');
    }

    const renderEqupNm = (d) => {        
        return(
            <>
            <span className={type === 'site' ? 'site-span' : ''}>
                {d.equpNm}
            </span>
            </>
        ) 
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <div>
                <ul className="ul-row" style={{width: '100%'}}>
                    {lineYn === 'Y' ?
                    <li style={{width: width}}>
                        <SelectBox 
                            width={width}
                            placeholder={'생산라인을 선택하세요'}
                            value={lineUid}
                            dataSource={lines}
                            displayExpr={'lineNm'}
                            valueExpr={'lineUid'}
                            showClearButton={showClearButton}
                            
                            onValueChanged={(e)=>{
                                lineLoadInfo(e.value, true);
                            }} 
                        />
                    </li>
                    : ''
                    }
                    <li className="fl-r">   
                        <SelectBox 
                            width={width}
                            placeholder={placeholder}
                            value={value}
                            dataSource={equps}
                            displayExpr={'equpNm'}
                            valueExpr={'equpUid'}
                            showClearButton={showClearButton}

                            searchEnabled={searchEnabled}
                            searchMode={'contains'} // contains, startswith
                            searchExpr={['equpNm']}
                            minSearchLength={0}
                            searchTimeout={200}

                            itemRender={renderEqupNm}
                            
                            onValueChanged={(e)=>{
                                onValueChanged(e.value);
                                handleLoadInfo(equps, e.value);
                            }} 
                        />
                    </li>
                </ul>
            </div>
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default EqupSelectBox;

const stockApiz = {
    
    getStockList: {
        title: "재고 목록 조회",
        url: "/api/stock/getStockList",
        method: "POST"
    },
    getStock: {
        title: "재고 상세 조회",
        url: "/api/stock/getStock",
        method: "POST"
    },
    getStockLot: {
        title: "재고 상세 조회 (Lot)",
        url: "/api/stock/getStockLot",
        method: "POST"
    },
    addStock: {
        title: "재고 등록",
        url: "/api/stock/addStock",
        method: "POST"
    },
    upStock: {
        title: "재고 수정",
        url: "/api/stock/upStock",
        method: "POST"
    },
    delStock: {
        title: "재고 삭제",
        url: "/api/stock/delStock",
        method: "POST"
    },
    getStockListLot: {
        title: "Lot별 재고 목록 조회",
        url: "/api/stock/getStockListLot",
        method: "POST"
    },
    getFirstIngStockList: {
        title: "최초재공재고 목록 조회",
        url: "/api/stock/getFirstIngStockList",
        method: "POST"
    },
    addFirstIngStock: {
        title: "최초재공재고 등록",
        url: "/api/stock/addFirstIngStock",
        method: "POST"
    },
    addStockDueDili: {
        title: "재고실사 등록",
        url: "/api/stock/addStockDueDili",
        method: "POST"
    },
    getStockDueDiliList: {
        title: "재고실사 내역 목록 조회",
        url: "/api/stock/getStockDueDiliList",
        method: "POST"
    },
    getStorList: {
        title: "창고 목록 조회",
        url: "/api/stock/getStorList",
        method: "POST"
    },
    getStor: {
        title: "창고 상세 조회",
        url: "/api/stock/getStor",
        method: "POST"
    },
    getStorCombo: {
        title: "창고 콤보",
        url: "/api/stock/getStorCombo",
        method: "POST"
    },
    addStor: {
        title: "창고 등록",
        url: "/api/stock/addStor",
        method: "POST"
    },
    upStor: {
        title: "창고 수정",
        url: "/api/stock/upStor",
        method: "POST"
    },
    delStor: {
        title: "창고 삭제",
        url: "/api/stock/delStor",
        method: "POST"
    },
    getStorOutList: {
        title: "창고불출 다중 조회",
        url: "/api/stock/getStorOutList",
        method: "POST"
    },
    getStorOut: {
        title: "창고불출 단일 조회",
        url: "/api/stock/getStorOut",
        method: "POST"
    },
    addStorOut: {
        title: "창고불출등록",
        url: "/api/stock/addStorOut",
        method: "POST"
    },
    upStorOutRecall: {
        title: "창고불출회수",
        url: "/api/stock/upStorOutRecall",
        method: "POST"
    },
    getStockFifl: {
        title: "불출선입선출",
        url: "/api/stock/getStockFifl",
        method: "POST"
    },
    getStorRetList: {
        title: "불출선입선출",
        url: "/api/stock/getStorRetList",
        method: "POST"
    }
    
}

export default stockApiz;
import React, { useState, useEffect, useContext, useRef } from 'react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, CalendarView,
    EqupSelectBox, DayDateBox,
    SrchButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 일상점검
 */
const EqupDayChkPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * Control Items (* Combo ...)

    // * 검색
    const [chkMon, setChkMon] = useState(util.getNowMon());
    const [equpUid, setEqupUid] = useState('');
    const [chkItems, setChkItems] = useState([]);

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "점검월",
                width: "200px",
                item: 
                    <DayDateBox 
                        value={chkMon}
                        type={'month'}
                        displayFormat={cmn.DATE_FORMAT_MON_1}
                        onValueChanged={(e) => {
                            setChkMon(e.value);
                        }}                    
                    />
            },
            {   title: "설비",
                item: 
                    <>
                    <EqupSelectBox
                        width="200px"
                        ecc1UseYn='Y'
                        value={equpUid}
                        onLoadInfo={(o) => {}} 
                        onValueChanged={(v) => {
                            setEqupUid(v);
                        }}
                    />                    
                    </>        
            }
        ]
    ];

    /* #endregion */    
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [chkMon, equpUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => { 
        const ps =  {
            p: { yrMon: util.getDateToString('M', chkMon), equpUid: equpUid, chkCycleTp: cmn.CMNCO_CHKCYCLE.DAY }
        }
        const r = await cmn.api("getEqupChkMon", ps);
        if(r == null) return;
        setChkItems(r.d0);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Functions

    const openCrudPopup = (mode, uid, chktp, chkdy, tmtp) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equpdaychkpop?${qs.stringify({ mode: mode, uid: uid, chktp: chktp, chkdy: chkdy, tmtp: tmtp })}`; 
        util.openWindow(url, 1000, 700);    
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    marginRight={0}
                /> */}
            </ListTopView>       
            <ListSearchView searchItems={searchItems} /> 
                <CalendarView
                    yrMon={chkMon}
                    workTp="equp-chk"
                    uid={equpUid}
                    datas={chkItems}
                    onClick={(uid, dy, timeTp) => {
                        openCrudPopup('a', uid, cmn.CMNCO_CHKCYCLE.DAY, dy, timeTp);
                    }}
                />
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupDayChkPage;
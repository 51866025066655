import React, { useState, useEffect, useContext } from 'react';
import { Observer} from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox, SrchButton, ActionButton, DayDateBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  설비가동률
 */
const EqupOperatePage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate('d', -7));
    const [endDy, setEndDy] = useState(util.getNow());
    const [prodNm, setProdNm] = useState('');
    const [procNm, setProcNm] = useState('');

    const [selectedItem, setSelectedItem] = useState(null);

    /* #region Search Items */

    const searchItems = [
        [
            {   title: "작업일",
                width: "200px",
                item: 
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="제품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />         
            },
            {   title: "설비명",
                item: 
                <TextBox 
                    width={200}
                    value={procNm}
                    showClearButton={true}
                    placeholder="설비명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProcNm(e.value);
                    }}
                /> 
            }
        ]
    ];

    /* #endregion */

    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, startDy, endDy]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy), 
                endDy: util.getDateToString('d',endDy), 
                prodNm: prodNm, 
                procNm: procNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.bizUid);
        }
    }

    const handleClickView = async () => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.bizUid);
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, bizUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/bizpop?${qs.stringify({mode: mode, uid: bizUid})}`;        
        util.openWindow(url, 700, 500);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                /> */}
            </ListTopView>   
            }</Observer>    
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="시간당 생산량"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView> 
            <Observer>{() =>
            <>        
            <DataGridView            
                dataSource={[]}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H10}                

                // paging
                page={curPage}
                total={0}   // store
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}  
                
                colsWidth={[100, 150, 100, 100, 150, 100, 100, null]}
            >
                { cmn.getGridColumn('phone', '작업일') }
                { cmn.getGridColumn('bizNum', '설비명', renderGridHeaderCell) }
                { cmn.getGridColumn('bizTpNm', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('bizNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('ownerNm', 'Lot', renderGridHeaderCell) }
                { cmn.getGridColumn('bizCate', '주간 근무시간') }
                { cmn.getGridColumn('bizCond', '야간 근무시간') }
                { cmn.getGridColumn('addr', '설비 가동률') }
            </DataGridView>
            </>
            }</Observer>
            </> 
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupOperatePage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { SelectBox, TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  구매품 수입검사
 */
const OrderInInspPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

     // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate(-7));
    const [endDy, setEndDy] = useState(util.getNow());    
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [passYn, setPassYn] = useState('');


    /* #region Search Items */
    const searchItems = [
        [
            {   title: "입고일",
                width: "180px",
                item: 
                    <>
                    <ul className="ul-row">
                        <li>
                            <DayDateBox 
                                value={startDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setStartDy(e.value);
                                }}                    
                            />
                        </li>
                        <li>
                            ~
                        </li>
                        <li>            
                            <DayDateBox 
                                value={endDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setEndDy(e.value);
                                }}                    
                            />
                        </li>
                    </ul>
                    </>
            },
            {   title: "품번",
                width: "200px",
                item: 
                <>
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />     
                </>           
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "점검결과",
                item: 
                <SelectBox 
                    width={100}
                    value={passYn}      
                    displayExpr={'name'}
                    valueExpr={'value'}                      
                    items={[ 
                        { name: "합격", value: "Y" }, 
                        { name: "불합격", value: "N" }, 
                        { name: "미실행", value: "B" } 
                    ]}
                    placeholder="전체"
                    showClearButton={true}
                    onValueChanged={(e)=>{
                        setPassYn(e.value);
                    }}
                />   
            }
        ]
    ];

    /* #endregion */

    // * Grid
    const [procInspHisListIn, setProcInspHisListIn] = useState([]);
    const [procInspHisListInTot, setProcInspHisListInTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});


    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, passYn]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodTps: [cmn.CMNCO_PRODTP.ONE
                    , cmn.CMNCO_PRODTP.HALF
                    , cmn.CMNCO_PRODTP.ASSY
                    , cmn.CMNCO_PRODTP.MERC],
                prodId: prodId,
                prodNm: prodNm,
                passYn: passYn,
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),   

                offset: rowsOffset,
                limit: rowsLength,
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
            }
        }

        const r = await cmn.api("getProcInspHisListIn", ps );
        if(r === undefined || r == null) return;

        setProcInspHisListIn(r.d0)
        setProcInspHisListInTot(r.tot);

    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }   
    }

    const handleClickAdd = (e) => {
        if(util.chkListSelected(selectedItem, '검사대상을')) {
            openCrudPopup("a", selectedItem.inUid);    
        }   
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "inDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
        else if(gc.column.dataField === "inAmt" 
            ||  gc.column.dataField === "badAmt" 
        ) e.value =util.getNumComma(gc.value);
        else if( gc.column.dataField === "passYn" 
        ) e.value = gc.value === 'Y' ? "합격" : gc.value === 'N' ? "불합격" : "";
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) {    
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Funtions
    const openCrudPopup = (mode, uid) => {
        const url = `/qual/ininsppop?${qs.stringify({mode: mode, uid: uid, intp: ''})}`;
        util.openWindow(url, 920, 620);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="검사"
                    type="2"
                    show={authStore.page.crtYn}
                />
            </ListTopView>
            }</Observer>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="구매품 수입검사 내역"
            >
                {/* <IconButton
                    className="btn btn-isz ico-print"
                /> */}
               <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>
            <Observer>{() =>
            <DataGridView
                reff={grid1}
                dataSource={procInspHisListIn}
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[cmn.CW.bizNm, cmn.CW.procNm, cmn.CW.lot
                    , cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    , cmn.CW.dy, cmn.CW.unit, cmn.CW.amt, cmn.CW.amt
                    , cmn.CW.yn, cmn.CW.userNm, cmn.CW.userNm,  null]}
                // paging
                page={curPage}
                total={procInspHisListInTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}
                // excel
                exlnm={'구매품 수입검사 내역'}
                customizeExcelCell={customizeExcelCell}                 
            >
                { cmn.getGridColumn('bizNm', '입고업체', renderGridHeaderCell) }
                { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell) }
                { cmn.getGridColumn('lot', 'Lot', renderGridHeaderCell) }
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('inDy', '입고일', renderGridHeaderCell) }
                { cmn.getGridColumn('unitTpNm', '단위', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('inAmt', '입고량', renderGridHeaderCell) }                
                { cmn.getGridColumnNumCommaType('badAmt', '불량수량', renderGridHeaderCell) }
                { cmn.getGridColumnQualRet('passYn', '결과', renderGridHeaderCell) }
                { cmn.getGridColumn('inUserNm', '입고자', renderGridHeaderCell) }
                { cmn.getGridColumn('inspUserNm', '검수자', renderGridHeaderCell) }
                <Column />
            </DataGridView>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default OrderInInspPage;
import React, { useState, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox, NumberBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';
import moment from 'moment';

/** components */
import {
    DayDateBox, SrchButton, UserSelectBox, 
    ActionButton, SiteLayout, BadAmtBox,DateTimeBox,
    GridTopView, ListSearchView, DataGridView, BizSelectBox
} from '../../components';

/** stores & lib */

import { AuthStore, CmnStore } from '../../stores';

import { util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const SiteManuRetAftProcPage = (props) => {

    const {
        location,
        ls = qs.parse(location.search),
        lt = ls.lt,
        pcd = ls.pcd
    } = props;

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    
    
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [lot, setLot] = useState(util.getChkNull(lt));  

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "Lot",
                item: 
                    <TextBox 
                        value={lot}
                        width={'200px'}
                        showClearButton={true}
                        placeholder="Lot로 검색하세요."
                        onValueChanged={(e)=>{
                            setLot(e.value);
                        }}
                    /> 
            }
        ]
    ];    

    //
    const [workNum, setWorkNum] = useState('');   
    const [manuDy, setManuDy] = useState(util.getNow());   
    const [manuDt, setManuDt] = useState(util.getNow('m'));
    const [userUid, setUserUid] = useState(authStore.user.uid);  
    const [manuAmt, setManuAmt] = useState(0);  
    const [possAmt, setPossAmt] = useState(0);  
    const [goodAmt, setGoodAmt] = useState(0);  
    const [procNm, setProcNm] = useState('');  
    const [prodProcUid, setProdProcUid] = useState('');  
    const [badAmts, setBadAmts] = useState([]);  
    const [unitTp, setUnitTp] = useState('');  
    const [unitTpNm, setUnitTpNm] = useState('');  
    const [prodUid, setProdUid] = useState('');  
    const [lastYn, setLastYn] = useState('');  
    const [procCd, setProcCd] = useState(util.getChkNull(pcd));  
    const [workListItems, setWorkListItems] = useState([]);  
    const [stndImgFile, setStndImgFile] = useState('');  
    const [equpChkYn, setEqupChkYn] = useState(''); 
    const [equpUid, setEqupUid] = useState(''); 
    const [equpNm, setEqupNm] = useState(''); 
    const [procPrice, setProcPrice] = useState(0); 

    const [dyStartTm, setDyStartTm] = useState('');
    const [dyEndTm, setDyEndTm] = useState('');
    const [lotTp, setLotTp] = useState('71002');
    const [dyNtUseYn, setDyNtUseYn] = useState('Y');
    const [bizUid, setBizUid] = useState('');


    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadList1();
    },[lot]);

    useEffect(() => {
        !pageLoad && loadProdProcInfo();
    },[procCd, prodUid]);

    useEffect(() => {
        !pageLoad && loadProdProcAmtInfo();
    },[prodProcUid]);

    useEffect(() => {
        !pageLoad && loadWorkList();
    },[workNum]);

    useEffect(() => {
        !pageLoad && loadManuAmt();
    },[badAmts, goodAmt]);
    
    // useEffect(() => {
    //     //loadList1();
    // }, []);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        if(util.getChkNull(lot) === '') return;

        let ps = {p:{lot:lot}};
        let r = await cmn.api('getLot', ps);
        if(r == null || r.d0.length === 0) return;

        let data = r.d0[0];
        setProdUid(data.prodUid);
      //  setLot(data.lot);
        setWorkNum(data.workNum);
    }

    const loadWorkList = async() => {
        if(util.getChkNull(workNum) === '') return;

        const para =  {
            p: {
                workNum: workNum === '' ? 'x' : workNum, 

                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        }

        const r = await cmn.api('getWorkList', para);
        if(r == null || r === undefined || r.d0.length === 0) return;

        const data = r.d0;
        let workListItems_ = [];
        
        _.forEach(data, (v,k) => {
            workListItems_.push({
                prodUid: v.prodUid
                ,workNum: v.workNum
                ,prodNm: v.prodNm
                ,prodId: v.prodId
                ,prodStnd: v.prodStnd
                ,equpNm: v.equpNm
                ,workAmt: v.workAmt
                ,accrueManuAmt: v.accrueManuAmt
                ,workPer: v.workPer
            })
        })
        
        setWorkListItems(workListItems_);
    }

    // 제품공정의 수량를 조회
    const loadProdProcAmtInfo = async() => {
        if(util.getChkNull(prodProcUid) === '') return;

        const ps = {
            p: {
                prodProcUid: prodProcUid,
                workNum: workNum,
                lot: lot
            }
        }
        const r = await cmn.api('getProdProcAmt', ps)
        if(r == null || r.d0.length === 0)  return;
        const data = r.d0[0];
        
        setPossAmt(data.manuAmt);
    //    setManuAmt(data.manuAmt);
    }

    const loadProdProcInfo = async() =>{
        if(util.getChkNull(procCd) === '') return;

        if(util.getChkNull(prodUid) === '') 
        {
            alert('[Lot]를 먼저 조회해 주세요.');
            setProcCd('');
            return;
        }
        let ps = {p: {prodUid: prodUid, procCd: procCd}};

        const r = await cmn.api('getProdProc', ps);
        if(r == null || r.d0.length === 0) 
        {
            setProdProcUid('');
            setProcNm('');
            setLastYn('');
            setPossAmt(0);
            setManuAmt(0);
            setStndImgFile('');
            setEqupUid('');
            setEqupNm('');
            setEqupChkYn('');
            return;
        }
        let data = r.d0[0];

        if(data.mainBizYn === 'N')
        {
            setProdProcUid('');
            setProcNm('');
            setLastYn('');
            setPossAmt(0);
            setManuAmt(0);
            setStndImgFile('');
            setEqupUid('');
            setEqupNm('');
            setEqupChkYn('');
            setProcPrice(0);
        }else{
            setProdProcUid(data.prodProcUid);
            setProcNm(data.procNm);
            setLastYn(data.lastYn);
            setStndImgFile(data.stndImgFile);
            setEqupUid(data.equpUid);
            setEqupNm(data.equpNm);
            setEqupChkYn(data.equpChkYn);
            setProcPrice(data.procPrice);
        }
        
    }

    const loadManuAmt = () => {
        setManuAmt(goodAmt + _.sumBy(badAmts, 'badAmt'));
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    const handleClickSearch = (e) => {
        loadList1();
    }

     
    const handleClickProcFlowTag = () => {
        if(util.getChkNull(lot) === '' || util.getChkNull(prodUid) === '')
        {
            alert('[Lot]를 먼저 조회해 주세요.');
            return;
        }
        const url = `/cmn/procflowtagpop?${qs.stringify({uid: prodUid, lot: lot})}`;        
        util.openWindow(url, 820, 840); 
    }

    const handleClickProcInsp = async(e) => {
        openProcInspPopup('a', '', util.getDateToString('d',manuDy)
            , prodUid, prodProcUid, workNum, lot,  equpUid);
    }     

    const handleClickComplete = async(e) => {

        if(!util.chkMustValue(lot, 'Lot'))
            return;
        if(!util.chkMustValue(userUid, '작업자'))
            return; 
        if(!util.chkMustValue(manuAmt, '생산량', 'cnt'))
            return;

        //생산량과 양품량 + 불량품량이 같아야함.
        // let totBadAmts = _.sumBy(badAmts, 'badAmt');
        // if(manuAmt !== goodAmt + totBadAmts)
        // {
        //     alert('[양품수량]과 [불량수량]의 합은 [생산수량]과 동일해야합니다.');
        //     return;
        // }

        let totBadAmts = _.sumBy(badAmts, 'badAmt');
        if(possAmt < goodAmt + totBadAmts)
        {
            util.cmnAlert('[양품량]과 [불량품량]의 합이 [생산가능수량]보다 큽니다.');
            return;
        }

        if(util.confirmYn('실적등록'))
            addManu();
    }

    const openProcInspPopup = (mode, uid, pdy, pduid, ppuid, pwn, plot,  peuid) => {
        const url = `/qual/procinsppop?${qs.stringify({mode: mode, uid: uid, pdy: pdy, pduid: pduid, ppuid: ppuid
            , pwn: pwn, plot: plot, peuid: peuid
            , ptp: 'site'
        })}`;
        util.openWindow(url, 1000, 720);
    }

    const addManu = async() => {
        let workList_ = workListItems[0];

        let para = {p: {workNum: workList_.workNum}};

        // 생성 기준일 조회
        let r2 = await cmn.api('getWorkNum', para);
        if(r2 == null || r2.d0.length == 0) {
            util.cmnAlert('등록된 정보가 없습니다.');
            return;
        }

        let data = r2.d0[0];
        let manuDy_ = util.getDateToString('d', manuDt, '-');
        let format_ = 'YYYY-MM-DD HH:mm';
        let dyStartTm_ = moment(`${manuDy_} ${util.getDateStrSplit('t' , data.dyStartTm, ':')}`).format(format_);
        let dyEndTm_ = moment(`${manuDy_} ${util.getDateStrSplit('t' , data.dyEndTm, ':')}`).format(format_);
        let manuStndDy_ = cmn.getCrtDy(data.lotTp, data.dyNtUseYn, dyStartTm_, dyEndTm_, manuDt);

        const ps = {
            p: {
                    manuUid: util.getUUID(),
                    comUid: authStore.com.uid,
                    prodUid: workList_.prodUid,
                    prodProcUid: prodProcUid,
                    equpUid: equpUid,
                    lot: lot,
                    workNum: workList_.workNum,
                    manuDy: util.getDateToString('d',manuDy),
                    manuStndDy: manuStndDy_,
                    manuDt: manuDt,
                    userUid: userUid,
                    manuAmt: manuAmt,
                    goodAmt: goodAmt,
                    unitWght: 0,
                    cmnt: '',
                    lastYn: lastYn,
                    procPrice: procPrice,
                    uptUserUid: authStore.user.uid,
                    badAmts: badAmts,
                    bizUid: bizUid
                }
        }

        // console.log("===== addManu para", para);
        const r = await cmn.api('addManu', ps);
        if(r == null || r === undefined) return;

        if(window.confirm('[공정이동표]를 출력하시겠습니까?'))
            handleClickProcFlowTag(prodUid, lot);
        else
            window.location.reload();

       // window.location.reload();
    }


    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                <GridTopView
                    title="작업지시 정보"
                >
                    <SrchButton 
                        id="srchButton"
                        type={"site-1"}
                        onClick={handleClickSearch}
                    />
                    <ActionButton 
                        title="검사등록"
                        width={"140px"}
                        type={"site-3"}
                        onClick={handleClickProcInsp}
                        show={true}
                    />
                    <ActionButton 
                        title="실적등록"
                        width={"160px"}
                        onClick={handleClickComplete}
                        show={true}
                        type={"site-1"}
                    />
                    <ActionButton 
                        title="공정이동표"
                        width={"160px"}
                        onClick={handleClickProcFlowTag}
                        show={true}
                        type={"site-3"}
                    />
                </GridTopView>
                <ListSearchView searchItems={searchItems} type={"site"}  />
                <Observer>{() =>
                <>
                <DataGridView            
                    dataSource={workListItems}   // store
                    gridHeight={80}
                    colsWidth={[200, 200, 200, null, null, null, null]}
                    showNo={false}
                    pagingable={false}       
                    selection=''                         
                >
                    { cmn.getGridColumn('workNum', '작업지시번호')}
                    { cmn.getGridColumn('prodNm', '품명')}
                    { cmn.getGridColumn('prodId', '품번')}
                    { cmn.getGridColumn('prodStnd', '규격')}
                    { cmn.getGridColumnNumCommaType('workAmt', '작업지시수량(EA)')}
                    { cmn.getGridColumnNumCommaType('accrueManuAmt', '누적생산수량(EA)')}
                    { cmn.getGridColumnPer('workPer', '진행률(%)')}
                </DataGridView>
                </>
                }</Observer>
                    <div className="site_list_type fl-l" style={{width: "49%", marginTop: "20px"}}>
                        <table>
                            <colgroup>
                                <col className="tit" style={{width:'20%'}}/>
                                <col className="tcon" style={{width:'30%'}}/>
                                <col className="tit" style={{width:'20%'}}/>
                                <col className="tcon" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="tit" colSpan={4} >공정상태 정보(4M)</th>
                                </tr>
                                <tr>
                                    <th className="tit">공정코드</th>
                                    <td className="tcon">
                                        <TextBox 
                                            value={procCd}
                                            width={'200px'}
                                            showClearButton={true}
                                            placeholder={'공정코드를 입력해주세요.'}
                                            onValueChanged={(e)=>{
                                                setProcCd(e.value);
                                            }}
                                        /> 

                                    </td>
                                    <th className="tit">공정명</th>
                                    <td className="tcon">
                                        <span>{procNm}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">작업일</th>
                                    <td className="tcon">
                                        {/* <DayDateBox
                                            value={manuDy}
                                            onValueChanged={(e) => {
                                                setManuDy(e.value);
                                            }}                    
                                        /> */}
                                        <DateTimeBox
                                            width={"200px"}
                                            value={manuDt}                                    
                                            onValueChanged={(dt) => { 
                                                setManuDy(util.getDateToString('d',dt));
                                                setManuDt(dt);
                                            }}
                                        />
                                    </td>
                                    <th className="tit">작업자</th>
                                    <td className="tcon">
                                        <UserSelectBox
                                            value={userUid}
                                            showClearButton={false}
                                            placeholder="작업자를 선택하세요"
                                            onValueChanged={(v) => { 
                                                setUserUid(v);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">업체</th>
                                    <td className="tcon" colSpan={3}>
                                        <BizSelectBox
                                            width={'200px'}
                                            value={bizUid}
                                            isDefaultVal={true}
                                            showClearButton={false}
                                            onInit={(i) => {
                                                setBizUid(i.bizUid);
                                            }}
                                            onValueChanged={(v) => {
                                                setBizUid(v);
                                            }}
                                        />  
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">공정설비</th>
                                    <td className="tcon">
                                       { equpNm }
                                    </td>
                                    <th className="tit">설비 점검여부</th>
                                    <td className="tcon">
                                       { equpChkYn === 'Y' ? '점검완료' : '미점검' }
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">작업표준서</th>
                                    <td className="tcon" colSpan={3}>
                                       { cmn.getRenderDownloadFile(stndImgFile) }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="site_list_type fl-r" style={{width: "49%", marginTop: "20px"}}>
                        <table>
                            <colgroup>
                                <col className="tit" style={{width:'20%'}}/>
                                <col className="tcon" style={{width:'30%'}}/>
                                <col className="tit" />
                                <col className="tcon" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="tit" colSpan={4} >작업등록</th>
                                </tr>
                                <tr>
                                    <th className="tit req">생산량/생산가능수량</th>
                                    <td className="tcon" colSpan={3}>
                                        {/* <NumberBox
                                            width={150}
                                            format={'#,##0.###'}
                                            value={manuAmt}
                                            defaultValue={0}
                                            min={0}
                                            max={possAmt}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setManuAmt(e.value);
                                            }}
                                        /> */}
                                        {manuAmt} / {possAmt}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">양품수량</th>
                                    <td className="tcon" colSpan={3}>
                                        <NumberBox
                                            width={150}
                                            format={'#,##0.###'}
                                            value={goodAmt}
                                            defaultValue={0}
                                            min={0}
                                            max={possAmt}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setGoodAmt(e.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">불량수량</th>
                                    <td className="tcon" colSpan={3}>
                                        <BadAmtBox
                                            mode={'a'}
                                            badAmts={badAmts}
                                            unitTp={unitTp}
                                            unitTpNm={unitTpNm}
                                            onClickAdd={(badAmts) => { setBadAmts(badAmts); }}
                                            onClickDel={(badAmts) => { setBadAmts(badAmts); }}
                                        />
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>

                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"작업등록 (후공정)"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteManuRetAftProcPage;
const salesApiz = {
    
    getSalesList: {
        title: "수주 목록 조회",
        url: "/api/sales/getSalesList",
        method: "POST"
    },
    getSales: {
        title: "수주 상세 조회",
        url: "/api/sales/getSales",
        method: "POST"
    },
    getSalesNum: {
        title: "수주 번호 조회",
        url: "/api/sales/getSalesNum",
        method: "POST"
    },
    addSales: {
        title: "수주 등록",
        url: "/api/sales/addSales",
        method: "POST"
    },
    upSales: {
        title: "수주 수정",
        url: "/api/sales/upSales",
        method: "POST"
    },
    delSales: {
        title: "수주 삭제",
        url: "/api/sales/delSales",
        method: "POST"
    },
    getSalesNum: {
        title: "수주 번호 조회",
        url: "/api/sales/getSalesNum",
        method: "POST"
    },
    getSalesItem: {
        title: "수주 Item 조회",
        url: "/api/sales/getSalesItem",
        method: "POST"
    },
    getSalesDeliList: {
        title: "수주 대비 납품 현황 조회",
        url: "/api/sales/getSalesDeliList",
        method: "POST"
    },
    getSalesDeli: {
        title: "수주 대비 납품 상세",
        url: "/api/sales/getSalesDeli",
        method: "POST"
    },
    addEsti: {
        title: "견적서 등록",
        url: "/api/sales/addEsti",
        method: "POST"
    },
    upEsti: {
        title: "견적서 수정",
        url: "/api/sales/upEsti",
        method: "POST"
    },
    delEsti: {
        title: "견적서 삭제",
        url: "/api/sales/delEsti",
        method: "POST"
    },
    getEstiList: {
        title: "견적서 목록 조회",
        url: "/api/sales/getEstiList",
        method: "POST"
    },
    getEsti: {
        title: "견적서 상세 조회",
        url: "/api/sales/getEsti",
        method: "POST"
    },
    getEstiNum: {
        title: "견적서 번호 조회",
        url: "/api/sales/getEstiNum",
        method: "POST"
    },
    getEstiItem: {
        title: "견적서 Item 조회",
        url: "/api/sales/getEstiItem",
        method: "POST"
    },
    getSalesNDeliAmt: {
        title: "수주대비 납품실적 조회",
        url: "/api/sales/getSalesNDeliAmt",
        method: "POST"
    },
    upSalesCompYn: {
        title: "수주마감 및 취소",
        url: "/api/sales/upSalesCompYn",
        method: "POST"
    },
    
}

export default salesApiz;
import React, { useState, useEffect, useContext } from 'react';
import { TextBox, NumberBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';

/** components */
import {
    ActionButton, SiteLayout, DataGridView, SubActionButton, EditGridView, ProdSearchBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  설비 작업 현황
 */
const SiteEqupWorkStaPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);    

    const [equps, setEqups] = useState([]);
    
    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            // await loadTimeTp();
            await loadList1();
            setPageLoad(false);    
            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const para =  {
            p: {
                connYn: 'Y', 
                delYn: 'N',
                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        }
        
        const r = await cmn.api("getEqupConnHisList", para);
        if(r === undefined || r == null) return;

        setEqups(r.d0);
    }

    /**
     * 작지번호로 정보조회 후 표시 ... 시스템에서는 정보 입력
     * 
     * @param {*} euid : equpUid
     * @param {*} wn : workNum
     */
    const loadWorkNum = async(wn, i) => {
       
       
        const wn_ = wn;//cmn.getWorkNum(wn);

        if(wn_ === ''){
            util.cmnAlert('작업번호를 입력해 주십시오.');
            setEqupsData('', '', '', 0, 0, '', i);
            return;
        }

        const r = await cmn.api("getWorkWn", {p: { workNum: wn_ }} );
        if(r === undefined || r == null) return;
        
        if(r.d0.length === 0) {
            util.cmnAlert('등록된 작업이 없습니다.');
            setEqupsData('', '', '', 0, 0, 0, '', i);
            return;
        }

        const d0 = r.d0[0];
        setEqupsData(d0.prodUid, d0.prodNm, d0.prodId, d0.workAmt, d0.workAmt, 0, wn_, i);
    }

    const loadProdInfo = async(e, i) => {
        let r = await cmn.api('getProd', {p: {prodUid: e}} )
        if(r == null || r === undefined) return;

        const d0 = r.d0[0];
        
        setEqupsData(e, d0.prodNm, d0.prodId, 0, '', '', '', i);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const setEqupsData = async(puid, pnm, pid, wamt, tamt, samt, wn,  i) => {
        console.log()
        const equps_ = [...equps];
        equps_[i].prodUid = puid;
        equps_[i].prodNm = pnm;
        equps_[i].prodId = pid;
        equps_[i].workAmt = wamt;
        equps_[i].targetAmt = tamt;
        equps_[i].startAmt = samt;
        equps_[i].workNum = wn;
        setEqups(equps_);
    }

    // const setEqupsDataWn = async(wn,  i) => {
    //     const equps_ = [...equps];
    //     equps_[i].workNum = wn;
    //     setEqups(equps_);
    // }

    const setEqupsDataAmt = async(amt,  i) => {
        const equps_ = [...equps];
        equps_[i].targetAmt = amt;
        setEqups(equps_);
    }

    const setEqupsDataStartAmt = (amt, i) => {
        const equps_ = [...equps];
        equps_[i].startAmt = amt;
        setEqups(equps_);
    }


    const addEqupConnHis = async(c) => {
        // console.log("### c", c);
        // return;

        if(!util.chkMustValue(c.data.workNum, '작지번호')) return;
        // if(!util.chkMustValue(c.data.prodUid, '제품정보')) return;
        //if(!util.chkMustValue(c.data.targetAmt, '목표수량', 'cnt')) return;

        if(!util.confirmYn('시작'))
            return;

        const uid_ = util.getUUID();
        const ps = {
            p: 
                {
                    equpConnHisUid: uid_,
                    comUid: authStore.com.uid,
                    equpUid: c.data.equpUid,
                    prodUid: c.data.prodUid,
                    connCd: c.data.connCd,
                    workNum: c.data.workNum,
                    workAmt: c.data.workAmt,
                    targetAmt: c.data.targetAmt,
                    startAmt: c.data.startAmt,
                    startDt: null,
                    endDt: null,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("addEqupConnHis", ps);
        if(r === undefined || r == null) return;

        const rr = await cmn.api("getEqupConnHis", {p: {equpConnHisUid: uid_}} );
        if(rr === undefined || rr == null) return;

        const d0 = rr.d0[0];

        const equps_ = [...equps];
        equps_[c.rowIndex].equpConnHisUid = d0.equpConnHisUid;        
        equps_[c.rowIndex].prodUid = d0.prodUid;        
        equps_[c.rowIndex].prodNm = d0.prodNm;        
        equps_[c.rowIndex].prodId = d0.prodId;     
        equps_[c.rowIndex].workAmt = d0.workAmt;   
        equps_[c.rowIndex].targetAmt = d0.targetAmt;   
        equps_[c.rowIndex].workNum = d0.workNum;
        equps_[c.rowIndex].startDt = util.getDateToString('m', d0.startDt, '-');        
      //  equps_[c.rowIndex].uptDt = util.getDateToString('m', d0.uptDt, '-'); 

        setEqups(equps_);

    }

    
    const upEqupConnHisAmt = async(c) => {
        // console.log("### c", c);
        // return;

        if(!util.chkMustValue(c.data.targetAmt, '목표수량', 'cnt')) return;
    
        if(!util.confirmYn('수정')) return;

        const ps = {
            p: 
                {
                    equpConnHisUid: c.data.equpConnHisUid,
                    targetAmt: c.data.targetAmt,
                    startAmt: c.data.startAmt,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("upEqupConnHisAmt", ps);
        if(r === undefined || r == null) return;

        const rr = await cmn.api("getEqupConnHis", {p: {equpConnHisUid: c.data.equpConnHisUid}} );
        if(rr === undefined || rr == null) return;

        const d0 = rr.d0[0];

        const equps_ = [...equps];
        equps_[c.rowIndex].equpConnHisUid = d0.equpConnHisUid;        
        equps_[c.rowIndex].prodUid = d0.prodUid;        
        equps_[c.rowIndex].prodNm = d0.prodNm;        
        equps_[c.rowIndex].prodId = d0.prodId;     
        equps_[c.rowIndex].workAmt = d0.workAmt;   
        equps_[c.rowIndex].targetAmt = d0.targetAmt;   
        equps_[c.rowIndex].workNum = d0.workNum;
        equps_[c.rowIndex].startDt = util.getDateToString('m', d0.startDt, '-'); 
        equps_[c.rowIndex].uptDt = util.getDateToString('m', d0.uptDt, '-');  

        setEqups(equps_);

    }


    const upEqupConnHisEnd = async(c) => {
        // console.log("### c", c);
        // return;

        if(!util.confirmYn('종료')) return;

        const ps = {
            p: 
                {
                    comUid: authStore.com.uid,
                    equpConnHisUid: c.data.equpConnHisUid,
                    connCd:c.data.connCd,
                    endDt: null,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("upEqupConnHisEnd", ps);
        if(r === undefined || r == null) return;

        const rr = await cmn.api("getEqupConnHis", {p: {equpConnHisUid: c.data.equpConnHisUid}} );
        if(rr === undefined || rr == null) return;

        // console.log("## rr", rr);

        const d0 = rr.d0[0];

        if(d0.endDt != null){
            const equps_ = [...equps];
            equps_[c.rowIndex].equpConnHisUid = null;        
            equps_[c.rowIndex].prodUid = null;        
            equps_[c.rowIndex].prodNm = null;        
            equps_[c.rowIndex].prodId = null;     
            equps_[c.rowIndex].workNum = null;
            equps_[c.rowIndex].workAmt = null;
            equps_[c.rowIndex].targetAmt = null;
            equps_[c.rowIndex].startAmt = null;
            equps_[c.rowIndex].startDt = null;        
            equps_[c.rowIndex].uptDt = null;       
    
            setEqups(equps_);
        }        
    }

    

    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                {/* <GridTopView title="설비 일상 점검 현황">
                    <ActionButton
                        width={"140px"}
                        onClick={handleClickInsp}
                        title="점검하기"
                        type={"site-1"}
                        show={true}
                    />
                </GridTopView>                 */}

                <DataGridView            
                    dataSource={equps}
                    selection=''
                    colsWidth={[200, 230, 200, 220, 110, 110, 170, 170, null]}
                >
                    
                    { cmn.getGridColumn('equpNm', '설비명' ) }
                    {/* <Column 
                        alignment={'center'}
                        dataField={'targetAmt'} 
                        caption={'제품검색'}  
                        cellRender={(c) => {
                            return (
                                <>
                                   
                                 {
                                    c.data.startDt == null ?
                                        <ProdSearchBox
                                            value={c.prodUid}
                                            showSearchText={false}
                                            popCenter={true}
                                            btnType={"3"}
                                            onLoadInfo={(o) => {
                                            }} 
                                            onValueChanged={(v) => {                                        
                                                loadProdInfo(v, c.rowIndex);
                                            }}
                                        />
                                        :''
                                }                        
                                </>
                            )
                        }}                                   
                    />  */}
                    <Column 
                        alignment={'center'}
                        dataField={'workNum'} 
                        caption={'작업번호'}  
                        allowEditing={false}
                        cellRender={(c) => {
                            return (
                                <>
                                {
                                    c.data.startDt == null ?                                
                                        <TextBox
                                            width={'100%'}         
                                            value={c.value}
                                            showClearButton={true}
                                            placeholder={'작업번호로 검색하세요.'}
                                            // valueChangeEvent={'keyup'}
                                            onValueChanged={(e)=>{
                                                loadWorkNum(e.value, c.rowIndex);
                                            }} 
                                            // onEnterKey={(e)=>{
                                            //     alert(e.value);
                                            //     loadWorkNum(e.value, c.rowIndex);
                                            // }} 
                                        />   
                                    :
                                    <span>{c.value}</span>
                                }
                                </>
                            )
                        }}                                   
                    />
                    {/* { cmn.getGridColumn('workNum', '작지/Lot' ) }     */}
                    { cmn.getGridColumn('prodId', '품번' ) } 
                    { cmn.getGridColumn('prodNm', '품명' ) }                               
                    <Column 
                        alignment={'center'}
                        dataField={'startAmt'} 
                        caption={'시작수량'}  
                        cellRender={(c) => {
                            return (
                                <>
                                {
                                    <NumberBox
                                        width={'100%'}         
                                        format={'#,##0.###'}
                                        defaultValue={0}
                                        min={0}
                                        value={c.value}
                                        onValueChanged={(e)=>{
                                            setEqupsDataStartAmt(e.value, c.rowIndex);
                                            c.data.startDt == null ? addEqupConnHis(c) : upEqupConnHisAmt(c);
                                        }} 
                                    />   
                                }                               
                                </>
                            )
                        }}                                   
                    /> 
                    <Column 
                        alignment={'center'}
                        dataField={'targetAmt'} 
                        caption={'목표수량'}  
                        cellRender={(c) => {
                            return (
                                <>
                                {
                                    <NumberBox
                                        width={'100%'}         
                                        format={'#,##0.###'}
                                        defaultValue={0}
                                        min={0}
                                        value={c.value}
                                       // placeholder={'목표수량을 입력하세요.'}
                                        // valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setEqupsDataAmt(e.value, c.rowIndex);
                                            c.data.startDt == null ? addEqupConnHis(c) : upEqupConnHisAmt(c);
                                        }} 
                                    />   
                                }                               
                                </>
                            )
                        }}                                   
                    /> 
                    <Column 
                        alignment={'center'}
                        dataField={'startDt'} 
                        caption={'시작'}  
                        cellRender={(c) => {
                            return (
                                <>
                                {
                                    c.value == null ?
                                    <ActionButton
                                        onClick={(e) => {
                                            // console.log("&&& c", c);
                                            addEqupConnHis(c);
                                        }}
                                        title="시작"
                                        type={"site-1"}
                                        show={true}
                                    />
                                    : <span>{util.getDateToString('m', c.value, '-')}</span>
                                    // <ActionButton
                                    //     onClick={(e) => {
                                    //         // console.log("&&& c", c);
                                    //         upEqupConnHisAmt(c);
                                    //     }}
                                    //     title="수정"
                                    //     type={"site-1"}
                                    //     show={true}
                                    // />
                                }                               
                                </>
                            )
                        }}                                   
                    /> 
                     <Column 
                        alignment={'center'}
                        dataField={'endDt'} 
                        caption={'종료'}  
                        cellRender={(c) => {
                            return (
                                <>
                                {
                                    (c.value == null && c.data.startDt != null) &&
                                    <ActionButton
                                        onClick={(e) => {
                                            // console.log("&&& c", c);
                                            upEqupConnHisEnd(c);
                                        }}
                                        title="종료"
                                        type={"site-1"}
                                        show={true}
                                    />
                                }
                                </>
                            )
                        }}                                   
                    /> 
                    <Column 
                        alignment={'center'}
                        dataField={'uptDt'} 
                        caption={'수정시간'}  
                        cellRender={(c) => {
                            return (
                                <>
                                {
                                    c.value != null ?
                                    <span>{util.getDateToString('m', c.value, '-')}</span>
                                    :
                                    ''
                                }                               
                                </>
                            )
                        }}                                   
                    /> 
                    <Column/>
                </DataGridView>  
                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"설비 작업 현황"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteEqupWorkStaPage;
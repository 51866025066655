import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer} from 'mobx-react-lite';
// import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
// import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
// import TreeView from 'devextreme-react/tree-view';
import ScrollView from 'devextreme-react/scroll-view';

import useMobileDetect from 'use-mobile-detect-hook'
import _ from 'lodash';

/** components */
// import {
//     IconButton
// } from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  Data Grid Pager
 */
const SideMenuView = (props) => {

    /* ====================
     *  Store 
     * ==================== */    

    const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const detectMobile = useMobileDetect();

    const {
        location
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */

    // const [menus, setMenus] = useState(authStore.menus2);
    const [tabIdx, setTabIdx] = useState(1);

    const menuTreeview = useRef(null);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadMenuUser();
        }
        catch(err){
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
   
    const addMenuFavi = async(menuUid, parentUid) => {
        const menuFaviUid = util.getUUID();
        const ps = {
            p: {
                menuFaviUid: menuFaviUid,
                comUid: authStore.com.uid,
                userUid: authStore.user.uid,
                menuUid: menuUid
            }
        }

        const r = await cmn.api("addMenuFavi", ps);
        if(r === undefined || r == null) return;
        await authStore.loadMenuFaviUser();
        setMenuFavi(menuFaviUid, menuUid, parentUid);
    }

    const delMenuFavi = async(menuFaviUid, menuUid, parentUid) => {
        const ps = {
            p: {
                menuFaviUid: menuFaviUid
            }
        }

        const r = await cmn.api("delMenuFavi", ps);
        if(r === undefined || r == null) return;
        await authStore.loadMenuFaviUser();
        setMenuFavi('', menuUid, parentUid);
    }

    const setMenuFavi = (menuFaviUid, menuUid, parentUid) => {
        const menus_ = [...authStore.menus];
        const i = _.findIndex(menus_, {menuUid: parentUid});
        const ii = _.findIndex(menus_[i].subMenu, {menuUid: menuUid});

        menus_[i].subMenu[ii].menuFaviUid = menuFaviUid;
        authStore.menus = menus_;
    }

    /* ====================
     *  Render
     * ==================== */ 

    const renderContent = () => {
        // console.log("### render SideMenu");
        // console.log("### menus", authStore.menus.slice());
        return (
            <>
            <Observer>{() => 
            <>
            <div className={`lnb from-left ${authStore.sideOpened === true ? "is-visible" : ""}`}>
                <div className="closeWrap">
                    <a  href="#" className="menuClose"
                        onClick={(e) => {
                            e.preventDefault();
                            authStore.sideOpened = false;
                        }} 
                    ><span></span>닫기</a>
                </div>
                <div className="lnb-container">
                    <div className="lnb-content fl-l">
                        <div className="tab-menu">

                            <input type="radio" id="tab1" name="tab" value={1} 
                                onClick={(e) => {
                                    e.preventDefault();                                    
                                    tabIdx !== 1 && setTabIdx(1);
                                }} 
                            />
                            <label htmlFor="tab1" className={`menu01 ${tabIdx === 1 ? "tab-checked": ""}`}>전체메뉴</label>

                            <input type="radio" id="tab2" name="tab" value={2} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    tabIdx !== 2 && setTabIdx(2);
                                }} 
                            />
                            <label htmlFor="tab2" className={`menu02 ${tabIdx === 2 ? "tab-checked": ""}`}>즐겨찾기</label> 
                                   
                            <div className="tab-content">
                            <ScrollView id="scrollview" 
                                scrollByContent={true}
                                showScrollbar="onHover"
                                scrollByThumb={true}
                            >
                                <div className={`${tabIdx !== 1 ? "tcon-none" : ""}`}>
                                    <div className="snb" id="snb">
                                        <ul className="snb-list">
                                        {
                                            _.map(authStore.menus, (v, k) => {
                                                
                                                // console.log("### authStore.curMenu", authStore.curMenu);
                                                //const isOpen = ((v.isOpen === undefined && v.menuUid === authStore.curMenu.parentUid) || v.isOpen === true) ? "on" ? "";

                                                return (
                                                    <li className={v.isOpen === true ? "on" : ""}
                                                        key={k}
                                                    >
                                                        <a  className={v.menuUid === authStore.curMenu.parentUid ? "active" : ""}
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                const menus_ = [...authStore.menus];
                                                                menus_[k].isOpen = !menus_[k].isOpen;               
                                                                authStore.menus = menus_;
                                                            }}
                                                        ><span className="sp">하위메뉴열기</span>{v.menuNm}</a>
                                                        <ul className="depth">
                                                        {
                                                            _.map(v.subMenu, (vv, kk) => { 
                                                                return (
                                                                    <li
                                                                        key={kk}
                                                                    >
                                                                        <a 
                                                                            className={vv.menuUid === authStore.curMenu.menuUid ? "active" : ""}
                                                                            href="#" 
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                authStore.history.push(vv.menuPath);
                                                                            }}
                                                                        >{`- ${vv.menuNm}`}</a>                                                                        
                                                                        <a className={`favBtn ${util.getChkNull(vv.menuFaviUid) !== "" ? "on" : ""}`}
                                                                            href="#" 
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if(util.getChkNull(vv.menuFaviUid) === "") {
                                                                                    addMenuFavi(vv.menuUid, vv.parentUid);
                                                                                } 
                                                                                else {
                                                                                    delMenuFavi(vv.menuFaviUid, vv.menuUid, vv.parentUid);
                                                                                } 
                                                                            }}
                                                                        >즐겨찾기 추가</a>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        </ul>

                                                    </li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div>
                                </div>
                                <div className={`${tabIdx !== 2 ? "tcon-none" : ""}`}>
                                    <div>
                                        <ul className="favmenu">
                                        {
                                            _.map(authStore.menusFavi, (v, k) => {
                                                return (                                                
                                                    <li key={k}>
                                                        <a 
                                                            className={v.menuUid === authStore.curMenu.menuUid ? "active" : ""}
                                                            href="#" 
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                authStore.history.push(v.menuPath);
                                                            }}
                                                        >{`- ${v.menuNm}`}</a>                                                          
                                                    </li>
                                                )
                                            })
                                        }
                                        </ul>
                                        {/* <li><a href="#" className="active">- 제품관리</a></li>
                                        <li><a href="#">- 공정관리</a></li>
                                        <li><a href="#">- 제품공정관리</a></li>
                                        <li><a href="#">- 제품공정상세관리</a></li>
                                        <li><a href="#">- 제품검사관리</a></li>
                                        <li><a href="#">- BOM관리</a></li> */}
                                    </div>
                                </div>
                                </ScrollView>   
                            </div>
 
                        </div>
                        
                    </div> 
                </div> 
            </div>
            <a  href="#" 
                className={`menuBtn ${authStore.sideOpened === true ? "is-visible" : ""}`}
                onClick={(e) => {
                    e.preventDefault();
                    authStore.sideOpened = true;
                }}
            >메뉴바로가기</a>
            </>
            }</Observer>
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default SideMenuView;

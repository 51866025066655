import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    IconButton, ActionButton, PopupLayout,
    ListSearchView, EditGridView
} from '../../components';

/** stores & lib */
import { CmnStore } from '../../stores';

import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const LotLblPrintPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const cmnStore = useContext(CmnStore);
    /* ====================
     *  Object
     * ==================== */

    // ...
    const {
        location,
        ls = qs.parse(location.search),
        lt = ls.lt,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [lot, setLot] = useState(util.getChkNull(lt));

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "Lot",
                item: 
                    <TextBox 
                        value={lot}
                        width={'200px'}
                        placeholder="Lot로 검색하세요."
                        showClearButton={true}
                        onValueChanged={(e)=>{
                            setLot(e.value);
                        }}
                    /> 
            }
        ]
    ];    

    //
    const [lblPrintItems, setLblPrintItems] = useState([]);  

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadLotInfo();
    },[lot]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
        try {
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadLotInfo = async() => {
        if(util.getChkNull(lot) === '') return;

        let ps = {
            p:{
                lot:lot
            }
        };

        let r = await cmn.api('getLot', ps);
        if(r == null || r === undefined) return;
        
        let data = r.d0[0];

        let lblPrintItems_ = [...lblPrintItems];

        // 입력 Validation Check
        // data = lblPrintItems_ 의 상태를 체크

        let msg = '';
        _.forEach(lblPrintItems_, (v, k) => {
            if(v.lot === data.lot) // lot 중복 체크
                msg = '이미 등록된 Lot 입니다.' 
        });

        if(msg !== '')
        {
            util.cmnAlert(msg);
            return;
        }

        lblPrintItems_.push({
            uid: util.getUUID()
            ,lot: data.lot
            ,prodNm: data.prodNm
            ,prodId: data.prodId
            ,prodStnd: data.prodStnd
            ,inDy: util.getDateStrSplit('d',data.inDy)
            ,inAmt: data.inAmt
            ,lblCnt: 0
            ,bizNm: data.bizNm
        });

      //  console.log('lblPrintItems_ ===>', lblPrintItems_);

        setLblPrintItems(lblPrintItems_);
    }

     /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickPrint = async(e) => {

        // validation Check
        if(!util.chkMustValue(_.sumBy(lblPrintItems, 'lblCnt'), '출력라벨수', 'cnt'))
            return;

        lblPrint();
    }

    const lblPrint = async() => {
        
        let para = '';
        _.forEach(lblPrintItems, (v,k) => {
            para += `|${v.lblCnt}`
            para += `^${v.lot}`
            para += `^${v.inDy}`
            para += `^${v.inAmt}`
            para += `^${v.prodId}`
            para += `^${v.prodNm}`
            para += `^${v.prodStnd}`
            para += `^${v.bizNm}`
        })

        const url = `/cmn/lblprintpop?${qs.stringify({para: para.substring(1)})}`;        
        util.openWindow(url, 820, 840); 
    }


    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div>
                <div className="btn_r mgb10">                
                    <ActionButton 
                        title="라벨출력"
                        onClick={handleClickPrint}
                        show={true}
                        width={"140px"}
                    />
                </div>
            </div>
            <div className="pop-grid">            
                <ListSearchView searchItems={searchItems} />
                    <>
                    <EditGridView            
                        dataSource={lblPrintItems}   // store
                        colsWidth={[120, null, null, null, null, null, null,  80]}
                    >
                        { cmn.getGridColumn('lot', 'Lot')}
                        { cmn.getGridColumn('prodNm', '품명')}
                        { cmn.getGridColumn('prodId', '품번')}
                        { cmn.getGridColumn('prodStnd', '규격')}
                        { cmn.getGridColumn('inDy', '입고일')}
                        { cmn.getGridColumnNumCommaType('inAmt', '입고량')}
                        <Column 
                            alignment={'center'}
                            dataField={'lblCnt'} 
                            caption={'라벨수'}
                            dataType={'number'}
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'uid'} 
                            caption={'삭제'}
                            allowEditing={false} 
                            cellRender={(d) => {
                                return (
                                    <IconButton
                                        className="btn-icon-close-1"
                                        onClick={(e) => {
                                            
                                            const lblPrintItems_ = _.remove(lblPrintItems, (v) => {
                                                console.log(d.value);
                                                console.log(v.uid);
                                                return d.value !== v.uid;
                                            });
                                    
                                            setLblPrintItems(lblPrintItems_);
                                        }}
                                        marginRight={0}
                                    />
                                )
                            }}
                        />
                    </EditGridView>
                    </>
                </div>    
            </>  
        )
    }

    return (
        <>
        <PopupLayout
            title={"라벨출력"}
            content = {renderContent()}
        />
        </>
    )
}

export default LotLblPrintPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  창고 관리
 */
const StorPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
   
    // * Control Items (* Combo ...)

    // * 검색
    const [storNm, setStorNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "창고명",
                item: 
                <TextBox 
                    width={"200px"}
                    value={storNm}
                    showClearButton={true}
                    placeholder="창고명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setStorNm(e.value);
                    }}
                />          
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [storList, setStorList] = useState([]);
    const [storListTot, setStorListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadList1 = async() => {
        const ps =  {
            p: {
                storNm: storNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }
        
        const r = await cmn.api("getStorList", ps );
        if(r === undefined || r == null) return;

        setStorList(r.d0)
        setStorListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.storUid);
        }
    }

    const handleClickDel = async () => {
        try {
            if(util.chkListSelected(selectedItem)){
                if(!util.confirmYn('삭제')) return;

                const para =  {
                    p: 
                        {
                            comUid: authStore.com.uid
                            ,storUid: selectedItem.storUid
                        }
                }

                let r = await cmn.api('delStor', para);
                if(r == null || r == undefined) return;

                util.cmnSucAlert('삭제');

                loadList1();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, storUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/stock/storpop?${qs.stringify({mode: mode, uid: storUid})}`;        
        util.openWindow(url, 600, 360);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickDel}
                    title="삭제"
                    marginRight={0}
                    show={authStore.page.delYn}
                />
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="창고"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView>     
            <DataGridView            
                dataSource={storList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={storListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}   

                colsWidth={[200, 150, null]}
            >
                { cmn.getGridColumn('storNm', '창고명', renderGridHeaderCell)}
                { cmn.getGridColumn('storCd', '창고코드', renderGridHeaderCell)}
                {/* { cmn.getGridColumn('area', 'Area', renderGridHeaderCell)}
                { cmn.getGridColumn('line', 'Line', renderGridHeaderCell)}
                { cmn.getGridColumn('cell', 'Cell', renderGridHeaderCell)} */}
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default StorPage;
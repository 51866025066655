import React, { useState, useEffect, useContext, useRef } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, PrintLayout, CheckListBox, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */
import ceo_stamp from '../../images/etc/ceo_stamp.png';

/** 
 *  거래명세서
 */
const DeliRepoPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        uid = ls.uid,
        dnum = ls.dnum
        // sd = ls.sd,
        // ed = ls.ed,
        // buid = ls.buid
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);
    const [deliItems, setDeliItems] = useState([]);

    const [deliUid, setDeliUid] = useState('');
    const [comUid, setComUid] = useState('');
    const [bizUid, setBizUid] = useState('');
    const [prodUid, setProdUid] = useState('');
    const [acctMon, setAcctMon] = useState('');
    const [deliDy, setDeliDy] = useState('');
    const [price, setPrice] = useState(0);
    const [userUid, setUserUid] = useState('');

    const [totPrice, setTotPrice] = useState(0);
    const [sumPrice, setSumPrice] = useState(0);
    const [sumTax, setSumTax] = useState(0);

    const [comNm, setComNm] = useState('');
    const [bizNum, setBizNum] = useState('');
    const [ownerNm, setOwnerNm] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [addr, setAddr] = useState('');
    const [addrDtl, setAddrDtl] = useState('');
    const [logoImgUid, setLogoImgUid] = useState('');
    const [mail, setMail] = useState('');

    const [bizBizNum, setBizBizNum] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [bizPhone, setBizPhone] = useState('');
    const [bizFax, setBizFax] = useState('');
    const [bizOwnerNm, setBizOwnerNm] = useState('');
    const [bizAddr, setBizAddr] = useState('');
    const [bizAddrDtl, setBizAddrDtl] = useState('');

    const divPrintCont = useRef(null);

    /** Items */
    const [delis, setDelis] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);
  
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {     
            if(util.getChkNull(dnum) == '') return;

            
            let r = await cmn.api('getDeliPrint', {p:{deliNum:dnum}});

            console.log('r=======', r.d0);
            const data = r.d0[0];
            const data2 = r.d0;

            setComNm(data.comNm);
            setBizNum(data.comBizNum);
            setOwnerNm(data.comOwnerNm);
            setPhone(data.comPhone);
            setFax(data.comFax);
            setAddr(util.getChkNull(data.comAddr));
            setAddrDtl(util.getChkNull(data.comAddrDtl));
            setLogoImgUid(data.logoImgUid);

            setBizNm(data.bizNm);
            setBizPhone(data.bizPhone);
            setBizFax(data.bizFax);
            setBizOwnerNm(data.bizOwnerNm);
            setBizAddr(util.getChkNull(data.bizAddr));
            setBizAddrDtl(util.getChkNull(data.bizAddrDtl));
            setBizBizNum(data.bizNum);

            let deliItems_ = [];
            _.forEach(data2, (v,k) => {
                deliItems_.push({
                    deliDy: util.getDateToString('d',v.deliDy, '-'),
                    prodStnd: v.prodStnd,
                    prodNm: v.prodNm,
                    deliAmt: v.deliAmt,
                    deliPrice: v.deliPrice,
                    price: v.deliAmt * v.deliPrice,
                    tax: parseInt((v.deliAmt * v.deliPrice) / 10)
                });
            })

            let sumPrice_ = _.sumBy(deliItems_, 'price');
            let sumTax_ = _.sumBy(deliItems_, 'tax');

            setSumPrice(sumPrice_)
            setSumTax(sumTax_)
            setTotPrice(sumPrice_ + sumTax_);

            setDeliItems(deliItems_);
            
            // // alert(sd);
            // // alert(ed);
            // // alert(buid);
            // if(util.getChkNull(sd) == '') return;
            // if(util.getChkNull(ed) == '') return;
            // if(util.getChkNull(buid) == '') return;

            
            // //회사 정보
            // let r3 = await cmn.api('getCom', {search:{comUid:authStore.com.uid}});
            // if(r3.items.length == 0) return;
            
            // const data3 = r3.items[0];
            // setComNm(data3.comNm);
            // setBizNum(data3.bizNum);
            // setOwnerNm(data3.ownerNm);
            // setPhone(data3.phone);
            // setFax(data3.fax);
            // setAddr(data3.addr);
            // setAddrDtl(data3.addrDtl);
            // setLogoImgUid(data3.logoImgUid);

            // // 업체정보 조회
            // let r2 = await cmn.api('getBiz', {search:{bizUid: buid}});
            // let data2 = r2.items[0];

            // setBizNm(data2.bizNm);
            // setBizPhone(data2.phone);
            // setBizAddr(data2.addr);
            // setBizAddrDtl(data2.addrDtl);
            // setBizBizNum(data2.bizNum);

            // // 납품 정보
            // const para =  {
            //     p: {
            //         startDy: util.getDateToString('d',sd),
            //         endDy: util.getDateToString('d',ed),
            //         bizUid: buid,
    
            //         sortCol: '',
            //         sortTp: '',
            //         offset: -1,
            //         limit: 0
            //     }
            // }
            // console.log("===== para", para);
            // let r = await cmn.api('getDeliListPrint', para);
            // let data = r.items;

            // let deliItems_ = [];
            // _.forEach(data, (v,k) => {
            //     deliItems_.push({
            //         deliDy: util.getDateToString('d',v.deliDy, '-'),
            //         prodStnd: v.prodStnd,
            //         prodNm: v.prodNm,
            //         deliAmt: v.deliAmt,
            //         deliPrice: v.deliPrice,
            //         price: v.deliAmt * v.deliPrice
            //     });
            // })
            // setTotPrice(_.sumBy(deliItems_, 'price'));
            // console.log(deliItems_);
            // setDeliItems(deliItems_);

        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }    
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            printPage();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const printPage = async() => {

        window.print();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        
        const herderH = 26;

        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div ref={divPrintCont} 
                className="print_type"
                style={{ padding: "20px 20px 0 20px", backgroundColor: "#ffffff" }}
            >

                <div style={{position: 'absolute', left: 710}}>
                    <div className="no_print" style={{marginTop: 50}} />
                    <div style={{marginTop: 88}} />
                    <img src={ceo_stamp} alt="" />
                </div>
                <div style={{position: 'absolute', left: 710}}>
                    <div className="no_print" style={{marginTop: 50}} />
                    <div style={{marginTop: 673}} />
                    <img src={ceo_stamp} alt="" />
                </div>

                <div className="deli-sender">

                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={160} />
                            <col  />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" style={{height: 26}} >거&nbsp;&nbsp;&nbsp;래&nbsp;&nbsp;&nbsp;일&nbsp;&nbsp;&nbsp;자</td>
                                <td className="tit_" style={{borderBottom: 0}} rowSpan={2}>
                                    <span style={{fontSize: 26, fontWeight: "bold"}}>거&nbsp;&nbsp;&nbsp;래&nbsp;&nbsp;&nbsp;명&nbsp;&nbsp;&nbsp;세&nbsp;&nbsp;&nbsp;서</span>
                                    <span style={{fontSize: 14}}>&nbsp;&nbsp;&nbsp;(공급자용)</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="tcon_" style={{height: 26, borderBottom: 0}}></td>
                            </tr>                        
                        </tbody>
                    </table>
                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={26} />
                            <col width={68} />
                            <col />
                            <col width={68} />
                            <col width={110} />
                            <col width={26} />
                            <col width={68} />
                            <col width={126} />
                            <col width={26} />
                            <col width={110} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" rowSpan={4} style={{fontSize: 14, borderBottom: 0}}>공<br/>급<br/>받<br/>는<br/>자</td>
                                <td className="tit_">등록번호</td>
                                <td className="tcon_" colSpan={3}>{bizBizNum}</td>
                                <td className="tit_" rowSpan={4} style={{fontSize: 14, borderBottom: 0}}>공<br/><br/>급<br/><br/>자</td>
                                <td className="tit_">등록번호</td>
                                <td className="tcon_" style={{fontWeight: "bold", fontSize: 14}} colSpan={3}>{bizNum}</td>
                            </tr>
                            <tr>
                                <td className="tit_">상&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;호<br/>(&nbsp;법인명&nbsp;)</td>
                                <td className="tcon_">{bizNm}</td>
                                <td className="tit_">전화번호</td>
                                <td className="tcon_">{bizPhone}</td>
                                <td className="tit_">상&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;호<br/>(&nbsp;법인명&nbsp;)</td>
                                <td className="tcon_" style={{fontWeight: "bold"}}>{comNm}</td>
                                <td className="tit_">성<br/>명</td>
                                <td className="tcon_" style={{fontWeight: "bold", textAlign: "left"}}>{ownerNm}</td>
                            </tr>
                            <tr>
                                <td className="tit_">사&nbsp;&nbsp;업&nbsp;&nbsp;장<br/>주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</td>
                                <td className="tcon_" colSpan={3}>{bizAddr + ' ' + bizAddrDtl}</td>
                                <td className="tit_">사&nbsp;&nbsp;업&nbsp;&nbsp;장<br/>주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</td>
                                <td className="tcon_" colSpan={3}>{addr + ' ' + addrDtl}</td>
                            </tr>
                            <tr>
                                <td className="tit_" style={{borderBottom: 0}}>합계금액</td>
                                <td className="tcon_" style={{borderBottom: 0}} colSpan={3}>{util.getNumComma(totPrice)}</td>
                                <td className="tit_" style={{borderBottom: 0}}>전화번호</td>
                                <td className="tcon_" style={{borderBottom: 0}}>{phone}</td>
                                <td className="tit_" style={{borderBottom: 0}}>팩<br/>스</td>
                                <td className="tcon_" style={{borderBottom: 0}}>{fax}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={26}/>
                            <col width={26}/>
                            <col width={140}/>
                            <col width={140}/>
                            <col width={52}/>
                            <col width={80}/>
                            <col width={100}/>
                            <col width={80}/>
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" style={{height: herderH}}>월</td>
                                <td className="tit_" style={{height: herderH}}>일</td>
                                <td className="tit_" style={{height: herderH}}>품&nbsp;&nbsp;&nbsp;&nbsp;목</td>
                                <td className="tit_" style={{height: herderH}}>규&nbsp;&nbsp;&nbsp;&nbsp;격</td>
                                <td className="tit_" style={{height: herderH}}>수량</td>
                                <td className="tit_" style={{height: herderH}}>단&nbsp;&nbsp;&nbsp;&nbsp;가</td>
                                <td className="tit_" style={{height: herderH}}>공&nbsp;급&nbsp;가&nbsp;액</td>
                                <td className="tit_" style={{height: herderH}}>세&nbsp;&nbsp;&nbsp;&nbsp;액</td>
                                <td className="tit_" style={{height: herderH}}>비&nbsp;&nbsp;&nbsp;&nbsp;고</td>
                            </tr>
                            {
                                _.map(deliItems, (v, k) => {
                                    return (
                                        <tr key={k}>
                                            <td className="tcon_" style={{height: "40px"}}>{v.deliDy.substring(5,7)}</td>
                                            <td className="tcon_">{v.deliDy.substring(8,10)}</td>
                                            <td className="tcon_">{v.prodNm}</td>
                                            <td className="tcon_">{v.prodStnd}</td>
                                            <td className="tcon_">{util.getNumComma(v.deliAmt)}</td>
                                            <td className="tcon_">{util.getNumComma(v.deliPrice)}</td>
                                            <td className="tcon_">{util.getNumComma(v.price)}</td>
                                            <td className="tcon_">{util.getNumComma(v.tax)}</td>
                                            <td className="tcon_"></td>
                                        </tr>      
                                    )
                                })
                            }
                            {
                                _.times(6 - deliItems.length, (n) => {
                                    return (
                                        <tr key={n}>
                                            <td className="tcon_" style={{height: "40px"}}></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                        </tr>    
                                    )
                                })
                            }                      
                        </tbody>
                    </table>
                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={52}/>
                            <col width={114}/>
                            <col width={52}/>
                            <col width={114}/>
                            <col width={52}/>
                            <col width={114}/>
                            <col width={52}/>
                            <col width={90}/>
                            <col width={52}/>
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" style={{borderTop: 0}}>공급<br/>가액</td>
                                <td className="tcon_" style={{borderTop: 0}}></td>
                                <td className="tit_" style={{borderTop: 0}}>세액</td>
                                <td className="tcon_" style={{borderTop: 0}}></td>
                                <td className="tit_" style={{borderTop: 0}}>합계<br/>금액</td>
                                <td className="tcon_" style={{borderTop: 0}}>{util.getNumComma(totPrice)}</td>
                                <td className="tit_" style={{borderTop: 0}}>미수<br/>금</td>
                                <td className="tcon_" style={{borderTop: 0}}></td>
                                <td className="tit_" style={{borderTop: 0}}>인수자</td>
                                <td className="tcon_" style={{borderTop: 0}}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <table>
                   <tbody>
                       <tr>
                           <td style={{border: 0, height: 16}}></td>
                       </tr>
                       <tr>
                        <td style={{border: 0, height: 16, borderTop: "1px dotted #000"}}></td>
                       </tr>
                   </tbody>
                </table>

                <div className="deli-rec">

                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={160} />
                            <col  />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" style={{height: 26}} >거&nbsp;&nbsp;&nbsp;래&nbsp;&nbsp;&nbsp;일&nbsp;&nbsp;&nbsp;자</td>
                                <td className="tit_" style={{borderBottom: 0}} rowSpan={2}>
                                    <span style={{fontSize: 26, fontWeight: "bold"}}>거&nbsp;&nbsp;&nbsp;래&nbsp;&nbsp;&nbsp;명&nbsp;&nbsp;&nbsp;세&nbsp;&nbsp;&nbsp;서</span>
                                    <span style={{fontSize: 14}}>&nbsp;&nbsp;&nbsp;(공급받는자용)</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="tcon_" style={{height: 26, borderBottom: 0}}></td>
                            </tr>                        
                        </tbody>
                    </table>
                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={26} />
                            <col width={68} />
                            <col />
                            <col width={68} />
                            <col width={110} />
                            <col width={26} />
                            <col width={68} />
                            <col width={126} />
                            <col width={26} />
                            <col width={110} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" rowSpan={4} style={{fontSize: 14, borderBottom: 0}}>공<br/>급<br/>받<br/>는<br/>자</td>
                                <td className="tit_">등록번호</td>
                                <td className="tcon_" colSpan={3}>{bizBizNum}</td>
                                <td className="tit_" rowSpan={4} style={{fontSize: 14, borderBottom: 0}}>공<br/><br/>급<br/><br/>자</td>
                                <td className="tit_">등록번호</td>
                                <td className="tcon_" style={{fontWeight: "bold", fontSize: 14}} colSpan={3}>{bizNum}</td>
                            </tr>
                            <tr>
                                <td className="tit_">상&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;호<br/>(&nbsp;법인명&nbsp;)</td>
                                <td className="tcon_">{bizNm}</td>
                                <td className="tit_">전화번호</td>
                                <td className="tcon_">{bizPhone}</td>
                                <td className="tit_">상&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;호<br/>(&nbsp;법인명&nbsp;)</td>
                                <td className="tcon_" style={{fontWeight: "bold"}}>{comNm}</td>
                                <td className="tit_">성<br/>명</td>
                                <td className="tcon_" style={{fontWeight: "bold", textAlign: "left"}}>{ownerNm}</td>
                            </tr>
                            <tr>
                                <td className="tit_">사&nbsp;&nbsp;업&nbsp;&nbsp;장<br/>주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</td>
                                <td className="tcon_" colSpan={3}>{bizAddr + ' ' + bizAddrDtl}</td>
                                <td className="tit_">사&nbsp;&nbsp;업&nbsp;&nbsp;장<br/>주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</td>
                                <td className="tcon_" colSpan={3}>{addr + ' ' + addrDtl}</td>
                            </tr>
                            <tr>
                                <td className="tit_" style={{borderBottom: 0}}>합계금액</td>
                                <td className="tcon_" style={{borderBottom: 0}} colSpan={3}>{util.getNumComma(totPrice)}</td>
                                <td className="tit_" style={{borderBottom: 0}}>전화번호</td>
                                <td className="tcon_" style={{borderBottom: 0}}>{phone}</td>
                                <td className="tit_" style={{borderBottom: 0}}>팩<br/>스</td>
                                <td className="tcon_" style={{borderBottom: 0}}>{fax}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={26}/>
                            <col width={26}/>
                            <col width={140}/>
                            <col width={140}/>
                            <col width={52}/>
                            <col width={80}/>
                            <col width={100}/>
                            <col width={80}/>
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" style={{height: herderH}}>월</td>
                                <td className="tit_" style={{height: herderH}}>일</td>
                                <td className="tit_" style={{height: herderH}}>품&nbsp;&nbsp;&nbsp;&nbsp;목</td>
                                <td className="tit_" style={{height: herderH}}>규&nbsp;&nbsp;&nbsp;&nbsp;격</td>
                                <td className="tit_" style={{height: herderH}}>수량</td>
                                <td className="tit_" style={{height: herderH}}>단&nbsp;&nbsp;&nbsp;&nbsp;가</td>
                                <td className="tit_" style={{height: herderH}}>공&nbsp;급&nbsp;가&nbsp;액</td>
                                <td className="tit_" style={{height: herderH}}>세&nbsp;&nbsp;&nbsp;&nbsp;액</td>
                                <td className="tit_" style={{height: herderH}}>비&nbsp;&nbsp;&nbsp;&nbsp;고</td>
                            </tr>
                            {
                                _.map(deliItems, (v, k) => {
                                    return (
                                        <tr key={k}>
                                            <td className="tcon_" style={{height: "40px"}}>{v.deliDy.substring(5,7)}</td>
                                            <td className="tcon_">{v.deliDy.substring(8,10)}</td>
                                            <td className="tcon_">{v.prodNm}</td>
                                            <td className="tcon_">{v.prodStnd}</td>
                                            <td className="tcon_">{util.getNumComma(v.deliAmt)}</td>
                                            <td className="tcon_">{util.getNumComma(v.deliPrice)}</td>
                                            <td className="tcon_">{util.getNumComma(v.price)}</td>
                                            <td className="tcon_">{util.getNumComma(v.tax)}</td>
                                            <td className="tcon_"></td>
                                        </tr>    
                                    )
                                })
                            }
                            {
                                _.times(6 - deliItems.length, (n) => {
                                    return (
                                        <tr key={n}>
                                            <td className="tcon_" style={{height: "40px"}}></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                            <td className="tcon_"></td>
                                        </tr>    
                                    )
                                })
                            }                      
                        </tbody>
                    </table>
                    <table style={{margin: 0}}>
                        <colgroup>
                            <col width={52}/>
                            <col width={114}/>
                            <col width={52}/>
                            <col width={114}/>
                            <col width={52}/>
                            <col width={114}/>
                            <col width={52}/>
                            <col width={90}/>
                            <col width={52}/>
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="tit_" style={{borderTop: 0}}>공급<br/>가액</td>
                                <td className="tcon_" style={{borderTop: 0}}>{util.getNumComma(sumPrice)}</td>
                                <td className="tit_" style={{borderTop: 0}}>세액</td>
                                <td className="tcon_" style={{borderTop: 0}}>{util.getNumComma(sumTax)}</td>
                                <td className="tit_" style={{borderTop: 0}}>합계<br/>금액</td>
                                <td className="tcon_" style={{borderTop: 0}}>{util.getNumComma(totPrice)}</td>
                                <td className="tit_" style={{borderTop: 0}}>미수<br/>금</td>
                                <td className="tcon_" style={{borderTop: 0}}></td>
                                <td className="tit_" style={{borderTop: 0}}>인수자</td>
                                <td className="tcon_" style={{borderTop: 0}}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div className="no_print bottom-mg" />
            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"거래명세서"}
            content = {renderContent()}
        />
        </>
    )
}

export default DeliRepoPage;

const bizApiz = {
    
    getBizList: {
        title: "업체 목록 조회",
        url: "/api/biz/getBizList",
        method: "POST"
    },
    getBiz: {
        title: "업체 상세 조회",
        url: "/api/biz/getBiz",
        method: "POST"
    },
    addBiz: {
        title: "업체 등록",
        url: "/api/biz/addBiz",
        method: "POST"
    },
    upBiz: {
        title: "업체 수정",
        url: "/api/biz/upBiz",
        method: "POST"
    },
    delBiz: {
        title: "업체 삭제",
        url: "/api/biz/delBiz",
        method: "POST"
    },
    getBizCombo: {
        title: "업체 콤보",
        url: "/api/biz/getBizCombo",
        method: "POST"
    },
    getBizProdList: {
        title: "업체별 제품 목록 조회",
        url: "/api/biz/getBizProdList",
        method: "POST"
    },
    getBizProd: {
        title: "업체별 제품 상세 조회",
        url: "/api/biz/getBizProd",
        method: "POST"
    },
    getBizProdCombo: {
        title: "업체별 제품 콤보",
        url: "/api/biz/getBizProdCombo",
        method: "POST"
    },
    addBizProd: {
        title: "업체별 제품 등록",
        url: "/api/biz/addBizProd",
        method: "POST"
    },
    upBizProd: {
        title: "업체별 제품 수정",
        url: "/api/biz/upBizProd",
        method: "POST"
    },
    delBizProd: {
        title: "업체별 제품 삭제",
        url: "/api/biz/delBizProd",
        method: "POST"
    },
    getBizComboProc: {
        title: "업체 콤보 공정",
        url: "/api/biz/getBizComboProc",
        method: "POST"
    },
    getDupBizNum: {
        title: "업체 사업자 번호 중복체크",
        url: "/api/biz/getDupBizNum",
        method: "POST"
    },
    
}

export default bizApiz;
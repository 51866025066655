import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    DayDateBox,
    BizSelectBox,
    DataGridView,
    SubActionButton,
    CancelButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** images */

/** 
 *  수주 등록 / 수정
 */
const SalesPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);   

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
        snum = ls.snum
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);
    
    const [salesItem, setSalesItem] = useState([]);
    /** Items */
    const [bizProdItems, setBizProdItems] = useState([]);
    const [prodUid, setProdUid] = useState('');
    const [bizUid, setBizUid] = useState('');
    const [bizNm, setBizNm] = useState('');
    const [planDy, setPlanDy] = useState(util.getNow());
    const [payDy, setPayDy] = useState(util.getNow());
    const [salesAmt, setSalesAmt] = useState(0);
    const [amtPrice, setAmtPrice] = useState(0);
    const [salesPrice, setSalesPrice] = useState(0);
    const [cmnt, setCmnt] = useState('');

    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [outputTp, setOutputTp] = useState('');

    const [option1, setOption1] = useState('');  // 외경
    const [option2, setOption2] = useState('');  // 내경
    const [option3, setOption3] = useState('');  // 길이

    const [salesTotPrice, setSalesTotPrice] = useState(0);  // 수주 총 금액
    

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadBizProdCombo();
    },[bizUid]);
    
    useEffect(() => {
        !pageLoad && loadBizProdInfo();
    },[prodUid]);

    useEffect(() => {
        !pageLoad && loadAmtPrice();
    },[salesPrice, salesAmt]);
    
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadInfo1 = async() => {
        if(util.getChkNull(mode) === 'a') return;
        if(util.getChkNull(snum) === '') return;

        try {   
            let r = await cmn.api('getSales', {p:{salesNum:snum}});
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            const data2 = r.d0;
            
            setBizNm(data.bizNm);
            setPlanDy(util.getDateStrSplit('d', data.planDy));
            setPayDy(util.getDateStrSplit('d', data.payDy));
            setSalesTotPrice(util.getNumComma(data.salesTotPrice));

            let salesItem_ = [];

            _.forEach(data2, (v,k) => {
                salesItem_.push(
                    {
                        salesUid: v.salesUid,
                        prodUid: v.prodUid,
                        prodNm: v.prodNm,
                        salesAmt: v.salesAmt,
                        salesPrice: v.salesPrice,
                        amtPrice: v.amtPrice,
                        option1: v.option1,
                        option2: v.option2,
                        option3: v.option3,
                        cmnt: v.cmnt,
                    });
                })

            setSalesItem(salesItem_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadBizProdInfo = async() => {
        try {  
            if(util.getChkNull(prodUid) === '') return;

            let ps = {p: {bizUid: bizUid, prodUid: prodUid}};

            let r = await cmn.api('getBizProd', ps);
            if(r == null || r === undefined) return;

            const data = r.d0[0];
            
            setProdNm(data.prodNm);
            setProdId(data.prodId);
            setProdStnd(data.prodStnd);
            setSalesPrice(data.bizPrice);
            setOutputTp(data.outputTp);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadBizProdCombo = async() => {
        try { 
            if(util.getChkNull(bizUid) == '') return;

            //하위정보 초기화
            setProdUid('');
            setProdNm('');
            setProdId('');
            setProdStnd('');
            setSalesPrice(0);
            setAmtPrice(0);

            const ps = {p: {bizUid: bizUid}} 

            let r = await cmn.api('getBizProdCombo', ps);
            if(r == null || r === undefined) return;

            setBizProdItems(r.d0);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadAmtPrice = () => {
        setAmtPrice(util.fix(_.multiply(salesAmt, salesPrice)));  
    }
    
    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try { 
            //validation
            // if(!util.chkMustValue(bizUid, '고객'))
            //     return;
            // if(!util.chkMustValue(prodUid, '제품'))
            //     return;
            // if(!util.chkMustValue(salesAmt, '수주량', 'cnt'))
            //     return;

            if(mode === "a")
            { 
                if(salesItem.length <= 0)
                {
                    util.cmnAlert("수주 등록할 제품이 없습니다.")
                    return;
                }

                if(util.confirmYn("등록"))
                    addSales();
            }
            else if(mode === "v") 
            {
                //if(util.confirmYn("취소"))
                delSales(e);
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }

    }

    const addSales = async() => {

       

        let r = await cmn.api('getSalesNum', ps);
        if(r == null || r === undefined) return;

        let salesNum_ = r.d0[0].salesNum;

        const ps = {
            p: {
                comUid: authStore.com.uid,
                salesNum: salesNum_,
                planDy: util.getDateToString('d',planDy),
                payDy: util.getDateToString('d',payDy),
                bizUid: bizUid,
                uptUserUid: authStore.user.uid,
                items: [...salesItem]
            }
        }

        console.log(ps);
        let r2 = await cmn.api('addSales', ps);
        if(r2 == null || r2 === undefined) return;

        util.cmnSucAlert("등록");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delSales = async(delCmnt) => {

        const ps = {
            p: {
                delCmnt: delCmnt,
                uptUserUid: authStore.user.uid,
                items: [...salesItem]
            }
        }

        console.log(ps);
        let r = await cmn.api('delSales', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("취소");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    
    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }
    
    const handleClickCrtRows = () => {
    //  validation
        if(!util.chkMustValue(prodUid, '제품'))
            return;
        if(!util.chkMustValue(bizUid, '고객사'))
            return;
        if(!util.chkMustValue(salesAmt, '수주량', 'cnt'))
            return;
            
        const salesItem_ = [...salesItem];

        let isDup = false;
        _.forEach(salesItem_, (v, k)=>{
            if(v.prodUid === prodUid)
            {
                util.cmnAlert('이미 등록된 제품입니다.');
                isDup = true;
            }
        })

        if(isDup) return;

        salesItem_.push(
        {
            salesUid: util.getUUID(),
            prodUid: prodUid,
            prodNm: prodNm,
            salesAmt: salesAmt,
            salesPrice: salesPrice,
            amtPrice: salesAmt * salesPrice,
            option1: option1,
            option2: option2,
            option3: option3,
            cmnt: cmnt,
            takeInfo: {
                takeUid: util.getUUID(),
                inputTp: '70502',
                prodUid: prodUid,
                outputTp: outputTp, //생산, 구매, 외주
                takeMon: util.getDateToString('M',planDy),
                takeAmt: salesAmt
            }
        });

        console.log(salesItem_);
        setSalesItem(salesItem_);

        pageInit();
    }

    const handleClickDelRows = () => {
        
        if(util.chkListSelected(selectedItem)){
            if(util.confirmYn('삭제'))
            {
                const salesItem_ = _.remove(salesItem, (v) => {
                    return selectedItem.salesUid !== v.salesUid;
                });
        
                setSalesItem(salesItem_);
            }
        }

    }
    
    const pageInit = () => {
        setSelectedItem(null);
        setProdUid('');
        setProdId('');
        setProdNm('');
        setProdStnd('');
        setSalesAmt(0);
        setOption1('');
        setOption2('');
        setOption3('');
        setCmnt('');
        setProdId('');
        setSalesPrice(0);
        setOutputTp('');
    }
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "취소완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                {mode === "v" ?
                    <CancelButton
                        title={completeButtonTitle}
                        isDel={'Y'}
                        handleClickComplete={(e) => {
                            handleClickComplete(e)
                        }} 
                    />
                    :
                    <ActionButton 
                        onClick={handleClickComplete}
                        title={completeButtonTitle}
                        marginRight={0}
                        show={true}
                    />
                }
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"33%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        {
                        mode === "a" || mode === "u" ? 
                        <>
                        <tr>
                            <th className="tit">수주일</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={planDy}
                                    onValueChanged={(e) => {
                                        setPlanDy(e.value);
                                    }}                    
                                />
                            </td>
                            <th className="tit">납기일</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={payDy}
                                    onValueChanged={(e) => {
                                        setPayDy(e.value);
                                    }}                    
                                />
                            </td>
                            
                        </tr>
                        <tr>
                        <th className="tit req">고객</th>
                            <td className="tcon">
                                <BizSelectBox
                                    value={bizUid}
                                    bizTp={['70202']}
                                    showClearButton={false}
                                    onLoadInfo={(o) => {
                                        if(salesItem.length > 0) {
                                            const _bizUid = bizUid
                                            setBizUid(_bizUid)
                                            util.cmnAlert('하나의 고객사에만 발주 가능합니다.');
                                            return
                                        }
                                        setBizNm(o.bizNm);
                                        
                                    }} 
                                    onValueChanged={(v) => {
                                        setBizUid(v);
                                    }}
                                />    
                            </td>
                            <th className="tit req">제품</th>
                            <td className="tcon">
                                <SelectBox 
                                    value={prodUid}
                                    showClearButton={false}
                                    dataSource={bizProdItems}
                                    displayExpr={'prodNm'}
                                    valueExpr={'prodUid'}
                                    placeholder="제품을 선택하세요"
                                    onValueChanged={(e)=>{
                                        setProdUid(e.value);
                                    }}
                                />
                            </td>
                            
                        </tr>
                        
                        <tr>
                            <th className="tit">품번</th>
                            <td className="tcon">
                                <span>{prodId}</span>
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{prodStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">수주량</th>
                            <td className="tcon" colSpan={3} >
                                <div className="inline-block">
                                    <NumberBox
                                        width={80}
                                        format={'#,##0'}
                                        value={salesAmt}
                                        defaultValue={0}
                                        min={0}
                                        // max={20}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setSalesAmt(e.value);
                                        }}
                                    />
                                </div>
                                <div className="inline-block mar-left-10">
                                X (단가:  <strong>{util.getNumComma(salesPrice)}</strong> 원)   
                                = (금액:  <strong>{util.getNumComma(amtPrice)}</strong> 원)    
                                </div>
                            </td>
                        </tr>   
                        {/* <tr>
                            <th className="tit">옵션</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={option1}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setOption1(e.value);
                                    }} 
                                /> 
                                <span>* 파이프 : 길이 / 직관</span><br />
                                <span>* 분배기 : 몇구 몇구 (ex 단방향 5구4구) / 위치 (ex 20mm 위치) / B캡 위치,개수 / 스탠드 높이</span><br />
                                <span>* 오픈수전함 : 연장관 유무 및 높이</span>
                            </td>
                            <th className="tit">길이</th>
                            <td className="tcon">
                                <TextBox 
                                    value={option2}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setOption2(e.value);
                                    }} 
                                /> 
                            </td>
                        </tr> */}
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                            </td>
                        </tr>
                        </>
                        :
                        <>
                        <tr>
                            <th className="tit">수주일</th>
                            <td className="tcon">
                                <span>{planDy}</span>
                            </td>
                            <th className="tit">납기일</th>
                            <td className="tcon">
                                <span>{payDy}</span>
                            </td>
                            
                        </tr>
                        <tr>
                        <th className="tit req">고객</th>
                            <td className="tcon">
                                <span>{bizNm}</span>
                            </td>
                            <th className="tit req">총 수주금액</th>
                            <td className="tcon">
                                <span>{salesTotPrice}</span>
                            </td>
                        </tr>
                        </>
                        }
                    </tbody>
                </table>
            </div>
            
            <div className="pop-grid">
                {
                    mode === "a" ?
                        <div className="clearfix">
                            <div className="btn-area fl-r">
                                <SubActionButton 
                                    onClick={handleClickCrtRows}
                                    title="추가"
                                    show={true}
                                /> 
                                <SubActionButton 
                                    onClick={handleClickDelRows}
                                    title="삭제"
                                    marginRight={0}
                                    show={true}
                                /> 
                            </div>
                        </div>
                    : ''
                }
                <div className="clearfix" style={{marginTop:'10px'}}>
                    <DataGridView
                        onSelectionChanged={handleGridSelectionChanged}     
                        showNo={false}
                        dataSource={salesItem}
                        gridHeight={180}
                        colsWidth={[200,  150, 150, null]}  
                    >
                        {/* { cmn.getGridColumnDayType('planDy', '수주일') }
                        { cmn.getGridColumnDayType('payDy', '납기일') } */}
                        { cmn.getGridColumn('prodNm', '제품명')}
                        { cmn.getGridColumnNumCommaType('salesAmt', '수주량') }
                        { cmn.getGridColumnNumCommaType('amtPrice', '수주금액') }
                        {/* { cmn.getGridColumn('option1', '옵션') } */}
                        { cmn.getGridColumnCmnt() }
                    </DataGridView>
                </div>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "수주 등록" 
                : mode === "u" ? "수주 수정"
                : mode === "v" ? "수주 상세보기"
                : "수주" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default SalesPopPage;

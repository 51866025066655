const prodProcApiz = {
    getProdProcList: {
        title: "제품별 공정 목록 조회",
        url: "/api/prodProc/getProdProcList",
        method: "POST"
    },
    getProdProc: {
        title: "제품별 공정 상세 조회",
        url: "/api/prodProc/getProdProc",
        method: "POST"
    },
    addProdProc: {
        title: "제품별 공정 등록",
        url: "/api/prodProc/addProdProc",
        method: "POST"
    },
    upProdProc: {
        title: "제품별 공정 수정",
        url: "/api/prodProc/upProdProc",
        method: "POST"
    },
    delProdProc: {
        title: "제품별 공정 삭제",
        url: "/api/prodProc/delProdProc",
        method: "POST"
    },
    getProdProcFirst: {
        title: "제품별 공정 상세 조회(최초재공재고)",
        url: "/api/prodProc/getProdProcFirst",
        method: "POST"
    },
    getProdProcListTree: {
        title: "제품별 공정 목록 Tree",
        url: "/api/prodProc/getProdProcListTree",
        method: "POST"
    },
    getProdProcDtlList: {
        title: "제품별 공정 상세 목록 조회",
        url: "/api/prodProc/getProdProcDtlList",
        method: "POST"
    },
    getProdProcDtl: {
        title: "제품별 공정 상세 상세 조회",
        url: "/api/prodProc/getProdProcDtl",
        method: "POST"
    },
    getProdProcCombo: {
        title: "제품별 공정 콤보",
        url: "/api/prodProc/getProdProcCombo",
        method: "POST"
    },
    addProdProcDtl: {
        title: "제품별 공정 상세 등록",
        url: "/api/prodProc/addProdProcDtl",
        method: "POST"
    },
    upProdProcDtl: {
        title: "제품별 공정 상세 수정",
        url: "/api/prodProc/upProdProcDtl",
        method: "POST"
    },
    delProdProcDtl: {
        title: "제품별 공정 상세 삭제",
        url: "/api/prodProc/delProdProcDtl",
        method: "POST"
    },
    getProdProcDtlCombo: {
        title: "제품별 공정 상세 콤보",
        url: "/api/prodProc/getProdProcDtlCombo",
        method: "POST"
    },
    getProcPriceHisList: {
        title: "공정 가격 정보 목록 조회",
        url: "/api/prodProc/getProcPriceHisList",
        method: "POST"
    },
    getProdProcInspList: {
        title: "제품별 공정 검사 목록 조회",
        url: "/api/prodProc/getProdProcInspList",
        method: "POST"
    },
    getProdProcInsp: {
        title: "제품별 공정 검사 상세 조회",
        url: "/api/prodProc/getProdProcInsp",
        method: "POST"
    },
    addProdProcInsp: {
        title: "제품별 공정 검사 등록",
        url: "/api/prodProc/addProdProcInsp",
        method: "POST"
    },
    upProdProcInsp: {
        title: "제품별 공정 검사 수정",
        url: "/api/prodProc/upProdProcInsp",
        method: "POST"
    },
    delProdProcInsp: {
        title: "제품별 공정 검사 삭제",
        url: "/api/prodProc/delProdProcInsp",
        method: "POST"
    },
    getProdProcAmt: {
        title: "제품별 공정 수량 조회",
        url: "/api/prodProc/getProdProcAmt",
        method: "POST"
    },
    getProdProcInspTpCombo: {
        title: "공정 검사 유형 목록 콤보",
        url: "/api/prodProc/getProdProcInspTpCombo",
        method: "POST"
    },
}

export default prodProcApiz;
import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, PrintLayout, CheckListBox, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  발주서
 */
const OrderRepoPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        guid = ls.guid,
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    const [orderItems, setOrderItems] = useState([]);

    const [bizNm, setBizNm] = useState('');
    const [bizPhone, setBizPhone] = useState('');
    const [bizFax, setBizFax] = useState('');
    const [bizAddr, setBizAddr] = useState('');
    const [bizAddrDtl, setBizAddrDtl] = useState('');
    const [orderNum, setOrderNum] = useState('');
    const [prodUid, setProdUid] = useState('');
    const [orderAmt, setOrderAmt] = useState('');
    const [payDy, setPayDy] = useState('');
    const [orderDy, setOrderDy] = useState('');
    const [orderMon, setOrderMon] = useState('');
    const [preOrderUid, setPreOrderUid] = useState('');
    const [orderPrice, setOrderPrice] = useState(0);
    const [userNm, setUserNm] = useState('');
    const [deptNm, setDeptNm] = useState('');
    const [prodProcUid, setProdProcUid] = useState('');
    const [groupUid, setGroupUid] = useState('');
    const [cmnt, setCmnt] = useState('');
    const [totPrice, setTotPrice] = useState(0);
    
    const [comNm, setComNm] = useState('');
    const [ownerNm, setOwnerNm] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [addr, setAddr] = useState('');
    const [addrDtl, setAddrDtl] = useState('');
    const [logoImgUid, setLogoImgUid] = useState('');
    const [mail, setMail] = useState('');

    useEffect(() => {
        loadPage();
    },[]);
  
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {          
            if(util.getChkNull(guid) == '') return;

            //회사 정보
            let r3 = await cmn.api('getCom', {search:{comUid:authStore.com.uid}});
            if(r3.items.length == 0) return;
            
            const data3 = r3.items[0];
            setComNm(data3.comNm);
            setOwnerNm(data3.ownerNm);
            setPhone(data3.phone);
            setFax(data3.fax);
            setAddr(data3.addr);
            setAddrDtl(data3.addrDtl);
            setLogoImgUid(data3.logoImgUid);


            // 발주 정보
            let r = await cmn.api('getOrder', {search:{groupUid:guid}});
            if(r.items.length == 0) return;
            
            let data = r.items;
            
            setBizNm(data[0].bizNm);
            setBizPhone(data[0].bizPhone);
            setBizFax(data[0].bizFax);
            setBizAddr(data[0].bizAddr);
            setBizAddrDtl(data[0].bizAddrDtl);
            setOrderNum(data[0].orderNum);
            setProdUid(data[0].prodUid);
            setOrderAmt(data[0].orderAmt);
            setPayDy(data[0].payDy);
            setOrderDy(util.getDateToString('d',data[0].orderDy, '-'));
            setOrderMon(data[0].orderMon);
            setPreOrderUid(data[0].preOrderUid);
            setOrderPrice(data[0].orderPrice);
            setUserNm(data[0].userNm);
            setDeptNm(data[0].deptNm);
            setProdProcUid(data[0].prodProcUid);
            setCmnt(data[0].cmnt);

            let orderItems_ = [];
            _.forEach(data, (v,k) => {
                orderItems_.push({
                    orderUid: util.getUUID(),
                    orderDy: util.getDateToString('d',orderDy),
                    payDy: util.getDateToString('d',payDy),
                    prodUid:v.prodUid,
                    orderNum: v.orderNum,
                    prodNm: v.prodNm,
                    prodProcUid: v.prodProcUid,
                    bizUid: v.bizUid,
                    bizNm: v.bizNm,
                    orderAmt: v.orderAmt,
                    orderPrice: v.orderPrice,
                    price: v.orderAmt * v.orderPrice,
                    prodImgUid: v.prodImgUid,
                    prodImgFile: v.prodImgFile,
                    preOrderUid: v.preOrderUid,
                    userUid: v.userUid,
                    cmnt: v.cmnt
                });
            })
            
            setTotPrice(_.sumBy(orderItems_, 'price'));

            console.log(orderItems_);
            setOrderItems(orderItems_);
            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            printPage();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const printPage = async() => {

        window.print();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        
        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div className="print_type"
                style={{ padding: "20px 20px 0 20px", backgroundColor: "#ffffff" }}
            >
                <table>
                    <colgroup>
						<col width={160} />
                        <col  />
                        <col width={280} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100, border: 0 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon" style={{border: 0}}>
                                <div
                                    style={{ fontSize: 30, fontWeight: "bold" }}
                                >
                                발&nbsp;&nbsp;&nbsp;주&nbsp;&nbsp;&nbsp;서
                                </div>
                            </td> 
                            <td style={{border: 0}}>
                               
                                <table>
                                    <colgroup>
                                        <col />
                                        <col width={80} />
                                        <col width={80} />
                                        <col width={80} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="tcon" rowSpan={2} style={{fontWeight: "bold"}}>결<br/><br/>재</td>
                                            <td className="tcon" style={{height: 28, fontWeight: "bold"}} >담&nbsp;&nbsp;&nbsp;당</td>
                                            <td className="tcon" style={{height: 28, fontWeight: "bold"}} >검&nbsp;&nbsp;&nbsp;토</td>
                                            <td className="tcon" style={{height: 28, fontWeight: "bold"}} >승&nbsp;&nbsp;&nbsp;인</td>
                                        </tr>
                                        <tr>
                                            <td style={{height: 60}}></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>      

                            </td>                              
                        </tr>                        
                    </tbody>
                </table>

                <table style={{border: "2px solid #000"}}>
                    <colgroup>
						<col width={100} />                        
                        <col  />
						<col width={120} />
                        <col width={100} />
                        <col width={110} />
                        <col width={60} />
                        <col width={110} />
                    </colgroup>
                    <tbody>                        
                        <tr>   
                            <td className="tcon">발&nbsp;행&nbsp;번&nbsp;호</td>
                            <td className="tcon" style={{ fontWeight: "bold" }}>DB2019-구</td>
                            <td className="tcon" style={{ fontWeight: "bold" }}>110000</td>
                            <td className="tcon">관리책임자</td>
                            <td className="tcon">최지영 실장</td>
                            <td className="tcon">담당자</td>
                            <td className="tcon">이현주 주임</td>
                        </tr> 
                        <tr>   
                            <td className="tcon">수&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;신</td>
                            <td className="tcon" colSpan={2}>{bizNm}</td>
                            <td className="tcon">발&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;신</td>
                            <td className="tcon" colSpan={3}>{comNm}</td>
                        </tr>   
                        <tr>   
                            <td className="tcon">전&nbsp;화&nbsp;번&nbsp;호</td>
                            <td className="tcon" colSpan={2}>{bizPhone}</td>
                            <td className="tcon">전&nbsp;화&nbsp;번&nbsp;호</td>
                            <td className="tcon" colSpan={3}>{phone}</td>
                        </tr>                      
                        <tr>   
                            <td className="tcon">팩&nbsp;스&nbsp;번&nbsp;호</td>
                            <td className="tcon" colSpan={2}>{bizFax}</td>
                            <td className="tcon">팩&nbsp;스&nbsp;번&nbsp;호</td>
                            <td className="tcon" colSpan={3}>{fax}</td>
                        </tr>
                        <tr>   
                            <td className="tcon">주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</td>
                            <td className="tcon" colSpan={2}>{bizAddr + ' ' + bizAddrDtl}</td>
                            <td className="tcon">주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</td>
                            <td className="tcon" colSpan={3}>{addr + ' ' + addrDtl}</td>
                        </tr>
                        <tr>   
                            <td className="tcon"></td>
                            <td className="tcon" colSpan={2}></td>
                            <td className="tcon">수&nbsp;&nbsp;&nbsp;화&nbsp;&nbsp;&nbsp;물</td>
                            <td className="tcon" colSpan={3}></td>
                        </tr>
                        
                    </tbody>
                </table>
                
                <table style={{border: "2px solid #000", marginTop: 10}}>
                    <colgroup>
                        <col width={50} />
						<col width={150} />
                        <col width={100} />
						<col width={40} />
                        <col width={80} />
                        <col width={80} />
                        <col width={80} />
                        <col width={80} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon" colSpan={2}>제품명</td>
                            <td className="tcon" colSpan={7}></td>
                        </tr>
                        <tr>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>순번</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>부품명</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>규격</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>단위</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>수량</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>단가</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>금액</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>납기일</td>
                            <td className="tcon" style={{borderBottom: "2px double #000"}}>비고</td>
                        </tr>
                        {
                            _.map(orderItems, (v, k) => {
                                return (
                                    <tr key={k}>
                                        <td className="tcon al-c" style={{height: "40px"}}>
                                            {k+1}
                                        </td>
                                        <td className="tcon">{v.prodNm}</td>
                                        <td className="tcon">{v.prodStnd}</td>
                                        <td className="tcon">{v.prodTpNm}</td>
                                        <td className="tcon">{v.orderAmt}</td>
                                        <td className="tcon">{v.orderPrice}</td>
                                        <td className="tcon">{v.price}</td>
                                        <td className="tcon">{v.payDy}</td>
                                        <td className="tcon">{v.cmnt}</td>
                                    </tr>    
                                )
                            })
                        }
                        {
                            _.times(14 - orderItems.length, (n) => {
                                return (
                                    <tr key={n}>
                                        <td style={{height: "40px"}}></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>    
                                )
                            })
                        }
                         <tr>
                            <td className="tcon" 
                                style={{height: 30, borderTop: "2px double #000", borderBottom: "2px double #000"}} 
                                colSpan={6}
                            >발주합계금액(부가세 별도)</td>
                            <td className="tcon" 
                                style={{fontWeight: "bold", height: 30, borderTop: "2px double #000", borderBottom: "2px double #000"}} 
                                colSpan={3} 
                            >
                                <div className="fl-l">
                                    {`￦`}
                                </div>
                                <div className="fl-r">
                                    {totPrice}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="tcon" style={{height: 30, textAlign: "left"}} colSpan={9}>1.납품장소 : 지정장소</td>       
                        </tr>
                        <tr>
                            <td className="tcon" style={{height: 30, textAlign: "left"}} colSpan={9}>2.납기지연 : 사유발생시 <span style={{fontWeight: "bold"}}>반드시 납기일 이전에</span> 당사 담당자와 <span style={{fontWeight: "bold"}}>협의</span></td>                           
                        </tr>
                        <tr>
                            <td className="tcon" style={{height: 30, textAlign: "left"}} colSpan={9}>3.마감일자 : 매월 말일 마감</td>                           
                        </tr>
                        <tr>
                            <td className="tcon" style={{height: 30, textAlign: "left"}} colSpan={9}>4.거래명세표 : <span style={{fontWeight: "bold"}}>발주서와 동일하게 작성</span>바랍니다.</td>                           
                        </tr>
                    </tbody>
                </table>

            </div>
            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"발주서"}
            content = {renderContent()}
        />
        </>
    )
}

export default OrderRepoPage;

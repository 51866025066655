import React, { useState, useEffect, useContext, useRef } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox, EqupSelectBox, DayDateBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 정기점검
 */
const EqupCycleChkPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    //...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [chkMon, setChkMon] = useState(util.getNowMon());
    const [equpUid, setEqupUid] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "점검월",
                width: "200px",
                item: 
                    <DayDateBox 
                        value={chkMon}
                        type={'month'}
                        displayFormat={cmn.DATE_FORMAT_MON_1}
                        onValueChanged={(e) => {
                            setChkMon(e.value);
                        }}                    
                    />
            },
            {   title: "설비",
                item: 
                    <>
                    <EqupSelectBox
                        width="200px"
                        ecc1UseYn='Y'
                        lineYn={authStore.com.isLine ? 'Y' : 'N'}
                        value={equpUid}
                        onLoadInfo={(o) => {}} 
                        onValueChanged={(v) => {
                            setEqupUid(v);
                        }}
                    />                    
                    </>        
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [equpChkListCycle, setEqupChkListCycle] = useState([]);
    const [equpChkListCycleTot, setEqupChkListCycleTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                chkMon: util.getDateToString('M', chkMon),
                equpUid: equpUid,

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getEqupChkListCycle", ps );
        if(r === undefined || r == null) return;

        setEqupChkListCycle(r.d0)
        setEqupChkListCycleTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(!util.chkListSelected(selectedItem, '점검대상을')) return;
        openCrudPopup('a', selectedItem.equpUid, selectedItem.chkCycleTp, selectedItem.chkCycleDy);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "chkCycleDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
        else if(gc.column.dataField === "chkDt" 
            ) e.value =util.getDateToString('m', gc.value, '-');            
        else if(
            gc.column.dataField === "goodCnt" 
        )   e.value = '';
    }  

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, uid, chktp, chkdy) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equpdaychkpop?${qs.stringify({ mode: mode, uid: uid, chktp: chktp, chkdy: chkdy })}`; 
        util.openWindow(url, 1000, 700);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="점검하기"
                    type="2"
                    show={authStore.page.crtYn}
                />  
            </ListTopView>   
            }</Observer>    
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="정기점검 내역"
            >
                {/* <IconButton
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <Observer>{() =>
            <>        
            <DataGridView   
                reff={grid1}             
                dataSource={equpChkListCycle}   
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[cmn.CW.equpNm, 80, cmn.CW.dy, 160, 160, 120, null]}
                // paging
                page={curPage}
                total={equpChkListCycleTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}         
                // excel
                exlnm={'정기점검내역'}
                customizeExcelCell={customizeExcelCell}        
            >
                { cmn.getGridColumn('equpNm', '설비명', renderGridHeaderCell ) }
                { cmn.getGridColumn('chkCycleTpNm', '점검주기', renderGridHeaderCell ) }
                { cmn.getGridColumnDayType('chkCycleDy', '점검예정일', renderGridHeaderCell ) }
                { cmn.getGridColumnDateType('chkDt', '점검일시', renderGridHeaderCell ) }
                <Column 
                    alignment={'center'}
                    dataField={'goodCnt'} 
                    caption={'점검결과'} 
                    cellRender={(d) => {
                        const o = d.data;
                        let isChk = false;
                        let isGood = false;
                        let disp = '';
                        if(o == undefined || o.equpChkUid == null) disp = "";
                        else {
                            isChk = true;
                            if(o.totCnt == o.goodCnt) isGood = true;
                            disp = `${isGood ? '양호' : ('불량 ( ' + o.goodCnt + ' / ' + o.totCnt + ' )')} `;
                        }

                        return (
                            <span className={`${isChk ? isGood ? 'pass-y' : 'pass-n' : ''}`}>{disp}</span> 
                        ) 
                    }}            
                />
                { cmn.getGridColumn('chkUserNm', '점검자', renderGridHeaderCell ) }
                <Column />                
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupCycleChkPage;
import React, { useState, useEffect, useContext } from 'react';
import { SelectBox } from 'devextreme-react';
import useMobileDetect from 'use-mobile-detect-hook'
import _ from 'lodash';

/** components */
import {
   IconButton
} from '../../components';

/** stores & lib */
// import { AuthStore } from '../../stores';
// import { util, cmn } from '../../lib';

/** images */

/** 
 *  Data Grid Pager
 */
const DataGridPagerView = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    // const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const detectMobile = useMobileDetect();

    const {
        page= 1,
        total= 1001,
        itemsPerPage= 20,
        pagesPerBlock= detectMobile.isMobile() ? 5 : 10,
        marginTop = 10,

        onClickPage,
        onValueChanged
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
        if(loaded) return;        
        setLoaded(true);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 

    const renderContent = () => {

        const totolPages = _.floor(total / itemsPerPage) + ((total % itemsPerPage) > 0 ? 1 : 0);
        const startPage = _.floor((page-1)/pagesPerBlock)*pagesPerBlock+1;
        const endPage = (startPage+pagesPerBlock) > totolPages ? totolPages : (startPage+pagesPerBlock-1);

        return (
            total > 0 &&
            <>
            <div className="pageWrap clearfix">
                <div className="clearfix">
                    <p className="num-Area fl-l">
                    {
                        itemsPerPage < 0 ?
                        <>
                        <span>총</span>
                        <span className="ftstyle1"> {total} </span>
                        <span>건</span>
                        </>
                        :
                        <>
                        <span>{page}</span>
                        <span> / </span>
                        <span>{totolPages} </span>
                        (
                            <span> 총</span>
                            <span className="ftstyle1"> {total} </span>
                            <span>건 </span>
                        )
                        </>
                    }
                    </p>
                    <div className="list-Area fl-r clearfix">
                        <div className="select-box select-script fl-r">
                            <SelectBox 
                                width={60}
                                value={itemsPerPage}
                                displayExpr={'name'}
                                valueExpr={'value'}
                                //defaultValue="10"
                                items={[ 
                                    { name: "10", value: 10 }, 
                                    { name: "20", value: 20 }, 
                                    { name: "30", value: 30 }, 
                                    { name: "50", value: 50 },
                                    { name: "100", value: 100 }, 
                                    { name: "전체", value: -1 }
                                ]}
                                // showClearButton={true} 
                                onValueChanged={onValueChanged} 
                            />
                        </div>
                        <span className="mgr10 fl-r">목록수 :</span>
                    </div>  
                    <div className="page fl-l">
                        <div className="paging">
                        {
                            itemsPerPage > 0 &&
                            <>
                            <IconButton
                                className={`pbtn ${startPage == 1 ? "first-dis" :  "first"}`}
                                marginRight={5}
                                onClick={() => {
                                    if(startPage == 1) return;
                                    onClickPage(1);
                                }}
                            />
                            <IconButton
                                className={`pbtn ${page == 1 ? "prev-dis" : "prev"}`}                        
                                marginRight={5}
                                onClick={() => {
                                    if(page == 1) return;
                                    onClickPage(page-1);
                                }}
                            />   
                            {
                            _.map(_.range(startPage, endPage+1), (v, k) => {                                
                                return (
                                    <p key={k}>
                                    {
                                        page ==  v ?
                                        <strong>{v}</strong>
                                    :
                                        <a  
                                            className="a-page"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onClickPage(v);
                                            }}
                                        >
                                            {v}
                                        </a>
                                    } 
                                    </p>                          
                                )
                            })
                            }
                            <IconButton
                                className={`pbtn ${page == totolPages ? "next-dis" : "next"}`}
                                marginLeft={5}
                                marginRight={5}
                                onClick={() => {
                                    if(totolPages == page) return;
                                    onClickPage(page+1);
                                }}
                            />
                            <IconButton
                                className={`pbtn ${endPage == totolPages ? "last-dis" : "last"}`}
                                marginRight={0}
                                onClick={() => {
                                    if(endPage == totolPages) return;
                                    onClickPage(totolPages);
                                }}
                            />    
                            </>
                        }   
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default DataGridPagerView;
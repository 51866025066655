import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    ActionButton, SiteLayout, GridTopView, ListSearchView, EditGridView,IconButton
} from '../../components';

/** stores & lib */
import { AuthStore } from '../../stores';

import { util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const SiteLblPrintPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [lot, setLot] = useState('');  

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "Lot",
                item: 
                    <TextBox 
                        value={lot}
                        width={'200px'}
                        placeholder="Lot로 검색하세요."
                        showClearButton={true}
                        onValueChanged={(e)=>{
                            setLot(e.value);
                        }}
                    /> 
            }
        ]
    ];    

    //
    const [lblPrintItems, setLblPrintItems] = useState([]);  

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadLotInfo();
    },[lot]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {
        authStore.loadSitePage(props);
        setPageLoad(false);
    }

    const loadLotInfo = async() => {
        if(util.getChkNull(lot) === '') return;

        let ps = {
            p:{
                lot:lot
            }
        };

        let r = await cmn.api('getLot', ps);
        if(r == null || r === undefined) return;
        
        let data = r.d0[0];

        let lblPrintItems_ = [...lblPrintItems];

        // 입력 Validation Check
        // data = lblPrintItems_ 의 상태를 체크

        let msg = '';
        _.forEach(lblPrintItems_, (v, k) => {
            if(v.lot === data.lot) // lot 중복 체크
                msg = '이미 등록된 Lot 입니다.' 
        });

        if(msg !== '')
        {
            util.cmnAlert(msg);
            return;
        }

        lblPrintItems_.push({
            uid: util.getUUID()
            ,lot: data.lot
            ,prodNm: data.prodNm
            ,prodId: data.prodId
            ,prodStnd: data.prodStnd
            ,dy: util.getDateStrSplit('d',data.dy)
            ,amt: data.amt
            ,lblCnt: 0
            ,bizNm: data.bizNm
        });

      //  console.log('lblPrintItems_ ===>', lblPrintItems_);

        setLblPrintItems(lblPrintItems_);
    }

     /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickPrint = async(e) => {

        // validation Check
        if(!util.chkMustValue(_.sumBy(lblPrintItems, 'lblCnt'), '출력라벨수', 'cnt'))
            return;

        lblPrint();
    }

    const lblPrint = async() => {
        
        let para = '';
        _.forEach(lblPrintItems, (v,k) => {
            para += `|${v.lblCnt}`
            para += `^${v.lot}`
            para += `^${v.dy}`
            para += `^${v.amt}`
            para += `^${v.prodId}`
            para += `^${v.prodNm}`
            para += `^${v.prodStnd}`
            para += `^${v.bizNm}`

        })

        const url = `/cmn/lblprintpop?${qs.stringify({para: para.substring(1)})}`;        
        util.openWindow(url, 820, 840); 
    }


    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                <GridTopView
                    title="라벨출력 정보"
                >
                    {/* <SrchButton 
                        id="srchButton"
                        onClick={handleClickSearch}
                    /> */}
                    <ActionButton 
                        title="라벨출력"
                        onClick={handleClickPrint}
                        show={true}
                        type={"site-1"}
                        width={"140px"}
                    />
                </GridTopView>
                <ListSearchView searchItems={searchItems} type={"site"} />
                    <>
                    <EditGridView            
                        dataSource={lblPrintItems}   // store
                        colsWidth={[null, null, null, null, null, null, 150, 100]}
                    >
                        { cmn.getGridColumn('lot', 'Lot') }
                        { cmn.getGridColumn('prodNm', '품명') }
                        { cmn.getGridColumn('prodId', '품번') }
                        { cmn.getGridColumn('prodStnd', '규격') }
                        { cmn.getGridColumn('dy', '최종작업일') }
                        { cmn.getGridColumnNumCommaType('amt', '누적생산수량') }
                        <Column 
                            alignment={'center'}
                            dataField={'lblCnt'} 
                            caption={'라벨수'}
                            dataType={'number'}
                        />
                        <Column 
                            alignment={'center'}
                            dataField={'uid'} 
                            caption={'삭제'}
                            allowEditing={false} 
                            cellRender={(d) => {
                                return (
                                    <IconButton
                                        className="btn-icon-close-1"
                                        onClick={(e) => {
                                            
                                            const lblPrintItems_ = _.remove(lblPrintItems, (v) => {
                                                console.log(d.value);
                                                console.log(v.uid);
                                                return d.value !== v.uid;
                                            });
                                    
                                            setLblPrintItems(lblPrintItems_);
                                        }}
                                        marginRight={0}
                                    />
                                )
                            }}
                        />
                    </EditGridView>
                    </>
                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"라벨출력"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteLblPrintPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { NumberBox, SelectBox } from 'devextreme-react';
import DataGrid, { Column, Editing, Paging, Pager, Selection, Sorting, ColumnChooser } from 'devextreme-react/data-grid';

import _ from 'lodash';

/** components */
import { IconButton, EditGridView, SubActionButton} from '..';

/** stores & lib */
import { apiz, cmn, util } from '../../lib';

/** 
 *  공정검사시료
 */
const InspSampBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        mode,

        maxSampCnt=5,
        inspItems=[],

        onValueChanged,
        onBtnValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [goodBadTps, setGoodBadTps] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            if(mode == "a" || mode == "u"){        
                const r = await cmn.apiCodeCombo("301");
                if(r == undefined || r == null) return;
                setGoodBadTps(r);
            }
        }
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const getTotPassYn = (items) => {
        let passYnTot = 'Y';
        _.forEach(items, (v, k) => {
            if(v.passYn != 'Y'){
                passYnTot = 'N';
                return false;
            }
        });
        return passYnTot;
    }
    
    const getEqupConnData = async(items, d, i) => {

        let r = await cmn.api('getEqupConnDataTmp', {p:{}});
        if(r == null || r.d0.length <= 0) return;

        let data_ = r.d0[0].connData;
        let passYn = 'Y';
        
        items[d.rowIndex].samps[i].inspVal = data_;

        _.forEach(items[d.rowIndex].samps, (v, k) => {
            if(
                !cmn.getIsPassInsp(v.goodBadTp, v.inspVal, d.data.valSelectTp
                , items[d.rowIndex].stndVal, items[d.rowIndex].upperVal, items[d.rowIndex].lowerVal)
            ) {
                passYn = 'N';
                return false;
            }
        });

        items[d.rowIndex].passYn = passYn;
        onBtnValueChanged(items, getTotPassYn(items));
        //alert('데이터를 로드했습니다.');
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        let colsWidth_ = [cmn.CW.inspNm, cmn.CW.inspStnd];
        _.times(maxSampCnt, (i) => {
            colsWidth_.push(100);
        });
        colsWidth_.push(80);
        colsWidth_.push(80);
        colsWidth_.push(null);

        // console.log("--- colsWidth_", colsWidth_);

        return (
            // <div className="data-grid-1 mgt5">
                <EditGridView 
                    // id={'gridCodeList'}
                    // height={(gridHeight && !detectMobile.isMobile()) > 0 ? gridHeight : null}
                    // ref={dgCodeListRef}
                    dataSource={inspItems}
                    colsWidth={colsWidth_}

                >
                    { cmn.getGridColumn('inspNm', '항목' ) }
                    { cmn.getGridColumn('dispStnd', '규격' ) }
                    {
                        _.times(maxSampCnt, (i) => {
                            
                            return(
                                <Column
                                    key={i}
                                    width={100}
                                    alignment={'center'}
                                    dataField={'inspVal'}
                                    caption={`X${(i+1)}`}  
                                    cellRender={(d) => {
                                        return (
                                            (d.data.sampCnt > i) &&
                                            <div className="editable-outline">
                                            {
                                                d.data.valSelectTp == cmn.CMNCO_VALSELTP.SEL ?
                                                d.data.samps[i].goodBadTpNm
                                                :
                                                util.getNumComma(d.data.samps[i].inspVal)
                                            }
                                            </div>
                                        )
                                    }}

                                    editCellRender={(d) => {     
                                        return (
                                            (d.data.sampCnt > i) &&
                                            <div className="editable-cell">
                                            {
                                                d.data.equpConnYn == 'Y' ? 
                                                <>
                                                    <NumberBox
                                                        width={'60%'}
                                                        format={'#,##0.###'}
                                                        value={d.data.samps[i].inspVal}
                                                        disabled={true}
                                                        showClearButton={true}
                                                    />

                                                    <SubActionButton  
                                                        onClick={(e)=>{
                                                            const items_ = [...inspItems];
                                                            //items_[d.rowIndex].samps[i].inspVal = 2;

                                                            getEqupConnData(items_, d, i);
                                                          
                                                        }}
                                                        title="입력"
                                                        width="30px"
                                                        show={true}
                                                    />
                                                </>
                                                :
                                                    d.data.valSelectTp == cmn.CMNCO_VALSELTP.SEL ?
                                                    <SelectBox 
                                                        width={'100%'}                                                    
                                                        // value={d.data.samps[i].goodBadTp}
                                                        defaultValue={d.data.samps[i].goodBadTp}
                                                        dataSource={goodBadTps}
                                                        displayExpr={'codeNm'}
                                                        valueExpr={'codeId'}
                                                        placeholder="양호/불량"
                                                        onValueChanged={(e)=>{
                                                            const items_ = [...inspItems];
                                                            items_[d.rowIndex].samps[i].goodBadTp = e.value;
                                                            items_[d.rowIndex].samps[i].goodBadTpNm = e.component._options.displayValue;

                                                            let passYn = 'Y';
                                                            _.forEach(items_[d.rowIndex].samps, (v, k) => {
                                                                if(v.goodBadTp != cmn.CMNCO_INSPSEL.GOOD){
                                                                    passYn = 'N';
                                                                    return false;
                                                                }
                                                            });
                                                                                                                                        
                                                            items_[d.rowIndex].passYn = passYn;                                                            
                                                            onValueChanged(items_, getTotPassYn(items_));
                                                        }} 
                                                    />
                                                    :
                                                    <NumberBox
                                                        width={'100%'}
                                                        format={'#,##0.###'}
                                                        // value={d.data.samps[i].inspVal}
                                                        defaultValue={d.data.samps[i].inspVal}
                                                        showSpinButtons={true}
                                                        showClearButton={true}
                                                        onValueChanged={(e)=>{
                                                            const items_ = [...inspItems];
                                                            items_[d.rowIndex].samps[i].inspVal = e.value;

                                                            // 하나라도 불합격이면 불합격 처리
                                                            let passYn = 'Y';
                                                            _.forEach(items_[d.rowIndex].samps, (v, k) => {
                                                                if(
                                                                    !cmn.getIsPassInsp(v.goodBadTp, v.inspVal, d.data.valSelectTp
                                                                    , items_[d.rowIndex].stndVal, items_[d.rowIndex].upperVal, items_[d.rowIndex].lowerVal)
                                                                ) {
                                                                    passYn = 'N';
                                                                    return false;
                                                                }
                                                            });

                                                            items_[d.rowIndex].passYn = passYn;
                                                            onValueChanged(items_, getTotPassYn(items_));
                                                        }}
                                                    />
                                            }
                                            </div>
                                        )
                                    }}
                                />   
                            )
                        })
                    }

                    <Column 
                        alignment={'center'}
                        dataField={'passYn'} 
                        caption={'합부'}  
                        allowEditing={false}
                        cellRender={(d) => {
                            return cmn.getDispPass(d.value);
                        }}
                    />
                    <Column 
                        alignment={'center'}
                        dataField={'equpConnYn'} 
                        caption={'연동'}  
                        allowEditing={false}
                        cellRender={(d) => {
                            return d.value == 'Y' ? 'O' : 'X' ;
                        }}
                    />
                    <Column
                        allowEditing={false}
                    />
                </EditGridView>

            // </div>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default InspSampBox;

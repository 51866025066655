import React, { useState, useEffect, useContext, useRef, useReducer } from 'react';
import { Observer } from 'mobx-react-lite';
import { SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox,
    SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';
import { Column } from 'devextreme-react/data-grid';

/** 
 *  공통코드 관리
 */
const MenuPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */


    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [useYn, setUseYn] = useState('');
    const [menuNm, setMenuNm] = useState('');
    const codeNmEl = useRef(null);
    // const useYnEl = useRef(null);

    // ** Ref
    const dgEl0 = useRef(null);

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "메뉴명",
                width: "200px",
                item: 
                    <TextBox 
                        value={menuNm}
                        showClearButton={true}
                        placeholder="메뉴명으로 검색하세요."
                        valueChangeEvent={'keyup'}
                        onValueChanged={(e)=>{
                            setMenuNm(e.value);
                        }}
                    />     
            },
            {   title: "사용여부",
                item: 
                    <UseYnSelectBox
                        value={useYn}
                        onValueChanged={(e)=>{
                            setUseYn(e.value);
                        }}
                    />
            }
        ]
    ];

    /* #endregion */
    
    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);

    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const [menus, setMenus] = useState([]);
    const [menuTot, setMenuTot] = useState([]);

    // const [selectedItem, setSelectedItem] = useState(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, useYn]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);

            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        const ps =  {
            p: {
                codeNm: util.getValEl(codeNmEl), 
                useYn: useYn,

                offset: rowsOffset,
                limit: rowsLength,
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
            }
        }

        // console.log("===== p", ps);

        let r = await cmn.api('getMenuList', ps);
        if(r == null || r === undefined) return;

        setMenus(r.d0);
        setMenuTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
    
    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickUp = (e) => {
        // const sd = util.getDgSelectedRowsData(dgEl0);        
        // if(!util.chkListSelected(sd[0], '공통코드를')) return;
        // if(sd[0].systemYn === 'Y') {
        //     util.cmnAlert('[SYSTEM] 코드는 수정할 수 없습니다.');
        //     return;
        // }
        // openCrudPopup("u", sd[0].codeUid);
    }

    const handleClickActive = async (e) => {
        if(!util.chkListSelected(selectedItem, '활성화 할 메뉴를')) return;

        let menu_ = [];
        _.forEach(selectedItem, (v, k) => {
            menu_.push({
                menuUid: v.menuUid,
                menuLv: v.menuLv,
                groupUid: v.groupUid,
                useYn: 'Y'
            })
        })

        console.log('ps =========>', {p: menu_});

        const r = await cmn.api('upMenuUseYn', {p: menu_})
        if(r == null || r === undefined) return;

        util.cmnAlert("메뉴가 활성화 되었습니다.");
        
        await loadList1();
    }

    const handleClickInActive = async (e) => {
        if(!util.chkListSelected(selectedItem, '비활성화 할 메뉴를')) return;

        let menu_ = [];
        _.forEach(selectedItem, (v, k) => {
            menu_.push({
                menuUid: v.menuUid
                ,menuLv: v.menuLv
                ,groupUid: v.groupUid
                ,useYn: 'N'
            })
        })

        console.log('ps =========>', {p: menu_});

        const r = await cmn.api('upMenuUseYn', {p: menu_})
        if(r == null || r === undefined) return;

        util.cmnAlert("메뉴가 비활성화 되었습니다.");
        
        await loadList1();
    }


    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Funtions
    const openCrudPopup = (mode, uid) => {
        const url = `/base/codepop?${qs.stringify({mode: mode, uid: uid})}`;
        util.openWindow(url, 600, 360);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData);
    }

    const renderContent = () => {

        console.log("##### renderContent");

        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />   */}
               
                <ActionButton 
                    onClick={handleClickActive}
                    title="활성"
                    show={true}
                />
                <ActionButton 
                    onClick={handleClickInActive}
                    title="비활성"
                    show={true}
                />
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={true}
                />
            </ListTopView>
            }</Observer>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="메뉴"
            >
                {/* <IconButton
                    className="btn btn-isz ico-print"
                />
                <IconButton
                    className="btn btn-isz ico-exldown"
                    marginRight={0}
                /> */}
            </GridTopView>
            <Observer>{() =>
            <DataGridView
                dataSource={menus}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={menuTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}
                onInitPage={(pg, ofs) => {setCurPage(pg); setRowsOffset(ofs)}}
                
                colsWidth={[200, 200, 100, cmn.CW.sortOrder, cmn.CW.yn, null]}

                selection='multiple'

                showNo={false}
            >
                { cmn.getGridColumn('parentMenuNm', '상위메뉴', renderGridHeaderCell)}
                { cmn.getGridColumn('menuNm', '메뉴명', renderGridHeaderCell)}
                { cmn.getGridColumn('menuLv', '메뉴레벨', renderGridHeaderCell)}
                { cmn.getGridColumnSortOrder() }
                { cmn.getGridColumnUseYn() }
                <Column />
            </DataGridView>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default MenuPage;
import React, { useState, useEffect, useContext } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    InspSampBox, DateTimeBox, DayDateBox, FileUploadBox,
    PopupLayout, CheckListBox, ActionButton,
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  측정기 검교정
 */
const GaugeCalibPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        // mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,
        cuid = ls.cuid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    // const [gaugeUid, setGaugeUid] = useState(uid);
    // const [calibUid, setCalibUid] = useState(cuid);

    const [gaugeTp, setGaugeTp] = useState('');
    const [gaugeTpNm, setGaugeTpNm] = useState('');
    const [calibCycle, setCalibCycle] = useState(0);
    const [gaugeCd, setGaugeCd] = useState('');
    const [gaugeNm, setGaugeNm] = useState('');

    const [calibDy, setCalibDy] = useState(util.getNow());

    const [calibComNm, setCalibComNm] = useState('');
    const [calibCost, setCalibCost] = useState(0);
    const [calibSize, setCalibSize] = useState('');

    const [certFileUid, setCertFileUid] = useState(util.getUUID());
    const [certFileUploadFiles, setCertFileUploadFiles] = useState([]);

    const [calibRet, setCalibRet] = useState('');

    const [cmnt, setCmnt] = useState('');    

    useEffect(() => {
        loadPage();
    },[]);

    // useEffect(() => {
    //     !pageLoad && loadInfo1();
    // },[gaugeUid]);

    // useEffect(() => {
    //     !pageLoad && loadContent2();
    // },[calibUid]);
 
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            await loadContent2();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {                    
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getGauge", {p: {gaugeUid: uid}} );
            if(r == undefined || r == null) return;

            const d1 = r.d1[0];

            // setGaugeUid(d1.gaugeUid);
            setGaugeTp(d1.gaugeTp);
            setGaugeTpNm(d1.gaugeTpNm);
            setGaugeCd(d1.gaugeCd);
            setGaugeNm(d1.gaugeNm);
            setCalibCycle(d1.calibCycle);     
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }   
    }

    const loadContent2 = async() => {
        try {
            if(util.getChkNull(cuid) == '') return;

            const r = await cmn.api("getGaugeCalib", {p: {calibUid: cuid}} );
            if(r == undefined || r == null) return;

            const d0 = r.d0[0];       

            setCalibDy(d0.calibDy);
            setCalibRet(d0.calibRet);
            
            setCalibComNm(d0.calibComNm);
            setCalibCost(d0.calibCost);
            setCalibSize(d0.calibSize);

            setCertFileUid(d0.certFileUid = null ? util.getUUID() : d0.certFileUid);
            setCmnt(d0.cmnt);

            setCertFileUploadFiles(r.f1);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.confirmYn('교정완료')) return;

            if(util.getChkNull(cuid) == '') addGaugeCalib();
            else upGaugeCalib();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addGaugeCalib = async() => {
        const ps = {
            p: 
                {
                    calibUid: util.getUUID(),
                    gaugeUid: uid,
                    calibDy: util.getDateToString('d',calibDy),
                    calibRet: calibRet,
                    calibComNm: calibComNm,
                    calibCost: calibCost,
                    calibSize: calibSize,
                    certFileUid: certFileUid,
                    certFileUploadFiles: certFileUploadFiles,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("addGaugeCalib", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("교정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upGaugeCalib = async() => {
        const ps = {
            p: 
                {
                    calibUid: cuid,
                    gaugeUid: uid,
                    calibDy: util.getDateToString('d',calibDy),
                    calibRet: calibRet,
                    calibComNm: calibComNm,
                    calibCost: calibCost,
                    calibSize: calibSize,
                    certFileUid: certFileUid,
                    certFileUploadFiles: certFileUploadFiles,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid,
                }
        }

        const r = await cmn.api("upGaugeCalib", ps);
        if(r == undefined || r == null) return;

        cmn.successToast(cmn.MSG_SUCCESS_ADD);
        util.clickOpenerElement("srchButton");
        window.close();
    }



    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = "교정완료";

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    width={120}
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"38%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">측정기명</th>
                            <td className="tcon" colSpan={3}>
                                <span>{gaugeNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">측정기코드</th>
                            <td className="tcon" colSpan={3}>
                                <span>{gaugeCd}</span>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit">측정기유형</th>
                            <td className="tcon">
                                <span>{gaugeTpNm}</span>
                            </td>
                            <th className="tit">교정주기</th>
                            <td className="tcon">
                                <span>{calibCycle} (년)</span>
                            </td>
                        </tr>                                                              
                    </tbody>
                </table>
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={"38%"} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>                        
                        <tr>
                            <th className="tit">교정일</th>
                            <td className="tcon">
                                <DayDateBox 
                                    value={calibDy}
                                    type={'day'}
                                    displayFormat={cmn.DATE_FORMAT_DAY_1}
                                    onValueChanged={(e) => {
                                        setCalibDy(e.value);
                                    }}                    
                                />
                            </td>
                            <th className="tit">교정결과</th>
                            <td className="tcon">
                                <TextBox 
                                    value={calibRet}
                                    showClearButton={true}
                                    // placeholder=""
                                    // valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCalibRet(e.value);
                                    }} 
                                />                            
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit">교정처</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    with={"100%"}
                                    value={calibComNm}
                                    showClearButton={true}
                                    placeholder="교정처를 입력하세요."
                                    // valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCalibComNm(e.value);
                                    }} 
                                />
                            </td>                            
                        </tr>     
                        <tr>
                            <th className="tit">교정비용</th>
                            <td className="tcon">
                                <ul className="ul-row">
                                    <li>
                                        <NumberBox
                                            width={100}
                                            value={calibCost}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setCalibCost(e.value);
                                            }} 
                                        />
                                    </li>
                                    <li>
                                        <span>(원)</span>      
                                    </li>
                                </ul>
                            </td>   
                            <th className="tit">교정치수</th>
                            <td className="tcon">
                                <TextBox 
                                    with={"100%"}
                                    value={calibSize}
                                    showClearButton={true}
                                    placeholder="교정치수를 입력하세요."
                                    // valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCalibSize(e.value);
                                    }} 
                                />
                            </td>                           
                        </tr>     
                        <tr>
                            <th className="tit">성적서첨부</th>
                            <td className="tcon" colSpan={3}>
                                <FileUploadBox
                                    limit={1}
                                    grpUid={certFileUid}
                                    // accept={'image/*'}
                                    userUid={authStore.user.uid}
                                    uploadFiles={certFileUploadFiles}

                                    onUploaded={(e) => {
                                        setCertFileUploadFiles(e);
                                    }}
                                    onReomveFile={(e) => {
                                        setCertFileUploadFiles(e);
                                    }}
                                />
                            </td>                            
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                <TextBox 
                                    with={"100%"}
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    // valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }} 
                                />
                            </td>                            
                        </tr>                                            
                    </tbody>
                </table>
            </div>
      
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {"측정기 교정"}
            content = {renderContent()}
        />
        </>
    )
}

export default GaugeCalibPopPage;
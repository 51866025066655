import React,  { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import BaseLayout from './components/layouts/BaseLayout';

import { 
    LoginPage,
    MainPage,
    ErrorPage,

    Sample1Page,
    Sample2Page,

    /** cmn */
    ProcFlowTagPopPage,
    LblPrintPopPage,
    ProdViewPopPage,
    UseLogPage,
    /** base */
    ComPage,
    CodePage,
    DeptPage,
    UserPage,
    BizPage,
    ProdPage,
    ProcPage,
    ProdProcPage,
    ProdProcDtlPage,
    ProdProcInspPage,
    ProdBomPage,
    AuthPage,
    MenuPage,

    ComPopPage,
    CodePopPage,
    DeptPopPage,
    UserPopPage,
    UserInfoPopPage,
    BizPopPage,
    ProdPopPage,
    ProdHisPopPage,
    ProcPopPage,
    ProdProcPopPage,   
    ProdProcDtlPopPage,   
    ProcPriceHisPopPage, 
    ProdProcInspPopPage,
    ProdBomPopPage,
    AuthPopPage,
    AuthUserPopPage,
    AuthMenuPage,

    /** sales */
    BizProdPage,
    SalesPage,
    DeliStandbyPage,
    DeliPage,
    SalesDeliPage,
    DeliRetPage,
    DeliRepoPage,

    BizProdPopPage,
    SalesPopPage,
    DeliPopPage,
    DeliSalesPopPage,
    SalesDeliPopPage,

    /** order */
    OrderPage,
    InPage,
    OrderTakeAnalysisPage,
    OrderInAnalysisPage,

    OrderPopPage,
    InPopPage,    
    InLblPrintPopPage,
    TakeProdPopPage,
    OrderRepoPage,

    /** manu */
    WorkSalesSinglePage,
    WorkStocSinglePage,
    WorkSalesAssyPage,
    WorkStocAssyPage,
    WorkPage,
    WorkStaPage,
    ManuRetPage,
    ManuStndByPage,
    OutsOutStndByPage,
    OutsInStndByPage,
    OutsOutPage,
    OutsInPage,
    ManuTimeDayPage,

    WorkPopPage,
    ManuRetPopPage,
    OutsOutPopPage,
    OutsInPopPage,
    ManuTimeDayPopPage,

    /** stock */
    StockPage,
    StockDueDiliPage,
    StockDueDiliPopPage,
    StockDueDiliHisPopPage,
    FirstIngStockPage,
    FirstIngStockPopPage,
    OutPage,
    OutPopPage,
    StorPage,
    StorPopPage,
    StorRetPage,

    /** qual */
    MatInInspPage,
    OrderInInspPage,
    OutsInInspPage,
    ProcInspPage,
    XRControlPage,
    InInspBadStaPage,
    ProcManuBadStaPage,
    OutInspPage,
    BadImprPage,
    ShapeMeasMonitPage,

    InInspPopPage,
    ProcInspPopPage,
    InInspBadPopPage,
    ProcManuBadPopPage,
    XRControlPopPage,
    OutInspPopPage,
    BadImprPopPage,
    BadImprRepoPage,

    /** equp */
    CalibHisPage,
    EqupPage,
    EqupChkItemPage,
    EqupDayChkPage,
    EqupCycleChkPage,
    EqupBkupPage,
    EqupBkupInPage,
    EqupBkupOutPage,
    EqupFixPage,
    EqupRunPage,
    LinePage,

    EqupPopPage,
    EqupChkItemPopPage,
    EqupDayChkPopPage,
    EqupBkupPopPage,
    EqupBkupInPopPage,
    EqupBkupOutPopPage,
    EqupFixPopPage,
    EqupRunPopPage,
    EqupHisRepoPage,
    LinePopPage,

    /** gauge */
    GaugePage,
    GaugePopPage,
    GaugeCalibPopPage,
    GaugeHisRepoPage,

    /** mold */
    MoldPage,
    MoldMovePage,
    MoldInspPage,

    MoldPopPage,
    MoldMovePopPage,
    MoldInspPopPage,
    MoldHisRepoPage,

    /** tool */
    ToolPage,
    ToolInPage,
    ToolOutPage,
    ToolStockPage,

    ToolPopPage,
    ToolInPopPage,
    ToolOutPopPage,

    /** indicator */
    ProcBadPage,
    ProdCostPage,
    TimeManuPage,
    ProdBadPage,
    EqupOperatePage,
    ReadTimePage,
    WorkManPage,

    /** trace */
    LotTracePage,

    /** site */
    SitePage,
    SiteWorkStaPage,
    SiteManuRetFstProcPage,
    SiteManuRetAftProcPage,
    SiteOutsOutPage,
    SiteOutsInPage,
    SiteEqupDayChkPage,
    SiteEqupDayChkCrtPage,
    SiteLblPrintPage,
    SiteEqupWorkStaPage,
    SiteEqupWorkMonitPage,    
    SiteManuStndByPage,
    SiteOutsInStndByPage,
    SiteOutsOutStndByPage,

    /** MT-Link */
    MtLinkPage,
    
    /** notFound */
    NotFoundPage

 } from './pages';

const App = (props) => {

    return (
        <>
        <Switch>

            <Route path="/notfoundpage" component={NotFoundPage}/>

            <Route path="/sample1" component={Sample1Page}/>
            <Route path="/sample2" component={Sample2Page}/>
            <Route path="/login" component={LoginPage}/>
            <Route path="/error" component={ErrorPage}/>

            /* #region POPUP */
            
            /** cmn */
            <Route path="/cmn/procflowtagpop" component={ProcFlowTagPopPage}/>
            <Route path="/cmn/lblprintpop" component={LblPrintPopPage}/>
            <Route path="/cmn/prodviewpop" component={ProdViewPopPage}/>

            /* #region POPUP - base */
            <Route path="/base/compop" component={ComPopPage}/>
            <Route path="/base/codepop" component={CodePopPage}/>
            <Route path="/base/deptpop" component={DeptPopPage}/>
            <Route path="/base/userpop" component={UserPopPage}/>
            <Route path="/base/userinfopop" component={UserInfoPopPage}/>
            <Route path="/base/bizpop" component={BizPopPage}/>
            <Route path="/base/prodpop" component={ProdPopPage}/>
            <Route path="/base/prodhispop" component={ProdHisPopPage}/>
            <Route path="/base/procpop" component={ProcPopPage}/>
            <Route path="/base/prodprocpop" component={ProdProcPopPage}/>
            <Route path="/base/prodprocdtlpop" component={ProdProcDtlPopPage}/>
            <Route path="/base/procpricehispop" component={ProcPriceHisPopPage}/>
            <Route path="/base/prodprocinsppop" component={ProdProcInspPopPage}/>
            <Route path="/base/prodbompop" component={ProdBomPopPage}/>
            <Route path="/base/authpop" component={AuthPopPage}/>
            <Route path="/base/authuserpop" component={AuthUserPopPage}/>

            
            /* #endregion */

            /* #region POPUP - sales */
            <Route path="/sales/bizprodpop" component={BizProdPopPage}/>
            <Route path="/sales/salespop" component={SalesPopPage}/>
            <Route path="/sales/delipop" component={DeliPopPage}/>
            <Route path="/sales/delisalespop" component={DeliSalesPopPage}/>
            <Route path="/sales/delirepo" component={DeliRepoPage}/>
            <Route path="/sales/salesdelipop" component={SalesDeliPopPage}/>
            /* #endregion */

            /* #region POPUP - order */
            <Route path="/order/orderpop" component={OrderPopPage}/>
            <Route path="/order/inpop" component={InPopPage}/>
            <Route path="/order/inlblprintpop" component={InLblPrintPopPage}/> 
            <Route path="/order/takeprodpop" component={TakeProdPopPage}/> 
            <Route path="/order/orderrepo" component={OrderRepoPage}/>             
            /* #endregion */

            /* #region POPUP - manu */
            <Route path="/manu/workpop" component={WorkPopPage}/>
            <Route path="/manu/manuretpop" component={ManuRetPopPage}/>
            <Route path="/manu/outsoutpop" component={OutsOutPopPage}/>
            <Route path="/manu/outsinpop" component={OutsInPopPage}/>
            <Route path="/manu/manutimedaypop" component={ManuTimeDayPopPage}/>
            /* #endregion */

            /* #region POPUP - stock */
            <Route path="/stock/firstingstockpop" component={FirstIngStockPopPage}/>
            <Route path="/stock/stockduedilipop" component={StockDueDiliPopPage}/>
            <Route path="/stock/stockduedilihispop" component={StockDueDiliHisPopPage}/>
            <Route path="/stock/outpop" component={OutPopPage}/>
            <Route path="/stock/storpop" component={StorPopPage}/>
            /* #endregion */

            /* #region POPUP - qual */
            <Route path="/qual/ininsppop" component={InInspPopPage}/>
            <Route path="/qual/procinsppop" component={ProcInspPopPage}/>
            <Route path="/qual/ininspbadpop" component={InInspBadPopPage}/>
            <Route path="/qual/procmanubadpop" component={ProcManuBadPopPage}/>
            <Route path="/qual/xrcontrolpop" component={XRControlPopPage}/>
            <Route path="/qual/outinsppop" component={OutInspPopPage}/>
            <Route path="/qual/badimprpop" component={BadImprPopPage}/>
            <Route path="/qual/badimprrepo" component={BadImprRepoPage}/>
            /* #endregion */

            /* #region POPUP - equp  */
            <Route path="/equp/equppop" component={EqupPopPage}/>
            <Route path="/equp/equpchkitempop" component={EqupChkItemPopPage}/>
            <Route path="/equp/equpdaychkpop" component={EqupDayChkPopPage}/>
            <Route path="/equp/equpbkuppop" component={EqupBkupPopPage}/>
            <Route path="/equp/equpbkupinpop" component={EqupBkupInPopPage}/>
            <Route path="/equp/equpbkupoutpop" component={EqupBkupOutPopPage}/>
            <Route path="/equp/equpfixpop" component={EqupFixPopPage}/>
            <Route path="/equp/equprunpop" component={EqupRunPopPage}/>
            <Route path="/equp/equphisrepo" component={EqupHisRepoPage}/>
            <Route path="/equp/linepop" component={LinePopPage}/>
            /* #endregion */

            /* #region POPUP - gauge  */
            <Route path="/equp/gaugepop" component={GaugePopPage}/>
            <Route path="/equp/gaugecalibpop" component={GaugeCalibPopPage}/>
            <Route path="/gauge/gaugehisrepo" component={GaugeHisRepoPage}/>

            /* #region POPUP - mold  */
            <Route path="/mold/moldpop" component={MoldPopPage}/>
            <Route path="/mold/moldmovepop" component={MoldMovePopPage}/>
            <Route path="/mold/moldinsppop" component={MoldInspPopPage}/>
            <Route path="/mold/moldhisrepo" component={MoldHisRepoPage}/>
            /* #endregion */

            /* #region POPUP - tool  */
            <Route path="/tool/toolpop" component={ToolPopPage}/>
            <Route path="/tool/toolinpop" component={ToolInPopPage}/>
            <Route path="/tool/tooloutpop" component={ToolOutPopPage}/>
            /* #endregion */


            /* #region site  */
            <Route path="/site/site" component={SitePage}/>
            <Route path="/site/siteworksta" component={SiteWorkStaPage}/>
            <Route path="/site/sitemanuretfstproc" component={SiteManuRetFstProcPage}/>
            <Route path="/site/sitemanuretaftproc" component={SiteManuRetAftProcPage}/>
            <Route path="/site/siteoutsout" component={SiteOutsOutPage}/>
            <Route path="/site/siteoutsin" component={SiteOutsInPage}/>
            <Route path="/site/siteequpdaychk" component={SiteEqupDayChkPage}/>
            <Route path="/site/siteequpdaychkcrt" component={SiteEqupDayChkCrtPage}/>  
            <Route path="/site/sitelblprint" component={SiteLblPrintPage}/>  
            <Route path="/site/siteequpworksta" component={SiteEqupWorkStaPage}/>
            <Route path="/site/siteequpworkmonit" component={SiteEqupWorkMonitPage}/>
            <Route path="/site/sitemanustndby" component={SiteManuStndByPage}/>
            <Route path="/site/siteoutsinstndby" component={SiteOutsInStndByPage}/>
            <Route path="/site/siteoutsoutstndby" component={SiteOutsOutStndByPage}/>
            
            /* #endregion */

            <BaseLayout>
                <Switch>
                    <Route exact path="/" component={MainPage}/>

                    <Route path="/cmn/uselog" component={UseLogPage}/>
                    
                    {/** base  */}
                    <Route path="/base/com" component={ComPage}/>
                    <Route path="/base/code" component={CodePage}/>
                    <Route path="/base/dept" component={DeptPage}/>
                    <Route path="/base/user" component={UserPage}/>
                    <Route path="/base/biz" component={BizPage}/>
                    <Route path="/base/prod" component={ProdPage}/>
                    <Route path="/base/proc" component={ProcPage}/>
                    <Route path="/base/prodproc" component={ProdProcPage}/>
                    <Route path="/base/prodprocdtl" component={ProdProcDtlPage}/>
                    <Route path="/base/prodprocinsp" component={ProdProcInspPage}/>
                    <Route path="/base/prodbom" component={ProdBomPage}/>
                    <Route path="/base/auth" component={AuthPage}/>
                    <Route path="/base/authmenu" component={AuthMenuPage}/>
                    <Route path="/base/menu" component={MenuPage}/>

                    {/** sales  */}
                    <Route path="/sales/bizprod" component={BizProdPage}/>
                    <Route path="/sales/sales" component={SalesPage}/>
                    <Route path="/sales/delistandby" component={DeliStandbyPage}/>
                    <Route path="/sales/deli" component={DeliPage}/>
                    <Route path="/sales/salesdeli" component={SalesDeliPage}/>
                    <Route path="/sales/deliret" component={DeliRetPage}/>

                    {/** order  */}
                    <Route path="/order/order" component={OrderPage}/>
                    <Route path="/order/in" component={InPage}/>
                    <Route path="/order/ordertakeanalysis" component={OrderTakeAnalysisPage}/>
                    <Route path="/order/orderinanalysis" component={OrderInAnalysisPage}/>

                    {/** manu  */}
                    <Route path="/manu/worksalessingle" component={WorkSalesSinglePage}/>
                    <Route path="/manu/workstocsingle" component={WorkStocSinglePage}/>
                    <Route path="/manu/worksalesassy" component={WorkSalesAssyPage}/>
                    <Route path="/manu/workstocassy" component={WorkStocAssyPage}/>
                    <Route path="/manu/work" component={WorkPage}/>
                    <Route path="/manu/worksta" component={WorkStaPage}/>
                    <Route path="/manu/manuret" component={ManuRetPage}/>
                    <Route path="/manu/manustndby" component={ManuStndByPage}/>
                    <Route path="/manu/outsout" component={OutsOutPage}/>
                    <Route path="/manu/outsoutstndby" component={OutsOutStndByPage}/>
                    <Route path="/manu/outsin" component={OutsInPage}/>
                    <Route path="/manu/outsinstndby" component={OutsInStndByPage}/>
                    <Route path="/manu/manutimeday" component={ManuTimeDayPage}/>

                    {/** stock  */}
                    <Route path="/stock/stock" component={StockPage}/>
                    <Route path="/stock/stockduedili" component={StockDueDiliPage}/>
                    <Route path="/stock/firstingstock" component={FirstIngStockPage}/>
                    <Route path="/stock/out" component={OutPage}/>
                    <Route path="/stock/stor" component={StorPage}/>
                    <Route path="/stock/storret" component={StorRetPage}/>
                                            
                    {/** qual */}
                    <Route path="/qual/matininsp" component={MatInInspPage}/>
                    <Route path="/qual/orderininsp" component={OrderInInspPage}/>
                    <Route path="/qual/outsininsp" component={OutsInInspPage}/>
                    <Route path="/qual/procinsp" component={ProcInspPage}/>
                    <Route path="/qual/xrcontrol" component={XRControlPage}/>
                    <Route path="/qual/ininspbadsta" component={InInspBadStaPage}/>
                    <Route path="/qual/procmanubadsta" component={ProcManuBadStaPage}/>
                    <Route path="/qual/outinsp" component={OutInspPage}/>
                    <Route path="/qual/badimpr" component={BadImprPage}/>
                    <Route path="/qual/shapemeasmonit" component={ShapeMeasMonitPage}/>
                    
                    {/** indicator */}
                    <Route path="/indicator/procbad" component={ProcBadPage}/>
                    <Route path="/indicator/prodcost" component={ProdCostPage}/>
                    <Route path="/indicator/timemanu" component={TimeManuPage}/>
                    <Route path="/indicator/prodbad" component={ProdBadPage}/>
                    <Route path="/indicator/equpoperate" component={EqupOperatePage}/>
                    <Route path="/indicator/readtime" component={ReadTimePage}/>
                    <Route path="/indicator/workman" component={WorkManPage}/>

                    
                    {/** trace */}
                    <Route path="/trace/lottrace" component={LotTracePage}/>

                    {/** equp  */}
                    <Route path="/equp/calibhis" component={CalibHisPage}/>
                    <Route path="/equp/equp" component={EqupPage}/>
                    <Route path="/equp/equpchkitem" component={EqupChkItemPage}/>
                    <Route path="/equp/equpdaychk" component={EqupDayChkPage}/>
                    <Route path="/equp/equpcyclechk" component={EqupCycleChkPage}/>
                    <Route path="/equp/equpbkup" component={EqupBkupPage}/>
                    <Route path="/equp/equpbkupin" component={EqupBkupInPage}/>
                    <Route path="/equp/equpbkupout" component={EqupBkupOutPage}/>
                    <Route path="/equp/equpfix" component={EqupFixPage}/>
                    <Route path="/equp/equprun" component={EqupRunPage}/>
                    <Route path="/equp/line" component={LinePage}/>

                    {/** gauge  */}
                    <Route path="/gauge/gauge" component={GaugePage}/>

                    {/** mold  */}
                    <Route path="/mold/mold" component={MoldPage}/>
                    <Route path="/mold/moldmove" component={MoldMovePage}/>
                    <Route path="/mold/moldinsp" component={MoldInspPage}/>

                    {/** tool  */}
                    <Route path="/tool/tool" component={ToolPage}/>
                    <Route path="/tool/toolin" component={ToolInPage}/>
                    <Route path="/tool/toolout" component={ToolOutPage}/>
                    <Route path="/tool/toolstock" component={ToolStockPage}/>

                    {/** MT-Link */}
                    <Route path="/mtlink" component={MtLinkPage} />

                    <Redirect path="*" to="/notfoundpage"/>
                </Switch>
            </BaseLayout>

            <Route component={NotFoundPage}/>
 
        </Switch>
        </>
    );
};
  
export default App;
  
import React, { useState, useEffect, useContext, useRef } from 'react';
import { NumberBox, SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */
import { IconButton, SubActionButton } from '..';

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  불량수량
 */
const BadAmtBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        mode = 'v',
        badAmts=[],
        unitTp,
        unitTpNm='EA',

        onClickAdd,
        onClickDel
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [badTps, setBadTps] = useState([]);
    const [badTp, setBadTp] = useState('');
    const [badTpNm, setBadTpNm] = useState('');
    const [badAmt, setBadAmt] = useState(0);

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try{
            const r = await cmn.apiCodeCombo("303");
            setBadTps(r);
        }
        catch(err){
            // nothing ...
        }  
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    
    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        const badAmtTot = _.sumBy(badAmts, 'badAmt');
        return (
            <div className="bad-comp">
                <div className="tot">
                    <span className="amt">
                        {`총 불량수량 : ${badAmtTot}`}
                    </span>
                    <span className="unit">
                        {cmn.getDispUnitTpNm(unitTpNm)}
                    </span>
                </div>
                <div className="dtl">
                    {mode === 'a' ?
                    <ul>
                        <li>
                            <ul className="row">
                                <li className="c1">
                                    <SelectBox 
                                        width={'100%'}
                                        placeholder={'불량유형을 선택하세요.'}
                                        value={badTp}
                                        dataSource={badTps}
                                        displayExpr={'codeNm'}
                                        valueExpr={'codeId'}
                                        showClearButton={true}

                                        onValueChanged={(e)=>{
                                            setBadTp(e.value);
                                            setBadTpNm(cmn.getCodeNmInCombo(badTps, e.value));
                                        }} 
                                        />
                                </li>
                                <li className="c2">
                                    <NumberBox
                                        width={'100%'}
                                        format={'#,##0.###'}
                                        value={badAmt}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        
                                        onValueChanged={(e)=>{
                                            setBadAmt(e.value);
                                        }} 
                                    />
                                </li>
                                <li className="c3">
                                        <SubActionButton 
                                            title="추가"
                                            show={true}
                                            onClick={(e) => {
                                                if(util.getChkNull(badTp) === ''){
                                                    alert("불량유형을 선택하세요.");
                                                    return;
                                                }
                                                if(badAmt === 0){
                                                    alert("불량수량(중량)을 입력하세요.");
                                                    return;
                                                }
                                                const badAmts_ = [...badAmts];
                                                badAmts_.push({uid: util.getUUID(), badTp: badTp, badTpNm: badTpNm, badAmt: badAmt});
                                                onClickAdd(badAmts_);                                            
                                                setBadAmt(0);
                                            }}
                                        />
                                </li>
                            </ul>
                            {
                                _.map(badAmts, (v, k) => {
                                    return(
                                            <ul className="row" key={k}>
                                                <li className="c1"
                                                    style={{marginLeft:5}} >
                                                    {v.badTpNm}
                                                </li>
                                                <li className="c2"
                                                    style={{width:85}}>
                                                    {v.badAmt}
                                                </li>
                                                <li className="c3">
                                                    <SubActionButton 
                                                        title="삭제"
                                                        show={true}
                                                        onClick={(e) => {
                                                            const badAmts_ = _.remove(badAmts, (vv) => {
                                                                return v.uid !== vv.uid;
                                                            });
                                                            onClickDel(badAmts_);
                                                        }}
                                                    />  
                                                
                                                </li>
                                            </ul>  
                                    )
                                })  
                            }
                        </li>                        
                           
                    </ul>    
                        :
                        _.map(badAmts, (v, k) => {
                            return(
                                    <ul className="row" key={k}>
                                        <li className="c1" style={{width:85}}>
                                            {v.badTpNm}
                                        </li>
                                        <li>
                                            {`${v.badAmt} ${cmn.getDispUnitTpNm(unitTpNm)}`}
                                        </li>
                                    </ul>  
                            )
                        })  
                    }         
                </div>
            </div>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default BadAmtBox;

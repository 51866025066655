import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout,
    CheckListBox,
    ActionButton,
    UseYnSwitchBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  업체 등록 / 수정
 */
const BizPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [bizTpItems, setBizTpItems] = useState([]);
    const [bizNm, setBizNm] = useState('');
    const [bizTpNm, setBizTpNm] = useState('');
    const [ownerNm, setOwnerNm] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [bizNum, setBizNum] = useState('');
    const [bizCate, setBizCate] = useState('');
    const [bizCond, setBizCond] = useState('');
    const [addr, setAddr] = useState('');
    const [addrDtl, setAddrDtl] = useState('');
    const [useYn, setUseYn] = useState('Y');
    const [cmnt, setCmnt] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadInfo1();
    },[pageLoad]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {     
        try {
            await authStore.loadPopPage(props);

            if(mode === "a" || mode === "u"){      
                let r = await cmn.api('getCodeCombo', {p:{classId:'702'}});
                setBizTpItems(r.d0);
            }

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {      
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api("getBiz", {p : {bizUid: uid}});
            const data = r.d0[0];
            
            setBizNm(data.bizNm);
            setBizTpNm(data.bizTpNm);
            setOwnerNm(data.ownerNm);
            setPhone(data.phone);
            setFax(data.fax);
            setEmail(data.email);
            setBizNum(data.bizNum);
            setBizCate(data.bizCate);
            setBizCond(data.bizCond);
            setAddr(data.addr);
            setAddrDtl(data.addrDtl);
            setUseYn(data.useYn);
            setCmnt(data.cmnt);

            let bizTpItems_ = [...bizTpItems];
            _.forEach(bizTpItems_, (v, k) => {
                if(!_.find(data.bizTps, { bizTp: v.codeId})) return;
                bizTpItems_[k].checked = true;
            });
            setBizTpItems(bizTpItems_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = async(e) => {
        try {
            // validation 체크
            if(mode === "a" || mode === "u")
            {
                if(!util.chkMustValue(bizTpItems, '업체유형', 'chk'))
                    return;
                if(!util.chkMustValue(bizNm, '업체명'))
                    return;
                if(!util.chkMustValue(bizNum, '사업자번호'))
                    return;

                // let ps = {
                //     p :{
                //         bizUid : uid,
                //         bizNum : bizNum 
                //     }
                // }
                // const r = await cmn.api('getDupBizNum', ps);
                // if(r == null || r === undefined) return;

                // if(r.d0[0].dupYn === 'Y')
                // {
                //     util.cmnAlert('[사업자번호]가 중복되었습니다.')
                //     return;
                // }
            }


            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addBiz();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upBiz();
            }
            else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delBiz();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addBiz = async() => {
        const ps = {
            p: {
                bizUid: util.getUUID(),
                comUid: authStore.com.uid,
                bizNm: bizNm,
                bizTps: cmn.getCheckedCodes(bizTpItems),
                ownerNm: ownerNm,
                phone: phone,
                fax: fax,
                email: email,
                bizNum: bizNum,
                bizCate: bizCate,
                bizCond: bizCond,
                addr: addr,
                addrDtl: addrDtl,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }
        //console.log("===== para", ps);
        const r = await cmn.api('addBiz', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upBiz = async() => {
        const ps = {
            p: {
                bizUid: uid,
                bizNm: bizNm,
                bizTps: cmn.getCheckedCodes(bizTpItems),
                ownerNm: ownerNm,
                phone: phone,
                fax: fax,
                email: email,
                bizNum: bizNum,
                bizCate: bizCate,
                bizCond: bizCond,
                addr: addr,
                addrDtl: addrDtl,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api('upBiz', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const delBiz = async() => {
        const ps =  {
            p: {
                comUid: authStore.com.uid
                ,bizUid: uid
                ,delCmnt: ''
            }
        }

        let r = await cmn.api('delBiz', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("삭제");
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">업체유형</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <CheckListBox
                                        items={bizTpItems}
                                        textExpr={'codeNm'}
                                        onValueChanged={(e) => {
                                            setBizTpItems(e.items);                        
                                        }}                                          
                                    />
                                :
                                    <span>{bizTpNm}</span>
                                }
                            </td>
                            <th className="tit req">업체명</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={bizNm}
                                        showClearButton={true}
                                        placeholder="업체명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setBizNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{bizNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">대표자명</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={ownerNm}
                                        showClearButton={true}
                                        placeholder="대표자명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setOwnerNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{ownerNm}</span>
                                }
                            </td>
                            <th className="tit req">사업자번호</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={bizNum}
                                        showClearButton={true}
                                        placeholder="사업자번호를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setBizNum(e.value);
                                        }} 
                                    />
                                :
                                    <span>{bizNum}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">업태</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={bizCate}
                                        showClearButton={true}
                                        placeholder="업태를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setBizCate(e.value);
                                        }} 
                                    />
                                :
                                    <span>{bizCate}</span>
                                }
                            </td>
                            <th className="tit">종목</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={bizCond}
                                        showClearButton={true}
                                        placeholder="종목을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setBizCond(e.value);
                                        }} 
                                    />
                                :
                                    <span>{bizCond}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">전화번호</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={phone}
                                        showClearButton={true}
                                        placeholder="전화번호를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setPhone(e.value);
                                        }} 
                                    />
                                :
                                    <span>{phone}</span>
                                }
                            </td>
                            <th className="tit">FAX 번호</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={fax}
                                        showClearButton={true}
                                        placeholder="FAX 번호를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setFax(e.value);
                                        }} 
                                    />
                                :
                                    <span>{fax}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">E-Mail</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={email}
                                        showClearButton={true}
                                        placeholder="E-Mail을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setEmail(e.value);
                                        }} 
                                    />
                                :
                                    <span>{email}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon"> 
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={useYn}
                                        onValueChanged={(v)=>{
                                            setUseYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">주소</th>
                            <td className="tcon" colSpan={3}> 
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={addr}
                                        showClearButton={true}
                                        placeholder="주소를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setAddr(e.value);
                                        }} 
                                    />
                                :
                                    <span>{addr}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">상세주소</th>
                            <td className="tcon" colSpan={3}> 
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={addrDtl}
                                        showClearButton={true}
                                        placeholder="상세주소를 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setAddrDtl(e.value);
                                        }} 
                                    />
                                :
                                    <span>{addrDtl}</span>
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    placeholder="비고를 입력하세요"
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "업체 등록" 
                : mode === "u" ? "업체 수정"
                : mode === "v" ? "업체 상세보기"
                : "업체" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default BizPopPage;
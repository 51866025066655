import React, { useState, useEffect, useContext } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { FileUploader, CheckBox,  DateBox, Calendar, CalendarOptions, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, {
    Column,
    Editing,
    Paging,
    Selection,
    Lookup,
    ColumnChooser
  } from 'devextreme-react/data-grid';

import crypto from 'crypto';


import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    Legend,
    Margin,
    Title,
    Subtitle,
    Tooltip,
    Grid
  } from 'devextreme-react/chart';

import Scheduler from 'devextreme-react/scheduler';
import ScrollView from 'devextreme-react/scroll-view';

// import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
// import TreeList, { Selection, Column } from 'devextreme-react/tree-list';
// import { FORMATS_TO_PATTERN_MAP, date, locale, loadMessages, formatMessage } from 'devextreme/localization';
import useKey from 'use-key-hook';
import _ from 'lodash';
import moment from 'moment';

/** components */
import {
    ListTopView, GridTopView, DataGridView, 
    UseYnSelectBox, CheckListBox, DayDateBox, FileUploadBox,
    SrchButton, ActionButton, IconButton, DyChkDataCell
} from '../components';

/** stores & lib */
import { util, cmn } from '../lib';

/** images */

/** 
 *  메인 페이지
 */
const Sample2Page = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    
    /* ====================
     *  Object
     * ==================== */
    const energySources = [
        { value: 'hydro', name: 'Hydro-electric' },
        { value: 'oil', name: 'Oil' },
        { value: 'gas', name: 'Natural gas' },
        { value: 'coal', name: 'Coal' },
        { value: 'nuclear', name: 'Nuclear' }
    ];

    const countriesInfo = [{
        country: 'USA',
        hydro: 59.8,
        oil: 937.6,
        gas: 582,
        coal: 564.3,
        nuclear: 187.9
    }, {
        country: 'China',
        hydro: 74.2,
        oil: 308.6,
        gas: 35.1,
        coal: 956.9,
        nuclear: 11.3
    }, {
        country: 'Russia',
        hydro: 40,
        oil: 128.5,
        gas: 361.8,
        coal: 105,
        nuclear: 32.4
    }, {
        country: 'Japan',
        hydro: 22.6,
        oil: 241.5,
        gas: 64.9,
        coal: 120.8,
        nuclear: 64.8
    }, {
        country: 'India',
        hydro: 19,
        oil: 119.3,
        gas: 28.9,
        coal: 204.8,
        nuclear: 3.8
    }, {
        country: 'Germany',
        hydro: 6.1,
        oil: 123.6,
        gas: 77.3,
        coal: 85.7,
        nuclear: 37.8
    }];

    const data = [
        {
          text: 'Website Re-Design Plan',
          startDate: new Date(2017, 4, 22, 9, 30),
          endDate: new Date(2017, 4, 22, 11, 30)
        }, {
          text: 'Book Flights to San Fran for Sales Trip',
          startDate: new Date(2017, 4, 22, 12, 0),
          endDate: new Date(2017, 4, 22, 13, 0),
          allDay: true
        }, {
          text: 'Install New Router in Dev Room',
          startDate: new Date(2017, 4, 22, 14, 30),
          endDate: new Date(2017, 4, 22, 15, 30)
        }, {
          text: 'Approve Personal Computer Upgrade Plan',
          startDate: new Date(2017, 4, 23, 10, 0),
          endDate: new Date(2017, 4, 23, 11, 0)
        }, {
          text: 'Final Budget Review',
          startDate: new Date(2017, 4, 23, 12, 0),
          endDate: new Date(2017, 4, 23, 13, 35)
        }, {
          text: 'New Brochures',
          startDate: new Date(2017, 4, 23, 14, 30),
          endDate: new Date(2017, 4, 23, 15, 45)
        }, {
          text: 'Install New Database',
          startDate: new Date(2017, 4, 24, 9, 45),
          endDate: new Date(2017, 4, 24, 11, 15)
        }, {
          text: 'Approve New Online Marketing Strategy',
          startDate: new Date(2017, 4, 24, 12, 0),
          endDate: new Date(2017, 4, 24, 14, 0)
        }, {
          text: 'Upgrade Personal Computers',
          startDate: new Date(2017, 4, 24, 15, 15),
          endDate: new Date(2017, 4, 24, 16, 30)
        }, {
          text: 'Customer Workshop',
          startDate: new Date(2017, 4, 25, 11, 0),
          endDate: new Date(2017, 4, 25, 12, 0),
          allDay: true
        }, {
          text: 'Prepare 2015 Marketing Plan',
          startDate: new Date(2017, 4, 25, 11, 0),
          endDate: new Date(2017, 4, 25, 13, 30)
        }, {
          text: 'Brochure Design Review',
          startDate: new Date(2017, 4, 25, 14, 0),
          endDate: new Date(2017, 4, 25, 15, 30)
        }, {
          text: 'Create Icons for Website',
          startDate: new Date(2017, 4, 26, 10, 0),
          endDate: new Date(2017, 4, 26, 11, 30)
        }, {
          text: 'Upgrade Server Hardware',
          startDate: new Date(2017, 4, 26, 14, 30),
          endDate: new Date(2017, 4, 26, 16, 0)
        }, {
          text: 'Submit New Website Design',
          startDate: new Date(2017, 4, 26, 16, 30),
          endDate: new Date(2017, 4, 26, 18, 0)
        }, {
          text: 'Launch New Website',
          startDate: new Date(2017, 4, 26, 12, 20),
          endDate: new Date(2017, 4, 26, 14, 0)
        }
      ];

      const currentDate = new Date(2017, 4, 25);
      const views = ['day', 'week', 'workWeek', 'month'];

    /* ====================
     *  State / Effect
     * ==================== */       



    /* ====================
     *  Load
     * ==================== */


    /* ====================
     *  Handle / Functions
     * ==================== */

    

    const customizeTooltip = (arg) => {
        return { text: arg.valueText };
    }

    const handleClickLogIn = async () =>{
       // const salt = new Buffer('this_is_salt');

        
        let ret = crypto.createHash('sha512').update('pw').digest('base64');
        alert(ret);

    
        // let buf_ = '';
        // crypto.randomBytes(64, (err, buf) => {
        //     buf_ = buf.toString('base64');
        //     console.log(buf.toString('base64'));
        // })

        // crypto.pbkdf2('pw', buf_, 1000000, 64, 'sha512', (err, key) =>{
        //     console.log(key.toString('base64'));
        // })

        // crypto.pbkdf2('pw', buf_, 1000000, 64, 'sha512', (err, key) =>{
        //     console.log(key.toString('base64'));
        // });

    }
        /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {       
        
        return (
            <>
            <div>
                <ScrollView id="scrollview" 
                    // ref={this.getInstance}
                    // reachBottomText="Updating..."
                    scrollByContent={true}
                    // bounceEnabled={pullDown}
                    // onReachBottom={this.updateBottomContent}
                    // onPullDown={false}
                    showScrollbar={"onHover"}
                    scrollByThumb={true}
                >
                    <div 
                        style={{
                            width: 300,
                            height: 400
                        }}
                    >
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br/>
                        

                    </div>
                </ScrollView>                
            </div>

            <div>Chart</div>
            <div>
            <Chart
                palette={'Violet'}
                dataSource={countriesInfo}
                >
                <CommonSeriesSettings
                    argumentField={'country'}
                    type={'spline'}
                />
                {
                    energySources.map(function(item) {
                    return <Series key={item.value} valueField={item.value} name={item.name} />;
                    })
                }
                <Margin bottom={20} />
                <ArgumentAxis
                    valueMarginsEnabled={false}
                    discreteAxisDivisionMode={'crossLabels'}
                >
                    <Grid visible={true} />
                </ArgumentAxis>
                <Legend
                    verticalAlignment={'bottom'}
                    horizontalAlignment={'center'}
                    itemTextPosition={'bottom'}
                />
                <Export enabled={true} />
                <Title text={'Energy Consumption in 2004'}>
                    <Subtitle text={'(Millions of Tons, Oil Equivalent)'} />
                </Title>
                <Tooltip
                    enabled={true}
                    customizeTooltip={customizeTooltip}
                />
                </Chart>
            </div>
            <div>
                Scheduler
            </div>
            <div>
                <Scheduler
                    dataSource={[]}
                    // views={views}
                    currentView={'month'}
                    //dataCellComponent={DyChkDataCell}
                    
                    // showAllDayPanel={true}
                    // showAllDayPanel={true}
                    firstDayOfWeek={1}
                    // defaultCurrentView={'day'}
                    defaultCurrentDate={currentDate}
                    height={600}
                    // startDayHour={9} 
                    // appointmentRender={(d) => {
                    //     return(
                    //         <>
                    //         <div className="dychk-cell">
                    //             ttt
                    //         </div>
                    //         </>
                    //     )
                    // }}
                />
                <Scheduler
                    dataSource={[]}
                    // views={views}
                    currentView={'month'}
                    // dataCellComponent={DyChkDataCell}
                    
                    // showAllDayPanel={true}
                    showAllDayPanel={true}
                    firstDayOfWeek={1}
                    // defaultCurrentView={'day'}
                    defaultCurrentDate={currentDate}
                    height={600}
                    // startDayHour={9} 
                    // appointmentRender={(d) => {
                    //     return(
                    //         <>
                    //         <div className="dychk-cell">
                    //             ttt
                    //         </div>
                    //         </>
                    //     )
                    // }}
                />
            </div>
            <div>
                <input type='text' id='preCryto'></input>
                <input type='text' id='aftCryto'></input>
                <button 
                    id="loginButton"
                    className="btn"
                    onClick={(e) => {
                        e.preventDefault();
                        handleClickLogIn();
                    }}
                >변환</button>
            </div>
            </>  
        )
    }

    return(
        <>
        {
            renderContent()
        }
        </>
    )
}

export default Sample2Page;
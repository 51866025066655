import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, BizSelectBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  고객매츌현황
 */
const SalesDeliPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startMon, setStartMon] = useState(util.getNow());
    const [endMon, setEndMon] = useState(util.getNow());
    const [prodNm, setProdNm] = useState('');
    const [bizUid, setBizUid] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "회계월",
                width: "200px",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setStartMon(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endMon}
                            type={'month'}
                            displayFormat={cmn.DATE_FORMAT_MON_1}
                            onValueChanged={(e) => {
                                setEndMon(e.value);
                            }}                    
                        />
                    </li>
                </ul>
                </>
            },
            {   title: "업체명",
                width: "200px",
                item: 
                <BizSelectBox
                    value={bizUid}
                    bizTp={['70202']}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        setBizUid(v);
                    }}
                />     
            },
            {   title: "품명",
                item: 
                <TextBox 
                    width={"200px"}
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            }
        ]
    ];
    /* #endregion */
    
    // * Grid
    const [salesDeliList, setSalesDeliList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, bizUid, startMon, endMon]);   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        if(util.getChkNull(bizUid) === '') return;

        const ps =  {
            p: {
                startMon: util.getDateToString('M',startMon), 
                endMon: util.getDateToString('M',endMon), 
                bizUid: bizUid, 
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getSalesDeliList", ps );
        if(r === undefined || r == null) return;

        setSalesDeliList(r.d0)
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const handleClickSalesDeliView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("", selectedItem.bizProdUid, util.getDateToString('M',selectedItem.startMon), util.getDateToString('M',selectedItem.endMon));
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "buyDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    
    // * Functions

    const openCrudPopup = (mode, bizProdUid, startMon, endMon) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/sales/salesdelipop?${qs.stringify({mode: mode, bpuid: bizProdUid, sm: startMon, em: endMon})}`;        
        util.openWindow(url, 800, 700);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    width={"150px"}
                    onClick={handleClickSalesDeliView}
                    title="수주/납품건 조회"
                    show={true}
                />  
            </ListTopView>
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="수주대비납품내역"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>

            {/** GRID */}
            <Observer>{() =>
            <>        
            <DataGridView           
                reff={grid1} 
                dataSource={salesDeliList}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H20}

                pagingable={false}
                // paging
                // page={curPage}
                // total={salesStore.salesDeliListTotal}   // store
                // itemsPerPage={rowsLength}
                // onClickPage={handleClickPage}
                // onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}   
                
                // excel
                exlnm={'수주대비납품내역'}
                customizeExcelCell={customizeExcelCell}       

                colsWidth={[cmn.CW.bizNm, cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    , cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, 120, cmn.CW.amt, null]}
            >
                { cmn.getGridColumn('bizNm', '고객', renderGridHeaderCell) }
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('salesCnt', '수주건') }
                { cmn.getGridColumnNumCommaType('deliCnt', '납품건') }
                { cmn.getGridColumnNumCommaType('salesAmt', '수주량') }
                { cmn.getGridColumnNumCommaType('deliAmt', '납품량') }
                { cmn.getGridColumnNumCommaType('deliPrice', '판매금액') }
                { cmn.getGridColumnNumCommaType('noDeliAmt', '미납량') }
                <Column />
                <Summary>
                    <TotalItem
                        column={'salesCnt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'deliCnt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'salesAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'deliAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'deliPrice'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                    <TotalItem
                        column={'noDeliAmt'}
                        summaryType={'sum'}
                        customizeText={(d) => {
                            return util.getNumComma(d.value);
                        }}
                    />
                </Summary>
            </DataGridView>
            </>
            }</Observer>
            </>
        )

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default SalesDeliPage;
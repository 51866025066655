import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextBox } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';

import _ from 'lodash';

/** components */
import { ActionButton, SubActionButton } from '..';

/** stores & lib */
import { util, cmn } from '../../lib';

/** 
 *  제품 검색 조회
 */
const CancelButton = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    /* ====================
     *  Object
     * ==================== */

    const {
        isDel,
        isDelMsg = '취소할 수 없습니다.',
        title = '취소',
        titleMsg = '취소하시겠습니까?',
        popCenter=true,
        handleClickComplete,
        marginRight=0,
        placeholder = '취소 사유를 입력하세요.'
    } = props;

    const pw = 400;     // popup width
    const ph = 135;     // popup height

    /* ====================
     *  State / Effect
     * ==================== */

    const [popupVisible, setPopupVisible] = useState(false);
    const [delCmnt, setDelCmnt] = useState('');
    const divCancel = useRef(null);

    useEffect(() => {
        loadPage();
    },[]);


    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {        
        
    }



    /* ====================
     *  Handle / Functions
     * ==================== */
    const handleClickOk = () =>{
        if(!util.chkMustValue(delCmnt, '사유'))
            return;

        handleClickComplete(delCmnt);
    }
    const handleClickCancel = (e) => {
        setPopupVisible(false);
    }

    const handleClickOpenPop = (e) => {
        if(isDel === 'N')
        {
            util.cmnAlert(isDelMsg);
            
            return;
        }

        setPopupVisible(true);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <div ref={divCancel}>
                <ActionButton 
                    onClick={handleClickOpenPop}
                    title={title}
                    marginRight={marginRight}
                    show={true}
                />
            </div>
        
            <Popup
                visible={popupVisible}
                shading={false}
                dragEnabled={true}
                closeOnOutsideClick={true}
                width={pw}
                height={ph}
                title={titleMsg}
                position={(e)=>{
                    if(popCenter) {
                        const ofs = `${(window.innerWidth/2)} ${(window.innerHeight/2)}`;   
                        return {at: 'left top', offset: ofs};   
                    }
                }}

                onHiding={(e)=>{
                    setPopupVisible(false);
                }} 
            >
                <>
                <div className="board_list_type">
                    <table>
                        <colgroup>
                            <col className="tit" />
                            <col className="tcon" />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className="tit req">사유</th>
                            <td>
                                <TextBox 
                                    value={delCmnt}
                                    showClearButton={true}
                                    placeholder= {placeholder}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setDelCmnt(e.value);
                                    }}

                                /> 
                            </td>
                            
                        </tr>
                        <tr>
                            <td colSpan={3} style={{textAlign:'right'}}>
                                <SubActionButton
                                    title={'확인'}
                                    onClick={handleClickOk}
                                    show={true}
                                />
                                <SubActionButton
                                    title={'취소'}
                                    onClick={handleClickCancel}
                                    show={true}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </>
            </Popup>  
              
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default CancelButton;

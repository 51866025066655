import uuidv1  from 'uuid/v1';
import moment from 'moment';
import _ from 'lodash';
import crypto from 'crypto';

const util = {

    /** Open Window - 새창 */
    openWindow: (url, w, h) => {
        const left = (window.innerWidth - w) / 2;
        const top = (window.innerHeight - h) / 4;

        window.open(
            url,
            "_blank",
            `toolbar=yes,scrollbars=yes,resizable=yes,top=${top},left=${left},width=${w},height=${h}`
        );
    },

    clickElement: (elementId) => {
        document.getElementById(elementId).click();
    },

    /** Click Opener Element */
    clickOpenerElement: (elementId) => {
        if(util.getChkNull(window.opener.document.getElementById(elementId)) === '') return;
        window.opener.document.getElementById(elementId).click();
    },

    focusElement: (elementId) => {
        document.getElementById(elementId).focus();
    },

    blurElement: (elementId) => {
        document.getElementById(elementId).blur();
    },

    /** Get UUID */
    getUUID: () => {
        return uuidv1();
    },

    /** String 인 경우 undefined, null, '' 인경우 r 으로 리턴 */
    getChkNull: (o, r) => {
        if(r === undefined) r = "";
        return (o === undefined || o == null || o === '') ? r : o;
    },

    /** Date to String Format */

    // 날짜 형식을 문자형식으로 변경한다.
    getDateToString:(type, date, delimiter, format) => {
        if(util.getChkNull(date) === '') date = moment();
        if(delimiter === undefined) delimiter = '';
        if(format === undefined) format = `YYYY${delimiter}MM${delimiter}DD`;

        if(type === 'y')
            format = `YYYY`;
        else if(type === 'M')
            format = `YYYY${delimiter}MM`;
        else if(type === 'd')
            format = `YYYY${delimiter}MM${delimiter}DD`;
        else if(type === 'h')
            format = `YYYY${delimiter}MM${delimiter}DD HH`;
        else if(type === 'm')
            format = `YYYY${delimiter}MM${delimiter}DD HH:mm`;
        else if(type === 's')
            format = `YYYY${delimiter}MM${delimiter}DD HH:mm:ss`;
        else if(type === 't')
            format = `HH${delimiter}mm`;
            
        return moment(date).format(format);
    },

    // 날짜/시간 문자열을 Split 한다..
    getDateStrSplit:(type, day, delimiter) => {
        if(delimiter === undefined) delimiter = '-';
        if(util.getChkNull(day) === '') return '';        
        let ret = '';
        if(type === 'y')
            ret = `${day.substring(0,4)}`;
        else if(type === 'M')
            ret = `${day.substring(0,4)}${delimiter}${day.substring(4,6)}`;
        else if(type === 'm')
            ret = `${day.substring(4,6)}${delimiter}${day.substring(6,8)}`;
        else if(type === 'd')
            ret = `${day.substring(0,4)}${delimiter}${day.substring(4,6)}${delimiter}${day.substring(6,8)}`;
        else if(type === 't')
            ret = `${day.substring(0,2)}${delimiter}${day.substring(2,4)}`;

        return ret;
    },

    /**
     * 
     * @d1 : dividend  분모
     * @d2 : divisor  분자
     * @n : 소숫점 이하 자리수
     */
    getPercentage: (d1, d2, n) => {
        if(util.getChkNull(d1) === '' || util.getChkNull(d2) === '') return '';
        if(n === undefined) n = 2;
        return util.fix(_.divide(d1, d2)*100, n);
    },

    getNumComma: (n, r) => {
        if(r === undefined) r = 0;
        if(util.getChkNull(n) === '') return r; 

        let parts = n.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },

    /** Number 인 경우 NaN, undefined, null 인경우 r 으로 리턴 */
    getNumCheck: (n, r) => {
        if(r === undefined || r == null) r = 0;
        return (_.isNumber(n) && !_.isNaN(n)) ? n : r;
    },

    /**
     * get Value ...
     * o: object, c: column, r: return
     */
    getVal: (o, c, r) => {
        if(r === undefined || r == null) r = '';
        if(o === undefined || o == null) return r;
        return o[c];
    },

    getNow: (type, delimiter) => {
        if(type === undefined) type = 'd';
        if(delimiter === undefined) delimiter = '-';

        return util.getDateToString(type, moment(), delimiter); //2020-03-12
    },

    getNowMon: () => {
        return util.getDateToString('M', moment(), '-');
    },

    getMonStartEndDy: (type, m) => {
        let ret = `${m.substring(0,7)}-01`;
        if(type !== 'S')
            ret = util.getAddDate2(util.getAddDate2(ret, +1, 'M'), -1, 'd');
        
        return util.getDateToString('d', ret);
    },

    isToday: (dy) => {
        if(util.getDateToString('d',dy) === util.getDateToString('d',util.getNow())) return true;
        return false;
    },
 
    /*
    v : value (ex +1 or -1)
    k : key
    ex) 
        years	y
        quarters	Q
        months	M
        weeks	w
        days	d
        hours	h
        minutes	m
        seconds	s
        milliseconds	ms
    f : format
    */
    getAddDate: (v, k, f) => {
        if(f === undefined || f == null) f = `YYYYMMDD`;
        if(k === undefined || k == null) k = 'd';
       
        return moment().add(v, k).format(f);
    },

    getAddDate2: (d, v, k, f) => {
        if(f === undefined || f == null) f = `YYYYMMDD`;
        if(k === undefined || k == null) k = 'd';
       
        return moment(d).add(v, k).format(f);
    },


    getCurHour: () => {
       return moment().hour();
    },

    /** #region  */
    // 필수값 체크 
    // 값이 있으면 true 값이 있으면 false 리턴
    // v : value
    // n : return text
    // t : defalut = str, checked = chk, cout = cnt
    chkMustValue: (v, n, t) => {
        let ret = true;
        
        if(v === undefined || v == null) v = '';
        if(n === undefined || n == null) n = '필수';
        if(t === undefined || t == null) t = 'str';

        if(t === 'chk')
        {
            if(!_.find(v, {checked: true}))
                ret = false;
        }else if (t === 'cnt'){
            if(v === '' || v <= '0')
                ret = false;
        }else
        {
            if(_.trim(v) === '')
                ret = false;
        }

        if(!ret)
            util.cmnAlert(`[${n}] 데이타를 입력해 주십시오.`);

        return ret;
    },

    // 리스트 행선택 체크 
    chkListSelected: (v, m) => {
        let ret = true;
        if(v === undefined || v == null) v = [];
        if(m === undefined || m == null) m = "행을";

        if(_.isString(v))
        {
            if(_.trim(v) === '')
            {
                util.cmnAlert(`${m} 선택하여 주십시오.`);
                ret = false;
            }
        }
        else
        {
            if(v.length <= 0)
            {
                util.cmnAlert(`${m} 선택하여 주십시오.`);
                ret = false;
            }
        }
        return ret;
    },

    getValEl: (o) => {
        // console.log("### o", o);
        if(o == undefined || o == null) return;
        if(o.current == undefined || o.current == null) return;

        // return o.current.instance._options.value;
        return o.current.instance.option('value');
    },    

    getDgSelectedRowsData: (dgEl) => {
        return dgEl.current.instance.getSelectedRowsData();
    },

    confirmYn: (v) => {
        if(v === undefined || v == null) v = '진행';
        let msg = `[${v}] 하시겠습니까?`;
        
        return util.cmnConfirm(msg);
    },

    // v : value (message)
    cmnAlert: (v) => {
        // Todo: 디자인 된 Alert 창 호출
        alert(v);
    },

    cmnSucAlert: (v) => {
        if(v === undefined || v == null) v = '완료';
        let msg = `[${v}] 하였습니다.`;
        alert(msg);
    },
    
    // v : value (message)
    cmnConfirm: (v) => {
        // Todo: 디자인 된 Alert 창 호출
        return window.confirm(v);
    },

    fix: (v, n) => {
        if(n === undefined) n = 3;
        return _.isInteger(v) ? v : parseFloat(v).toFixed(n).replace(/(0+$)/, '').replace(/([.]+$)/, '')
    },

    pwInit: (v) => {
        let buf = crypto.randomBytes(64);
        let key = crypto.pbkdf2Sync(v, buf.toString('base64'), 1000, 64, 'sha512');
        
        let retBuf = buf.toString('base64');
        let retKey = key.toString('base64');
        return {retBuf, retKey};
    },

    pwCrypto: (v, k) => {
        let key = crypto.pbkdf2Sync(v, k, 1000, 64, 'sha512');
        let retKey = key.toString('base64');
        return retKey
    },

    getPwChk: (v) => {

        var pw = v;
        //alert(v);
        // var num = pw.search(/[0-9]/g);
        // var eng = pw.search(/[a-z]/ig);
        // var spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
        
        var reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

        let ret = true;

        if(pw.length < 8 || pw.length > 20){
         //   alert(1);
            ret = false;
        }else if(pw.search(/\s/) != -1){
       //     alert(2);
            ret = false;
        }else if(!reg.test(pw)){
         //   alert(3);
            ret = false;
        }else {
            ret = true;
        }
       
        return ret;
    }
}

export default util;
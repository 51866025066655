import React, { useState, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import SplitterLayout from 'react-splitter-layout';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    SrchButton, SubActionButton, ActionButton
} from '../../components'; 

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공통코드 관리
 */
const AuthPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [authNm, setAuthNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "권한명",
                item: 
                <TextBox 
                    value={authNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="권한명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setAuthNm(e.value);
                    }}
                />        
            }
        ]
    ];

    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItem2, setSelectedItem2] = useState(null);


    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    useEffect(() => {
        !pageLoad && handleClickSearch2();
    }, [selectedItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {

        const ps =  {
            p: {
                authNm: authNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        await authStore.loadAuthList(ps);
    }

    const loadList2 = async() => {
        if(selectedItem == null) return; 
        const ps =  {
            p: {
                authUid: selectedItem.authUid, 

                sortCol: "",
                sortTp: "",
                offset: 0,
                limit: -1,            
            }
        }

        await authStore.loadUserAuthList(ps);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickSearch2 = async(e) => {
        try {
            await loadList2();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    //권한등록팝업
    const handleClickAdd = (e) => {
        openCrudPopup("a", "");
    }
    //권한수정팝업
    const handleClickUp = (e) => {
        // alert(authUid);
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.authUid);
        }
     }
     //권한상세팝업
    const handleClickView = (e) => {
        // alert(authUid);
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.authUid);
        }
     }
  
    //사용자등록팝업
    const handleClickAddUser = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup2("a", selectedItem.authUid);
        }
    }
    //사용자삭제
    const handleClickDelUser = async (e) => {
        if(util.chkListSelected(selectedItem2)){
            if(!util.confirmYn('삭제')) return;

            const ps =  { p :{authUserUid: selectedItem2.authUserUid }}

            let r = await cmn.api('delAuthUser', ps);
            if(r == null || r === undefined) return;

            util.cmnSucAlert('삭제');

            loadList2();
        }
    }

    const handleGridSelectionChanged = ({selectedRowsData}) => {
    //    console.log("=== selectedRowsData", selectedRowsData);
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGrid2SelectionChanged =  ({selectedRowsData}) => {
    //    console.log("=== selectedRowsData", selectedRowsData);
        if(selectedRowsData.length === 0) { 
            setSelectedItem2(null); return;
        }
        setSelectedItem2(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);        
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    //권한 등록
    const openCrudPopup = (mode, authuid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/authpop?${qs.stringify({mode: mode, uid: authuid})}`;
        util.openWindow(url, 650, 250);
    }

    //권한별 사용자 등록
    const openCrudPopup2 = (mode, authuid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/authuserpop?${qs.stringify({mode: mode, uid: authuid})}`;
        util.openWindow(url, 600, 350);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderLeftContent = () => {
        return (
            <>
            <GridTopView
                title="권한 목록"
            >
            </GridTopView>   
            <Observer>{() =>
            <>        
            <DataGridView            
                dataSource={authStore.authList.slice()}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H21}

                // paging
                page={curPage}
                total={authStore.authListTotal}   // store
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort                
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}

                colsWidth={[150, 150, 80, null]}
            >
                { cmn.getGridColumn('authNm', '권한명') }
                { cmn.getGridColumnNumCommaType('userCnt', '사용자수') }
                { cmn.getGridColumnUseYn() }
                { cmn.getGridColumnCmnt() }
                
            </DataGridView>
            </>
            }</Observer>
            </>
        )   
    }

    const renderRightContent = () => {

        return (
            <>
            <GridTopView
                title="사용자 목록"
            >
                <SubActionButton 
                    onClick={handleClickAddUser}
                    title="추가"
                    show={authStore.page.crtYn}
                /> 
                <SubActionButton 
                    onClick={handleClickDelUser}
                    title="삭제"
                    marginRight={0}
                    show={authStore.page.delYn}
                />
            </GridTopView>                        
            
            <DataGridView            
                dataSource={authStore.userAuthList.slice()}   // store
                gridHeight={authStore.windowHeight - cmn.BH.H20}
                page={-1}
                onSelectionChanged={handleGrid2SelectionChanged}

                colsWidth={[150, 150, null]}
            >
                { cmn.getGridColumn('deptNm', '부서명') }
                { cmn.getGridColumn('userNm', '사용자명') }
                <Column />   
            </DataGridView>
            </>
        )   
    }
 
    const renderContent = () => {

        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
                <SrchButton 
                    id="subSrchButton"
                    onClick={handleClickSearch2}
                    show={false}
                />
            </ListTopView>
            }</Observer>
            <ListSearchView searchItems={searchItems} />
            <Observer>{() =>    
            <div className="cont-split"
                style={{
                    marginTop: 16,
                }}
            >
                <SplitterLayout
                    customClassName={`split-layout-cust ${authStore.sideOpened === true ? "is-visible" : ""}`}
                >
                    <div className="cont-split-left"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderLeftContent() }
                    </div>
                    <div className="cont-split-right"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderRightContent() }
                    </div>
                </SplitterLayout>
            </div>           
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default AuthPage;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    DayDateBox, SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  불출 관리
 */
const ToolOutPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [startDy, setStartDy] = useState(util.getAddDate(-7));
    const [endDy, setEndDy] = useState(util.getNow());
    const [toolNm, setToolNm] = useState('');
    const [prodNm, setProdNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "불출일",
                width: "200px",
                item: 
                    <>
                    <ul className="ul-row">
                        <li>
                            <DayDateBox 
                                value={startDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setStartDy(e.value);
                                }}                    
                            />
                        </li>
                        <li>
                            ~
                        </li>
                        <li>            
                            <DayDateBox 
                                value={endDy}
                                displayFormat={cmn.DATE_FORMAT_DAY_1}
                                onValueChanged={(e) => {
                                    setEndDy(e.value);
                                }}                    
                            />
                        </li>
                    </ul>
                    </>
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />       
            },
            {   title: "치공구명",
                item: 
                <TextBox 
                    value={toolNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setToolNm(e.value);
                    }}
                /> 
            }
        ]
    ];

    /* #endregion */

    // * Grid
    const [toolOutList, setToolOutList] = useState([]);
    const [toolOutListTot, setToolOutListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    const grid1 = useRef(null);
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadList1 = async() => {
        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),
                prodNm:prodNm,
                toolNm: toolNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getToolOutList", ps );
        if(r === undefined || r == null) return;

        setToolOutList(r.d0)
        setToolOutListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }
    
    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.toolOutUid);
        }
    }
    
    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.toolUid);
        }
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        // if(     gc.column.dataField === "payDy" 
        //     ||  gc.column.dataField === "toolDy" 
        //     ) e.value =util.getDateStrSplit('d',gc.value);
    }
    
    // * Functions
    const openCrudPopup = (mode, toolOutUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/tool/tooloutpop?${qs.stringify({mode: mode, uid: toolOutUid})}`;        
        util.openWindow(url, 750, mode === 'a' ? 650 : 450); 
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(           
            <>
            <Observer>{() =>
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />
                {/* <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                /> */}
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />  
            </ListTopView>  
            </>  
            }</Observer>   
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="불출 내역"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView> 
            <Observer>{() =>
            <>        
            <DataGridView      
                reff={grid1}      
                dataSource={toolOutList}  
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={toolOutListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}     

                // excel
                exlnm={'불출내역'}
                customizeExcelCell={customizeExcelCell}     

                colsWidth={[80, 120, 120, 100, 100, 120, 80, 80, 80, null]}
            >
                { cmn.getGridColumn('workNum', '작업번호', renderGridHeaderCell)}
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumn('toolId', '치공구품번', renderGridHeaderCell)}
                { cmn.getGridColumn('toolCd', '치공구코드', renderGridHeaderCell)}
                { cmn.getGridColumn('toolNm', '치공구명', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('outAmt', '불출수량')}
                { cmn.getGridColumn('userNm', '불출자')}
                { cmn.getGridColumnDayType('outDy', '불출일') }
                { cmn.getGridColumnCmnt() }
                
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ToolOutPage;
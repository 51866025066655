import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';

import _ from 'lodash';

/** components */
import { SrchButton, SubActionButton, DataGridView, CodeSelectBox } from '..';

/** stores & lib */
import { CmnStore } from '../../stores';
import { cmn, util } from '../../lib';

/** 
 *  제품 검색 조회
 */
const ProdSearchBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const cmnStore = useContext(CmnStore);
    
    /* ====================
     *  Object
     * ==================== */

    const {
        value,
        btnType="1",
        popCenter=false,
        width= '100%',
        onLoadInfo,
        onValueChanged,
        showSearchText=true,

        schProdTp= true,
        schProdNm= true,
        schProdId= true,
        schDrawNum= false,
        schProdStnd = false,

    } = props;

    const pw = 600;     // popup width
    const ph = 470;     // popup height

    /* ====================
     *  State / Effect
     * ==================== */

    const [prods, setProds] = useState([]);
    const [prodsTotal, setProdsTotal] = useState(0);    
    const [searchText, setSearchText] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);

    // * 검색
    const [prodTp, setProdTp] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [drawNum, setDrawNum] = useState('');
    const [prodStnd, setProdStnd] = useState('');

    const [cols, setCols] = useState([]);

    // * Grid, Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(10);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const [prodInfo, setProdInfo] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);    

    const grid1 = useRef(null);
    const inputProd = useRef(null);
    const divProd = useRef(null);

    useEffect(() => {
        loadPage();
    },[value]);

    useEffect(() => {
        handleClickSearch();
    }, [curPage, rowsLength, sortItem, prodTp]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = () => {        
        try {

            
            let schList = [
                {
                    colId : 'prodTp',
                    colNm : '유형',
                    value : schProdTp,
                    width : 100
                },
                {
                    colId : 'prodNm',
                    colNm : '품명',
                    value : schProdNm,
                    width : 200
                },
                {
                    colId : 'prodId',
                    colNm : '품번',
                    value : schProdId,
                    width : 100
                },
                {
                    colId : 'drawNum',
                    colNm : '도면번호',
                    value : schDrawNum
                },
                {
                    colId : 'prodStnd',
                    colNm : '규격',
                    value : schProdStnd
                }
            ]

            let col_ = [];
            
            _.forEach(schList, (v, k) => {
                if(v.value)
                {
                    if(v.colId === 'prodTp')
                    {
                        col_.push({
                            colId : 'prodTpNm',
                            colNm : '유형',
                            value : schProdTp,
                            width : 100
                        });
                    }else
                    {
                        col_.push(v);
                    }
                }
            })
            setCols(col_);

            if(util.getChkNull(value) === '') {

                setSelectedItem(null);
                setProdInfo('');    
                handleLoadInfo(null);
                setPopupVisible(false);
                grid1.current.instance.clearSelection();
                return;
            }     
            
            // Colunm 정보를 설정한다.
            loadProd();
        }
        catch(err) {
            // nothing ...
        }
    }

    const loadProd = async() => {
         const ps =  {
            p: {
                prodUid: value
            }
        }

        const r = await cmn.api("getProd", ps);
        const d = r.d0[0];

        setProdInfo(d.prodNm);
        handleLoadInfo(d);
    }

    const loadProds = async() => {
 
        const ps =  {
            p: {
                prodTp: prodTp, 
                prodNm: prodNm, 
                prodId: prodId,
                drawNum: drawNum,
                prodStnd: prodStnd,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getProdList", ps);

        setProds(r.d0);
        setProdsTotal(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleLoadInfo = (ds) => {

        setProdInfo(ds.prodNm);

        onLoadInfo(ds);        
    }

    const handleValueChanged = (v, o) => {
        onValueChanged(v, o);
    }

    const handleClickOpenPop = (e) => {
        setPopupVisible(true);
    }

    const handleClickSearch = async(e) => {
        try {
            await loadProds();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) return;

        setSelectedItem(selectedRowsData[0]);

        handleLoadInfo(selectedRowsData[0]);
        handleValueChanged(selectedRowsData[0].prodUid, selectedRowsData[0]);

        setPopupVisible(false);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            <div ref={divProd}>
                {
                showSearchText ?
                    <ul className="ul-row" style={{width: width}}>
                        <li style={{width: 'calc(100% - 80px)'}}>
                            <TextBox
                                width={'100%'}         
                                ref={inputProd}
                                value={prodInfo}

                                readOnly={true}
                                showClearButton={true}

                                placeholder="제품을 검색하여 선택하세요."
                                valueChangeEvent={'keyup'}
                                onValueChanged={(e)=>{
                                    setSearchText(e.value);
                                }} 

                                onEnterKey={(e)=>{
                                    setPopupVisible(true);
                                }} 
                            />
                        </li>
                        <li className="fl-r" style={{width: '80px'}}>                        
                            <SubActionButton 
                                onClick={handleClickOpenPop}
                                title="검색"
                                type="1"
                                marginRight={0}
                                show={true}
                            />
                        </li>
                        
                    </ul>
                    :
                    <ul className="ul-row" style={{width: width}}>
                        <li style={{width: width}}>                        
                            <SubActionButton 
                                onClick={handleClickOpenPop}
                                title="검색"
                                width={width}
                                type={btnType}
                                marginRight={0}
                                show={true}
                            />
                        </li>
                    </ul>
                }
            </div>
        
            <Popup
                visible={popupVisible}
                
                shading={false}
                dragEnabled={true}
                closeOnOutsideClick={true}

                width={pw}
                height={ph}

                position={(e)=>{
                    
                    if(popCenter) {
                        const ofs = `${(window.innerWidth/2)} ${(window.innerHeight/2)}`;   
                        // console.log("&&& ofs", ofs);
                        // console.log("&&& window", window);
                        return {at: 'left top', offset: ofs};   
                    }
                    else {
                        if(divProd.current === undefined || divProd.current == null) return;
                        const r = divProd.current.getBoundingClientRect();
                        const ofs = `${r.x+(pw/2)} ${r.y+(ph/2)+r.height+2}`;   

                        return {at: 'left top', offset: ofs};                        
                    }                  

                }}

                onHiding={(e)=>{
                    setPopupVisible(false);
                }} 
            >
                <>
                <div className="clearfix">
                    <div className="fl-l" style={{width: 'calc(100% - 120px)'}}>
                        <ul className="ul-row">
                            {
                                schProdTp ? 
                                <li style={{width: '30%'}}>
                                    <CodeSelectBox
                                        value={prodTp}
                                        classId={'101'}
                                        placeholder="제품유형"
                                        onValueChanged={(v) => { 
                                            setProdTp(v);
                                        }}
                                    />
                                </li>
                                : ''
                            }
                            {
                                schProdNm ? 
                                <li  style={{width: '40%'}}>
                                    <TextBox 
                                        width={'100%'}
                                        value={prodNm}
                                        showClearButton={true}
                                        placeholder="품명"
                                        valueChangeEvent={'keyup'}

                                        onEnterKey={(e)=>{
                                            loadProds();
                                        }} 

                                        onValueChanged={(e)=>{
                                            setProdNm(e.value);
                                        }}

                                    /> 
                                </li>
                                : ''
                            }
                            {
                                schProdId ? 
                                <li  style={{width: '30%'}}>
                                    <TextBox 
                                        value={prodId}
                                        showClearButton={true}
                                        placeholder="품번"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setProdId(e.value);
                                        }}
                                    /> 
                                </li>
                                : ''
                            }
                            {
                                schDrawNum ? 
                                <li  style={{width: '30%'}}>
                                    <TextBox 
                                        value={prodId}
                                        showClearButton={true}
                                        placeholder="도면번호"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setDrawNum(e.value);
                                        }}
                                    /> 
                                </li>
                                : ''
                            }
                            {
                                schProdStnd ? 
                                <li  style={{width: '30%'}}>
                                    <TextBox 
                                        value={prodStnd}
                                        showClearButton={true}
                                        placeholder="규격"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setProdStnd(e.value);
                                        }}
                                    /> 
                                </li>
                                : ''
                            }
                        </ul>
                    </div>

                    <div className="fl-r">

                        <SrchButton 
                            id="srchButton"
                            onClick={handleClickSearch}
                            type="selpop"
                            marginRight={0}
                        />
                
                    </div>
                </div>
                <div>

                    <DataGridView   
                        reff={grid1}         
                        dataSource={prods}   // store

                        gridHeight={320}

                        showNo={false}

                        // paging
                        page={curPage}
                        total={prodsTotal}   // store

                        itemsPerPage={rowsLength}
                        pagesPerBlock={5}
                        onClickPage={handleClickPage}
                        onChangePageSize={handleChangePageSize}

                        // sort
                        onCellClick={handleGridCellClick}

                        // select
                        onSelectionChanged={handleGridSelectionChanged}        

                        colsWidth={[null, null, null, null]}
                    >
                        { cmn.getGridColumn(cols.length > 0 && cols[0].colId, cols.length > 0 && cols[0].colNm) }
                        { cmn.getGridColumn(cols.length > 0 && cols[1].colId, cols.length > 0 && cols[1].colNm) }
                        { cmn.getGridColumn(cols.length > 0 && cols[2].colId, cols.length > 0 && cols[2].colNm) }
                        <Column />
                    </DataGridView>

                </div>
                </>
            </Popup>  
              
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default ProdSearchBox;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, GridTopView, DataGridView,
    CodeSelectBox, BizSelectBox, SrchButton, ActionButton, SiteLayout
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  외주출고 관리
 */
const SiteOutsOutStndPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * Control Items (* Combo ...)
    // * 검색
    const [prodId, setProdId] = useState('');
    const [procTp, setProcTp] = useState('');
    const [bizUid, setBizUid] = useState('');

    /* #region Search Items */
    
    const searchItems = [
        [
            {   title: "품번",
                width: "200px",
                item: 
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />  
            },
            {   title: "공정",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={procTp}
                    classId={'104'}
                    placeholder="공정을 선택하세요."
                    onValueChanged={(v) => { 
                        setProcTp(v);
                    }}
                />      
            },
            {   title: "협력사",
                item: 
                <BizSelectBox
                    value={bizUid}
                    width={'200px'}
                    bizTp={[cmn.CMNCO_BIZ.PART]}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        setBizUid(v);
                    }}
                />   
            }
        ]
    ];

    /* #endregion */    

    // * Grid
    const [outsOutListStndBy, setOutsOutListStndBy] = useState([]);
    const [outsOutListStndByTot, setOutsOutListStndByTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    // const dgCodeListRef = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);  

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodId: prodId, 
                procTp: procTp, 
                bizUid: bizUid, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getOutsOutListStndBy", ps );
        if(r === undefined || r == null) return;

        setOutsOutListStndBy(r.d0)
        setOutsOutListStndByTot(r.tot);
        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const handleClickOutsOut = () => {
        if(util.chkListSelected(selectedItem)){
            //alert(selectedItem.lot);
            const url = `/site/siteoutsout?${qs.stringify({lt:selectedItem.lot})}`;
            authStore.history.push(url);
        }
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        return(
            <>
            <div id="SiteContWrap">  
                <GridTopView title="외주출고대기 현황">
                    <SrchButton 
                        id="srchButton"
                        type={"site-1"}
                        onClick={handleClickSearch}
                    />
                    <ActionButton
                        width={"140px"}
                        onClick={handleClickOutsOut}
                        title="출고등록"
                        type={"site-1"}
                        show={true}
                    />
                </GridTopView>   
                <ListSearchView searchItems={searchItems} type="site" />
                <DataGridView            
                    dataSource={outsOutListStndBy}
                    gridHeight={authStore.windowHeight - cmn.BH.H10}

                    // paging
                    page={curPage}
                    total={outsOutListStndByTot}
                    itemsPerPage={rowsLength}
                    onClickPage={handleClickPage}
                    onChangePageSize={handleChangePageSize}

                    // sort
                    onCellClick={handleGridCellClick}

                    // select
                    onSelectionChanged={handleGridSelectionChanged}     
                    
                    colsWidth={[100, 150, null, null, null, 150, 150]}
                >
                    { cmn.getGridColumn('procNm', '공정')}
                    { cmn.getGridColumn('lot', 'Lot')}
                    { cmn.getGridColumn('prodNm', '품명')}
                    { cmn.getGridColumn('prodId', '품번')}
                    { cmn.getGridColumn('prodStnd', '규격')}
                    { cmn.getGridColumnNumCommaType('stndByAmt', '출고예정수량') }
                    { cmn.getGridColumnNumCommaType('compAmt', '기출고수량') }
                </DataGridView>
            </div>
            </>
        );

    }

    return (
        <>
        <SiteLayout
            title={"외주출고대기"}
            content = { !pageLoad && renderContent() }
        />
        </>
    )
}

export default SiteOutsOutStndPage;
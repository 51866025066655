const moldApiz = {
    
    /** ***************** 
     *  목록 조회
     *  ***************** */

    getMoldList: {
        title: "금형 목록 조회",
        url: "/api/mold/getMoldList",
        method: "POST"
    },    

    getMoldMoveHisList: {
        title: "금형 이동 목록 조회",
        url: "/api/mold/getMoldMoveHisList",
        method: "POST"
    },    

    getMoldInspHisList: {
        title: "금형 점검 목록 조회",
        url: "/api/mold/getMoldInspHisList",
        method: "POST"
    }, 

    /** ***************** 
     *  단일조회
     *  ***************** */

    getMold: {
        title: "금형 상세 조회",
        url: "/api/mold/getMold",
        method: "POST"
    },   

    getMoldMoveHis: {
        title: "금형 이동정보 단일 검색",
        url: "/api/mold/getMoldMoveHis",
        method: "POST"
    },   
    
    getMoldInspHis: {
        title: "금형 점검정보 단일 검색",
        url: "/api/mold/getMoldInspHis",
        method: "POST"
    },   

    getMoldHisRepo: {
        title: "금형 이력카드 리포트",
        url: "/api/mold/getMoldHisRepo",
        method: "POST"
    },   

    /** ***************** 
     *  등록
     *  ***************** */
    
    addMold: {
        title: "금형 등록",
        url: "/api/mold/addMold",
        method: "POST"
    },    

    addMoldMove: {
        title: "금형 이동정보 등록",
        url: "/api/mold/addMoldMove",
        method: "POST"
    },    

    addMoldInsp: {
        title: "금형 점검정보 등록",
        url: "/api/mold/addMoldInsp",
        method: "POST"
    },    

    /** ***************** 
     *  수정
     *  ***************** */
   
    upMold: {
        title: "금형 수정",
        url: "/api/mold/upMold",
        method: "POST"
    }, 

    upMoldMove: {
        title: "금형 이동 수정",
        url: "/api/mold/upMoldMove",
        method: "POST"
    }, 
    
    upMoldInsp: {
        title: "금형 점검 수정",
        url: "/api/mold/upMoldInsp",
        method: "POST"
    }, 

    /** ***************** 
     *  삭제
     *  ***************** */

    delMold: {
        title: "금형 삭제",
        url: "/api/mold/delMold",
        method: "POST"
    }, 
    
    delMoldMove: {
        title: "금형 이동 정보 삭제",
        url: "/api/mold/delMoldMove",
        method: "POST"
    }, 

    delMoldInsp: {
        title: "금형 점검 정보 삭제",
        url: "/api/mold/delMoldInsp",
        method: "POST"
    }, 

}

export default moldApiz;
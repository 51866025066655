import React, { useState, useEffect, useRef } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  검사순번 선택 조회
 */
const InspIdxSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    /* ====================
     *  Object
     * ==================== */

    const {
        mode,
        width='100%',
        value,
        placeholder='차수',

        inspDy,
        lot,
        prodProcUid,
        equpUid,

        // showClearButton=false,

        onValueChanged

    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [inspIdx, setInspIdx] = useState();
    const [inspIdxs, setInspIdxs] = useState([]);

    useEffect(() => {
        loadPage();
    },[inspDy, lot, prodProcUid, equpUid, inspIdx]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {      

        // alert(inspDy);
        // alert(lot);
        // alert(prodProcUid);

        try {
            if(    util.getChkNull(inspDy) === ''
            || util.getChkNull(lot) === ''
            || util.getChkNull(prodProcUid) === ''
            // || util.getChkNull(equpUid) == ''
            ){
                setInspIdx('1');
                setInspIdxs([]);
                
                onValueChanged();
                return;
            }

            const ps = {
                p: {
                    inspDy: inspDy,
                    lot: lot,
                    prodProcUid: prodProcUid,
                    equpUid: equpUid,
                }
            }

            const r =  await cmn.api("getProcInspHisMaxIdx", ps);
            if(r === undefined || r == null) return;

            const idx = r.d0.length === 0 ? 0 : r.d0[0].inspIdx;

            if(mode === 'v') {
                setInspIdx(idx + 1);
                onValueChanged(idx + 1);
                return;
            }

            let inspIdxs_ = [];
            _.times(cmn.INSP_IDX_LIMIT - idx, (i) => {
                inspIdxs_.push({inspIdx: idx + i + 1});
            });

            setInspIdxs(inspIdxs_);
        }
        catch(err) {
            // nothing ...
        }        
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    
    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        return (
            <>
            {
            (mode === 'a' || mode === 'u')
            ?
            <SelectBox 
                width={width}
                placeholder={placeholder}
                value={value}
                dataSource={inspIdxs}
                displayExpr={'inspIdx'}
                valueExpr={'inspIdx'}

                onValueChanged={(e)=>{
                    onValueChanged(e.value);
                }} 
            />
            :
            <span>{inspIdx}</span>
            }
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default InspIdxSelectBox;

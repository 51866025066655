import React, { useState, useEffect, useContext } from 'react';
import { NumberBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout,
    ActionButton,
    CheckListBox,
    SeqSelectBox,
    CodeSelectBox,
    UseYnSwitchBox,
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공정 검사 등록 / 수정
 */
const ProdProcInspPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore); 

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        pdpcuid = ls.pdpcuid,
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    /** Items */
    const [inspTpItems, setInspTpItems] = useState([]);
    const [prodProcUid, setProdProcUid] = useState('');
    const [inspNm, setInspNm] = useState('');
    const [sampCnt, setSampCnt] = useState(1);
    const [inspTpsNm, setInspTpsNm] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [dispStnd, setDispStnd] = useState('');
    const [stndVal, setStndVal] = useState(0);
    const [upperVal, setUpperVal] = useState(0);
    const [lowerVal, setLowerVal] = useState(0);
    const [valSelectTp, setValSelectTp] = useState('');
    const [specialYn, setSpecialYn] = useState('N');
    const [inspChkYn, setInspChkYn] = useState('N');
    const [equpConnYn, setEqupConnYn] = useState('N');
    const [inspToolTp, setInspToolTp] = useState('');
    const [inspToolTpNm, setInspToolTpNm] = useState('');

    const [stndValDis, setStndValDis] = useState(false);
    const [upperValDis, setUpperValDis] = useState(false);
    const [lowerValDis, setLowerValDis] = useState(false);

    const [oldOrder, setOldOrder] = useState(''); 
    const [maxOrder, setMaxOrder] = useState(''); 

    useEffect(() => {
        loadPage();
    },[]);

    
    useEffect(() => {
        loadInfo1();
    },[pageLoad]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async () => {
        try {
            await authStore.loadPopPage(props);

            if(mode === "a" || mode === "u"){
                const ps = {
                    p: {
                        tableNm : "tb_prod_proc_insp",
                        keyField: "prod_proc_uid",
                        keyValue: pdpcuid
                    }
                };
    
                let r = await cmn.api('getSortOrderCnt', ps);
                setMaxOrder(r.d0[0].cnt);
    
                let r2 = await cmn.api('getCodeCombo', {p:{classId:'107'}});
                setInspTpItems(r2.d0);
            }

            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {            
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            let r = await cmn.api('getProdProcInsp', {p: {prodProcInspUid: uid}} )
            if(r == null || r === undefined) return;

            const data = r.d0[0];

            chgValSelectTp(data.valSelectTp);
            setInspNm(data.inspNm);
            setSampCnt(data.sampCnt);
            setProdProcUid(data.prodProcUid);
            setOldOrder(data.sortOrder);
            setSortOrder(data.sortOrder);
            setDispStnd(data.dispStnd);
            setStndVal(data.stndVal);
            setUpperVal(data.upperVal);
            setLowerVal(data.lowerVal);
            setValSelectTp(data.valSelectTp);
            setSpecialYn(data.specialYn);
            setInspChkYn(data.inspChkYn)
            setEqupConnYn(data.equpConnYn);
            setInspToolTp(data.inspToolTp);
            setInspToolTpNm(data.inspToolTpNm);

            let inspTpItems_ = [...inspTpItems];
            console.log(data.inspTps);
            _.forEach(inspTpItems_, (v, k) => {
                if(!_.find(data.inspTps, { inspTp: v.codeId})) return;
                inspTpItems_[k].checked = true;
            });

            // console.log("--- inspTpItems_", inspTpItems_);

            setInspTpItems(inspTpItems_);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const chgValSelectTp = (e) => {
        if(util.getChkNull(e) === '') return;

        if(e === cmn.CMNCO_VALSELTP.SEL)
        {
            setDispStnd('양호/불량');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);

            setStndValDis(true);
            setUpperValDis(true);
            setLowerValDis(true);
        }else if(e === cmn.CMNCO_VALSELTP.TOPBOT)
        {
            setDispStnd('');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);

            setStndValDis(false);
            setUpperValDis(false);
            setLowerValDis(false);
        }else if(e === cmn.CMNCO_VALSELTP.TOP)
        {
            setDispStnd('');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);

            setStndValDis(false);
            setUpperValDis(true);
            setLowerValDis(true);   
        }else if(e === cmn.CMNCO_VALSELTP.BOT)
        {
            setDispStnd('');
            setStndVal(0);
            setUpperVal(0);
            setLowerVal(0);

            setStndValDis(false);
            setUpperValDis(true);
            setLowerValDis(true);
        }else{
            return;
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(inspNm, '항목명'))
                return;
            if(!util.chkMustValue(dispStnd, '출력(규격)'))
                return;
            if(!util.chkMustValue(inspTpItems, '검사유형', 'chk'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addProdProcInsp();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upProdProcInsp();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addProdProcInsp = async() => {

        const ps = {
            p: {
                prodProcInspUid: util.getUUID(),
                prodProcUid: pdpcuid,
                inspNm: inspNm,
                sampCnt: sampCnt,
                sortOrder: sortOrder,
                dispStnd: dispStnd,
                stndVal: stndVal,
                upperVal: util.fix(upperVal, 3),
                lowerVal: util.fix(lowerVal, 3),
                valSelectTp: valSelectTp,
                specialYn: specialYn,
                inspChkYn: inspChkYn,
                equpConnYn: equpConnYn,
                uptUserUid: authStore.user.uid,
                inspToolTp: inspToolTp,
                inspTps: cmn.getCheckedCodes(inspTpItems),
                sort:{
                    oldOrder: "0",
                    newOrder: sortOrder
                }
            }
        }

        const r = await cmn.api('addProdProcInsp', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("subSrchButton");
        window.close();
    }

    const upProdProcInsp = async() => {

        const ps = {
            p: {
                prodProcInspUid: uid,
                prodProcUid: prodProcUid,
                inspNm: inspNm,
                sampCnt: sampCnt,
                sortOrder: sortOrder,
                dispStnd: dispStnd,
                stndVal: stndVal,
                upperVal: upperVal,
                lowerVal: lowerVal,
                valSelectTp: valSelectTp,
                specialYn: specialYn,
                inspChkYn: inspChkYn,
                equpConnYn: equpConnYn,
                uptUserUid: authStore.user.uid,
                inspToolTp: inspToolTp,
                inspTps: cmn.getCheckedCodes(inspTpItems),
                sort:{
                    oldOrder: oldOrder,
                    newOrder: sortOrder
                }
            }
        }

        const r = await cmn.api('upProdProcInsp', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");
        util.clickOpenerElement("subSrchButton");
        window.close();
    }


    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">항목명</th>
                            <td className="tcon" > 
                                {
                                mode === "a" || "u" ?
                                <TextBox 
                                    value={inspNm}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setInspNm(e.value);
                                    }}
                                /> 
                                :
                                    <span>{inspNm}</span>
                                }
                            </td>
                            <th className="tit">시료수</th>
                            <td className="tcon"> 
                                {
                                mode === "a" || "u" ?
                                <NumberBox
                                    width={80}
                                    format={'#,##0'}
                                    value={sampCnt}
                                    defaultValue={1}
                                    min={1}
                                    max={5}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setSampCnt(e.value);
                                    }} 
                                />
                                :
                                    <span>{sampCnt}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">항목구분</th>
                            <td className="tcon" > 
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={valSelectTp}
                                        classId={'304'}
                                        showClearButton={false}
                                        placeholder={'항목을 선택해주세요'}
                                        onValueChanged={(v) => { 
                                            setValSelectTp(v);
                                            chgValSelectTp(v);
                                        }}
                                    />
                                : 
                                    <span>{valSelectTp}</span>
                                }
                            </td>
                            <th className="tit">순서</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <SeqSelectBox 
                                        mode={mode}
                                        value={sortOrder}
                                        cnt={maxOrder}                         
                                        onValueChanged={(v)=>{
                                            setSortOrder(v);
                                        }} 
                                    />
                                :
                                    <span>{sortOrder}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">출력(규격)</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={dispStnd}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setDispStnd(e.value);
                                    }}
                                /> 
                                :
                                    <span>{dispStnd}</span>
                                }
                            </td>
                            <th className="tit req">규격</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'80px'}
                                        format={'#,##0.####'}
                                        value={stndVal}
                                        defaultValue={0}
                                        disabled={stndValDis}
                                        //min={0}
                                        showSpinButtons={true}                                    
                                        onValueChanged={(e)=>{
                                            setStndVal(e.value);
                                            if(valSelectTp === cmn.CMNCO_VALSELTP.TOP)
                                                setLowerVal(e.value);
                                            else if (valSelectTp === cmn.CMNCO_VALSELTP.BOT)
                                                setUpperVal(e.value);
                                        }} 
                                    />
                                :
                                    <span>{stndVal}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">상한값</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'80px'}
                                        format={'#,##0.####'}
                                        value={upperVal}
                                        defaultValue={0}
                                        disabled={upperValDis}
                                        showSpinButtons={true}                                    
                                        onValueChanged={(e)=>{
                                            setUpperVal(e.value);
                                        }} 
                                    />
                                :
                                    <span>{upperVal}</span>
                            }
                            </td>
                            <th className="tit">하한값</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        width={'80px'}
                                        format={'#,##0.####'}
                                        value={lowerVal}
                                        defaultValue={0}
                                        disabled={lowerValDis}
                                        showSpinButtons={true}                                    
                                        onValueChanged={(e)=>{
                                            setLowerVal(e.value);
                                        }} 
                                    />
                                :
                                <span>{lowerVal}</span>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">검사유형</th>
                            <td className="tcon" colSpan={3}> 
                                {
                                mode === "a" || mode === "u" ?
                                    <CheckListBox
                                        items={inspTpItems}
                                        textExpr={'codeNm'}
                                        onValueChanged={(e) => {
                                            setInspTpItems(e.items);                        
                                        }}                                          
                                    />
                                :
                                    <span>{inspTpsNm}</span>
                                }
                            </td>
                            
                        </tr>
                        <tr>
                            <th className='tit'>수입검사 필수</th>
                            <td className='tcon' colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={inspChkYn}
                                        onValueChanged={(v)=>{
                                            setInspChkYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{inspChkYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">특별관리</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={specialYn}
                                        onValueChanged={(v)=>{
                                            setSpecialYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{specialYn}</span>
                                }
                            </td>
                            <th className="tit">검사장비연동</th>
                            <td className="tcon" >
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={equpConnYn}
                                        onValueChanged={(v)=>{
                                            setEqupConnYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{equpConnYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">검사도구</th>
                            <td className="tcon" colSpan={3} >
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={inspToolTp}
                                        classId={'121'}
                                        showClearButton={false}
                                        placeholder={'검사도구를 선택해주세요'}
                                        onValueChanged={(v) => { 
                                            setInspToolTp(v);
                                        }}
                                    />
                                : 
                                    <span>{inspToolTpNm}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "공정검사 등록" 
                : mode === "u" ? "공정검사 수정"
                : "공정검사" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProdProcInspPopPage;
import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PrintLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산설비 이력카드
 */
const EqupHisRepoPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        uid = ls.uid,
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [equpTp, setEqupTp] = useState('');
    const [equpTpNm, setEqupTpNm] = useState('');

    const [equpCd, setEqupCd] = useState('');
    const [equpNm, setEqupNm] = useState('');
    const [modelNm, setModelNm] = useState('');
    const [equpStnd, setEqupStnd] = useState('');
    const [maker, setMaker] = useState('');
    const [makeYr, setMakeYr] = useState('');
    const [buyDy, setBuyDy] = useState('');

    const [uph, setUph] = useState(0);
    const [equpImgFileInfo, setEqupImgFileInfo] = useState('');

    const [bkupParts, setBkupParts] = useState([]);
    const [equpFixs, setEqupFixs] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    // useEffect(() => {
    //     loadInfo1();
    // },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {          
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getEqupHisRepo", {p: {equpUid: uid}} );
            if(r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
            const d2 = r.d2;
            
            setEqupTp(d0.equpTp);
            setEqupTpNm(d0.equpTpNm);

            setEqupCd(d0.equpCd);
            setEqupNm(d0.equpNm);
            setModelNm(d0.modelNm);
            setEqupStnd(d0.equpStnd);
            setMaker(d0.maker);
            setMakeYr(d0.makeYr);
            setBuyDy(d0.buyDy);
            setUph(d0.uph);
            setEqupImgFileInfo(d0.equpImgFileInfo);

            setBkupParts(d1);
            setEqupFixs(d2);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {        
            printPage();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const printPage = async() => {

        window.print();
    }

    const customizeTooltip = (arg) => {
        return { text: arg.valueText };
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        
        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div className="print_type"
                style={{ padding: "20px 20px 0 20px", backgroundColor: "#ffffff" }}
            >

                <table>
                    <colgroup>
						<col width={100} />
                        <col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon">
                                <div
                                    style={{ fontSize: "26px", fontWeight: "bold" }}
                                >
                                {`설비 이력 카드 (A)`}
                                </div>
                                <div>
                                {`출력일자: (${util.getDateToString('d',util.getNow(), '-')})`}
                                </div>
                            </td>                           
                        </tr>                        
                    </tbody>
                </table>

                <table>
                    <colgroup>
						<col width={100} />                        
                        <col  />
						<col width={100} />
                        <col width={100} />
                        <col width={338} />
                    </colgroup>
                    <tbody>                        
                        <tr>   
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                설비유형
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{equpTpNm}</span>
                            </td>
                            <td className="tcon"
                                rowSpan={8}
                                style={{paddingTop: 4, paddingBottom: 4}}
                            >
                                <div className="div-img-100" style={{minHeight: 240}}>
                                { 
                                    equpImgFileInfo != '' ?                                                
                                    cmn.getRenderImg(equpImgFileInfo, 320) 
                                    :
                                    <p>
                                        이미지 없음.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr>                        
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                설비코드
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{equpCd}</span>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                설비명
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{equpNm}</span>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                모델명
                            </th>
                            <td className="tcon"
                               style={{paddingLeft: "10px", textAlign: "left"}}
                               colSpan={3}
                            >
                                <span>{modelNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                규격
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{equpStnd}</span>
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                제작처
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{maker}</span>
                            </td>
                        </tr> 
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                제작년도
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                            >
                                <span>{makeYr}</span>
                            </td>
                            <th className="tit"                                
                                style={{height: "30px"}}
                            >
                                구입일자
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                            >
                                <span>{util.getDateStrSplit('d',buyDy)}</span>
                            </td>
                            
                        </tr>
                        <tr>
                            <th className="tit"
                                style={{height: "30px"}}
                            >
                                생산능력
                            </th>
                            <td className="tcon"
                                style={{paddingLeft: "10px", textAlign: "left"}}
                                colSpan={3}
                            >
                                <span>{`${uph} (EA/HOUR)`}</span>
                            </td>
                        </tr> 
                    </tbody>
                </table>                

                <div className="subTitle">
                    주요 예비부품 사양
                </div>
                <table>
                    <colgroup>
                        <col width={50} />
						<col width={80} />
                        <col width={180} />
						<col width={120} />
                        <col width={80} />
                        <col width={80} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: 46}} >No.</th>
                            <th className="tit">부품사진</th>
                            <th className="tit">품명</th>
                            <th className="tit">부품규격</th>
                            <th className="tit">안전재고</th>
                            <th className="tit">제작소요일</th>
                            <th className="tit">비고</th>
                        </tr>
                        {
                            _.map(bkupParts, (v, k) => {

                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 80}}
                                        >
                                            {k+1}</td>
                                        <td className="tcon">
                                            { cmn.getRenderImg(v.fileInfo, 72) }
                                        </td>
                                        <td className="tcon">{v.partNm}</td>
                                        <td className="tcon">{v.partStnd}</td>                                        
                                        <td className="tcon">{util.getNumComma(v.safeStock)}</td>
                                        <td className="tcon">{v.makeTakeDyCnt}</td>
                                        <td className="tcon">{v.cmnt}</td>
                                    </tr>    
                                )

                            })
                        }     
                        {
                            _.times(7 - bkupParts.length, (n) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 80}}
                                        >
                                            </td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                    </tr>    
                                )
                            })
                        }   
                    </tbody>
                </table> 
                <div className="print-bottom">
                    <table>
                        <colgroup>
                            <col width={160}/>
                            <col />
                            <col width={160}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>QF-000-000</td>
                                <td className="al-c">{authStore.com.name}</td>
                                <td className="al-r">A4 (210x297mm)</td>     
                            </tr>
                        </tbody>
                    </table> 
                </div> 

                <div className="endline"></div>
                <br style={{height:0, lineHeight: 0}} />

                <table>
                    <colgroup>
						<col width={100} />
                        <col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon">
                                <div
                                    style={{ fontSize: "26px", fontWeight: "bold" }}
                                >
                                {`설비 이력 카드 (B)`}
                                </div>
                                <div>
                                {`출력일자: (${util.getDateToString('d',util.getNow(), '-')})`}
                                </div>
                            </td>                           
                        </tr>                        
                    </tbody>
                </table>

                <div className="subTitle">
                    설비 비가동 이력
                </div>  
                <table>
                    <colgroup>
                        <col width={50} />
						<col width={100} />
                        <col />
						<col width={100} />
                        <col width={120} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: 46}} >No.</th>
                            <th className="tit">점검일자</th>
                            <th className="tit">비가동 내역</th>
                            <th className="tit">소요시간</th>
                            <th className="tit">점검자</th>
                        </tr>
                        {
                            _.times(17, (n) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 50}}
                                        >
                                            </td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        <td className="tcon"></td>
                                    </tr>    
                                )
                            })
                        } 
                    </tbody>
                </table> 

                <div className="print-bottom">
                    <table>
                        <colgroup>
                            <col width={160}/>
                            <col />
                            <col width={160}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>QF-000-000</td>
                                <td className="al-c">{authStore.com.name}</td>
                                <td className="al-r">A4 (210x297mm)</td>     
                            </tr>
                        </tbody>
                    </table> 
                </div> 

                <div className="endline"></div>
                <br style={{height:0, lineHeight: 0}} />

                <table>
                    <colgroup>
						<col width={100} />
                        <col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon">
                                <div
                                    style={{ fontSize: "26px", fontWeight: "bold" }}
                                >
                                {`설비 이력 카드 (C)`}
                                </div>
                                <div>
                                {`출력일자: (${util.getDateToString('d',util.getNow(), '-')})`}
                                </div>
                            </td>                           
                        </tr>                        
                    </tbody>
                </table>

                <div className="subTitle">
                    설비 수리 이력
                </div>  
                <table>
                    <colgroup>
                        <col width={50} />
						<col width={100} />
                        <col width={220} />
						<col width={120} />
                        <col width={80} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: 46}} >No.</th>
                            <th className="tit">수리일자</th>
                            <th className="tit">수리내용/소모부품</th>
                            <th className="tit">수리처</th>
                            <th className="tit">비용</th>
                            <th className="tit">상세내역</th>
                        </tr>
                        {
                            _.map(equpFixs, (v, k) => {

                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 50}}
                                        >
                                            {k+1}</td>
                                        <td className="tcon">{util.getDateStrSplit('d',v.fixStartDy)}<br />{util.getDateStrSplit('d',v.fixEndDy)}</td>                                            
                                        <td className="tcon">{v.fixTitle}</td>                                        
                                        <td className="tcon">{v.fixBizNm}</td>                                        
                                        <td className="tcon">{util.getNumComma(v.fixCost)}</td>
                                        <td className="tcon">{v.fixCnts}</td>
                                    </tr>    
                                )

                            })
                        } 
                        {
                            _.times(17 - equpFixs.length, (n) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 50}}
                                        >
                                            </td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                    </tr>    
                                )
                            })
                        }     
                    </tbody>
                </table> 

                <div className="print-bottom">
                    <table>
                        <colgroup>
                            <col width={160}/>
                            <col />
                            <col width={160}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>QF-000-000</td>
                                <td className="al-c">{authStore.com.name}</td>
                                <td className="al-r">A4 (210x297mm)</td>     
                            </tr>
                        </tbody>
                    </table> 
                </div> 

            </div>
            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"생산설비 이력카드"}
            content = {renderContent()}
        />
        </>
    )
}

export default EqupHisRepoPage;

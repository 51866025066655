import React, { useState, useEffect, useContext, useRef } from 'react';
import { SelectBox } from 'devextreme-react';

import _ from 'lodash';

/** components */
import { IconButton } from '..';

/** stores & lib */
import { cmn, util } from '../../lib';

/** 
 *  사용자 다중 추가
 */
const UserMultiSelectBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    /* ====================
     *  Object
     * ==================== */

    const {
        mode,
        users=[],

        onClickAdd,
        onClickDel
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */

    const [pageLoad, setPageLoad] = useState(true);
    const [deptItems, setDeptItems] = useState([]);
    const [userItems, setUserItems] = useState([]);

    
    const [deptUid, setDeptUid] = useState('');
    const [userUid, setUserUid] = useState('');
    const [userNm, setUserNm] = useState('');
    const [deptNm, setDeptNm] = useState('');
    
    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            const ps = {p: {}} 
            const r = await cmn.api("getDeptCombo", ps);
            setDeptItems(r.d0);
        }
        catch(err) {
            // nothing ...
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const loadUserCombo = async(e) => {
        const ps =  {
            p: {
                deptUid: e
            }
        }

        const r = await cmn.api("getUserCombo", ps);
        setUserItems(r.d0);
    }
    
    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {

        return (
            <div className="bad-comp">
                <div className="tot">
                    {/* <span className="amt">
                        { badAmtTot }
                    </span> */}
                    {/* <span className="unit">
                        {cmn.getDispUnitTpNm(unitTpNm)}
                    </span> */}
                </div>
                <div className="dtl">
                    <ul>
                        <li>
                            <ul className="row">
                                <li className="c1">
                                    <SelectBox 
                                        width={'100%'}
                                        placeholder={'부서를 선택하세요.'}
                                        value={deptUid}
                                        dataSource={deptItems}
                                        displayExpr={'deptNm'}
                                        valueExpr={'deptUid'}
                                        showClearButton={false}

                                        onValueChanged={(e)=>{
                                            setDeptUid(e.value);
                                            const o = _.find(deptItems, {deptUid: e.value});
                                            setDeptNm(o.deptNm);
                                            loadUserCombo(e.value);
                                        }} 
                                    />
                                </li>
                                <li className="c1">
                                    <SelectBox 
                                        width={'100%'}
                                        placeholder={'사용자를 선택하세요.'}
                                        value={userUid}
                                        dataSource={userItems}
                                        displayExpr={'userNm'}
                                        valueExpr={'userUid'}
                                        showClearButton={false}
                                        onValueChanged={(e)=>{
                                            setUserUid(e.value);
                                            const o = _.find(userItems, {userUid: e.value});
                                            setUserNm(o.userNm);
                                        }} 
                                    />
                                </li>
                                <li className="c3">
                                    <IconButton
                                        className="btn-icon-plus-1"
                                        marginRight={0}

                                        onClick={(e) => {
                                            if(util.getChkNull(userUid) == ''){
                                                alert("사용자를 선택하세요.");
                                                return;
                                            }

                                            const users_ = [...users];
                                            console.log('users---->', users_);

                                            let isReturn = false;
                                            _.forEach(users_, (v, k) => {
                                                if(_.isMatch(v, { 'userUid': userUid }))
                                                {
                                                    alert("이미 선택된 사용자입니다.");
                                                    isReturn = true;
                                                }
                                            });

                                            if(isReturn)
                                                return;

                                            users_.push({uid: util.getUUID(), userUid: userUid, userNm: userNm, deptNm:deptNm});
                                            onClickAdd(users_);  
                                            
                                            //data reset
                                            setUserUid('');
                                        }}
                                    />
                                </li>
                            </ul>
                            {
                                _.map(users, (v, k) => {
                                    return(
                                        <ul className="row" key={k}>
                                            <li className="c1">
                                                {v.deptNm}
                                            </li>
                                            <li className="c2">
                                                {v.userNm}
                                            </li>
                                            <li className="c3">
                                                <IconButton
                                                    className="btn-icon-close-1"
                                                    onClick={(e) => {
                                                        const users_ = _.remove(users, (vv) => {
                                                            return v.uid != vv.uid;
                                                        });
                                                        onClickDel(users_);
                                                    }}
                                                    marginRight={0}
                                                />
                                            </li>
                                        </ul>                                      
                                    )
                                })  
                            }                            

                        </li>                        
                    </ul>                    
                </div>
            </div>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default UserMultiSelectBox;

import React from "react";

/** images */
//import ico_print from '../../images/ico/ico_print.png';

const IconButton = (props) => {

    const { 
        alt="",
        title="",
        onClick, 
        className = null,
        marginTop = 0,
        marginLeft = 0,
        marginRight = 5,
        show=true
    } = props;

    return(
        <>
        <a 
            href="#"
            className={className}
            style={{ 
                marginTop: marginTop,
                marginLeft: marginLeft, 
                marginRight: marginRight,
                display: show ? '' : 'none' 
            }}    
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
            alt={alt}
            title={title}
        >   
        </a>
        </>
    );

}

export default IconButton;
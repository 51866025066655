import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { Column as TreeColumn } from 'devextreme-react/tree-list';
import SplitterLayout from 'react-splitter-layout';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, TreeListView, CodeSelectBox,
    SrchButton, SubActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  제품검사관리
 */
const ProdProcInspPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

        // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const rfProdProcTreeList = useRef(null);
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodTp, setProdTp] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "제품유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={prodTp}
                    classId={'101'}
                    placeholder="제품유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setProdTp(v);
                    }}
                />
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",
                item: 
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />        
            }
        ]
    ];
    
    /* #endregion */    

    
    // * Grid
    const [prodProcListTree, setProdProcListTree] = useState([]);
    const [prodProcListTreeTot, setProdProcListTreeTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);

    const [prodProcInspList, setProdProcInspList] = useState([]);
    const [selectedItem2, setSelectedItem2] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    // * 목록 선택 코드 UID

    
    // * Grid, Paging & Sorting
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, prodTp]);

    useEffect(() => {
        !pageLoad && handleClickSearch2();
    }, [selectedItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodTp: prodTp,
                prodId: prodId,
                prodNm: prodNm,

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getProdProcListTree", ps );
        if(r === undefined || r == null) return;

        setProdProcListTree(r.d0)
        setProdProcListTreeTot(r.tot);
    }

    const loadList2 = async() => {
        if(selectedItem == null) {
            setProdProcInspList([]); 
            return; 
        }

        const ps =  {
            p: {
                prodProcUid: selectedItem.taskId,

                sortCol: "",
                sortTp: "",
                offset: 0,
                limit: -1,
            }
        }

        const r = await cmn.api("getProdProcInspList", ps );
        if(r === undefined || r == null) return;

        setProdProcInspList(r.d0)
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickSearch2 = async(e) => {
        try {
            await loadList2();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(util.chkListSelected(selectedItem, "공정을")){
            openCrudPopup("a", selectedItem.taskId, "");
        }
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem2)){
            openCrudPopup("u", selectedItem.taskId, selectedItem2.prodProcInspUid);
        }
    }   
    
    const handleClickDel = async () => {
        try {
            if(util.chkListSelected(selectedItem2)){
                if(!util.confirmYn('삭제')) return;

                const ps =  {
                    p: 
                        {
                            prodProcInspUid: selectedItem2.prodProcInspUid,
                            prodProcUid: selectedItem2.prodProcUid,
                            sort:{
                                oldOrder: selectedItem2.sortOrder,
                                newOrder: 0
                            }
                        }
                }

                let r = await cmn.api('delProdProcInsp', ps);
                if(r == null || r === undefined) return;

                util.cmnSucAlert('삭제');

                loadList2();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const handleTreeListSelectionChanged = ({selectedRowsData}) => {       
        if(selectedRowsData.length < 1) return;
        if(selectedRowsData[0].parentTaskId === "0"){
            const nodeKey = selectedRowsData[0].taskId;
            const refObj = rfProdProcTreeList.current.instance;
            if(refObj.isRowExpanded(nodeKey)) refObj.collapseRow(nodeKey);
            else refObj.expandRow(nodeKey);            
            refObj.clearSelection();
            setSelectedItem(null);
            return;
        }

        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridSelectionChanged =  ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem2(null); return;
        }
        setSelectedItem2(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    const openCrudPopup = (mode, pdpcuid, pdpciuid) => {
        //alert(pdpciuid);
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/prodprocinsppop?${qs.stringify({mode: mode, pdpcuid: pdpcuid, uid: pdpciuid})}`;
        util.openWindow(url, 700, 500);
    }

    /* ====================
     *  Render
     * ==================== */

    // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderLeftContent = () => {

        return (
            <>
            <GridTopView
                title="제품별 공정 내역"
            >
            </GridTopView>            
            <TreeListView
                reff={rfProdProcTreeList}
                height={authStore.windowHeight - cmn.BH.H10}
                dataSource={prodProcListTree}

                // paging
                page={curPage}
                total={prodProcListTreeTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                pagesPerBlock={5}
                // sort
                onCellClick={handleGridCellClick}

                keyExpr={'taskId'}
                parentIdExpr={'parentTaskId'}
                onSelectionChanged={handleTreeListSelectionChanged}
            >
                <TreeColumn 
                    dataField={'col1'} 
                    caption={'품명 / 공정'} 
                    sortable={true}
                    headerCellRender={renderGridHeaderCell}  
                />
               <TreeColumn 
                    dataField={'col2'} 
                    caption={'품번 / 공정SPEC'} 
                    alignment={'center'}
                    width={150} 
                    sortable={true}
                    headerCellRender={renderGridHeaderCell}
                    cellTemplate={(el, d) => {
                        if(d.data.parentTaskId !== '0' && d.value != null && d.value !== '') {
                            el.innerHTML=cmn.getHtmlDownloadFile(d.value);
                        }
                        else {
                            el.innerText=d.value;
                        }
                   }}
                />
                <TreeColumn 
                    dataField={'col6'} 
                    caption={'규격 / 검사항목수'} 
                    alignment={'center'} 
                    width={150} 
                    sortable={true}
                    headerCellRender={renderGridHeaderCell}
                />
                <TreeColumn 
                    dataField={'col7'} 
                    caption={'재질'} 
                    alignment={'center'} 
                    width={140} 
                    sortable={true}
                    headerCellRender={renderGridHeaderCell}
                />
            </TreeListView>           
            </>
        )   
    }

    const renderRightContent = () => {

        return (
            <>       
            <GridTopView
                title="제품별 공정 검사 내역"
            >
                <SubActionButton 
                    onClick={handleClickAdd}
                    title="추가"
                    show={authStore.page.crtYn}
                /> 
                <SubActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />  
                <SubActionButton 
                    onClick={handleClickDel}
                    title="삭제"
                    marginRight={0}
                    show={authStore.page.delYn}
                />
            </GridTopView>                 
             
            <DataGridView            
                dataSource={prodProcInspList}   // store
                page={-1}
                gridHeight={authStore.windowHeight - cmn.BH.H20}
                onSelectionChanged={handleGridSelectionChanged}
                showNo={false}
                colsWidth={[40, 100, 100, 100, 60, 60, 60, 60, 100, 100, 60, null]}
            >
            { cmn.getGridColumn('sortOrder', '순번')}
            { cmn.getGridColumn('inspNm', '항목명')}
            { cmn.getGridColumn('valSelectTpNm', '항목구분')}
            { cmn.getGridColumn('dispStnd', '출력규격')}
            { cmn.getGridColumn('stndVal', '규격')}
            { cmn.getGridColumn('upperVal', '상한(최대)')}
            { cmn.getGridColumn('lowerVal', '하한(최소)')}
            { cmn.getGridColumn('sampCnt', '시료수')}
            { cmn.getGridColumn('inspTpNm', '검사유형') }
            { cmn.getGridColumn('inspToolTpNm', '검사도구') }
            { cmn.getGridColumnUseYn('specialYn', '특별검사') }
            <Column/>
            </DataGridView>
            </>
        )   
    }
 
    const renderContent = () => {

        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    marginRight={0}
                    onClick={handleClickSearch}
                />
                <SrchButton 
                    id="subSrchButton"
                    marginRight={0}
                    onClick={handleClickSearch2}
                    show={false}
                />                
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <div className="cont-split"
                style={{
                    marginTop: 16,
                }}
            >                
                <SplitterLayout
                    customClassName={`split-layout-cust ${authStore.sideOpened === true ? "is-visible" : ""}`}
                >
                    <div className="cont-split-left"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderLeftContent() }
                    </div>
                    <div className="cont-split-right"
                        style={{
                            height: authStore.windowHeight - cmn.BH.H50
                        }}
                    >
                        { renderRightContent() }
                    </div>
                </SplitterLayout>
            </div>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ProdProcInspPage;
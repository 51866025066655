import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, DayDateBox, SrchButton, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  작업지시 생성(수주조립품)
 */
const WorkSalesAssyPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [planMon, setPlanMon] = useState(Date.now);
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "계획월",
                width: "200px",
                item: 
                    <DayDateBox 
                        value={planMon}
                        type={'month'}
                        displayFormat={cmn.DATE_FORMAT_MON_1}
                        onValueChanged={(e) => {
                            setPlanMon(e.value);
                        }}                    
                    />
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />   
            },
            {   title: "품번",
                item: 
                <TextBox 
                    value={prodId}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />        
            }
            
        ]
    ];
    /* #endregion */       

    // * Grid
    const [workListSales, setWorkListSales] = useState([]);
    const [workListSalesTot, setWorkListSalesTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, planMon]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodTp: cmn.CMNCO_PRODTP.ASSY, //단품 검색 코드
                planMon: util.getDateToString('M', planMon),  
                prodId: prodId, 
                prodNm: prodNm, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getWorkListSales", ps );
        if(r === undefined || r == null) return;

        setWorkListSales(r.d0)
        setWorkListSalesTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */
 
    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("a", selectedItem.prodUid, selectedItem.takeAmt);
        }
    }

    // const handleClickUp = (e) => {
    //     if(prodUid == ""){
    //         // 선택해주세요.
    //         return;
    //     }
    //     openCrudPopup("u", prodUid);
    // }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }
 
    // * Functions

    const openCrudPopup = (mode, prodUid, takeAmt) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/manu/workpop?${qs.stringify({mode: mode, inputtp: "30205", puid: prodUid, amt: takeAmt})}`;        
        util.openWindow(url, 700, 600);      
    }
    
    /* ====================
     *  Render
     * ==================== */
 
    // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
    
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="작업지시등록"
                    width={'130px'}
                    marginRight={0}
                    show={authStore.page.crtYn}
                />   
            </ListTopView>  
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="수주조립품 내역"
            >
                {/* <IconButton
                    className="btn-icon-print"
                />
                <IconButton
                    className="btn-icon-xls"
                    marginRight={0}
                /> */}
            </GridTopView> 
            <DataGridView            
                dataSource={workListSales}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                page={curPage}
                total={workListSalesTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}

                colsWidth={[150, 150, 150, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, null]}
            >
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('takeAmt', '수주량') }
                { cmn.getGridColumnNumCommaType('stockAmt', '재고') }
                { cmn.getGridColumnNumCommaType('lackAmt', '부족량') }
                { cmn.getGridColumnNumCommaType('workAmt', '작업지시량') }
                { cmn.getGridColumnNumCommaType('noWorkAmt', '미작업지시량') }

                <Column />
            </DataGridView>
            </>
        );

    }


    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default WorkSalesAssyPage;
const deptApiz = {
    
    getDeptList: {
        title: "부서 목록 조회",
        url: "/api/dept/getDeptList",
        method: "POST"
    },
    getDept: {
        title: "부서 상세 조회",
        url: "/api/dept/getDept",
        method: "POST"
    },
    getDeptCombo: {
        title: "부서 콤보",
        url: "/api/dept/getDeptCombo",
        method: "POST"
    },
    addDept: {
        title: "부서 등록",
        url: "/api/dept/addDept",
        method: "POST"
    },
    upDept: {
        title: "부서 수정",
        url: "/api/dept/upDept",
        method: "POST"
    },
    delDept: {
        title: "부서 삭제",
        url: "/api/dept/delDept",
        method: "POST"
    },
}

export default deptApiz;
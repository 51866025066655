import React, { useState, useEffect, useContext } from 'react';
import { TextBox, NumberBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import _ from 'lodash';

/** components */
import {
    IconButton, DayDateBox, UserSelectBox, 
    ActionButton, SiteLayout, BizSelectBox,
    GridTopView, ListSearchView, EditGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';

import { util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const SiteOustsInPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [lot, setLot] = useState('');  

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "Lot",
                item: 
                    <TextBox 
                        value={lot}
                        width={'200px'}
                        placeholder="Lot로 검색하세요."
                        showClearButton={true}
                        onValueChanged={(e)=>{
                            setLot(e.value);
                        }}
                    /> 
            }
        ]
    ];    

    //
    const [inDy, setInDy] = useState(util.getNow());   
    const [userUid, setUserUid] = useState(authStore.user.uid);  
    const [prodProcUid, setProdProcUid] = useState('');  
    const [prodUid, setProdUid] = useState('');  
    const [outsInItems, setOutsInItems] = useState([]);  
    const [bizUid, setBizUid] = useState('');  
    const [outsInPrice, setOutsInPrice] = useState('0');  
    const [lastYn, setLastYn] = useState('N');
    

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadLotInfo();
    },[lot]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadLotInfo = async() => {
        if(util.getChkNull(lot) === '') return;

        let para = {
            search:{
                lot:lot,

                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        };

        let r = await cmn.api('getOutsInListStndBy', para);
        if(r == null || r === undefined) return;
        let data = r.d0[0];

        let outsInItems_ = [...outsInItems];

        // 입력 Validation Check
        // data = outsOutList_ 의 상태를 체크

        let msg = '';
        _.forEach(outsInItems_, (v, k) => {
            if(v.lot === data.lot) // lot 중복 체크
                msg = '이미 등록된 Lot 입니다.' 

            if(v.prodProcUid !== data.prodProcUid)
                msg = '같은 공정의 제품만 등록할 수 있습니다.' 

            if(v.prodUid !== data.prodUid)
                msg = '같은 제품만 등록할 수 있습니다.' 
        });

        if(msg !== '')
        {
            util.cmnAlert(msg);
            return;
        }

        outsInItems_.push({
            uid: util.getUUID()
            ,bizNm: data.bizNm
            ,lot: data.lot
            ,prodProcUid: data.prodProcUid
            ,procNm: data.procNm
            ,procSpec: data.procSpec
            ,prodUid: data.prodUid
            ,prodNm: data.prodNm
            ,prodId: data.prodId
            ,prodStnd: data.prodStnd
            ,stndByAmt: data.stndByAmt
            ,compAmt: data.compAmt
            ,inAmt: data.stndByAmt
            ,cmnt: ''
        });

        console.log('outsInItems_ ===>', outsInItems_);

        setProdProcUid(data.prodProcUid);
        setLastYn(data.lastYn);
        setProdUid(data.prodUid);
        setOutsInItems(outsInItems_);
    }

     /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = async(e) => {

        // validation Check
        if(!util.chkMustValue(bizUid, '입고업체'))
            return;
        if(!util.chkMustValue(userUid, '입고자'))
            return;
        if(!util.chkMustValue(_.sumBy(outsInItems, 'inAmt'), '입고수량', 'cnt'))
            return;
        
          // 추가 Validation Check
        let msg = '';
         _.forEach(outsInItems, (v,k) => {
            if(v.inAmt > v.possAmt) 
                msg = '[입고수량]이 [입고가능수량] 보다 큽니다.';
              
            if(v.inAmt < 0)
                msg = '[입고수량]은 양수로 입력해야 합니다.';
        });

        if(msg !== '')
        {
            util.cmnAlert(msg);
            return;
        }

        if(util.confirmYn('외주입고'))
            addOutsIn();
    }

    const addOutsIn = async() => {

        let outsInItems_ = [];

        let groupUid = util.getUUID()
        _.forEach(outsInItems, (v,k) => {
            if(v.inAmt > 0) {
                outsInItems_.push(
                    {
                        outsInUid: util.getUUID(),
                        comUid: authStore.com.uid,
                        bizUid: bizUid,
                        prodUid: prodUid,
                        prodProcUid: v.prodProcUid,
                        inDy: util.getDateToString('d',inDy),
                        outsInPrice: outsInPrice,
                        userUid: userUid,
                        lastYn: lastYn,
                        uptUserUid: authStore.user.uid,
                        lot: v.lot,
                        inAmt: v.inAmt,
                        groupUid: groupUid,
                        cmnt: util.getChkNull(v.cmnt)
                    }
                );
            }
        });

        const ps = {
            p: [...outsInItems_]
        }

        const r = await cmn.api('addOutsIn', ps); // inOutStore.addOutsIn(para);
        if(r == null) return;

        util.cmnSucAlert("등록");

        window.location.reload();
    }


    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                <GridTopView
                    title="입고 정보"
                >
                    {/* <SrchButton 
                        id="srchButton"
                        onClick={handleClickSearch}
                    /> */}
                    <ActionButton 
                        title="외주입고"
                        width={"140px"}
                        onClick={handleClickComplete}
                        show={true}
                        type={"site-1"}
                    />
                    <ActionButton 
                        title="공정이동표"
                        width={"160px"}
                      //  onClick={handleClickDel}
                        show={true}
                        type={"site-1"}
                    />
                </GridTopView>
                <ListSearchView searchItems={searchItems} type={"site"} />
                
                    <div className="site_list_type">
                        <table>
                            <colgroup>
                                <col className="tit" />
                                <col className="tcon" />
                                <col className="tit" />
                                <col className="tcon" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="tit">입고일자</th>
                                    <td className="tcon">
                                        <DayDateBox
                                            value={inDy}
                                            onValueChanged={(e) => {
                                                setInDy(e.value);
                                            }}                    
                                        />
                                    </td>
                                    <th className="tit">입고자</th>
                                    <td className="tcon">
                                        <UserSelectBox
                                            value={userUid}
                                            showClearButton={false}
                                            placeholder="입고자를 선택하세요"
                                            onValueChanged={(v) => { 
                                                setUserUid(v);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="tit">협력사</th>
                                    <td className="tcon" >
                                        <BizSelectBox
                                            value={bizUid}                                        
                                            bizTp={[cmn.CMNCO_BIZ.PART]}
                                            width={'200px'}
                                            onLoadInfo={(o) => {
                        
                                            }} 
                                            onValueChanged={(v) => {
                                                setBizUid(v);
                                            }}
                                        />   
                                    </td>
                                    <th className="tit">입고단가</th>
                                    <td className="tcon" >
                                        <NumberBox
                                            width={120}
                                            value={outsInPrice}
                                            format={'#,##0'}
                                            defaultValue={0}
                                            min={0}
                                            // max={20}
                                            showSpinButtons={true}
                                            onValueChanged={(e)=>{
                                                setOutsInPrice(e.value);
                                            }} 
                                        />
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                        <>
                        <EditGridView            
                            dataSource={outsInItems}   // store
                            colsWidth={[null, null, null, null, null, null, null, null, null, null, null]}
                        >
                            { cmn.getGridColumn('bizNm', '출고업체')}
                            { cmn.getGridColumn('lot', 'Lot')}
                            { cmn.getGridColumn('procNm', '공정')}
                            { cmn.getGridColumn('prodNm', '품명')}
                            { cmn.getGridColumn('prodId', '품번')}
                            { cmn.getGridColumn('prodStnd', '규격')}
                            { cmn.getGridColumnNumCommaType('stndByAmt', '입고대기수량')}
                            { cmn.getGridColumnNumCommaType('compAmt', '기입고수량')}
                            <Column 
                                alignment={'center'}
                                dataField={'inAmt'} 
                                caption={'입고수량'}
                            />
                            <Column 
                                alignment={'center'}
                                dataField={'cmnt'} 
                                caption={'비고'}
                            />
                            <Column 
                                alignment={'center'}
                                dataField={'uid'} 
                                caption={'취소'}
                                allowEditing={false} 
                                cellRender={(d) => {
                                    return (
                                        <IconButton
                                            className="btn-icon-close-1"
                                            onClick={(e) => {
                                                const outsInItems_ = _.remove(outsInItems, (v) => {
                                                    return d.value != v.uid;
                                                });
                                        
                                                setOutsInItems(outsInItems_);
                                            }}
                                            marginRight={0}
                                        />
                                    )
                                }}
                            />
                        </EditGridView>
                        </>

                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"외주입고"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteOustsInPage;
import React from "react";

/** components */
import { ActionButton } from "../components";

const MtLinkPage = () => {
  const uri = {
    monitoring: "http://192.168.0.134:3000/index.html#monitoring_all",
    results: "http://192.168.0.134:3000/index.html#operation_result",
    diagnosis: "http://192.168.0.134:3000/index.html#program_history",
    utility: "http://192.168.0.134:3000/index.html#system_diagnosis",
  };

  const renderContent = () => {
    return (
      <div className="mt-link-container">
        <div className="mt-link-content">
          <div className="box">
            <ActionButton
              onClick={() => {
                window.open(uri.monitoring, "_blank");
              }}
              width={"100%"}
              type={"site-2"}
              title="Monitoring"
              show={true}
            />
          </div>
          <div className="box">
            <ActionButton
              onClick={() => {
                window.open(uri.results, "_blank");
              }}
              width={"100%"}
              type={"site-2"}
              title="Results"
              show={true}
            />
          </div>
          <div className="box">
            <ActionButton
              onClick={() => {
                window.open(uri.diagnosis, "_blank");
              }}
              width={"100%"}
              type={"site-2"}
              title="Diagnosis"
              show={true}
            />
          </div>
          <div className="box">
            <ActionButton
              onClick={() => {
                window.open(uri.utility, "_blank");
              }}
              width={"100%"}
              type={"site-2"}
              title="Utility"
              show={true}
            />
          </div>
        </div>
      </div>
    );
  };
  return <>{renderContent()}</>;
};

export default MtLinkPage;

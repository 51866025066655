import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox, SelectBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    CodeSelectBox, 
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  설비 관리
 */
const EqupPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    //...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [lineUid, setLineUid] = useState('');
    const [equpTp, setEqupTp] = useState('');
    const [equpNm, setEqupNm] = useState('');
    const [equpCd, setEqupCd] = useState('');
    const [lineItem, setLineItem] = useState([]);

    /* #region Search Items */
    const searchItems = [
        [
            // {   
            //     title: "생산라인",
            //     width: "200px",
            //     item: 
            //     <SelectBox 
            //         placeholder={'생산라인을 선택하세요.'}
            //         value={lineUid}
            //         dataSource={lineItem}
            //         displayExpr={'lineNm'}
            //         valueExpr={'lineUid'}
            //         showClearButton={true}
            //         onValueChanged={(e)=>{
            //             setLineUid(e.value);
            //         }} 
            //     />  
            // },
            {   
                title: "설비유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={equpTp}
                    classId={'401'}
                    placeholder="설비유형을 선택하세요."
                    onValueChanged={(v, o) => { 
                        setEqupTp(v);
                    }}
                />  
            },
            {   title: "설비명",
                item: 
                <TextBox 
                    width="200px"
                    value={equpNm}
                    showClearButton={true}
                    placeholder="설비명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setEqupNm(e.value);
                    }}
                />                      
            }
        ]
    ];
    /* #endregion */    
 
    // * Grid
    const [equpList, setEqupList] = useState([]);
    const [equpListTot, setEqupListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, equpTp, lineUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);

            const r = await cmn.api("getLineCombo", {} );
            setLineItem(r.d0);

            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                lineUid: lineUid,
                equpTp: equpTp, 
                equpCd: equpCd, 
                equpNm: equpNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getEqupList", ps );
        if(r === undefined || r == null) return;

        setEqupList(r.d0)
        setEqupListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        if(!util.chkListSelected(selectedItem, '설비를')) return;
        openCrudPopup("u", selectedItem.equpUid);
    }

    const handleClickView = (e) => {
        if(!util.chkListSelected(selectedItem, '설비를')) return;
        openCrudPopup("v", selectedItem.equpUid);
    }
    
    const handleClickHisRepo = (e) => {
        if(!util.chkListSelected(selectedItem, '설비를')) return;
        openHisRepoPopup(selectedItem.equpUid);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "useYn" 
            ) e.value = gc.value === 'Y' ? "○" : "";
        else if(
                gc.column.dataField === "equpImgFile" 
            ||  gc.column.dataField === "chkImgFile" 
        )   e.value = '';
    }  

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, equpUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equppop?${qs.stringify({mode: mode, uid: equpUid})}`;        
        util.openWindow(url, 960, 840);    
    }

    const openHisRepoPopup = (equpUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/equp/equphisrepo?${qs.stringify({uid: equpUid})}`;        
        util.openWindow(url, 820, 840);
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
 
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"                    
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"                    
                    show={authStore.page.viewYn}
                />
                 <ActionButton 
                    onClick={handleClickHisRepo}
                    title="이력카드"
                    type="2"
                    show={authStore.page.viewYn}
                />  
            </ListTopView>   
            <ListSearchView searchItems={searchItems} />
            
            <GridTopView
                title="설비 내역"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <DataGridView   
                reff={grid1}              
                dataSource={equpList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[ 160, 160, cmn.CW.equpNm
                    , 100, 100, 80, 80, 80
                    , cmn.CW.yn, cmn.CW.file, null]}
                // paging
                page={curPage}
                total={equpListTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged} 
                // excel
                exlnm={'설비내역'}
                customizeExcelCell={customizeExcelCell}                
            >
                {/* { cmn.getGridColumn('lineNm', '라인명', renderGridHeaderCell ) } */}
                { cmn.getGridColumn('equpTpNm', '설비유형', renderGridHeaderCell ) }
                { cmn.getGridColumn('equpCd', '설비코드', renderGridHeaderCell ) }
                { cmn.getGridColumn('equpNm', '설비명', renderGridHeaderCell ) }

                { cmn.getGridColumn('modelNm', '모델명', renderGridHeaderCell ) }
                { cmn.getGridColumn('equpStnd', '규격', renderGridHeaderCell ) }
                { cmn.getGridColumn('makeYr', '제작년도', renderGridHeaderCell ) }
                { cmn.getGridColumnDayType('buyDy', '구입일자', renderGridHeaderCell ) }
                { cmn.getGridColumn('uph', '생산능력', renderGridHeaderCell ) }

                { cmn.getGridColumnUseYn('useYn', '사용여부', renderGridHeaderCell) }  
                { cmn.getGridColumnFiles('equpImgFile', '설비사진') }          
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default EqupPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import {  TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, DayDateBox,
    SrchButton, ActionButton, IconButton, BizSelectBox
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';


/** 
 *  수주 관리
 */
const SalesPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [planMon, setPlanMon] = useState(util.getNow());
    const [payDy, setPayDy] = useState('');
    const [salesNum, setSalesNum] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [bizUid, setBizUid] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "수주월",
                width: "200px",
                item: 
                <DayDateBox 
                    value={planMon}
                    type={'month'}
                    displayFormat={cmn.DATE_FORMAT_MON_1}
                    onValueChanged={(e) => {
                        setPlanMon(e.value);
                    }}                    
                />
            },
            {   title: "납기일",
                width: "200px",
                item: 
                <DayDateBox
                    value={payDy}
                    showClearButton={true}
                    onValueChanged={(e) => {
                        setPayDy(e.value);
                    }}                    
                />  
            },
            {   title: "제품명",
                item: 
                <TextBox 
                    value={prodNm}
                    width={"200px"}
                    showClearButton={true}
                    placeholder="제품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />    
            }
        ],
        [
            {   
                title: "고객사",
                width: "200px",
                item: 
                <BizSelectBox
                    value={bizUid}
                    width={'200px'}
                    bizTp={[cmn.CMNCO_BIZ.CUST]}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        setBizUid(v);
                    }}
                />     
            },
            {   
                title: "수주번호",
                width: "200px",
                colSpan: "3",
                item: 
                <TextBox 
                    value={salesNum}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="수주번호로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setSalesNum(e.value);
                    }}
                />     
            }
        ]
    ];
    /* #endregion */

    // * Grid
    const [salesList, setSalesList] = useState([]);
    const [salesListTot, setSalesListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, planMon, payDy]);   

    /* ====================
     *  Load
     * ==================== */

    
    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                planMon: util.getDateToString('M',planMon), 
                payDy: util.getChkNull(payDy) !== '' ? util.getDateToString('d',payDy) : '', 
                salesNum: salesNum,
                bizUid: bizUid,
                prodNm: prodNm,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

      //  console.log("===== para", para);
        const r = await cmn.api("getSalesList", ps );
        if(r === undefined || r == null) return;

        setSalesList(r.d0)
        setSalesListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "");
    }

    const handleClickView = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.salesNum);
        }
    }
    
    const handleClickDeli = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopupDeli("a", selectedItem.salesNum);
        }
    }

    const handleClickEnd = async () => {
        try {
            if(util.chkListSelected(selectedItem)){
                if(!util.confirmYn('마감'))
                    return;
                
                // 공정마감
                const ps = {
                    p: {
                        comUid: authStore.com.uid,
                        salesNum: selectedItem.salesNum,
                        compYn: 'Y',
                        uptUserUid: authStore.user.uid
                    }
                }
        
                const r = await cmn.api('upSalesCompYn', ps);
                if(r == null) return;
                
                loadList1();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "planDy" 
            ||  gc.column.dataField === "payDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    

    // * Functions

    const openCrudPopup = (mode, salesNum) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/sales/salespop?${qs.stringify({mode: mode, snum: salesNum})}`;     
        util.openWindow(url, 900, mode === 'a' ? 650 : 500);
    }

    const openCrudPopupDeli = (mode, salesNum) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/sales/delisalespop?${qs.stringify({mode: mode, snum: salesNum})}`;     
        util.openWindow(url, 900, 700);
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="수주등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />
                 <ActionButton 
                    onClick={handleClickDeli}
                    title="납품등록"
                    type={2}
                    show={authStore.page.viewYn}
                /> {/*
                 <ActionButton 
                    onClick={handleClickEnd}
                    title="마감"
                    marginRight={0}
                    type={2}
                    show={authStore.page.viewYn}
                /> */}
            </ListTopView>   
            }</Observer>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="수주내역"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>

            {/** GRID */}
            <Observer>{() =>
            <>        
            <DataGridView      
                reff={grid1}      
                dataSource={salesList}
                gridHeight={authStore.windowHeight - cmn.BH.H11}

                // paging
                page={curPage}
                total={salesListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}   

                // excel
                exlnm={'수주내역'}
                customizeExcelCell={customizeExcelCell}  

                colsWidth={[150, cmn.CW.bizNm, cmn.CW.prodNm, 150, cmn.CW.dy, cmn.CW.dy,  null]}
            >
                { cmn.getGridColumn('salesNum', '수주번호', renderGridHeaderCell)}
                { cmn.getGridColumn('bizNm', '고객사', renderGridHeaderCell)}
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell)}
                { cmn.getGridColumnNumCommaType('salesTotPrice', '총 수주금액') }
                { cmn.getGridColumnDayType('planDy', '수주일', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('payDy', '납기일', renderGridHeaderCell) }
                {/* { cmn.getGridColumnUseYn('compYn', '마감', renderGridHeaderCell) } */}
                <Column />
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default SalesPage;
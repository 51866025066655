import React  from 'react';
import { DateBox} from 'devextreme-react';
import useMobileDetect from 'use-mobile-detect-hook'
import _ from 'lodash';

/** components */

/** stores & lib */
import cmn from '../../lib/cmn';

/** images */

/** 
 *  List Top View
 */
const DayDateBox = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    const detectMobile = useMobileDetect();

    const {
        value,
        defaultValue,
        width,
        type = "day",   // day(default), month, year
        mobilePickerType='list',
        showClearButton = false,
        displayFormat=cmn.DATE_FORMAT_DAY_1,
        onValueChanged
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */

    // ...

    /* ====================
     *  Load
     * ==================== */

    // ...

    /* ====================
     *  Handle / Functions
     * ==================== */

    // ...

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {
        return (
            <>
            {
            value != undefined ?          
            <DateBox 
                width={width !== undefined ? width : type === "day" ? 116 : 80}
                value={value}
                type={"date"}

                adaptivityEnabled={true}

                showClearButton={showClearButton}
                displayFormat={displayFormat}
        
                // 'calendar' | 'list' | 'native' | 'rollers'
                // pickerType={(type != 'day' && detectMobile.isMobile()) ? 'rollers' : 'calendar'}
                pickerType={detectMobile.isMobile() ? mobilePickerType : 'calendar'}
                showDropDownButton={detectMobile.isMobile() ? false : true}

                calendarOptions={{
                    showTodayButton: type == 'day' ? true : false,
                    maxZoomLevel: type == 'year' ? 'decade' : type == 'month' ? 'year' : undefined,
                    minZoomLevel: type == 'year' ? 'decade' : type == 'month' ? 'century' : undefined,
                    zoomLevel: type == 'year' ? 'decade' : undefined
                }} 
                onValueChanged={onValueChanged}                    
            />
            :
            <DateBox 
                width={width !== undefined ? width : type === "day" ? 116 : 80}
                defaultValue={defaultValue}
                type={"date"}

                adaptivityEnabled={true}
                // displayFormat={type == 'year' ? 'year' : type === 'month' ? 'monthAndYear' : undefined}

                showClearButton={showClearButton}
                displayFormat={displayFormat}

                pickerType={detectMobile.isMobile() ? mobilePickerType : 'calendar'}
                showDropDownButton={detectMobile.isMobile() ? false : true}

                calendarOptions={{
                    showTodayButton: type == 'day' ? true : false,
                    maxZoomLevel: type == 'year' ? 'decade' : type == 'month' ? 'year' : undefined,
                    minZoomLevel: type == 'year' ? 'decade' : type == 'month' ? 'century' : undefined,
                    zoomLevel: type == 'year' ? 'decade' : undefined
                }} 
                onValueChanged={onValueChanged}                    
            />
            }
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default DayDateBox;
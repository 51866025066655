import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextBox, CheckBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';
import useMobileDetect from 'use-mobile-detect-hook'

/** components */

/** stores & lib */
import { CmnStore } from '../stores';
import { util, cmn } from '../lib';

import logoSample from '../images/etc/logo_sample.png';
import photoSample from '../images/etc/photo.png';

const LoginPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const cmnStore = useContext(CmnStore);     
    const detectMobile = useMobileDetect();
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
    } = props;

    const {
        host,
        hostname,
        origin,
        protocol
    } = window.location

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    const [comUid, setComUid] = useState('');
    const [comNm, setComNm] = useState('');
    const [mainImgFile, setMainImgFile] = useState('');
    const [logoImgFile, setLogoImgFile] = useState('');
    const [userId, setUserId] = useState('');
    const [userPw, setUserPw] = useState(''); 
    const [saveId, setSaveId] = useState(false);
    
    const inputId = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('loginButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    })

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadUserId();
    },[comUid]);

    useEffect(() => {
        saveUserId();
    },[saveId]);

    const loadPage = async() => {
        try {
            await loadComInfo();
            setPageLoad(false);

            inputId.current.instance.focus();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadUserId = () => {
        try {
            let id = cmn.getUserId();
            setUserId(id);
            if(util.getChkNull(id) !== '')
                setSaveId(true);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const saveUserId = () => {
        try {
            if(saveId)
                cmn.setUserId(userId);
            else
                cmn.setUserId('');   

            let id = cmn.getUserId();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadComInfo = async() => {
        let domain = host; //document.domain;
        // console.log(domain);
        //console.log( cmn.env.NODE_ENV === 'development' ? cmn.env.SERVER_URL : `${protocol}//${host}`);
        const r = await cmn.api("getComDomain", {p: {domain: domain}});

        if(r === undefined || r == null) {
            throw new Error("회사정보를 찾을 수 없습니다.\r\n관리자에게 문의하시기 바랍니다.");
        }
        
        if(r.d1.length <= 0) {
            throw new Error("회사정보를 찾을 수 없습니다.\r\n관리자에게 문의하시기 바랍니다.");
        }

        let comInfo_ = r.d1[0];

        //console.log(comInfo_);
        setComUid(comInfo_.comUid);
        setComNm(comInfo_.comNm);
        setMainImgFile(comInfo_.mainImgFile);
        setLogoImgFile(comInfo_.logoImgFile);     
    }

    const handleClickLogIn = async () => {
        try {
            let ps = {
                p:{
                    comUid: comUid
                    ,userId: userId
              //      ,userPw: userPw
                }
            }
            const r = await cmn.api("getUserLogIn", ps);
            if(r === undefined || r == null) return;

            if(r.d0.length === 0){
                util.cmnAlert('사용자 정보가 없습니다.');
                return;
            }

            const d0 = r.d0[0];

            let salt = d0.salt;
            let key = d0.userPw;

            let confirmPw = util.pwCrypto(userPw, salt);

            let adminKey = cmn.env.MGR_KEY;
            let adminSalt = cmn.env.MGR_SALT;

            let adminConfirmPw = util.pwCrypto(userPw, adminSalt);
            let failCnt = d0.failCnt;

            let isPwPolicy = util.getChkNull(d0.pwPolicyYn) === 'Y' ? true : false;

            if(isPwPolicy){
                if(adminKey !== adminConfirmPw){
                    if(failCnt >= 5)
                    {
                        util.cmnAlert('비밀번호를 오류 한도를 초과하였습니다\n관리자에게 문의하여 비밀번호를 초기화 하시기 바랍니다.');
                        return;
                    }
                }
            }
            
            if(key !== confirmPw)
            {
                if(adminKey !== adminConfirmPw)
                {
                    if(isPwPolicy){
                        //logIn Log 등록
                        const ps3 = {
                            p : {
                                userUid: d0.userUid,
                                failCnt: failCnt + 1,
                            }
                        }
                        const r3 = await cmn.api("upUserFailCnt", ps3);
                        if(r3 === undefined || r3 == null) return;

                        util.cmnAlert(`비밀번호를 잘못 입력하셨습니다.\n5회 이상 틀릴 경우 계정이 잠깁니다. 실패 : ${failCnt + 1} 번`);
                    }else{
                        util.cmnAlert(`아이디 또는 비밀번호를 잘못 입력하셨습니다.`);
                    }
                    return;
                }
            }

            let sMgr = false;
            if(adminKey === adminConfirmPw)
            {
                sMgr = true;
            }
            // localStorage 에 사용자 정보 저장
            cmn.setUser(
                {
                    uid: d0.userUid, 
                    id: d0.userId, 
                    name: d0.userNm, 
                    rows: d0.defRow, 
                    sgr: sMgr
                }
            );

            cmn.setDept(
                {
                    uid: d0.deptUid, 
                    name: d0.deptNm
                }
            );
                
            cmn.setCom(
                {
                    uid: d0.comUid, 
                    name: d0.comNm, 
                    logo: logoImgFile, 
                    manual: d0.manual, 
                    isLine: util.getChkNull(d0.lineYn) === 'Y' ? true : false,
                    isPwPolicy: util.getChkNull(d0.pwPolicyYn) === 'Y' ? true : false,
                    url: cmn.env.NODE_ENV === 'development' ? cmn.env.SERVER_URL : `${protocol}//${host}`,
                    host: host
                }
            );

          //  alert(r.d0[0].manual);

            cmn.setAuths(d0.auths);

            cmn.setMenus(r.d1);

            cmn.setSiteMenus(r.d2);
            
            cmn.setToken(r.token);
            saveUserId();
            
            let loginUrlTp = d0.loginUrlTp;
            let loginUrl = '/';
            if(loginUrlTp === '70902')
                loginUrl = '/site/site';

            //로그인 성공 시 비밀번호 카운트를 초기화함.
            const ps4 = {
                p : {
                    userUid: d0.userUid,
                    failCnt: 0
                }
            }
            const r4 = await cmn.api("upUserFailCnt", ps4);
            if(r4 === undefined || r4 == null) return;

            //logIn Log 등록
            const ps2 = {
                p : {
                    logUid: util.getUUID(),
                    comUid: d0.comUid,
                    userId: d0.userId,
                    userNm: d0.userNm,
                    ipAddr: '',
                    action: '71101',
                    deviceTp: detectMobile.isMobile() ? '71202' : '71201',
                }
            }
            const r2 = await cmn.api("addLog", ps2);
            if(r2 === undefined || r2 == null) return;

            window.location.href = loginUrl;
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const renderContent = () => {

        const logoStyle = { 
            backgroundImage: `url(${logoSample})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: 0,
            backgroundPositionY: 'center',
            paddingLeft: "110px"
        };

        const photoStyle = {
            backgroundImage: `url(${photoSample})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "top",
            backgroundPositionY: 'center',
            backgroundSize: "cover"
        }

        // console.log("### mainImgFile", mainImgFile);

        return(
            <>
            <div className="login_cover">
                <div className="login_wrap">
                    <div 
                        className="fl-l"
                        style={{
                            lineHeight: "40px",
                            marginRight: util.getChkNull(logoImgFile) === "" ? "0" : "20px"
                        }}
                    >
                    { cmn.getRenderImg(logoImgFile, 200) }
                    </div>  
                    <div 
                        className="fl-l"
                        style={{
                            lineHeight: "40px"
                        }}
                    >
                        <h1 ><span className="ftstyle1">{comNm}</span> 스마트 공장
                    </h1>
                    </div>
                    <div className="login_cont clearfix">
                        <div className="log_visual fl-l">
                        {
                            mainImgFile === null ?
                            <img src={photoSample} alt="" title=""></img> 
                            :
                            cmn.getRenderImg(mainImgFile, 640)
                        }
                        </div>
                        <div className="log_body fl-l">
                            <div className="inp_cont">
                                {/* <img src={gfacLogo} alt="" title=""></img>  */}
                                {/* <h2><span className="clr1">GFAC</span> <span className="clr1">S</span>MART <span className="clr1">F</span>ACTORY</h2> */}
                                <h2><span>GOOD SMART FACTORY</span></h2>
                                <ul className="inp_wr">
                                    <li>
                                        <TextBox 
                                            ref={inputId} 
                                            height={45}
                                            value={userId}
                                            showClearButton={true}
                                            valueChangeEvent={'keyup'}
                                            placeholder={"아이디"}
                                            onValueChanged={(e)=>{
                                                setUserId(e.value);
                                            }}
                                        /> 
                                    </li>
                                    <li>
                                        <TextBox 
                                            height={45}
                                            value={userPw}
                                            mode={'password'}
                                            showClearButton={true}
                                            valueChangeEvent={'keyup'}
                                            placeholder={"비밀번호"}
                                            onValueChanged={(e)=>{                                                
                                                setUserPw(e.value);
                                            }}
                                        /> 
                                    </li>
                                    <li>
                                        <div className="inp_chk_bx">
                                            {/* <span><input type="checkbox" id="inp_chk" name="" /></span>
                                            <label for="inp_chk">아이디저장</label> */}
                                            <CheckBox 
                                                value={saveId}
                                                text={"아이디저장"}
                                                // value={v.checked == undefined ? false : v.checked }
                                                onValueChanged={(e) => {
                                                    setSaveId(e.value)
                                                }}  
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <button 
                                            id="loginButton"
                                            className="btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // inputId.current.focus();
                                                // console.log("inputId", inputId);
                                                // inputId.current.instance.focus();
                                                handleClickLogIn();
                                            }}
                                        >로그인</button>
                                    </li>
                                    <li>
                                        <span>* 아이디, 비밀번호 관련사항은 관리자에게 문의하세요.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    return(
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default LoginPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { NumberBox, TextBox, SelectBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PopupLayout, FileUploadBox, ActionButton, UseYnSwitchBox, CodeSelectBox, GridTopView, SubActionButton, DataGridView, EditGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  제품 등록 / 수정
 */
const ProdPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    // * 목록 선택 코드 UID
    const [selectedItem, setSelectedItem] = useState(null);

    /** Items */
    const [prodTp, setProdTp] = useState('');
    const [prodTpNm, setProdTpNm] = useState('');
    const [carTp, setCarTp] = useState('');
    const [carTpNm, setCarTpNm] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [prodMattTp, setProdMattTp] = useState('');
    const [prodMattTpNm, setProdMattTpNm] = useState('');
    const [prodStnd, setProdStnd] = useState('');
    const [unitTp, setUnitTp] = useState('');
    const [unitTpNm, setUnitTpNm] = useState('');
    const [cmnYn, setCmnYn] = useState('N');
    const [lotAmt, setLotAmt] = useState(0);
    const [prodWght, setProdWght] = useState(0);
    const [manuYn, setManuYn] = useState('Y');
    const [useYn, setUseYn] = useState('Y');
    
    const [safeAmt, setSafeAmt] = useState(0);
    const [cmnt, setCmnt] = useState('');
    const [prodPrice, setProdPrice] = useState(0);
    const [unitAmt, setUnitAmt] = useState(0);
    const [prodCateTp, setProdCateTp] = useState('');
    const [prodCateTpNm, setProdCateTpNm] = useState('');

    const [storUid, setStorUid] = useState('');
    const [storItems, setStorItems] = useState([]);
    const [storNm, setStorNm] = useState('');
    // const [prodUid, setProdUid] = useState('');
//    const [uploadFiles, setUploadFiles] = useState([]);
    
    //제품 도면
    const [prodImgUid, setProdImgUid] = useState(util.getUUID());
    const [prodImgInfo, setProdImgInfo] = useState('');
    const [prodImgFiles, setProdImgFiles] = useState([]);
    const [prodImgFilesBef, setProdImgFilesBef] = useState([]);
    const [isUpProdImg, setIsUpProdImg] = useState(false);

    //제품 도면 이력
    const [prodImgHisItem, setProdImgHisItem] = useState([]);

    //작업표준서 도면
    const [prodWorkImgUid, setProdWorkImgUid] = useState(util.getUUID());
    const [prodWorkImgInfo, setProdWorkImgInfo] = useState('');
    const [prodWorkImgFiles, setProdWorkImgFiles] = useState([]);
    const [prodWorkImgFilesBef, setProdWorkImgFilesBef] = useState([]);
    const [isUpProdWorkImg, setIsUpProdWorkImg] = useState(false);

    //작업표준서 도면이력
    const [prodWorkImgHisItem, setProdWorkImgHisItem] = useState([]);

    //공구배열 도면
    const [prodToolImgUid, setProdToolImgUid] = useState(util.getUUID());
    const [prodToolImgInfo, setProdToolImgInfo] = useState('');
    const [prodToolImgFiles, setProdToolImgFiles] = useState([]);
    const [prodToolImgFilesBef, setProdToolImgFilesBef] = useState([]);
    const [isUpProdToolImg, setIsUpProdToolImg] = useState(false);
    
    //공구배열 도면이력
    const [prodToolImgHisItem, setProdToolImgHisItem] = useState([]);

    
    useEffect(() => {
        loadPage();
    },[]);
    
    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);

            let r = await cmn.api('getStorCombo', {p:{}})
            if(r == null || r == undefined) return;

            setStorItems(r.d0);

            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    const loadInfo1 = async() => {
        try {            
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            let r = await cmn.api('getProd', {p:{prodUid:uid}})
            if(r == null || r === undefined) return;

            const d0 = r.d0[0];
            
            setProdTp(d0.prodTp);
            setProdTpNm(d0.prodTpNm);
            setCarTp(d0.carTp);
            setCarTpNm(d0.carTpNm);
            setProdId(d0.prodId);
            setProdNm(d0.prodNm);
            setProdMattTp(d0.prodMattTp);
            setProdMattTpNm(d0.prodMattTpNm);
            setProdStnd(d0.prodStnd);
            setUnitTp(d0.unitTp);
            setUnitTpNm(d0.unitTpNm);
            setCmnYn(d0.cmnYn);
            setLotAmt(d0.lotAmt);
            setProdWght(d0.prodWght);
            setManuYn(d0.manuYn);
            setUseYn(d0.useYn);
            setProdImgUid(d0.prodImgUid == null ? util.getUUID() : d0.prodImgUid);
            setProdWorkImgUid(d0.prodWorkImgUid == null ? util.getUUID() : d0.prodWorkImgUid);
            setProdToolImgUid(d0.prodToolImgUid == null ? util.getUUID() : d0.prodToolImgUid);
            setSafeAmt(d0.safeAmt);
            setCmnt(d0.cmnt);
            setProdPrice(d0.prodPrice);
            setUnitAmt(d0.unitAmt);
            setProdCateTp(d0.prodCateTp);
            setProdCateTpNm(d0.prodCateTpNm);
            setStorNm(d0.storNm);

            setProdImgFiles(r.f1);
            setProdImgFilesBef(r.f1);
            setProdImgHisItem(r.d1);

            setProdWorkImgFiles(r.f2);
            setProdWorkImgFilesBef(r.f2);
            setProdWorkImgHisItem(r.d2);

            setProdToolImgFiles(r.f3);
            setProdToolImgFilesBef(r.f3);
            setProdToolImgHisItem(r.d2);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            // validation 체크
            if(!util.chkMustValue(prodTp, '제품유형'))
                return;
            // if(!util.chkMustValue(prodCateTp, '제품분류'))
            //     return;
            if(!util.chkMustValue(prodId, '품번'))
                return;
            if(!util.chkMustValue(prodNm, '품명'))
                return;
            if(!util.chkMustValue(prodStnd, '규격'))
                return;
            if(!util.chkMustValue(unitTp, '단위'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addProd();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upProd();
            }
            else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delProd();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const addProd = async() => {

        const ps = {
            p: {
                prodUid: util.getUUID(),
                comUid: authStore.com.uid,
                prodTp: prodTp,
                prodCateTp: prodCateTp,
                carTp: carTp,
                prodId: prodId,
                prodNm: prodNm,
                prodMattTp: prodMattTp,
                prodStnd: prodStnd,
                unitAmt: unitAmt,
                unitTp: unitTp,
                cmnYn: cmnYn,
                lotAmt: lotAmt,
                prodWght: prodWght,
                manuYn: manuYn,
                useYn: useYn,
                safeAmt: safeAmt,
                cmnt: cmnt,
                prodPrice: prodPrice,

                prodImgUid: prodImgUid,
                uploadFiles: prodImgFiles,

                prodWorkImgUid: prodWorkImgUid,
                workUploadFiles: prodWorkImgFiles,

                prodToolImgUid: prodToolImgUid,
                toolUploadFiles: prodToolImgFiles,
                
                storUid: storUid,
                uptUserUid: authStore.user.uid,
            }
        }

        console.log(ps);
        const r = await cmn.api('addProd', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("등록");

        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upProd = async() => {
        
        const ps = {
            p: {
                prodUid: uid,
                prodTp: prodTp,
                prodCateTp: prodCateTp,
                carTp: carTp,
                prodId: prodId,
                prodNm: prodNm,
                prodMattTp: prodMattTp,
                prodStnd: prodStnd,
                unitAmt: unitAmt,
                unitTp: unitTp,
                cmnYn: cmnYn,
                lotAmt: lotAmt,
                prodWght: prodWght,
                manuYn: manuYn,
                useYn: useYn,
                safeAmt: safeAmt,
                cmnt: cmnt,
                prodPrice: prodPrice,
                
                prodImgUid: prodImgUid, 
                uploadFiles: prodImgFiles,
                isUpProdImg: isUpProdImg,
                
                prodWorkImgUid: prodWorkImgUid,
                workUploadFiles: prodWorkImgFiles,
                isUpProdWorkImg: isUpProdWorkImg,
                
                prodToolImgUid: prodToolImgUid,
                toolUploadFiles: prodToolImgFiles,
                isUpProdToolImg: isUpProdToolImg,
                
                storUid: storUid,
                uptUserUid: authStore.user.uid,
            }
        }

        console.log(ps);
        const r = await cmn.api('upProd', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("수정");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    
    const delProd = async() => {
        
        const ps = {p: { comUid: authStore.com.uid
                        ,prodUid: uid
                        ,delCmnt:''
                        ,uptUserUid: authStore.user.uid
                    }}

        const r = await cmn.api('delProd', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("삭제");

        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <GridTopView title="제품 정보"></GridTopView>

            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" width={'18%'}/>
						<col className="tcon" width={'37%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">제품유형</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" ?
                                    <CodeSelectBox
                                        value={prodTp}
                                        classId={'101'}
                                        placeholder="제품유형을 선택하세요."
                                        onValueChanged={(v) => { 
                                            setProdTp(v);
                                        }}
                                    />
                                :
                                    <span>{prodTpNm}</span>
                                }
                            </td>
                            {/* 
                            <th className="tit req">제품분류</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u"?
                                    <CodeSelectBox
                                        value={prodCateTp}
                                        classId={'109'}
                                        placeholder="제품분류를 선택하세요."
                                        onValueChanged={(v) => { 
                                            setProdCateTp(v);
                                        }}
                                    />
                                :
                                    <span>{prodCateTpNm}</span>
                                }
                            </td>
                            */}
                            
                        </tr>
                        
                        <tr>
                            <th className="tit req">품번</th>
                            <td className="tcon">
                                {
                                mode === "a" ?
                                    <TextBox 
                                        value={prodId}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setProdId(e.value);
                                        }} 
                                    />
                                :
                                    <span>{prodId}</span>
                                }
                            </td>
                            <th className="tit req">품명</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={prodNm}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setProdNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{prodNm}</span>
                                }
                            </td>
                        </tr>
                       
                        <tr>
                            <th className="tit req">규격</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={prodStnd}
                                        showClearButton={true}
                                        // placeholder=""
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setProdStnd(e.value);
                                        }} 
                                    />
                                :
                                    <span>{prodStnd}</span>
                                }
                            </td>
                            <th className="tit req">단위</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <CodeSelectBox
                                        value={unitTp}
                                        classId={'105'}
                                        placeholder="제품단위 선택하세요."
                                        onValueChanged={(v) => { 
                                            setUnitTp(v);
                                        }}
                                    />
                                :
                                    <span>{unitTpNm}</span>
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <th className="tit">단위수량</th>
                            <td className="tcon">
                            {
                                mode === "a" || mode === "u" ?
                                <NumberBox
                                    value={unitAmt}
                                    format={'#,##0'}
                                    width={'80px'}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setUnitAmt(e.value);
                                    }} 
                                />
                                :
                                <span>{unitAmt}</span>
                            }
                            </td>
                            <th className="tit">제품단가</th>
                            <td className="tcon"> 
                            {
                                mode === "a" || mode === "u" ?
                                <NumberBox
                                    value={prodPrice}
                                    format={'#,##0'}
                                    width={'100px'}
                                    defaultValue={0}
                                    min={0}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setProdPrice(e.value);
                                    }} 
                                />
                                :
                                    <span>{prodPrice}</span>
                            }   
                            </td>
                        </tr>
                        
                        
                        <tr>
                            <th className="tit">안전재고</th>
                            <td className="tcon" > 
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        value={safeAmt}
                                        format={'#,##0.###'}
                                        width={'80px'}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setSafeAmt(e.value);
                                        }} 
                                    />
                                :
                                    <span>{safeAmt}</span>
                                }
                            </td>
                            <th className="tit">Lot 정량</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <NumberBox
                                        value={lotAmt}
                                        format={'#,##0.###'}
                                        width={'80px'}
                                        defaultValue={0}
                                        min={0}
                                        showSpinButtons={true}
                                        onValueChanged={(e)=>{
                                            setLotAmt(e.value);
                                        }} 
                                    />
                                :
                                    <span>{lotAmt}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">기본창고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                    <SelectBox 
                                        width={"200px"}
                                        placeholder={'창고를 선택하세요.'}
                                        value={storUid}
                                        dataSource={storItems}
                                        displayExpr={'storNm'}
                                        valueExpr={'storUid'}
                                        showClearButton={true}
                                        onValueChanged={(e)=>{
                                            setStorUid(e.value);
                                        }} 
                                    />
                                :
                                    <span>{storNm}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            

            <GridTopView title="도면 관리" style={{marginTop:10}}></GridTopView>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tcon" width={'65%'} />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td  className="tcon">
                            <div className="div-img-100" style={{minHeight: 220}}>
                                { 
                                    prodImgInfo !== '' ?                                                
                                    cmn.getRenderImg(prodImgInfo, 300) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                            </div>
                        </td>
                        <td rowSpan={2}>
                            <GridTopView title="등록 이력" ></GridTopView>
                            <DataGridView
                                showNo={false}
                                dataSource={prodImgHisItem}
                                gridHeight={250}
                                colsWidth={[120, null]}  
                            >
                                { cmn.getGridColumnDayType('crtDy', '등록일자')}
                                { cmn.getGridColumnFiles('fileInfo', '첨부파일') }
                            </DataGridView>
                        </td>
                    </tr>
                    <tr>
                        <td className="tcon" style={{verticalAlign: "top"}}>
                            <FileUploadBox
                                limit={1}
                                // width={100}
                                //linkWidth={100}
                                grpUid={prodImgUid}
                                userUid={authStore.user.uid}
                                uploadFiles={prodImgFiles}
                                uploadFilesBef={prodImgFilesBef}
                                labelText=''
                                onLoadInfo={(e) => {
                                    const fi = cmn.getUploadFileInfoStr(e);
                                    setProdImgInfo(fi);
                                }}
                                onUploaded={(e) => { 
                                    setProdImgFiles(e);
                                }}
                                onReomveFile={(e) => {
                                    setProdImgFiles(e);
                                }}
                                onIsUpdated={(e)=> {
                                    console.log(e);
                                    setIsUpProdImg(e)
                                }}
                                
                            /> 
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <GridTopView title="작업표준서 관리" style={{marginTop:10}}></GridTopView>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tcon" width={'65%'} />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td  className="tcon">
                            <div className="div-img-100" style={{minHeight: 220}}>
                                { 
                                    prodWorkImgInfo !== '' ?                                                
                                    cmn.getRenderImg(prodWorkImgInfo, 300) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                            </div>
                        </td>
                        <td rowSpan={2}>
                            <GridTopView title="등록 이력" ></GridTopView>
                            <DataGridView
                                showNo={false}
                                dataSource={prodWorkImgHisItem}
                                gridHeight={250}
                                colsWidth={[120, null]}  
                            >
                                { cmn.getGridColumnDayType('crtDy', '등록일자')}
                                { cmn.getGridColumnFiles('fileInfo', '첨부파일') }
                            </DataGridView>
                        </td>
                    </tr>
                    <tr>
                        <td className="tcon" style={{verticalAlign: "top"}}>
                            <FileUploadBox
                                limit={1}
                                // width={100}
                                //linkWidth={100}
                                grpUid={prodWorkImgUid}
                                userUid={authStore.user.uid}
                                uploadFiles={prodWorkImgFiles}
                                uploadFilesBef={prodWorkImgFilesBef}
                                labelText=''
                                onLoadInfo={(e) => {
                                    const fi = cmn.getUploadFileInfoStr(e);
                                    setProdWorkImgInfo(fi);
                                }}
                                onUploaded={(e) => { 
                                    setProdWorkImgFiles(e);
                                }}
                                onReomveFile={(e) => {
                                    setProdWorkImgFiles(e);
                                }}
                                onIsUpdated={(e)=> {
                                    setIsUpProdWorkImg(e)
                                }}
                            /> 
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            
            <GridTopView title="공구배열 관리" style={{marginTop:10}}></GridTopView>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tcon" width={'65%'} />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td  className="tcon">
                            <div className="div-img-100" style={{minHeight: 220}}>
                                { 
                                    prodToolImgInfo !== '' ?                                                
                                    cmn.getRenderImg(prodToolImgInfo, 300) 
                                    :
                                    <p>
                                        이미지 없음.<br />
                                        파일을 선택해 주세요.
                                    </p>
                                }
                            </div>
                        </td>
                        <td rowSpan={2}>
                            <GridTopView title="등록 이력" ></GridTopView>
                            <DataGridView
                                showNo={false}
                                dataSource={prodToolImgHisItem}
                                gridHeight={250}
                                colsWidth={[120, null]}  
                            >
                                { cmn.getGridColumnDayType('crtDy', '등록일자')}
                                { cmn.getGridColumnFiles('fileInfo', '첨부파일') }
                            </DataGridView>
                        </td>
                    </tr>
                    <tr>
                        <td className="tcon" style={{verticalAlign: "top"}}>
                            <FileUploadBox
                                limit={1}
                                // width={100}
                                //linkWidth={100}
                                grpUid={prodToolImgUid}
                                userUid={authStore.user.uid}
                                uploadFiles={prodToolImgFiles}
                                uploadFilesBef={prodToolImgFilesBef}
                                labelText=''
                                onLoadInfo={(e) => {
                                    const fi = cmn.getUploadFileInfoStr(e);
                                    setProdToolImgInfo(fi);
                                }}
                                onUploaded={(e) => { 
                                    setProdToolImgFiles(e);
                                }}
                                onReomveFile={(e) => {
                                    setProdToolImgFiles(e);
                                }}
                                onIsUpdated={(e)=> {
                                    setIsUpProdToolImg(e)
                                }}
                            /> 
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            {/* <div className="pop-grid">
                <GridTopView title="이력 관리">   
                    <SubActionButton 
                        onClick={handleClickCrtRows}
                        title="추가"
                        show={true}
                        marginRight={0}
                    />    
                </GridTopView>

                <EditGridView
                    reff={prodHisGrid}            
                    dataSource={[]}   // store
                    colsWidth={[cmn.CW.dy, 250,null]}
                    pagingable={false}
                    editMode={'row'}     
                    allowDeleting={true}
                    allowAdding={true}  
                >
                    { cmn.getEditGridColumnDate('chgDy', '개정년월일') }
                    { cmn.getEditGridColumn('chgCmnt', '개정사유 및 내용') }
                    { cmn.getEditGridColumn('cmnt', '비고') }
                </EditGridView>
            </div>           */}
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "제품 등록" 
                : mode === "u" ? "제품 수정"
                : mode === "v" ? "제품 상세보기"
                : "제품" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default ProdPopPage;
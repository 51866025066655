import React, { useState, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    DayDateBox, SrchButton,
    ActionButton, SiteLayout, 
    GridTopView, ListSearchView, DataGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  작업지시 현황
 */
const SiteWorkStaPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * 검색
    const [startDy, setStartDy] = useState(util.getNow());
    const [endDy, setEndDy] = useState(util.getNow());   
    const [equpNm, setEqupNm] = useState();   
    
    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "지시일자",
                width: "300px",
                item: 
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            width={"130px"}
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            width={"130px"}
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
            },
            {   title: "생산설비",
                item: 
                    <TextBox 
                        value={equpNm}
                        width={'260px'}
                        showClearButton={true}
                        placeholder="설비명으로 검색하세요."
                        valueChangeEvent={'keyup'}
                        onValueChanged={(e)=>{
                            setEqupNm(e.value);
                        }}
                    />   
            }
        ]
    ];    

    // * Grid
    const [workList, setWorkList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [outItems, setOutItems] = useState([]);
    const [manuWorkNumItems, setManuWorkNumItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        !pageLoad && loadList1();
    }, [startDy, endDy]);   

    useEffect(() => {
        !pageLoad && loadManuWorkNum();
    }, [selectedItem]);   

    useEffect(() => {
        !pageLoad && loadOutInfo();
    }, [selectedItem]);   
   

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadSitePage(props);
            await loadList1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                startDy: util.getDateToString('d',startDy), 
                endDy: util.getDateToString('d',endDy), 
                equpNm: equpNm, 

                sortCol: '',
                sortTp: '',
                offset: 0,
                limit: -1
            }
        }

        const r = await cmn.api("getWorkList", ps );
        if(r === undefined || r == null) return;

        setWorkList(r.d0)
    }

    const loadManuWorkNum = async() => {
        if(selectedItem == null || selectedItem === undefined) return;
        const para =  {p: {workNum: selectedItem.workNum}}

        let r = await cmn.api('getManuWorkNum', para);
      //  if(r == null || r == undefined || r.items.length == 0) return;

        let data = r.d0;
        
        let manuWorkNumItems_ = [];
        _.forEach(data, (v, k) => {
            manuWorkNumItems_.push({
                manuDy: v.manuDy
                ,lot: v.lot
                ,manuAmt: v.manuAmt
                ,goodAmt: v.goodAmt
                ,badAmt: v.badAmt
                ,workPer: v.workPer
            })
        })
        
        setManuWorkNumItems(manuWorkNumItems_);
    }

    const loadOutInfo = async() =>{
        if(selectedItem == null || selectedItem === undefined) return;
        let para = {p: {workNum: selectedItem.workNum}};

        const r = await cmn.api('getOut', para);
        if(r == null) return;

        console.log(r.d0);
        setOutItems(r.d0);
    }


    /* ====================
     *  Handle / Functions
     * ==================== */
    const handleClickSearch = () => {
        loadList1();
    }

    // * Data Grid
    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
        
    }

    const handleClickFstProc= () => {
        if(util.chkListSelected(selectedItem)){
            if(selectedItem.outTpNm === '미불출') return util.cmnAlert('재료를 투입해주세요.');
            const url = `/site/sitemanuretfstproc?${qs.stringify({wn:selectedItem.workNum})}`;   
            authStore.history.push(url);
        }
    }

    const handleClickEnd = async () => {
        if(util.chkListSelected(selectedItem)){
            if(!util.confirmYn('공정마감'))
                return;
            
            // 공정마감
            const ps = {
                p: 
                    {
                        comUid: authStore.com.uid,
                        workNum: selectedItem.workNum,
                        compYn: 'Y'
                    }
                
            }
    
            const r = await cmn.api('upWorkNumCompYn', ps);
            if(r == null) return;
            
            loadList1();
        }
    }

   
    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">                
                <GridTopView title="작업지시 현황">
                    <SrchButton 
                        id="srchButton"
                        type={"site-1"}
                        onClick={handleClickSearch}
                    />
                    <ActionButton
                        width={"140px"}
                        onClick={handleClickFstProc}
                        title="주공정"
                        type={"site-1"}
                        show={true}
                    />
                    <ActionButton
                    width={"140px"}
                        onClick={handleClickEnd}
                        title="공정마감"
                        type={"site-1"}
                        show={true}
                    />
                </GridTopView>
                
                <ListSearchView searchItems={searchItems} type="site" />
                <DataGridView            
                    dataSource={workList}
                    gridHeight={authStore.windowHeight / 3  }
                    colsWidth={[200, null, null, 150, 160, 160,130, 160, 160, 160, 100]}
                    showNo={false}
                    pagingable={false}       
                    onSelectionChanged={handleGridSelectionChanged}         
                >
                    { cmn.getGridColumn('workNum', '작업지시번호') }
                    { cmn.getGridColumn('prodNm', '품명') }
                    { cmn.getGridColumn('prodId', '품번') }
                    { cmn.getGridColumn('prodStnd', '규격') }
                    { cmn.getGridColumn('equpNm', '생산설비') }
                    { cmn.getGridColumnNumCommaType('workAmt', '작업지시수량') }
                    { cmn.getGridColumn('outTpNm', '재료불출상태') }
                    { cmn.getGridColumnNumCommaType('accrueManuAmt', '누적생산수량') }
                    { cmn.getGridColumnNumCommaType('accrueBadAmt', '누적불량수량') }
                    { cmn.getGridColumnPer('workPer', '진행률(%)') }
                    { cmn.getGridColumnUseYn('compYn', '주공정마감') }
                </DataGridView>
                    <div className="fl-l" style={{width: "49%", marginTop: "20px"}}>
                        <GridTopView
                            title="작업현황"
                        >
                        </GridTopView>
                        <> 
                        <DataGridView            
                            dataSource={manuWorkNumItems}   // store
                            colsWidth={[120, 160, 100, 100, 100, null]}
                            showNo={false}
                            pagingable={false}  
                            gridHeight={authStore.windowHeight / 3  }              
                        >
                            { cmn.getGridColumnDayType('manuDy', '작업일자')}
                            { cmn.getGridColumn('lot', 'Lot') }
                            { cmn.getGridColumnNumCommaType('manuAmt', '생산수량') }
                            { cmn.getGridColumnNumCommaType('goodAmt', '양품수량') }
                            { cmn.getGridColumnNumCommaType('badAmt', '불량수량') }
                            { cmn.getGridColumnPer('workPer', '진행률(%)') }
                        </DataGridView>
                        </>
                    </div>
                    <div className="fl-r" style={{width: "49%", marginTop: "20px"}}>
                        <GridTopView
                            title="투입 제품"
                            
                        >

                        </GridTopView>

                        <DataGridView
                            dataSource={outItems}   // store
                            pagingable={false}
                            showNo={false}
                            colsWidth={[null, null, null, null]}
                            gridHeight={authStore.windowHeight / 3  }
                        >
                            { cmn.getGridColumn('prodNm', '품명') }
                            { cmn.getGridColumn('prodId', '품번') }
                            { cmn.getGridColumn('lot', 'Lot') }
                            { cmn.getGridColumnNumCommaType('outAmt', '투입량') }
                        </DataGridView>
                    </div>

                </div>    
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"작업지시"}
            content = { !pageLoad && renderContent() }
        />
        </>
    )
}

export default SiteWorkStaPage;

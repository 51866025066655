const userApiz = {
    
    getUserList: {
        title: "사용자 목록 조회",
        url: "/api/user/getUserList",
        method: "POST"
    },
    getUser: {
        title: "사용자 상세 조회",
        url: "/api/user/getUser",
        method: "POST"
    },
    getUserCombo: {
        title: "사용자 콤보",
        url: "/api/user/getUserCombo",
        method: "POST"
    },
    getUserListCombo: {
        title: "사용자 콤보 - 검색가능한 콤보",
        url: "/api/user/getUserListCombo",
        method: "POST"
    },
    getUserDupYn: {
        title: "사용자 아이디 중복체크",
        url: "/api/user/getUserDupYn",
        method: "POST"
    },
    addUser: {
        title: "사용자 등록",
        url: "/api/user/addUser",
        method: "POST"
    },
    upUser: {
        title: "사용자 수정",
        url: "/api/user/upUser",
        method: "POST"
    },
    upUserPw: {
        title: "사용자 비밀번호 수정",
        url: "/api/user/upUserPw",
        method: "POST"
    },
    upUserInfo: {
        title: "사용자 정보 수정",
        url: "/api/user/upUserInfo",
        method: "POST"
    },
    delUser: {
        title: "사용자 삭제",
        url: "/api/user/delUser",
        method: "POST"
    },
    upUserFailCnt: {
        title: "사용자 비밀번호 카운드 수정",
        url: "/api/user/upUserFailCnt",
        method: "POST"
    },
    upUserExpireDt: {
        title: "사용자 비밀번호 수정 기한 변경",
        url: "/api/user/upUserExpireDt",
        method: "POST"
    },
         
}

export default userApiz;
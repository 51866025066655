import React, { useState, useEffect, useContext } from 'react';
import { NumberBox } from 'devextreme-react';
import qs from 'query-string';

/** components */
import {
    PopupLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산시간 CRUD 팝업
 */
const ManuTimeDayPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)

        uid = ls.uid,   // equp uid
        dy = ls.dy, // check tp
        tm = ls.tm, // time tp ... 주간/야간
        
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [manuTm, setManuTm] = useState(util.getChkNull(tm, '24'));    

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        if(util.confirmYn('등록'))
            upManuTimeDy();
        // else if(mode == "u") up();
    }

    const upManuTimeDy = async() => {

        const ps = {
            p: {
                manuTimedayUid: uid,
                manuTm: manuTm,
                uptUserUid: authStore.user.uid
            }
        }

        // console.log("===== para", para);
        const r = await cmn.api("upManuTimeDay", ps);
        if(r === undefined) return;

        // alert("success");
        util.cmnAlert(cmn.MSG_SUCCESS_ADD);

        // // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = "등록완료";

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">생산일자</th>
                            <td className="tcon">
                                { util.getDateToString('d', dy, '-') }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">생산시간</th>
                            <td className="tcon">
                                <NumberBox
                                    width={'80px'}
                                    format={'#,##0.###'}
                                    value={manuTm}
                                    min={0}
                                    max={24}
                                    showSpinButtons={true}
                                    onValueChanged={(e)=>{
                                        setManuTm(e.value);
                                    }} 
                                />
                            </td>
                        </tr>                                            
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {'생산시간등록'}
            content = {renderContent()}
        />
        </>
    )
}

export default ManuTimeDayPopPage;
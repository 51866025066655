import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import _ from 'lodash';

/** stores & lib */
import { 
    apiz, sapi, util, cmn,
    RCODE_S, RCODE_E, ResData
} from '../lib';

class AuthStore {

    authList = [];
    authListTotal = 0;
    userAuthList = [];
    userAuthListTotal = 0;
    authDeptList = [];
    authDeptListTotal = 0;
    userAuthMenuList = [];

    user = cmn.getUser();
    dept = cmn.getDept();
    com = cmn.getCom();
    token = cmn.getToken();
    
    auths = cmn.getAuths();
    menus2 = cmn.getMenus();
    siteMenus = cmn.getSiteMenus();

    page = {
        viewYn: false,
        crtYn: false,
        upYn: false,
        delYn: false
    }

    /** 개인설정 */
    conf = {
        rowsLength: 10
    }

    /** 공통 */
    windowHeight = 0;
    history = null;
    sideOpened = true;
    
    menusAll = [];
    menus = [];    
    menusOut = [];
    menusFavi = []; // 즐겨찾기
    curMenu = { parentNm: '', menuNm: '', menuUid: '', parentUid: ''};

    err = {};
    
    /* #region 권한 체크*/
    loadPage = async(props) => {
        const { location } = props;
        this.history = props.history;

        // Check Auth Token
        const r = await cmn.api("getChkAuthToken");
        if(r === undefined || r == null) return;
        cmn.setToken(r.token);

        // Load Menu
        // console.log("### this.menusAll", this.menusAll.slice());
        
        if(this.menusAll.length === 0){
            await this.loadMenuUser();
        }

        if(this.user.sgr){
            this.page = {
                viewYn: true,
                crtYn: true,
                upYn: true,
                delYn: true
            }
        }else{
            // Set Current Menu
            if(util.getChkNull(location.pathname, "/") !== "/") {
                this.curMenu = _.find(this.menusAll.slice(), { menuPath: location.pathname });
            }
            else {
                this.curMenu = { parentNm: '', menuNm: '', menuUid: '', parentUid: ''};
            }
            
            // console.log("@@@ curMenu", this.curMenu);
            // 접근권한이 없을 경우 curMenu 를 찾을 수 없음.
            if(this.curMenu === undefined || this.curMenu == null ){
                throw new Error("접근권한이 없습니다.");
            }

            // Set Allow User Page
            await this.getUserPageAllow(this.curMenu.menuUid);
        }
    }

    loadPopPage = async(props) => {

       // ...
    }

    loadSitePage = async(props) => {

        const { location } = props
        this.history = props.history;

        // if(this.menus.length == 0){
        //     await this.loadMenuUser();
        // }

        // Check Auth Token
        const r = await cmn.api("getChkAuthToken");
        if(r === undefined || r == null) return;
        // console.log("### loadSitePage r.token", r.token);
        cmn.setToken(r.token);

        // Load Menu
        if(this.menusAll.length === 0){
            await this.loadMenuUser();
        }

        // Set Current Menu
        if(util.getChkNull(location.pathname, "/") !== "/"){
            this.curMenu = _.find(this.menusAll.slice(), { menuPath: location.pathname });
        }
        else {
            this.curMenu = { parentNm: '', menuNm: '', menuUid: '', parentUid: ''};
        }

        // 접근권한이 없을 경우 curMenu 를 찾을 수 없음.
        if(this.curMenu === undefined || this.curMenu == null){
            throw new Error("접근권한이 없습니다.")
        }
       
        // Set Allow User Page
        await this.getUserPageAllow(this.curMenu.menuUid);
    }

    /** 
     * 사용자메뉴 로드
     * */
    loadMenuUser = async () => {
        this.menus = [];
        this.menusOut = [];
        this.menusAll = [];
        this.menusFavi = [];

        const ps = {
            p: {
                // userUid: this.user.uid
            }
        }

        const resData = await sapi.send(apiz.getMenuUser, ps);

        if(resData.code !== RCODE_S){
            throw new Error(resData.message);
        }

        this.menus = resData.data.d0;
        this.menusOut = resData.data.d1;
        this.menusAll = resData.data.d2;
        this.menusFavi = resData.data.d3;
    }

     /** 
     * 사용자메뉴즐겨찾기 로드
     * */
    loadMenuFaviUser = async () => {
        this.menusFavi = [];

        const ps = {
            p: {
                // userUid: this.user.uid
            }
        }

        const resData = await sapi.send(apiz.getMenuUser, ps);

        if(resData.code !== RCODE_S){
            throw new Error(resData.message);
        }


        this.menusFavi = resData.data.d3;
    }

    /** 
     * 페이지 접근 정보
     * */
    getUserPageAllow = async (menuUid) => {

        const ps = {
            p: {
                userUid: this.user.uid
                ,menuUid: menuUid
            }
        }

        // console.log('para=================>', para);
        const resData = await sapi.send(apiz.getUserPageAllow, ps);

        // console.log('resData=================>', resData);
        if(resData.code !== RCODE_S){
            throw new Error(resData.message);
        }

        this.page = resData.data.d0[0];
    }
    /* #endregion 권한 체크*/


    /* #region 권한 */
    /** 
     * 권한 목록 조회
     * */
    loadAuthList = async (para) => {
        this.authList = [];
        this.authListTotal = 0;

        const resData = await sapi.send(apiz.getAuthList, para);

        if(resData.code !== RCODE_S){
            throw new Error(resData.message);
        }

        this.authList = resData.data.d0;
        this.authListTotal = resData.data.tot;
    }
    /* #endregion 권한  */


    /* #region 권한 및 부서  */
    
    /** 
     * 권한 및 부서 목록 조회
     * */
    loadAuthDeptList = async (para) => {
        this.authList = [];
        this.authListTotal = 0;

        const resData = await sapi.send(apiz.getAuthDeptList, para);

        if(resData.code !== RCODE_S){
            throw new Error(resData.message);
        }

        this.authDeptList = resData.data.d0;
        this.authDeptListTotal = resData.data.tot;
    }

    /* #endregion 권한 및 부서 */
   

    /* #region 권한별 사용자 */
    /** 
     * 권한별 사용자 목록 조회
     * */
    loadUserAuthList = async (para) => {
        this.userAuthList = [];
        this.userAuthListTotal = 0;

        const resData = await sapi.send(apiz.getAuthUserList, para);

        if(resData.code !== RCODE_S){
            throw new Error(resData.message);
        }

        this.userAuthList = resData.data.d0;
        this.userAuthListTotal = resData.data.tot;
    }

    
    /** 
     * 권한별 사용자 등록
     * */
    addAuthUser = async (para) => {
        let resData =  new ResData();

        try{
            resData = await sapi.send(apiz.addAuthUser, para );
        }
        catch(err){        
            resData.code = RCODE_E;
            resData.message = err.message;
        }

        return resData;
    }


    /* #endregion 권한 */

    /* #region 권한별 메뉴 */
    /** 
     * 권한별 메뉴 조회
     * */
    loadAuthMenuList = async (para) => {
        this.userAuthMenuList = [];

        const resData = await sapi.send(apiz.getAuthMenuAuthUid, para);

        if(resData.code !== RCODE_S){
            throw new Error(resData.message);
        }

        this.userAuthMenuList = resData.data.d0;
    }
    /* #region 권한별 메뉴 */
}

decorate(AuthStore, {
    user: observable,
    com: observable,
    auth: observable,
    conf: observable,
    page: observable,

    windowHeight: observable,
    history: observable,
    sideOpened: observable,
    menus: observable,
    menusAll: observable,
    menusOut: observable,
    menusFavi: observable,
    curMenu: observable,

    authList: observable,
    authListTotal: observable,
    userAuthList: observable,
    userAuthListTotal: observable,
    authDeptList: observable,
    authDeptListTotal: observable,
    userAuthMenuList: observable
});

export default createContext(new AuthStore())
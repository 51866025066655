const dotenv = require('dotenv');
dotenv.config();

const NODE_ENV = process.env.NODE_ENV;  // development, production
const PORT = process.env.PORT || 3000;
const SERVER_ENV = process.env.SERVER_ENV;  // development, production
const SERVER_URL = process.env.SERVER_URL;
const MGR_KEY = process.env.MGR_KEY;
const MGR_SALT = process.env.MGR_SALT;

module.exports = { NODE_ENV, PORT, SERVER_ENV, SERVER_URL, MGR_KEY, MGR_SALT}
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer, } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, CodeSelectBox, BizSelectBox,
    SrchButton, ActionButton, SiteLayout
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  외주입고 관리
 */
const SiteOutsInStndByPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodId, setProdId] = useState('');
    const [bizUid, setBizUid] = useState('');
    const [procTp, setProcTp] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "품번",
                width: "200px",
                item: 
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />  
            },
            {   title: "공정",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={procTp}
                    classId={'104'}
                    placeholder="공정을 선택하세요."
                    onValueChanged={(v) => { 
                        setProcTp(v);
                    }}
                />      
            },
            {   title: "출고업체",
                item: 
                <BizSelectBox
                    value={bizUid}
                    width={'200px'}
                    bizTp={[cmn.CMNCO_BIZ.PART]}
                    onLoadInfo={(o) => {

                    }} 
                    onValueChanged={(v) => {
                        setBizUid(v);
                    }}
                />   
            }
        ]
    ];
    /* #endregion */
    
    // * Grid
    const [outsInListStndBy, setOutsInListStndBy] = useState([]);
    const [outsInListStndByTot, setOutsInListStndByTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodId: prodId, 
                bizUid: bizUid, 
                procTp: procTp, 

                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getOutsInListStndBy", ps );
        if(r === undefined || r == null) return;

        setOutsInListStndBy(r.d0)
        setOutsInListStndByTot(r.tot);
    }
    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickIn = (e) => {
        if(util.chkListSelected(selectedItem)){
            // page mode: v(View), a(Add), u(Update)
            const url = `/manu/outsInpop?${qs.stringify({mode: 'a', ouid:selectedItem.outsOutUid})}`;        
            util.openWindow(url, 700, 500);    
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openCrudPopup = (mode, outsOutUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/manu/outsinpop?${qs.stringify({mode: mode, oouid: outsOutUid})}`;        
        util.openWindow(url, 700, 550);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <div id="SiteContWrap">  
                <GridTopView title="외주입고대기 현황">
                    <SrchButton 
                        id="srchButton"
                        type={"site-1"}
                        onClick={handleClickSearch}
                    />
                    <ActionButton
                        width={"140px"}
                    //   onClick={handleClickFstProc}
                        title="입고등록"
                        type={"site-1"}
                        show={true}
                    />
                </GridTopView>     
                <ListSearchView searchItems={searchItems} type="site" />
                <DataGridView            
                    dataSource={outsInListStndBy}
                    gridHeight={authStore.windowHeight - cmn.BH.H10}

                    page={curPage}
                    total={outsInListStndByTot}
                    itemsPerPage={rowsLength}
                    onClickPage={handleClickPage}
                    onChangePageSize={handleChangePageSize}

                    // sort
                    onCellClick={handleGridCellClick}

                    // select
                    onSelectionChanged={handleGridSelectionChanged} 

                    colsWidth={[cmn.CW.bizNm, 120, 150, null, null, null,120, 120, 100]}
                >

                    { cmn.getGridColumn('bizNm', '출고업체', renderGridHeaderCell)}
                    { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell)}
                    { cmn.getGridColumn('lot', 'Lot', renderGridHeaderCell)}
                    { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell)}
                    { cmn.getGridColumn('prodNm', '품명')}
                    { cmn.getGridColumn('prodStnd', '규격')}
                    { cmn.getGridColumnNumCommaType('stndByAmt', '입고대기수량') }
                    { cmn.getGridColumnNumCommaType('inAmt', '기입고수량') }
                    { cmn.getGridColumnPer('inPer', '입고율')} 
                </DataGridView>
            </div>
            </>
        );

    }

    return (
        <>
        <SiteLayout
            title={"외주입고대기"}
            content = { !pageLoad && renderContent() }
        />
        </>
    )
}

export default SiteOutsInStndByPage;
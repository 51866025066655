import React, { useState, useEffect, useContext } from 'react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    PrintLayout, ActionButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  금형 이력카드
 */
const MoldHisRepoPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        uid = ls.uid,
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [equpTp, setEqupTp] = useState('');
    const [equpTpNm, setEqupTpNm] = useState('');

    const [equpCd, setEqupCd] = useState('');
    const [equpNm, setEqupNm] = useState('');
    // const [modelNm, setModelNm] = useState('');
    const [equpStnd, setEqupStnd] = useState('');
    // const [maker, setMaker] = useState('');
    const [makeYr, setMakeYr] = useState('');
    const [buyDy, setBuyDy] = useState('');

    const [uph, setUph] = useState(0);
    const [moldImgFileInfo, setMoldImgFileInfo] = useState('');

    const [bkupParts, setBkupParts] = useState([]);
    const [equpFixs, setEqupFixs] = useState([]);


    // ...

    const [moldCd, setMoldCd] = useState('');
    const [moldNm, setMoldNm] = useState('');
    const [moldStnd, setMoldStnd] = useState('');
    const [moldWght, setMoldWght] = useState(null);
    const [saveLoca, setSaveLoca] = useState('');
    const [modelNm, setModelNm] = useState('');
    const [maker, setMaker] = useState('');
    const [makeDy, setMakeDy] = useState('');
    const [makeCost, setMakeCost] = useState(0);

    const [moldTpNm, setMoldTpNm] = useState('');
   
    const [outs, setOuts] = useState([]);
    const [manus, setManus] = useState([]);
    const [insps, setInsps] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    // useEffect(() => {
    //     loadInfo1();
    // },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {          
            if(util.getChkNull(uid) == '') return;

            const r = await cmn.api("getMoldHisRepo", {p: {moldUid: uid}} );
            if(r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
            const d2 = r.d2;
            const d3 = r.d3;
            
            setMoldCd(d0.moldCd);
            setMoldNm(d0.moldNm);
            setMoldStnd(d0.moldStnd);

            setMoldWght(d0.moldWght);
            setSaveLoca(d0.saveLoca);
            setModelNm(d0.modelNm);

            setMaker(d0.maker);
            setMakeDy(d0.makeDy);
            setMakeCost(d0.makeCost);

            setMoldTpNm(d0.moldTpNm);
            setMoldImgFileInfo(d0.moldImgFileInfo);

            setOuts(d1);
            setManus(d2);
            setInsps(d3);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {        
            printPage();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const printPage = async() => {

        window.print();
    }

    const customizeTooltip = (arg) => {
        return { text: arg.valueText };
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {
        
        return(
            <>
            <div className="no_print action btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={"인쇄하기"}
                    show={true}
                />
            </div>
            <div className="print_type"
                style={{ padding: "20px 20px 0 20px", backgroundColor: "#ffffff" }}
            >

                <table>
                    <colgroup>
						<col width={100} />
                        <col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon">
                                <div
                                    style={{ fontSize: "26px", fontWeight: "bold" }}
                                >
                                {`금형 이력 카드 (A)`}
                                </div>
                                <div>
                                {`출력일자: (${util.getDateToString('d',util.getNow(), '-')})`}
                                </div>
                            </td>                           
                        </tr>                        
                    </tbody>
                </table>

                <table>
                    <colgroup>
						<col width={100} />                        
                        <col  />
						<col width={100} />
                        <col width={100} />
                        <col width={338} />
                    </colgroup>
                    <tbody>                        
                    <tr>
                            <th className="tit">금형코드</th>
                            <td className="tcon" colSpan={3}>
                                <span>{moldCd}</span>
                            </td>
                            <td className="tcon"
                                rowSpan={8}
                                style={{paddingTop: 4, paddingBottom: 4}}
                            >
                                <div className="div-img-100" style={{minHeight: 240}}>
                                { 
                                    moldImgFileInfo != '' ?                                                
                                    cmn.getRenderImg(moldImgFileInfo, 320) 
                                    :
                                    <p>
                                        이미지 없음.
                                    </p>
                                }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">금형명</th>
                            <td className="tcon" colSpan={3}>
                                <span>{moldNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">규격</th>
                            <td className="tcon" colSpan={3}>
                                <span>{moldStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">금형유형</th>
                            <td className="tcon">
                                <span>{moldTpNm}</span>
                            </td>
                            <th className="tit">금형중량</th>
                            <td className="tcon">
                                <span>{ moldWght == null ? '' : `${util.getNumComma(moldWght)} (KG)`}</span>
                            </td>
                        </tr>   
                        <tr>
                            <th className="tit">보관위치</th>
                            <td className="tcon"colSpan={3}>
                                <span>{saveLoca}</span>
                            </td>
                        </tr>     
                        <tr>
                            <th className="tit">모델명</th>
                            <td className="tcon"colSpan={3}>
                                <span>{modelNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">제작처</th>
                            <td className="tcon"colSpan={3}>
                                <span>{maker}</span>
                            </td>
                        </tr>            
                        <tr>
                            <th className="tit">제작일자</th>
                            <td className="tcon">
                                <span>{util.getDateToString('d',makeDy, '-')}</span>
                            </td>
                            <th className="tit">제작금액</th>
                            <td className="tcon">
                                <span>{makeCost == null ? '' : `${makeCost} (원)`}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>                

                <div className="subTitle">
                    불출내역
                </div>
                <table>
                    <colgroup>
                        <col width={50} />
						<col width={80} />
                        <col width={80} />
						<col />
                        <col width={120} />
                        <col width={120} />
                        <col width={100} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: 46}} >No.</th>
                            <th className="tit">불출일자</th>
                            <th className="tit">회수일자</th>
                            <th className="tit">품명</th>
                            <th className="tit">공정</th>
                            <th className="tit">설비</th>
                            <th className="tit">생산타수</th>
                        </tr>
                        {
                            _.map(outs, (v, k) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 46}}
                                        >
                                            {k+1}</td>
                                        <td className="tcon">{util.getDateToString('d',v.outDt, '-')}</td>
                                        <td className="tcon">{util.getDateToString('d',v.inDt, '-')}</td>
                                        <td className="tcon">{v.prodNm}</td>                                        
                                        <td className="tcon">{v.procNm}</td>
                                        <td className="tcon">{v.equpNm}</td>
                                        <td className="tcon">{util.getNumComma(v.manuShot)}</td>
                                    </tr>    
                                )

                            })
                        }     
                        {
                            _.times(14 - outs.length, (n) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 46}}
                                        >
                                            </td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                    </tr>    
                                )
                            })
                        }   
                    </tbody>
                </table> 
                
                {/* <div className="print-bottom">
                    <table>
                        <colgroup>
                            <col width={160}/>
                            <col />
                            <col width={160}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>QF-000-000</td>
                                <td className="al-c">{authStore.com.name}</td>
                                <td className="al-r">A4 (210x297mm)</td>     
                            </tr>
                        </tbody>
                    </table> 
                </div>  */}

                <div className="endline"></div>
                <br style={{height:0, lineHeight: 0}} />

                <table>
                    <colgroup>
						<col width={100} />
                        <col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon">
                                <div
                                    style={{ fontSize: "26px", fontWeight: "bold" }}
                                >
                                {`금형 이력 카드 (B)`}
                                </div>
                                <div>
                                {`출력일자: (${util.getDateToString('d',util.getNow(), '-')})`}
                                </div>
                            </td>                           
                        </tr>                        
                    </tbody>
                </table>

                <div className="subTitle">
                    생산내역
                </div>  
                <table>
                    <colgroup>
                        <col width={50} />
						<col width={100} />
                        <col width={80} />
                        <col />
						<col width={100} />
                        <col width={120} />
                        {/* <col width={80} /> */}
                        <col width={80} />
                        <col width={80} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: 46}} >No.</th>
                            <th className="tit">작지번호</th>
                            <th className="tit">작업일자</th>
                            <th className="tit">품명</th>
                            <th className="tit">공정</th>
                            <th className="tit">설비</th>
                            {/* <th className="tit">작업자</th> */}
                            <th className="tit">생산타수</th>
                            <th className="tit">누적타수</th>
                        </tr>
                        {
                            _.map(manus, (v, k) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 46}}
                                        >
                                            {k+1}</td>
                                        <td className="tcon">{v.workNum}</td>
                                        <td className="tcon">{util.getDateToString('d',v.manuDy, '-')}</td>
                                        <td className="tcon">{v.prodNm}</td>                                        
                                        <td className="tcon">{v.procNm}</td>
                                        <td className="tcon">{v.equpNm}</td>
                                        {/* <td className="tcon">{v.userNm}</td> */}
                                        <td className="tcon">{util.getNumComma(v.manuShot)}</td>
                                        <td className="tcon">{util.getNumComma(v.totalShot)}</td>
                                    </tr>    
                                )

                            })
                        }   
                        {
                            _.times(20 - manus.length, (n) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 46}}
                                        >
                                            </td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        {/* <td className="tcon"></td> */}
                                        <td className="tcon"></td>                                        
                                        <td className="tcon"></td>
                                    </tr>    
                                )
                            })
                        } 
                    </tbody>
                </table> 

                {/* <div className="print-bottom">
                    <table>
                        <colgroup>
                            <col width={160}/>
                            <col />
                            <col width={160}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>QF-000-000</td>
                                <td className="al-c">{authStore.com.name}</td>
                                <td className="al-r">A4 (210x297mm)</td>     
                            </tr>
                        </tbody>
                    </table> 
                </div>  */}

                <div className="endline"></div>
                <br style={{height:0, lineHeight: 0}} />

                <table>
                    <colgroup>
						<col width={100} />
                        <col  />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="tcon"
                                style={{ height: 100 }}
                            >                   
                            { 
                                util.getChkNull(authStore.com.logo) != '' &&
                                <div>
                                    { cmn.getRenderImg(authStore.com.logo, 80) }
                                </div>
                            }
                            </td>
                            <td className="tcon">
                                <div
                                    style={{ fontSize: "26px", fontWeight: "bold" }}
                                >
                                {`금형 이력 카드 (C)`}
                                </div>
                                <div>
                                {`출력일자: (${util.getDateToString('d',util.getNow(), '-')})`}
                                </div>
                            </td>                           
                        </tr>                        
                    </tbody>
                </table>

                <div className="subTitle">
                    점검내역
                </div>  
                <table>
                    <colgroup>
                        <col width={50} />
						<col width={80} />
                        <col width={80} />
						<col width={100} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit" style={{height: 46}} >No.</th>
                            <th className="tit">점검일자</th>
                            <th className="tit">점검유형</th>
                            <th className="tit">점검타수</th>
                            <th className="tit">점검내용</th>
                        </tr>
                        {
                            _.map(insps, (v, k) => {

                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 46}}
                                        >
                                            {k+1}</td>
                                        <td className="tcon">{util.getDateStrSplit('d',v.inspDy)}</td>                                            
                                        <td className="tcon">{v.moldInspTpNm}</td>        
                                        <td className="tcon">{util.getNumComma(v.totalShot)}</td>
                                        <td className="tcon">{v.inspCont}</td>
                                    </tr>    
                                )

                            })
                        } 
                        {
                            _.times(20 - insps.length, (n) => {
                                return (
                                    <tr>
                                        <td className="tcon al-c"
                                            style={{height: 46}}
                                        >
                                            </td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>
                                        <td className="tcon"></td>                                        
                                        <td className="tcon"></td>
                                    </tr>    
                                )
                            })
                        }     
                    </tbody>
                </table> 

                {/* <div className="print-bottom">
                    <table>
                        <colgroup>
                            <col width={160}/>
                            <col />
                            <col width={160}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>QF-000-000</td>
                                <td className="al-c">{authStore.com.name}</td>
                                <td className="al-r">A4 (210x297mm)</td>     
                            </tr>
                        </tbody>
                    </table> 
                </div>  */}

            </div>
            </>
        )
    }

    return(
        <>
        <PrintLayout
            title = {"금형 이력카드"}
            content = {renderContent()}
        />
        </>
    )
}

export default MoldHisRepoPage;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { CheckBox, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    InspSampBox, DayDateBox, DateTimeBox, FileUploadBox,
    CodeSelectBox, ChkCycleChkListBox, UseYnSwitchBox, 
    UserSelectBox , EqupSelectBox ,
    PopupLayout, ActionButton, SubActionButton, GridTopView, DataGridView 
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';
import { Column } from 'devextreme-react/data-grid';

/** 
 *  금형 불출 CRUD 팝업
 */
const MoldMovePopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update) 
        uid = ls.uid,   // mold_move_his_uid
        mouid = ls.mouid // mold_uid
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */ 
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    // 금형정보
    const [moldCd, setMoldCd] = useState('');
    const [moldNm, setMoldNm] = useState('');
    const [moldStnd, setMoldStnd] = useState('');
    const [saveLoca, setSaveLoca] = useState('');
    const [modelNm, setModelNm] = useState('');
    const [limitShot, setLimitShot] = useState(0);
    const [mngShot, setMngShot] = useState(0);
    const [startShot, setStartShot] = useState(0);
    const [totalShot, setTotalShot] = useState(0);
    
    // 금형이동유형 moldMoveTp : 불출(40801), 회수(40802)  
    const [moldMoveTp, setMoldMoveTp] = useState('');    

    const [moldTpNm, setMoldTpNm] = useState('');
    const [moldMoveTpNm, setMoldMoveTpNm] = useState('');
    const [equpUid, setEqupUid] = useState('');

    const [prodProcs, setProdProcs] = useState([]);

    // 불출/회수 정보
    const [outDt, setOutDt] = useState(util.getDateToString('m', util.getNow(), '-'));
    const [inDt, setInDt] = useState(util.getDateToString('m', util.getNow(), '-'));
    const [outTotalShot, setOutTotalShot] = useState(null);
    const [inTotalShot, setInTotalShot] = useState(null);

    // const [moldMoveTotalShot, setMoldMoveTotalShot] = useState(0);
    // const [uptUserUid, setUptUserUid] = useState(authStore.user.uid);
    const [cmnt, setCmnt] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadMoldInfo();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /** 금형 정보 조회 */
    const loadMoldInfo = async() => {  
        try {            
            // if(mode == 'a') return;
            if(util.getChkNull(mouid) == '') return;

            const r = await cmn.api("getMold", {p: {moldUid: mouid}} );
            if(r == undefined || r == null) return;

            const d0 = r.d0[0];
            const d1 = r.d1;
            const d2 = r.d2.length > 0 ? r.d2[0] : null;

            setMoldCd(d0.moldCd);
            setMoldNm(d0.moldNm);
            setMoldStnd(d0.moldStnd);
            setSaveLoca(d0.saveLoca);
            setModelNm(d0.modelNm);
            setLimitShot(d0.limitShot);
            setMngShot(d0.mngShot);
            setStartShot(d0.startShot);
            setTotalShot(d0.totalShot); // 현재 타수 설정
            
            setOutTotalShot(d0.totalShot);
            setInTotalShot(d0.totalShot);

            // setMoldTpNm(d0.moldTpNm);
            // setMoldMoveTp(d0.moldMoveTp);

            setProdProcs(d1);
            
            if(util.getChkNull(uid) != '') {
                const rr = await cmn.api("getMoldMoveHis", {p: {moldMoveHisUid: uid}} );
                if(rr == undefined || rr == null) return;

                const dd0 = rr.d0[0];

                setOutDt(dd0.outDt);
                dd0.inDt != null && setInDt(dd0.inDt);

                setEqupUid(dd0.equpUid);

                setMoldMoveTp(dd0.moldMoveTp);
                setMoldMoveTpNm(dd0.moldMoveTpNm);

                dd0.outTotalShot != null && setOutTotalShot(dd0.outTotalShot);
                dd0.inTotalShot != null && setInTotalShot(dd0.inTotalShot);

                setCmnt(dd0.cmnt);
            }            
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = (e) => {
        try {
            if(!util.chkMustValue(equpUid, '설비')) return;
            // if(!util.chkMustValue(moldMoveTotalShot, '누적타수', 'cnt')) return;

            if(mode == "a") {
                if(!util.confirmYn(`${moldMoveTp == '40801' ? '회수등록' : '불출등록'}`)) return;                
            }
            else if(mode == "u") { 
                if(!util.confirmYn('수정')) return;
            }

            if(util.getChkNull(uid) == '') addMoldMove();
            else upMoldMove();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickDel = (e) => {
        try {
            if(!util.confirmYn('삭제')) return;
            delMoldMove();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const addMoldMove = async() => {        
        const ps = {
            p: 
                {
                    moldMoveHisUid: util.getUUID(),
                    moldUid: mouid,
                    moldMoveTp: '40801',
                    outDt: outDt,                    
                    equpUid: equpUid,
                    outTotalShot: outTotalShot,
                    cmnt: cmnt,
                    uptUserUid: authStore.user.uid
                }
        }

        const r = await cmn.api("addMoldMove", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("불출등록완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    const upMoldMove = async() => {        
        const ps = {
            p: 
                {
                    moldMoveHisUid: uid,
                    moldMoveTp: mode === 'a' ? '40802' : moldMoveTp,
                    outDt: util.getDateToString('m', outDt, '-'),
                    inDt: (mode === 'u' &&  moldMoveTp !== '40802') ? null : util.getDateToString('m', inDt, '-'),
                    equpUid: equpUid,
                    outTotalShot: outTotalShot,
                    inTotalShot: (mode === 'u' &&  moldMoveTp !== '40802') ? null : inTotalShot,
                    cmnt: cmnt,
                    uuptUserUid: authStore.user.uid
                }
        }

        const r = await cmn.api("upMoldMove", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert(mode === 'a' ? "회수등록완료" : "수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }    

    const delMoldMove = async() => {        
        const ps = {
            p: 
                {
                    moldMoveHisUid: uid
                }
        }

        const r = await cmn.api("delMoldMove", ps);
        if(r == undefined || r == null) return;

        util.cmnSucAlert("삭제완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }    

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        // 금형이동유형 moldMoveTp : 불출(40801), 회수(40802)        
        
        const completeButtonTitle = 
              mode == "a" ? `${moldMoveTp == '40801' ? '회수등록완료' : '불출등록완료'}`
            : mode == "u" ? "수정완료"
            : "" ;

            // : mode == "u" ? `${moldMoveTp == '40802' ? '회수수정완료' : '불출수정완료'}`
          
        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    width={140}
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    show={true}
                />
                <ActionButton 
                    title={"삭제완료"}
                    onClick={handleClickDel}
                    marginRight={0}
                    show={mode == "u" ? true : false}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={200}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">금형코드</th>
                            <td className="tcon">
                                <span>{moldCd}</span>
                            </td>
                            <th className="tit">금형유형</th>
                            <td className="tcon">
                                <span>{moldTpNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">금형명</th>
                            <td className="tcon">
                                <span>{moldNm}</span>
                            </td>
                            <th className="tit">규격</th>
                            <td className="tcon">
                                <span>{moldStnd}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">보관위치</th>
                            <td className="tcon">
                                <span>{saveLoca}</span>
                            </td>
                            <th className="tit">모델</th>
                            <td className="tcon">
                                <span>{modelNm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">타수</th>
                            <td className="tcon" colSpan={3}>
                                <span>{`보증: ${limitShot}  누적: ${totalShot}  시작: ${startShot}  관리: ${mngShot}`}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <GridTopView title="제품공정">   
            </GridTopView>
            <DataGridView         
                // reff={prodProcGrid}   
                dataSource={prodProcs}   // store
                // gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[
                    200, 180, 80, null                         
                ]}
                pagingable={false}
                
                columnResizingMode={'nextColumn'}
                // selection='multiple' 
                // onSelectionChanged={handleGridSelectionChanged}            
            >
                { cmn.getGridColumn('prodNm', '품명' ) }
                { cmn.getGridColumn('procNm', '공정') }
                { cmn.getGridColumn('cavity', '캐비티' ) }

                <Column />
                
            </DataGridView>
            <GridTopView 
                title={"불출정보"} 
                style={{marginTop: 16}}
            >   
            </GridTopView>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={200}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">불출일시</th>
                            <td className="tcon">
                                <DateTimeBox
                                    value={outDt}                                    
                                    onValueChanged={(dt) => { 
                                        setOutDt(dt);
                                    }}
                                />
                              </td>
                            <th className="tit">누적타수</th>
                            <td className="tcon">
                                <span>{util.getNumComma(outTotalShot)}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="tit req">설비</th>
                            <td className="tcon" colSpan={3}>
                                <EqupSelectBox
                                    width="200px"
                                    value={equpUid}
                                    lineYn={authStore.com.isLine ? 'Y' : 'N'}
                                    onLoadInfo={(o) => {
                                        // setEqup(o);
                                    }} 
                                    onValueChanged={(v) => {
                                        setEqupUid(v);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                            {
                                mode == "a" || mode == "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    // placeholder=""
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                            }
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            {
            (mode == 'a' && moldMoveTp == '40801' || mode == 'u' && moldMoveTp == '40802') &&   
            <>
            <GridTopView 
                title={"회수정보"} 
                style={{marginTop: 16}}
            >   
            </GridTopView>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" />
						<col className="tit" />
						<col className="tcon" width={200}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">회수일시</th>
                            <td className="tcon">
                                <DateTimeBox
                                    value={inDt}                                    
                                    onValueChanged={(dt) => { 
                                        setInDt(dt);
                                    }}
                                />
                              </td>
                            <th className="tit">누적타수</th>
                            <td className="tcon">
                                <span>{util.getNumComma(inTotalShot)}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
            }
            
            </>
        )   
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode == "a" ? "금형 불출/회수" 
                : mode == "u" ? "금형 불출/회수"
                : "금형 불출/회수" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default MoldMovePopPage;
import React, { useState, useEffect, useContext } from 'react';
import { observer, Observer, useObservable, useObserver } from 'mobx-react-lite';
import { CheckBox, SelectBox, TextBox, RadioGroup } from 'devextreme-react';
import DataGrid, { Column, Paging, Pager, Selection, Sorting } from 'devextreme-react/data-grid';
import qs from 'query-string';
import _ from 'lodash';

/** components */
import {
    IconButton, DayDateBox, SrchButton, EqupChkBox, UserSelectBox,
    ActionButton, SiteLayout, 
    GridTopView, ListSearchView, DataGridView, EditGridView
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { RCODE_S, util, cmn } from '../../lib';

/** 
 *  설비 일상 점검 등록(현장)
 */
const SiteEqupDayChkCrtPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);
    
    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        // mode = ls.mode,   // mode: v(View), a(Add), u(Update)

        uid = ls.uid,   // equp uid
        chktp = ls.chktp, // check tp
        tmtp = ls.tmtp, // time tp ... 주간/야간
        chkdy = ls.chkdy
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    /** Items */
    const [equpUid, setEqupUid] = useState(uid);    
    const [equpNm, setEqupNm] = useState('');    

    const [equpChkUid, setEqupChkUid] = useState('');

    const [chkCycleTp, setChkCycleTp] = useState(chktp);
    const [chkCycleTpNm, setChkCycleTpNm] = useState('');

    const [timeTp, setTimeTp] = useState(tmtp);
    const [timeTpNm, setTimeTpNm] = useState('');

    const [chkCycleObj, setChkCycleObj] = useState(null);

    const [wk, setWk] = useState(0);
    const [dd, setDd] = useState(0);
    const [startDy, setStartDy] = useState('');
    
    const [chkCycleDy, setChkCycleDy] = useState(chkdy);
    const [chkDy, setChkDy] = useState(util.getDateStrSplit('d',chkdy, '-'));
    const [chkDt, setChkDt] = useState('');

    const [equpImgUid, setEqupImgUid] = useState('');
    const [equpFileInfo, setEqupFileInfo] = useState('');

    const [chkImgUid, setChkImgUid] = useState('');
    const [chkUserUid, setChkUserUid] = useState('');
    const [chkUserNm, setChkUserNm] = useState('');
    
    const [chkItems, setChkItems] = useState([]);

    const [chkCycleItems, setChkCycleItems] = useState([]);

    useEffect(() => {
        loadPage();
    },[]);

    useEffect(() => {
        loadInfo1();
    },[uid, chkCycleTp, chkdy]); 

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {

        try {
            await authStore.loadSitePage(props);
            await loadChkCyleItems();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const loadChkCyleItems = async() => {
        const r1 = await cmn.api("getEqupChkCycleEqup", {p: {equpUid: equpUid}});
        setChkCycleItems(r1.d0);
    }

    const loadInfo1 = async() => {

        // console.log("--- props", props);
        const r = await cmn.api("getEqupChkEqup"
                    , {p: {equpUid: uid, chkCycleTp: chkCycleTp, chkCycleDy: chkdy
                            , timeTp: tmtp === undefined ? '' : tmtp}});
                            
        if(r == null) return;

        // console.log("--- r", r);
        const d = r.d0[0];
        
        setEqupNm(d.equpNm);
        setEqupChkUid(d.equpChkUid);
        
        // setChkCycleTp(d.chkCycleTp);
        // setChkCycleTpNm(d.chkCycleTpNm);

        if(tmtp === '') {
            setTimeTp(d.timeTp);
            setTimeTpNm(d.timeTpNm);
        }

        setWk(d.wk);
        setDd(d.dd);
        setStartDy(d.startDy);
        // setChkCycleObj({chkCycleTp: d.chkCycleTp, wk: d.wk, dd: d.dd, startDy: d.startDy});

        // if(util.getChkNull(d.equpChkUid) != '') setChkCycleDy(d.chkCycleDy);

        // setChkDy(d.chkDy);
        setChkDt(d.chkDt);

        setEqupImgUid(d.equpImgUid);
        setEqupFileInfo(d.equpFileInfo);

        setChkImgUid(d.chkImgUid);
        setChkUserUid(d.chkUserUid);
        setChkUserNm(d.chkUserNm);

        setChkItems(r.d1);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickAddChk = async(e) => {
        addEqupChk();
    }

    const addEqupChk = async() => {
        const ps = {
            p: {
                    equpChkUid: equpChkUid,
                    equpUid: equpUid,
                    chkCycleTp: chkCycleTp,
                    timeTp: timeTp,
                    wk: wk,
                    dd: dd,
                    startDy: startDy,
                    chkCycleDy: chkCycleDy,
                    chkDy: util.getDateToString('d',chkDy),
                    chkDt: chkDt,
                    chkUserUid: chkUserUid,
                    dtls: chkItems
            }
        }

        console.log(ps);
        const r = await cmn.api("addEqupChk", ps);
        if(r == undefined || r == null) return;

        // alert("success");
        cmn.successToast(cmn.MSG_SUCCESS_ADD);

        // // Reload Opener List & Close Popup
        // util.clickOpenerElement("srchButton");
        // window.close();
    }

    const handleClickCancel = async() => {
        authStore.history.push('/site/siteequpdaychk');
    }

    /* ====================
     *  Render
     * ==================== */
      
    const renderContent = () => {
        
        return (
            <>
            <div id="SiteContWrap">           
            <div className="site_list_type" >
                <table>
                    <colgroup>
                        <col width={160} />
                        <col />
                        <col width={240} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit">점검일</th>
                            <td className="tcon">{chkDy}</td>
                            <td className="tcon" rowSpan={6}>
                                { cmn.getDispImg(equpFileInfo, 220, 220 ) }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">설비명</th>
                            <td className="tcon">{equpNm}</td>                            
                        </tr>
                        <tr>
                            <th className="tit">점검자</th>
                            <td className="tcon">
                                <UserSelectBox
                                    width={200}
                                    value={chkUserUid}
                                    type={'site'}
                                    onValueChanged={(v) => { 
                                        setChkUserUid(v);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">점검주기</th>
                            <td className="tcon">
                                <RadioGroup 
                                    value={chkCycleTp}
                                    dataSource={chkCycleItems} 
                                    displayExpr={'chkCycleTpNm'}
                                    valueExpr={'chkCycleTp'}
                                    layout="horizontal"
                                    onValueChanged={(e) => { 
                                        setChkCycleTp(e.value);
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <GridTopView title="점검내용">
                <ActionButton 
                    title="점검등록"
                    width={"160px"}
                    type={"site-1"}
                    onClick={handleClickAddChk}
                    show={true}
                />
                <ActionButton 
                    title="목록으로"
                    type={"site-1"}
                    width={"160px"}
                    onClick={handleClickCancel}
                    show={true}
                />
            </GridTopView>
            <EqupChkBox
                mode={'a'}
                type={'site'}
                chkItems={chkItems}
                onValueChanged={(v)=>{
                    // console.log("--- v", v);
                    setChkItems(v);
                    // chkItems = v;
                }} 
            />    
            {/* <EditGridView
                dataSource={chkItems}   // store
                // colsWidth={[cmn.CW.lot, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, cmn.CW.amt, null]}
                // onRowUpdated={(e) => {loadTotOutAmt();}}
                colsWidth={[80, 120, 260, 100, 160, 160, 100, null]}
            >

                { cmn.getGridColumn('sortOrder', '순서') }
                { cmn.getGridColumnImg('chkFileInfo', '점검부위') }
                <Column 
                    alignment={'center'}
                    caption={'점검내용'}
                    allowEditing={false} 
                    cellRender={(d) => {
                        return (
                            <>
                            <ul>
                                <li>
                                    <span>{d.data.chkNm}</span>
                                </li>
                                <li>
                                    <span>{d.data.chkCont}</span>
                                </li>
                            </ul>
                            </>
                        )
                    }} 
                />
                { cmn.getGridColumn('chkTpNm', '점검구분') }
                <Column 
                    alignment={'center'}
                    caption={'규격'}  
                    allowEditing={false}
                    cellRender={(d) => {
                        return (
                            <>
                            { cmn.getDispEqupChkStnd(d.data.valSelectYn, d.data.upperVal, d.data.lowerVal) } 
                            </>
                        )
                    }} 
                />
                <Column 
                    alignment={'center'}
                    dataField={'chkVal'} 
                    caption={'점검'}  
                    dataType={"number"}
                    // editCellRender={(d) => {
                >
                    <Lookup dataSource={goodBadTps} valueExpr="codeId" displayExpr="codeNm" />
                </Column>
                <Column 
                    alignment={'center'}
                    caption={'점검결과'}  
                    allowEditing={false}
                    cellRender={(d) => {
                        return cmn.getDispEqupGoodBadTp(d.data.goodBadTp);
                    }} 
                />
                <Column />
            </EditGridView> */}

            </div>
            </>  
        )
    }

    return (
        <>
        <SiteLayout
            title={"설비 일상 점검"}
            content = {renderContent()}
        />
        </>
    )
}

export default SiteEqupDayChkCrtPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer,  } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    UseYnSelectBox, CodeSelectBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  공통코드 관리
 */
const ProdPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [prodTp, setProdTp] = useState('');
    const [prodId, setProdId] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [useYn, setUseYn] = useState('');
    
    /* #region Search Items */
    const searchItems = [
        [
            {   title: "제품유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={prodTp}
                    classId={'101'}
                    placeholder="제품유형을 선택하세요."
                    onValueChanged={(v) => { 
                        setProdTp(v);
                    }}
                />
            },
            {   title: "품명",
                width: "200px",
                item: 
                <TextBox 
                    value={prodNm}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />      
            },
            {   title: "품번",
                width: "200px",
                item: 
                <TextBox 
                    value={prodId}
                    showClearButton={true}
                    placeholder="품번으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdId(e.value);
                    }}
                />      
            },
            {   title: "사용여부",
                item: 
                <UseYnSelectBox
                    value={useYn}
                    onValueChanged={(e)=>{
                        setUseYn(e.value);
                    }}
                />
            }
        ]
    ];
    /* #endregion */

    // * Grid
    const [prodList, setProdList] = useState([]);
    const [prodListTot, setProdListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, prodTp, useYn]);

    /* ====================
     *  Load
     * ==================== */
  
    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                prodTp: prodTp, 
                prodNm: prodNm, 
                prodId: prodId,
                useYn: useYn, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getProdList", ps );
        if(r === undefined || r == null) return;

        setProdList(r.d0)
        setProdListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickAdd = (e) => {
        openCrudPopup("a", "0");
    }

    const handleClickUp = (e) => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("u", selectedItem.prodUid);
        }
    }

    const handleClickView = async () => {
        if(util.chkListSelected(selectedItem)){
            openCrudPopup("v", selectedItem.prodUid);
        }
    }


    const handleClickProdHis = async () => {
        if(util.chkListSelected(selectedItem)){
            const url = `/base/prodhispop?${qs.stringify({uid: selectedItem.prodUid})}`;
            util.openWindow(url, 800, 1000);    
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        // if(     gc.column.dataField === "buyDy" 
        //     ||  gc.column.dataField === "lastCalibDy" 
        //     ||  gc.column.dataField === "nextCalibDy" 
        //     ) e.value =util.getDateStrSplit('d',gc.value);
    }

    // * Functions
    const openCrudPopup = (mode, prodUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/base/prodpop?${qs.stringify({mode: mode, uid: prodUid})}`;        
        util.openWindow(url, 800, 1000);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }
 
    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                />
                <ActionButton 
                    onClick={handleClickProdHis}
                    title="이력관리"
                    marginRight={0}
                    show={authStore.page.viewYn}
                />
            </ListTopView>       
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="제품 내역"
            >
                {/* <IconButton
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>            
                    
            <DataGridView   
                reff={grid1}         
                dataSource={prodList}  
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={prodListTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}        
                // excel
                exlnm={'제품내역'}
                customizeExcelCell={customizeExcelCell}        

                colsWidth={[80, cmn.CW.prodNm, cmn.CW.prodId, 100, 100, cmn.CW.amt, cmn.CW.amt, 60, cmn.CW.yn, 70, 100, null]}
            >
                { cmn.getGridColumn('prodTpNm', '제품유형', renderGridHeaderCell) }
                {/* { cmn.getGridColumn('prodCateTpNm', '제품분류', renderGridHeaderCell) } */}
                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('safeAmt', '안전재고') }
                { cmn.getGridColumnNumCommaType('unitAmt', '단위수량') }
                { cmn.getGridColumn('unitTpNm', '단위', renderGridHeaderCell) }
                { cmn.getGridColumn('lotAmt', 'Lot정량', renderGridHeaderCell) }
                { cmn.getGridColumnUseYn() }
                { cmn.getGridColumnFiles('fileInfo', '제품도면') }
                { cmn.getGridColumn('storNm', '기본창고') }
                { cmn.getGridColumnCmnt() }
            </DataGridView>
            </>
        );

    }

    return (
        <>
         { !pageLoad && renderContent() }
        </>
    )
}

export default ProdPage;
import React, { useState, useEffect, useContext } from 'react';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
//import _ from 'lodash';

/** components */
import {
    PopupLayout, 
    ActionButton,
    UseYnSwitchBox,
} from '../../components';


/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  권한 등록 / 수정 / 상세보기
 */
const AuthPopPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);  

    /* ====================
     *  Object
     * ==================== */

    const {
        location,
        ls = qs.parse(location.search),
        mode = ls.mode,   // mode: v(View), a(Add), u(Update)
        uid = ls.uid,
    } = props;

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    /** Items */
    const [authNm, setAuthNm] = useState('');
    const [useYn, setUseYn] = useState('Y');
    const [cmnt, setCmnt] = useState('');

    useEffect(() => {
        loadPage();
    },[]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPopPage(props);
            await loadInfo1();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadInfo1 = async() => {
        try {  
            if(mode === 'a') return;
            if(util.getChkNull(uid) === '') return;

            const r = await cmn.api("getAuth", {p: {authUid: uid}} );
            const data = r.d0[0];
            
            setAuthNm(data.authNm);
            setUseYn(data.useYn);
            setCmnt(data.cmnt);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }


    /* ====================
     *  Handle / Functions
     * ==================== */

    const handleClickComplete = () => {
        try {
            // validation 체크
            if(!util.chkMustValue(authNm, '권한명'))
                return;

            if(mode === "a")
            { 
                if(util.confirmYn("등록"))
                    addAuth();
            }
            else if(mode === "u") 
            {
                if(util.confirmYn("수정"))
                    upAuth();
            }else if(mode === "v") 
            {
                if(util.confirmYn("삭제"))
                    delAuth();
            }
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    //권한 등록
    const addAuth = async() => {
        const ps = {
            p: {
                authUid: util.getUUID(),
                comUid: authStore.com.uid,
                authNm: authNm,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }
        
        const r = await cmn.api("addAuth", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert('등록완료');
        // Reload Opener List & Close Popup
        util.clickOpenerElement("srchButton");
        window.close();
    }

    //권한 수정
    const upAuth = async() => {
        const ps = {
            p: {
                authUid: uid,
                authNm: authNm,
                useYn: useYn,
                cmnt: cmnt,
                uptUserUid: authStore.user.uid
            }
        }

        const r = await cmn.api("upAuth", ps);
        if(r === undefined || r == null) return;

        util.cmnSucAlert("수정완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    //권한삭제
    const delAuth = async() => {
        const ps =  {
            p: {authUid: uid}
        }

        let r = await cmn.api('delAuth', ps);
        if(r == null || r === undefined) return;

        util.cmnSucAlert("삭제완료");
        util.clickOpenerElement("srchButton");
        window.close();
    }

    /* ====================
     *  Render
     * ==================== */

    const renderContent = () => {

        const completeButtonTitle = 
              mode === "a" ? "등록완료" 
            : mode === "u" ? "수정완료"
            : mode === "v" ? "삭제완료"
            : "" ;

        return(
            <>
            <div className="btn_r mgb10">
                <ActionButton 
                    onClick={handleClickComplete}
                    title={completeButtonTitle}
                    marginRight={0}
                    show={true}
                />
            </div>
            <div className="board_list_type">
                <table>
                    <colgroup>
                        <col className="tit" />
						<col className="tcon" width={'33%'} />
						<col className="tit" />
						<col className="tcon" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="tit req">권한명</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <TextBox 
                                        value={authNm}
                                        showClearButton={true}
                                        placeholder="권한명을 입력하세요"
                                        valueChangeEvent={'keyup'}
                                        onValueChanged={(e)=>{
                                            setAuthNm(e.value);
                                        }} 
                                    />
                                :
                                    <span>{authNm}</span>
                                }
                            </td>
                            <th className="tit">사용여부</th>
                            <td className="tcon">
                                {
                                mode === "a" || mode === "u" ?
                                    <UseYnSwitchBox 
                                        value={useYn}
                                        onValueChanged={(v)=>{
                                            setUseYn(v);
                                        }} 
                                    />
                                : 
                                    <span>{useYn}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className="tit">비고</th>
                            <td className="tcon" colSpan={3}>
                                {
                                mode === "a" || mode === "u" ?
                                <TextBox 
                                    value={cmnt}
                                    showClearButton={true}
                                    valueChangeEvent={'keyup'}
                                    onValueChanged={(e)=>{
                                        setCmnt(e.value);
                                    }}
                                /> 
                                :
                                    <span>{cmnt}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }

    return(
        <>
        <PopupLayout
            title = {
                  mode === "a" ? "권한 등록" 
                : mode === "u" ? "권한 수정"
                : mode === "v" ? "권한 상세보기"
                : "권한 상세보기" 
            }
            content = {renderContent()}
        />
        </>
    )
}

export default AuthPopPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    CodeSelectBox, 
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  금형 점검 내역
 */
const MoldInspPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    //...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [moldCd, setMoldCd] = useState('');
    const [moldNm, setMoldNm] = useState('');

    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "금형코드",
                width: "200px",
                item: 
                <TextBox 
                    width="200px"
                    value={moldCd}
                    showClearButton={true}
                    placeholder="금형코드로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setMoldCd(e.value);
                    }}
                />   
            },
            {   title: "금형명",
                item: 
                <TextBox 
                    width="200px"
                    value={moldNm}
                    showClearButton={true}
                    placeholder="금형명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setMoldNm(e.value);
                    }}
                />                      
            }
        ]
    ];
    /* #endregion */    

    // * Grid
    const [moldInspHisList, setMoldInspHisList] = useState([]);
    const [moldInspHisListTot, setMoldInspHisListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                moldCd: moldCd, 
                moldNm: moldNm, 
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getMoldInspHisList", ps );
        if(r === undefined || r == null) return;

        setMoldInspHisList(r.d0)
        setMoldInspHisListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }
    
    const handleClickUp = (e) => {
        if(!util.chkListSelected(selectedItem, '금형 점검 내역을')) return;
        openMoldInspCrudPopup("u", selectedItem.moldInspHisUid, selectedItem.moldUid);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType != "data") return;
        if(gc.column.dataField == "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField == "useYn" 
            ) e.value = gc.value == 'Y' ? "○" : "";
        else if(
                gc.column.dataField == "equpImgFile" 
            ||  gc.column.dataField == "chkImgFile" 
        )   e.value = '';
    }  

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions

    const openMoldInspCrudPopup = (mode, uid, moldUid) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/mold/moldinsppop?${qs.stringify({mode: mode, uid: uid, mouid: moldUid})}`;        
        util.openWindow(url, 680, 740);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {

        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                    show={authStore.page.crtYn}
                />   */}
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"                    
                    show={authStore.page.upYn}
                />
            </ListTopView>   
            <ListSearchView searchItems={searchItems} />
            
            <GridTopView
                title="금형 점검 내역"
            >
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <DataGridView   
                reff={grid1}              
                dataSource={moldInspHisList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[100, 80
                    , cmn.CW.moldCd, cmn.CW.moldCd, 80, 100
                    , cmn.CW.prodNm, cmn.CW.procNm
                    , cmn.CW.amt, null]}
                // paging
                page={curPage}
                total={moldInspHisListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged} 
                // excel
                exlnm={'금형점검내역'}
                customizeExcelCell={customizeExcelCell}                
            >
                { cmn.getGridColumnDayType('inspDy', '점검일자', renderGridHeaderCell ) }
                { cmn.getGridColumn('moldInspTpNm', '점검유형', renderGridHeaderCell ) }

                { cmn.getGridColumn('moldCd', '금형코드', renderGridHeaderCell ) }
                { cmn.getGridColumn('moldNm', '금형명', renderGridHeaderCell ) }
                { cmn.getGridColumn('moldTpNm', '유형', renderGridHeaderCell ) }
                { cmn.getGridColumn('moldStnd', '규격', renderGridHeaderCell ) }

                { cmn.getGridColumn('prodNm', '품명', renderGridHeaderCell ) }
                { cmn.getGridColumn('procNm', '공정', renderGridHeaderCell ) }

                { cmn.getGridColumnNumCommaType('totalShot', '점검타수', renderGridHeaderCell) } 
                { cmn.getGridColumn('inspCont', '점검내용', renderGridHeaderCell ) }               
                
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default MoldInspPage;
const workApiz = {
    
    getWorkList: {
        title: "작업지시 목록 조회",
        url: "/api/work/getWorkList",
        method: "POST"
    },
    getWorkListSales: {
        title: "작업지시분석(수주) 목록 조회",
        url: "/api/work/getWorkListSales",
        method: "POST"
    },
    // getWorkSalseAssyList: {
    //     title: "작업지시분석(수주조립품) 목록 조회",
    //     url: "/api/work/getWorkSalesAssyList",
    //     method: "POST"
    // },
    // getWorkStocSingleList: {
    //     title: "작업지시분석(재고단품) 목록 조회",
    //     url: "/api/work/getWorkStocSingleList",
    //     method: "POST"
    // },
    getWorkListStock: {
        title: "작업지시분석(재고조립품) 목록 조회",
        url: "/api/work/getWorkListStock",
        method: "POST"
    },
    getWork: {
        title: "작업지시 상세 조회",
        url: "/api/work/getWork",
        method: "POST"
    },
    getWorkWn: {
        title: "작업지시 조회(작지번호)",
        url: "/api/work/getWorkWn",
        method: "POST"
    },
    addWork: {
        title: "작업지시 등록",
        url: "/api/work/addWork",
        method: "POST"
    },
    upWork: {
        title: "작업지시 수정",
        url: "/api/work/upWork",
        method: "POST"
    },
    delWork: {
        title: "작업지시 삭제",
        url: "/api/work/delWork",
        method: "POST"
    },
    
  
    
}

export default workApiz;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView,
    CodeSelectBox, DayDateBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore} from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  검교정 이력현황
 */
const CalibHisPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
     
    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);     

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);

    // * 검색
    const [gaugeTp, setGaugeTp] = useState('');
    const [gaugeNm, setGaugeNm] = useState('');
    
    const [startDy, setStartDy] = useState(util.getAddDate(-30));
    const [endDy, setEndDy] = useState(util.getNow());    

    /* #region Search Items */
    const searchItems = [
        [
            {   
                title: "교정일",
                width: "200px",
                item: 
                <>
                <ul className="ul-row">
                    <li>
                        <DayDateBox 
                            value={startDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setStartDy(e.value);
                            }}                    
                        />
                    </li>
                    <li>
                        ~
                    </li>
                    <li>            
                        <DayDateBox 
                            value={endDy}
                            displayFormat={cmn.DATE_FORMAT_DAY_1}
                            onValueChanged={(e) => {
                                setEndDy(e.value);
                            }}                    
                        />
                    </li>
                </ul>
                </>
            },
            {   
                title: "측정기유형",
                width: "200px",
                item: 
                <CodeSelectBox
                    value={gaugeTp}
                    classId={'405'}
                    placeholder="측정기유형을 선택하세요."
                    onValueChanged={(v, o) => { 
                        setGaugeTp(v);
                    }}
                />
            },
            {   title: "측정기명",
                // width: "200px",
                item: 
                <TextBox 
                    width={"180px"}
                    value={gaugeNm}
                    showClearButton={true}
                    placeholder="측정기명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setGaugeNm(e.value);
                    }}
                />        
            }
            
        ]
    ];

    // * Grid
    const [gaugeCalibList, setGaugeCalibList] = useState([]);
    const [gaugeCalibListTot, setGaugeCalibListTot] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, gaugeTp]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);  
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                gaugeTp: gaugeTp, 
                gaugeNm: gaugeNm, 
                startDy: util.getDateToString('d',startDy),
                endDy: util.getDateToString('d',endDy),
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getGaugeCalibList", ps );
        if(r === undefined || r == null) return;

        setGaugeCalibList(r.d0)
        setGaugeCalibListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickUp = (e) => {
        if(!util.chkListSelected(selectedItem, '수정대상 교정 이력을')) return;
        openCalibPopup(selectedItem.gaugeUid, selectedItem.calibUid);
    }

    const handleClickDel = async() => {
        try {
            if(!util.chkListSelected(selectedItem, '삭제대상 교정 이력을')) return;
            if(!util.confirmYn('삭제')) return;

            const r = await cmn.api("delGaugeCalib", {params: [{calibUid: selectedItem.calibUid}]});
            if(r == undefined) return;

            util.cmnSucAlert("삭제완료");
            handleClickSearch();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // ** Excel
    const handleClickExcel = (e) => {
        try {
            cmn.xlsNo = 0;
            grid1.current.instance.exportToExcel();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType != "data") return;
        if(gc.column.dataField == "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField == "calibDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
        else if(
            gc.column.dataField == "certFile" 
        )   e.value = '';
    }    

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length == 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // * Functions
    const openCalibPopup = (gaugeUid, calibUid) => {
        const url = `/equp/gaugecalibpop?${qs.stringify({uid: gaugeUid, cuid: calibUid})}`;        
        util.openWindow(url, 720, 560);    
    }

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <Observer>{() =>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}                    
                />
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    show={authStore.page.upYn}
                />
                <ActionButton 
                    onClick={handleClickDel}
                    title="삭제"
                    marginRight={0}
                    show={authStore.page.upYn}
                />
            </ListTopView> 
            }</Observer>      
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="교정 내역"
            >
                {/* <IconButton
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <Observer>{() =>
            <>        
            <DataGridView    
                reff={grid1}                    
                dataSource={gaugeCalibList}  
                gridHeight={authStore.windowHeight - cmn.BH.H10}
                colsWidth={[cmn.CW.dy
                    , 120, 160, 200
                    , 120, 120, 100, 100, 120, null]}
                // paging
                page={curPage}
                total={gaugeCalibListTot} 
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}
                // sort
                onCellClick={handleGridCellClick}
                // select
                onSelectionChanged={handleGridSelectionChanged}       
                // excel
                exlnm={'교정이력현황'}
                customizeExcelCell={customizeExcelCell}                              
            >
                { cmn.getGridColumnDayType('calibDy', '교정일', renderGridHeaderCell) }

                { cmn.getGridColumn('gaugeTpNm', '측정기유형', renderGridHeaderCell ) }
                { cmn.getGridColumn('gaugeCd', '측정기코드', renderGridHeaderCell ) }
                { cmn.getGridColumn('gaugeNm', '측정기명', renderGridHeaderCell ) }
                
                { cmn.getGridColumn('calibRet', '교정결과', renderGridHeaderCell ) } 

                { cmn.getGridColumn('calibComNm', '교정처', renderGridHeaderCell ) } 
                { cmn.getGridColumnNumCommaType('calibCost', '교정비용', renderGridHeaderCell ) } 
                { cmn.getGridColumn('calibSize', '교정치수', renderGridHeaderCell ) } 
                
                { cmn.getGridColumnFiles('certFile', '성적서') }
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
            }</Observer>
            </>
        );

    }

    return (
        <>
         { !pageLoad && renderContent() }
        </>
    )
}

export default CalibHisPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import qs from 'query-string';
import useKey from 'use-key-hook';
import _ from 'lodash';

/** components */
import {
    ListSearchView, ListTopView, CalendarView,
    EqupSelectBox, DayDateBox,
    SrchButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  생산시간 관리
 */
const ManuTimeDayPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */

    const authStore = useContext(AuthStore);
    const cmnStore = useContext(CmnStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * Control Items (* Combo ...)

    // * 검색
    const [chkMon, setChkMon] = useState(util.getNowMon());
    const [equpUid, setEqupUid] = useState('');
    const [chkItems, setChkItems] = useState([]);
    const [dys, setDys] = useState([]);

    /* #region Search Items */
    const searchItems = [
        [
            {   title: "생산월",
                width: "200px",
                item: 
                    <DayDateBox 
                        value={chkMon}
                        type={'month'}
                        displayFormat={cmn.DATE_FORMAT_MON_1}
                        onValueChanged={(e) => {
                            setChkMon(e.value);
                        }}                    
                    />
            }
        ]
    ];

    /* #endregion */    
    
    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });
    
    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [chkMon, equpUid]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {
            await authStore.loadPage(props);
            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => { 

        const ps2 =  {
            p: { yrMon: util.getDateToString('M', chkMon)}
        }
        const r2 = await cmn.api("getManuTimeDay", ps2);
        if(r2 == null) return;
        
        
        const ps = { p: { yrMon: util.getDateToString('M', chkMon) } }  
        const r = await cmn.api("getDyMon", ps);
        console.log(r.d0);

        setDys(r.d0);
        setChkItems(r2.d0);
        
    }
    
    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions
    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    // * Functions

    const openCrudPopup = (uid, dy, tm) => {
        // page mode: v(View), a(Add), u(Update)
        const url = `/manu/manutimedaypop?${qs.stringify({ mode: 'u', uid: uid, dy: dy, tm: tm })}`; 
        util.openWindow(url, 500, 250);    
    }

    /* ====================
     *  Render
     * ==================== */
    /** 설비점검 Data Rendering */
    const renderDataEqupChk = (dy) => {        
        if(util.getChkNull(chkMon) === '' || util.getChkNull(chkItems) === '') return;
        const o = _.find(chkItems, {manuDy: dy});
        if(util.getChkNull(o) === '') return;
        return (
            <>
            <ul>
                <li>
                    <a
                        href="#"
                        className={'btn-chk'}
                        onClick={(e) => {
                            e.preventDefault();
                            openCrudPopup(o.manuTimeDayUid, o.manuDy, o.manuTm);
                        }}
                    >
                        생산시간등록
                    </a>
                </li>
                <li>
                        {`${o.manuTm} 시간`}
                </li>
            </ul>            
            </>
        )
    }

    const renderWkSeq = (wkSeq) => {
        return (
            <>
            <div className="wk-seq">
                <span>{wkSeq}</span>
            </div>
            </>
        )
    }

    
    const renderDayCell = (dy) => {
        const yrMon_ = util.getDateToString('M', chkMon);
        const o =  _.split(dy, '^'); // dy^yr_mon^dd^wk 
        return (
            <>
            <div className="dy-cell">
                <div className={`disp-dy fl-r`}>
                    <span className={`${util.isToday(o[0]) ? 'today' : yrMon_ != o[1] ? 'diff-mon' : ''}`}>
                        {o[2]}
                    </span>
                </div>
                <div className="disp-info">
                    {renderDataEqupChk(o[0])}
                </div>
            </div>
            </>
        )
    }


    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                {/* <ActionButton 
                    onClick={handleClickAdd}
                    title="등록"
                />  
                <ActionButton 
                    onClick={handleClickUp}
                    title="수정"
                    marginRight={0}
                /> */}
            </ListTopView>       
            <ListSearchView searchItems={searchItems} /> 

            <div className="cal-vw">
                <table>
                    <colgroup>
                        <col width={"9%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
                        <col width={"13%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
						<col width={"13%"}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="b-left">주차</th>
                            <th>월</th>
                            <th>화</th>
                            <th>수</th>
                            <th>목</th>
                            <th>금</th>
                            <th>토</th>
                            <th>일</th>
                        </tr>
                        {
                            _.map(dys, (v, k) => {
                                return(
                                    <tr key={k}>
                                        <td className="b-left">{renderWkSeq(v.wkSeq)}</td>
                                        <td>{renderDayCell(v.wk2)}</td>
                                        <td>{renderDayCell(v.wk3)}</td>
                                        <td>{renderDayCell(v.wk4)}</td>
                                        <td>{renderDayCell(v.wk5)}</td>
                                        <td>{renderDayCell(v.wk6)}</td>
                                        <td>{renderDayCell(v.wk7)}</td>
                                        <td>{renderDayCell(v.wk1)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            </>


        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default ManuTimeDayPage;
import React, { useState, useEffect, useContext, useRef } from 'react';
import DataGrid, { Column, Editing, KeyboardNavigation, Paging, Sorting } from 'devextreme-react/data-grid';
import useMobileDetect from 'use-mobile-detect-hook'
import _ from 'lodash';

/** components */

/** stores & lib */

/** images */

/** 
 *  Data Grid Pager
 */
const EditGridView = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    
    // ...

    /* ====================
     *  Object
     * ==================== */

    const detectMobile = useMobileDetect();

    const {
        id,
        reff,
//        showNo=true,
        dataSource,
        gridHeight=0,

        editMode='cell',
        allowUpdating=true,
        allowDeleting=false,
        allowAdding=false,

        enterKeyAction='moveFocus',
        enterKeyDirection='column',

        startEditAction='click',        
        colsWidth=[],

        columnResizingMode='widget',

        onRowUpdated,
        onEditingStart
        
    } = props;


    /* ====================
     *  State / Effect
     * ==================== */
    const [loaded, setLoaded] = useState(false);

    // useEffect(() => {
    //     loadPage();
    // },[]);

    /* ====================
     *  Load
     * ==================== */

    // const loadPage = () => {
        // if(loaded) return;        
        // setLoaded(true);
    // }

    /* ====================
     *  Handle / Functions
     * ==================== */

    const customizeColumns = (c) => {
        if(loaded) return;

        _.forEach(colsWidth, (v,k) => {                            
            if(v == null) return;
            c[k].width = v;
        })

        setLoaded(true);
    }

    /* ====================
     *  Render
     * ==================== */ 
      
    const renderContent = () => {

        return (            
            <>
            <div className="data-grid-1 mgt5">
                <DataGrid 
                    id={id}
                    ref={reff}

                    height={(gridHeight && !detectMobile.isMobile()) > 0 ? gridHeight : null}
                    dataSource={dataSource}

                    // allowColumnReordering={true}
                    showBorders={true}

                    allowColumnResizing={detectMobile.isMobile() == true ? false : true}
                    columnResizingMode={columnResizingMode}   // widget, nextColumn
                    showRowLines={true}

                    columnHidingEnabled={detectMobile.isMobile() == true ? true : false}

                    onRowUpdated={onRowUpdated}
                    onEditingStart={onEditingStart}

                    // columns width
                    customizeColumns={!loaded ? customizeColumns : null}
                >
                    
                    <Paging enabled={false} />
                    <Sorting mode={'none'} />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction={enterKeyAction}
                        enterKeyDirection={enterKeyDirection} />
                    <Editing
                        mode={editMode}
                        allowUpdating={allowUpdating}
                        allowDeleting={allowDeleting}
                        allowAdding={allowAdding}
                        startEditAction={startEditAction} 
                    />
                    {
                        /** Columns */
                        props.children
                    }
                </DataGrid>
            </div>
            </>
        )
    }

    return(
        <>
        { renderContent() }
        </>
    )
}

export default EditGridView;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { TextBox } from 'devextreme-react';
import qs from 'query-string';
import useKey from 'use-key-hook';

/** components */
import {
    ListSearchView, ListTopView, GridTopView, DataGridView, DayDateBox,
    SrchButton, ActionButton, IconButton
} from '../../components';

/** stores & lib */
import { AuthStore, CmnStore } from '../../stores';
import { util, cmn } from '../../lib';

/** 
 *  입고 관리
 */
const InPage = (props) => {

    /* ====================
     *  Store 
     * ==================== */
    const cmnStore = useContext(CmnStore);
    const authStore = useContext(AuthStore);

    /* ====================
     *  Object
     * ==================== */

    // ...

    /* ====================
     *  State / Effect
     * ==================== */
    const [pageLoad, setPageLoad] = useState(true);
    
    // * Control Items (* Combo ...)
    const [inTpItems, setInTpItems] = useState([]);

    // * 검색
    const [inDy, setInDy] = useState('');
    const [prodNm, setProdNm] = useState('');
    const [acctMon, setAcctMon] = useState(util.getNow());

    /* #region Search Items */    
    const searchItems = [
        [
            {   title: "회계월",
                width: "200px",
                item: 
                <DayDateBox 
                    value={acctMon}
                    type={'month'}
                    displayFormat={cmn.DATE_FORMAT_MON_1}
                    onValueChanged={(e) => {
                        setAcctMon(e.value);
                    }}                    
                />
            },
            {   title: "입고일",
                width: "200px",
                item: 
                <DayDateBox
                    value={inDy}
                    showClearButton={true}
                    onValueChanged={(e) => {
                        setInDy(e.value);
                    }}                    
                />  
            },
            {   title: "품명",
                item: 
                <TextBox 
                    value={prodNm}
                    width={'200px'}
                    showClearButton={true}
                    placeholder="품명으로 검색하세요."
                    valueChangeEvent={'keyup'}
                    onValueChanged={(e)=>{
                        setProdNm(e.value);
                    }}
                />    
            }
        ]
    ];
    /* #endregion */    

   // * Grid
   const [inList, setInList] = useState([]);
   const [inListTot, setInListTot] = useState(0);
   const [selectedItem, setSelectedItem] = useState(null);
   // * Paging & Sorting
    const [curPage, setCurPage] = useState(1);
    const [rowsOffset, setRowsOffset] = useState(0);
    const [rowsLength, setRowsLength] = useState(authStore.user.rows);
    const [sortItem, setSortItem] = useState({col: '', tp: ''});

    const grid1 = useRef(null);

    /** Enter Key */
    useKey((pressedKey, event) => {
        util.clickElement('srchButton');
    }, {
        detectKeys: [13]    // detectKeys: ['A', '+', 122]
    });

    useEffect(() => {
        loadPage();
    },[]);
    
    useEffect(() => {
        !pageLoad && handleClickSearch();
    }, [curPage, rowsLength, sortItem, acctMon, inDy]);

    /* ====================
     *  Load
     * ==================== */

    const loadPage = async() => {
        try {

            await authStore.loadPage(props);
        
            let r = await cmn.api('getCodeCombo', {p:{classId:'701'}}); 
            setInTpItems(r.d0);

            await handleClickSearch();
            setPageLoad(false);
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const loadList1 = async() => {
        const ps =  {
            p: {
                acctMon: util.getDateToString('M', acctMon), 
                inDy: util.getChkNull(inDy) !== '' ? util.getDateToString('d',inDy) : '', 
                prodNm: prodNm,
                                
                sortCol: sortItem.col,
                sortTp: sortItem.tp,
                offset: rowsOffset,
                limit: rowsLength
            }
        }

        const r = await cmn.api("getInList", ps );
        if(r === undefined || r == null) return;

        setInList(r.d0)
        setInListTot(r.tot);
    }

    /* ====================
     *  Handle / Functions
     * ==================== */

    // * Actions

    const handleClickSearch = async(e) => {
        try {
            await loadList1();
        }
        catch(err){
            cmnStore.handleErrorPage(err, props);
        }
    }

    const handleClickView = async() => {
        if(util.chkListSelected(selectedItem)){
            const url = `/order/inpop?${qs.stringify({mode: 'v', uid: selectedItem.inUid})}`;        
            util.openWindow(url, 800, 580);     
        }
    }

    // * Data Grid

    const handleGridSelectionChanged = ({selectedRowsData}) => {
        if(selectedRowsData.length === 0) { 
            setSelectedItem(null); return;
        }
        setSelectedItem(selectedRowsData[0]);
    }

    const handleGridCellClick = (e) => {
        // Header Sorting
        const sortItem_ = cmn.getGridHandleSortItem(e, sortItem);
        if(sortItem_ != null){
            setSortItem(sortItem_);
        }
    }

    const handleClickLblPrint = () => {
        let url = '';
        if(selectedItem == null)
            url = `/order/inlblprintpop`;     
        else
            url = `/order/inlblprintpop?${qs.stringify({lt:selectedItem.lot})}`;     
            
        util.openWindow(url, 800, 360);    
    }


    const handleClickPage = (p) => {
        setCurPage(p);
        const offset = (p-1)*rowsLength;
        setRowsOffset(offset);
    }

    const handleChangePageSize = (e) => {
        setRowsLength(e.value);
    }

    // ** Excel
    const handleClickExcel = (e) => {
        cmn.xlsNo = 0;
        grid1.current.instance.exportToExcel();
    }

    const customizeExcelCell = (e) => {
        const gc = e.gridCell;
        if(!gc || gc.rowType !== "data") return;
        if(gc.column.dataField === "no") e.value = ++cmn.xlsNo;
        if(     gc.column.dataField === "inDy" 
            ) e.value =util.getDateStrSplit('d',gc.value);
    }
    
    // * Functions

    /* ====================
     *  Render
     * ==================== */

     // Render Grid Header (* Sort ...)
    const renderGridHeaderCell = (e) => {
        return cmn.renderGridHeaderCell(e, sortItem);
    }

    const renderContent = () => {
        return(
            <>
            <ListTopView>
                <SrchButton 
                    id="srchButton"
                    onClick={handleClickSearch}
                />
                <ActionButton 
                    onClick={handleClickView}
                    title="상세보기"
                    show={authStore.page.viewYn}
                /> 
                <ActionButton 
                    onClick={handleClickLblPrint}
                    title="라벨출력"
                    marginRight={0}
                    type={2}
                    show={authStore.page.viewYn}
                /> 
            </ListTopView>     
            <ListSearchView searchItems={searchItems} />
            <GridTopView
                title="입고 내역"
            >
                {/* <IconButton
                    onClick={handleClickPrint}
                    className="btn-icon-print"
                /> */}
                <IconButton
                    onClick={handleClickExcel}
                    className="btn-icon-xls"
                    marginRight={0}
                />
            </GridTopView>     
            <DataGridView        
                reff={grid1}    
                dataSource={inList}
                gridHeight={authStore.windowHeight - cmn.BH.H10}

                // paging
                page={curPage}
                total={inListTot}
                itemsPerPage={rowsLength}
                onClickPage={handleClickPage}
                onChangePageSize={handleChangePageSize}

                // sort
                onCellClick={handleGridCellClick}

                // select
                onSelectionChanged={handleGridSelectionChanged}   

                // excel
                exlnm={'입고내역'}
                customizeExcelCell={customizeExcelCell}        

                colsWidth={[cmn.CW.bizNm, cmn.CW.lot, cmn.CW.prodNm, cmn.CW.prodId, cmn.CW.prodStnd
                    ,cmn.CW.dy, cmn.CW.amt , cmn.CW.unit, cmn.CW.price, cmn.CW.price, cmn.CW.userNm, 100, null]}             
            >
                
                { cmn.getGridColumn('bizNm', '협력사', renderGridHeaderCell) }
                { cmn.getGridColumn('lot', 'LOT NO', renderGridHeaderCell) }
                { cmn.getGridColumnProdInfo('prodNm', '품명', renderGridHeaderCell) }
                { cmn.getGridColumn('prodId', '품번', renderGridHeaderCell) }
                { cmn.getGridColumn('prodStnd', '규격', renderGridHeaderCell) }
                { cmn.getGridColumnDayType('inDy', '입고일', renderGridHeaderCell) }
                { cmn.getGridColumnNumCommaType('inAmt', '입고량') }
                { cmn.getGridColumn('unitTpNm', '단위') }
                { cmn.getGridColumnNumCommaType('inPrice', '단가') }
                { cmn.getGridColumnNumCommaType('totPrice', '금액') }
                { cmn.getGridColumn('userNm', '입고자', renderGridHeaderCell) }
                { cmn.getGridColumn('storNm', '창고위치', renderGridHeaderCell) }
                { cmn.getGridColumnCmnt() } 
            </DataGridView>
            </>
        );

    }

    return (
        <>
        { !pageLoad && renderContent() }
        </>
    )
}

export default InPage;